export enum UserGuideKeyEnum  {
    //Account Setting Tabs
    Patient1 = 'PharmacyInfo',
    Patient2 = 'Settings',
    Patient3 = 'Rx',
    // Patient4: "PatientSettings",
    Patient5 = 'Clinical',
    Patient6 = 'Drug',
    Patient7 = 'PriceUpdate',
    Patient8 = 'eRx',
    Patient9 = 'Label',
    Patient10 = 'Messaging',
    Patient11 = 'ControlFile',
    Patient12 = 'DMS',
    Patient13 = 'WorkFlow',
    Patient14 = 'FaxandReport',
    Patient15 = 'Transmission',
    Patient16 = '340B',
    Patient17 = "EDIInventory",
    Patient18 = "InterfaceSettings",
    //Rx Flows
    AddPatient = "AddNewPatient",
    EditPatient = "EditPatient",
    AddPrescriber = "AddNewPrescriber",
    EditPrescriber = "EditPrescriber",
    BrowseRx = "BrowseRx",
    AddDrug = "AddNewDrug",
    DpvVerification = "DpvVerification",
    AddInsurance = "AddInsurance",
    RefillQueue = "RefillQueue",
    RefillDue = "RefillDue",
    RxVerification = "RxVerification",
    RphVerification = "RphVerification",
    RxFilledSummary = "RxFilledSummary",
    EditRx = "EditRx",
    RxEntry = "RxEntry",
    TransferInAndTransferOut = "TransferInAndTransferOut",
    DeferrredQueue = "DeferrredQueue",
    LabelQueue = "LabelQueue",
    InsuExceReport = "InsuExceReport",
    TransHistory = "TransHistory",
    DPVHistory = "DPVHistory",
    RPHVerfHist = "RPHVerfHist",
    RphDashboard = "RphDashboard",
    ReturnToStock = "ReturnToStock",
    IntakeQueue = "IntakeQueue",
    PatientHistory = "PatientHistory",
    ReviewTaggedFlwUp = "ReviewTaggedFlwUp",

    //eRx
    eRxActionList = "eRxActionList",
    eRxExceReport ="eRxExceReport",
    //Login Page
    LoginPage = "Login",
    SSOData = "SSOData",
    //Qz Tray Popup
    QzTrayInstallation = "QzTray",
    //Dashboard
    DashBoard = "Dashboard",
   //Security Manager Tabs
    users = "Users",
    roles = "Roles",
    loginhistory = "UsersLoginHistory",
    auditlog = "AuditLog",
    overrideLog = "OverrideLog",
    //DMS
    DMSSubcategory = "DMSSubcategory",
    BatchScan = "BatchScan",
    DocumentQueue = "DocumentQueue",
    PrintRxWithNoAttachedDocuments = "PrintRxWithNoAttachedDocuments",
    PrintRxWithAttachedDocuments = "PrintRxWithAttachedDocuments",
    //Tools
    MergePatients = "MergePatients",
    LabelDesigner = "LabelDesigner",
    DrugPriceCheck = "DrugPriceCheck",
    DDIChecks = "DDIChecks",
    ScheduledTasks = "ScheduledTasks",
    BusinessIntelligence = "BusinessIntelligence",
    PackageFileGen = "PackageFileGen",
    RecallDeletedRx = "RecallDeletedRx",
    ImmunFileGen = "ImmunFileGen",
    NotesSubCategory = "NotesSubCategory",
    //Reports
    DailyLogReport = "DailyLogReport",
    DeferredRxReport = "DeferredRxReport",
    RxByPharmacistReport = "RxByPharmacistReport",
    PrintControlRxReport = "PrintControlRxReport",
    RefillExpirationReport = "RefillExpirationReport",
    BusinessSummaryReport = "BusinessSummaryReport",
    PatientMedicationAdherence = "PatientMedicationAdherence",
    PriceReport = "PriceReport",
    RxByPrescriberReport = "RxByPrescriberReport",
    DrugDispensedReport = "DrugDispensedReport",
    DrugDispensedEquReport = "DrugDispensedEquReport",
    UnbilledRxReport = "UnbilledRxReport",
    RefillsDueReport = "RefillsDueReport",
    ASAPControlDataReport = "ASAPControlDataReport",
    InvtentoryStatusReport = "InvtentoryStatusReport",
    RxNotPickedUpReport = "RxNotPickedUpReport",
    TransferredRxsReport = "TransferredRxsReport",
    SalesTaxSummaryReport = "SalesTaxSummaryReport",
    RxByInsuranceReport = "RxByInsuranceReport",
    PartialFillReport = "PartialFillReport",
    AuditTrails = "AuditTrails",
    EDI835RemitReport = "EDI835RemitReport",
    ReportWriter = "ReportWriter",

    // Inventory
    BucketInv = "BucketInv",
    EnterInventory = "EnterInventory",
    ViewInventory = "ViewInventory",
    PhysicalInventory = "PhysicalInventory",
    TransTypeInv = "TransTypeInv",
    EDI832PriceUpdateHis = "EDI832PriceUpdateHis",
    EDI810LogReport = "EDI810LogReport",
    InvReconcReport = "InvReconcReport",

    //  Fax
    faxRefill = "FollowupRefReq",
    faxLog = "FaxReqLog",
    incomingfax = "IncomingFax",
    batchfax = "BatchedFaxes",

    //Files
    PatientFile = "PatientFile",
    PatientCategory = "PatientCategory",
    FacilityFile = "FacilityFile",
    DiagnosisFile = "DiagnosisFile",
    PrescriberFile = "PrescriberFile",
    PrescriberSpeciality = "PrescriberSpeciality",
    SigFile = "SigFile",
    DrugDescFile = "DrugDescFile",
    DrugWarnFile = "DrugWarnFile",
    DrugCatFile = "DrugCatFile",
    DrugFile = "DrugFile",
    PrescriberTypeFile = "PrescriberTypeFile",

    //SMS
    MessageLog = "MessageLog",
    MessageQueue = "MessageQueue",

    //Electronic Signature
    SignByPatient = "SignByPatient",
    SignByDate = "SignByDate",
    SignByRx = "SignByRx",
    HippaAckment = "HippaAckment",
    PrintRxPickLog = "PrintRxPickLog",
    SignClaimsLog = "SignClaimsLog",
    RxPickupDetails = "RxPickupDetails",
    Pos = "Pos",
    IouReport = "IouReport",
    NotesReport = "NotesReport"
};
export enum UserGuideHeadingEnum  {
    //Account Setting Tabs
    Patient1 = 'Pharmacy Info',
    Patient2 = 'Pharmacy Settings',
    Patient3 =  'Rx Settings',
    // Patient4: "PatientSettings",
    Patient5 = 'Clinical Settings',
    Patient6 = 'Drug Settings',
    Patient7 =  'Price Update Settings',
    Patient8 =  'eRx Settings',
    Patient9 = 'Label Settings',
    Patient10 = 'Messaging Settings',
    Patient11 = 'Control File Settings',
    Patient12 = 'DMS Settings',
    Patient13 = 'Work Flow Settings',
    Patient14 = 'Fax & Report Settings',
    Patient15 = 'Transmission Settings',
    Patient16 = '340B Settings',
    Patient17 = "EDI/Inventory Settings",
    Patient18 = "Interface Settings",
    //Rx Flows
    AddPatient = "Add New Patient",
    EditPatient = "Edit Patient",
    AddPrescriber = "Add Prescriber",
    EditPrescriber = "Edit Prescriber",
    BrowseRx = "Browse Rx",
    AddDrug = "Add New Drug",
    DpvVerification = "Drug Pick Verification",
    AddInsurance = "Add Insurance",
    RefillQueue = "Refill Queue",
    RefillDue = "Refill Due",
    RxVerification = "Rx Verification",
    RphVerification = "Rph Verification",
    RxFilledSummary = "Rx Filled Summary",
    EditRx = "Edit Rx",
    RxEntry = "Rx Entry",
    TransferInAndTransferOut = "Rx Transfer",
    DeferrredQueue = "Deferred Queue",
    LabelQueue = "Label Queue",
    InsuExceReport = "Insurance Exception Report",
    TransHistory = "Transmission History",
    DPVHistory = "DPV History",
    RPHVerfHist = "R.Ph Verfication History",
    RphDashboard = "Rph Dashboard",
    ReturnToStock = "Return To Stock",
    IntakeQueue = "Intake Queue",
    PatientHistory = "Patient History",
    ReviewTaggedFlwUp = "Review Rxs Tagged for Follow-up",
    //eRx
    eRxActionList = "eRx Action List",
    eRxExceReport ="eRx Exception Report",
    //Login Page
    LoginPage = "Login",
    SSOData = "SSOData",
    //Qz Tray Installation
    QzTrayInstallation = "Qz-Tray Installation",
   //Dashboard
    DashBoard = "Dashboard",
   //Security Manager Tabs
    users = "Users",
    roles = "Roles",
    loginhistory = "Users Login History",
    auditlog = "Audit Log",
    overrideLog = "Override Log",
    //DMS
    DMSSubcategory = "DMS Subcategory",
    BatchScan = "Batch Scan",
    DocumentQueue = "Document Queue",
    PrintRxWithNoAttachedDocuments = "Print Rx With No Documents",
    PrintRxWithAttachedDocuments = "Print Rx With Documents",
    //Tools
    MergePatients = "Merge Patient",
    LabelDesigner = "Label Designer",
    DrugPriceCheck = "Drug Price Check",
    DDIChecks = "DDI Check",
    ScheduledTasks = "Scheduled Tasks",
    BusinessIntelligence = "Business Intelligence",
    PackageFileGen = "Package File Generator",
    RecallDeletedRx = "Recall Deleted Rx",
    ImmunFileGen = "Immunization File Generator",
    NotesSubCategory = "Notes Subcategory",
    //Reports
    DailyLogReport = "Daily Log Report",
    DeferredRxReport = "Deferred Rx Report",
    RxByPharmacistReport = "Rx By Pharmacist Report",
    PrintControlRxReport = "Control Rx Report",
    RefillExpirationReport = "Refill Expiration Report",
    BusinessSummaryReport = "Business Summary Report",
    PatientMedicationAdherence = "Patient Medication Adherence Report",
    PriceReport = "Price Report",
    RxByPrescriberReport = "Rx By Prescriber Report",
    DrugDispensedReport = "Drug Dispensed Report",
    DrugDispensedEquReport = "Drug Dispensed Equivalency Report",
    UnbilledRxReport = "Unbilled Rx Report",
    RefillsDueReport = "Refills Due Report", 
    ASAPControlDataReport = "ASAP Control Data Report",
    InvtentoryStatusReport = "Invtentory Status Report",
    RxNotPickedUpReport = "Rx Not PickedUp Report",
    TransferredRxsReport = "Transferred Rxs Report",
    SalesTaxSummaryReport = "Sales Tax Summary Report",
    RxByInsuranceReport = "Rx By Insurance Report",
    PartialFillReport = "Partial Fill Report",
    AuditTrails = "Audit Trails",
    EDI835RemitReport = "EDI 835 Remittance Report",
    ReportWriter = "Report Writer",

    // Inventory
    BucketInv = "Bucket",
    EnterInventory = "Enter Inventory",
    ViewInventory = "View Inventory",
    PhysicalInventory = "Physical Inventory",
    TransTypeInv = "Inventory Trans Type",
    EDI832PriceUpdateHis = "EDI 832 Price Update History",
    EDI810LogReport = "EDI 810 Log Report",
    InvReconcReport = "Perpetual Inventory Report",

    // Fax
    faxRefill = "Follow up Refill Request",
    faxLog = "Fax/Refill Request Log",
    incomingfax = "Incoming Fax",
    batchfax = "Batched Faxes",

    //Files
    PatientFile = "Patient File",
    PatientCategory = "Patient Category",
    FacilityFile = "Facility File",
    DiagnosisFile = "Diagnosis File",
    PrescriberFile = "Prescriber File",
    PrescriberSpeciality = "Prescriber Speciality",
    SigFile = "Sig File",
    DrugDescFile = "Drug Description File",
    DrugWarnFile = "Drug Warning File",
    DrugCatFile = "Drug Category File",
    DrugFile = "Drug File",
    PrescriberTypeFile = "Prescriber Type File",

    //SMS
    MessageLog = "Message Log",
    MessageQueue = "Message Queue",

    //Electronic Signature
    SignByPatient = "Signature By Patient",
    SignByDate = "Signature By Date",
    SignByRx = "Signature By Rx",
    HippaAckment = "Hippa Acknowldegment Report",
    PrintRxPickLog = "Print Rx Pickup Log Report",
    SignClaimsLog = "Signature Claims Log Report",
    RxPickupDetails = "Rx Pickup Details Report",
    Pos = "POS - Copay Collected",
    IouReport = "IOU Report",
    NotesReport = "Notes Report"
};