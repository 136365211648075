<div class="modal-header">
    <h4 class="modal-title">{{titleText}}</h4>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click');backToprev()">
        <span aria-hidden="true" class="close-button"vv>&times;</span>
    </button>
</div>
<div class="modal-body modal-dropdown-overflow" *ngIf="showContent=='buckTrans'">
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 seconday-search--grid" [formGroup]="BucketTransFormGroup">
            <div class="row">
                <div class="col-4 color_cyan">
                    Drug Name<span class="help--text__danger">*</span></div>
                <div class="col-8">
                    <!-- <div class="text-right">
                        <div><i class="far fa-lg fa-long-arrow-alt-right color_orange"></i><span>
                                for Equv ; </span><b class="color_orange" >Shift + <i
                                    class="far fa-lg fa-long-arrow-alt-right color_orange"></i></b><span> for Gen
                                Equv</span></div>
                    </div> -->
                </div>
            </div>
            <!-- <app-searchbox-global #drugSearch [SearchFor]="'Drug'" [DisableRadioButtons]="true" [IsAddNew]="false"
                (SelectedDrug)="optedDrug($event)" [ClearInputValue]="selectedDrugName" [IsFocused]="isDrugFocused">
            </app-searchbox-global> -->
            <app-searchbox-global [SearchFor]="'Drug'" #drugSearch [DisableRadioButtons]="true" [IsAddNew]="false" class="common_global_search_max_content_display_for_reports"
                [DisableSerchFil]="true" (SelectedDrug)="optedDrug($event)" [ClearInputValue]="selectedDrugName"
                [IsFocused]="isDrugFocused" [SearchFrm]="'commonDrugSearch'">
            </app-searchbox-global>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3" *ngIf="selectdDrug">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label>Total Quantity:</label>
                        <div class="label--data">{{ (BucketTransFormGroup?.value?.DrugQnty ? (BucketTransFormGroup?.value?.DrugQnty | number:'1.3-3') : "0.00")}}</div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label>NDC:</label>
                        <div class="label--data">{{ this._formatsUtil.getNDCFormat(selectdDrug?.ndc) }}</div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label>Strength:</label>
                        <div class="label--data">{{ selectdDrug?.strength ? selectdDrug?.strength : "--"}}</div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label>Form:</label>
                        <div class="label--data">{{ selectdDrug?.drugformname ? selectdDrug?.drugformname : "--"}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="disableTransfer">
            <hr>
            <h5>Selected Drug has no available quantity to do Bucket Transfer.</h5>
        </div>
    </div>
  <hr>
    <div class="row"  *ngIf="!disableTransfer">
        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Bucket 1
                        <!-- <ul class="pull-right eprx--header__icons" *ngIf="selectdBuck1">
                            <li (click)="editBucket1()"><img src="assets/dist/img/patient-edit.png"></li>
                        </ul> -->
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="align-label">Available Qty: </label>&nbsp;
                            <span>{{ BucketTransFormGroup?.value?.Bucket1 ?  (BucketTransFormGroup?.value?.Buck1Qnty ? (BucketTransFormGroup?.value?.Buck1Qnty | number:'1.3-3') : "0.000") : "0.000"}}</span>
                        </div>
                        <div class="col-md-12">
                            <eprx-select [LabelText]="'Select Bucket'" [PlaceHolder]="'Bucket Name'"
                                [IsDisabled]="!selectdDrug||!drugBucketList" [ControlName]="'Bucket1'" [FormGroupName]="BucketTransFormGroup"
                                [List]="drugBucketList" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'bucket1Id'"
                                [MarkAsTouched]="BucketTransFormGroup?.get('Bucket1')?.touched" (TriggerSelectValue)="optedBucket1($event?.value)"
                                [IsRequired]="true" [ErrorDefs]="{required: 'Required'}">
                            </eprx-select>
                        </div>
                        <div class="col-md-12">
                            <eprx-input [LabelText]="'Transfer Quantity'" [PlaceHolder]="'Transfer Quantity'" [InputType]="'NUMBER'"
                                [MarkAsTouched]="BucketTransFormGroup?.get('Buck1TransQnty')?.touched" [ControlName]="'Buck1TransQnty'"
                                [FormGroupName]="BucketTransFormGroup" [IsRequired]="true" [IsDisabled]="!selectdDrug||!drugBucketList"
                                (TriggerOnBlur)="onTransQntyChange($event, $event['target'].value)"
                                [ErrorDefs]="{required: 'Required'}" [RxSelectId]="'Buck1TransQnty'">
                            </eprx-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 bucket--edit--buttons">
            <h1><i class="far fa fa-arrow-circle-o-right text-primary action"></i></h1>
            <label class="align-label">Transfer to</label>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Bucket 2
                        <!-- <ul class="pull-right eprx--header__icons" *ngIf="selectdBuck2">
                            <li (click)="editBucket2()"><img src="assets/dist/img/patient-edit.png"></li>
                        </ul> -->
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="align-label">Available Qty: </label>&nbsp;
                            <span>{{ BucketTransFormGroup?.value?.Bucket2 ? (BucketTransFormGroup?.value?.Buck2Qnty ? (BucketTransFormGroup?.value?.Buck2Qnty | number:'1.3-3') : "0.000" ) : "0.000"}}</span>

                        </div>
                        <div class="col-md-12">
                            <eprx-select [LabelText]="'Select Bucket'" [PlaceHolder]="'Bucket Name'" [ControlName]="'Bucket2'"
                                [FormGroupName]="BucketTransFormGroup" [List]="drugBucketList" [BindLabel]="'Name'"
                                [IsDisabled]="!selectdDrug||!drugBucketList" [BindValue]="'Id'" [LabelForId]="'bucket2Id'" [IsRequired]="true"
                                [ErrorDefs]="{required: 'Required'}" (TriggerSelectValue)="optedBucket2($event?.value)"
                                [MarkAsTouched]="BucketTransFormGroup?.get('Bucket2')?.touched" [IsDisabled]="!(BucketTransFormGroup.value.Drug)">
                            </eprx-select>
                        </div>
                        <div class="col-md-12">
                            <eprx-input [LabelText]="'Qty Post Transfer'" [PlaceHolder]="'Qty Post Transfer'" [HasControl]="false" [InputValue]="+BucketTransFormGroup?.value?.Buck2TransQnty +
                            (BucketTransFormGroup?.value?.Bucket2 ? +BucketTransFormGroup?.value?.Buck2Qnty : 0)"
                             [InputType]="'NUMBER'" [IsDisabled]="true">
                            </eprx-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 bucket--edit--buttons">
        </div> -->
    </div>
</div>


<div class="modal-body" *ngIf="showContent=='lowInv'" id="lowInvGrid">
    <!-- <div class="clearfix"></div> -->
    <div class="row">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 edit--drug">

            <div class="row padding_bottom_10px">
                <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12 padding-0">
                    <label class="align-label" class="col-lg-5 col-md-5 col-sm-5 col-xs-5">Drug Being Dispensed</label>
                    <span>:&nbsp; {{drugInfo.drugname | uppercase}}</span>
                </div>
                <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12 padding-0">
                    <label class="align-label" class="col-lg-2 col-md-2 col-sm-2 col-xs-2">NDC</label>
                    <!-- <span>:{{drugInfo.ndc}}</span> -->
                    <span>
                            : &nbsp; {{ (this._formatsUtil.getNDCFormat(drugInfo?.ndc)) }}
                    </span>
                </div>
            </div>

            <div class="row">

                <div class="col-md-4 col-lg-4 col-xs-4 col-sm-4 inventory  drug-inven inventory--color2">
                    <div class="inv--information">
                        <div class="inv--image--sml">
                            <img src="assets/dist/Icons/icon_Pickup.svg" title="Qty Dispensed">
                        </div>
                        <div class="inv--value">
                            <h3>{{(qntyInhand| number:'1.3-3')}}</h3>
                            <div class="inv--description">
                                <h5>Quantity In Hand</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xs-4 col-sm-4 inventory  drug-inven inventory--color4">
                    <div class="inv--information">
                        <div class="inv--image--sml">
                            <img src="assets/dist/Icons/icon_pills1_white.svg" title="Qty In Hand">
                        </div>

                        <div class="inv--value">
                            <h3>{{(orderQnty | number:'1.3-3')}}</h3>
                        </div>
                        <div class="inv--description">
                            <h5>Qty Being Dispensed</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xs-4 col-sm-4 inventory  drug-inven inventory--color1">
                    <div class="inv--information">
                        <div class="inv--image--sml">
                            <img src="assets/dist/Icons/icon_pills_white.svg" title="Bucket">
                        </div>
                        <div class="inv--value">
                            <h3>{{buckInfo.Name | uppercase}}</h3>
                            <div class="inv--description">
                                <h5>Bucket Name</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row margin_top_15px">
                <div class="col help--text__danger font_size_18px text-center">
                    Quantity in hand in the bucket {{buckInfo.Name | uppercase}} for the selected drug is less
                    than the quantity
                    being
                    dispensed.
                </div>
            </div>


            <div class="row">
                <div class="col erx--body">
                    <label>Equivalent Drugs</label>
                    <div class="row">
                        <div class="col">
                            <div class="exprx--common-block">
                                <div class="eprx--block__content edit--drug insurance--body">
                                    <wj-flex-grid #equDrug [headersVisibility]="'Column'" [itemsSource]="eqDrugWJ"
                                        [isReadOnly]="true" [columnPicker]="'row'" [selectionMode]="'Row'"
                                        *ngIf="(eqDrugList && eqDrugList.length !== 0)" (updatedView)="init(equDrug)"
                                        (dblclick)="onRowDblclicked(equDrug, $event)"
                                        (keydown.enter)="keyeventOnWj(equDrug)" (keydown.tab)="focusOutFromWJ($event)"
                                        (keydown.shift.tab)="focusOutFromWJ($event)" [style.max-height]="_wijHeight">
                                        <wj-flex-grid-column [header]="'Action'" [width]="75">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <i class="far fa-file-import action m-0" title="Select" (click)="
                                                        optedInvDrug(item)"></i>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="header" [binding]="header" [visible]="true"
                                            *ngFor="let header of actvHeaders; let i=index;" [width]="'*'">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <div *ngIf="header === 'Pref'" class="text-center">
                                                    <i *ngIf="item['IsPreferred']" class="far fa-check "
                                                        title="True"></i>
                                                    <i *ngIf="!item['IsPreferred']" class="far fa-times  fa-uncheck"
                                                        title="False"></i>
                                                </div>
                                                <div *ngIf="!(header === 'Pref' || header === 'NDC')">
                                                    {{item[header]}}
                                                </div>
                                                <div *ngIf="header === 'NDC'">
                                                    {{item[header] | mask:"AAAA0-0000-00"}}
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>

                                    <h3 *ngIf="(!eqDrugList || eqDrugList.length === 0)" class="text-center">No
                                        Equivalent Drugs</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                    <label>Bucket List</label>

                </div>
            </div>

        </div>
    </div>
</div>





<div class="modal-body" *ngIf="showContent=='editBuck'">
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
            <eprx-input [LabelText]="'Bucket Information for bucket Code'" [PlaceHolder]="'Bucket Code'"
                [ControlName]="'Code'" [FormGroupName]="EditBucketrmGrp" [ReadOnly]="true" [IsDisabled]="true">
            </eprx-input>
            <span class="help--text__danger" *ngIf="isBucketExists">Bucket code already exists</span>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7" [formGroup]="EditBucketrmGrp">
                <label class="form-check-label">
                    Use Bucket Price Code
                </label>
                <eprx-radio [ControlName]="'IsBucketPriceUsed'" [FormGroupName]="EditBucketrmGrp" [IsRequired]="true"
                    [List]="['Yes', 'No']" [IsDisabled]="EditBucketrmGrp?.value['Name'] === 'GENERAL'" [ValueList]="[true, false]" [IDForList]="['1', '0']" [LabelForId]="">
                </eprx-radio>
            </div>
        </div> -->
    <!-- <hr> -->
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--right__7">
            <eprx-input [LabelText]="'Bucket Name'" [AutoFocus]="true" [PlaceHolder]="'Bucket Name'"
                [ControlName]="'Name'" [FormGroupName]="EditBucketrmGrp" [ErrorDefs]="{required: 'Required'}"
                [IsRequired]="true" [MaxLength]="30" [InputErrors]="BEName?.errors">
            </eprx-input>
            <span class="help--text__danger" *ngIf="isBucketNameExists">Bucket Name already exists</span>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--right__7">
            <eprx-select [LabelText]="'Bucket Context Type'" [PlaceHolder]="'Bucket Context Type'" [ControlName]="'Type'" [FormGroupName]="EditBucketrmGrp"
                        [List]="bucketDropsData" [BindLabel]="'ContextName'" [BindValue]="'Id'" [LabelForId]="'ContextName'" [ErrorDefs]="{required: 'Required'}"
                        [IsRequired]="true" [InputErrors]="BEType?.errors" [IsDisabled]="true" [showBindVal]="true">
            </eprx-select>
        </div>
        <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--right__7">
                <eprx-select [LabelText]="'Price Code'" [PlaceHolder]="'Price Code'" [ControlName]="'PriceSchId'"
                    [FormGroupName]="EditBucketrmGrp" [List]="bucketPriceDropData" [BindLabel]="'Name'" [BindValue]="'Id'"
                    [LabelForId]="'Name'" [IsDisabled]="!IsBucketPriceUsed.value">
                </eprx-select>
        </div> -->
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <wj-flex-grid *ngIf="BucketHistWJ" #BucketHistoryGrid [headersVisibility]="'Column'" [isReadOnly]="true"
                [itemsSource]="BucketHistWJ" [selectionMode]="'None'" class="bucket-history-wijmo">
                <wj-flex-grid-column [header]="bucketHistHeader" [binding]="bucketHistHeader" [visible]="true"
                    *ngFor="let bucketHistHeader of bucketHistHeaders; let i = index" [width]="bucketHistHeader === 'Drug Name'? 262 :
                    (bucketHistHeader === 'Drug NDC' || bucketHistHeader === 'Quantity Pack') ? 196 : 172">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                        <div *ngIf="bucketHistHeader === 'Drug NDC'">
                            {{item[bucketHistHeader] | mask:"AAAA0-0000-00"}}
                        </div>
                        <div *ngIf="!(bucketHistHeader === 'Drug NDC')">
                            {{item[bucketHistHeader]}}
                        </div>
                    </ng-template>
                </wj-flex-grid-column>
            </wj-flex-grid>
            <div class="row"  *ngIf="BucketHistWJ">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <app-eprx-pagination [FromModal]="true" [TotalCount]="totalCount" [SecondName]="'Total Value'" [SecondCount]="EditBucketrmGrp?.value['TotalValue']"
                    [WijmoName]="flex" [GridName]="'BucketHistoryGriddisp'"
                    (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [PageNumber]="EditBucketrmGrp?.value['pageNumber']"></app-eprx-pagination>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--right__7">
            <eprx-input [LabelText]="'Total Cost'" [PlaceHolder]="''" [ControlName]="'TotalValue'"
                [FormGroupName]="EditBucketrmGrp" [ReadOnly]="true">
            </eprx-input>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--right__7">
            <eprx-input [LabelText]="'Total Items'" [PlaceHolder]="''" [ControlName]="'TotalItems'"
                [FormGroupName]="EditBucketrmGrp" [ReadOnly]="true">
            </eprx-input>
        </div>
    </div> -->
</div>
<div class="modal-footer ">
    <div class="text-right float-right ">
        <button class="hotkey_success" autofocus *ngIf="showContent=='buckTrans' && !disableTransfer && selectdDrug"  id="transfernow"
            (click)="transferBucket($event)" appShortcutKey [AltKey]="'t'"><span>T</span>
            Transfer Now</button>
        <button class="inactive" autofocus *ngIf="showContent=='buckTrans' &&(disableTransfer || !selectdDrug)">
            <span>T</span>
        Transfer Now</button>
        <button class="hotkey_primary" (keydown.tab)="focusOutFromCancelButton($event)" id="lowInvCancel"
            autofocus
            *ngIf="showContent=='lowInv'" (click)="backToprev()" appShortcutKey [InputKey]="'o'"><b>O</b> Continue with
            current Drug</button>
        <button class="hotkey_success" *ngIf="showContent=='editBuck'" (click)="onBucketCodeChange()" appShortcutKey [AltKey]="'u'"><span>U</span>
            Update</button>
        <button class="hotkey_success" (click)="backToprev()" *ngIf="showContent!=='lowInv'" appShortcutKey [AltKey]="'c'"><span>C</span>
            Cancel</button>
    </div>
</div>

<ng-template #warningContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click'); modalRef=null">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <label>{{WarningMessage}}</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click'); modalRef=null" appShortcutKey InputKey="o"><b>O</b>Ok</button>
    </div>
</ng-template>

<ng-template #ERRORMSG let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');focusAferPopup($event)">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <span>{{errorMessage}}</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" footer autofocus (click)="c('Close click');focusAferPopup($event); modalRef=null" appShortcutKey InputKey="o"><b>O</b>Ok</button>
    </div>

</ng-template>
