import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, CommonService, ControlFileService, RxService } from 'src/app/services';
import * as moment from "moment";
import { FormBuilder, FormGroup } from '@angular/forms';
import { CollectionView } from '@grapecity/wijmo';
import { DomSanitizer } from '@angular/platform-browser';
import { PrintService } from 'src/app/services/print.service';
import { SystemData, resolveErrorEnum } from 'src/app/models';
import { ColorCode, CommonUtil, NRxUtils, ValidateFormFieldsUtils, WijimoUtil } from 'src/app/utils';
import { AccCategoryE, RxE } from 'src/app/models';
import { PrivMaskGuard } from 'src/app/guards';
import { EdRxComponent } from 'src/app/modules/ed-rx';
import * as wjcGrid from "@grapecity/wijmo.grid";
import { ColorCodeRx } from 'src/app/utils/rx-color.util';
import { HoverOver } from 'src/app/utils/hover-over.util';
import { CommonStore } from 'src/app/store';
import { MsgConfig } from 'src/app/app.messages';
import { FormatsUtil } from 'src/app/utils/formats.util';
@Component({
  selector: 'app-display-notifications',
  templateUrl: './display-notifications.component.html'
})
export class DisplayNotificationsComponent implements OnInit {
  isActive = true;
  activeSubMenu: string;
  upArrowIcon:boolean = true;
  openSuccessBlock:boolean = false;
  openErrorBlock:boolean = false;
  zeroReportingBlock:boolean = false;
  CountDetails:any;
  nabpNum:any;
  date:any;
  today: any;
  displaySuccessCount:CollectionView;
  displayErrorCount:CollectionView;
  displayZeroReportingCount:CollectionView;
  actvCtrlHeaders = ["State", "Notification Date Time","Status","Action"];
  notificationDisplayFG:FormGroup;
  maxDate: { year: number; month: number; day: number; };
  previewWJ: CollectionView;
  pdfSrc: any;
  showSuccessDocPreview:boolean = false;
  showErrorDocPreview:boolean = false;
  showZeroReportDocPreview:boolean = false;
  successCount:any = [];
  ErrorCount:any = [];
  RxCount:any = [];
  submittedDate:any;
  @Output()
  popupClosed = new EventEmitter<any>();
  @Output()
  notificationCountAfterPreview = new EventEmitter<any>();
  alreadyRead :boolean = true;
  @Input() NotificationCount:any;
  printNotif:any;
  userReviewStatus:any;
  submissionNotif : boolean = true;
controlFileErrorNY :boolean;
wjHeaders:any;
controlError:boolean;
wjHeaders2:any;
errorResponse:any;
controlErrorWJ: CollectionView;
showNYErrorsWJ:CollectionView;
newYorkErros: any;
selectedrxNo: any;
  selectedrxId: any;
  accessPrivCate:  any;
  rxPrivs: any;
  categoryId: any;
  privType: any;
  openOverride: boolean;
  overrideEndpoint: any;
  controlErrorWWJ:CollectionView;
  errors:any;
  // ErrorUpdateStatus:boolean;
  rxWijmo: wjcGrid.FlexGrid;
  totalRecordsLength: any;
  successLength: any;
  resolvedCount: any;
  unResolvedCount: any;
  systemData: SystemData;
  yesterday: any;
  selectedError: any;
  selectedFilter: any;
  IsActiveTabName: string = 'ASAP';
  constructor( private _colorCodeRx: ColorCodeRx, private _formUtils: ValidateFormFieldsUtils, private _comnUtil: CommonUtil, private _commonStore: CommonStore,private _alertSer: AlertService,  private _hoverOver: HoverOver, private _modalService: NgbModal,private _colorCode: ColorCode,private _privMaskGuard: PrivMaskGuard,private _printServ: PrintService,  private _nrxUtls: NRxUtils, private _cmnSvc: CommonService,private _wijimoUtils: WijimoUtil,public activeModal: NgbActiveModal, private _rxService: RxService, private _fb: FormBuilder, private _controlFileSvc: ControlFileService,private sanitizer: DomSanitizer,private _cdr: ChangeDetectorRef,
    private _formatsUtil:FormatsUtil
  ) {
    this.accessPrivCate = AccCategoryE;
    this.rxPrivs = RxE;
   }

  ngOnInit() {
    this._cmnSvc.systemData$.subscribe(resp => {
      this.systemData = resp;
  });
    this.maxDate = {
        year: moment(this.today).year(),
        month: moment(this.today).month() + 1,
        day: moment(this.today).date()
    };
      this.today = moment().format("MM/DD/YYYY");
    this.yesterday = moment().subtract(1, "day").format("MM/DD/YYYY")
    this.createFG();
    this.setDefaultValues();
    this.patchDefaultValHeaders();
    this._wijimoUtils.wjSavedData$.subscribe(w => {
        if (w && w["WijmoKey"] && (w["WijmoKey"] === "controlFileError" || w["WijmoKey"] === "controlFileErrorForNY")) {
            this.patchDefaultValHeaders();
        }
    });
    this.onSearch();
    this.searchForControlErrorData();
  }
  closePopUp(){
    this.popupClosed.emit(true);
    if (this.activeModal) {
      this.activeModal.close();
    }

  }
  createFG(){
    this.notificationDisplayFG = this._fb.group({
        fromDate:null,
        toDate: null,
        statusDropdwn:null,
        FromDate:null,
        ToDate:null,
    });
  }
  setDefaultValues(){
    this.notificationDisplayFG.controls["fromDate"].patchValue(moment()
    .subtract(1, "day")
    .format("MM/DD/YYYY"));
    this.notificationDisplayFG.controls["toDate"].patchValue(this.today);
    this.notificationDisplayFG.controls["FromDate"].patchValue(moment()
    .subtract(1, "day")
    .format("MM/DD/YYYY"));
    this.notificationDisplayFG.controls["ToDate"].patchValue(this.today);
    this.notificationDisplayFG.controls["statusDropdwn"].patchValue(1);
  }
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("controlFileError");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("controlFileError", storedWJ);
    const storedWJ2 = await this._wijimoUtils.getWJSavedData("controlFileErrorForNY");
    this.wjHeaders2 = this._wijimoUtils.patchDefHeader("controlFileErrorForNY", storedWJ2);
}
loadReportData(data) {
  this.displaySuccessCount = new CollectionView(
    data.map((item, i) => {
          const k = {};
          k["State"] = item.StateName;
          k["NotificationDateTime"] = (item.CreatedDtTm) ? item.CreatedDtTm:"";
          k["Notification Date Time"] =(item.CreatedDtTm) ? moment.utc(item.CreatedDtTm).local().format(
            "MM/DD/YYYY hh:mm:ss a"
        ) : "";
          k["Action"] = "";
          k["Status"] = "";
          k["SuccessCount"] = item.SuccessCount;
          k["ErrorCount"] = item.ErrorCount;
          k["RxCount"] = item.RxCount;
          k["FailureCount"] = item.FailureCount;
          k["UserReviewStatus"] =  this.userReviewStatus = item.UserReviewStatus ?  true : false;
          return k;
      })
  );

}
async onSearch(){
  await this._formUtils.blurAndDelay();
        const resp = this._formUtils.checkDateErrors(this.notificationDisplayFG, "fromDate", "toDate", 0);
       if (resp === "DATEISSUE") {
            this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
        } else {
          const data={
            fromDate: this.notificationDisplayFG.controls["fromDate"].value,
            toDate :this.notificationDisplayFG.controls["toDate"].value,
          }
          this._rxService.getDetailsForNotificationDisplay(data).subscribe(resp=>{
             if(resp){
             this.CountDetails =  resp['CountDetails'];
             this.userReviewStatus = resp['UserReviewStatus'];
             this.date = moment().utc( resp['CountDetails'].CreatedDtTm).local().format("MM/DD/YYYY");
             this.nabpNum = resp['NABPNo'];
            const readNotification = {
              fromDate: this.notificationDisplayFG.controls["fromDate"].value,
              toDate : this.notificationDisplayFG.controls["toDate"].value
            }
           this._rxService.detailsForDisplayNotificationCount(readNotification).subscribe(notifCount=>{
            if(notifCount){
              this.notificationCountAfterPreview.emit(notifCount);
            }
           })
              this.loadReportData(this.CountDetails);
              let successData = this.CountDetails.filter(item=>{
                return item.SuccessCount > 0
               })
               this.successCount = successData;
               let errorData = this.CountDetails.filter(item=>{
                return item.ErrorCount > 0
              })
              this.ErrorCount = errorData;
              let zeroReportData = this.CountDetails.filter(item=>{
                return item.RxCount === 0
               })
               this.RxCount = zeroReportData;
            }
        
          })
        }
  

}
async preview(val: any,bool?) {
  this.alreadyRead = !this.alreadyRead;
  const readNotification = {
    CreatedDtTm : bool? moment.utc(val.CreatedDtTm).local().format("MM/DD/YYYY hh:mm:ss a") :  val["NotificationDateTime"],
    StateName: bool ? val.StateName : val.State,
    fromDate: this.notificationDisplayFG.controls["fromDate"].value,
    toDate : this.notificationDisplayFG.controls["toDate"].value
  }
 this._rxService.detailsForDisplayNotificationCount(readNotification).subscribe(notificationCount=>{
if(notificationCount){
const payload = {
  CreatedDtTm : bool?  moment.utc(val.CreatedDtTm).local().format("MM/DD/YYYY hh:mm:ss a") : val["NotificationDateTime"],
  RxCount: val.RxCount,
  ErrorCount: val.ErrorCount,
  SuccessCount: val.SuccessCount,
  FailureCount: val.FailureCount,
  StateName:  bool ? val.StateName : val.State,
  NABPNo:this.nabpNum,
}
this._rxService.notAutoSubmittedData(payload).subscribe(resp=>{
if(resp){
this.previewPdf(resp);
this.onSearch();
}
})

}
 })

}
previewPdf(resp) {
  if (resp && resp.size > 0 && resp.type === "application/pdf") {
      const urlCreator = window.URL;
      this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
          urlCreator.createObjectURL(resp) + "#toolbar=0"
      );
  }

}
download(val){
  const payload = {
    CreatedDtTm : val["NotificationDateTime"],
    RxCount: val.RxCount,
    ErrorCount: val.ErrorCount,
    SuccessCount: val.SuccessCount,
    FailureCount: val.FailureCount,
    StateName: val.State,
    NABPNo:this.nabpNum,
}
 this._rxService.notAutoSubmittedData(payload).subscribe(resp=>{
if(resp){
  this.printNotif = resp;
  this.printReport();
}

})
}
printReport() {
  const newBlob = new Blob([this.printNotif], { type: "application/pdf" });
  const reader = new FileReader();
  reader.readAsDataURL(newBlob);
  reader.onloadend = function() {
      const base64data = reader.result;
      this._printServ.printPdf(base64data);
  }.bind(this);
}
openSuccess(){
this.submissionNotif = true;
this.controlFileErrorNY = false;
this.controlError = false;
}
openControlFileError(){
  this.submissionNotif = false;
  this.controlFileErrorNY = false;
  this.controlError = true;
}
openControlFileErrorForNY(){
  this.submissionNotif = false;
  this.controlFileErrorNY = true;
  this.controlError = false;
}
async searchForControlErrorData(val?){
  await this._formUtils.blurAndDelay();
  const resp = this._formUtils.checkDateErrors(this.notificationDisplayFG, "FromDate", "ToDate", 0);
 if (resp === "DATEISSUE") {
      this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
  } else if (resp === "INVALID") {
      this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
  } else {
    this.selectedFilter = val ? val : {Id: (this.notificationDisplayFG.controls["statusDropdwn"].value === 0 ? 0 : (this.notificationDisplayFG.controls["statusDropdwn"].value === 1 ? 1 : 2)),
     Name: (this.notificationDisplayFG.controls["statusDropdwn"].value === 0 ? resolveErrorEnum.ALL :( this.notificationDisplayFG.controls["statusDropdwn"].value === 1 ? resolveErrorEnum.Unresolved : resolveErrorEnum.Resolved))} ;
  this.selectedError = (val && val.Id) ? val.Id : this.notificationDisplayFG.controls["statusDropdwn"].value;
  const payload={
    fromDate: this.notificationDisplayFG.controls["FromDate"].value,
    toDate : this.notificationDisplayFG.controls["ToDate"].value,
    ErrorStatus: this.selectedError
  }
  this._rxService.dataOfcontrolErrorfile(payload).subscribe(resp=>{
    if(resp){
      this.errorResponse = resp;
      this.errors = resp['errors'];
      this.totalRecordsLength = resp['rxData'].length;
      this.resolvedCount = this.errors.filter(resp=>{
        return resp.ErrorUpdateStatus === 2
      })
      this.unResolvedCount = this.errors.filter(resp=>{
        return resp.ErrorUpdateStatus === 1
      })
      this.generateControlErrorWJ();
    }
  })
  }
}
generateControlErrorWJ() {
  this.controlErrorWJ = new CollectionView(
      this.errorResponse.rxData.map((ctrlFile, i) => {
          const k = {};
          k["RowId"] = i;
          k["Rx#"] = ctrlFile.Prescription.PrescNum;
          k["Ref#"] = ctrlFile.PrescReFill.ReFillNum;
          k["Fill#"] = this._cmnSvc.checkIsPartialFill(ctrlFile.PrescReFill, ctrlFile.Prescription)
          ? ctrlFile.PrescReFill.PartialFillNo : "";
          k["PrescRefillId"] = ctrlFile.PrescReFill.Id;
          k["Date Filled"] = moment.utc(ctrlFile.PrescReFill.FillDtTm).local().format("MM/DD/YYYY");
          k["Patient Name"] = ctrlFile.Patient.lastname + ", " + ctrlFile.Patient.firstname;
          k["Drug Name"] = ctrlFile.Drug.drugname + " " + ctrlFile.Drug.strength + " " + ctrlFile.Drug.drugformname;
          let errorObj = this.errorResponse.errors.find((err,j)=> err.RxNumber == ctrlFile.Prescription.PrescNum && err.RefillNumber == ctrlFile.PrescReFill.ReFillNum && i === j );
          if (errorObj) {
              k["Control Error"] = errorObj.Error;
              k["Id"] = errorObj.Id;
              k["ErrorUpdateStatus"] = errorObj.ErrorUpdateStatus
          } else {
              k["Control Error"] = "";
          }
          k["Resolved Date"] = errorObj && errorObj.ResolvedDtTm ? moment.utc(errorObj.ResolvedDtTm).local().format(MsgConfig.DATE_FORMAT) : "";
          k["Amount"] =  ctrlFile.PrescReFill.TotalAmt ? "$" + (parseFloat(ctrlFile.PrescReFill.TotalAmt + "")).toFixed(2) : "$0.00";
          k["Billed"] = ctrlFile.PrescReFill.BalDue ? "$" + (parseFloat(ctrlFile.PrescReFill.BalDue + "")).toFixed(2) : "$0.00";
          let paidPrice = 0;
          let hasPayRecrds = false;
          if (ctrlFile && ctrlFile.RxPay && ctrlFile.RxPay.length > 0) {
              hasPayRecrds = ctrlFile.RxPay.some(pay => pay.RecType !== "R");
              ctrlFile.RxPay.map(val => {
                  if (val.RecType !== "R" || (val.RecType === "R" && val.REJCodes === "70")) {
                      paidPrice = paidPrice + val.InsNetPaid;
                  }
              });
          }
          k["Insu Paid"] = !paidPrice ? "$0.00" : "$" + (paidPrice).toFixed(2);
          k["Copay"] = ctrlFile.PrescReFill.CoPay ? "$" + (parseFloat(ctrlFile.PrescReFill.CoPay + "")).toFixed(2) : "$0.00";
          k["Prescriber Name"] = ctrlFile && ctrlFile.Prescriber ? (ctrlFile.Prescriber.prescriberlastname + ", " + ctrlFile.Prescriber.prescriberfirstname) : "";
          k["NDC"] = this._formatsUtil.getNDCFormat(ctrlFile.Drug.ndc) ;
          k["Date Ordered"] = moment.utc(ctrlFile.PrescReFill.OrderDtTm).local().format("MM/DD/YYYY");
          k["Status"] = (ctrlFile.PrescReFill.StatusId === 4 || ctrlFile.Prescription.RxStatusId === 4) ? "T" :
          (ctrlFile.PrescReFill.StatusId === 2 || ctrlFile.Prescription.RxStatusId === 2 || ctrlFile.RxBill.StatusId === 6) ? "F" :
                  (ctrlFile.PrescReFill.StatusId === 3
                      || ctrlFile.Prescription.RxStatusId === 3) && ctrlFile.RxBill.StatusId === 2 ? "BI" :
                      (ctrlFile.RxBill.StatusId === 2 && ctrlFile.PrescReFill.StatusId !== 4
                          && ctrlFile.Prescription.RxStatusId !== 2) ? "B" :
                          ((ctrlFile.PrescReFill.StatusId === 3 || ctrlFile.Prescription.RxStatusId === 3) &&
                              ctrlFile.RxBill.StatusId === 1) ? "UI" : "U";
          let insurence = null;
          if (ctrlFile.Patient && ctrlFile.Patient.insurance && ctrlFile.Patient.insurance.length > 0) {
              insurence = ctrlFile.Patient.insurance.find(insuObj => insuObj.insuranceid == ctrlFile.RefillInsu.InsuId);
              // insurence = _.filter(ctrlFile.Patient.insurance, {
              //     insuranceid: +ctrlFile.RefillInsu.InsuId
              // })[0];
          }
          k["IsBilled"] = this._comnUtil.checkIsGreenBilled(ctrlFile, this.systemData);
          k["Ins."] = (insurence && insurence.insuType) ? insurence.insuType : "";
          k["IsPicked"] = ctrlFile.pickupRx && ctrlFile.pickupRx.IsPickedUp ? true : false;
          k["Qty"] = ctrlFile.PrescReFill.DispQty.toFixed(3);
          k["Days"] = ctrlFile.PrescReFill.SupplyDays;
          k["DrugClass"] = ctrlFile.Drug.drugclass;
          k["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(k);
          k["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(k);
          const rowId = k["RowId"] >= 0 ? k["RowId"] : "";
          const RxDetails = rowId >= 0 ?  this.errorResponse["rxData"][rowId] : null;
          k["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx", "hoverOver");
          return k;
      }));
}
routeToEditRx(item: any) {
let Ids = this.errors.filter(resp=>{
   return resp.Id === item.Id
  })
  const readNotification = {
    fromDate: this.notificationDisplayFG.controls["FromDate"].value,
    toDate : this.notificationDisplayFG.controls["ToDate"].value
  }
 this._rxService.detailsForDisplayNotificationCount(readNotification).subscribe(notifCount=>{
  if(notifCount) {
    this.notificationCountAfterPreview.emit(notifCount);
    this.selectedrxNo = item["Rx#"];
    this.selectedrxId = item["PrescRefillId"];
    if (this.checkHasPrivsToAccess(
        this.accessPrivCate.Rx,
        this.rxPrivs.EditRx)) {
            this.routeToRx(Ids);
    } else {
        this.openOveride("Rx", "EditRx", ["POST_Rx_GetRxDetails", "PUT_Rx"]);
    }
  }
 })
}
checkHasPrivsToAccess(category: string, privType: string) {
  return this._privMaskGuard.canActivate(category, privType);
}
async routeToRx(item?) {
  let rxInfo: any = await this._nrxUtls.getandStoreRxInfo(+this.selectedrxNo, +this.selectedrxId, false, null, null, null, true,null, null, null, true);
  if (rxInfo) {
      rxInfo = this._nrxUtls.editRxPatchValues(rxInfo);
      this._nrxUtls.createPatchStoreRxFG(rxInfo);
      this._nrxUtls.getCommonEndPoints(rxInfo);
      const modalRef = this._modalService.open(
          EdRxComponent,
          {
              backdrop: false,
              size: "lg",
              windowClass: "md-xx-lg comp--modal",
              keyboard: false
          }
      );
      modalRef.componentInstance.errorEditInfo = item ? item[0] : "";
      modalRef.componentInstance.isFromCntrlFileErr = true;
      modalRef.componentInstance.CloseEditMode
          .subscribe(x => {
              modalRef.close();
              this._commonStore.notifCount$.subscribe(resp=>{
                this.notificationCountAfterPreview.emit(resp);
                this.searchForControlErrorData();
                })

          });

      modalRef.componentInstance.CloseAndReopen
          .subscribe(d => {
              modalRef.close();
              if (d) {
                  this.selectedrxNo = d["rxNo"];
                  this.selectedrxId = d["rfId"];
                  this.routeToRx();
              }
          });
  }
}
openOveride(category, type, endpoint?) {
  this.categoryId = category;
  this.privType = type;
  this.openOverride = true;
  this.overrideEndpoint = endpoint;
}
init(flex: wjcGrid.FlexGrid) {
  this.rxWijmo = flex;
}
itemFormatter = (panel, r, c, cell) => {
  const row = panel.rows[r];
  if (row.dataItem) {
      cell.style.background = "";
      if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
        const rowId = row && row.dataItem ? row.dataItem["RowId"] : "";
        const RxDetails = rowId >= 0 ?this.errorResponse["rxData"][rowId] : null;
        const colors = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx");
          if(colors && colors.bg && colors.color) {
              cell.style.background = colors.bg;
              cell.style.color = colors.color;
          } else {
              cell.style.background = "#FFF";
              cell.style.color = "#000";
          }
          cell.style.fontWeight = "bold";
          cell.style.paddingLeft = "1rem";
      } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
          const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
          if(colors && colors.bg && colors.color) {
              cell.style.background = colors.bg;
              cell.style.color = colors.color;
          } else {
              cell.style.background = "#FFF";
              cell.style.color = "#000";
          }
          cell.style.fontWeight = "bold";
          cell.style.paddingLeft = "1rem";
      } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Status") {
          const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
          if(colors && colors.bg && colors.color) {
              cell.style.background = colors.bg;
              cell.style.color = colors.color;
          } else {
              cell.style.background = "#FFF";
              cell.style.color = "#000";
          }
          cell.style.fontWeight = "bold";
          cell.style.paddingLeft = "1rem";
      } else {
          cell.style.backgroundColor = "#FFF";
          cell.style.color = "#000";
          cell.style.fontWeight = "normal";
          cell.style.textAlign = "initial";
      }
  }
}
toggleColorLegend() {
  this._alertSer.showHelpText({RxStatus: true, DrugName: true, Status: true});
}
searchForControlErrorDataOnload(){
  this.selectedFilter = {Id:1, Name:'Unresolved'}
  const payload={
    fromDate: null,
    toDate : null,
    ErrorStatus: 1
  }
  this.notificationDisplayFG.controls["statusDropdwn"].patchValue(1);
  this._rxService.dataOfcontrolErrorfile(payload).subscribe(resp=>{
    if(resp){
      this.notificationDisplayFG.controls["FromDate"].patchValue((resp['ErrorFileMinDate'] && (resp['ErrorFileMinDate'] != '0001-01-01T00:00:00')) ?(moment.utc(resp['ErrorFileMinDate']).local().format(
        "MM/DD/YYYY")):this.yesterday);
        this.notificationDisplayFG.controls["ToDate"].patchValue((resp['ErrorFileMaxDate'] && (resp['ErrorFileMaxDate'] != '0001-01-01T00:00:00')) ?(moment.utc(resp['ErrorFileMaxDate']).local().format(
          "MM/DD/YYYY")):this.today)
      this.errorResponse = resp;
      this.errors = resp['errors'];
      this.totalRecordsLength = resp['rxData'].length;
      this.resolvedCount = this.errors.filter(resp=>{
        return resp.ErrorUpdateStatus === 2
      })
      this.unResolvedCount = this.errors.filter(resp=>{
        return resp.ErrorUpdateStatus === 1
      })
      this.generateControlErrorWJ();
    }
  })
}
searchNYErrors(){
  let payload:any;
  payload = {
    FromDate: null,
    ToDate:null,
    ErrorCode :null,
    PatientId :null,
    StateId :null
  }
  this._rxService.showNYErrors(payload).subscribe(resp=>{
  this.newYorkErros = resp;
  this.displayingNYErrors(resp);
  })
}
displayingNYErrors(data){
this.showNYErrorsWJ = new CollectionView(
  data.map((err,i)=>{
    const p = {}
    p["Rx#"] = err.PrescNum ? err.PrescNum : "" ;
    p["Ref#"] = err.ReFillNum ?err.ReFillNum  :"";
    p["Fill#"] = err.PartialFillNum ? err.PartialFillNum :"";
    p["Patient Name"] = err.PatientName ? err.PatientName : "";
    p["Drug Name"] = err.DrugName ? err.DrugName : "";
    p["NDC"] = err.DrugNDC ? err.DrugNDC : "";
    p["Fill Date"] = err.DateFilled ? err.DateFilled :"";
    p["Prescriber Name"] = err.Prescriber ? err.Prescriber : "";
    p["Ins."] = err.Insurance ? err.Insurance : "";
    p["Error Code"] = err.FieldCode ? err.FieldCode : "";
    p["Error Description"] = err.FieldDescription ? err.FieldDescription : "";
    p["Value Provided"] = err.ValueProvided ? err.ValueProvided : "";
    p["Comment"] = err.Comment ? err.Comment : "";
    p["Submitted Date"] = err.DateSubmitted ? err.DateSubmitted : "";
    return p;
  }))
}
beforeTabChange(event) {
  this.IsActiveTabName = event;
}
}
