import { Patient } from "./../../../models/patient-Info.model";
import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    HostListener,
    ChangeDetectorRef,
    OnDestroy,
    ElementRef
} from "@angular/core";
import { Router } from "@angular/router";
import { RxUtils, PatchRXFGUtil, NRxUtils, RxBillingUtil, CommonUtil, ValidateFormFieldsUtils, NewAuditUtils, TransmissionUtils } from "src/app/utils";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { RxService, AlertService, CommonService, TransmissionService, RphDashboardService,
     PatPreDrugModalService, DrugPickVerificationService, RphVerificationService, ControlFileService, LoaderService, RxTransferService } from "src/app/services";
import {
    RxDeliveryInfoComponent,
    BillToCashComponent
} from "../../shared";
import { CollectionView } from "@grapecity/wijmo";
import { PrintErxService } from "src/app/services/print-erx.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
    RxInputParams,
    AccCategoryE,
    RefillRxE,
    RxE,
    RxDateInputParams,
    EPatient,
    EditRxE,
    Rx1E,
    RxNotesE,
    SystemData,
    RxModel,
    RxTransferE,
    ERxDetailsViewModel,
    ErxDetailsData,
    PaginationModel
} from "src/app/models";
import { RxOrigin } from "src/app/models";
import { RefillProgressStatsComponent } from "../../shared/refill-progress-stats/refill-progress-stats.component";
import { PrivMaskGuard } from "src/app/guards";
import { MsgConfig } from "../../../app.messages";
import * as moment from "moment";
import { retry, startWith, switchMap, take, takeUntil } from "rxjs/operators";
import { TRRxOnlyComponent } from "../../transmission";
import { combineLatest, Subscription, Observable, Subject, timer } from "rxjs";
import { NRxSaveUtils } from "src/app/utils/nRx-save.util";
import { CommonStore, RxStore } from "src/app/store";
import { EdRxComponent } from "../../ed-rx/ed-rx.component";
import { CommonPrintService } from "src/app/services/common-print.service";
import { RecallDeleteRxService } from "src/app/services/recall-delete-rx.service";
import { PPopupsComponent } from "../../rx-shared/rx-grid/ppd/npatient/p-popups/p-popups.component";
import * as Sentry from "@sentry/browser";
import { CommonWarnorconfirmPopupComponent } from "../common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { LabelQueueService } from "src/app/services/label-queue.service";
import { Location } from "@angular/common";
import { VerificationTabsListComponent } from "../../verification-tabs/verification-tabs-list/verification-tabs-list.component";
import { environment } from "src/environments/environment";
@Component({
    selector: "app-actions",
    templateUrl: "./actions.component.html"
})
export class ActionsComponent implements OnInit, OnDestroy {
    paginationModel: PaginationModel = new PaginationModel();
    subscription: Subscription;
    @Output()
    ExpiryRefillsDate: EventEmitter<any> = new EventEmitter();
    @Output()
    emitRxForNewRxRequest: EventEmitter<any> = new EventEmitter();
    @Output()
    emitShowRxHistory: EventEmitter<any> = new EventEmitter();
    @Input() isFromNewRxRequest: string;
    unsubscribe$: Subject<void> = new Subject();
    @Output() emitPickedRx = new EventEmitter();
    @Output() closePopUp = new EventEmitter();
    TotalRxInfo: any[] = [];
    TotalSelctedDrugVerifactionRxs: any[] = [];
    totalSelectedRphRxs: any[] = [];
    deletRxRecords: any[] = [];
    printRxRecords: any[] = [];
    printRefWJ: CollectionView;
    singleRxPerPage = false;
    DrugMissingForm: FormGroup;
    dropdownData: any;
    largerValuesForDisplay: any;
    formSubmitted = false;
    modalRef: NgbModalRef;
    spiNumber: any;
    hisFrm: any;
    selectedRxFrQ: any[] = [];
    activeScreen: string;
    categoryId: any;
    privType: any;
    openOverride: boolean;
    overrideFor: any;
    patientInfo: EPatient;
    editRxPrivs: any;
    notes: any;
    rx1Privs: any;
    rxNotes: any;
    warningInfo: string;
    overrideEndpoint: any;
    hasRefRxPrivs: boolean;
    hasDeleteRxPrivs: boolean;
    hasQuickRxPrivs: boolean;
    hasRxNoteChangPriv: boolean;
    warningMsg: string;
    confirmModel: any;
    confirmModalName: string;
    subscriptions = new Subscription();
    showDrugDescription: boolean;
    drugIngredientsList: any;
    processingMissingDrug: any;
    systemInfo$: Observable<SystemData>;
    isRefillReq: boolean;
    IseRxTRxREQType: string = "";
    transfData: { Type: string; rxNo: number; prescRefillId: number; RefillNo: number, FillNo: number };
    showMarkAsVerif = false;
    warnMsg: any;
    modalRefWarn: NgbModalRef;
    @ViewChild("invalidRx", { static: true })
    invalidRx: any;
    @ViewChild("alreadyVerified", { static: true })
    alreadyVerified: any;
    selectedRxFTrans: any[];
    showRefillQueRxs: any[] = [];
    issueRxNos: string;
    transModelRef: NgbModalRef;
    transCompltedList: any;
    grpTransData$: any;
    reset$ = new Subject();
    resetCmpl$ = new Subject();
    selectedRxNoFTrans: any[];
    enableTrans: boolean;
    enableBatch: boolean;
    UnbilledRxs: any[];
    NotUnbilledRxs: any[];
    verifiedAlready: any;
    warningMsgDeceased: string = "";
    warnDuplicate: string;
    DEAProcessingData: any[] = [];
    DEARxInfo: any;
    gpBatchId = 0;
    @Output()
    emitCreateNewRx: EventEmitter<any> = new EventEmitter();
    rxVerf = false;
    fromActn: any;
    isFiledpopupOpen: boolean;
    afterFileDet: any;
    isDphOrRphOrRx: any;
    model: any;
    orginal: number;
    beforeDPV: number;
    beforeRph: number;
    disContinueOpenFr: string;
    createNewRxFlow: any = [];
    warningMessageForTrans: any = [];
    drugInfo: any;
    warningMessageDis: string = "";
    othrverfchcks: boolean =false;
    totalSelectedRphRxsshifted: any;
    notYetVerf: boolean = false;
    oldInfo: any;
    subscription1: Subscription;
    authRefFrmOldRx: any;
    transmissionRejctedList: any;
    resetObs: Subscription;
    finalValidList: any[];
    objList: any[];
    grpTransOutRxs = [];
    controlRxResp: any;
    quickRefillforHoldRx: boolean;
    startQuickRefill: boolean;
    selectedReFillNums: any;
    selectedRx: number;
    refillObj= {IsForPartialFill : false}
    Isfromrefill: any;
    rxrefDetails: any;
    currentRefillData: any;
    refDetails: any;
    openFrm:any
    @Input()
    set RxInfo(ri: any) {
        if (ri && ri.length > 0) {
            this.TotalRxInfo = ri;
            this.TotalSelctedDrugVerifactionRxs = ri;
            this.totalSelectedRphRxs = ri;
            this.printRxRecords = ri;
            this.createNewRxFlow = ri;
            this.rxInfo = ri[0];
            this.rxNum = (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.PrescNum)
             ? this.rxInfo.Prescription.PrescNum : 0;
        } else {
            this.TotalRxInfo = [];
            this.TotalSelctedDrugVerifactionRxs = [];
            this.totalSelectedRphRxs = [];
            this.printRxRecords = [];
            this.rxInfo = [];
        }
    }
    @Input() openModalFr: string;
    @Input() newRxInfo: any;
    @Input() SearchParams: any;

    @Input()
    set PatientID(pi: number) {
        this.patientID = pi;
    }

    @Input()
    set PatientInfo(pi: EPatient) {
        this.patientInfo = pi;
    }

    @Input()
    set ActiveScreen(as: string) {
        if (as) {
            this.activeScreen = as;
        }
    }

    @Input()
    set PageFrm(pi: any) {
        this.hisFrm = pi;
    }



    @Output()
    EmitCanDeactivate = new EventEmitter<any>();


    @ViewChild("rxVerification", { static: true })
    rxVerification: any;

    @ViewChild("WARNINGMSGS", { static: true })
    WARNINGMSGS: any;

    @ViewChild("TRANSWARNMSGS", { static: true })
    TRANSWARNMSGS: any;

    @ViewChild("Pharmaverfication", { static: true })
    Pharmaverfication: any;

    @ViewChild("warnPharamVerified", { static: true })
    warnPharamVerified: any;

    @ViewChild("CopayPaidStatusModal", { static: true })
    CopayPaidStatusModal: any;

    @ViewChild("SPINoModal")
    SPINoModal: any;

    @ViewChild("deceased", { static: true } )
    deceased: any;

    @ViewChild("AlreadyVerif")
    AlreadyVerif: any;

    @ViewChild("DawValid")
    DawValid: any;

    @ViewChild("MissingFields")
    MissingFields: any;

    @ViewChild("warnBilledRx", { static: true })
    warnBilledRx: any;

    @ViewChild("QueuC", { static: true })
    QueuC: any;

    @ViewChild("PrintRef")
    PrintRef: any;

    @ViewChild("AddSpi")
    AddSpi: any;

    @ViewChild("RxNOTES", { static: true })
    RxNOTES: any;

    @ViewChild("RXINMODAL", { static: true })
    RXINMODAL: any;

    @ViewChild("warnTransRx", { static: true })
    warnTransRx: any;

    @ViewChild("GRPTRANSMODAL", { static: true })
    GRPTRANSMODAL: any;

    @ViewChild("Rxexists", {static: true})
    Rxexists: any;

    @ViewChild("warnBatchEdit", { static: true})
    warnBatchEdit: any;

    @ViewChild("duplicateRx", {static: true})
    duplicateRx: any;

    @ViewChild("ResubmitConfirm", {static: true})
    ResubmitConfirm: any;

    @ViewChild("RxDiscontinued", {static: true})
    RxDiscontinued: any;

    @ViewChild("multipleSameRxTrOut", {static: true})
    multipleSameRxTrOut: any;

    @ViewChild("CommonWarning", {static: true})
    CommonWarning: any;

    @ViewChild("WARNINGINFO", { static: true })
    WARNINGINFO: ElementRef;

    @ViewChild("DiscontinuedRx", { static: true})
    DiscontinuedRx: any;
    @ViewChild("inActiveInsurance", {static: true})
    inActiveInsurance: any;

    patientID: number;
    expDate: number;
    showExpiredRx = true;
    selectedRxId: number;
    pageFrom = "";
    activeModal: any;
    rxInfo: any;
    modalRefSM: any;
    pvrfStatus: string = null;
    copayPaid: string = null;
    copayStatus: boolean;
    rxNum: number;
    show = false;
    openPopUp: boolean = false;
    dpv: boolean = false;
    rph: boolean = false;
    rxInputParams: RxInputParams;
    accessPrivCate: any;
    refillRxPrivs: any;
    rxPrivs: any;
    tranferPrivs: any;
    selectedRxsFrReg: any[] = [];
    queueNotToInsert: any[] = [];
    AlreadyVerPopUp: any;
    checkDawModal: any;
    checkSPIModal: any;
    missingPopUP: any;
    addSPIModal: any;
    modalRef1: any;
    refillsinserted: any;
    totalDeceasedPats = 0;
    dateParams: RxDateInputParams;
    fileFG: FormGroup;
    deferredReasonList = [];
    unRxVerifiedRecords: any = [];
    noneReason = [
        {
            Id: null,
            Name: "None",
            TenantId: null,
            IsActive: true,
            CreatedDtTm: null,
            ModifiedDtTm: null
        }
    ];
    verfData: any;
    popUpOpenedFor: string;
    isAfterPopup: boolean = false;
    refillIdlist = [];
    notAbleToTrans = [];
    ableToTrans = [];
    ableToTransCopy = [];
    discontinuedList = [];
    isShowDrugValuesPop: boolean = false;
    selectedBenefitCheckType: string;
    selectedRxForBenefitCheck: object;
    withDrugPickVerfList: any[] = [];
    withOutDrugPickVerfList: any = [];
    notDrugPickVerfRecords: any = [];
    modalRefTrOut: NgbModalRef;
    drupPickClicked: boolean = false;
    alreadyshifted: boolean = false;
    // alreadyshiftedWithOut: boolean = false;
    showRxTransferOut: boolean;
    showRxTransferIn: boolean;
    holdRx: boolean;
    actvmodal: string;
    warningMessage: string;
    transferdetbeforediscont: any;
    patchTransferInfo: any;
    holdRespType: any;
    holdRxData: boolean;
    clearData: boolean;
    modalRefInact: any;
    rxDetails: any;
    openBillToPopup: boolean;
    dataList: any;
    refillInsu: any;
    rphverfChckd = false;
    rphClicked = false;
    isRxDiscont = false;
    withoutAlreadyshifted = false;
    alreadyChckdDPVMandat = false;
    fromRoutRph = false;
    rxVerified = false;
    directRxVerf = false;
    rxFG: FormGroup;
    selctIndex: any;
    totalRxsCountForRxVerf: any;
    reqRxVerfStng: number;
    workFlwPatStng: number;
    transSwitchDownList: any;
    currentIndex = 0;
    IseRxTransfer;
    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if(event.altKey && (!this.activeModal)  &&  !this._modalService.hasOpenModals() && ((this._location.path()).includes("/rx") || 
        (this._location.path()).includes("/PatientHistory")) && !(sessionStorage.getItem("showDefaultLabel"))) {
            if (event.which === 79 ) { // Patient Notes Log ->  'o'
                this.openActionModal('PatientNotes');
              }  else if (event.which === 71) { //Rx Notes Log  -> 'g'
                  this.openRxNotes()
              }  else if (event.which === 73) { //XFRIN -> 'i'
                  this.openActionModal('XFRIN');
              }  else if (event.which === 85) { //XFROUT  -> 'u'
                  this.openActionModal('XFROUT');
              }  else if (event.which === 77 && this.reqRxVerfStng) { //RxVerification -> 'M'
                this.openActionModal('RxVerification')
            }  else if (event.which === 86) { //DrugPickVerification -> 'v'
                this.openActionModal('DrugPickVerification');
            }  else if (event.which === 87) { //RphVerification     -> 'W'
                this.openActionModal('RphVerification')
            } else if (event.which === 66 && this.showMarkAsVerif) { //MarkAsVerf -> 'B'
                this.openActionModal('MarkAsVerf');
            }
        } 
        if (event.which === 27) {
            if (this.actvmodal === "popUp") {
                this.clearRxdata();
            }
        }
    }
    @HostListener("document:keydown.alt.y", ["$event"])
    keyEventaltY(event: KeyboardEvent) {
        if (event.altKey && ((this.openModalFr && this.openModalFr !== 'rph') || this.isFromNewRxRequest)) {
            event.preventDefault();
            this.emitPickRx();
          } 
    }

    constructor(
        private _router: Router,
        private _rxUtils: RxUtils,
        private _modalService: NgbModal,
        private _rxSvc: RxService,
        private _alertSvc: AlertService,
        private _erxServ: PrintErxService,
        private _cdr: ChangeDetectorRef,
        public _commonServ: CommonService,
        private _privMaskGuard: PrivMaskGuard,
        private _rxFGPat: PatchRXFGUtil,
        private _commUtil: CommonUtil,
        private _nrxUtls: NRxUtils,
        private _saveRxUtls: NRxSaveUtils,
        private _commonStore: CommonStore,
        private _transmsnServ: TransmissionService,
        private _commonPrintSer: CommonPrintService,
        private _rxBillUtil: RxBillingUtil,
        private _fb: FormBuilder,
        private _DfrdReasonServ: RphDashboardService,
        private _formUtils: ValidateFormFieldsUtils,
        private _drgVerifyServ: DrugPickVerificationService,
        private _patPreDrugModalServ: PatPreDrugModalService,
        private _rphService: RphVerificationService,
        private _contrlSer: ControlFileService,
        private _recallSer: RecallDeleteRxService,
        private _nwAudtUtl: NewAuditUtils,
        private _loadrServ: LoaderService,
        private _modalSvc: NgbModal,
        private _rxSer: RxTransferService,
        private _rxStore: RxStore,
        private _transUtils: TransmissionUtils,
        private _transServ: TransmissionService,
        private _labelQueueService: LabelQueueService,
        private _location: Location,
    ) {
        this.editRxPrivs = EditRxE;
        this.dateParams = {
            StartDate: null,
            EndDate: null
        };
        this.dateParams.StartDate = moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD");
        this.dateParams.EndDate = moment()
            .subtract(0, "days")
            .format("YYYY-MM-DD");
        this.accessPrivCate = AccCategoryE;
        this.refillRxPrivs = RefillRxE;
        this.rxPrivs = RxE;
        this.tranferPrivs = RxTransferE;
        this.rx1Privs = Rx1E;
        this.rxNotes = RxNotesE;
        this.systemInfo$ = this._commonServ.systemData$;

    }

    ngOnInit() {
        this.IseRxTransfer = environment.IseRxTransferRequest;
        this.rxInputParams = {
            patientId: null,
            prescriberId: null,
            drugId: null,
            prescNum: null,
            refillNum: null,
            remarks: null,
            rxId: null,
            prescRefillId: null,
            PartialfillNum: null,
        };
        if (this.accessPrivCate) {
            this.hasRefRxPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.Rx, this.rxPrivs.RefillRx);
            this.hasQuickRxPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.Rx, this.rxPrivs.QuickRefill);
            this.hasRxNoteChangPriv = this.checkHasPrivsToAccess(this.accessPrivCate.RxNotes, this.rxNotes.Change);
            this.hasDeleteRxPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.EditRx, this.editRxPrivs.DeleteRx);
        }

        this._transmsnServ.isTransmnsCmpltd$.subscribe(x => {
            if (x === "Actions") {
                this.closeModalAndNext();
            }
        });
        this.showMarkAsVerif =
        this._commonServ.getSetttingValue("WorkFlowSettings", "Mark_Verify_by_the_Pharmacist") === "1" ? true : false;
        this.reqRxVerfStng = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification"), 0);
        this.workFlwPatStng = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings",
         "Require_Rx_Verification_for_workflow_patient_only"), 0);
        this._commonStore.rfProcessStage$.subscribe(rfStage => {
            let rfData = Object.assign({}, this._commonStore.rxSelectedForRf$["source"]["value"]);
            if (rfStage === "Pop" && rfData["quickRefillList"]?.length && rfData.frm == 'RxHist') {
                this.TotalRxInfo = rfData.quickRefillList;
                this.activeModal = 'QuickRefills';
            } 
        });
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscription1) {
            this.subscription1.unsubscribe();
        }
    }

    closeBatch() {
        this.enableBatch = false;
        this.loadRxHistory();
    }

    checkIsRxFilable() {
        if (this.TotalRxInfo && this.TotalRxInfo.length) {
            this.isFiledpopupOpen = true;
        } else {
            this._alertSvc.error("Please select atleast one Rx.");
            return;
        }
    }

    async closeMultipleFileRx(type) {
        if (type) {
            this.afterFileDet = type;
            await this.saveAuditOnFileChange();
        }
        this.isFiledpopupOpen = false;
        this.loadRxHistory();
    }

    saveAuditOnFileChange() {
        const unbilledRx = this.afterFileDet["unbilledRxs"];
        const fileFG: FormGroup = this.afterFileDet["fileFG"];
        const defReasonName = this.afterFileDet["defferedReasonName"];
        if (unbilledRx && unbilledRx.length) {
            const result = unbilledRx.map((ele: any) => {
                const k = {};
                k["original"] = [{ name: "Status", value: "U" },
                { name: "Defer DueDate", value: null }, { name: "Deferred Reason", value: null }];
                k["changed"] = [{ name: "Status", value: "D" },
                { name: "Defer DueDate", value: moment(fileFG.value["dueDateTime"]).format("MM/DD/YYYY") },
                { name: "Deferred Reason", value: defReasonName }]; // fileFG.value["deferredReason"]
                k["action"] = "EditRx";
                k["entity"] = "Rx";
                k["entityid"] = ele["PrescReFill"]["Id"];
                return k;
            });
            this._nwAudtUtl.auditWithMultiEmtity(result);
        }
    }

    getRxId(val: number) {
        this.selectedRxId = val;
    }
    closeConfirmModal() {
        if (this.confirmModel) {
            this.confirmModel.close();
            this.confirmModel = null;
        }
        // document.getElementById("rxInsuId").getElementsByTagName("input")[0].focus();

    }
    routeToRx() {
        if (this.checkHasPrivsToAccess(
            this.accessPrivCate.Rx,
            this.rxPrivs.RxEntry)) {
            this.routeToRxWithPrivs();
        } else {
            this.openOveride("Rx", "RxEntry");
        }
    }

    async routeToRxWithPrivs() {
        if(!(this.createNewRxFlow) || (this.createNewRxFlow && this.createNewRxFlow.length === 0)) {
            this._alertSvc.error("Please Select Alteast one Rx.");
        } else if (this.createNewRxFlow && this.createNewRxFlow.length > 1) {
            this._alertSvc.error("Please Select Only one Rx.");
        } else {
                const rxInfo = await this._commonServ.getRxDetailsByPrescNos(this.createNewRxFlow[0].Prescription.PrescNum, this.createNewRxFlow[0].PrescReFill.Id,
                     false, null, false, false, false, true).toPromise();
                if(rxInfo && rxInfo[0]){
                const selectedrxInfo = Object.assign({}, rxInfo[0]);
                    if (selectedrxInfo) {
                        selectedrxInfo["RxOtherAmount"] = null;
                        selectedrxInfo["RxPay"] = null;
                        selectedrxInfo["PrescReFill"]["FillDtTm"] = moment(new Date()).format("MM/DD/YYYY");
                        selectedrxInfo["PrescReFill"]["IsNewPrescRefill"] = false;
                        selectedrxInfo["PrescReFill"]["ReFillNum"] = 0;
                        selectedrxInfo["Prescription"]["PrescNum"] = null;
                        selectedrxInfo["PrescReFill"]["StatusId"] = 1;
                        selectedrxInfo["PrescReFill"]["NumberOfFills"] = 0;
                        selectedrxInfo["PrescReFill"]["GroupTransStatus"] = "NotTransmitted";
                        selectedrxInfo["Prescription"]["RxStatusId"] = 1;
                        selectedrxInfo["RxBill"]["StatusId"] = 1;
                        selectedrxInfo["RefillInsu"]["InsuPriId"] = 1;
                        selectedrxInfo["PrescReFill"]["Id"] = 0;
                        selectedrxInfo["PrescReFill"]["PrescId"] = 0;
                        selectedrxInfo["Prescription"]["Id"] = 0;
                        selectedrxInfo["PrescReFill"]["PartialFillNo"] = 1;
                        selectedrxInfo["RefillInsu"]["RefillId"] = 0;
                        selectedrxInfo["NotePopupscreenType"] = "cn";
                        selectedrxInfo["PrescReFill"]["IsRxHold"] = false;
                        selectedrxInfo["PrescReFill"]["ImmunizationId"] = null;
                        selectedrxInfo["PrescReFill"]["DispQty"] = selectedrxInfo["PrescReFill"]["OrdQty"];
                        selectedrxInfo["PrescReFill"]["SupplyDays"] = selectedrxInfo["PrescReFill"]["OrderDays"];
                        selectedrxInfo["drugPickVerif"]= [];
                        selectedrxInfo["rPHVerifs"]= [];
                        selectedrxInfo["drugPickVerificationStatus"] = null;
                        selectedrxInfo["rph1VerificationStatus"] = null;
                        selectedrxInfo["rph2VerificationStatus"] = null;
                        selectedrxInfo["PrescReFill"]["IsEquivalentDisp"] = false;
                        selectedrxInfo["eRxDetailsViewModel"] = new ERxDetailsViewModel();
                        selectedrxInfo["erxDetailsInRx"] = new ErxDetailsData();
                        selectedrxInfo["PrescReFill"]["IsHOADefined"] = false;
                        selectedrxInfo["PrescReFill"]["IsIou"] = false;
                        this.rxFG = await this._nrxUtls.createPatchStoreRxFG(null);
                        this.rxFG.controls["isNewFrmExisting"].patchValue(true);
                        if (this._router.url && this._router.url.includes("eprx/PatientHistory")) {
                            this.rxFG.controls["routeFrom"].patchValue("eprx/PatientHistory/" + selectedrxInfo["Patient"]["patientid"]);
                        } else if (this._router.url && this._router.url.includes("eprx/rx?fm=nr")) {
                            this.rxFG.controls["routeFrom"].patchValue(null);
                        } else {
                            this.rxFG.controls["routeFrom"].patchValue(this._router.url);
                        }
                        this._rxFGPat.patchRxInfo(selectedrxInfo, this.rxFG);
                        this._nrxUtls.getCommonEndPoints(selectedrxInfo);
                        this.rxFG.controls["rxType"].patchValue("nr");
                        this.rxFG.controls["PrescReFill"].patchValue({IsControlSubmitted : null});
                        // this.rxFG.controls["authRefFrmOldRx"].patchValue(selectedrxInfo["Prescription"]["ReFillNum"]);
                    }
                }
                if (this.openModalFr) {
                    this.closePopUp.emit(null);
                }
                if (this.activeScreen !== "Rx") {
                    this._router.navigate(["/eprx/rx"], {
                        queryParams: { fm: "nr" }
                    });
                } else {
                    this.emitCreateNewRx.emit(false);
                    // create New rx from Rx History using 'Create New Rx' button Patient is going to InProgress. To resolve this issue tryed cdr.DetectChanges(), NgZone() not working.
                    // setTimeOut() is needed.
                    setTimeout(() => {
                        this._router.navigate(["eprx/rxnav/rxhist"]);
                    }, 0);
                }
            }
    }

    routeToEditRx() {
        if (this.checkForRxSelect()) {
            const selectedList = Object.assign([], this.TotalRxInfo);
            if (selectedList && selectedList.length > 1) {
                let hasBilled = false;
                selectedList.map((data, i) => {
                    const status = this._commUtil.getRxStatus(data.Prescription.RxStatusId,
                        data.PrescReFill.StatusId, data.RxBill.StatusId);
                    if (status && (status === "BI" || status === "B" || status === "T")) {
                        hasBilled = true;
                        const index = this.TotalRxInfo.findIndex(v => v["PrescReFill"]["Id"] === data["PrescReFill"]["Id"]);
                        this.TotalRxInfo.splice(index, 1);
                    }
                });
                if (hasBilled) {
                    this._modalService.open(this.warnBatchEdit, {centered: true, backdrop: false, windowClass: "large--content"});
                } else {
                    this.intiateEditAfterChecking();
                }
            } else {
                this.intiateEditAfterChecking();
            }
        }
    }

    intiateEditAfterChecking() {
        if (this.TotalRxInfo && this.TotalRxInfo.length > 1) {
            this.enableBatch = false;
            this._cdr.detectChanges();
            this.enableBatch = true;
        } else if (this.TotalRxInfo && this.TotalRxInfo.length === 1) {
            this.enableBatch = false;
            if (this.checkHasPrivsToAccess(
                this.accessPrivCate.Rx,
                this.rxPrivs.EditRx)) {
                this.routeToEditRxWithPrivs();
            } else {
                this.openOveride("Rx", "EditRx", ["POST_Rx_GetRxDetails", "PUT_Rx"]);
            }
        } else {
            this.enableBatch = false;
            this.loadRxHistory();
        }
    }

    async routeToEditRxWithPrivs(rxNo?: any, pRfId?: any, val?: any) {
        this.rxInfo = this.TotalRxInfo && this.TotalRxInfo.length ? this.TotalRxInfo[0] : this.rxInfo;
        const rxNum = rxNo ? +rxNo : +this.rxInfo.Prescription.PrescNum;
        const rfId = pRfId ? pRfId : this.rxInfo.PrescReFill.Id;
        let rxInfo: any = await this._nrxUtls.getandStoreRxInfo(+rxNum, rfId, false, null, null, null, true,null, null, null,true);
        if (rxInfo) {
            rxInfo = this._nrxUtls.editRxPatchValues(rxInfo);
            this._nrxUtls.createPatchStoreRxFG(rxInfo);
            await this._nrxUtls.getCommonEndPoints(rxInfo);
            const modalRef = this._modalService.open(
                EdRxComponent,
                {
                    backdrop: false,
                    size: "lg",
                    windowClass: "md-xx-lg comp--modal",
                    keyboard: false
                }
            );
            modalRef.componentInstance.SelectedValue = ((val && val === "false")) ? val : "true";
            modalRef.componentInstance.openFrom = "PatHis";
            modalRef.componentInstance.rxFG = this.rxInfo as FormGroup;
            modalRef.componentInstance.largerValuesForDisplay = this.largerValuesForDisplay;
            modalRef.componentInstance.isFromLargeValues = rxNo? false: true;


            modalRef.componentInstance.CloseEditMode
                .subscribe(x => {
                    modalRef.close();
                    this.loadRxHistory();
            });
            modalRef.componentInstance.CloseAndReopen
            .subscribe(d => {
                modalRef.close();
                if (d) {
                    this.routeToEditRxWithPrivs(d["rxNo"], d["rfId"], d["isNPPatientValue"]);
                } else {
                    this.loadRxHistory();
                }
            });
            return;
        }
    }

    openTRRXModal() {
        if (+this.rxInfo.PrescReFill.StatusId === 2) {
            this.warningMsg = "Filed Rx Cannot be Transmitted.";
            this.confirmModel = this._modalService.open(this.WARNINGMSGS, {centered:true, keyboard: false, windowClass: "large--content" });
            this.confirmModalName = "WARNINGMSGS";
            document.getElementById("btnOK").focus();
       } else if (this.rxInfo.PrescReFill.GroupTransStatus === "Pending" || this.rxInfo.PrescReFill.GroupTransStatus === "Completed" ||
       this.rxInfo.PrescReFill.GroupTransStatus === "Transmitting") {
            this.warningMsg = "Please review previously transmission result for this Rx.";
            this.confirmModel = this._modalService.open(this.WARNINGMSGS, {centered: true, keyboard: false, windowClass: "large--content" });
            this.confirmModalName = "WARNINGMSGS";
            document.getElementById("btnOK").focus();
        } else if (this.rxInfo.Prescription.IsDiscontinued) {
            this.warningMsg = "Discontinued Rx cannot be transmitted.";
            this.confirmModel = this._modalService.open(this.WARNINGMSGS, {centered: true, keyboard: false, windowClass: "large--content"});
            this.confirmModalName = "WARNINGMSGS";
            document.getElementById("btnOK").focus();
        } else {
            let insuList = null;
            if (this.rxInfo.Patient.insurance) {
                insuList = this.rxInfo.Patient.insurance.filter((ins: any) =>
                    ins.insuranceid === this.rxInfo.RefillInsu.InsuId)[0];
                if (insuList && insuList.length === 0) {
                    insuList = this.rxInfo.insurance[0];
                }
            }

            this.subscriptions.add(
                this._rxSvc.getBInInsuPayerSetCheck(insuList.insurerid)
                .subscribe(resp => {
                    if (!resp) {
                        this.warningMsg = "Insurer's BIN# with 6 digits and NCPDP payer set is required for transmission.";
                        this.confirmModel = this._modalService.open(this.WARNINGMSGS, {centered: true, keyboard: false, windowClass: "large--content"});
                        this.confirmModalName = "WARNINGMSGS";
                        document.getElementById("btnOK").focus();
                    } else {
                        if (this.checkForRxSelect()) {

                            const modalRef = this._modalService.open(TRRxOnlyComponent, {
                                backdrop: false,
                                size: "lg",
                                windowClass: "md-x-lg",
                                keyboard: false
                            });
                            modalRef.componentInstance.RxInfo = this.rxInfo;
                            modalRef.componentInstance.IsFrmTransHistory = true;
                            // modalRef.componentInstance.RxInfo = rxFG;
                            modalRef.componentInstance.IsPopUpClosed.subscribe(resp1 => {
                                modalRef.close();
                                this.loadRxHistory();
                            });
                        }
                    }
            }));
        }
    }
   async intiateTransProcess() {
        if (this.checkForRxSelect()) {
            if (this.TotalRxInfo && this.TotalRxInfo.length === 1) {
                this.getLatestPatientPrescriberDrugInfo(this.TotalRxInfo[0], null);
            } else if (this.TotalRxInfo && this.TotalRxInfo.length > 1) {
                const insuerList = [];
                const insuerList1 = [];
                this.refillIdlist = [];
                this.notAbleToTrans = [];
                this.ableToTrans = [];
                this.ableToTransCopy = [];

                this.discontinuedList = [];
                await this.TotalRxInfo.map(async data => {
                    const canTran = await this.getLatestPatientPrescriberDrugInfo(data, true);
                    if (canTran) {
                        if (canTran === 2) {
                            this.discontinuedList.push({PresRfId: data.PrescReFill.Id,
                                 prescNum: data.Prescription.PrescNum, RefNo: data.PrescReFill.ReFillNum,
                                fillNo: data.PrescReFill.PartialFillNo});
                        }
                        this.refillIdlist.push(data.PrescReFill.Id);
                        this.ableToTrans.push(data.Prescription.PrescNum);
                        this.ableToTransCopy.push(data.PrescReFill.Id);
                        insuerList.push( (data && data.insuranceInfo && data.insuranceInfo.insurance) ? (data.insuranceInfo.insurance.InsurerId) : 0 );
                    } else {
                        this.notAbleToTrans.push(data.Prescription.PrescNum);
                    }
                });
               const newValues: any =  await this._transServ.getLargePriceValues(this.ableToTransCopy).toPromise();

               if(this.ableToTransCopy && this.ableToTransCopy.length) {
                if(newValues && newValues.length) {
                    newValues.map((newItem: any) => {
                        const index = this.ableToTrans.findIndex((id: any) => newItem['prescNum'] ==  id);
                        if(index >= 0) this.ableToTrans.splice(index, 1);
                        if(index >= 0) this.refillIdlist.splice(index, 1);
                        this.warningMessageForTrans.push(this.getRxStatusForDisplay(newItem['prescNum'], "Calculated price values are greater than the permissible values.", true));
                        this.warnMsg = "<label>" + newItem["prescNum"] + "<b></label>:Calculated price values are greater than the permissible values.</b>";
                        if(this.warnMsg) {
                            this.warningMessageDis = this.warnMsg;
                        }
                        this.notAbleToTrans.push(newItem["prescNum"]);
                    });
                }
               }
                if (this.refillIdlist && this.refillIdlist.length) {
                    if (insuerList && insuerList.length) {
                        const checkPay: any = await this._rxSvc.checkHasPayerCheck(insuerList).toPromise();
                        if (checkPay && checkPay.length) {
                            checkPay.map((c, i) => {
                               const index =  insuerList.findIndex(v => c === v);
                                if (index > -1) {
                                    const refId = this.refillIdlist.splice(index, 1);
                                    if (this.discontinuedList && this.discontinuedList) {
                                        const disI = this.discontinuedList.findIndex(v => v["PresRfId"] === refId);
                                        if (disI > -1) {
                                            this.discontinuedList.splice(disI, 1);
                                        }
                                    }
                                    const checkedval = this.ableToTrans.splice(index, 1);
                                    insuerList.splice(index, 1);
                                    this.notAbleToTrans.push(checkedval);
                                }
                            });
                        }
                    }
                }
                if (this.refillIdlist && this.refillIdlist.length) {
                    if (this.discontinuedList && this.discontinuedList.length) {
                        this.disContinueOpenFr = "MultiTrans";
                        this._modalService.open(this.RxDiscontinued, { centered: true, backdrop: false,
                             keyboard: false, windowClass: "large--content"});
                    } else {
                        this.afterDiscontinueCheckFrTran();
                    }
                }  else {
                    this.afterDiscontinueCheckFrTran();
                }
            }
        }
    }

    async afterDiscontinueCheckFrTran() {
        if (this.refillIdlist && this.refillIdlist.length) {
            this.selectedRxFTrans = this.refillIdlist;
            this.selectedRxNoFTrans = this.ableToTrans;
        } else {
            this.selectedRxFTrans = null;
            this.selectedRxNoFTrans = null;
        }
        const isRequeiredRxVerification = this._commUtil.checkRxvBeforeTransiomisson(JSON.parse(JSON.stringify(this.TotalRxInfo)));

        if (this.notAbleToTrans && this.notAbleToTrans.length) {
            // tslint:disable-next-line:max-line-length
            this.issueRxNos =  (this.warningMessageForTrans && this.warningMessageForTrans.length && this.warningMessageForTrans.length === 1)  ?  this.warningMessageDis : "Following Selected Rx's cannot be transmitted" + this.warningMessageForTrans.join(",");
            this.transModelRef = this._modalService.open(this.warnTransRx, {centered: true, backdrop: false,
                 keyboard: false, windowClass: "large--content"});
        } else if (this.refillIdlist && this.refillIdlist.length) {
           this.getUbilledRxData();
        }  else if (isRequeiredRxVerification && isRequeiredRxVerification.withoutRxVerification && isRequeiredRxVerification.withoutRxVerification.length) {
                    this.unRxVerifiedRecords = isRequeiredRxVerification.unRxVerifiedRecordsForDisplay;
                    this.confirmModel = this._modalService.open(this.rxVerification, { keyboard: false, centered: true,
                        backdrop: false, windowClass: "large--content max-modal-34" });
        } else {
           this.closeModalAndNext();
        }
    }
    async closeRxVerf() {
        if (this.confirmModel) {
            this.confirmModel.close();
            this.TotalRxInfo = [];
            this.confirmModel = null;
        }
        if (this.TotalRxInfo && this.TotalRxInfo.length) {
            await  this.intiateTransProcess();
        } else {
            if (this.TotalRxInfo && this.TotalRxInfo.length) {
                this.TotalRxInfo = null;
            }
            this.loadRxHistory();
        }
    }

    async getUbilledRxData() {
        if (this.selectedRxFTrans && this.selectedRxFTrans.length === 1) {
            await this._nrxUtls.getandStoreRxInfo(+this.selectedRxNoFTrans[0], +this.selectedRxFTrans[0]);
            this._transmsnServ._transmsnModal$.next({"type": "TransmitNow", "Fm": "Actions"});
        } else if (this.selectedRxFTrans && this.selectedRxFTrans.length) {
            const unbilledobj = {
                refillIdlist: this.selectedRxFTrans,
                pageSize: this.selectedRxFTrans.length,
                pageNumber: this.paginationModel.pageNumber
            };
            const data = await this._transmsnServ.getUnbilledRxInfo(unbilledobj).toPromise();
            this.initBatchTransmission(data);
        } else {
            if (this.TotalRxInfo && this.TotalRxInfo.length) {
                this.TotalRxInfo = null;
            }
            this.warningMessageForTrans= [];
            this.warningMessageDis = null;
            this.loadRxHistory();
        }
    }

    async initBatchTransmission(UnbilledRxData) {
        const rxNos = [];
        UnbilledRxData.map(data => {
            const rxNo = data["PrescrefillId"];
            rxNos.push(rxNo);
            delete data["IsSelected"];
        });
        const resp = await this._transmsnServ.checkBeforeBatchTrans(UnbilledRxData).toPromise();
        if (resp) {
            this.gpBatchId = resp["GroupNum"];
            UnbilledRxData = UnbilledRxData.map(v => ({...v, GroupNum: this.gpBatchId}));
            if (resp["Pendingrxlist"] && resp["Pendingrxlist"].length) {
                resp["Pendingrxlist"].map(rx => {
                    const index = UnbilledRxData.findIndex(o => o["PrescrefillId"] == rx);
                    UnbilledRxData.splice(index, 1);
                });
            }
            if (UnbilledRxData && UnbilledRxData.length) {
                this._transmsnServ.initiateBatchTransmission(UnbilledRxData).toPromise();
                this._loadrServ.display(true);
                setTimeout(async v => {
                    this._loadrServ.display(false);
                    UnbilledRxData = [];
                    if (resp && resp["Pendingrxlist"] && resp["Pendingrxlist"].length > 0) {
                        const initRxs = rxNos.filter(rx => (resp["Pendingrxlist"].indexOf(rx) === -1));
                        // let msg = "";
                        // resp["Pendingrxlist"].map((m, i) => {
                        //     if (i !== 0) {
                        //         msg += ", ";
                        //     }
                        //     msg += m;
                        // });
                        //"Transmission for " + resp["Pendingrxlist"].length + " Rx(s) is initiate by another user."
                        this._alertSvc.warning("Transmission for all or few of the selected Rx(s) has been initiated by a different user on the same pharmacy.");
                        if (initRxs && initRxs.length > 0) {
                            this.getGrpTransmissionStatus();
                            this.transModelRef = this._modalService.open(this.GRPTRANSMODAL,
                                { centered: true, backdrop: false, keyboard: false });
                            this.checkIsStillInPending();
                        }
                    } else {
                        this._alertSvc.success("Group Transmission initiated successful.");
                        this.getGrpTransmissionStatus();
                        this.transModelRef = this._modalService.open(this.GRPTRANSMODAL,
                             { centered: true, backdrop: false, keyboard: false });
                        this.checkIsStillInPending();
                    }
                }, 1000);
            } else {
                this._alertSvc.warning(
                    "Transmission for selected Rx's are initiated by another user."
                );
            }
        } else {
            this.TotalRxInfo = null;
            this._alertSvc.error("Transmission initiation for the group unsuccessful.");
            this.loadRxHistory();
        }
    }

    getGrpTransmissionStatus() {
        this._transmsnServ.getGroupTransmissionStatus(this.patientID ? this.patientID : 0, false, this.gpBatchId).subscribe(resp => {
            if(resp){
                    this.transCompltedList = resp.MenuGroupTransmission;
                    this.transmissionRejctedList = resp.TransError;
                    this.transSwitchDownList = resp.SwitchDownError;
            }
            setTimeout(() => {
                const ele = document.getElementsByClassName("inprogress--GPT");
                if (ele && ele.length) {
                    const target: any = ele[ele.length - 1];
                    if (target && target.parentNode && target.parentNode.parentNode &&
                         target.parentNode.parentNode.parentNode) {
                            target.parentNode.parentNode.parentNode.scrollTop = target.offsetTop;
                         }
                }
            }, 10);
            // if (!this.transCompltedList.length && this.subscription) {
            //     this.subscription.unsubscribe();
            // }
            if ((!this.transCompltedList || (this.transCompltedList && !this.transCompltedList.length)) &&
            ((this.transmissionRejctedList && !this.transmissionRejctedList.length) && (this.transSwitchDownList && !this.transSwitchDownList.length))) {
                this._alertSvc.error("Error while doing batch transmission, please process again.");
                this.closeTransModel();
                if (this.resetObs && this.subscription) {
                    this.resetObs.unsubscribe();
                    this.subscription.unsubscribe();
                }
                this.loadRxHistory();
            }
        });
    }

    checkIsStillInPending() {
        this.reset$.next(void 0);
        this.subscription = this.reset$
        .pipe(
            startWith(void 0),
            switchMap(() => timer(2000, 2000)),
        )
        .subscribe(next => {
            this.reset$.next(void 0);
            if (!this.checkIsAllCompleted()) {
                this.transCompltedList = [];
                this.getGrpTransmissionStatus();
            }
        });

        // resetCmpl$ = new Subject();
        this.resetCmpl$.next(void 0);
      this.resetObs =   this.resetCmpl$
        .pipe(
            startWith(void 0),
            switchMap(() => timer(1000, 1000)),
        )
        .subscribe(next => {
            this.resetCmpl$.next(void 0);
            if (this.checkIsAllCompleted()) {
                this.checkAndShowTrans();
            } else {
                this.enableTrans = false;
            }
        });
    }

    checkIsAllCompleted() {
        let isCompleted = null;
        if (this.transCompltedList && this.transCompltedList.length > 0) {
            isCompleted = this.transCompltedList.every(i => {
                return (i.GroupTransmissionStatus === 4);
            });
        }else if((!this.transCompltedList || (this.transCompltedList && !this.transCompltedList.length)) &&
        ((this.transmissionRejctedList && this.transmissionRejctedList.length) || (this.transSwitchDownList && this.transSwitchDownList.length))){
            isCompleted = true;
        }
        return isCompleted;
    }

    checkAndShowTrans() {
        if (this.checkIsAllCompleted()) {
            this.closeTransModel();
            if (!this.enableTrans) {
                this._transmsnServ._transmsnModal$.next({"type": "GroupTransmission", "Fm": "Actions",
                 "patId": this.patientID, "gTBatchId": this.gpBatchId});
            }
            this.enableTrans = true;
        }
    }

    closeTransModel() {
        if (this.transModelRef) {
            this.transModelRef.close();
            this.transModelRef = null;
        }
    }

    closeModalAndNext() {
        if (this.modalRefWarn) {
            this.modalRefWarn.close();
        }
        if (this.TotalRxInfo && this.TotalRxInfo.length) {
            this.TotalRxInfo = null;
        }
        if (this.subscription) {
            this.subscription.unsubscribe();  
        }
        this.warningMessageForTrans = [];
        this.transCompltedList = [];
        this.loadRxHistory()
        this.warningMessageForTrans = [];
    }


    async getLatestPatientPrescriberDrugInfo(data, type?: any) {
        let warnMsg = null;
        let result = 1;
        this.dataList = data;
        this.rxDetails = data.Patient.insurance.filter((item: any) => {
                if ((item.insuranceid === data.RefillInsu.InsuId) && !item.insurerActiveStatus && (item.InsuPriority === "Primary")) {
                    return data;
                }
        });
        if (data.Prescription.IsDiscontinued && !this.isAfterPopup) {
            if (!type) {
                this.disContinueOpenFr = "SingleTrans";
                this.model = this._modalService.open(this.RxDiscontinued, { centered: true, backdrop: false, keyboard: false, windowClass: "large--content"});
                return;
            } else {
                warnMsg = "Discontinued";
            }
        }
        const fillNum = this._commonServ.checkIsPartialFill
        (data.PrescReFill, data.Prescription)
        ? `-${data.PrescReFill.PartialFillNo}` : " ";
        const isRequeiredRxVerification = this._commUtil.checkRxvBeforeTransiomisson([data]);
        if (data.PrescReFill.StatusId === 2 || data.Prescription.RxStatusId === 2) {
            warnMsg = "Selected Rx# " + data.Prescription.PrescNum + "-" + data.PrescReFill.ReFillNum + fillNum +  " is Deferred Rx, cannot be transmitted";
            this.warningMessageForTrans.push(this.getRxStatusForDisplay(data, "Deferred Rx"));
        } else if (data.PrescReFill.StatusId === 4 || data.Prescription.RxStatusId === 4) {
            warnMsg = "Selected Rx# " + data.Prescription.PrescNum + "-" + data.PrescReFill.ReFillNum + fillNum + " is Transfered Rx, cannot be transmitted";
            this.warningMessageForTrans.push(this.getRxStatusForDisplay(data, "Transfered Rx"));
        } else  if (this._rxBillUtil.checkIsCahsRx(data, data["Patient"], this.systemInfo$["source"]["value"])) {
            warnMsg = "Selected Rx# " + data.Prescription.PrescNum + "-" + data.PrescReFill.ReFillNum + fillNum +  " is Cash Rx, cannot be transmitted";
            this.warningMessageForTrans.push(this.getRxStatusForDisplay(data, "Cash Rx"));
        } else if (data.PrescReFill.GroupTransStatus === "Pending" || data.PrescReFill.GroupTransStatus === "Completed" ||
         this.rxInfo.PrescReFill.GroupTransStatus === "Transmitting") {
            warnMsg = "Please review previously transmission result for Rx# " + data.Prescription.PrescNum + ".";
            this.warningMessageForTrans.push(this.getRxStatusForDisplay(data, "Please review previously transmission result"));
        } else if ((data.PrescReFill.StatusId === 1 || data.Prescription.RxStatusId === 1) && data.RxBill.StatusId === 2) {
            warnMsg = "Selected Rx# " + data.Prescription.PrescNum + "-" + data.PrescReFill.ReFillNum + fillNum +  " is Billed Rx, cannot be transmitted";
            this.warningMessageForTrans.push(this.getRxStatusForDisplay(data, "Billed Rx"));
        }  else if(isRequeiredRxVerification && isRequeiredRxVerification.withoutRxVerification && isRequeiredRxVerification.withoutRxVerification.length) {
            warnMsg = "Rx Verification is Required before Transmission for Selected Rx# <b>" +  data.Prescription.PrescNum + "</b>";
            this.unRxVerifiedRecords = isRequeiredRxVerification.unRxVerifiedRecordsForDisplay;
            this.warningMessageForTrans.push(this.getRxStatusForDisplay(data, "Rx Verification is Required before Transmission"));
        } else if (this.rxDetails && this.rxDetails.length > 0 && (!type)) {
            this.modalRefInact = this._modalService.open(this.inActiveInsurance, {centered: true, backdrop: false, keyboard: false,
                windowClass: "large--content"});
        } else if (!type) {
            const transmissionParams = this._transUtils.createTransmissionParams(
                this.rxInfo,
                "Tr-Now", null,
                null,
                this.rxInfo.RxPay
            );
            const resp = await this._transServ.initiateRxTransmissionAsObsev(transmissionParams).toPromise();
            if(resp && resp['greaterPriceValues'] && resp['greaterPriceValues']['BalDue']) {
                this.largerValuesForDisplay = resp['greaterPriceValues'];
                if(!type)
                         this.routeToEditRxWithPrivs();
            } else {
                this._rxStore.storeRxInfo(data);
                //await this._nrxUtls.getandStoreRxInfo(+data.Prescription.PrescNum, data.PrescReFill.Id);
                this._transmsnServ._transmsnModal$.next({"type": "TransmitNow", "Fm": "Actions", "transData": resp});
            }
        }
        if(warnMsg) {
            this.warningMessageDis = warnMsg;
        }
        if (warnMsg && !type) {
            this.warningMsg = warnMsg;
            this.modalRefWarn = this._modalService.open(this.TRANSWARNMSGS,
                 {backdrop: false, centered: true, windowClass: "large--content"});
        } else if (warnMsg && type) {
            result = warnMsg === "Discontinued" ? 2 : 0;
        }

        return result;
    }
    getRxStatusForDisplay(data, rxStatus,isFromCheck: boolean = false) {

        if(!isFromCheck) {
            return  ("<br><label>" +
            data["Prescription"]["PrescNum"] + "-" + data["PrescReFill"]["ReFillNum"] +
            (this._commonServ.checkIsPartialFill(data["PrescReFill"], data["Prescription"]) ? "-" + data["PrescReFill"]["PartialFillNo"] : " ") + " </label> :  <b>" + rxStatus + "</b>");
        } else {
            return  ("<br><label>" +  data + "<b></label>:   " + rxStatus + "</b>");
        }

    }

   async openSelectBillToPopup() {
        if (this.modalRefInact) {
            this.modalRefInact.close();
            this.modalRefInact = null;
        }
        this.openBillToPopup = true;
        let rxFG = this._rxFGPat.createNewRxFG();
        if (this.dataList) {
            this._rxFGPat.patchRxInfo(this.dataList, rxFG);
        }
        const modalRef = this._modalService.open(PPopupsComponent, {
            size: "lg",
            backdrop: false,
            keyboard: false
        });
        modalRef.componentInstance.rxFG = rxFG;
        modalRef.componentInstance.fromTrActions = true;
        modalRef.componentInstance.systemDataList = this.systemInfo$["source"]["value"];
        modalRef.componentInstance.rxDetailList = this.rxDetails[0];
        modalRef.componentInstance.InactIsPopUpClosed.subscribe(async resp => {
            this.refillInsu = resp;
            modalRef.close();
            if (this.refillInsu === "Cash") {
                const modalRefCash = this._modalService.open(BillToCashComponent, {
                    keyboard: false,
                    backdrop: false,
                    centered : true
                });
                modalRefCash.componentInstance.rxInfo = rxFG.value;
                modalRefCash.componentInstance.rxFG = rxFG;
                modalRefCash.componentInstance.IsBillToCashPopUpClosed.subscribe(async r => {
                    modalRefCash.close();
                    const rxBill = rxFG.controls["RxBill"] as FormGroup;
                    rxBill.controls["StatusId"].patchValue(2);
                    this.loadRxHistory();
                });
                modalRefCash.componentInstance.CancelCashPopUp.subscribe(r => {
                    modalRefCash.close();
                    this.loadRxHistory();
                });
            } else {
                await this._nrxUtls.getandStoreRxInfo(+this.dataList.Prescription.PrescNum,
                    this.dataList.PrescReFill.Id, false, "", this.refillInsu, true);
                this._transmsnServ._transmsnModal$.next({"type": "TransmitNow", "Fm": "Actions"});
            }
        });
    }

    async continueTransmit() {
        if (this.modalRefInact) {
            this.modalRefInact.close();
            this.modalRefInact = null;
        }
        this.rxDetails = [];
        await this._nrxUtls.getandStoreRxInfo(+this.dataList.Prescription.PrescNum, this.dataList.PrescReFill.Id);
        this._transmsnServ._transmsnModal$.next({"type": "TransmitNow", "Fm": "Actions"});
    }

    async setOrderStatus() {
        const resp = await this._rxSvc.putRxDiscontinuedOrderStatus(this.rxInfo.Prescription.Id).toPromise();
        if (resp) {
            this.rxInfo["Prescription"]["IsDiscontinued"] = false;
        }
        if (this.disContinueOpenFr === "SingleTrans") {
            this.isAfterPopup = true;
            this.intiateTransProcess();
        } else if (this.disContinueOpenFr === "MultiTrans") {
            this.afterDiscontinueCheckFrTran();
        } else if (this.disContinueOpenFr === "Dpv" || this.disContinueOpenFr === "Rph" || this.disContinueOpenFr === "Rx") {
            this.checkIsDiscontinue();
            // if (this.disContinueOpenFr === "Dpv") {
            //     this.openDrugPickVerf();
            // } else {
            //     this.routeToRph();
            // }
        }
    }

    closeDiscontinuePopup() {
        this.alreadyChckdDPVMandat = true;
        if (this.disContinueOpenFr === "SingleTrans") {
            this.closeModalAndNext();
        } else if (this.disContinueOpenFr === "MultiTrans") {
            this.discontinuedList.map(v => {
                const refillIndex = this.refillIdlist.findIndex(i => i === v["PresRfId"]);
                if (refillIndex > -1) {
                    this.refillIdlist.splice(refillIndex, 1);
                    this.ableToTrans.splice(refillIndex, 1);
                }
            });
            this.afterDiscontinueCheckFrTran();
        } else if (this.disContinueOpenFr === "Dpv") {
            if (this.TotalSelctedDrugVerifactionRxs?.length) {
                this.TotalSelctedDrugVerifactionRxs.shift();
            }
            this.processDrugPickVerification();
        } else if (this.disContinueOpenFr === "Rph") {
            this.withoutAlreadyshifted = true;
            if (this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length > 0) {
                this.withoutAlreadyshifted = false;
                this.withOutDrugPickVerfList.shift();
                this.rxInfo = this.withOutDrugPickVerfList[0];
            }
            this.processRphVerfMultiple();
        } else if (this.disContinueOpenFr === "Rx") {
            this.processRxVerfication();
        }
    }

    addToQueue() {
        this._rxSvc.addToRefillQueue(this.selectedRxFrQ).subscribe(resp => {
            if (this.modalRef) {
                this.modalRef.close();
            }
            this.showRefillQueRxs = [];
            this.queueNotToInsert = [];
            if (resp !== -1) {
                if (resp > 0) {
                    this.warnDuplicate = "Selected " + resp + " Rx(s) already exists in the Refill Queue, hence " +
                    (this.TotalRxInfo.length - resp) + " Rx(s) added to Queue.";
                    this._modalService.open(this.duplicateRx, {centered: true, backdrop: false, windowClass: "large--content"});
                } else {
                    this.afterAddQueue();
                }
            } else {
                this.afterAddQueue();
            }
        });
    }

    afterAddQueue() {
        this.selectedRxFrQ = [];
        this._commonServ.getSideNavCounts(this.dateParams);
        this.emitShowRxHistory.emit(true);
    }

    closeModalAndShowHistory()  {
        if (this.modalRef1) {
            this.modalRef1.close();
            this.modalRef1 = null;
        }
        this.emitShowRxHistory.emit(true);
    }

    AddToQueue() {
        if (this.checkForRxSelect()) {
            this.TotalRxInfo.map(val => {
                this.selectedRxFrQ.push({
                    Prescnum: val["Prescription"]["PrescNum"],
                    PrescrefillId: val["PrescReFill"]["Id"],
                    PartialFillNo:   val["PrescReFill"]["PartialFillNo"],
                    ReFillNum:   val["PrescReFill"]["ReFillNum"]
                });
            });
            if (this.TotalRxInfo && this.TotalRxInfo[0] &&  this.TotalRxInfo[0].Patient && this.TotalRxInfo[0].Patient.isdeceased) {
                this.warningMsgDeceased = this.TotalRxInfo[0] ?
                (this.TotalRxInfo[0].Patient.lastname + ", " + this.TotalRxInfo[0].Patient.firstname).toUpperCase():"";
                this.totalDeceasedPats = this.TotalRxInfo.length;
                this.modalRef = this._modalService.open(this.deceased, {
                    centered: true,
                    backdrop: false,
                    keyboard: false,
                    windowClass: "large--content"
                });
            } else {
                this.modalRef = this._modalService.open(this.QueuC, {
                    centered: true,
                    backdrop: false,
                    keyboard: false,
                    windowClass: "large--content"
                });
            }
        }
    }

    openRxNotes() {
        if (this.TotalRxInfo && this.TotalRxInfo.length > 1) {
            this.activeModal = null;
            this._alertSvc.error("Please select only one Rx.");
            this.loadRxHistory();
        } else if (this.TotalRxInfo && this.TotalRxInfo.length === 0) {
            this.activeModal = null;
            this._alertSvc.error("Please select atleast one Rx.");
        } else {
            this.activeModal = "RxNotes";
        }
    }

    closeRxNotes() {
        this.activeModal = null;
        if (this.modalRef) {
            this.modalRef.close();
        }
    }

    async saveNotesWithRx() {
        const oldInfo = Object.assign({}, this.rxInfo);
        this.rxInfo["Prescription"]["Remarks"] = this.notes;
        if (!(this.rxInfo["Prescription"]["RxStatusId"] === 2 || this.rxInfo["PrescReFill"]["StatusId"] === 2)) {
            this.rxInfo["RxFile"] = null;
        }
        const resp = await this._saveRxUtls.updateRxInfo(this.rxInfo, oldInfo);
        if (resp.RxNum && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
            this._alertSvc.success("Notes saved successfully.");
            this.closeRxNotes();
        } else if (resp && isNaN(+resp.Message)) {
            if (resp.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                this._alertSvc.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
            } else if (typeof resp.Message === "string") {
            this._alertSvc.error(resp.Message);
            }
        } else {
            this._alertSvc.error("Notes Save unsuccessful.");
        }
    }

    changeNotes(notes: string) {
        this.notes = notes;
    }

    emitPickRx() {
        if (this.isFromNewRxRequest) {
            this.openActionModal("rxToNewRxrequest");
        } else if (this.checkForRxSelect()) {
            if (!(this.openModalFr === "ERW" || this.openModalFr === "RFQ")) {
                if (this.IsMultiselect()) {
                    return;
                } else {
                    this.emitPickedRx.emit(this.TotalRxInfo);
                }
            } else {
                this.emitPickedRx.emit(this.TotalRxInfo);
            }
        }
    }
    async processDrugPickVerification(type?) {
        this.rphClicked = this.drupPickClicked ? false : true;
        this.orginal = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification_for_Original_Rx_Only"), 0);
        this.beforeDPV = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Rx_Verification_Required_Before_DPV"), 0);
        // this.beforeRph = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings",
        //                  "Rx_Verification_Required_Before_RPh_Verification"), 0);
        if (type === "continue") {
            await this.otherVerificationChecks(type);
            return;
        }
        if (this.TotalSelctedDrugVerifactionRxs.length > 0) {
            this.rxInfo = (this.TotalSelctedDrugVerifactionRxs && this.TotalSelctedDrugVerifactionRxs.length > 1)
            ? this.TotalSelctedDrugVerifactionRxs[0] : this.TotalSelctedDrugVerifactionRxs[0];
            if ((this.rxInfo.drugPickVerificationStatus &&
                 (this.rxInfo.drugPickVerificationStatus.trim()).toLowerCase() === "p") && (this.rxInfo.rph2VerificationStatus &&
                    (this.rxInfo.rph2VerificationStatus.trim()).toLowerCase() === "v")) {
                        if (this.drupPickClicked) {
                            this.closeModal();
                            await this.getRxDetails("Dpv");
                        } else {
                            this.isRxDiscont = false;
                            this.rph = true;
                            this.isDphOrRphOrRx = "Rph";
                            this.withDrugPickVerfList.shift();
                            this.TotalSelctedDrugVerifactionRxs.shift();
                            this.rphverfChckd = false;
                            this.othrverfchcks = false;
                            this.oldInfo = this.rxInfo.PrescReFill.Id;
                            await this.IsVerifiedAlready();
                        }
            } else if ((this.rxInfo.drugPickVerificationStatus &&
                 (this.rxInfo.drugPickVerificationStatus.trim()).toLowerCase() === "p")) {
                    if (this.drupPickClicked) {
                        this.closeModal();
                        this.getRxDetails("Dpv");
                    } else {
                        this.isRxDiscont = false;
                        this.rph = true;
                        this.isDphOrRphOrRx = "Rph";
                        this.withDrugPickVerfList.shift();
                        this.TotalSelctedDrugVerifactionRxs.shift();
                        this.totalSelectedRphRxs = [...this.TotalSelctedDrugVerifactionRxs];
                        this.rphverfChckd = false;
                        this.othrverfchcks = false;
                        this.oldInfo = this.rxInfo.PrescReFill.Id;
                        await this.IsVerifiedAlready();
                    }
            } else if (
                this._commonServ.getSetttingValue(
                    "WorkFlowSettings",
                    "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF"
                ) === "1"
            && !this.alreadyChckdDPVMandat && (!this.rxInfo["drugPickVerificationStatus"] || ( this.rxInfo["drugPickVerificationStatus"] && (this.rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "rv" || this.rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "nv"))) && this.rphClicked) {
                this.dpv = true;
                this.alreadyChckdDPVMandat = true;
                this.rxInfo = this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length > 0 ? this.withOutDrugPickVerfList[0] : null;
                this.isDphOrRphOrRx = "Dpv";
                // this.withOutDrugPickVerfList.shift();
                this.warningInfo = "Drug Pick Verification has not been performed on Rx(s) "
                        + "<div><label>" + this.notDrugPickVerfRecords.join(", ") +
                        "</label></div>" + "<div>Please perform Drug Pick Verification before R.Ph Verification.</div>";
                this.modalRef = this._modalService.open(this.invalidRx, {
                        centered: true,
                        keyboard: false,
                        windowClass: "large--content"
                    });
            } else {
                this.isRxDiscont = false;
                this.closeModal();
                this.isDphOrRphOrRx = "Dpv";
                this.rph = false;
                this.dpv = true;
                this.totalSelectedRphRxs = this.TotalSelctedDrugVerifactionRxs;
                this.oldInfo = this.rxInfo.PrescReFill.Id;
                if (this.rxVerified && this.totalSelectedRphRxs.length >= 1) {
                    this.rxVerified = false;
                    this.totalSelectedRphRxs.shift();
                    if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length >= 1) {
                        this.rxInfo = this.totalSelectedRphRxs[0];
                    } else {
                        this.rxInfo = null;
                    }
                }
                await this.otherVerificationChecks();
            }
        } else {
            this.closeModal();
            this.reloadRxHistory(null);
        }
    }
    processRphVerfMultiple() {
        this.orginal = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification_for_Original_Rx_Only"), 0);
        this.beforeDPV = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Rx_Verification_Required_Before_DPV"), 0);
        // this.beforeRph = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings",
        //                  "Rx_Verification_Required_Before_RPh_Verification"), 0);
        this.rphClicked = true;
        if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length) {
            if (!this.withoutAlreadyshifted) {
            this.withOutDrugPickVerfList = [];
            this.withDrugPickVerfList = [];
            this.notDrugPickVerfRecords = [];
            this.totalSelectedRphRxs.filter((x: any) => {
            if ((!x.drugPickVerificationStatus || ((x.drugPickVerificationStatus && x.drugPickVerificationStatus.trim()).toLowerCase() !== "p"))) {
                this.withOutDrugPickVerfList.push(x);
            } else {
                this.withDrugPickVerfList.push(x);
            }
            });

            // if (this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length) {
            //     this.withOutDrugPickVerfList.map((item: any) => {
            //         if (item && item.Prescription && item.Prescription.PrescNum) {
            //             this.notDrugPickVerfRecords.push(item.Prescription.PrescNum + "-" + item.PrescReFill.ReFillNum +
            //              (this._commonServ.checkIsPartialFill(item.PrescReFill.PartialFillNo,
            //                 item.PrescReFill.PartialIndStatus) ? "-" + item.PrescReFill.PartialFillNo : " "));
            //         }
            //     });
            // }
            if (this.withOutDrugPickVerfList && !this.withOutDrugPickVerfList.length) {
                this.rph = true;
                this.alreadyshifted = false;
                this.isDphOrRphOrRx = "Rph";
                this.rxInfo = this.withDrugPickVerfList[0];
                this.rphverfChckd = false;
                this.othrverfchcks = false;
                this.IsVerifiedAlready();
            } else {
                if (
                    this._commonServ.getSetttingValue(
                        "WorkFlowSettings",
                        "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF"
                    ) === "1"
                && !this.alreadyChckdDPVMandat && (!this.rxInfo["drugPickVerificationStatus"] || ( this.rxInfo["drugPickVerificationStatus"] && (this.rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "rv" || this.rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "nv")))) {
                    if (this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length) {
                        this.withOutDrugPickVerfList.map((item: any) => {
                            if (item && item.Prescription && item.Prescription.PrescNum) {
                                this.notDrugPickVerfRecords.push(item.Prescription.PrescNum + "-" + item.PrescReFill.ReFillNum +
                                 (this._commonServ.checkIsPartialFill(item.PrescReFill, item.Prescription) ? "-" + item.PrescReFill.PartialFillNo : " "));
                            }
                        });
                    }
                    this.alreadyChckdDPVMandat = true;
                    this.dpv = true;
                    this.rxInfo = this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length > 0 ? this.withOutDrugPickVerfList[0] : null;
                    this.isDphOrRphOrRx = "Dpv";
                    // this.withOutDrugPickVerfList.shift();
                    this.warningInfo = "Drug Pick Verification has not been performed on Rx(s) "
                            + "<div><label>" + this.notDrugPickVerfRecords.join(", ") +
                            "</label></div>" + "<div>Please perform Drug Pick Verification before R.Ph Verification.</div>";
                    this.modalRef = this._modalService.open(this.invalidRx, {
                            centered: true,
                            keyboard: false,
                            windowClass: "large--content"
                        });
                } else {
                        this.rph = true;
                        this.rxVerf = false;
                        this.isDphOrRphOrRx = "Rph";
                        this.otherVerificationChecks();
                }
            }
        } else {
            this.closeModal();
            this.reloadRxHistory(null);
        }
     } else {
            this.closeModal();
            this.reloadRxHistory(null);
        }
    }

    drugRphRxVerification() {
        this.setDefaultValuesForRphDpv();
        if (this.isDphOrRphOrRx === "Dpv") {
            this.dpv = true;
        } else if (this.isDphOrRphOrRx === "Rph") {
            this.rph = true;
        } else {
            this.rxVerf = true;
        }
        if (this.dpv) {
            this.processDrugPickVerification();
        } else if (this.rph) {
            this.fromRoutRph = ((this.rxInfo && this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0 &&
                 this.rxInfo.PrescReFill.Id === this.totalSelectedRphRxs[0]["PrescReFill"]["Id"]) ? false : true);
            this.isDphOrRphOrRx = "Dpv";
            this.closeDrugPickVerf();
        } else if (this.rxVerf) {
            this.processRxVerfication();
        }
    }


    processRphVerfication() {
        if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length) {
            if ((this._commonServ.getSetttingValue(
                "WorkFlowSettings",
                "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF"
            ) === "1")) {
                this.withoutAlreadyshifted = false;
                this.processRphVerfMultiple();
            } else {
            this.rxInfo = this.totalSelectedRphRxs[0];
            this.getRxDetails("Rph");
            }
        } else {
            this.closeModal();
            this.reloadRxHistory(null);
        }
    }

    async closeDrugPickVerf() {
        if (this.dpv) {
            await this.processDrugPickVerification();
            return;
        }
        if (this.rxVerf) {
           await this.processRxVerfication();
           return;
        }
        this.isRxDiscont = false;
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this.rphverfChckd = false;
        this.isDphOrRphOrRx = ((this._commonServ.getSetttingValue(
            "WorkFlowSettings",
            "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF"
        ) === "1") && !this.rph) ? "Dpv" : "Rph";
        this.rxVerified = (((this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length === 1) || (this.withDrugPickVerfList && this.withDrugPickVerfList.length === 1))
        && (this.rxInfo && this.rxInfo["PrescReFill"] && ((this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length &&
            (this.withOutDrugPickVerfList[0]["PrescReFill"]["Id"] === this.rxInfo["PrescReFill"]["Id"])) ||
             (this.withDrugPickVerfList && this.withDrugPickVerfList.length &&
                (this.withDrugPickVerfList[0]["PrescReFill"]["Id"] === this.rxInfo["PrescReFill"]["Id"]))))) ? true : false;
        if (this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length === 1 && this.rxVerified) {
            this.withOutDrugPickVerfList.shift();
            this.totalSelectedRphRxs.shift();
        }
        if (this.withDrugPickVerfList && this.withDrugPickVerfList.length === 1 && this.rxVerified) {
            this.withDrugPickVerfList.shift();
            this.totalSelectedRphRxs.shift();
        }
        if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0) {
            if (this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length > 0) {
                if (!this.fromRoutRph) {
                    this.totalSelectedRphRxs.shift();
                    this.TotalSelctedDrugVerifactionRxs = this.totalSelectedRphRxs;
                }
                this.rxInfo = this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0 ? this.totalSelectedRphRxs[0] : "";
                this.isRxDiscont = false;
                this.rphverfChckd = false;
                this.othrverfchcks = false;
                if (!this.oldInfo || (this.oldInfo && this.rxInfo && this.rxInfo.PrescReFill && this.oldInfo !== this.rxInfo.PrescReFill.Id)) {
                    // this.otherVerificationChecks();
                    this.processRphVerfMultiple();
                }
            } else if (this.withDrugPickVerfList && this.withDrugPickVerfList.length > 0) {
                this.alreadyshifted = true;
                if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 1 && !this.notYetVerf) {
                    this.withDrugPickVerfList.shift();
                    this.totalSelectedRphRxs.shift();
                }
                this.rxInfo = this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0 ? this.totalSelectedRphRxs[0] : "";
                this.isRxDiscont = false;
                this.rphverfChckd = false;
                this.othrverfchcks = false;
                if (!this.oldInfo || (this.oldInfo && this.rxInfo && this.rxInfo.PrescReFill  && this.oldInfo !== this.rxInfo.PrescReFill.Id)) {
                    this.otherVerificationChecks();
                }
            } else {
                if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0) {
                        this.totalSelectedRphRxs.shift();
                        this.TotalSelctedDrugVerifactionRxs = this.totalSelectedRphRxs;
                    this.rxInfo = this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0 ? this.totalSelectedRphRxs[0] : "";
                    this.isRxDiscont = false;
                    this.rphverfChckd = false;
                    this.othrverfchcks = false;
                    if (!this.oldInfo || (this.oldInfo && this.rxInfo && this.rxInfo.PrescReFill  && this.oldInfo !== this.rxInfo.PrescReFill.Id)) {
                        this.otherVerificationChecks();
                    }
                } else {
                    this.loadRxHistory();
                    this.oldInfo = null;
                }
            }
        } else {
            this.oldInfo = null;
            this.loadRxHistory();
        }
    }
    processRxVerfication() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0) {
            if (this.rphClicked || this.drupPickClicked) {
                if (this.withOutDrugPickVerfList && this.withDrugPickVerfList && !this.withDrugPickVerfList.length && !this.withOutDrugPickVerfList.length) {
                    this.rxVerf = false;
                    if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 1) {
                        this.totalSelectedRphRxs.shift();
                        this.directRxVerf = false;
                        this.rxInfo = this.totalSelectedRphRxs[0];
                    } else {
                        this.rxInfo = this.totalSelectedRphRxs[0];
                        this.directRxVerf = false;
                        this.totalSelectedRphRxs.shift();
                    }
                } else {
                    if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length >= 1) {
                        this.rxVerf = false;
                        if (this.rxVerified) {
                            this.totalSelectedRphRxs.shift();
                        }
                        this.directRxVerf = false;
                        this.rxInfo = this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0 ? this.totalSelectedRphRxs[0] : "";
                    }
                }
            } else {
                if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length >= 1) {
                    this.rxInfo = this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0 ? this.totalSelectedRphRxs[0] : "";
                    this.directRxVerf = true;
                    this.totalRxsCountForRxVerf = [...this.totalSelectedRphRxs];
                    this.totalSelectedRphRxs.shift();
                }
            }
            if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0 && !this.directRxVerf) {
                if (this.orginal && !(this.rxInfo["rph1VerificationStatus"] && this.rxInfo.rph1VerificationStatus.trim().toLowerCase() === "r")
                 && this.isDphOrRphOrRx === "Rx" && !(this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
                     if (this.beforeDPV && this.drupPickClicked) {
                        this.isDphOrRphOrRx = "Dpv";
                     }
                    //  if (this.beforeRph && this.rphClicked) {
                    //     this.isDphOrRphOrRx = "Rph";
                    //  }
                } else if (this.rxInfo["rph1VerificationStatus"] && this.rxInfo.rph1VerificationStatus.trim().toLowerCase() === "r") {
                        this.isDphOrRphOrRx = this.rphClicked ? "Rph" : "Dpv";
                } else {
                    this.isDphOrRphOrRx = "Rx";
                }
                this.getRxDetails(this.isDphOrRphOrRx);
            } else if (this.directRxVerf) {
                this.getRxDetails("Rx");
            } else {
                this.closeModal();
                this.reloadRxHistory(null);
            }
        } else {
            this.closeModal();
            this.reloadRxHistory(null);
        }
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.close();
        }
    }

    getRxDetails(frm) {
        this.isDphOrRphOrRx = frm;
        this.orginal = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification_for_Original_Rx_Only"), 0);
        this.beforeDPV = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Rx_Verification_Required_Before_DPV"), 0);
        // this.beforeRph = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings",
        //  "Rx_Verification_Required_Before_RPh_Verification"), 0);
        if (this.rxInfo) {
            this.setDefaultValuesForRphDpv();
            this.dpv = frm === "Dpv" ? true : false;
            this.rph = frm === "Rph" ? true : false;
            this.rxVerf = frm === "Rx" ? true : false;
            if (this.rxVerf && this.rphClicked) {
                this.rph = true;
            } else if (this.rxVerf && this.drupPickClicked) {
                this.dpv = true;
            } else {
                this.dpv = frm === "Dpv" ? true : false;
                this.rph = frm === "Rph" ? true : false;
            }
            this.closeModal();
            if (this.rxInfo && this.rxInfo.rph2VerificationStatus && this.rxInfo.rph2VerificationStatus.trim().toLowerCase() === "v" &&
             this.rphClicked) {
                this.othrverfchcks = false;
                this.IsVerifiedAlready();
            } else {
                this.otherVerificationChecks();
            }
        }
    }

    async otherVerificationChecks(type?: any) {
        this.rphverfChckd = false;
        this.reqRxVerfStng = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification"), 0);
        this.workFlwPatStng = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings",
         "Require_Rx_Verification_for_workflow_patient_only"), 0);
        if (this.rxInfo) {
            const rxNo = this.rxInfo["Prescription"]["PrescNum"] + "-" + this.rxInfo["PrescReFill"]["ReFillNum"] +
                        (this._commonServ.checkIsPartialFill(this.rxInfo["PrescReFill"], this.rxInfo["Prescription"]) ? "-" + this.rxInfo["PrescReFill"]["PartialFillNo"] : " ");
            this.closeModal();
            if (
                (this.rxInfo["drugPickVerificationStatus"] && this.rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "p") 
                && !type && this.dpv && this.drupPickClicked
            ) {
                if (this.TotalSelctedDrugVerifactionRxs && this.TotalSelctedDrugVerifactionRxs.length >= 1 ) {
                    this.TotalSelctedDrugVerifactionRxs.shift();
                }
                this.totalSelectedRphRxs = this.TotalSelctedDrugVerifactionRxs;
                this.rphverfChckd = true;
                this.modalRef = this._modalService.open(
                    this.alreadyVerified,
                    {
                        centered: true,
                        keyboard: false,
                        windowClass: "large--content"
                    }
                );
            } else if (this.rxInfo["PrescReFill"]["StatusId"] === 4 || this.rxInfo["Prescription"]["RxStatusId"] === 4) {
                this.rxInfo["Prescription"]["IsDiscontinued"] = false;
                this.rphverfChckd = true;
                let rxShifted = false;
                this.othrverfchcks = true;
                this.warningInfo = "<label>" + rxNo + " " + "</label>" + " is a Transfered Rx, it cannot be verified.";
                if (this.drupPickClicked) {
                    if (this.TotalSelctedDrugVerifactionRxs && this.TotalSelctedDrugVerifactionRxs.length >= 1 ) {
                        this.TotalSelctedDrugVerifactionRxs.shift();
                    }
                } else {
                    if (this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length >= 1 ) {
                        this.TotalSelctedDrugVerifactionRxs.shift();
                        rxShifted = true;
                        this.withOutDrugPickVerfList = this.TotalSelctedDrugVerifactionRxs;
                    }
                    if (this.withDrugPickVerfList && this.withDrugPickVerfList.length >= 1 && !rxShifted ) {
                        this.TotalSelctedDrugVerifactionRxs.shift();
                        this.withDrugPickVerfList = this.TotalSelctedDrugVerifactionRxs;
                    }
                }
                this.modalRef = this._modalService.open(this.invalidRx, {
                    centered: true,
                    keyboard: false,
                    windowClass: "large--content"
                });
            } else if (this.rxInfo["PrescReFill"]["StatusId"] === 2 || this.rxInfo["Prescription"]["RxStatusId"] === 2) {
                this.rxInfo["Prescription"]["IsDiscontinued"] = false;
                this.rphverfChckd = true;
                let rxShifted = false;
                this.othrverfchcks = true;
                this.warningInfo = "<label>" + rxNo + " " + "</label>" + " is a Deferred Rx, it cannot be verified.";
                if (this.drupPickClicked) {
                    if (this.TotalSelctedDrugVerifactionRxs && this.TotalSelctedDrugVerifactionRxs.length >= 1 ) {
                        this.TotalSelctedDrugVerifactionRxs.shift();
                    }
                } else {
                    this.isDphOrRphOrRx = "Rph";
                    if (this.TotalSelctedDrugVerifactionRxs && this.TotalSelctedDrugVerifactionRxs.length >= 1 ) {
                        this.TotalSelctedDrugVerifactionRxs.shift();
                        // this.withOutDrugPickVerfList = this.TotalSelctedDrugVerifactionRxs;
                        // this.withDrugPickVerfList = this.TotalSelctedDrugVerifactionRxs;
                    }
                    // if (this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length >= 1 ) {
                    //     this.TotalSelctedDrugVerifactionRxs.shift();
                    //     rxShifted = true;
                    //     this.withOutDrugPickVerfList = this.TotalSelctedDrugVerifactionRxs;
                    // }
                    // if (this.withDrugPickVerfList && this.withDrugPickVerfList.length >= 1  && !rxShifted ) {
                    //     this.TotalSelctedDrugVerifactionRxs.shift();
                    //     this.withDrugPickVerfList = this.TotalSelctedDrugVerifactionRxs;
                    // }
                }
                this.modalRef = this._modalService.open(this.invalidRx, {
                    centered: true,
                    keyboard: false,
                    windowClass: "large--content"
                });
            } else if (this.reqRxVerfStng) {
                /*if (this.workFlwPatStng) {
                    if (this.patientInfo && this.patientInfo.IsWorkflowPatient) {
                        await this.rxVerfChecks(rxNo);
                    } else {
                        await this.remainingVerfications(rxNo);
                    }
                } else {*/
                    await this.rxVerfChecks(rxNo);
                // }
            } else {
                await this.remainingVerfications(rxNo);
            }
        } else {
            this.loadRxHistory();
            this.oldInfo = null;
        }
    }
    async remainingVerfications(rxNo) {
        if (
            this.rxInfo["RxBill"]["StatusId"] === 1 &&
            this._commonServ.getSetttingValue(
                "WorkFlowSettings",
                "ALLOW_DRUG_PICK_VERF_UNB_RX"
            ) === "0" && this.isDphOrRphOrRx === "Dpv"
        ) {
            this.rxInfo["Prescription"]["IsDiscontinued"] = false;
           this.warningInfo = "<label>" + rxNo + " " + "</label>" + "  is unbilled Rx. Cannot be Verified";
           if (this.TotalSelctedDrugVerifactionRxs && this.TotalSelctedDrugVerifactionRxs.length >= 1 ) {
                this.TotalSelctedDrugVerifactionRxs.shift();
           }
            this.modalRef = this._modalService.open(this.invalidRx, {
                centered: true,
                keyboard: false,
                windowClass: "large--content"
            });
        } else if (
            this.rxInfo["RxBill"]["StatusId"] === 1 &&
            this.rxInfo.RxBill.StatusId === 1 &&
            this._commonServ.getSetttingValue(
                "WorkFlowSettings",
                "ALLOW_RPH_VERF_UNB_RX"
            ) === "0" && this.rph) {
            this.rxInfo["Prescription"]["IsDiscontinued"] = false;
            this.rphverfChckd = true;
            this.warningInfo = "<label>" + rxNo + " " + "</label>" + "  is unbilled Rx. Cannot be Verified";
            if (this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length >= 1 ) {
                this.withOutDrugPickVerfList.shift();
                this.TotalSelctedDrugVerifactionRxs = this.withOutDrugPickVerfList;
           }
            this.modalRef = this._modalService.open(this.invalidRx, {
                centered: true,
                keyboard: false,
                windowClass: "large--content"
            });
        } else if (this.dpv) {
            this.rphverfChckd = true;
            this.isRxDiscont = this.rxInfo["Prescription"]["IsDiscontinued"] ? true : false;
            if ((this.rxInfo["rph1VerificationStatus"] && this.rxInfo.rph1VerificationStatus.trim().toLowerCase() === "r") && this.beforeDPV) {
                this.isDphOrRphOrRx = "Dpv";
            } else if (this.beforeDPV && this.orginal && (this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
                // this.isDphOrRphOrRx = "Rx";
                if (this.reqRxVerfStng) {
                    /*if (this.workFlwPatStng && this.patientInfo && this.patientInfo.IsWorkflowPatient) {
                        this.isDphOrRphOrRx = "Dpv";
                    } else {*/
                        this.isDphOrRphOrRx = "Dpv";
                    // }
                } else {
                    this.isDphOrRphOrRx = "Dpv";
                }
            } else if (!this.orginal && this.beforeDPV) {
                // this.isDphOrRphOrRx = "Rx";
                if (this.reqRxVerfStng) {
                   /* if (this.workFlwPatStng && this.patientInfo && this.patientInfo.IsWorkflowPatient) {
                        this.isDphOrRphOrRx = "Dpv";
                    } else {*/
                        this.isDphOrRphOrRx = "Dpv";
                    // }
                } else {
                    this.isDphOrRphOrRx = "Dpv";
                }
            } else {
                this.isDphOrRphOrRx = "Dpv";
            }
            this.checkIsDiscontinue();
        } else if (this.rxVerf) {
            this.rphverfChckd = true;
            this.othrverfchcks = true;
            this.isRxDiscont = this.rxInfo["Prescription"]["IsDiscontinued"] ? true : false;
            if (this.reqRxVerfStng) {
                /*if (this.workFlwPatStng) {
                    if (this.patientInfo && this.patientInfo.IsWorkflowPatient) {
                        if (this.orginal) {
                            if (this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0) {
                                await this.IsVerifiedAlready(this.isDphOrRphOrRx);
                            } else {
                                this.warningInfo = "<label>" + rxNo + " " + "</label>" + " Rx verification is required only for Original Rx's and not for Refills.";
                                this.modalRef = this._modalService.open(this.invalidRx, {centered: true, keyboard: false,
                                windowClass: "large--content"});
                            }
                        } else {
                            await this.IsVerifiedAlready(this.isDphOrRphOrRx);
                        }
                    }
                } else if (this.orginal) {
                    if (this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0) {
                        await this.IsVerifiedAlready(this.isDphOrRphOrRx);
                    } else {
                        this.warningInfo = "<label>" + rxNo + " " + "</label>" + " Rx verification is required only for Original Rx's and not for Refills.";
                        this.modalRef = this._modalService.open(this.invalidRx, {centered: true, keyboard: false,
                        windowClass: "large--content"});
                    }
                } else {*/
                    await this.IsVerifiedAlready(this.isDphOrRphOrRx);
                // }
            }
        } else {
            this.othrverfchcks = true;
            this.rphverfChckd = true;
            this.isRxDiscont = this.rxInfo["Prescription"]["IsDiscontinued"] ? true : false;
            /* if ((this.rxInfo["rph1VerificationStatus"] && this.rxInfo.rph1VerificationStatus.toLowerCase() === "r") && this.beforeRph) {
                this.rphverfChckd = false;
                this.isDphOrRphOrRx = "Rph";
            } else if (this.beforeRph && this.orginal && (this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
                // this.rphverfChckd = false;
                // this.isDphOrRphOrRx = "Rx";
                if (this.reqRxVerfStng) {
                    if (this.workFlwPatStng && this.patientInfo && this.patientInfo.IsWorkflowPatient) {
                        this.rphverfChckd = false;
                        this.isDphOrRphOrRx = "Rph";
                    } else {
                        this.rphverfChckd = false;
                        this.isDphOrRphOrRx = "Rph";
                    }
                } else {
                    this.rphverfChckd = false;
                    this.isDphOrRphOrRx = "Rph";
                }
            } else if (!this.orginal && this.beforeRph) {
                // this.isDphOrRphOrRx = "Rx";
                if (this.reqRxVerfStng) {
                    if (this.workFlwPatStng && this.patientInfo && this.patientInfo.IsWorkflowPatient) {
                        this.isDphOrRphOrRx = "Rph";
                    } else {
                        this.isDphOrRphOrRx = "Rph";
                    }
                } else {
                    this.isDphOrRphOrRx = "Rph";
                }
             }*/
            if (this.oldInfo && this.rxInfo && this.rxInfo.PrescReFill && (this.oldInfo === this.rxInfo.PrescReFill.Id)) {
                this.routeToRph();
            } else {
                this.IsVerifiedAlready();
            }
        }
    }

    async rxVerfChecks(rxNo) {
        if (this.dpv && this.orginal && this.beforeDPV &&
            !(this.rxInfo.rph1VerificationStatus && (this.rxInfo.rph1VerificationStatus.trim().toLowerCase() === "r" || this.rxInfo.rph1VerificationStatus.trim().toLowerCase() === "rv"))
             && (this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
                this.totalSelectedRphRxs = this.TotalSelctedDrugVerifactionRxs;
                this.rxInfo["Prescription"]["IsDiscontinued"] = false;
                this.isDphOrRphOrRx = "Dpv";
                this.rxVerified = (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0 && this.totalSelectedRphRxs[0]
                    && this.rxInfo["PrescReFill"]["Id"] === this.totalSelectedRphRxs[0]["PrescReFill"]["Id"]) ? true : false;
                this.warningInfo = "<label>" + rxNo + " " + "</label>" + " Rx  verification is required before Drug pick verification.";
                this.modalRef = this._modalService.open(this.invalidRx, {centered: true, keyboard: false,
                     windowClass: "large--content"});
        } else if (this.dpv && !(this.orginal) && this.beforeDPV && this.rxInfo && !(this.rxInfo["rph1VerificationStatus"] &&
            (this.rxInfo.rph1VerificationStatus.trim().toLowerCase() === "r" || this.rxInfo.rph1VerificationStatus.trim().toLowerCase() === "rv"))) {
                this.totalSelectedRphRxs = this.TotalSelctedDrugVerifactionRxs;
                this.rxInfo["Prescription"]["IsDiscontinued"] = false;
                this.isDphOrRphOrRx = "Dpv";
                this.rxVerified = (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0 && this.totalSelectedRphRxs[0]
                    && this.rxInfo["PrescReFill"]["Id"] === this.totalSelectedRphRxs[0]["PrescReFill"]["Id"]) ? true : false;
                this.warningInfo = "<label>" + rxNo + " " + "</label>" + " Rx  verification is required before Drug pick verification.";
                this.modalRef = this._modalService.open(this.invalidRx, {
                centered: true,
                keyboard: false,
                windowClass: "large--content"});
        /* else if (this.rph && this.orginal && this.beforeRph &&
            !(this.rxInfo.rph1VerificationStatus && this.rxInfo.rph1VerificationStatus.toLowerCase() === "r")
             && (this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
                this.rxInfo["Prescription"]["IsDiscontinued"] = false;
                this.fromRoutRph = false;
                this.rphverfChckd = true;
                this.isDphOrRphOrRx = "Rph";
                this.othrverfchcks = true;
                this.rxVerified = (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0
                     && this.rxInfo["PrescReFill"]["Id"] === this.totalSelectedRphRxs[0]["PrescReFill"]["Id"]) ? true : false;
                if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length === 1) {
                    this.totalSelectedRphRxs.shift();
                }
                this.warningInfo = "<label>" + rxNo + " " + "</label>" +
                " Rx  verification is required before pharmacist verification.";
                this.modalRef = this._modalService.open(this.invalidRx, {
                centered: true,
                keyboard: false,
                windowClass: "large--content"});
        } else if (this.rph && !(this.orginal) && this.beforeRph && !(this.rxInfo["rph1VerificationStatus"] &&
            this.rxInfo.rph1VerificationStatus.toLowerCase() === "r")) {
                this.rxInfo["Prescription"]["IsDiscontinued"] = false;
                this.fromRoutRph = false;
                this.rphverfChckd = true;
                this.isDphOrRphOrRx = "Rph";
                this.othrverfchcks = true;
                this.rxVerified = (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0
                 && this.rxInfo["PrescReFill"]["Id"] === this.totalSelectedRphRxs[0]["PrescReFill"]["Id"]) ? true : false;
                 if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length === 1) {
                    this.totalSelectedRphRxs.shift();
                }
                this.warningInfo = "<label>" + rxNo + " " + "</label>" +
                " Rx  verification is required before pharmacist verification.";
                this.modalRef = this._modalService.open(this.invalidRx, {
                centered: true,
                keyboard: false,
                windowClass: "large--content"});
         }*/
        } else {
            await this.remainingVerfications(rxNo);
        }
    }
    setDefaultValuesForRphDpv() {
        this.rph = false;
        this.dpv = false;
        this.rxVerf = false;
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
    }

    // closeDpvRphRx() {
    //     if (this.dpv) {
    //         this.processDrugPickVerification();
    //     }
    //     if (this.rph) {
    //         this.fromRoutRph = ((this.rxInfo && this.totalSelectedRphRxs && this.totalSelectedRphRxs.length > 0 &&
    //             this.rxInfo.PrescReFill.Id === this.totalSelectedRphRxs[0]["PrescReFill"]["Id"]) ? true : false);
    //         this.closeDrugPickVerf();
    //     }

    //     if (this.rxVerf) {
    //         this.processRxVerfication();
    //     }
    // }

    async IsVerifiedAlready(frm ?: string) {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        if (this.rxInfo) {
            if (!((this.rxInfo.rph1VerificationStatus && (this.rxInfo.rph1VerificationStatus.trim().toUpperCase() === "RV")) ||
            (this.rxInfo.rph2VerificationStatus && (this.rxInfo.rph2VerificationStatus.trim().toUpperCase() === "RV")))) {
            const resp = await this._rphService.IsRphVerifiedAlready(
                this.rxInfo.Prescription.Id,
                this.rxInfo.PrescReFill.Id,
                frm === "Rx" ? true : false).toPromise();
                // subscribe(async resp => {
                if (resp && ((frm === "Rx" && resp["Status"] && resp["Status"].trim() === "R") ||
                 (frm !== "Rx" && resp["Status"] && resp["Status"].trim() === "V"))) {
                   this.oldInfo = this.rxInfo.PrescReFill.Id;
                    this.verifiedAlready = resp;
                    this.rxVerf = (frm === "Rx") ? true : false;
                    this.TotalSelctedDrugVerifactionRxs = this.TotalSelctedDrugVerifactionRxs.filter((item: any) => {
                        return (item.PrescReFill.Id !== this.rxInfo.PrescReFill.Id);
                    });
                    if (this.TotalSelctedDrugVerifactionRxs && !this.TotalSelctedDrugVerifactionRxs.length
                         && this.totalSelectedRphRxs && this.totalSelectedRphRxs.length === 1) {
                        this.totalSelectedRphRxs = this.TotalSelctedDrugVerifactionRxs;
                    }
                    if ((this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length > 0) ||
                     (this.withDrugPickVerfList && this.withDrugPickVerfList.length > 0)) {
                    this.withOutDrugPickVerfList = [];
                    this.withDrugPickVerfList = [];
                    this.TotalSelctedDrugVerifactionRxs.filter((x: any) => {
                        if (!x.drugPickVerificationStatus || ((x.drugPickVerificationStatus &&
                             x.drugPickVerificationStatus.trim()).toLowerCase() !== "p")) {
                            this.withOutDrugPickVerfList.push(x);
                        } else {
                            this.withDrugPickVerfList.push(x);
                        }
                    });
                    }
                    this.rphverfChckd = this.rphverfChckd ? true : false;
                    this.rxVerf = this.rph ? false : true;
                    this.notYetVerf = false;
                    if (this.TotalSelctedDrugVerifactionRxs && this.TotalSelctedDrugVerifactionRxs.length > 0 && this.rxInfo) {
                        this.rxVerified = (this.totalSelectedRphRxs[0] && (this.TotalSelctedDrugVerifactionRxs[0]["PrescReFill"]["Id"] === this.rxInfo.PrescReFill.Id)) ? true : false;
                    }
                    if (this.withDrugPickVerfList && this.withDrugPickVerfList.length >= 1 && this.rxInfo && (this.rxInfo.PrescReFill.Id === this.withDrugPickVerfList[0]["PrescReFill"]["Id"])) {
                        this.withDrugPickVerfList.shift();
                        this.TotalSelctedDrugVerifactionRxs = this.withDrugPickVerfList;
                    }
                    this.rphverfChckd = true;
                    this.fromRoutRph = false;
                    this.modalRef = this._modalService.open(this.AlreadyVerif, { centered: true,keyboard: false, windowClass: "large--content"});
                } else {
                    this.rphverfChckd = this.rphverfChckd ? false : true;
                    await this.checkIsDiscontinue();
                }
            // });
        } else {
            this.rphverfChckd = this.rphverfChckd ? false : true;
            await this.checkIsDiscontinue();
        }
        } else {
            this.loadRxHistory();
            this.oldInfo = null;
        }
    }

    async checkIsDiscontinue() {
        if (this.rxInfo && this.rxInfo["Prescription"] && this.rxInfo["Prescription"]["IsDiscontinued"]) {
            this.disContinueOpenFr = this.isDphOrRphOrRx;
            if (!this.isRxDiscont) {
                await this.otherVerificationChecks();
            } else {
            if (this.withDrugPickVerfList && this.withDrugPickVerfList.length >= 1 ) {
                this.withDrugPickVerfList.shift();
            }
            this.modalRef = this._modalService.open(this.RxDiscontinued, { centered: true,
                keyboard: false,
                backdrop: false,
                windowClass: "large--content"});
            }
        } else {
            if ((!this.rxInfo["drugPickVerificationStatus"] || ( this.rxInfo["drugPickVerificationStatus"] && (this.rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "rv" || this.rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "nv"))) && (this._commonServ.getSetttingValue(
                "WorkFlowSettings",
                "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF"
            ) === "1") && this.rph && !this.rphverfChckd) {
                this.isDphOrRphOrRx = "Dpv";
            }
            if ((this.rxInfo["drugPickVerificationStatus"] && this.rxInfo["drugPickVerificationStatus"].toUpperCase() === "P") &&
            (this._commonServ.getSetttingValue(
                "WorkFlowSettings",
                "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF"
            ) === "1") && this.rphClicked ) {
                this.isDphOrRphOrRx = "Rph";
            }
            if (this.isDphOrRphOrRx === "Dpv") {
                this.openDrugPickVerf();
            } else {
                /* if (this.rphverfChckd && this.withOutDrugPickVerfList.length >= 1 &&
                //      !(this.rxInfo["drugPickVerificationStatus"] && this.rxInfo["drugPickVerificationStatus"].toUpperCase() === "P")
                //      && (this.beforeRph && !(this.rxInfo.rph1VerificationStatus && this.rxInfo.rph1VerificationStatus.toLowerCase() === "r")
                //       && !this.orginal && !(this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0))) {
                //          if ((this._commonServ.getSetttingValue("WorkFlowSettings", "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF") === "1")) {
                //             this.isDphOrRphOrRx = "Dpv";
                //         }
                //         this.otherVerificationChecks();
                // } else {*/
                    this.rphverfChckd = ((this.rxInfo && this.rxInfo.rph2VerificationStatus &&
                         this.rxInfo.rph2VerificationStatus.trim().toLowerCase() === "v") && (this.rxInfo.rph1VerificationStatus &&
                            this.rxInfo.rph1VerificationStatus.trim().toLowerCase() === "r")) ? false : true;
                /*     if (this.beforeRph) {
                //      if (!this.orginal) {
                //              if (this.rph && !(this.rxInfo["rph1VerificationStatus"] && this.rxInfo.rph1VerificationStatus.toLowerCase() === "r") && !this.othrverfchcks) {
                //                 this.otherVerificationChecks();
                //              } else {
                //                 this.routeToRph();
                //              }
                //       } else if (this.rphverfChckd && this.orginal && (this.drupPickClicked || this.rphClicked) &&
                //             (this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0) && !this.othrverfchcks) {
                //                 this.otherVerificationChecks();
                //       } else if (!(this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0) && (this.drupPickClicked || this.rphClicked) &&
                //             this.rphverfChckd && !this.othrverfchcks) {
                //                 this.otherVerificationChecks();
                //       } else {
                //         this.oldInfo = this.rxInfo.PrescReFill.Id;
                //         await this.routeToRph();
                //       }
                //  } else {*/
                    if (!this.othrverfchcks) {
                        await this.otherVerificationChecks();
                    } else {
                        this.oldInfo = this.rxInfo.PrescReFill.Id;
                        await this.routeToRph();
                    }
                    // }
                // }
            }
        }
    }

    openDrugPickVerf() {
        if (!this._privMaskGuard.canActivate(AccCategoryE.Rx1, Rx1E.DrugPickVerification)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
            this.reloadRxHistory(null);
        } else{
        if (this.modalRef) {
            this.modalRef.close();
        }
        // this._patPreDrugModalServ._patPreDrugModal$.next("DrugPickVerification");
        // this.modalRef = this._patPreDrugModalServ.modalInstanceRef;
        const modalRefVerfTab = this._modalService.open(VerificationTabsListComponent, {size: "lg",  windowClass: "md-xx-screenWidth comp--modal modal-large modal-center-to-page", backdrop: false});
        modalRefVerfTab.componentInstance.RxInfo = this.rxInfo;
        modalRefVerfTab.componentInstance.IsPatientHistory = true;
        modalRefVerfTab.componentInstance.activeTabId = "dpvVerfTab";
        modalRefVerfTab.componentInstance.closeTabs.subscribe((res: any) => {
            if (res) {
                if (modalRefVerfTab) {
                    modalRefVerfTab.close();
                    this.dpv = false;
                    this.TotalSelctedDrugVerifactionRxs = this.TotalSelctedDrugVerifactionRxs.filter((item: any) => {
                        return (item.PrescReFill.Id !== this.rxInfo.PrescReFill.Id);
                    });
                    this.totalSelectedRphRxs.shift();
                    this.processDrugPickVerification();
                }
            }
        });
    }
}
    routeToRph() {
        if (!this._privMaskGuard.canActivate(AccCategoryE.Rx1, (this.isDphOrRphOrRx === "Rx" ?  Rx1E.RxVerification: Rx1E.PharmacistVerification))) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
            this.reloadRxHistory(null);
        } else{
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        // this._patPreDrugModalServ._patPreDrugModal$.next("RphVerification");
        // const modalRef = this._patPreDrugModalServ.modalInstanceRef;
        let rxInfoToSend: any;
        rxInfoToSend = this.TotalRxInfo.filter((item: any) => item.PrescReFill.Id === this.rxInfo.PrescReFill.Id);
        if (this.isDphOrRphOrRx === "Rx") {
            rxInfoToSend = this.totalRxsCountForRxVerf.filter((item: any) => item.PrescReFill.Id === this.rxInfo.PrescReFill.Id);
        }
        const modalRefVerfTab = this._modalService.open(VerificationTabsListComponent, {size: "lg",  windowClass: "md-xx-screenWidth comp--modal modal-large modal-center-to-page", backdrop: false});
        modalRefVerfTab.componentInstance.IsPatientHistory = true;
        modalRefVerfTab.componentInstance.RxID = this.rxInfo.Prescription.PrescNum ;
        modalRefVerfTab.componentInstance.RefilID = this.rxInfo.PrescReFill.ReFillNum ;
        modalRefVerfTab.componentInstance.FillID = this.rxInfo.PrescReFill.PartialFillNo;
        modalRefVerfTab.componentInstance.RefillNumId = this.rxInfo.PrescReFill.Id;
        modalRefVerfTab.componentInstance.IsRxVerification = this.isDphOrRphOrRx === "Rx" ? true : false;
        modalRefVerfTab.componentInstance.RxInfo = rxInfoToSend[0];
        modalRefVerfTab.componentInstance.activeTabId = this.isDphOrRphOrRx === "Rx" ?  "rxVerfTab" : "rphVerfTab";
        modalRefVerfTab.componentInstance.closeTabs.subscribe((x: any) => {
        // if (this.isDphOrRphOrRx === "Rx") {
        //     modalRef.componentInstance.IsRxVerification = true;
        // }
        // modalRef.componentInstance.IsPatientHistory = true;
        // modalRef.componentInstance.RxID = this.rxInfo.Prescription.PrescNum ;
        // modalRef.componentInstance.RefilID = this.rxInfo.PrescReFill.ReFillNum ;
        // modalRef.componentInstance.FillId = this.rxInfo.PrescReFill.PartialFillNo;
        // modalRef.componentInstance.RefillNumId = this.rxInfo.PrescReFill.Id;
        // modalRef.componentInstance.totalRxInfo = this.totalRxsCountForRxVerf;
        // modalRef.componentInstance.RxInfo = this.isDphOrRphOrRx === "Rx" ? this.totalRxsCountForRxVerf : this.totalSelectedRphRxs;
        // modalRef.componentInstance.CloseRphModal.subscribe(x => {
            if (x) {
                this.rph = false;
                modalRefVerfTab.close();
                this.activeModal = null;
                if (!(typeof x === "boolean")) {
                    x.map((id: any) => {
                        this.selctIndex = this.totalSelectedRphRxs.findIndex((item: any) => item.PrescReFill.Id === parseInt(id, 0));
                        this.totalSelectedRphRxs.splice(this.selctIndex, 1);
                    });
                }
                if (this.isDphOrRphOrRx === "Rx") {
                    this.processRxVerfication();
                } else if (this.isDphOrRphOrRx === "Rph") {
                    this.rph = true;
                    this.totalSelectedRphRxsshifted = false;
                    if (this.totalSelectedRphRxs && this.totalSelectedRphRxs.length >= 1 &&
                        (this.rxInfo && this.rxInfo.PrescReFill && this.totalSelectedRphRxs[0] &&
                        this.totalSelectedRphRxs[0].PrescReFill &&
                         (this.rxInfo.PrescReFill.Id === this.totalSelectedRphRxs[0]["PrescReFill"]["Id"]))) {
                        this.totalSelectedRphRxs.shift();
                        this.totalSelectedRphRxsshifted = true;
                    }
                    this.TotalSelctedDrugVerifactionRxs = this.totalSelectedRphRxs;
                    this.TotalSelctedDrugVerifactionRxs = this.TotalSelctedDrugVerifactionRxs.filter((item: any) => {
                        return (item.PrescReFill.Id !== this.rxInfo.PrescReFill.Id);
                    });
                    if (this.rxInfo && this.withDrugPickVerfList && this.withDrugPickVerfList.length > 0 &&
                        (this.rxInfo && this.rxInfo.PrescReFill && this.withDrugPickVerfList[0] &&
                        this.withDrugPickVerfList[0].PrescReFill &&
                        (this.rxInfo.PrescReFill.Id === this.withDrugPickVerfList[0].PrescReFill.Id))
                              && !this.totalSelectedRphRxsshifted) {
                        this.alreadyshifted = (this.rxInfo.PrescReFill.Id === this.withDrugPickVerfList[0].PrescReFill.Id) ? true : false;
                        this.totalSelectedRphRxs.shift();
                    }
                    if (this.rxInfo && this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length > 0 &&
                        (this.rxInfo && this.rxInfo.PrescReFill && this.withOutDrugPickVerfList[0] &&
                            this.withOutDrugPickVerfList[0].PrescReFill &&
                        (this.rxInfo.PrescReFill.Id === this.withOutDrugPickVerfList[0].PrescReFill.Id))) {
                         this.withOutDrugPickVerfList.shift();
                    }
                    if (this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length > 1 &&
                         this.TotalSelctedDrugVerifactionRxs && this.TotalSelctedDrugVerifactionRxs.length > 0) {
                    this.withOutDrugPickVerfList = [];
                    this.withDrugPickVerfList = [];
                    this.TotalSelctedDrugVerifactionRxs.filter((x: any) => {
                        if (!x.drugPickVerificationStatus || ((x.drugPickVerificationStatus
                             && x.drugPickVerificationStatus.trim()).toLowerCase() !== "p")) {
                            this.withOutDrugPickVerfList.push(x);
                        } else {
                            this.withDrugPickVerfList.push(x);
                        }
                        });
                        this.fromRoutRph = true;
                    } else if (this.withDrugPickVerfList && this.withDrugPickVerfList.length > 1
                        && (this.rxInfo && this.rxInfo.PrescReFill && this.withDrugPickVerfList[0] &&
                            this.withDrugPickVerfList[0].PrescReFill &&
                             (this.rxInfo.PrescReFill.Id === this.withDrugPickVerfList[0]["PrescReFill"]["Id"]))) {
                        this.fromRoutRph = true;
                        this.notYetVerf = false;
                        this.withDrugPickVerfList.shift();
                    } else {
                        if (this.withOutDrugPickVerfList && this.withOutDrugPickVerfList.length > 1) {
                            this.withOutDrugPickVerfList = this.TotalSelctedDrugVerifactionRxs;
                        }
                        if (this.withDrugPickVerfList && this.withDrugPickVerfList.length > 1) {
                            this.notYetVerf = true;
                            this.withDrugPickVerfList = this.TotalSelctedDrugVerifactionRxs;
                        }
                        this.fromRoutRph = true;
                    }
                    this.closeDrugPickVerf();
                }
            }
    });
    }
}

    closeActiveModalAndReLoad(val?: string) {
        if (val && val === "SendRefillReq") {
            this.isRefillReq = false;
        }
        this.activeModal = null;
        this.loadRxHistory();
    }

    async openActionModal(modalType) {
        if (this.modalRefTrOut) {
            this.modalRefTrOut.close();
        }
        if(modalType == false ||  modalType == true) {
            setTimeout(() => {
                this.activeModal = null;
            },1000);
        }  else if(modalType === "XFRIN" &&  !this.TotalRxInfo.length && this.IseRxTransfer) {
            this.IseRxTRxREQType = "TransferInReq";
            return;
        }
        else if ((modalType === "PrintRxReport" ||
            modalType === "checkDur" ||
            modalType === null ||
            this.checkForRxSelect(modalType))
        ) {
            if (
                modalType !== "printFax" &&
                modalType !== null &&
                modalType !== "QuickRefills" &&
                modalType !== "TR-NOW"   &&
                modalType !== "Refill" &&
                modalType !== "PrintRxReport" &&
                modalType !== "MarkAsVerf" &&
                modalType !== "DeleteRx" &&
                modalType !== "XFROUT"  &&
                modalType !== "QuickLabel" &&
                modalType !== "DrugPickVerification" &&
                modalType !== "RphVerification" &&
                modalType !== "RxVerification" &&
                modalType !== "PrintRxForm" &&
                modalType !== "PrintRefillReqForm"
            ) {
                if (this.IsMultiselect()) {
                    this.loadRxHistory();
                    return;
                }
            }
            if (modalType === "QuickLabel") {
                this.processQuickLabel();
            }
            if (modalType === "DrugPickVerification") {
                this.drupPickClicked = true;
                if (!this.checkHasPrivsToAccess(this.accessPrivCate.Rx1, this.rx1Privs.DrugPickVerification)) {
                    this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
                    this.reloadRxHistory(null);
                } else {
                    if (sessionStorage.getItem("drug-pick")) {
                        this.processDrugPickVerification();
                        return;
                    } else {
                        this.activeModal = "DrugPickVerification";
                    }
                }
            }
            if (modalType === "TR-NOW") {
                if(this._nrxUtls.hasEntryRxTransmissnPrv()) {
                    this.intiateTransProcess();
                } else
                    this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
            }
            if (modalType === "RphVerification") {
                this.drupPickClicked = false;
                this.rphClicked = true;
                this.dpv = false;
                if (!this.checkHasPrivsToAccess(this.accessPrivCate.Rx1, this.rx1Privs.PharmacistVerification)) {
                    this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
                    this.reloadRxHistory(null);
                } else {
                    if (sessionStorage.getItem("rph-verify")) {
                        this.withoutAlreadyshifted = false;
                        this.totalRxsCountForRxVerf = [...this.totalSelectedRphRxs];
                        this.processRphVerfMultiple();
                    } else {
                        this.openPopUp = true;
                        this.activeModal = "RphVerification";
                    }
                }  
            }
            if (modalType === "RxVerification") {
                this.drupPickClicked = false;
                this.rphClicked = false;
                if (!this.checkHasPrivsToAccess(this.accessPrivCate.Rx1, this.rx1Privs.RxVerification)) {
                    this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
                    this.reloadRxHistory(null);
                } else {
                    if (sessionStorage.getItem("rx-verify")) {
                        this.processRxVerfication();
                    } else {
                        this.openPopUp = true;
                        this.activeModal = "RxVerification";
                    }
                }
            }
            if (modalType === "rxToNewRxrequest") {
                if (this.TotalRxInfo && this.TotalRxInfo.length) {
                    this.emitRxForNewRxRequest.emit(this.TotalRxInfo[0]);
                }
            }
            if (modalType === "Refill") {
                if (!this.checkHasPrivsToAccess(this.accessPrivCate.Rx, this.rxPrivs.RefillRx)) {
                    this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
                    return;
                } else this.processRefill(true);
            }
            if (modalType === "XFROUT" || modalType === "XFRIN") {
                this.activeModal = modalType;
                this.transfData = {Type: modalType === "XFROUT" ? "Out" : "In",
                rxNo: modalType === "XFROUT" ? this.rxInfo["Prescription"]["PrescNum"] : this.rxNum,
                RefillNo: modalType === "XFRIN" ? this.rxInfo["PrescReFill"]["ReFillNum"] : null,
                prescRefillId: modalType === "XFRIN" ? this.rxInfo["PrescReFill"]["Id"] : null,
                FillNo: modalType === "XFROUT" ? (this._commonServ.checkIsPartialFill(this.rxInfo["PrescReFill"], this.rxInfo["Prescription"]) ? this.rxInfo["PrescReFill"]["PartialFillNo"] : "") : null
                };
             
                if(modalType === "XFRIN" && !this.checkHasPrivsToAccess(this.accessPrivCate.RxTransfer, this.tranferPrivs.AllowRxTransferIn)) {
                    this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS, false, "long");
                    this.loadRxHistory();
                    return;
                }  
                if (modalType === "XFROUT" && !this.checkHasPrivsToAccess(this.accessPrivCate.RxTransfer, this.tranferPrivs.AllowRxTransferOut)) {
                    this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS, false, "long");
                    this.loadRxHistory();
                    return;
                }
                let rxData = this.TotalRxInfo;
                let rxNumbersCombined = "";
                let duplicateRxNumbersExist = false;
                if(rxData[0].PrescReFill.ReFillNum && this.IseRxTransfer) {
                    this._alertSvc.error("Refills cannot be transferred. Only the original prescription (Refill# 0) can be marked as a transfer-in. Please select the original prescription for transfer-in.", false, "long");
                    return;
                }
                this.TotalRxInfo.reduce(function(arr, e, i) {
                    if (rxNumbersCombined.includes(e.Prescription.PrescNum)) {
                        rxData.splice(i);
                        duplicateRxNumbersExist = true;
                    }
                    rxNumbersCombined = rxNumbersCombined + "," + e.Prescription.PrescNum;
                }, []);
                this.TotalRxInfo = rxData;
                this.rxInfo = this.TotalRxInfo[0];
                if (this.rxInfo && duplicateRxNumbersExist) {
                    this.modalRefTrOut = this._modalService.open(this.multipleSameRxTrOut,
                         {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"
                    });
                } else {
                    await this.getRxDetailsTransfer();
                    // this.holdRx = this.holdRxData;
                    if (this.holdRxData || this.clearData) {
                        this.holdRxData = false;
                        this.clearData = false;
                        if (this.grpTransOutRxs && this.currentIndex <= (this.grpTransOutRxs.length -1)) {
                            this.currentIndex++;
                        } else {
                            this.currentIndex = 0;
                        }
                        if(modalType === "XFRIN" && this.IseRxTransfer) {
                            this.IseRxTRxREQType = "MarkTransferIn";
                        } else
                             this._modalService.open(this.RXINMODAL , {centered: true, size: "lg", windowClass: "md-x-lg_77", backdrop: false});
                    }
                }
            }
            if (modalType === "DeleteRx") {
                if (!this.checkHasPrivsToAccess(this.accessPrivCate.EditRx, this.editRxPrivs.DeleteRx)) {
                    this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
                    this.reloadRxHistory(null);
                } else {
                    this.processDeletRx();
                    // this.activeModal = modalType;
                }
            } 
            if (modalType === "QuickRefills") {
                if (!this.checkHasPrivsToAccess(this.accessPrivCate.Rx, this.rxPrivs.QuickRefill)) {
                    this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
                    return;
                }               
            }
            if(modalType === "BefitCheck") {
                if (this.TotalRxInfo && this.TotalRxInfo.length) {
                    this.openBenefitCheckModal();
                }
            }
            this.activeModal = modalType;
            return;
        } else if (!modalType) {
            this.activeModal = modalType;
        }
    }
    processRefill(isFromPop: boolean = false) {
        if(isFromPop && this.TotalRxInfo && this.TotalRxInfo.length>1) {
            const totalRxInfo = [...this.TotalRxInfo];
            const rxPrescList =  totalRxInfo.splice(0, 1);
            this.rxInfo = totalRxInfo[0];
            if (this.rxInfo && (this.rxInfo.Prescription.PrescNum === rxPrescList[0].Prescription.PrescNum)) {
                this.TotalRxInfo.splice(0, 1)
                this.modalRefTrOut = this._modalService.open(this.CommonWarning,
                        {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"
                });
             }  else {
                this.TotalRxInfo = this.totalSelectedRphRxs;
                 this.processRefillFlow();
             }
         }  else {
           this.processRefillFlow();
        }
    }
    processRefillFlow() {
        if (this.modalRefTrOut) {
            this.modalRefTrOut.close();
        }
        const prescNum = this.TotalRxInfo.map(val => {
            return val["Prescription"]["PrescNum"];
        });
        this.closePopUp.emit("refill");
        const data = { selectedRxs: prescNum, frm: this.activeScreen === "Rx" ? "RxHist" : "PatHis", completeData: prescNum,
         patHistData: this.activeScreen === "Rx" ? this.SearchParams.value : this.TotalRxInfo[0]["Patient"]["patientid"]};
        if (this.activeScreen === "Rx") {
            this.SearchParams.controls["refillFrmHist"].patchValue(true);
        }
        localStorage.setItem("previousRefillScreen", this._commonServ.encryptData("Refill"));
        this._commonStore.storeRefInfo(data);
        this._commonStore.storeRfStage("Start");
    }
    drugMissingValuesClosingPop(isSelectedTYpe) {
        if (isSelectedTYpe !== "cancel") {
            this.selectedRxForBenefitCheck =   this.TotalRxInfo[0];
            this.closePopUp.emit("BenefitCheck");
            this._patPreDrugModalServ._patPreDrugModal$.next("BenefitCheck");
            const modalRef = this._patPreDrugModalServ.modalInstanceRef;
            modalRef.componentInstance.IsBenfitCheckfromRxHistory = true;
            modalRef.componentInstance.PrescReFill = this.TotalRxInfo[0]["PrescReFill"]["Id"];
            modalRef.componentInstance.SelectedRxDetails =  this.TotalRxInfo[0];
            modalRef.componentInstance.RxDetails = {PrescNum : this.TotalRxInfo[0]["Prescription"]["PrescNum"] ,
             Refill : this.TotalRxInfo[0]["PrescReFill"]["ReFillNum"] , FillNo:
             this._commonServ.checkIsPartialFill(this.TotalRxInfo[0]["PrescReFill"], this.TotalRxInfo[0]["Prescription"]) ? this.TotalRxInfo[0]["PrescReFill"]["PartialFillNo"] : " -- "
            };
            modalRef.componentInstance.CloseBenfitCheckModal.subscribe(closed => {
                modalRef.close();
                this.isShowDrugValuesPop = false;
                if (closed === "Refill") {
                    this.openActionModal("Refill");
                } else if (closed === "changeReq" || closed === "paReq") {
                    this.selectedBenefitCheckType = closed;
                    this.activeModal = null;
                    this._cdr.detectChanges();
                    this.activeModal = "ERxChange";
                } else {
                    this.loadRxHistory();
                }
                this._patPreDrugModalServ._patPreDrugModal$.next(null);
            });
        } else {
             this.isShowDrugValuesPop = false;
        }
    }
    openBenefitCheckModal() {
        this.isShowDrugValuesPop = true;
        this.drugInfo =  this.TotalRxInfo[0]["Drug"];
    }
    afterVerifyDrugPickVerificationp(event) {
        setTimeout(() => {
            this.activeModal = null;
        }, 30);
        if (sessionStorage.getItem("drug-pick")) {

            this.processDrugPickVerification();
         } else {
            this.reloadRxHistory(null);
         }
    }

    afterVerifyRxVerification(event) {
        this.openPopUp = event;
        if (sessionStorage.getItem("rx-verify")) {
            this.processRxVerfication();
        } else {
            this.reloadRxHistory(null);
        }
    }

    afterVerifyRphVerification(event) {
        this.openPopUp = event;
        if (sessionStorage.getItem("rph-verify")) {
            // this.processRphVerfication();
            this.totalRxsCountForRxVerf = [...this.totalSelectedRphRxs];
            this.processRphVerfMultiple();
        } else {
            this.reloadRxHistory(null);
        }
    }

    checkTrOut(type) {
        if (this.transfData && this.transfData["Type"] === "In") {
            this.openActionModal(null);
            if (type) {
                this.loadRxHistory();
            }
        } else {
            if (this.TotalRxInfo && this.TotalRxInfo.length >= 1) {
                if (this.TotalRxInfo.length > 0) {
                    this.TotalRxInfo.splice(0, 1);
                }
                if(this.TotalRxInfo.length > 0) {
                    this.rxInfo = this.TotalRxInfo[0];
                    this.openActionModal("XFROUT");
                } else {
                    this.currentIndex = 0;
                    this.finalValidList = [];
                    if (this.showRxTransferOut && localStorage.getItem("Restore") && this._commonServ.DecryptData(localStorage.getItem("Restore"))) {
                        localStorage.removeItem("Restore");
                    }
                    if (this.showRxTransferOut && localStorage.getItem("RestoreAddrData") && this._commonServ.DecryptData(localStorage.getItem("RestoreAddrData"))) {
                        localStorage.removeItem("RestoreAddrData");
                    }
                    if (this.showRxTransferOut &&  this.grpTransOutRxs && this.grpTransOutRxs.length > 0) {
                        this.grpTransOutRxs = [];
                    }
                    this.openActionModal(null);
                    this.loadRxHistory();
                }
            } else {
                this.finalValidList = [];
                if (this.showRxTransferOut && this.grpTransOutRxs && this.grpTransOutRxs.length > 0) {
                    this.grpTransOutRxs = [];
                }
                if (this.showRxTransferOut && localStorage.getItem("Restore") && this._commonServ.DecryptData(localStorage.getItem("Restore"))) {
                    localStorage.removeItem("Restore");
                }
                if (this.showRxTransferOut &&  localStorage.getItem("RestoreAddrData") && this._commonServ.DecryptData(localStorage.getItem("RestoreAddrData"))) {
                    localStorage.removeItem("RestoreAddrData");
                }
                this.openActionModal(null);
                this.loadRxHistory();
            }
        }
    }
    processQuickLabel() {
        this.rxInfo = this.printRxRecords.shift();
        this.rxInfo.PrescReFill.PartialFillNo  =  this._commonServ.checkIsPartialFill(this.rxInfo.PrescReFill, this.rxInfo.Prescription) ? this.rxInfo.PrescReFill.PartialFillNo : " -- ";
        this._commonPrintSer.printLabelToRx(this.rxInfo, false, false, false, null, "RxScreen");
        this.afterPintLabelCompleted();
    }

    afterPintLabelCompleted() {
        this.subscription1 =  this._commonPrintSer.isLabelPrintCompleted().subscribe((res: any) => {
            if (res && (this.rxInfo && this.rxInfo.PrescReFill &&
                 (res.PrescNum === this.rxInfo.Prescription.PrescNum) && (res.PrescReFill === this.rxInfo.PrescReFill.Id))) {
                    if (res && (!res.type || res.type === "SaveAsPdf") && res.fromScreen === "RxScreen") {
                        this._labelQueueService.IsExistInLabelQueue(res.PrescReFill).subscribe(resp => {
                            if (resp) {
                                this.removeFromLabelQueue(res);
                            } else {
                                this.CheckForOtherSelectedRx();
                            }
                        });
                    } else if (res && (res.type === "Cancel" || res.type === "Queue")) {
                        this.CheckForOtherSelectedRx();
                    } else {
                        this.CheckForOtherSelectedRx();
                    }
            }
        });
    }

    CheckForOtherSelectedRx() {
        if (this.printRxRecords && this.printRxRecords.length > 0) {
            this.rxInfo = this.printRxRecords.shift();
               setTimeout(() => {
                       this._commonPrintSer.printLabelToRx(this.rxInfo, false, false, false, null, "RxScreen");
                    //    return;
                }, 10);
         } else {
            if (this.subscription1) {
                this.subscription1.unsubscribe();
            }
            this._commonPrintSer.setPrintCompleted(null);
             this.loadRxHistory();
         }
    }

    async removeFromLabelQueue(data) {
        if (data.type === "SaveAsPdf") {
        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
            });
            modelRef.componentInstance.warnMsg = " Do you want to remove the selected label from the label queue?";
            modelRef.componentInstance.IsHeaderText = "Confirmation";
            modelRef.componentInstance.yesButton = true;
            modelRef.componentInstance.noButton = true;
            modelRef.componentInstance.IsPopUpClosed.subscribe(async (response: any) => {
                modelRef.close();
                if (response) {
                    await this._labelQueueService.deleteRxsFromLabelQueue([{PrescRefillId : data.PrescReFill }]).toPromise();
                    this.getLabelCount();
                    this.CheckForOtherSelectedRx();
                } else {
                    this.CheckForOtherSelectedRx();
                }
            });
        } else if (!data.type) {
           await this._labelQueueService.deleteRxsFromLabelQueue([{PrescRefillId : data.PrescReFill }]).toPromise();
            this.getLabelCount();
            this.CheckForOtherSelectedRx();
        }
    }

    getLabelCount() {
        const dateParams = {
            StartDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
            EndDate: moment().subtract(0, "days").format("YYYY-MM-DD")
        };
        this._commonServ.getSideNavCounts(dateParams);
    }

    processDeletRx() {
        this.deletRxRecords = this.TotalRxInfo;
        if (this.deletRxRecords && this.deletRxRecords.length) {
            this.deletRxRecords = this.deletRxRecords.sort((a: any, b: any) => {
                return b.PrescReFill.ReFillNum - a.PrescReFill.ReFillNum;
        });
        }
        this.processSingleDeleteRx();
    }

    processSingleDeleteRx() {
        if (this.deletRxRecords && this.deletRxRecords.length) {
            this.rxInfo = this.deletRxRecords.shift();
            this.openFrm = 'patHis';
            this.activeModal = "DeleteRx";
        } else {
            this.openFrm = "";
            this.reloadRxHistory(null);
        }
    }
    closeDeleteRx() {
        this.activeModal = null;
        setTimeout(() => {
            this.processSingleDeleteRx();
        }, 10);
    }
    openRegRef() {
        sessionStorage.setItem("refillQueueRx", null);
        const storeRx = JSON.stringify(this.selectedRxsFrReg);
        sessionStorage.setItem("selectedRxs", storeRx);
        if (this.selectedRxsFrReg["length"] > 1) {
            sessionStorage.setItem("refillQueueRx", storeRx);
        }
        if (this.selectedRxsFrReg["length"] > 0) {
            this.rxNum = this.selectedRxsFrReg[0]["Prescnum"];
            this.activeModal = "Refill";
            sessionStorage.setItem("routeAfterRef", "" + this.activeScreen);
        }
    }

    regRefAbort(val) {
        if (val) {
            this.activeModal = "";
            this._cdr.detectChanges();
            const RxS: any = JSON.parse(sessionStorage.getItem("selectedRxs"));
            RxS.splice(0, 1);
            this.selectedRxsFrReg = RxS;
            this.openRegRef();
        }
    }

    nextRx(val) {
        this._router.navigate(["eprx/dashboard"]);
    }
    closeRefill(ev: any) {
        this.activeModal = ev;
    }

    updatePharmacistStatus() {
        if (this.checkForRxSelect()) {
            if (this.IsMultiselect()) {
                return;
            }
            if (
                this.rxInfo.RxBill.StatusId === 1 &&
                this._commonServ.getSetttingValue("WorkFlowSettings", "ALLOW_RPH_VERF_UNB_RX") === "0"
            ) {
                this._alertSvc.error("Rx is unbilled.");
            } else if (this.rxInfo["PrescReFill"]["StatusId"] === 2 || this.rxInfo["Prescription"]["RxStatusId"] === 2) {
                this._alertSvc.error("Selected Rx is Filed, Cannot be verified.");
            } else if (this.rxInfo["PrescReFill"]["StatusId"] === 4 || this.rxInfo["Prescription"]["RxStatusId"] === 4) {
                this._alertSvc.error("Selected Rx is Transfered, Cannot be verified.");
            } else if (
                this.rxInfo.rph2VerificationStatus === "F" ||
                this.rxInfo.rph2VerificationStatus === null
            ) {
                this.modalRefSM = this._modalService.open(
                    this.Pharmaverfication,
                    {
                        centered: true,
                        backdrop: false,
                        keyboard: false,
                        windowClass: "large--content"
                    }
                );
            } else if (this.rxInfo.rph2VerificationStatus === "V") {
                this.modalRefSM = this._modalService.open(
                    this.warnPharamVerified,
                    {
                        centered: true,
                        backdrop: false,
                        keyboard: false,
                        windowClass: "large--content"
                    }
                );
            }
            return;
        }
    }

    setPvrf() {
        if (this.IsMultiselect()) {
            return;
        }
        if (this.modalRefSM) {
            this.modalRefSM.close();
        }
        this.rxInputParams.prescRefillId = this.rxInfo.PrescReFill.Id;
        this.rxInputParams.prescNum = this.rxInfo.Prescription.PrescNum;
        this.rxInputParams.rxId = this.rxInfo.Prescription.Id;
        this.rxInputParams.refillNum = this.rxInfo.PrescReFill.ReFillNum;
        this._rxSvc
            .updatePharmacistVerification("V", this.rxInputParams)
            .subscribe(resp => {
                if (resp) {
                    this.loadRxHistory();
                    this._alertSvc.success("Pharmacist verification status updated successfully.");
                }
            });
    }

    updateCopayStatus() {
        if (this.checkForRxSelect()) {
            if (this.IsMultiselect()) {
                return;
            }
            this.copayPaid = null;
            this.rxInfo.PrescReFill.IsCopayPaid
                ? (this.copayStatus = false)
                : (this.copayStatus = true);
            this.rxInfo.PrescReFill.IsCopayPaid
                ? (this.copayPaid = "UNPAID")
                : (this.copayPaid = "PAID");
            this.modalRefSM = this._modalService.open(
                this.CopayPaidStatusModal,
                {
                    centered: true,
                    backdrop: false,
                    keyboard: false,
                    windowClass: "large--content"
                }
            );
            return;
        }
    }

    setCopayStatus() {
        if (this.IsMultiselect()) {
            return;
        }
        if (this.modalRefSM) {
            this.modalRefSM.close();
        }
        this.rxInputParams.prescRefillId = this.rxInfo.PrescReFill.Id;
        this.rxInputParams.prescNum = this.rxInfo.Prescription.PrescNum;
        this._rxSvc
            .updateCopayStatus(this.copayStatus, this.rxInputParams)
            .subscribe(resp => {
                if (resp) {
                    this.loadRxHistory();
                    this._alertSvc.success("Copay status updated successfully.");
                }
            });
    }

    loadRxHistory() {
        this.rxInfo = null;
        this.TotalRxInfo = null;
        this.emitShowRxHistory.emit();
            this.openExceptionRxs();
    }

    reloadRxHistory(val,type?) {
        this.activeModal = null
       if(type == 'frmRefillFlow'){
        this.quickRefillforHoldRx = false
       } 
        this.alreadyChckdDPVMandat = false;
        this.rxInfo = null;
        this.TotalRxInfo = null;
        this.emitShowRxHistory.emit(true);
    }

    IsMultiselect(): boolean {
        if (this.TotalRxInfo && this.TotalRxInfo.length > 1) {
            // Sentry.captureException([
            //     {name: this.TotalRxInfo},
            //     {name: this.TotalRxInfo.length}
            // ]);
            this._alertSvc.error("Please select only one Rx.");
            return true;
        } else {
            return false;
        }
    }

    checkForRxSelect(modalType?: string): boolean {
        if (modalType !== "PatientNotes") {
            if (this.rxInfo && this.rxInfo.length === 1) {
                return true;
            } else if (this.TotalRxInfo && this.TotalRxInfo.length >= 1) {
                return true;
            } else {
                this._alertSvc.error("Please select atleast one Rx.");
                return false;
            }
        } else {
            return true;
        }
    }

    openModal(content: any) {
        this.modalRef = this._modalService.open(content, {
            backdrop: false,
            keyboard: false
        });
    }

    openRefillReqModal(val: boolean) {
        const response  = this.checkForRxSelect();
        if (response) {
            this.isRefillReq = val;
        }
    }

    optedType(eve: any) {
        this.singleRxPerPage = eve.target.value;
    }

    setDummyBool() {
        this.show = true;
    }

    openRxDeliveryModal() {
        const modalRef = this._modalService.open(RxDeliveryInfoComponent, {
            backdrop: false,
            size: "lg"
        });
        modalRef.componentInstance.RxInfo = this.rxInfo;
    }

    openRxStats() {
        const modalRef = this._modalService.open(RefillProgressStatsComponent, {
            backdrop: false,
            windowClass: "refillStats"
        });
    }

    openModalRf(content) {
        this.activeModal = content;
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            if (this.overrideFor === "QuickRefill") {
                this.openActionModal("QuickRefills");
            } else if (this.overrideFor === "RefillRx") {
                this.openActionModal("Refill");
            } else if (this.overrideFor === "DeleteRx") {
                // this.activeModal = "DeleteRx";
                this.processDeletRx();
            } else if (this.overrideFor === "RxEntry") {
                this.routeToRxWithPrivs();
            } else if (this.overrideFor === "EditRx") {
                this.routeToEditRxWithPrivs();
            } else if (this.overrideFor === "") {
                this.openRxNotes();
            }
        }
    }

    openOveride(category, type, endpoint?) {
        this.categoryId = category;
        this.privType = type;
        this.openOverride = true;
        this.overrideFor = type;
        this.overrideEndpoint = endpoint;
    }

    sendRefillRequest() {
        this.activeModal = "";
        this.isRefillReq = true;
    }

    navigateToFaxRefill() {
        sessionStorage.setItem('selectedRxs',this._commonServ.encryptData(JSON.stringify(this.TotalRxInfo)))
        this._router.navigate(["eprx/fax/faxRefill"], { state: { patientInfo: this.patientInfo} });
    }

    cancelRefill() {
        this._commonStore.storeRfStage("Pop");
    }

    async getRxDetailsTransfer() {
        const rxNo = this.rxInfo["Prescription"]["PrescNum"];
        const Id = this.transfData["rxNo"];
        const RefillId = this.transfData["RefillNo"];
        this.showRxTransferIn = this.transfData["Type"] === "In";
        this.showRxTransferOut = this.transfData["Type"] === "Out";
        let response: any;
        if (this.TotalRxInfo && this.TotalRxInfo.length > 0 && (!this.finalValidList || (this.finalValidList && !this.finalValidList.length) ) && this.showRxTransferOut) {
            this.objList = [];
            this.TotalRxInfo.map((item: any) => {
                const data = {
                    PrescNum: item.Prescription.PrescNum,
                    RefillNum: item.PrescReFill.ReFillNum.toString(),
                    FillNum: (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"]) ?
                    item["PrescReFill"]["PartialFillNo"].toString() : null)
                };
                this.objList.push(data);
            });
            response = await this._rxSer.getTransferRxOutCheckList(this.objList).toPromise();
            if (this.TotalRxInfo?.length && response?.length) {
                this.finalValidList = this.TotalRxInfo.filter((item: any) =>
                response.some((res: any) =>
                ((res["PopNum"] === 3 || res["PopNum"] === 2) && +res["PrescNum"] === item.Prescription.PrescNum && +res["RefillNum"] === item.PrescReFill.ReFillNum &&
                ((res["FillNum"] ? +res["FillNum"] : null) === 
                (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"]) ?
                item["PrescReFill"]["PartialFillNo"] : null))))
                );
            }
            if (this.TotalRxInfo.length > 1) {
                /*this.finalValidList.map((x) => {
                    this.grpTransOutRxs.push(x["Prescription"]["PrescNum"] + "-" + x["PrescReFill"]["ReFillNum"] +
                         (this._commonServ.checkIsPartialFill(x["PrescReFill"]["PartialFillNo"], x["PrescReFill"]["PartialIndStatus"]) ? "-" + x["PrescReFill"]["PartialFillNo"] : " "));
                });*/
                this.TotalRxInfo.map((x) => {
                    this.grpTransOutRxs.push(x["Prescription"]["PrescNum"] + "-" + x["PrescReFill"]["ReFillNum"] +
                         (this._commonServ.checkIsPartialFill(x["PrescReFill"], x["Prescription"]) ? "-" + x["PrescReFill"]["PartialFillNo"] : " "));
                });
            }
        }
        const resp = await this._rxSer.getRxTransferPopups(Id, this.showRxTransferOut ? true : false, RefillId).toPromise();
        if (this.showRxTransferIn) {
            this.holdRxData = false;
            this.clearData = false;
            if (resp === 0 || resp === -1 || resp === 1 || resp === 4 || resp === 3) {
                this.actvmodal = "popUp";
                this.modalRef = this._modalService.open(this.WARNINGINFO, {centered: true, backdrop: false, windowClass: "medium-sm-modal-305 large--content"});
                this.warningMessage = resp === 4 ? "Rx: " + rxNo + "-" + RefillId + " has already been Transferred Out." :
                resp === 3 ? "Rx " + rxNo + "-" + RefillId  + " is already Transferred In." : "Rx not found. Please re-enter Rx.";

                if (document.getElementById("myInput")) {
                    document.getElementById("myInput").focus();
                }
            } else if (resp === 2) {
                await this.getTrasferDetails();
            }
        } else if (this.showRxTransferOut) {
            if (resp === 2 || resp === 3) {
                this.holdRxData = false;
                this.clearData = false;
                this.controlRxResp = null;
                await this.getTrasferDetails();
            } else if (resp === 5 || resp === 4 || resp === 6 || resp === 7) {
                this.holdRx = resp === 6 ? true : false;
                this.clearData = false;
                this.holdRxData = false;
                this.actvmodal = "popUp";
                this.controlRxResp = null;
                this.modalRef = this._modalService.open(this.WARNINGINFO, {centered: true, backdrop: false, windowClass: "medium-sm-modal-305 large--content"});
                this.warningMessage = resp === 5 ? "No Quantity left on this Rx#: <label>" + rxNo + " </label> – Cannot be Transferred Out." :
                resp === 6 ? "Hold Rx#:   <label>" + rxNo + "  </label> cannot be Transferred Out." + "<br/>" + "Please Unhold if you wish to Transfer Out." :
                resp === 4 ? "Rx#:  <label>" + rxNo + " </label> is already Transferred Out." :
                resp === 7 ? "Rx#:  <label>" + rxNo + " </label> is expired. Cannot be Transferred out." : "Rx not found.Please re-enter Rx.";

                if (document.getElementById("myInput")) {
                    document.getElementById("myInput").focus();
                }
            }
        }
    }

    async getTrasferDetails() {
        const Id = this.transfData["rxNo"];
        const PrescRefillId = this.transfData["prescRefillId"];
        const resp: any = await this._rxSer.getRxTransferDetails(Id, this.showRxTransferOut ? true : false, PrescRefillId).toPromise();
        if (resp) {
            if (resp["Rxtransfer"].OrderStatus &&
                resp["Rxtransfer"].OrderStatus.trim().toLowerCase() === "d" && this.showRxTransferOut) {
                this.holdRxData = false;
                this.clearData = false;
                this.controlRxResp = null;
                this.transferdetbeforediscont = resp;
                this.modalRef = this._modalService.open(this.DiscontinuedRx, {
                    centered: true,
                    backdrop: false,
                    windowClass: "large--content"
                });
            } else {
                this.controlRxResp = null;
                this.holdRxData = true;
                this.clearData = true;
                this.patchTransferInfo = resp;
            }
        }
    }

    unHoldRx(type) {
        if ( this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this.holdRespType = type;
        this.holdRxData = true;
        if (this.grpTransOutRxs && this.currentIndex <= (this.grpTransOutRxs.length -1)) {
            this.currentIndex++;
        } else {
            this.currentIndex = 0;
        }
        this._modalService.open(this.RXINMODAL , {centered: true, size: "lg", windowClass: "md-x-lg", backdrop: false});
    }

    clearRxdata() {
        if ( this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this.holdRxData = false;
        this.clearData = true;
        this.patchTransferInfo = null;
        /* if (this.transferdetbeforediscont && this.transferdetbeforediscont["Rxtransfer"] && this.showRxTransferOut && this.transfData && this.grpTransOutRxs?.length) {
             const indexRem = this.grpTransOutRxs.findIndex((item: any) => // commenting this block as we are displaying all the selected Rx(s) in inprogress block
             item === (this.transfData["rxNo"] + "-" + this.transferdetbeforediscont["Rxtransfer"]["RefillNum"] +
                              (this.transfData["FillNo"] ? "-" + this.transfData["FillNo"] : " ")));
             this.grpTransOutRxs.splice(indexRem, 1);
        }*/
        if (this.grpTransOutRxs && this.currentIndex <= (this.grpTransOutRxs.length -1)) {
            this.currentIndex++;
        } else {
            this.currentIndex = 0;
        }
        this._modalService.open(this.RXINMODAL , {centered: true, size: "lg", windowClass: "md-x-lg", backdrop: false});
    }

    async setOrderStatusTrInOut() {
        await this._rxSvc.putRxDiscontinuedOrderStatus(this.transferdetbeforediscont["Rxtransfer"]["PrescId"]).toPromise();
        this.patchTransferInfo = this.transferdetbeforediscont;
        this.clearData = false;
        this.holdRx = false;
        if (this.grpTransOutRxs && this.currentIndex <= (this.grpTransOutRxs.length -1)) {
            this.currentIndex++;
        } else {
            this.currentIndex = 0;
        }
        this._modalService.open(this.RXINMODAL , {centered: true, size: "lg", windowClass: "md-x-lg", backdrop: false});
    }


    calQuatRem(rxFG) {
        if (parseFloat(rxFG.value["PrescReFill"]["DispQty"]) && parseFloat(rxFG.value["PrescReFill"]["OrdQty"])) {
            let QtyRem = 0;
            if (rxFG.value["PrescReFill"]["PartialFillNo"] === 1 && (rxFG.value["rxType"] !== "er")) {
                QtyRem = ( (!rxFG.value.PrescReFill.IsOverFillRx ? parseFloat(rxFG.value["PrescReFill"]["OrdQty"]) : +rxFG.value["PrescReFill"]["TotalRemainingQty"]) ? (!rxFG.value.PrescReFill.IsOverFillRx ? parseFloat(rxFG.value["PrescReFill"]["OrdQty"]) : parseFloat(rxFG.value["PrescReFill"]["TotalRemainingQty"])) : 0) -
                (parseFloat(rxFG.value["PrescReFill"]["DispQty"]) ? parseFloat(rxFG.value["PrescReFill"]["DispQty"]) : 0);
            } else {
                QtyRem = (!rxFG.value.PrescReFill.IsOverFillRx ? rxFG.value["erQtyRem"] : +rxFG.value["PrescReFill"]["TotalRemainingQty"]) -
                (+rxFG.value["PrescReFill"]["DispQty"] ? parseFloat(rxFG.value["PrescReFill"]["DispQty"]) : 0);
            }
            if(rxFG.value["rxType"] === "er" && rxFG.value.PrescReFill.IsOverFillRx && rxFG.value["PrescReFill"]["DispQty"]){
                QtyRem = +rxFG.value["PrescReFill"]["TotalRemainingQty"] + rxFG.value["PrescReFill"]["DispQty"] - parseFloat(rxFG.value["PrescReFill"]["DispQty"]);
            }
            rxFG.controls["PrescReFill"].patchValue({
                QtyRemRef: +QtyRem ? (QtyRem).toFixed(3) : "0.000"
            });
          }
    }
    openExceptionRxs(){
        let message: any;
        if((this.transmissionRejctedList && this.transmissionRejctedList.length) || this.transSwitchDownList?.length){
            const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                backdrop: false,
                keyboard: false, windowClass: "large--content"
                });
                if (this.transSwitchDownList && this.transmissionRejctedList && this.transSwitchDownList.length && this.transmissionRejctedList.length) {
                    message = "Following Rx(s) cannot be transmitted due to exception and all the transmission service switches are currently down."+ "<br>";
                    this.transSwitchDownList.map(resp=>{
                        const textmsg = "<div>"+ resp.PrescNum + "-" + resp.ReFillNum + (this._commonServ.checkIsPartialFill(resp) ? "-" + resp.PartialFillNo : "") + "</div>"
                    message = message + textmsg;

                    })
                    this.transmissionRejctedList.map(resp=>{
                        const textmsg = "<div>"+ resp.PrescNum + "-" + resp.ReFillNum + (this._commonServ.checkIsPartialFill(resp) ? "-" + resp.PartialFillNo : "") + "</div>"
                    message = message + textmsg;

                    })
                } else if (this.transSwitchDownList?.length) {
                    message = "All the transmission service switches are currently down, so the following Rx(s) cannot be transmitted."+ "<br>";
                    this.transSwitchDownList.map(resp=>{
                        const textmsg = "<div>"+ resp.PrescNum + "-" + resp.ReFillNum + (this._commonServ.checkIsPartialFill(resp) ? "-" + resp.PartialFillNo : "") + "</div>"
                    message = message + textmsg;

                    })
                } else {
                    message = "Following Rx(s) cannot be transmitted due to exception."+ "<br>";
                    this.transmissionRejctedList.map(resp=>{
                        const textmsg = "<div>"+ resp.PrescNum + "-" + resp.ReFillNum + (this._commonServ.checkIsPartialFill(resp) ? "-" + resp.PartialFillNo : "") + "</div>"
                    message = message + textmsg;

                })
                }

                modelRef.componentInstance.warnMsg = message;
                modelRef.componentInstance.IsHeaderText = "Information";
                modelRef.componentInstance.okButton = true;
                modelRef.componentInstance.IsPopUpClosed.subscribe((response: any) => {
                    setTimeout(async v => {
                    if( this.resetObs ){
                        this.resetObs.unsubscribe();
                        this.subscription.unsubscribe();
                    }
                    },10)
                    modelRef.close();
                    if(response){
                        this.transmissionRejctedList = [];
                        this.transSwitchDownList = [];
                    }
                });

    }
}
}
