import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditLogUtils } from 'src/app/utils/audit-log.util';
import { DrugPickVerify } from '../../../models';
import { CommonService, DrugPickVerificationService, PatPreDrugModalService, RxService, UserService } from '../../../services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VerificationTabsListComponent } from '../../verification-tabs/verification-tabs-list/verification-tabs-list.component';

@Component({
    selector: "app-common-drug-pick-verify",
    templateUrl: "./common-drug-pick-verify.component.html"
})
export class CommonDrugPickVerifyComponent implements OnInit {
    drugVerifyInfo: DrugPickVerify = new DrugPickVerify();
    selectedRxInfo: any[] = [];
    orginal: number;
    beforeDPV: number;
    rxInfo: any;
    warningInfo: string;
    warningInfoForDisplay: string;
    modalRef: any;
    activeModal: string;
    disContinueOpenFr: any;
    isDphOrRphOrRx: any;
    isAllowUNbilledRxForDpv = false;
    totalInvalidRxForDpv: any = [];
    openPopUp: any;
    totalValidRxForDpv: any = [];
    verificationType: any;
    reVerifyRxs: any = [];
    disContinedList: any = [];
    discontinuedData: any;
    batchDpv: any = [];
    verifiedArray: any = [];
    reqRxVerfStng: number;
    // workFlwPatStng: number;
    unsubscribe$: Subject<void> = new Subject();
    @Input()
    set SelectedRxInfo(rxs: any) {
        this.selectedRxInfo = rxs;
    }
    @Input()
    set dpvPopup(type: any) {
        this.openPopUp = type;
    }
    @Output()
    emitGetData = new EventEmitter();
    @ViewChild("RxDiscontinued", { static: true })
    RxDiscontinued: any;

    @ViewChild("invalidRx", { static: true })
    invalidRx: any;

    @ViewChild("alreadyVerified", { static: true })
    alreadyVerified: any;

    @ViewChild("Confirmation", { static: true })
    Confirmation: any;

    @ViewChild("VerifiedRxs", { static: true })
    VerifiedRxs: any;


    constructor(public _commonServ: CommonService,
        private _modalService: NgbModal,
        private _auditUtils: AuditLogUtils,
        private _patPreDrugModalServ: PatPreDrugModalService,
        private _userService: UserService,
        private _drgVerifyServ: DrugPickVerificationService,
        private _rxSvc: RxService) { }

    ngOnInit() {
        this.openPopupModal();
    }

    openPopupModal() {
        if (this.openPopUp) {
            this.modalRef = this._modalService.open(this.Confirmation, {
                backdrop: false,
                keyboard: false,
                centered: true,
                windowClass: "large--content_medium"
            });
        }
    }
    verifyInfo(type) {
        this.verificationType = type;
        if (type === "individually") {
            if (sessionStorage.getItem("drug-pick")) {
                this.processedRxsDrugPickVerification(type);
            } else {
                this.activeModal = "DrugPickVerification";
            }
        } else {
            this.processedRxsDrugPickVerification(type);
        }
    }
    getRxStatusForDisplay(data, warningMessage) {
        return  ("<br><label>" +
        data["Prescription"]["PrescNum"] + "-" + data["PrescReFill"]["ReFillNum"] +
        (this._commonServ.checkIsPartialFill(data["PrescReFill"], data["Prescription"]) ? "-" + data["PrescReFill"]["PartialFillNo"] : " ") +
        " </label> :  <b>" + warningMessage + "</b>");
    }
    processedRxsDrugPickVerification(type?) {
        if (this.selectedRxInfo && this.selectedRxInfo.length > 0) {
            const rxInfo = this.selectedRxInfo.shift();
            this.otherVerificationChecks(rxInfo, type);
        } else {
            this.selectedRxInfo = [];
        }
    }
    otherVerificationChecks(rxInfo, type?) {
        this.orginal = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification_for_Original_Rx_Only"), 0);
        this.beforeDPV = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Rx_Verification_Required_Before_DPV"), 0);
        this.isAllowUNbilledRxForDpv = this._commonServ.getSetttingValue(
            "WorkFlowSettings",
            "ALLOW_DRUG_PICK_VERF_UNB_RX"
        ) === "0";
        this.reqRxVerfStng = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification"), 0);
        // this.workFlwPatStng = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings","Require_Rx_Verification_for_workflow_patient_only"), 0);
            const rxRefillFillDisplay = rxInfo["Prescription"]["PrescNum"] + " - " + rxInfo["PrescReFill"]["ReFillNum"] +
                (this._commonServ.checkIsPartialFill(rxInfo["PrescReFill"], rxInfo["Prescription"]) ? " - " + rxInfo["PrescReFill"]["PartialFillNo"] : " ");
           if (rxInfo.PrescReFill.StatusId === 2 || rxInfo.Prescription.RxStatusId === 2) {
                this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" + " is a Deferred Rx, it cannot be verified.";
                this.totalInvalidRxForDpv.push(this.getRxStatusForDisplay(rxInfo, "Deferred Rx"));

            } else if (rxInfo.PrescReFill.StatusId === 4 || rxInfo.Prescription.RxStatusId === 4) {
                this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" + " is a Transferred Rx, it cannot be verified.";
                this.totalInvalidRxForDpv.push(this.getRxStatusForDisplay(rxInfo, "Transferred Rx"));

            } else if (rxInfo.RxBill.StatusId === 1 && this.isAllowUNbilledRxForDpv) {
                this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" + " is a Unbilled Rx, it cannot be verified.";
                this.totalInvalidRxForDpv.push(this.getRxStatusForDisplay(rxInfo, "Unbilled Rx"));

            } else if (rxInfo["drugPickVerificationStatus"] &&
                rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "p") {
                    this.reVerifyRxs.push(rxInfo);
            } else if (this.reqRxVerfStng) {
                /*if (this.workFlwPatStng) {
                    if (rxInfo && rxInfo.Patient && rxInfo.Patient.IsWorkflowPatient && this.orginal && this.beforeDPV &&
                        !(rxInfo.rph1VerificationStatus && rxInfo.rph1VerificationStatus.toLowerCase() === "r")
                        && (rxInfo && (rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
                        rxInfo["Prescription"]["IsDiscontinued"] = false;
                        this.isDphOrRphOrRx = "Rx";
                        this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" +
                        " Rx verification is required before Drug pick verification.";
                        this.totalInvalidRxForDpv.push(this.getRxStatusForDisplay(rxInfo,
                             "Rx verification is required before Drug pick verification."));
                    } else if (rxInfo && rxInfo.Patient && rxInfo.Patient.IsWorkflowPatient && !(this.orginal) && this.beforeDPV && !(rxInfo["rph1VerificationStatus"] &&
                    rxInfo.rph1VerificationStatus.toLowerCase() === "r")) {
                        this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" +
                        " Rx verification is required before Drug pick verification.";
                        this.totalInvalidRxForDpv.push(this.getRxStatusForDisplay(rxInfo,
                        "Rx verification is required before Drug pick verification."));
                    } else {
                        this.totalValidRxForDpv.push(rxInfo);
                    }
                } else if (this.orginal && this.beforeDPV &&
                    !(rxInfo.rph1VerificationStatus && rxInfo.rph1VerificationStatus.toLowerCase() === "r")
                    && (rxInfo && (rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
                        rxInfo["Prescription"]["IsDiscontinued"] = false;
                        this.isDphOrRphOrRx = "Rx";
                        this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" +
                        " Rx verification is required before Drug pick verification.";
                        this.totalInvalidRxForDpv.push(this.getRxStatusForDisplay(rxInfo,
                             "Rx verification is required before Drug pick verification."));
                } else*/
                 if (!(this.orginal) && this.beforeDPV && !(rxInfo["rph1VerificationStatus"] &&
                rxInfo.rph1VerificationStatus.trim().toLowerCase() === "r")) {
                    this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" +
                    " Rx verification is required before Drug pick verification.";
                    this.totalInvalidRxForDpv.push(this.getRxStatusForDisplay(rxInfo,
                    "Rx verification is required before Drug pick verification."));
                } else  if (rxInfo && rxInfo["Prescription"]["IsDiscontinued"]) {
                    this.disContinedList.push(rxInfo);
                } else {
                    this.totalValidRxForDpv.push(rxInfo);
                }
            } else  if (rxInfo && rxInfo["Prescription"]["IsDiscontinued"]) {
                this.disContinedList.push(rxInfo);
            } else {
                this.totalValidRxForDpv.push(rxInfo);
            }
     if (this.selectedRxInfo && this.selectedRxInfo.length > 0) {
        this.processedRxsDrugPickVerification();
     } else {
         if ((this.totalValidRxForDpv && this.totalValidRxForDpv.length && this.totalValidRxForDpv.length === 1)
         && this.totalInvalidRxForDpv && !this.totalInvalidRxForDpv.length ) {
             if (this.verificationType === "individually") {
                this.openDrugPickVerf(this.totalValidRxForDpv.shift());
             } else {
                 const data = this.totalValidRxForDpv;
                 this.totalValidRxForDpv = [];
                 this.sendDrugVerifyDetails(data);
             }
         } else if (this.totalInvalidRxForDpv && this.totalInvalidRxForDpv.length) {
            this.warningInfoForDisplay = (this.totalInvalidRxForDpv && this.totalInvalidRxForDpv.length
                && this.totalInvalidRxForDpv.length > 1) ?  "Following selected Rx's cannot be verified:" +
                this.totalInvalidRxForDpv.join("") : this.warningInfo;
            this.modalRef = this._modalService.open(this.invalidRx, {
                centered: true,
                keyboard: false,
                backdrop: false,
                windowClass: "large--content"
            });
        } else if ((this.totalValidRxForDpv && this.totalValidRxForDpv.length && this.totalValidRxForDpv.length > 1)
        && this.totalInvalidRxForDpv && !this.totalInvalidRxForDpv.length ) {
            if (this.verificationType === "individually") {
                 this.openDrugPickVerf(this.totalValidRxForDpv.shift());
            } else {
                const data = this.totalValidRxForDpv;
                this.totalValidRxForDpv = [];
                this.sendDrugVerifyDetails(data);
            }

        } else if ((this.reVerifyRxs && this.reVerifyRxs.length && this.reVerifyRxs.length === 1) &&
        this.totalInvalidRxForDpv && !this.totalInvalidRxForDpv.length && (this.totalValidRxForDpv &&
            !this.totalValidRxForDpv.length)) {
                if (this.verificationType === "individually") {
                  this.openReVerifyModal();
                } else {
                    const data = this.reVerifyRxs;
                    this.reVerifyRxs = [];
                    this.sendDrugVerifyDetails(data);
                }
        } else if ((this.reVerifyRxs && this.reVerifyRxs.length && this.reVerifyRxs.length > 1) &&
        this.totalInvalidRxForDpv && !this.totalInvalidRxForDpv.length && (this.totalValidRxForDpv &&
            !this.totalValidRxForDpv.length)) {
            if (this.verificationType === "individually") {
                this.openReVerifyModal();
            } else {
                const data = this.reVerifyRxs;
                this.reVerifyRxs = [];
                this.sendDrugVerifyDetails(data);
            }
        } else if (this.disContinedList && this.disContinedList.length) {
            this.openDiscontinuedModal();
        }
        }
    }

    openDiscontinuedModal() {
        this.discontinuedData = this.disContinedList[0];
        this.modalRef = this._modalService.open(this.RxDiscontinued, {
            centered: true,
            backdrop: false,
            windowClass: "large--content"
    });
    }
    openReVerifyModal () {
        this.rxInfo = this.reVerifyRxs[0];
        this.modalRef = this._modalService.open(
            this.alreadyVerified,
            {
                centered: true,
                keyboard: false,
                backdrop: false,
                windowClass: "large--content"
            });
    }
    processDpvForAlreadyVerified(type?) {
        if (type === "yes") {
            if (this.modalRef) {
                this.modalRef.close();
                this.modalRef = null;
            }
            if (this.reVerifyRxs && this.reVerifyRxs.length) {
                this.openDrugPickVerf(this.reVerifyRxs.shift());
            }
        } else {
            this.reVerifyRxs.shift();
            if (this.reVerifyRxs && this.reVerifyRxs.length) {
                 this.openReVerifyModal();
        }
    }
    }
    processDrugPickVerificationfromInvalidRx(type?) {
     if (this.totalValidRxForDpv && this.totalValidRxForDpv.length) {
            if (this.verificationType === "individually") {
            this.openDrugPickVerf(this.totalValidRxForDpv.shift());
            } else {
                const data = this.totalValidRxForDpv;
                this.totalValidRxForDpv = [];
                this.sendDrugVerifyDetails(data);
            }
       } else if (this.reVerifyRxs && this.reVerifyRxs.length) {
        if (this.verificationType === "individually") {
            this.openReVerifyModal();
        } else {
            const data = this.reVerifyRxs;
            this.reVerifyRxs = [];
            this.sendDrugVerifyDetails(data);
        }
    } else if (this.disContinedList && this.disContinedList.length) {
        if (!type) {
        if (this.verificationType === "individually" || this.verificationType === "mark") {
            if (this.disContinedList && this.disContinedList.length) {
            this.openDiscontinuedModal();
            }
        }
        } else {
        this.disContinedList.shift();
        if (this.verificationType === "individually" || this.verificationType === "mark") {
            if (this.disContinedList && this.disContinedList.length) {
            this.openDiscontinuedModal();
            } else {
                this.processDrugPickVerificationfromInvalidRx();
            }
        }
        }
    } else {
       if (this.verificationType === "mark" && this.batchDpv && this.batchDpv.length) {
        this._drgVerifyServ
        .SendBatchDrugVerificationDetails(this.batchDpv)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
                if (resp) {
                    this.getChangedValues();
                    this.modalRef = this._modalService.open(this.VerifiedRxs, {
                        centered: true,
                        backdrop: false,
                        windowClass: "large--content"
                });
            }
        });

       }
    }
}
ngOnDestroy() {
	this.unsubscribe$.next();
	this.unsubscribe$.complete();
}
    openDrugPickVerf(rxInfo?) {
        /*this._patPreDrugModalServ._patPreDrugModal$.next("DrugPickVerification");
        const modalRef = this._patPreDrugModalServ.modalInstanceRef;
        modalRef.componentInstance.RxInfo = rxInfo;
        modalRef.componentInstance.IsPatientHistory = true;
        modalRef.componentInstance.TriggerChangeTab
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
            if (res) {
                if (modalRef) {
                    modalRef.close();
                    this.emitGetData.emit(null);
                }
                this.processDrugPickVerificationfromInvalidRx();
            }
        });*/
        const modalRefVerfTab = this._modalService.open(VerificationTabsListComponent, {size: "lg",  windowClass: "md-xx-screenWidth comp--modal modal-large modal-center-to-page", backdrop: false});
        modalRefVerfTab.componentInstance.RxInfo = rxInfo;
        modalRefVerfTab.componentInstance.IsPatientHistory = true;
        modalRefVerfTab.componentInstance.activeTabId = "dpvVerfTab";
        modalRefVerfTab.componentInstance.closeTabs.subscribe((res: any) => {
            if (res) {
                if (modalRefVerfTab) {
                    modalRefVerfTab.close();
                    this.emitGetData.emit(null);
                   this.closeModal();
                }
                this.processDrugPickVerificationfromInvalidRx();
            }
        });
    }
    processDrugPickVerification(type?) {
        if (type === "continue") {
            this.openDrugPickVerf(this.reVerifyRxs.shift());
        } else {
            this.closeModal();
        }
    }
    closeModal(type?: any) {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
             this.emitGetData.emit(null);
        }
    }
    sendDrugVerifyDetails(rxInfo) {
        const technician = this._userService.getToken("TechUserName");
        let UserName = "";
        if (!technician) {
            UserName = this._userService.getToken("PhUserName");
        } else {
            UserName = this._userService.getToken("TechUserName");
        }
        const VerifiedById = Number(this._userService.getToken("UserId"));
        rxInfo.map((item: any) => {
            this.batchDpv.push({
            BottleNdc: item.Drug.ndc,
            PrescNum: item.Prescription.PrescNum,
            RefillNum: item.PrescReFill.ReFillNum,
            PartialfillNum: item.PrescReFill.PartialFillNo,
            PrescId: item.Prescription.Id,
            PrescRefillId: item.PrescReFill.Id,
            Result: "p",
            UserName: UserName,
            VerifiedById: VerifiedById
            });
            const rxNo =
             // tslint:disable-next-line:max-line-length
             item.Prescription.PrescNum + "-" + item.PrescReFill.ReFillNum + (this._commonServ.checkIsPartialFill(item.PrescReFill, item.Prescription) ? "-" + item.PrescReFill.PartialFillNo  : " ");
            this.verifiedArray.push(rxNo);

        });
         this.processDrugPickVerificationfromInvalidRx();
    }

    afterVerifyDrugPickVerificationp(event) {
        setTimeout(() => {
            this.activeModal = null;
        }, 30);
        if (sessionStorage.getItem("drug-pick")) {
            this.processedRxsDrugPickVerification();
        } else { 
            if (this.selectedRxInfo && this.selectedRxInfo.length > 0){
                this.selectedRxInfo.map((value)=>{
                    value['IsSelected'] = false;
                });
            }
            this.selectedRxInfo = [];
            this.emitGetData.emit(null);
        }
    }
    async setOrderStatus() {
        await this._rxSvc.putRxDiscontinuedOrderStatus(this.disContinedList[0].Prescription.Id).toPromise();
        if (this.verificationType === "individually") {
            this.openDrugPickVerf(this.disContinedList[0]);
            this.disContinedList.shift();
        } else {
            const arr = [];
            arr.push(this.disContinedList.shift());
            this.sendDrugVerifyDetails(arr);
        }
    }
    getChangedValues() {
        if(this.batchDpv && this.batchDpv.length) {
            this.batchDpv.map((item: any) => {
                this._auditUtils.getChangedValues(
                    null,
                    { "DP Verified": "Verified" },
                    "Drug Pick Verification",
                    "Rx",
                      item['PrescRefillId']
                );
                this._auditUtils.getChangedValues(
                    null,
                    { "DP Verification Status": "Pass" },
                    "Drug Pick Verification",
                    "Rx",
                      item['PrescRefillId']
                );
            });
        }
        this.batchDpv = [];
    }
}
