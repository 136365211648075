import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services';

@Component({
  selector: 'app-mark-as-transfer-in-rxdetails',
  templateUrl: './mark-as-transfer-in-rxdetails.component.html',
  styleUrls: ['./mark-as-transfer-in-rxdetails.component.scss']
})
export class MarkAsTransferInRxdetailsComponent implements OnInit {
  rxTransferFG: FormGroup;
  restoreParams: any;
  constructor(private _fb: FormBuilder,
    private _userService: UserService
  ) {
    this.rxTransferFG = this._fb.group({
      prescnumid: null,
      OrgRxNum: null,
      OrgRxDtTm: null,
      OrgLastRxFillDtTm: null,
      XferPharmIdxId: this.restoreParams ? this.restoreParams.otherPhamName : null,
      OrgPharmacistNm: this.restoreParams ? this.restoreParams.origPharmcistName : null,
      PharmacistId: this._userService.getToken("UserId")
        ? +this._userService.getToken("UserId")
        : null,
      TransferDtTm: null,
      Remarks: null,
      SigCode: null,
      SigDescription: null,
      RefillorRx: false,
      function: true,
      refillnum: null,
      IsFiledRx: false,
      IsFmPrint: false,
      Refills: 0,
      PartialFillNo: null,
      PartialFillQty: null,
      RefillsCount: null,
      RefillsTransferredList: null,
      PrescRefillId: null
    });

  }

  ngOnInit(): void {
  }

}
