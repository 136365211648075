

<div class="exprx--common-block erx--body insurance--body">
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        Your Pharmacy
    </div>
    <div
    class="eprx--block__content pt-0" style="height: 13.7rem;">
        <div class="row pt-4 mb-1 col">
            <div class="label--data"><span><b>
                {{requestPharmacyDetails.PharmacyName ?
                    (requestPharmacyDetails.PharmacyName | uppercase) : msgConfig.EMPTY_Data}}
            </b></span></div>
        </div>
        <div class="row">
            <div class="col-4">
                <label>{{pharmacyabels.Contact}}</label>
                <div class="label--data"><span>{{requestPharmacyDetails.TelephoneNumber ?
                        requestPharmacyDetails?.TelephoneNumber : msgConfig.EMPTY_Data}}</span></div>
            </div>
            <div class="col-3">
                <label>{{pharmacyabels.Fax}}</label>
                <div class="label--data"><span>{{requestPharmacyDetails?.fax ?
                        requestPharmacyDetails.fax : msgConfig.EMPTY_Data}}</span></div>
            </div>
            <div class="col-5 px-0">
                <label>{{pharmacyabels.Address}}</label>
                <div class="label--data "><span>{{requestPharmacyDetails.pharmAddress ?
                        requestPharmacyDetails.pharmAddress : msgConfig.EMPTY_Data}}</span></div>
            </div>
        </div>
        <div class="row row-space">
            <div class="col-4">
                <label>{{pharmacyabels.NPI}}</label>
                <div class="label--data"> <span>{{ (requestPharmacyDetails?.NPINum) ? requestPharmacyDetails.NPINum : msgConfig.EMPTY_Data}}</span></div>
            </div>
            <div class="col-3">
                <label>{{pharmacyabels.DEA}}</label>
                <div class="label--data"><span>{{ (requestPharmacyDetails?.DEARegistration) ? requestPharmacyDetails.DEARegistration : msgConfig.EMPTY_Data}}</span></div>
            </div>
            <div class="col-5 px-0">
                <label>{{pharmacyabels.Pharmacist_Name}}</label>
                <div class="label--data"><span>{{pharmacistName ? pharmacistName : msgConfig.EMPTY_Data}}</span></div>
            </div>
        </div>
    </div>
</div>

