<div class="row">
    <div class="col-lg-12 col-md-12 p-0">
        <div class="exprx--common-block">
            <div class="row content--heading" style="display: flex;flex-direction: row;">
                <div class="p-0" style="width: 18%;">
                    <div class="content--heading__title padding-0 border_radius_6px" [ngStyle]="{'background': isRxVerification ? '#ffca28' : 'rgb(134, 224, 150)', 'display': 'inline'}">
                        {{isRxVerification ? "Rx " : "R.Ph "}}Verification:
                        {{verifyData?.Prescription?.PrescNum}}-{{+verifyData?.PrescReFill?.ReFillNum}}{{fillNumber}}
                    </div>
                </div>
                <div style="width: 82%;">
                    <div class="row rx--his__actions col-md-12 height_3_5rem">
                        <!-- moved to bottom -->
                        <!-- <span *ngIf="!isRxVerification && verifyData?.Iou?.length" class="padding_top_0_5rem padding_right_0_3rem">DPV</span> -->
                        <!-- <div *ngIf="!isRxVerification && verifyData?.Iou?.length && diffNDCCnt === 0" class="ml-1 far fa-lg fa-check-circle text-success padding_top_0_7rem padding_right_0_3rem font_size_1_4rem" [ngbTooltip]="'Billed NDC and Dispensed NDC is same.'"
                        triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="bottom"></div> -->
                        <!-- moved to bottom -->
                        <!-- <div *ngIf="!isRxVerification && verifyData?.Iou?.length && diffNDCCnt === 0" class="ml-1 padding_right_0_3rem font_size_1_4rem" [ngbTooltip]="'Billed NDC and Dispensed NDC is same.'"
                        triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="bottom">
                            <img src="assets/img/green.png"  class="height_2rem">
                        </div>
                        <div *ngIf="!isRxVerification && verifyData?.Iou?.length && diffNDCCnt" class="ml-1 padding_right_0_3rem font_size_1_4rem" [ngbTooltip]="instructToolTip1" tooltipClass="search-tooltip"
                        triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="bottom">
                            <img src="assets/img/orange2.png" class="height_2rem">
                        </div> -->
                        <!-- <div *ngIf="!isRxVerification && verifyData?.Iou?.length && diffNDCCnt" class="ml-1 far fa-lg fa-check-circle text-orange padding_top_0_7rem padding_right_0_3rem font_size_1_4rem"[ngbTooltip]="instructToolTip1" tooltipClass="search-tooltip"
                        triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="bottom"></div> -->
                        <!-- moved to bottom -->
                        <!-- <ng-template #instructToolTip1>
                            <div class="col-12">
                                <div class="row text-left">
                                   <p class="font_size_15px mb-0">
                                    Dispensed NDC# is either a Therapeutic Equivalent/ different size stock bottle from the billed NDC#.<br>
                                    Please check the Rx and change the drug NDC#, if you wish so to.<br>
                                    <span *ngIf="(rxFG?.value?.PrescReFill?.IsEquivalentDisp && isPaidRecordsAv)">For Insurance Prescriptions, please use RRR button to reverse-replace-retransmit.<br>
                                    For Cash Prescriptions, click on Edit Rx to change the drug.</span>
                                   </p><br>
                                   <div class="row text-left">
                                    <div class="col-md-12">
                                        <label>Billed NDC# : </label>
                                        <span>
                                            {{this._formatsUtil.getNDCFormat(verifyData?.Drug?.drugcode)}}</span>
                                        <div *ngFor="let ndc of differentNDc; let i=index; ">
                                            <label>Dispensed NDC{{i+1}}# : </label><span> {{this._formatsUtil.getNDCFormat(ndc)}} <span [ngStyle]="{'color': verifyData.Iou[0].NotEquivalent ? '#f7941d' : ''}">
                                                {{verifyData.Iou[0].NotEquivalent ? '(Non-Equivalent NDC# from Pill Counter)' : ''}}
                                            </span></span>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                </div>
                        </ng-template> -->
                        <div *ngIf="(!isRxVerification && rxFG?.value?.PrescReFill?.IsEquivalentDisp && isPaidRecordsAv)" class="secondary-actn-btn height_2_5rem" placement="bottom" ngbTooltip="Reverse Replace Re-Transmit for Prescriptions with paid pay records">
                            <a class="enable--hotkey__buttons" appShortcutKey [AltKey]="'r'"   (click)="editRxScreen('RRR')">
                                <span class="hotkey--buttons text-center">R</span>
                                <span class="save--buttons">RRR</span>
                            </a>
                        </div>
                        <div class="secondary-actn-btn height_2_5rem" placement="bottom" [ngbTooltip]="((!isRxVerification && verifyData?.Iou?.length && diffNDCCnt && controlDrug) || (!isRxVerification && rxFG?.value?.PrescReFill?.IsEquivalentDisp && isPaidRecordsAv) ? (privCheckForNDCChange ? 'Verify' : '') : 'Verify')">
                            <a [ngClass]="(!isRxVerification && verifyData?.Iou?.length && diffNDCCnt && controlDrug) ? (!privCheckForNDCChange ? 'disable--hotkey__buttons' : 'enable--hotkey__buttons') : 'enable--hotkey__buttons'" appShortcutKey [AltKey]="'v'"  (click)="(!isRxVerification && verifyData?.Iou?.length && diffNDCCnt && controlDrug) ? (privCheckForNDCChange ? openOveride('PharmacistVerification','AllowRphVerificationIfDrugChange') : '') : verifyRph('verify')" [title]="(!isRxVerification && verifyData?.Iou?.length && diffNDCCnt && controlDrug) ? (!privCheckForNDCChange ? 'We have detected a change in NDC based on the prescribed medication and user does not have privileges to perform this action.' : '') : ''">
                                <span class="hotkey--buttons text-center">V</span>
                                <span class="save--buttons">Verify</span>
                            </a>
                        </div>
                        <span class="secondary-actn-btn height_2_5rem" placement="bottom" ngbTooltip="Follow Up" *ngIf="!isRxVerification">
                            <a class="enable--hotkey__buttons" appShortcutKey [AltKey]="'f'" (click)="openFollowup()">
                                <span class="hotkey--buttons text-center">F</span>
                                <span class="save--buttons">Follow up</span>
                            </a>
                        </span>
                        <span class="secondary-actn-btn height_2_5rem" placement="bottom" *ngIf="isLogicoyReg">
                            <a class="enable--hotkey__buttons" appShortcutKey [AltKey]="'l'" (click)="PMPNow()">
                                <span class="hotkey--buttons text-center">L</span>
                                <span class="save--buttons">PMP Now</span>
                            </a>
                        </span>
                        <!-- <span class="secondary-actn-btn" placement="bottom" ngbTooltip="Edit Rx">
                            <a class="enable--hotkey__buttons" (click)="goToEditRx()">
                                <span class="hotkey--buttons text-center">E</span>
                                <span class="save--buttons">Edit Rx</span>
                            </a>
                        </span> -->
                        <!-- <span class="secondary-actn-btn" placement="bottom" ngbTooltip="Patient History">
                            <a class="enable--hotkey__buttons" (click)="goToPatHist()">
                                <span class="hotkey--buttons text-center">T</span>
                                <span class="save--buttons">Pat Hist</span>
                            </a>
                        </span> -->
                        <span *ngIf="rxFG?.value?.Patient?.InterfaceId === 3 && isHoa" class="secondary-actn-btn height_2_5rem" placement="bottom" [ngbTooltip]="(!((rxFG?.value?.PrescReFill?.IsHOADefined || isHOADefined)) ? 'HOA Not Configured for this Rx' : 'HOA')">
                            <a [ngClass]="((rxFG?.value?.PrescReFill?.IsHOADefined || isHOADefined))? 'enable--hotkey__buttons' :'alert--hotkey__buttons'" (click)="displayHoa()" appShortcutKey [AltKey]="'h'">
                                <span class="hotkey--buttons text-center">H</span>
                                <span class="save--buttons">HOA</span>
                            </a>
                        </span>
                        <span class="secondary-actn-btn height_2_5rem" placement="bottom" ngbTooltip="Verify History">
                            <a class="enable--hotkey__buttons" (click)="openHistoryPopUp(isRxVerification ? 'rx' : 'rph')">
                                <span class="hotkey--buttons text-center">H</span>
                                <span class="save--buttons">Ver Hist</span>
                            </a>
                        </span>
                        <span *ngIf="drugInfoDet?.Drug?.IsVaccine" class="secondary-actn-btn height_2_5rem" placement="bottom" ngbTooltip="Vaccine Immunization Form">
                            <a [ngClass]="(rxFG?.value?.PrescReFill?.ImmunizationId > 0) ? 'Highlight--hotkey__buttons' : 'enable--hotkey__buttons'" (click)="openImmuneForm()" appShortcutKey [AltKey]="'a'">
                                <span class="hotkey--buttons text-center">A</span>
                                <span class="save--buttons">Imm Form</span>
                            </a>
                        </span>
                        <span class="secondary-actn-btn height_2_5rem" placement="bottom" ngbTooltip="Insurance Transmission Result">
                            <a class="enable--hotkey__buttons" (click)="openTrans()">
                                <span class="hotkey--buttons text-center">I</span>
                                <span class="save--buttons">Trans Result</span>
                            </a>
                        </span>
                        <span class="secondary-actn-btn height_2_5rem" *ngIf="!isRxVerification && displayFormBtn && formEntDetails?.length" placement="bottom" [ngbTooltip]="(formEntDetails[0].isCompleted ? 'Clinical Assessment is filled.' : formEntDetails[0].fillResponseId?.length ? 'Clinical Assessment is partially filled.': 'Clinical Assessment is not filled.')">
                            <a [ngClass]="(formEntDetails[0].isCompleted ? 'Highlight--hotkey__buttons' : 'Partial--hotkey__buttons')" (click)="openForm()"  appShortcutKey [AltKey]="'q'">
                                <span class="hotkey--buttons text-center">Q</span>
                                <span class="save--buttons">Assessment Form</span>
                            </a>
                        </span>
                        <span class="secondary-actn-btn height_2_5rem" *ngIf="!rphFmDph && !isPatientHistory" placement="bottom" ngbTooltip="Previous Rx">
                            <a class="enable--hotkey__buttons" *ngIf="(!isPatientHistory ? (rphFilters?.selectedIndex) : prevIndex)"  (click)="getPrev()" appShortcutKey [AltKey]="'p'">
                                <span class="hotkey--buttons text-center">P</span>
                                <span class="save--buttons">Previous</span>
                            </a>
                            <a class="disable--hotkey__buttons" *ngIf="(!isPatientHistory ? !(rphFilters?.selectedIndex) : !prevIndex)">
                                <span class="hotkey--buttons text-center">P</span>
                                <span class="save--buttons">Previous</span>
                            </a>
                        </span>
                        <span class="secondary-actn-btn height_2_5rem" *ngIf="!rphFmDph && !isPatientHistory" placement="bottom" ngbTooltip="Next Rx">
                            <a class="enable--hotkey__buttons"  *ngIf="(!isPatientHistory ? (nextbtnIndex && (rphFilters?.selectedIndex !== totalRecordsVerf - 1)) : nextIndex)" (click)="getNext()">
                                <span class="hotkey--buttons text-center">N</span>
                                <span class="save--buttons">Next</span>
                            </a>
                            <a class="disable--hotkey__buttons" *ngIf="(!isPatientHistory ? !((nextbtnIndex) && (rphFilters?.selectedIndex !== totalRecordsVerf-1)) : !nextIndex)">
                                <span class="hotkey--buttons text-center">N</span>
                                <span class="save--buttons">Next</span>
                            </a>
                        </span>
                        <span class="secondary-actn-btn height_2_5rem" *ngIf="!rphFmDph && !isPatientHistory" placement="bottom" [ngbTooltip]="((!isRxVerification && verifyData?.Iou?.length && diffNDCCnt && controlDrug) || (!isRxVerification && rxFG?.value?.PrescReFill?.IsEquivalentDisp && isPaidRecordsAv) ? (privCheckForNDCChange ? 'Verify' : '') : 'Verify & Next')">
                            <a [ngClass]="(!isRxVerification && verifyData?.Iou?.length && diffNDCCnt && controlDrug) ? (!privCheckForNDCChange ? 'disable--hotkey__buttons' : 'enable--hotkey__buttons') : 'enable--hotkey__buttons'" *ngIf="(!isPatientHistory ? (nextbtnIndex && (rphFilters?.selectedIndex !== totalRecordsVerf - 1)) : nextIndex)" (click)="(!isRxVerification && verifyData?.Iou?.length && diffNDCCnt && controlDrug) ? (privCheckForNDCChange ? openOveride('PharmacistVerification','AllowRphVerificationIfDrugChange') : '') : verifyRph('VerifyNext')" [title]="(!isRxVerification && verifyData?.Iou?.length && diffNDCCnt && controlDrug) ? (!privCheckForNDCChange ? 'We have detected a change in NDC based on the prescribed medicationand user does not have privileges to perform this action.' : '') : ''">
                                <span class="hotkey--buttons text-center">M</span>
                                <span class="save--buttons">Verify & Next</span>
                            </a>
                            <a class="disable--hotkey__buttons" *ngIf="(!isPatientHistory ? !((nextbtnIndex) && (rphFilters?.selectedIndex !== totalRecordsVerf-1)) : !nextIndex)">
                                <span class="hotkey--buttons text-center">M</span>
                                <span class="save--buttons">Verify & Next</span>
                            </a>
                        </span>
                        <!-- <span class="secondary-actn-btn" placement="bottom" ngbTooltip="Cancel">
                            <a class="enable--hotkey__buttons" appShortcutKey [AltKey]="'c'" (click)="cancelRPH()" (click)="resetAdminData()">
                                <span class="hotkey--buttons text-center">C</span>
                                <span class="save--buttons">Cancel</span>
                            </a>
                        </span> -->
                        <span class="review_x_days pt-3" [ngClass]="isPatientHistory ? 'margin_right_n1_9rem' : 'margin_right_n2_3rem'">
                            <eprx-input [LabelText]="'Review \'X\' days'" [PlaceHolder]="'Enter Days'" [ControlName]="'RphAndRxVerificationForPatientReviewOverXdays'"
                            [FormGroupName]="searchFG" [MinValue]="1" [InputType]="'NUMBER'" (TriggerOnEnterValue) = "showActionButtons()" (TriggerOnTabbing) = "showActionButtons()"
                            [DecimalValues]="0">
                        </eprx-input>
                        </span>
        
                    </div>
                </div>
            </div>
        
            <div class="row small--erx__screen">
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 pat-pre-drug-block padding_2px_0px_0px_0px">
                    <div class="col-12">
                        <app-electronic-rx-doc [IsFromRoute]="'rph'" [RxInfoFromVerifications]="verifyData"  [ErxDocData]="detailsInfo" *ngIf="(((verifyData && verifyData?.XmlFileId)) && (fileTypeToShow === 'erxDetails'))">
                        </app-electronic-rx-doc>
                        <div class="exprx--common-block erx__Doc--section" *ngIf="((fileTypeToShow !== 'erxDetails' && fileTypeToShow !== 'rxDetails') || (!totalDocs?.length))">
                            <!-- <div class="eprx--block__content erx__doc--body overflow_y_scroll text-center height_42rem"> -->
                                <div class="eprx--block__content erx__doc--body  text-center height_42rem overflow_y_scroll overflow_x_hidden">
                             <!--    <div class="row" style="margin-top: 2rem" *ngIf="hasDocuments">
                                    <div class="col-md-12">
                                        <wj-flex-grid #documentInfo class="grid" [headersVisibility]="'Column'"
                                            [itemsSource]="documentsList" [isReadOnly]="true" [selectionMode]="'None'">
                                            <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader"
                                                [visible]="true" *ngFor="let actvHeader of actvHeaders; let i = index"
                                                title="{{ actvHeader }}" [width]="actvHeader === 'Actions' ? 100 : '*'">
                                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                    <div *ngIf="actvHeader === 'Actions'" style="text-align: center">
                                                        <i class="far fa-tv actions" style="margin-left: 10px"
                                                            title="Preview" (click)="previewSelectedFile(item?.data)"></i>
                                                    </div>
                                                    <div *ngIf="!(actvHeader === 'Actions')">
                                                        {{ item[actvHeader] }}
                                                    </div>
                                                </ng-template>
                                            </wj-flex-grid-column>
                                        </wj-flex-grid>
                                    </div>
                                </div>
                               <div class="row" *ngIf="fileTypeToShow && documentAvailable && hasDocuments">
                                    <div class="col-md-12">
                                        <iframe *ngIf="fileTypeToShow == 'pdf'" [src]="pdfToShow" class="text-center"
                                            toolbar="0" width="100%" height="500px" type="application/pdf"
                                            style="width: 100%; border: 3px solid black;"></iframe>
                                        <img *ngIf="fileTypeToShow == 'image'" [src]="imageToShow"
                                            style="width: 100%; border: 3px solid black;">
                                            <app-electronic-rx-doc [ErxDocData]="detailsInfo" *ngIf="((verifyData && verifyData.XmlFileId)) && (fileTypeToShow === 'erxDetails')">
                                            </app-electronic-rx-doc>
                                    </div>
                                </div>-->
    
                                <div class="row overflow_y_scroll" *ngIf="fileTypeToShow && documentAvailable && hasDocuments">
                                    <div class="col-md-12">
                                        <iframe *ngIf="fileTypeToShow == 'pdf'" [src]="pdfToShow" class="text-center w-100 border_3px_solid_black"
                                            toolbar="0" width="100%" height="600px" type="application/pdf"
                                            ></iframe>
    
                                        <!-- <img *ngIf="fileTypeToShow == 'image'" [src]="imageToShow"
                                            style="width: 100%; border: 3px solid black;height: 40rem"> -->
    
                                          <lib-ngx-image-zoom *ngIf="fileTypeToShow == 'image'" [thumbImage]=imageToShow [fullImage]=imageToShow
                                            [magnification]="1" [enableScrollZoom]="true" [enableLens]="true" [lensHeight]="100" [lensWidth]="200">
                                        </lib-ngx-image-zoom>
                                    </div>
                                </div>
    
                                <div class="row padding_left_15rem" *ngIf="!hasDocuments">
                                    <h2>No Documents for this Rx</h2>
                                </div>
                            </div>
                        </div>
                        <app-electronic-rx-doc [RxInfoFromVerifications]="verifyData" *ngIf="(((verifyData && !verifyData?.XmlFileId)) && (fileTypeToShow === 'rxDetails'))" [IsFromRoute]="'rph'">
                        </app-electronic-rx-doc>
                       <div class="row" *ngIf="(totalDocs?.length === 1) ? (fileTypeToShow !== 'rxDetails' && fileTypeToShow !== 'erxDetails') : totalDocs?.length">
                        <div class="col-12 text-center">
                            <div class="d-inline">
                                <i class="far fa-step-backward actions text-primary font_size_1_5rem"  (click)="navigateToNextImage(false)"></i>
                            </div>
                            <div class="d-inline px-2">
                                <label class="font_size_1_5rem" > {{currentDocIndex + 1}} of {{totalDocs?.length}}</label>
                            </div>
                            <div class="d-inline">
                                <i class="far fa-step-forward actions text-primary font_size_1_5rem"  (click)="navigateToNextImage(true)"></i>
                            </div>
                        </div>
                       </div>
                    </div>
                    <div class="exprx--common-block erx--body insurance--body">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Observations</span>
                                        </div>
                                        <div class="col-md-2 text-right">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content max-height_11rem overflow_y_scroll">
                            <div>
                                <div class="row width_100per mt-2 mb-3">
                                    <div class="col-lg-1">
                                    </div>
                                    <div class="col-lg-2">
                                        <div (click)="getDrugAllergiesStatus()"
                                            *ngIf="(drugInteractions?.Allergies && drugInteractions?.AllergiesCount)"
                                            [ngbTooltip]="'Drug to Patient Allergy Conflict found'" triggers="mouseover:mouseleave" [autoClose]="true"
                                            container="body" placement="top">
                                            <span class="clinicalCheck_icons check-alert text-bg-red">!</span>
                                            <span class="check-button ml-2 clinical-alert">ALLG</span>
                                        </div>
                                        <div *ngIf="!(drugInteractions?.Allergies && drugInteractions?.AllergiesCount)"
                                            [ngbTooltip]="'Drug to Patient Allergy Conflict not found'"
                                            triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check text-bg-green">&#10003;</span>
                                            <span class="check-button ml-2">ALLG</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div (click)="getDrugToDrugInterationInfo()" *ngIf="alrtTyp.isDDI"
                                            [ngbTooltip]="'Drug To DrugInteractions found'" triggers="mouseover:mouseleave" [autoClose]="true"
                                            container="body" placement="top">
                                            <span class="clinicalCheck_icons check-alert text-bg-red">!</span>
                                            <span class="check-button ml-2 pr-4 clinical-alert">DDI</span>
                                        </div>
                                        <div *ngIf="!alrtTyp.isDDI"
                                            [ngbTooltip]="'Drug To DrugInteractions not found'" triggers="mouseover:mouseleave" [autoClose]="true"
                                            container="body" placement="top">
                                            <span class="clinicalCheck_icons check text-bg-green">&#10003;</span>
                                            <span class="check-button ml-2">DDI</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div (click)="getDrugDeceaseContradictionInfo()" *ngIf="alrtTyp.isDDC"
                                            [ngbTooltip]="'Drug Disease ContradictionInfo found'" triggers="mouseover:mouseleave" [autoClose]="true"
                                            container="body" placement="top">
                                            <span class="clinicalCheck_icons check-alert text-bg-red">!</span>
                                            <span class="check-button ml-2 pr-4 clinical-alert">DDC</span>
                                        </div>
                                        <div *ngIf="!alrtTyp.isDDC"
                                            [ngbTooltip]="'Drug Disease ContradictionInfo not found'" triggers="mouseover:mouseleave" [autoClose]="true"
                                            container="body" placement="top">
                                            <span class="clinicalCheck_icons check text-bg-green">&#10003;</span>
                                            <span class="check-button ml-2">DDC</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div (click)="getAdverseReaction()"
                                            *ngIf="(drugInteractions?.AdverseReaction && drugInteractions.AdverseReaction.length > 0)"
                                            [ngbTooltip]="'Adverse Drug Reaction Popup found'" triggers="mouseover:mouseleave" [autoClose]="true"
                                            container="body" placement="top">
                                            <span class="clinicalCheck_icons check-alert text-bg-red">!</span>
                                            <span class="check-button ml-2 pr-4 clinical-alert">ADV</span>
                                        </div>
                                        <div
                                            *ngIf="!(drugInteractions?.AdverseReaction && drugInteractions.AdverseReaction.length > 0)"
                                            [ngbTooltip]="'Adverse Drug Reaction Popup not found'" triggers="mouseover:mouseleave" [autoClose]="true"
                                            container="body" placement="top">
                                            <span class="clinicalCheck_icons check text-bg-green">&#10003;</span>
                                            <span class="check-button ml-2">ADV</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div (click)="getDrugPregnancyInfo()" *ngIf="alrtTyp.isPRG"
                                            [ngbTooltip]="'Pregnancy and Lactation Information found'" triggers="mouseover:mouseleave"
                                            [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check-alert text-bg-red">!</span>
                                            <span class="check-button ml-2 pr-4 clinical-alert">PREG</span>
                                        </div>
                                        <div *ngIf="!alrtTyp.isPRG"
                                            [ngbTooltip]="'Pregnancy and Lactation Information not found'" triggers="mouseover:mouseleave"
                                            [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check text-bg-green">&#10003;</span>
                                            <span class="check-button ml-2">PREG</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                    </div>
                                </div>
                                <div class="row width_100per mt-2 mb-3">
                                    <div class="col-lg-1">
                                    </div>
                                    <div class="col-lg-2">
                                        <div *ngIf="rxFG?.value?.Drug?.isDoseCheckDisabled" [ngbTooltip]="'Dose Check Not Available'"   triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check-alert grey">!</span>
                                            <span class="check-button ml-2 grey_border">DOSE</span>
                                        </div>
                                        <div *ngIf="alrtTyp.isDOSE && !rxFG?.value?.Drug?.isDoseCheckDisabled" [ngbTooltip]="'Dose Check Result found'" triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check text-bg-red">!</span>
                                            <span class="check-button ml-2 clinical-alert">DOSE</span>
                                        </div>
                                        <div *ngIf="!alrtTyp.isDOSE && !rxFG?.value?.Drug?.isDoseCheckDisabled"  [ngbTooltip]="'Dose Check Result not found'"   triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check text-bg-green">&#10003;</span>
                                            <span class="check-button ml-2">DOSE</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div (click)="getDuplicateDrugTherapyInfo()" *ngIf="(drugInteractions && drugInteractions?.DuplicateDrugs && drugInteractions?.DuplicateDrugs?.length > 0)"  [ngbTooltip]="'Duplicate Drug Therapy found'"   triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check-alert text-bg-red">!</span>
                                            <span class="check-button ml-2 pr-4 clinical-alert">DDT</span>
                                        </div>
                                        <div *ngIf="!(drugInteractions && drugInteractions?.DuplicateDrugs && drugInteractions?.DuplicateDrugs?.length > 0)"  [ngbTooltip]="'Duplicate Drug Therapy not found'"   triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check text-bg-green">&#10003;</span>
                                            <span class="check-button ml-2">DDT</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div (click)="getAcbScore()" *ngIf="alrtTyp.isACB" [ngbTooltip]="'ACB Scoring found'"   triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check-alert text-bg-red">!</span>
                                            <span class="check-button ml-2 pr-4 clinical-alert">ACB</span>
                                        </div>
                                        <div *ngIf="!alrtTyp.isACB" [ngbTooltip]="'ACB Scoring not found'" triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check text-bg-green">&#10003;</span>
                                            <span class="check-button ml-2">ACB</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div>
                                        <div (click)="getLifeStyle()" *ngIf="(drugInteractions?.LifeStyle && drugInteractions.LifeStyle.length > 0)"  [ngbTooltip]="'Lifestyle to Drug Interaction found'"   triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check-alert text-bg-red">!</span>
                                            <span class="check-button ml-2 pr-4 clinical-alert">LIFE STYLE</span>
                                        </div>
                                        <div *ngIf="!(drugInteractions?.LifeStyle && drugInteractions.LifeStyle.length > 0)"  [ngbTooltip]="'Lifestyle to Drug Interaction not found'" triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top">
                                            <span class="clinicalCheck_icons check text-bg-green">&#10003;</span>
                                            <span class="check-button ml-2">LIFE STYLE</span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row width_100per"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-6 pat-pre-drug-block pr-0">
                    <div class="row">
                        <div class="col-10 pr-0">
                            <div class="row">
                                <div *ngIf="!isRxVerification && verifyData?.Iou?.length" class="col-1 padding_top_0_5rem padding_right_0_3rem">DPV</div>
                                <div *ngIf="!isRxVerification && verifyData?.Iou?.length && diffNDCCnt === 0" class="pl-0 font_size_1_4rem height_2rem" [ngbTooltip]="'Billed NDC and Dispensed NDC is same.'"
                                triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="bottom">
                                <img src="assets/img/green.png"  class="height_2rem">
                                </div>
                                <div *ngIf="!isRxVerification && verifyData?.Iou?.length && diffNDCCnt" class="pl-0 font_size_1_4rem height_2rem" [ngbTooltip]="instructToolTip1" tooltipClass="search-tooltip"
                                triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="bottom">
                                <img src="assets/img/orange2.png" class="height_2rem">
                                </div>
                                <ng-template #instructToolTip1>
                                    <div class="col-12">
                                        <div class="row text-left">
                                           <p class="font_size_15px mb-0">
                                            Dispensed NDC# is either a Therapeutic Equivalent/ different size stock bottle from the billed NDC#.<br>
                                            Please check the Rx and change the drug NDC#, if you wish so to.<br>
                                            <span *ngIf="(rxFG?.value?.PrescReFill?.IsEquivalentDisp && isPaidRecordsAv)">For Insurance Prescriptions, please use RRR button to reverse-replace-retransmit.<br>
                                            For Cash Prescriptions, click on Edit Rx to change the drug.</span>
                                           </p><br>
                                           <div class="row text-left">
                                            <div class="col-md-12">
                                                <label>Billed NDC# : </label>
                                                <!-- <span>
                                                    {{verifyData?.Drug?.drugcode?.substr(0, 5) }}-{{verifyData?.Drug?.drugcode?.substr(5,
                                                        4) }}-{{verifyData?.Drug?.drugcode?.substr(9, 11)}}</span> -->
                                                <span>
                                                    {{this._formatsUtil.getNDCFormat(verifyData?.Drug?.drugcode)}}</span>
                                                <div *ngFor="let ndc of differentNDc; let i=index; ">
                                                    <!-- <label>Dispensed NDC{{i+1}}# : </label><span>{{ndc?.substr(0, 5) }}-{{ndc?.substr(5,
                                                        4) }}-{{ndc?.substr(9, 11)}}</span> -->
                                                    <label>Dispensed NDC{{i+1}}# : </label><span> {{this._formatsUtil.getNDCFormat(ndc)}} <span [ngStyle]="{'color': (rxFG?.value?.PrescReFill?.IsEquivalentDisp && verifyData.Iou[0].NotEquivalent) ? '#f7941d' : ''}">
                                                        {{(rxFG?.value?.PrescReFill?.IsEquivalentDisp && verifyData.Iou[0].NotEquivalent) ? '(Non-Equivalent NDC# from Pill Counter)' : ''}}
                                                    </span></span>
                                                </div>
                                            </div>
                                           </div>
                                        </div>
                                        </div>
                                </ng-template>
                            </div>
                            <div class="exprx--common-block erx--body insurance--body">
                            <div class="eprx--block__header">
                                <div><input type="checkbox" id="prescriber" class="form-check-input mt-2"></div>
                                <div class="eprx--header__heading pl-4">
                                    <span class="header--sml__htex width_93px"> Prescriber - </span>
                                    <span class="header--sml__text">
                                        <span class="header--sml__lstName">
                                            {{ verifyData?.Prescriber?.fullname ? (verifyData?.Prescriber?.fullname.replace('_', ', ') | uppercase) : ""}}
                                        </span>
                                    </span>
                                    <ul class="pull-right eprx--header__icons mr-3">
                                        <!-- <li>
                                            <input type="checkbox" class="form-check-input mt-2 ml-2" id="prescriber">
                                        </li> -->
                                        <li *ngIf="verifyData && verifyData.XmlFileId"> <i
                                            [ngClass]="(prescriberName | uppercase)!==(detailsInfo?.PrescName| uppercase) ? 'ml-1 far fa-lg fa-times-circle text-danger' : 'ml-1 far fa-lg fa-check-circle text-success'"></i>
                                       </li>
                                        <li>
                                            <!-- <a *ngIf="hasOvrRidPrv"> <img src="assets/User-md-edit3-mm.svg" title="Edit Prescriber (Alt+Y)" appShortcutKey [AltKey]="'y'" class="mr-0" style="width: 2rem;height: 2rem;"
                                                    (click)="openPrescriberModal()"/>
                                            </a> -->
                                            <a *ngIf="hasOvrRidPrv"> <img src="assets/User-md-edit3-mm.svg" title="Edit Prescriber (Ctrl+Y)" appShortcutKey [CtrlKey]="'y'" class="height_4rem font_size_35px margin_bottom_0_5rem"
                                                (click)="openPrescriberModal()"/>
                                        </a>
                                            <a *ngIf="!hasOvrRidPrv" >
                                                <img src="assets/User-md-edit3-mm.svg" title="User has No privilege" appShortcutKey [AltKey]="'y'" class="width_2rem height_2rem"
                                                (click)="openOveride('PrescriberFile', 'Edit')"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a  (click)="openNotesModel('prescriber')">
                                                <i  title="Prescriber Notes" [ngClass]="(verifyData?.Prescriber?.IsPrescNotestAvailable) ? 'fa-kit fa-notes-solid text-green' : 'fa-kit fa-notes-light'"
                                                ></i>
                                                
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <label>Address</label>
                                            <div class="label--data" [title]='(PrescAddress?.AddressLine1 ?
                                            ((PrescAddress?.AddressLine1).trim()|uppercase):"")+ ", " + (PrescAddress?.AddressLine2 ?
                                            (PrescAddress?.AddressLine2.trim()|uppercase) :"")+ ", " +(PrescAddress?.CityName ?
                                            (PrescAddress?.CityName.trim()|uppercase) :"") + ", "+ (PrescAddress?.StateName ?
                                            (PrescAddress?.StateName.trim()|uppercase) :"")+ ", "+(PrescAddress?.ZipCode ?
                                            (PrescAddress?.ZipCode.trim()|uppercase) :"")'>
                                                {{PrescAddress?.AddressLine1}},
                                                <span *ngIf="PrescAddress?.AddressLine2">{{PrescAddress?.AddressLine2}},</span>
                                                <span *ngIf="PrescAddress?.CityName">{{PrescAddress?.CityName}}, </span>
                                                <span *ngIf="PrescAddress?.StateName">{{PrescAddress?.StateName}}, </span>
                                                <span *ngIf="PrescAddress?.ZipCode">{{PrescAddress?.ZipCode}}</span></div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>NPI#</label>
                                            <div class="label--data">{{verifyData?.Prescriber?.prescribernpinum ?
                                                verifyData?.Prescriber?.prescribernpinum:"--"}}</div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label class="align-label">DEA#</label>
                                            <div class="label--data">{{verifyData?.Prescriber?.prescriberdeanum ?
                                                _formatsUtil.getDeaFormat(verifyData?.Prescriber?.prescriberdeanum):"--"}}</div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label class="align-label" >Phone#</label>
                                            <div class="label--data" [title]="verifyData?.Prescriber?.telephone ?
                                            (verifyData?.Prescriber?.telephone | mask : '(000)000-000000000'):'--'">{{verifyData?.Prescriber?.telephone ?
                                                (verifyData?.Prescriber?.telephone | mask : "(000)000-0000"):"--"}}</div>
                                        </div>
                                        <!-- prescriberaddress1 prescribercity prescriberstate prescriberzipcode telephone worknumber prescriberemail faxnumber -->
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="exprx--common-block erx--body insurance--body mt-2">
                            <div class="eprx--block__header">
                                <div><input type="checkbox" class="form-check-input mt-2" id="patient"></div>
                                <div class="eprx--header__heading pl-4">
                                    <span class="header--sml__htex width_80px"> Patient - </span>
                                    <span class="header--sml__text w-84">
                                        <span class="header--sml__lstName">
                                            {{ verifyData?.Patient?.fullname ? (verifyData?.Patient?.fullname.replace('_', ', ') | uppercase) : ""}}
                                        </span>
                                        <span class="text-danger" *ngIf="isPrmptHighRsk">
                                            <span *ngIf="years > geriatricAge"> <b> - GERIATRIC (Over Age of {{geriatricAge}})</b>
                                            </span>
                                            <span *ngIf="years < youthUnderAge"> <b> - Youth (Under Age of {{youthUnderAge}})</b>
                                            </span>
                                        </span>
                                    </span>
                                    <ul class="pull-right eprx--header__icons mr-3 w-38">
                                        <!-- <li>
                                            <input type="checkbox" class="form-check-input mt-2 ml-2" id="patient">
                                        </li> -->
                                        <li *ngIf="verifyData && verifyData.XmlFileId">
                                            <i [ngClass]="(patientName | uppercase)!==(detailsInfo?.PatName| uppercase) ? 'ml-1 far fa-lg fa-times-circle text-danger' :
                                             'ml-1 far fa-lg fa-check-circle text-success'"></i>
                                       </li>
                                        <li>
                                            <a *ngIf="hasOvrRidPrvP">
                                                <i class="far fa-user-edit fa-lg icon-fon-s"  (click)="editPatient()" appShortcutKey [CtrlKey]="'x'" title="Edit Patient(Ctrl+X)"></i>
                                            </a>
                                            <a *ngIf="!hasOvrRidPrvP">
                                                <i class="far fa-user-edit fa-lg icon-fon-s" title="User has No privilege" (click)="openOveride('PatientFile', 'Edit')"  appShortcutKey [AltKey]="'x'"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a (click)="openNotesModel('patient')">
                                                <i   title="Patient Notes" [ngClass]="(verifyData?.Prescriber?.IsPatientNotestAvailable) ? 'fa-kit fa-notes-solid text-green' : 'fa-kit fa-notes-light'"></i>
    
                                            </a>
                                        </li>
                                        <li>
                                            <a (click)="goToPatHist()">
                                                <i class="far fa-history fa-lg icon-fon-s"  title="Patient History"></i>
                                            </a>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>Address</label>
                                            <div class="label--data" [title]='(verifyData?.Patient?.address1 ?
                                                (verifyData?.Patient?.address1 | uppercase):"")+ ", " + (verifyData?.Patient?.city ?
                                                verifyData?.Patient?.city :"")+ ", " +(verifyData?.Patient?.state ?
                                                verifyData?.Patient?.state :"") + ", "+ (verifyData?.Patient?.zipcode ?
                                                verifyData?.Patient?.zipcode :"")'>
                                                {{(verifyData?.Patient?.address1 ?
                                                    verifyData?.Patient?.address1:"")+ ", " + (verifyData?.Patient?.city ?
                                                    verifyData?.Patient?.city :"")+ ", " +(verifyData?.Patient?.state ?
                                                    verifyData?.Patient?.state :"") + ", "+ (verifyData?.Patient?.zipcode ?
                                                    verifyData?.Patient?.zipcode :"")}}</div>
                                                <!-- city state zipcode -->
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" [title]='(verifyData?.Patient?.gender ? verifyData?.Patient?.gender : "--")'>
                                            <label>Gender</label>
                                            <div class="label--data" [title]='verifyData?.Patient?.gender ? verifyData?.Patient?.gender : "--"'>
                                                {{verifyData?.Patient?.gender ? verifyData?.Patient?.gender : "--"}}
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <label>DOB</label>
                                            <div class="label--data">{{verifyData?.Patient?.dob ?
                                                (verifyData?.Patient?.dob | date:'MM/dd/yyyy') :"--"}}</div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" [title]="verifyData?.Patient?.primeinsupolicyno ?
                                            verifyData?.Patient?.primeinsupolicyno :'--'">
                                            <label>Ins ID</label>
                                            <div class="label--data">{{verifyData?.Patient?.primeinsupolicyno ?
                                                verifyData?.Patient?.primeinsupolicyno :"--"}}</div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <label>Ins Code</label>
                                            <div class="label--data">{{insurDetails?.InsurerCode ? insurDetails?.InsurerCode : '--'}}</div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <label>Group#</label>
                                            <div class="label--data">
                                                {{ insurDetails?.groupname ? insurDetails?.groupname : "--" }}
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <label>Ez-cap?</label>
                                            <div class="label--data">
                                                {{verifyData?.Patient?.isEZCapReq  ? 'Yes' : 'No' }}
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                                            <label class="align-label">Allergies</label>
                                            <div [ngClass]="patientAlle && patientAlle[0] !== 'No Known Allergies' ? 'allergy-color max-height_27px overflow_y_scroll' : ''" >{{patientAlle.join(", ")}}</div>
            
                                        </div>
                                        <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <label class="align-label" >Notes</label>
                                            <div class="label--data" [title]="(verifyData?.Patient?.notes | uppercase)">{{verifyData?.Patient?.notes}}</div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="exprx--common-block erx--body insurance--body mt-2">
                                <div class="eprx--block__header">
                                    <div><input type="checkbox" class="form-check-input mt-2" id="drug"></div>
                                    <div class="eprx--header__heading pl-4">
                                        <span class="header--sml__htex width_42px"> Drug - </span>
                                        <span class="header--sml__text w-84">
                                            <span class="header--sml__lstName label--data">
                                                {{verifyData?.Drug?.drugname ? verifyData?.Drug?.drugname : ''}}
                                                {{ verifyData?.Drug?.strength ? ' ' + verifyData?.Drug?.strength: ''}}
                                                {{ verifyData?.Drug?.drugformname ? ' ' + verifyData?.Drug?.drugformname: ''}}
                                            </span>
                                        </span>
                                    </div>
                                    <!-- <div class="eprx--header__heading">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-5 pl-0">
                                                    <span class="header--sml__htex" > Drug </span>
                                                </div>
                                                <div class="col-lg-7">
                                                    <div class="mt-2 float-right mr-4"><input type="checkbox" class="form-check-input ml-2" id="drug"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="eprx--block__content">
                                    <div class="col-lg-12 padding-0">
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <div class="row">
                                                    <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4" *ngIf="!detailsInfo">
                                                        <label>Drug Name
                                                            <span
                                                                *ngIf="verifyData && verifyData?.XmlFileId && verifyData?.Drug && detailsInfo?.DrugNdc"
                                                                class="padding__left-1">
                                                                <i
                                                                    [ngClass]="(verifyData?.IsDrugEquivalent === false) ? 'far fa-times-circle text-danger fa-lg' : 'far fa-check-circle text-success fa-lg'"></i>
                                                            </span>
                                                        </label>
                                                        <div class="label--data height_2rem background_antiquewhite white_space_unset border_radius_6px"
                                                            [title]="((verifyData?.Drug?.drugname ? verifyData?.Drug?.drugname : '') +
                                                                                ( verifyData?.Drug?.strength ? ' ' + verifyData?.Drug?.strength: '') +
                                                                                ( verifyData?.Drug?.drugformname ? ' ' + verifyData?.Drug?.drugformname: '') | uppercase)">
                                                            {{verifyData?.Drug?.drugname ? verifyData?.Drug?.drugname : ''}}
                                                            {{ verifyData?.Drug?.strength ? ' ' + verifyData?.Drug?.strength: ''}}
                                                            {{ verifyData?.Drug?.drugformname ? ' ' + verifyData?.Drug?.drugformname: ''}}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4"
                                                        *ngIf="(detailsInfo && ((verifyData?.IsDrugEquivalent === null) || !(detailsInfo?.DrugNdc)))">
                                                        <label>Drug Name
                                                            <span
                                                                *ngIf="verifyData && verifyData?.XmlFileId && verifyData?.Drug && detailsInfo?.DrugNdc"
                                                                class="padding__left-1">
                                                                <i
                                                                    [ngClass]="(verifyData?.IsDrugEquivalent === false) ? 'far fa-times-circle text-danger fa-lg' : 'far fa-check-circle text-success fa-lg'"></i>
                                                            </span>
                                                        </label>
                                                        <div class="label--data height_2rem background_antiquewhite white_space_unset border_radius_6px"
                                                            [title]="((verifyData?.Drug?.drugname ? verifyData?.Drug?.drugname : '') +
                                                                                ( verifyData?.Drug?.strength ? ' ' + verifyData?.Drug?.strength: '') +
                                                                                ( verifyData?.Drug?.drugformname ? ' ' + verifyData?.Drug?.drugformname: '') | uppercase)">
                                                            {{verifyData?.Drug?.drugname ? verifyData?.Drug?.drugname : ''}}
                                                            {{ verifyData?.Drug?.strength ? ' ' + verifyData?.Drug?.strength: ''}}
                                                            {{ verifyData?.Drug?.drugformname ? ' ' + verifyData?.Drug?.drugformname: ''}}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 pr-0"
                                                        *ngIf="(verifyData && verifyData?.XmlFileId && verifyData?.Drug && detailsInfo?.DrugNdc && (verifyData?.IsDrugEquivalent !== null))">
                                                        <label>Dispensed Drug <span>{{verifyData?.Drug?.isbranded ? '(Brand Eqv.)' : '(Generic
                                                                Eqv.)'}}</span>
                                                            <span
                                                                *ngIf="verifyData && verifyData?.XmlFileId && verifyData?.Drug && detailsInfo?.DrugNdc"
                                                                class="padding__left-1">
                                                                <i
                                                                    [ngClass]="(verifyData?.IsDrugEquivalent === false) ? 'far fa-times-circle text-danger fa-lg' : 'far fa-check-circle text-success fa-lg'"></i>
                                                            </span>
                                                        </label>
                                                        <div class="label--data background_antiquewhite white_space_unset border_radius_6px">
                                                            <span
                                                                [title]="((verifyData?.Drug?.drugname ? verifyData?.Drug?.drugname : '') +
                                                                                    ( verifyData?.Drug?.strength ? ' ' + verifyData?.Drug?.strength: '') +
                                                                                    ( verifyData?.Drug?.drugformname ? ' ' + verifyData?.Drug?.drugformname: '') | uppercase)">
                                                                {{verifyData?.Drug?.drugname ? verifyData?.Drug?.drugname : ''}}
                                                                {{ verifyData?.Drug?.strength ? ' ' + verifyData?.Drug?.strength: ''}}
                                                                {{ verifyData?.Drug?.drugformname ? ' ' + verifyData?.Drug?.drugformname: ''}}
                                                                Brand:</span><br>
                                                        </div>
                                                        <label>Prescribed Drug</label>
                                                        <div class="label--data background_antiquewhite white_space_unset border_radius_6px">
                                                            <span [title]="(detailsInfo?.DrugName ? ' ' + detailsInfo?.DrugName : '')">
                                                                {{detailsInfo?.DrugName ? ' ' + detailsInfo?.DrugName : ''}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-7"
                                                        *ngIf="(verifyData && verifyData?.XmlFileId && verifyData?.Drug && detailsInfo?.DrugNdc && (verifyData?.Drug?.drugcode !== detailsInfo?.DrugNdc))">
                                                        <div class="row">
                                                            <div class="col-lg-6 col-md-6 col-sm-4 col-xs-4">
                                                                <label class="pr-2">NDC</label>
                                                                <app-common-dscsa-info *ngIf="verifyData?.Drug" [DrugSlctd]="verifyData?.Drug"
                                                                    [FromScreen]="'Captured from Rph Screen'" [PrescrefillId]="verifyData?.PrescReFill?.Id"
                                                                    (isDSCSAExist)="displayLot($event)"></app-common-dscsa-info>
                                                                    <button *ngIf="verifyData?.PrescReFill?.IsIou" class="iou-badge" [ngClass] = "notEqvList ? 'iou-orange' : 'iou-green'" id="iouActn"
                                                                    (click)="openActionModal()" (keydown.enter)="openActionModal()" tabindex="34" container="body" ngbTooltip="IOU">
                                                                        IOU<span>{{verifyData?.IouDetails?.length}}</span>
                                                                    </button>
                                                                    <app-iou-capture *ngIf="IOUClk" [systemData]="systemData" [RxInfo]="[rxFG?.value]" (IsPopupClosed)="IOUClk = false"></app-iou-capture>
                                                                <div class="label--data">{{this._formatsUtil.getNDCFormat(verifyData?.Drug?.ndc)}}</div>
                                                            </div>
                                                            <div *ngIf="displayLotNum" class="col-lg-6 col-md-6 col-sm-4 col-xs-4">
                                                                <label>Drug Expiration</label>
                                                                <div class="label--data">
                                                                    {{verifyData?.Drug?.discontdt ? (verifyData?.Drug?.discontdt | datetimeUtc ) :
                                                                    '--'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-lg-6 col-md-6 col-sm-4 col-xs-4">
                                                                <label>Qty in Hand</label>
                                                                <div class="label--data ">
                                                                    {{ verifyData ? (_buckUtils.getQtyInHand(verifyData?.Drug?.manageinventory ?
                                                                    verifyData?.Drug?.manageinventory : null, verifyData?.PrescReFill.BucketId)) :
                                                                    "0.000"}}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6 col-sm-4 col-xs-4" *ngIf="displayLotNum">
                                                                <label>Drug Lot#</label>
                                                                <div class="label--data">
                                                                    {{verifyData?.PrescReFill.LotNum ? verifyData?.PrescReFill.LotNum : '--'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div [ngClass]="displayLotNum ? 'col-lg-3 col-md-3 col-sm-3 col-xs-3' : 'row col-lg-4 col-md-4 col-sm-4 col-xs-4'"
                                                        *ngIf="!(verifyData && verifyData?.XmlFileId && verifyData?.Drug && detailsInfo?.DrugNdc && (verifyData?.Drug?.drugcode !== detailsInfo?.DrugNdc))">
                                                        <label class="pr-2">NDC
                                                        </label>
                                                        <app-common-dscsa-info *ngIf="verifyData?.Drug" [DrugSlctd]="verifyData?.Drug"
                                                            [FromScreen]="'Captured from Rph Screen'" [PrescrefillId]="verifyData?.PrescReFill?.Id"
                                                            (isDSCSAExist)="displayLot($event)"></app-common-dscsa-info>
                                                            <div *ngIf="verifyData?.PrescReFill?.IsIou && !displayLotNum" class="pl-1">
                                                                <button
                                                                class="iou-badge"
                                                                [ngClass] = "notEqvList ? 'iou-orange' : 'iou-green'"
                                                                    id="iouActn"
                                                                    (click)="openActionModal()"
                                                                    (keydown.enter)="openActionModal()"
                                                                    tabindex="34"
                                                                    container="body"
                                                                    ngbTooltip="IOU">
                                                                    IOU<span>{{verifyData?.IouDetails?.length}}</span>
                                                                </button>
                                                            </div>
                                                            <button
                                                                *ngIf="verifyData?.PrescReFill?.IsIou && displayLotNum"
                                                                class="iou-badge"  
                                                                [ngClass] = "notEqvList ? 'iou-orange' : 'iou-green'"
                                                                id="iouActn"
                                                                (click)="openActionModal()"
                                                                (keydown.enter)="openActionModal()"
                                                                tabindex="34"
                                                                container="body"
                                                                ngbTooltip="IOU">
                                                                IOU<span>{{verifyData?.IouDetails?.length}}</span>
                                                            </button>
                                                        <app-iou-capture *ngIf="IOUClk" [systemData]="systemData" [RxInfo]="[rxFG?.value]"
                                                            (IsPopupClosed)="IOUClk = false"></app-iou-capture>
                                                        <div class="label--data">{{this._formatsUtil.getNDCFormat(verifyData?.Drug?.ndc)}}</div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                                        *ngIf="!(verifyData && verifyData?.XmlFileId && verifyData?.Drug && detailsInfo?.DrugNdc && (verifyData?.Drug?.drugcode !== detailsInfo?.DrugNdc)) && displayLotNum">
                                                        <label>Drug Expiration</label>
                                                        <div class="label--data">
                                                            {{verifyData?.Drug?.discontdt ? (verifyData?.Drug?.discontdt | datetimeUtc ) : '--'}}
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4" *ngIf="!(verifyData && verifyData?.XmlFileId && verifyData?.Drug && detailsInfo?.DrugNdc && (verifyData?.Drug?.drugcode !== detailsInfo?.DrugNdc))">
                                                                            <label class="custom--label padding-0 d-inline">Drug Description</label>
                                                                            <span class="d-inline ml-0 margin_left_0_5rem padding_0_0_5_rem font-weight-bold color_white height_23pxwidth_23px text-center font_size_1_2rem background_orange border_radius_50per"
                                                                            [ngbTooltip]="instructToolTip" tooltipClass="search-tooltip" triggers="mouseover:mouseleave" [autoClose]="false"
                                                                            placement="right">𝒊</span>
                                                                        </div> -->
                                                    <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4"
                                                        *ngIf="!(verifyData && verifyData?.XmlFileId && verifyData?.Drug && detailsInfo?.DrugNdc && (verifyData?.Drug?.drugcode !== detailsInfo?.DrugNdc))">
                                                        <label>Qty in Hand</label>
                                                        <div class="label--data ">
                                                            {{ verifyData ? (_buckUtils.getQtyInHand(verifyData?.Drug?.manageinventory ?
                                                            verifyData?.Drug?.manageinventory : null, verifyData?.PrescReFill.BucketId)) : "0.000"}}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3"
                                                        *ngIf="displayLotNum && !(verifyData && verifyData?.XmlFileId && verifyData?.Drug && detailsInfo?.DrugNdc && (verifyData?.Drug?.drugcode !== detailsInfo?.DrugNdc))">
                                                        <label>Drug Lot#</label>
                                                        <div class="label--data">
                                                            {{verifyData?.PrescReFill.LotNum ? verifyData?.PrescReFill.LotNum : '--'}}
                                                        </div>
                                                    </div>
                                
                                                    <ng-template #instructToolTip>
                                                        <div>
                                                            <div class="row-text-left"
                                                                *ngIf="!(verifyData?.Drug?.drugdescription && verifyData?.Drug?.drugdescription.length)">
                                                                <div class="col-md-12">
                                                                    <label class="text-decoration_underline margin_bottom_1rem">Drug
                                                                        Description</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    No Description available
                                                                </div>
                                                            </div>
                                                            <div class="row text-left"
                                                                *ngIf="(verifyData?.Drug?.drugdescription && verifyData?.Drug?.drugdescription?.length)">
                                                                <div class="col-md-12">
                                                                    <label class="text-decoration_underline margin_bottom_1rem">Drug
                                                                        Description</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label>Shape :</label>
                                                                    <span>
                                                                        {{verifyData?.Drug?.drugdescription[0].shape ?
                                                                        (verifyData?.Drug?.drugdescription[0].shape | uppercase): '--'}}</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label>Coating :</label><span>
                                                                        {{verifyData?.Drug?.drugdescription[0].coatingname ?
                                                                        (verifyData?.Drug?.drugdescription[0].coatingname | uppercase) : '--'}}</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label>Color Front :</label><span>
                                                                        {{verifyData?.Drug?.drugdescription[0].color_frnt ?
                                                                        (verifyData?.Drug?.drugdescription[0].color_frnt | uppercase) : '--'}}</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label>Color Back :</label><span>
                                                                        {{verifyData?.Drug?.drugdescription[0].color_back ?
                                                                        (verifyData?.Drug?.drugdescription[0].color_back | uppercase): '--'}}</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label>Id Front :</label><span>
                                                                        {{verifyData?.Drug?.drugdescription[0].id_front ?
                                                                        (verifyData?.Drug?.drugdescription[0].id_front | uppercase) : '--'}}</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label>Id Back :</label><span>
                                                                        {{verifyData?.Drug?.drugdescription[0].id_back ?
                                                                        (verifyData?.Drug?.drugdescription[0].id_back | uppercase) : '--'}}</span>
                                                                </div>
                                                                <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                                                                                        <label>Drug Image :</label>
                                                                                        <img class="tablate--preview  width_150px" src="{{ imgURL }}"  />
                                                                                    </div> -->
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row">
                                                    <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label class="custom--label padding-0 d-inline">Drug Image/Drug Description</label>
                                                        <span *ngIf="!emptyImage" class="d-inline info_display left_13rem margin_left_0_5rem"
                                                            [ngbTooltip]="instructToolTip" tooltipClass="search-tooltip" triggers="mouseover:mouseleave"
                                                            [autoClose]="false" placement="left">𝒊</span>
                                                    </div>
                                                    <div class="col-lg-12 pl-0" [ngClass]="">
                                                        <!-- <img class="width_150px height_76px" src="{{ imgURL }}"/> -->
                                                        <img *ngIf="!emptyImage" class="width_150px height_76px" src="{{ imgURL }}" />
                                                        <div
                                                            *ngIf="emptyImage && !(verifyData?.Drug?.drugdescription && verifyData?.Drug?.drugdescription.length)">
                                                            No Drug description available
                                                        </div>
                                                        <div class="row"
                                                            *ngIf="emptyImage && (verifyData?.Drug?.drugdescription && verifyData?.Drug?.drugdescription?.length)">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-6 pr-0">
                                                                        <label>Shape / Coating: </label>
                                                                    </div>
                                                                    <div class="col-lg-6 label--data mb-0 p-0 right_1rem">
                                                                        <span
                                                                            title="{{verifyData?.Drug?.drugdescription?.length && verifyData?.Drug?.drugdescription[0].shape ? (verifyData?.Drug?.drugdescription[0].shape  | uppercase): '--'}} / {{verifyData?.Drug?.drugdescription?.length && verifyData?.Drug?.drugdescription[0].coatingname ? (verifyData?.Drug?.drugdescription[0].coatingname  | uppercase) : '--'}}">
                                                                            {{verifyData?.Drug?.drugdescription?.length &&
                                                                            verifyData?.Drug?.drugdescription[0].shape ?
                                                                            (verifyData?.Drug?.drugdescription[0].shape | uppercase): '--'}} /
                                                                            {{verifyData?.Drug?.drugdescription?.length &&
                                                                            verifyData?.Drug?.drugdescription[0].coatingname ?
                                                                            (verifyData?.Drug?.drugdescription[0].coatingname | uppercase) :
                                                                            '--'}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-6 pr-0">
                                                                        <label>Color Front / Back: </label>
                                                                    </div>
                                                                    <div class="col-lg-6 label--data mb-0 p-0 right_1rem">
                                                                        <span
                                                                            title="{{verifyData?.Drug?.drugdescription?.length && verifyData?.Drug?.drugdescription[0].color_frnt ? (verifyData?.Drug?.drugdescription[0].color_frnt  | uppercase) : '--'}} / {{verifyData?.Drug?.drugdescription?.length && verifyData?.Drug?.drugdescription[0].color_back ? (verifyData?.Drug?.drugdescription[0].color_back  | uppercase): '--'}}">
                                                                            {{verifyData?.Drug?.drugdescription?.length &&
                                                                            verifyData?.Drug?.drugdescription[0].color_frnt ?
                                                                            (verifyData?.Drug?.drugdescription[0].color_frnt | uppercase) : '--'}} /
                                                                            {{verifyData?.Drug?.drugdescription?.length &&
                                                                            verifyData?.Drug?.drugdescription[0].color_back ?
                                                                            (verifyData?.Drug?.drugdescription[0].color_back | uppercase): '--'}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-6 pr-0">
                                                                        <label>Id Front / Back: </label>
                                                                    </div>
                                                                    <div class="col-lg-6 label--data mb-0 p-0 right_1rem">
                                                                        <span
                                                                            title="{{verifyData?.Drug?.drugdescription?.length && verifyData?.Drug?.drugdescription[0].id_front ? (verifyData?.Drug?.drugdescription[0].id_front  | uppercase) : '--'}} / {{verifyData?.Drug?.drugdescription?.length && verifyData?.Drug?.drugdescription[0].id_back ? (verifyData?.Drug?.drugdescription[0].id_back  | uppercase) : '--'}}">
                                                                            {{verifyData?.Drug?.drugdescription?.length &&
                                                                            verifyData?.Drug?.drugdescription[0].id_front ?
                                                                            (verifyData?.Drug?.drugdescription[0].id_front | uppercase) : '--'}} /
                                                                            {{verifyData?.Drug?.drugdescription?.length &&
                                                                            verifyData?.Drug?.drugdescription[0].id_back ?
                                                                            (verifyData?.Drug?.drugdescription[0].id_back | uppercase) : '--'}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="exprx--common-block erx--body insurance--body mt-2">
                            <div class="eprx--block__header">
                                <div><input type="checkbox" class="form-check-input mt-2" id="rxChck"></div>
                                <div class="eprx--header__heading pl-4">
                                    <span class="header--sml__htex width_29px" > Rx : </span>
                                    <span class="header--sml__text w-84" >
                                        <span class="header--sml__lstName">
                                            {{verifyData?.Prescription?.PrescNum}}-{{+verifyData?.PrescReFill?.ReFillNum}}{{fillNumber}}
                                        </span>
                                    </span>
                                    <ul class="pull-right eprx--header__icons mr-3">
                                        <!-- <li> <button class="hotkey_primary hotkey_primary--Override" (click)="openRxAdmin()">Admin Rx</button>
                                        </li> -->
                                        <!-- <li>
                                            <input type="checkbox" class="form-check-input mt-2 ml-2" id="rxChck">
                                        </li> -->
                                        <li>
                                            <a (click)="printLabel()">
                                                <i class="far fa-print icon-fon-s margin_top_2px" title="Print Label" ></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <i class="far fa-edit icon-fon-s margin_top_2px"  (click)="editRxScreen()" appShortcutKey [CtrlKey]="'r'" title="Edit Rx (Ctrl+R)"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a (click)="openNotesModel('rx')">
                                                <i   title="Rx Notes"  class="fa-kit fa-notes-light"></i>
                                            </a>
                                        </li>
            
                                    </ul>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <!-- <div class="row water_mark" [ngStyle]="{'color': StatusId?.bgColor}">
                                    {{StatusId?.status}}
                                </div> -->
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <label>Fill Date</label>
                                                <div class="label--data">{{verifyData?.PrescReFill?.FillDtTm ?
                                                    (verifyData?.PrescReFill?.FillDtTm | localTime |
                                                    date:'MM/dd/yyyy') : '--'}}</div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <label>Order Date</label>
                                                <div class="label--data">{{verifyData?.PrescReFill?.OrderDtTm ?
                                                    (verifyData?.PrescReFill?.OrderDtTm | localTime | date:'MM/dd/yyyy') : '--'}}</div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <label>DAW</label>
                                                <div class="label--data">{{dawValue ? dawValue : '--'}}</div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <label>Auth#
                                                    <span *ngIf="verifyData && verifyData?.XmlFileId && verifyData?.Prescription && detailsInfo">
                                                        <i
                                                            [ngClass]="(+verifyData?.Prescription?.ReFillNum !== +detailsInfo?.RefillNum) ?
                                                            'far fa-times-circle text-danger fa-lg' : 'far fa-check-circle text-success fa-lg'">
                                                        </i>
                                                    </span>
                                                </label>
                                              <div class="label--data" [title]="'AuthRefNo/RefillNo/FillNo'">
                                                    {{verifyData?.Prescription.ReFillNum}}
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <label>Qty. Ordered
                                                    <span
                                                        *ngIf="verifyData && verifyData?.XmlFileId && verifyData?.PrescReFill && detailsInfo?.RxQty"
                                                        class="padding__left-1">
                                                    </span>
                                                </label>
    
                                                <div class="label--data">{{verifyData?.PrescReFill?.OrdQty ?
                                                    (verifyData?.PrescReFill?.OrdQty | number : '1.3-3') : '--'}}</div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 pr-0">
                                                <label>Qty. Dispensed
                                                    <span
                                                        *ngIf="verifyData && verifyData?.XmlFileId && verifyData?.PrescReFill && detailsInfo?.RxQty">
                                                        <i
                                                            [ngClass]="(verifyData?.PrescReFill?.DispQty)!=(detailsInfo?.RxQty) ? 'far fa-times-circle text-danger fa-lg' : 'far fa-check-circle text-success fa-lg'"></i>
                                                    </span>
                                                </label>
    
                                                <div class="label--data">{{verifyData?.PrescReFill?.DispQty ?
                                                    (verifyData?.PrescReFill?.DispQty | number : '1.3-3') : '--'}}</div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <label>Ordered Days
                                                    <span
                                                        *ngIf="verifyData && verifyData?.XmlFileId && verifyData?.PrescReFill && detailsInfo?.RxDays"
                                                        class="padding__left-1">
                                                    </span>
                                                </label>
    
                                                <div class="label--data">{{verifyData?.PrescReFill?.OrderDays ?
                                                    verifyData?.PrescReFill?.OrderDays : '--'}}
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <label>Supply Days
                                                    <span
                                                        *ngIf="verifyData && verifyData?.XmlFileId && verifyData?.PrescReFill && detailsInfo?.RxDays">
                                                        <i
                                                           [ngClass]="(verifyData?.PrescReFill?.SupplyDays)!==(detailsInfo?.RxDays ? +detailsInfo.RxDays : 0) ? 'far fa-times-circle text-danger fa-lg' : 'far fa-check-circle text-success fa-lg'"></i>
                                                    </span>
                                                </label>
    
                                                <div class="label--data">{{verifyData?.PrescReFill?.SupplyDays ?
                                                    verifyData?.PrescReFill?.SupplyDays : '--'}}
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <label>Rx Serial#</label>
                                                <div class="label--data">{{verifyData?.Prescription?.PrescSN ? verifyData?.Prescription?.PrescSN : "-"}}
                                                </div>
                                            </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <label class="align-label">Rx Notes</label>
                                                <div class="label--data">
                                                    <ul  class="rxNotes_label_display">
                                                        <li *ngFor="let x of verifyData?.rxnoteslog" >
                                                            <span [innerHTML]="x?.xNote ? x?.xNote : x?.Notes"></span>
                                                        </li>
                                                    </ul>
                                                </div>
                                        </div>
                                    </div>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mt-2">
                                                <label class="align-label">Sig</label>
                                                <div class="label--data background_antiquewhite border_radius_6px" title=" {{verifyData?.SigCodes?.Description}}">
                                                    {{verifyData?.SigCodes?.Description}}
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mt-2" *ngIf="verifyData?.SigCodes?.AlternateLangDescription && verifyData?.Patient?.langname?.toLowerCase() !== 'english'">
                                                <label class="align-label">Alternate Sig</label>
                                                <div class="label--data background_antiquewhite border_radius_6px" title=" {{verifyData?.SigCodes?.AlternateLangDescription}}">
                                                    {{verifyData?.SigCodes?.AlternateLangDescription}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="exprx--common-block erx--body insurance--body mt-2">
                                <div class="eprx--block__header">
                                    <div class="eprx--header__heading">
                                        <div class="col-sm-12 padding-0">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <span>Billing Information</span>
                                                </div>
                                                <div class="col-md-2 text-right">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="eprx--block__content">
                                    <div class="col-sm-12 padding-0">
                                       
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <label>Ins. Type</label>
                                                <div class="label--data">{{verifyData?.insuranceInfo?.insuPlan?.PlanCode ?
                                                    verifyData?.insuranceInfo?.insuPlan?.PlanCode : '--'}}</div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <label>Status</label>
                                                <div class="label--data larger-label-data border_radius_6px" [ngStyle]="{'backgroundColor':StatusId?.bgColor, 
                                                'color': StatusId?.colorText}">
                                                    {{StatusId?.status}}
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <label>Billed</label>
                                                <div class="label--data">
                                                    {{verifyData?.PrescReFill?.BalDue ? '$ '+(verifyData?.PrescReFill?.BalDue  | number : '1.2-2') : '--'}}
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <label class="align-label">Paid</label>
                                                <div class="label--data background_orange border_radius_6px">
                                                    {{(verifyData?.PaidPrice || verifyData?.PaidPrice === 0) ? '$ '+(verifyData?.PaidPrice | number : '1.2-2') : '--'}}
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <label>Profit Margin</label>
                                                <div class="label--data">
                                                    {{(verifyData?.ProfitMargin || verifyData?.ProfitMargin === 0) ? (verifyData?.ProfitMargin | number : '1.2-2') + "%" : '--'}}
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <label>Copay</label>
                                                <div class="label--data">{{(verifyData?.PrescReFill?.CoPay || verifyData?.PrescReFill?.CoPay === 0) ?
                                                        '$ '+ (verifyData?.PrescReFill?.CoPay | number : '1.2-2') : '--'}}</div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <label>Cost</label>
                                                <div class="label--data">{{verifyData?.Cost ?
                                                    '$ '+(verifyData?.Cost | number : '1.2-2') : '--'}}</div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <label class="align-label">Profit</label>
                
                                                <div class="label--data larger-label-data border_radius_6px" [ngStyle]=" verifyData?.Profit < 0 ? {'background-color': 'red', 'color': 'white'} :
                                                {'background-color': 'green', 'color': 'white'}">
                                                    {{(verifyData?.Profit || verifyData?.Profit === 0) ? '$ '+(verifyData?.Profit | number : '1.2-2') : '--'}}
                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-2 padding--left_refilldue">
                            <div class="row mt-3">
                                <div class="col-12">
                                        <button
                                        id="Refilldue"
                                        class="hotkey_primary hotkey_primary--Override-rph--verify" (click)="openModalForAll(1,'Refills Due',refillsDueCnt)"
                                        title="Refills Due" appShortcutKey  [CtrlKey]="'R'"><span class="rph-btn">R</span><br>
                                        Refills Due<div class="count_shown-rph--verify">{{refillsDueCnt}}</div></button>
                                </div>
                                <div class="col-12 mt-4">
                                        <button id="ExpiredRef" class="hotkey_primary hotkey_primary--Override-rph--verify"
                                        (click)="openModalForAll(2,'Expired Refills',exprdReflsCnt)"
                                        title="Expired Refills"
                                            appShortcutKey [CtrlKey]="'E'"><span class="rph-btn">E</span>
                                            Expired Refills<div class="count_shown-rph--verify">{{exprdReflsCnt}}</div></button>
                                </div>
                                <div class="col-12 mt-4">
                                        <button
                                        id="AwaitingPickup"
                                        class="hotkey_primary hotkey_primary--Override-rph--verify"
                                        (click)="openModalForAll(3,'Awaiting Pickup',awaitingPickUpCnt)"
                                        title="AwaitingPickup" appShortcutKey [CtrlKey]="'A'"><span class="rph-btn">A</span>
                                        Awaiting Pickup<div class="count_shown-rph--verify">{{awaitingPickUpCnt}}</div></button>
                                </div>
                                <div class="col-12 mt-4">
                                        <button
                                    id="AwaitingRphVerf"
                                    class="hotkey_primary hotkey_primary--Override-rph--verify"
                                    (click)="openModalForAll(4,'Awaiting Rph Verification',awtngPhrmVerftcnCnt)"
                                    title="AwaitingRphVerf" appShortcutKey [CtrlKey]="'H'"><span class="rph-btn">H</span>
                                    Awaiting Rph Verf<div class="count_shown-rph--verify">{{awtngPhrmVerftcnCnt}}</div></button>
                                </div>
                                <div class="col-12 mt-4">
                                        <button
                                        id="FiledRxs"
                                        class="hotkey_primary hotkey_primary--Override-rph--verify"
                                        (click)="openModalForAll(5,'Deferred Rxs',filedRxsCnt)"
                                        title="Deferred Rxs" appShortcutKey [CtrlKey]="'D'"><span class="rph-btn">D</span><br>
                                        Deferred Rxs<div class="count_shown-rph--verify">{{filedRxsCnt}}</div></button>
                                </div>
                                <div class="col-12 mt-4">
                                        <button id="NewERxs" class="hotkey_primary hotkey_primary--Override-rph--verify padding_top_1rem"  title="eRxs"
                                        appShortcutKey (click)="openModalForAll(6,'eRxs',neweRxsCnt)"
                                        [CtrlKey]="'s'"><span class="rph-btn">S</span><br>
                                        eRxs<div class="count_shown-rph--verify">{{neweRxsCnt}}</div></button>
        
                                </div>
                            </div>
                        </div>
                        <div class="col-10 pr-0">
                            <div class="exprx--common-block">
                                <div class="eprx--block__header">
                                    <div class="row">
                                        <div class="col-md-11">
                                            <div class="eprx--header__heading">
                                                Verification History
                                            </div>
                                        </div>
                                        <div class="text-right pull-right col-1">
                                            <i aria-hidden="true" class="far fa-angle-double-right text-white add--icon"
                                                *ngIf="!expandHist" (click)="expandHist=!expandHist"></i>
                                            <i aria-hidden="true" class="far fa-angle-double-down text-white add--icon"
                                                *ngIf="expandHist" (click)="expandHist=!expandHist"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="eprx--block__content" *ngIf="expandHist">
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <label class="font-weight-bolder font_size_large">Rx Verf: </label>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-lg-2">
                                            <label>Verified By</label>
                                            <div class="label--data">
                                                {{(rxData?.length > 0 ) ? rxData[rxData?.length - 1].UserName : "--"}}</div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <label>Verf. DtTm.</label>
                                            <div class="label--data">
                                                {{(rxData?.length > 0 ) ? (rxData[rxData?.length - 1].VerifDtTm | localTime | date : 'MM/dd/yyyy hh:mm a'): "--"}}
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-lg-3 padding--left__7">
                                            <label>Verf Status</label>
                                            <div class="label--data">
                                                {{rxData?.length > 0 ?
                                                    (["r", "R", "R ", "r "].indexOf(rxData
                                                    [rxData?.length-1].Status) > -1) ? (!rxData
                                                    [rxData?.length-1].IsReverified ? "Verified" : "Re-Verified"):
                                                    rxData[rxData?.length - 1].Status === 'RV' ? "Re-Verify" : "--" : "--"}}
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-lg-2 padding--left__7">
                                            <label>Verf Hist</label>
                                            <div class="label--data">
                                                <i class="far fa-eye actions blue-eye-icon" (click)="openHistoryPopUp('rx')"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <label class="font-weight-bolder font_size_large">DPV Verf: </label>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                            <label>Verified By</label>
                                            <div class="label--data">{{drugpickData && drugpickData.length > 0? drugpickData[drugpickData.length - 1].UserName: "--"}}</div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                                            <label>Verf DtTm.</label>
                                            <div class="label--data"> {{drugpickData && drugpickData.length > 0? (drugpickData[drugpickData.length - 1].VerifDtTm | localTime | date : 'MM/dd/yyyy hh:mm a'): "--"}}
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-1"></div> -->
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 padding--left__7">
                                            <label>Verf Status</label>
                                            <div class="label--data">{{drugPickVerfStatus ? drugPickVerfStatus : "--"}}
                                                </div>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-lg-2 padding--left__7">
                                            <label>Verf Hist</label>
                                            <div class="label--data">
                                                <i class="far fa-eye actions blue-eye-icon" (click)="openDPVVerfHist()"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <label class="font-weight-bolder font_size_large" >RPH Verf: </label>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                            <label>Verified By</label>
                                            <div class="label--data">{{(rphInfoData && rphInfoData?.length > 0 ) ? rphInfoData[rphInfoData.length - 1].UserName : "--"}}
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                                            <label>Verf DtTm.</label>
                                            <div class="label--data"> {{(rphInfoData && rphInfoData?.length > 0 ) ?
                                                 (rphInfoData[rphInfoData?.length - 1]?.VerifDtTm | localTime | date : 'MM/dd/yyyy hh:mm a'): "--"}}
                                                </div>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-lg-3 padding--left__7">
                                            <label>Verf Status</label>
                                            <div class="label--data">
                                                {{rphInfoData?.length > 0 ?
                                                    ((["V", "V "].indexOf(rphInfoData
                                                    [rphInfoData?.length-1].Status) > -1) ? (!rphInfoData
                                                    [rphInfoData?.length-1].IsReverified ? "Verified" : "Re-Verified") :
                                                    rphInfoData[rphInfoData?.length - 1].Status === 'RV' ? "Re-Verify" : "Not Verified") : "--"}}
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-4"></div> -->
                                        <div class="col-md-2 col-sm-2 col-lg-2 padding--left__7">
                                            <label>Verf Hist</label>
                                            <div class="label--data">
                                                <i class="far fa-eye actions blue-eye-icon" (click)="openHistoryPopUp('rph')"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <label class="font-weight-bolder font_size_large" >Pickup/Del: </label>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 ">
                                            <label>Pickup Date</label>
                                            <div class="label--data"> {{verifyData?.PickupRx?.ExpDtTm ? (verifyData?.PickupRx?.ExpDtTm | localTime |
                                                            date:'MM/dd/yyyy') : '--'}}</div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <label>Delivery Status</label>
                                            <div class="label--data">
                                                {{verifyData?.rxDelivery ? (verifyData?.rxDelivery.IsShipped ? "Ship" : "Deliver"): 'NOT SPECIFIED'}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2" *ngIf="grpVerfRxs?.length > 1">
        <span *ngIf="!isRxVerification">
            <div class="eprx--block__content batchrefill batchVerf-inprogress">
                <b>In progress Rxs</b>
                <ul class="refill-inProgress">
                    <li *ngFor="let trnsRx of grpVerfRxs; let i = index"
                    [ngClass]="trnsRx.trim() === (verifyData?.Prescription?.PrescNum + '-' + verifyData?.PrescReFill?.ReFillNum +( fillNumber))
                            ? 'refill-prog--li inProgress--refill'
                            : 'refill-prog--li notinProgress--refill'"
                    >
                        {{trnsRx}}
                    </li>
                </ul>
            </div>
        </span>
    </div>
</div>

<app-rph-followup *ngIf="IsFollowup" [RxInfo]="rxInfo" (IsPopUpClosed)="closeFollowupModal($event)"></app-rph-followup>
<app-rx-notes *ngIf="IsNotes" [RxInfo]="rxInfo" (IsPopUpClosed)="CloseNotesModal($event)"></app-rx-notes>
<app-ins-trans-result *ngIf="IsTransResult" [RxInfo]="rxInfo" (IsPopUpClosed)="CloseTransModal($event)"></app-ins-trans-result>
<app-rph-verify-hist *ngIf="IsHistory" [RxInfo]="rxInfo"  [IsRxVerification]="isRxVerificationHist" (IsPopUpClosed)="closeHist($event)"></app-rph-verify-hist>



<ng-template #DRUGALLERGIES let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click')">
        <h4 header> Patient Allergy </h4>
        <span body>
            <span [innerHTML]="drugAllergyData"></span>
         </span>
        <button footer ngbAutofocus class="hotkey_primary" (click)="c('Cross click')" appShortcutKey InputKey="o">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #DRUGDISEASECONTRIND let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click')">
        <h4 header> Drug-Patient Disease Contraindication </h4>
        <span body>
            <div class="col-xs-12">
                <div class="col-xs-12"> Drug Disease Contraindication predicted for the selected drug
                </div>
                <div class="col-xs-12">
                    <div class="col-xs-12" *ngFor="let key of diseaseHeading">
                    <label>{{key | uppercase}}</label>
                    <div class="col-xs-12">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col"> ICD10 Code </th>
                                    <th scope="col"> ICD10 Name </th>
                                    <th scope="col"> Min Age Year </th>
                                    <th scope="col"> Max Age Year </th>
                                    <th scope="col"> Gender </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let drugDes of drugDeseContrd?.DrillDownData[key]">
                                    <th scope="row"> {{drugDes?.ICD_CODE}}</th>
                                    <td>{{drugDes?.CHILDDISEASENAME}}</td>
                                    <td>{{drugDes?.MINAGE_YEARS}}</td>
                                    <td>{{drugDes?.MAXAGE_YEARS}}</td>
                                    <td>{{drugDes?.GENDER}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                    <div class="col-xs-12">
                        <label> CONTRADICTION TEXT FROM {{drugInfoFromEditDrug?.Drug.Id?.Name}}'S MONOGRAPH </label>
                        <div class="col-xs-12">
                            <div class="label--data">{{drugDeseContrd?.MonographText}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <span footer>
            <button class="hotkey_primary" ngbAutofocus (click)="c('Cross click')" appShortcutKey InputKey="o">
                <b>O</b> Ok
            </button>
            <button class="hotkey_primary" (click)="c('Cross click')" appShortcutKey InputKey="c">
                <b>C</b> Cancel
            </button>
        </span>
    </app-custom-modal>
</ng-template>

<ng-template #DRUGTODRUGINTERACTION let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="checkAllDDIPopClosed()">
        <h4 header> Drug-Drug Interaction Information </h4>
        <span body *ngIf="drugToDrugInteractions">
            <div class="row">
                <div class="col">
                    <label>Drug1: </label>
                    <div class="label--data">{{verifyData?.Drug?.drugname}}</div>
                </div>
                <div class="col">
                    <label>Drug2: </label>
                    <div class="label--data">{{drugToDrugInteractions?.DRUGNAME}}</div>
                </div>
                <div class="col">
                    <label>Rx No: </label>
                    <div class="label--data"> {{drugToDrugInteractions?.RXNO}} </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>Class1: </label>
                    <div class="label--data"> {{drugToDrugInteractions?.DRUGINTERACTCLASSNAME1}} </div>
                </div>
                <div class="col">
                    <label>Class2: </label>
                    <div class="label--data">{{drugToDrugInteractions?.DRUGINTERACTCLASSNAME2}}</div>
                </div>
                <div class="col"></div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="col">
                        <label>Effects: </label>
                        <div class="label--data">{{drugToDrugInteractions?.EFFECTS}} </div>
                    </div>
                    <div class="col">
                        <label>Mechanism: </label>
                        <div class="label--data">{{drugToDrugInteractions?.MECHANISM}}</div>
                    </div>
                    <div class="col">
                        <label>Management: </label>
                        <div class="label--data">{{drugToDrugInteractions?.MANAGEMENT}}</div>
                    </div>
                </div>
            </div>
            <div class="col">
                <label>
                    Onset
                </label>
            </div>
            <div class="row">
                <div class="col">
                    <div class="col">
                        <label>Severity -
                            <span *ngIf="drugToDrugInteractions?.SEVERITY === '3 - Moderate'" class="bg-light-red">
                                {{drugToDrugInteractions?.SEVERITY}}
                            </span>
                            <span *ngIf="drugToDrugInteractions?.SEVERITY === '2 - Major'" class="bg-hard-red">
                                {{drugToDrugInteractions?.SEVERITY}}
                            </span>
                        </label>
                        <div class="label--data"> {{drugToDrugInteractions?.SEVERITYDESCRIPTION}} </div>
                    </div>
                    <div class="col">
                        <label> Documentation: {{drugToDrugInteractions?.DOCUM}}</label>
                        <div class="label--data">{{drugToDrugInteractions?.DOCUMENTATIONDESCRIPTION}}</div>
                    </div>
                    <div class="col">
                        <label> Customer notes: </label>
                        <div class="label--data">{{drugToDrugInteractions?.CONSUMERNOTES}}</div>
                    </div>
                    <div class="col">
                        <label> Refrence: </label>
                        <div class="label--data">{{drugToDrugInteractions?.REFERENCE}}</div>
                    </div>
                </div>
            </div>
        </span>
        <span body *ngIf="!drugToDrugInteractions">
            No DDI detected
        </span>
        <button footer autofocus class="hotkey_primary" ngbAutofocus (click)="checkAllDDIPopClosed()" appShortcutKey
            InputKey="o">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #DUPLICATEDRUG let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click')">
        <h4 header> Duplicate Drug Therapy </h4>
        <span body
            *ngIf="!(drugInteractions && drugInteractions?.DuplicateDrugs && drugInteractions?.DuplicateDrugs?.length > 0)">
            No Duplicate drug therapy detected
        </span>
        <span body
            *ngIf="drugInteractions && drugInteractions?.DuplicateDrugs && drugInteractions?.DuplicateDrugs?.length > 0">
            <div class="border--bottom" *ngFor="let duplDrugTherapyInfo of drugInteractions.DuplicateDrugs">
                <div class="row">
                    <div class="col">
                        <div><label>Drug Name:</label>&nbsp;{{ duplDrugTherapyInfo?.DrugName2 | uppercase }}</div>
                        <div class="label--data"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Filled Date: </label>
                        <div class="label--data">{{duplDrugTherapyInfo?.Filldate}}</div>
                    </div>
                    <div class="col">
                        <label>Quantity: </label>
                        <div class="label--data">
                            {{ duplDrugTherapyInfo?.Quantity ? (duplDrugTherapyInfo?.Quantity | number : '1.3-3'):''}}
                        </div>
                    </div>
                    <div class="col">
                        <label>Days: </label>
                        <div class="label--data">{{duplDrugTherapyInfo?.Days}}</div>
                    </div>
                    <div class="col">
                        <label>Rx#: </label>
                        <div class="label--data"> {{duplDrugTherapyInfo?.rxNumber2}} </div>
                    </div>
                </div>
            </div>
        </span>
        <button footer autofocus class="hotkey_primary" (click)="c('Cross click')" appShortcutKey
            InputKey="o"><b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #DRUGPREGNENCY let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click')">
        <h4 header> Pregnancy and Lactation Information </h4>
        <span body>
            <div class="col-xs-12">
                <label> Pregnancy Information </label>
                <div class="col-xs-12">
                    <table class="table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> TRIMESTER </th>
                                <th scope="col"> PREGNANCY RATING CODE </th>
                                <th scope="col"> RATING DESCRIPTION </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pregnancyInfo of drugPregInfo?.dtPregnancyInformation">
                                <th scope="row"> {{pregnancyInfo?.TRIMESTER}}</th>
                                <td>{{pregnancyInfo?.PREGNANCYRATINGCODE}}</td>
                                <td>{{pregnancyInfo?.RATINGDESCRIPTION}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <label> LACTATION Information </label>
                <div class="col-xs-12">
                    <table class="table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> IS SAFE FOR CHILD BEARING AGE </th>
                                <th scope="col"> IS SAFE FOR LABOUR AND DELIVERY </th>
                                <th scope="col"> LACTATION RATING </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lactationInfo of drugPregInfo?.dtLactationInformation">
                                <th scope="row"> {{lactationInfo['SAFE FOR CHILD BEARING AGE']}}</th>
                                <td>{{lactationInfo['SAFE FOR LABOR AND DELIVERY']}}</td>
                                <td>{{lactationInfo['LACTATION RATING']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <label> ALTER LACTATION DRUGS ( LACTATION RATING= 'AVOID' OR LACTATION RATING="CAUTION") </label>
                <div class="col-xs-12">
                    <table class="table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> ALTERNATE PRODUCTION NAME </th>
                                <th scope="col"> ALTERNATE NDCB </th>
                                <th scope="col"> LACTATION RATING </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lactationInfo of drugPregInfo?.dtLactationInformation">
                                <th scope="row"> {{lactationInfo['SAFE FOR CHILD BEARING AGE']}}</th>
                                <td>{{lactationInfo['SAFE FOR LABOR AND DELIVERY']}}</td>
                                <td>{{lactationInfo['LACTATION RATING']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </span>
        <button footer autofocus class="hotkey_primary" (click)="c('Cross click')" appShortcutKey InputKey="o">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #ADVERSEDRUG let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click');getLifeStyle()">
        <h4 header> Adverse Drug Reactions </h4>
        <span body>
            <div class="col-xs-12">
                <div class="col-xs-12"> Adverse Drug Reactions Detected for the Drug
                    <span class="font-weight-bold">
                    ({{ (verifyData?.Drug?.drugname) ? (verifyData?.Drug?.drugname) : ''}}
                    {{verifyData?.Drug?.strength ? ' '+verifyData?.Drug?.strength: ''}}
                    {{verifyData?.Drug?.drugformname ? ' '+verifyData?.Drug?.drugformname: ''}})
                    </span>
                </div>
                <div class="col-xs-12" >
                        <div class="col-xs-12 max-height_40vh overflow_scroll" >
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col"> Severity </th>
                                            <th scope="col"> OnSet </th>
                                            <th scope="col"> Disease Name </th>
                                            <th scope="col"> Incidence </th>
                                            <th scope="col"> Is Blackbox? </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let adv of drugInteractions.AdverseReaction">
                                            <th scope="row"> {{adv?.Severity}}</th>
                                            <td>{{adv?.Onset}}</td>
                                            <td>{{adv?.DISEASENAME | titlecase}}</td>
                                            <td>{{adv?.INCIDENCE}}</td>
                                            <td>{{adv?.ISBLACKBOX.trim() === 'True' ? 'Yes' : 'No'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                </div>
            </div>
            <div class="col-xs-12">
                <label> Adverse Drug Reaction text from ({{(verifyData?.Drug?.drugname)?verifyData?.Drug?.drugname
                    : ''}}
                    {{verifyData?.Drug?.strength ? ' '+verifyData?.Drug?.strength: ''}}
                    {{verifyData?.Drug?.drugformname ? ' '+verifyData?.Drug?.drugformname: ''}})'S monograph </label>
                <div class="col-xs-12">
                    <div class="label--data overflow_scroll height_25vh">{{adverseDataMonograph}}</div>
                </div>
            </div>
        </span>
        <span footer>
            <button class="hotkey_primary" autofocus appShortcutKey InputKey="o" (click)="c('Cross click')">
                <b>O</b> OK
            </button>
        </span>
    </app-custom-modal>
</ng-template>

<ng-template #LIFESTYLE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (CloseModal)="c('Cross click');checkLifeStyleComplete()">
        <h4 header>
            <div class="text-center">
                Drug-Lifestyle Interaction Detected  ({{currentLifeStyleIndex + 1}} of {{drugInteractions?.LifeStyle?.length}})
            </div>
        </h4>
        <span body>
            <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <label > Drug:  </label>
                        {{(verifyData?.Drug?.drugname) ? (verifyData?.Drug?.drugname) : ''}}
                        {{verifyData?.Drug?.strength ? ' '+verifyData?.Drug?.strength: ''}}
                        {{verifyData?.Drug?.drugformname ? ' '+verifyData?.Drug?.drugformname: ''}}
                    </div>
                    <div class="col-6">
                        <label> Lifestyle Name:  </label>  {{ drugLifeStyle?.LifestyleName}}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <label> Mechanism  </label>
                <div class="col-12">
                    <div class="label--data">{{drugLifeStyle?.ProfessionalNotes}}</div>
                </div>
            </div>
            <div class="col-12" >
                <label> Management </label>
                <div class="col-12">
                    <div class="label--data">{{drugLifeStyle?.CLINICALMANAGEMENTTEXT}}</div>
                </div>
            </div>
            <div class="col-12">
                    <label> Severity : {{drugLifeStyle?.SEVERITYTEXT}}</label>
                    <div class="col-12">
                        <div class="label--data">{{drugLifeStyle?.SEVERITYDESCRIPTION}}</div>
                    </div>
                </div>
                <div class="col-12">
                    <label> Documentation : {{drugLifeStyle?.DOCUMENTATIONTEXT}}</label>
                    <div class="col-12">
                        <div class="label--data">{{drugLifeStyle?.DocumentationDescription}}</div>
                    </div>
                </div>
                <div class="col-12">
                    <label> Consumer Notes </label>
                    <div class="col-12">
                        <div class="label--data">{{drugLifeStyle?.ConsumerNotes}} <br> {{drugLifeStyle?.ConsumerNotes1}}</div>
                    </div>
                </div>
        </span>
        <span footer>
            <button class="hotkey_primary" autofocus appShortcutKey InputKey="o" (click)="c('Cross click')" (click)="checkLifeStyleComplete()">
                <b>O</b> OK
            </button>
        </span>
    </app-custom-modal>
</ng-template>


<ng-template #FILEDORTOUT let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click')">
        <h4 header> Warning!! </h4>
        <span body>
            {{warningMsg}}
        </span>
        <button footer ngbAutofocus class="hotkey_primary" (click)="c('Cross click'); cancelRPH(); resetAdminData()"
            appShortcutKey InputKey="o">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>
<app-notes-log *ngIf="notesType === 'prescriber'" (CloseNotesValue)="openNotesModel(null)"
        [PrescriberInfo]="verifyData?.Prescriber" [OpenAsModal]="true" [NotesFr]="'Prescriber'"
        [NotesInfoFor]="verifyData?.Prescriber?.fullname" >
</app-notes-log>
<app-notes-log *ngIf="notesType === 'patient'" (CloseNotesValue)="openNotesModel(null)"  [NotesFr]="'Patient'"
        [PatientInfo]="verifyData?.Patient" [OpenAsModal]="true"></app-notes-log>
<app-notes-log *ngIf="notesType === 'rx'" [NotesFr]="'RxAnnotation'" (CloseNotesValue)="openNotesModel(null)" [RxInfo]="rxInfo"
    [OpenAsModal]="true">
</app-notes-log>

<app-actions-history *ngIf="openPopUp" [ReviewXDays]="RphAndRxVerificationForPatientReviewOverXdays" [SwitchValue]="switchValue" [RxInfo]="verifyData" [PatientAlrgs]="patientAlle"  [FromRphModal]="true"
[Modalheader]="activeModal" [Insurance]="insurDetails" (IsPopupClosed)="closeActnHstPop()"></app-actions-history>

<app-clinical-assessment *ngIf="openAssessmentForm" [formEntityDetails]="formEntDetails[0]" [precscRefId]="verifyData?.PrescReFill?.Id" [rxNo]="rxNodisplay" [patientName]="patientFullName" [drugName]="drugFullName" [patInfo]="patientInfo" (IsPopupClosed)="recallFormEntity()">
</app-clinical-assessment>

<ng-template #AlreadyVerif let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header> Confirmation </h4>
        <span body>
            Rx#: <span class="font-weight-bold">{{selectedRxId}}</span> Refill#:
             <span class="font-weight-bold">{{selectedRefilId}}</span> Fill#:
             <span class="font-weight-bold">{{fillNoAlreVerf}}</span>
             <div><br></div>
                 This Rx has been {{isRxVerification ? 'Rx' : 'Rph'}} verified  by <span class="font-weight-bold">
                 {{verifiedAlready?.UserName ? (verifiedAlready?.UserName | uppercase) : '--' }}</span> on <span class="font-weight-bold">
            {{verifiedAlready.VerifDtTm ? (verifiedAlready.VerifDtTm | localTime | date:'MM/dd/yyyy'):'--'}}</span>. Would you wish to re-verify?
        </span>
        <button ngbAutofocus footer class="hotkey_primary" (click)="c('Close click')" (click)="checkIsDiscontinue()" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button footer class="hotkey_primary" (click)="loadRxInfo();c('Close click')" appShortcutKey InputKey="n"><b>N</b> NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #RxDiscontinued let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span >Warning</span>
    </div>
    <div class="modal-body">
        <p>Rx#: <span class="font-weight-bold">{{selectedRxId}}-{{selectedRefilId}}{{fillNumberDisc}}
        </span>is Discontinued. If you wish to continue the Rx will no more be marked as discontinued. Would you like to proceed?</p>
    </div>
    <div class="modal-footer" >
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
        (click)="setOrderStatus(); c('Close click')"><b>Y</b>Yes</button>
        <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
        (click)="loadRxInfo();c('Close click')"><b>N</b>No</button>
    </div>
</ng-template>
<ng-template #AuthReqPopup let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Authorization required for drug change</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Close click');closePopUp()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class = "row">
            <div class="col-12 font-size_13" *ngIf="rxType === 'er'">
                <div>
                    To allow drug change on Rx, authorization is required.<br>
                    Please enter credentials for authorization.
                </div>
             </div><br>
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <span>Authorization Credentials</span>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="col-sm-12 padding-0 erx--body">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [RxSelectId]="'EnterInitials'"  [AutoFocus]="true" [LabelText]="'Enter Initials'" [PlaceHolder]="'User Id'" [ControlName]="'UserName'" [FormGroupName]="LoginVerifyFG">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [RxSelectId]="'Password'" [InputType]="'PASSWORD'" [LabelText]="'Enter Password'"
                                    [PlaceHolder]="'Password'" [ControlName]="'Password'" [FormGroupName]="LoginVerifyFG"
                                    [IsRequired]="true" [InputErrors]="Password?.errors" [ErrorDefs]="{ required: 'Required' }"
                                    [FormGroupInvalid]="formGroupInvalid" [Title]="'Password'">
                                    </eprx-input>
                                    <span class="help--text__danger" *ngIf="warnMessage"
                                    >
                                    {{warnMessage}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="verifyAuthPassword()" appShortcutKey [AltKey]="'o'">
                    <span>O</span> Ok
                </button>
                <button class="hotkey_success" (click)="c('Close click');closePopUp()" appShortcutKey [AltKey]="'c'"><span>C</span>CANCEL</button>
            </div>
        </div>
    </div>
</ng-template>
