<div class="exprx--common-block erx--body insurance--body">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">
                <div class="col-sm-12 padding-0">
                    <div class="row">
                        <div class="col-md-10">
                            <span>Prescriber File</span>
                        </div>
                        <div class="col-md-2 text-right">
                            <button *ngIf="!hasAddPrescFilePrivs" id="prescAddBut" (click)="openOveride(null, null, 'Add','POST_Prescriber')" class="hotkey_success hotkey_primary--Override"
                                title="user has no privilege" appShortcutKey [AltKey]="'a'"><span>A</span>ADD</button>
                            <button *ngIf="hasAddPrescFilePrivs" id="prescAddBut" class="hotkey_success hotkey_primary--Override" (click)="addPresc()" title="Add" appShortcutKey [AltKey]="'a'"><span>A</span>ADD</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="eprx--block__content">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <eprx-input [LabelText]="'Last Name'" [RxSelectId]="'LastName'" [PlaceHolder]="'Last Name'" [ControlName]="'prescriberlastname'" [FormGroupName]="PrescSearchFG"
                            [Title]="'Last Name'" [AutoFocus]="true" (TriggerOnEnterValue)="searchPrescriberData()">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input  [LabelText]="'First Name'" [PlaceHolder]="'First Name'" [ControlName]="'prescriberfirstname'"
                            [FormGroupName]="PrescSearchFG" [Title]="'First Name'" (TriggerOnEnterValue)="searchPrescriberData()">
                        </eprx-input>

                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input [LabelText]="'Quick Code'" [PlaceHolder]="'Quick Code'" [ControlName]="'prescriberquickcode'" [FormGroupName]="PrescSearchFG"
                            [Title]="'Quick Code'" (TriggerOnEnterValue)="searchPrescriberData()">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input [LabelText]="'License#'" [MaxLength]="15" [PlaceHolder]="'License#'" [ControlName]="'prescriberlicensenumber'" [FormGroupName]="PrescSearchFG"
                            [Title]="'License#'" (TriggerOnEnterValue)="searchPrescriberData()">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                            <eprx-input [LabelText]="'DEA#'" [PlaceHolder]="'DEA#'" [ControlName]="'prescriberdeanum'" [FormGroupName]="PrescSearchFG"
                                [Title]="'DEA#'" (TriggerOnEnterValue)="searchPrescriberData()">
                            </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                            <eprx-input [LabelText]="'NPI#'" [PlaceHolder]="'NPI#'" [ControlName]="'prescribernpinum'" [FormGroupName]="PrescSearchFG"
                                [Title]="'NPI#'" (TriggerOnEnterValue)="searchPrescriberData()">
                            </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input [LabelText]="'Mobile#'" [PlaceHolder]="'Mobile#'" [ControlName]="'mobile'" [FormGroupName]="PrescSearchFG" [DropSpclChar]="true"
                            [Title]="'Mobile'" (TriggerOnEnterValue)="searchPrescriberData()" [MaskPattern]="'(000)000-0000'" [ShowMask]="true">
                        </eprx-input>
                    </div>


                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                                <eprx-input [LabelText]="'Telephone#'" [PlaceHolder]="'Telephone#'" [ControlName]="'telephone'" [FormGroupName]="PrescSearchFG" [DropSpclChar]="true"
                                    [Title]="'Telephone'" (TriggerOnEnterValue)="searchPrescriberData()" [MaskPattern]="'(000)000-0000'" [ShowMask]="true">
                                </eprx-input>
                            </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input [LabelText]="'Work#'" [PlaceHolder]="'Work#'" [ControlName]="'worknumber'" [FormGroupName]="PrescSearchFG" [DropSpclChar]="true"
                            [Title]="'Work#'" (TriggerOnEnterValue)="searchPrescriberData()" [MaskPattern]="'(000)000-0000'" [ShowMask]="true">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 margin_top_1_3rem">
                            <eprx-radio [ControlName]="'includeActive'" [FormGroupName]="PrescSearchFG"
                              [List]="['Include Inactive', 'Exclude Inactive']" [ValueList]="[false, true]"
                             [IDForList]="['Inc', 'Exc']" [LabelForId]="" (TriggerSelectValue)="searchPrescriberData()">
                         </eprx-radio>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 align-button text-right">
                        <button type="submit" class="hotkey_success pull-right" (click)="searchPrescriberData()" appShortcutKey [AltKey]="'f'">
                            <span>F</span>
                            Search
                        </button>
                        <button type="button" class="hotkey_success pull-right" (keydown.tab)="focusOutFmCan($event)" (click)="clear()" appShortcutKey [AltKey]="'l'">
                            <span>L</span>
                            clear
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="eprx--block__content">
            <wj-flex-grid #SearchPresc [headersVisibility]="'Column'" [itemsSource]="searchPrescList" [isReadOnly]="true" [columnPicker]="''"
                [selectionMode]="'Row'" [allowSorting]="true"  [ngClass]="[IsFromDocuementQueue ? 'wjm-grd-custom-height-39' : 'wjm-grd-custom-height-56', !totalCount ? 'no-data':'']" (keydown)="addEventOnWijimo($event)" (keydown.enter)="editEventOnWijimo($event, SearchPresc)">
                <wj-flex-grid-column (initialized)="init(SearchPresc)" [header]="actvHeader" [binding]="actvHeader" [visible]="true"
                    *ngFor="let actvHeader of actvHeaders; let i = index" title="{{ actvHeader }}" [width]="
                          actvHeader === 'Address'
                                ? '2*'
                                : '1*'
                        ">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                        <div *ngIf="actvHeader === 'Actions' && !isPrescriberSelectable">
                            <i *ngIf="!hasChangPrescFilePrivs" (click)="openOveride(null, item.Id, 'Edit','PUT_Prescriber')" class="far  fa-edit  actions" title="user has no privilege"></i>
                            <i *ngIf="hasChangPrescFilePrivs" class="far  fa-edit  actions" title="Edit" (click)="editPresc(item.Id)"></i>
                            <i *ngIf="!hasDeltPrescFilePrivs" (click)="openOveride(content, item, 'Delete','DELETE_Prescriber')" class="far  fa-edit  actions " class="far fa-trash-alt actions "
                                title="user has no privilege"></i>
                            <i *ngIf="hasDeltPrescFilePrivs" class="far fa-trash-alt  actions danger-actve" title="Delete" (click)="openPopUpModal(content, item)"></i>
                        </div>
                        <div *ngIf="actvHeader === 'Actions' && isPrescriberSelectable">
                            <input type="radio" [id]="row.index" [name]="'PrescriberSelect'" (change)="
                                prescriberSelected( $event?.target?.checked, item, row.index ) " value="{{ row.index }}"  />
                        </div>
                        <div *ngIf="!(actvHeader === 'Actions') && actvHeader !== 'Mobile#' && actvHeader !== 'Telephone#' && actvHeader !=='Work#'">
                            {{  item[actvHeader] }}
                        </div>

                    </ng-template>
                </wj-flex-grid-column>
                <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
            </wj-flex-grid>
            <app-eprx-pagination *ngIf="totalCount" [TotalCount]="totalCount" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [GridName]="'PrescriberSearchGrid'"
                [WijmoName]="flex" (keydown.tab)="focusToFirst($event)" [PageNumber]="pageNumber" [PageSize] = "pageSize"></app-eprx-pagination>
        </div>
    </div>
    <ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Confirmation</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click')">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body edit-patient">
            <div class="row">
                <div class="col">
                    <table class="table table-hover">
                        <tbody>
                            <label>Are you sure you want to delete the selected Prescriber ?
                            </label>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" autofocus appShortcutKey class="hotkey_primary" (click)="c('Close click')" (click)="deletePresc()"
                InputKey="y"><b>Y</b> YES
            </button>
            <button type="button" appShortcutKey InputKey="n" class="hotkey_primary" (click)="c('Close click')">
               <b>N</b> NO
            </button>
        </div>
    </ng-template>


    <app-override-log *ngIf="openOverride" [OverrideEndpoint]="overrideEndpoint" [PrivMask]="privType" [CatId]="categoryId" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>

