import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlecaseForText'
})
export class TitlecaseForTextPipe implements PipeTransform {

  transform(value: any): any {
    let textSplitDisplay = "";
    if(value) {
      const textSplit = value.split(" ");
      if(textSplit && textSplit.length) {
        textSplit.map((item: any) => {
          if(item)
             textSplitDisplay = textSplitDisplay + " " +  this.capitalizeFirstLetter(item);
        });
      }
    }
    return textSplitDisplay;
  }
   capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  

}
