import { RxE, SettingsE } from "./../models/access-privs.enum";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserService, AlertService } from "../services";
import { AccCategoryE, FileMaintenanceE, FileMaintenance1E, PatientFileE, PrescriberFileE, DrugFileE } from "../models";
import { BasePrivGetter } from "./access-privs.guard";

@Injectable()
export class PatientSearchGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.PatientFile, true);
        return reslt;
    }
}

@Injectable()
export class PrescriberSearchGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.PrescriberFile, true);
        return reslt;
    }
}

@Injectable()
export class DrugSearchGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.DrugFile, true);
        return reslt;
    }
}
@Injectable()
export class PriceScheduleGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.PriceScheduleFile, true);
        return reslt;
    }
}
@Injectable()
export class PriceListGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.PriceList, true);
        return reslt;
    }
}
@Injectable()
export class InsuranceFileGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.InsuranceFile, true);
        return reslt;
    }
}

@Injectable()
export class DrugWarnGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.DrugWarning, true);
        return reslt;
    }
}
@Injectable()
export class SigFileGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.SigFile, true);
        return reslt;
    }
}
@Injectable()
export class DiscountSchGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.DiscountScheduleFile, true);
        return reslt;
    }
}
@Injectable()
export class DoseSchGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance1, FileMaintenance1E.DoseScheduleFile, true);
        return reslt;
    }
}
@Injectable()
export class DiagnosisGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth( AccCategoryE.FileMaintenance, FileMaintenanceE.DiagnosisFile, true);
        return reslt;
    }
}
@Injectable()
export class CategoryGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.Category, true);
        return reslt;
    }
}

@Injectable()
export class FacilityGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.FacilityFile, true);
        return reslt;
    }
}

@Injectable()
export class PatientEditGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.PatientFile, PatientFileE.Edit, true);
        return reslt;
    }
}

@Injectable()
export class DrugEditGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.DrugFile, DrugFileE.Edit, true);
        return reslt;
    }
}

@Injectable()
export class PrescriberEditGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.PrescriberFile, PrescriberFileE.Edit, true);
        return reslt;
    }
}

@Injectable()
export class AccountSettingGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Settings, SettingsE.AccountSettings, true);
        return reslt;
    }
}

@Injectable()
export class RxFilledGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx, RxE.RxFilledSummary, true);
        return reslt;
    }
}

@Injectable()
export class PrescriberSpecialitiesGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance1, FileMaintenance1E.PrescriberSpecialities, true);
        return reslt;
    }
}

@Injectable()
export class DrugDescriptionGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance1, FileMaintenance1E.DrugDescription, true);
        return reslt;
    }
}

@Injectable()
export class DeferredReasonGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance1, FileMaintenance1E.DeferredReason, true);
        return reslt;
    }
}

@Injectable()
export class PayorFileMaintenanceGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance1, FileMaintenance1E.PayorFileMaintenance, true);
        return reslt;
    }
}

@Injectable()
export class NcdpGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.NcpdpPayerSet, true);
        return reslt;
    }
}

@Injectable()
export class RxFollowUpGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.RxFollowupTagFile, true);
        return reslt;
    }
}

@Injectable()
export class TransferPharmGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance, FileMaintenanceE.TransferPharmacy, true);
        return reslt;
    }
}
