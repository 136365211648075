import { Component, OnInit, AfterViewInit, OnDestroy} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CommonService, UserService } from "./services";
import { PreviousRouteService } from "./services/previous-route.service";
import { HubService } from "./services/Hub.service";
import { Location } from "@angular/common";
import { CommonStore } from "./store";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { Subject, fromEvent, merge} from "rxjs";
import * as wjcCore from "@grapecity/wijmo";
import { PrivMaskGuard } from "./guards";
import { CommonWarnorconfirmPopupComponent } from "./modules/shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";


@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    elem: any;
    HTTPActivity: boolean;
    
    hubSubscription: any;
    testType: string;
    payload: string;
    // endTime: string;
    endTime: number;
    stopTimeset: boolean;
    timer: number;
    settingsTimer: number;
    wijimoDistKey: any;
    accountSettingsTabPrivs: any = [];
    accountSettings: any  = {};
    checkd: boolean;
    unsubscribe$: Subject<void> = new Subject();

    constructor(
        private translate: TranslateService,
        private routingState: PreviousRouteService,
        private userService: UserService,
        private hubservice: HubService,
        private _commonSer: CommonService,
        private _location: Location,
        private _commonStore: CommonStore,
        private _prvMskGrd: PrivMaskGuard, private _modalService: NgbModal,
        private _router: Router
    ) {
        translate.setDefaultLang("en");
        // this.translate.use("fr"); // we need to get from localstorage
        // routingState.loadRouting();
    }

    ngOnInit() {
        this.hubservice.connect();
        this.hubSubscription = this.hubservice.
        messageHubClass.subscribe(loc => {
        });
        if (typeof localStorage !== 'undefined' && localStorage.getItem("userLogged")) {
                this.getWijimoAndDWTDWTLicenseKey();
        }
    }
    
   
    getWijimoAndDWTDWTLicenseKey() {
        this.userService.getWijimoAndDWTDWTLicenseKey().subscribe(resp => {
            if (resp) {
                this.wijimoDistKey =  resp.find(obj => obj.KeyName === "wijmoDistrKey");
                const Fontawesome =
                 resp.find(obj => obj.KeyName === "FONTAWESOME_NPM_AUTH_TOKEN");
                const DistKeyVal = this._commonSer.DecryptData(this.wijimoDistKey.KeyValue);
                wjcCore.setLicenseKey(
                    DistKeyVal
                );
                const DWTLicenseKey = resp.find(obj => obj.KeyName === "DWTLicenseKey");
                if (DWTLicenseKey) {
                    localStorage.setItem("DWTLicensekey", DWTLicenseKey.KeyValue);
                }
                environment.FONTAWESOME_NPM_AUTH_TOKEN = Fontawesome;
            }
        });
    }
    ngAfterViewInit() {
        let value;
        if (typeof localStorage !== 'undefined' && localStorage.getItem("userLogged") !== null) {
            value = window.location.href;
        } else {
            value = this._location.path();
        }
        // const value = window.location.href;
        if (value && !value.includes("/login")) {
            const scrollEvents$    = fromEvent(window, "mousewheel");
            const KeyEvents$     = fromEvent(window, "keyup");
            const MouseClickEvents$     = fromEvent(window, "click");
            const MouseMoveEvents$ = fromEvent(window, "mousemove");
            const allEvents$ = merge(
            scrollEvents$,
            KeyEvents$,
            MouseClickEvents$,
            MouseMoveEvents$
            );
            allEvents$.pipe(takeUntil(this.unsubscribe$))
            .subscribe((event) => {
                // const value = this._location.path();
                const value = !localStorage.getItem("userLogged")? this._location.path() : window.location.href;
                const noEvent = localStorage.getItem("NOEVENT") ? this._commonSer.DecryptData(localStorage.getItem("NOEVENT")) : null;
                const sessionExpPopupExists = sessionStorage.getItem("TimeOutPopOpened") ? this._commonSer.DecryptData(sessionStorage.getItem("TimeOutPopOpened")) : null;
                if(event && noEvent !== "true" && sessionExpPopupExists !== "true" && !this.userService.getAccessToken() && !localStorage.getItem("userLogged") && value && !value.includes("/login") && !this.checkd && localStorage.getItem("TimeOutSession") !== "true") {
                    this.checkd = true;
                    //This popup comes only for multiple tabs
                    const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                            backdrop: false,
                            keyboard: false, windowClass: "large--content"
                        });
                        modelRef.componentInstance.warnMsg = "Session has timed out. Please login to continue."
                        modelRef.componentInstance.okButton = true;
                        modelRef.componentInstance.IsPopUpClosed.subscribe((resp: any) => {
                            modelRef.close();
                            this._commonStore.isTimeOutSession(null);
                            sessionStorage.removeItem("TimeOutPopOpened");
                            this.clearCookies(true);
                            // window.close();   
                            // this.canCloseWindow.emit(true);
                            // window.onbeforeunload = null;
                            // sessionStorage.removeItem("Timeout");
                        });
                } else if (event && noEvent !== "true") {
                    this.increaseTimeOut();
                    if (localStorage.getItem("TimeOutSession") === "true") {
                        localStorage.removeItem("TimeOutSession");
                    }
                } else if (localStorage.getItem("TimeOutSession") === "true") {
                    localStorage.removeItem("TimeOutSession");
                }
            });
        }
    // }
      }
      clearCookies(timeExpired?: boolean) {
        const res = document.cookie;
        const multiple = res.split(";");
        for (let i = 0; i < multiple.length; i++) {
            const key = multiple[i].split("=");
            document.cookie =
                "" +
                key[0] +
                "=; expires= Thu, 21 Aug 1970 20:00:00 UTC; path=/ ";
        }
        let printSet = this._commonSer.DecryptData(localStorage.getItem("DefaultLabelPrinter"));
        let  reportPrint = this._commonSer.DecryptData(localStorage.getItem("DefaultReportPrinter"));
        let sso = this._commonSer.DecryptData(localStorage.getItem("SSO"));
        let redirectUrl = localStorage.getItem("redirectUrl");


        localStorage.clear();
        if (printSet) {
            localStorage.setItem("DefaultLabelPrinter", this._commonSer.encryptData(printSet));
        }
        if (reportPrint) {
            localStorage.setItem("DefaultReportPrinter", this._commonSer.encryptData(reportPrint));
        }
        sessionStorage.clear();
        if (timeExpired) {
            localStorage.setItem("TimeOutSession", "true");
        }
        if(!sso)
        {
            window.close();
            window.close();
        }
        else
        {
            if(redirectUrl){
                window.close();
                window.onbeforeunload = null;
                window.location.href = redirectUrl;
            } else{
                this._router.navigate(["/login"]);
            }
        }
    }
    increaseTimeOut() {
        this._commonSer.systemSettings$.subscribe(resp => {
            if (resp) {
                this.endTime = this._commonSer.getSetttingValue(
                    "PharmacySettings",
                    "IN_ACTIVITY_TIMEOUT"
                );
            }
        });
        if (!this.endTime) {
            this.endTime = this._commonSer.DecryptData(localStorage.getItem("TimeOut"));
        }
        localStorage.setItem("ExpireTime", this._commonSer.encryptData(moment().add(this.endTime * 60, "seconds").toISOString()));
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
