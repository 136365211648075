import * as wjcFlexGridColumnPicker from "./flex-grid-column-picker";

"use strict";

import { Inject, Directive, Self, OnInit } from "@angular/core";
import * as ngCore from "@angular/core";
import * as WjFlexGrid from "@grapecity/wijmo.angular2.grid";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Directive({
    selector: "[columnPicker]",
    inputs: ["columnPicker"]
})
export class ColumnPicker implements ngCore.OnInit, ngCore.OnDestroy {
    columnPicker: string;
    private _frame: HTMLElement;
    private _img: HTMLElement;

    constructor(
        @Self()
        @Inject(WjFlexGrid.WjFlexGrid)
        private _flex: WjFlexGrid.WjFlexGrid,
        private _modalSvc: NgbModal
    ) {}

    ngOnInit() {
        if (!this._flex) {
            return;
        }

        const cp = new wjcFlexGridColumnPicker.FlexGridColumnPicker(
            this._flex,
            this.columnPicker,
            this._modalSvc
        );
    }

    ngOnDestroy() {
        this._flex.invalidate();
    }
}
