import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from "@angular/core";
import { DefaultHeaders, wijimoIdsList, WjGridSaveModel } from "src/app/models";
import { AlertService, CommonService, UserService } from "src/app/services";
import { CommonUtil, WijimoUtil } from "src/app/utils";
import { closest, createElement, getElement, removeChild } from "@grapecity/wijmo";
import {  FlexGrid } from "@grapecity/wijmo.grid"
import { ListBox } from "@grapecity/wijmo.input";

@Component({
    selector: "app-column-picker-rf",
    templateUrl: "./column-picker-rf.component.html"
})
export class ColumnPickerRfComponent implements OnInit, AfterViewInit {

    @Input() flex: FlexGrid;
    @Input() WijmoKey: string;
    @Input() FromDrugGrid;
    @Input() FromPaGrid;
    @Output() EmitColumnPickerClose = new EventEmitter<any>();
    @ViewChild("columnPicker", { static: true }) columnPicker: ListBox;
    @ViewChild("columnPicker1", { static: true }) columnPicker1: ListBox;

    @ViewChild("ColumnPicker", { static: true }) ColumnPickerPopup: ListBox;

    private _dragSrc: HTMLElement = null;
    private _dragDst: HTMLElement = null;

    noOfColums = 14;
    wjHeaders: any = [];
    wjDefHeaders: any = [];
    savedData: any = [];
    excludeheaders =  [" ", null, "Action", "Actions", "Select", "View Docs", "Tag", "ACTIONS", "View Trans Result", "IsDeleted?"];
     excludeWijmoKeys  = ["prescListWJ","insuListWJ","insuListWJ","facilityListWJ","priceSchdListWJ","discountListWJ","drugWarnListWJ","diagnosisListWJ","rxFollowUpListWJ","patCategoryListWJ","presSpecalityListWJ","payorFileListWJ","pharmacyListWJ","drugSearchListWJ","drugCatoriesList","sigListWJ","intakeQueueWjList","drugOtherPriceswj","patDiagnosisListWJ","DocumentsListWJ","patClinicalInfoListWJ","prescHistoryListWJ","drugMiscInfoListWJ","batchScanListWJ","reviewdrugVerfWJ","patListWJ","priceListWJ","doseScheduleListWJ","vendorListWJ","usersListWJ","rolesListWJ","loginHistoryListWJ","overrideLogListWJ","phyInvListWJ","enterInvListWJ","bucketListwj","invTransListWJ","faxLogListWJ1","faxLogListWJ2","batchedFaxListWJ","patAdditionalInsuListWJ","drugInsuRestListWJ","patDeliveryAddListWJ","drugInvVendorListWJ","scheduledTaskListWJ","recallDeletedRxListWJ","drugPriceUpdateListWJ","primeescSignByDateListWJ","printRxWithAttDocsListWJ","scheduledTaskExecHistListWJ","fileRecordsWJ","topPerfomersPatientListWj","topPerfomersInsideListWj","topPerfomersPrescriberListWj","topPerfomersDrugListWj","topPerfomersInsListWj","topPerfomersFacListWj","CntrlRxAuditTrailWj","logicalAccessCtrlWj","messageLogWj","messageQueueWj","geographyDistributionWj","drugThearpauticWj","labelDesignListWJ","manageInvwj","physicalInvWj","receiveInvWj","dispensedInvWj","DURValNewRxWJ","prescClinicAddListPopupWJ","prescClinicAddListWJ"];
    constructor(private _comnUtil: CommonUtil, private _commonSer: CommonService, private _userService: UserService, private _alertService: AlertService,
        private _wijimoUtils: WijimoUtil,
        private _cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.getWJSavedData();
        this._commonSer.wjSavedList$.subscribe(res => {
            if (res && res.length > 0 && this.savedData && this.savedData["WijmoKey"] && this.savedData["WijmoKey"] !== "") {
                this._wijimoUtils.storeWjSavedData(this.savedData);
                this.savedData = [];
            }
        })
        this.generateExcludeHeaders();
    }

    closeModal() {
        this.EmitColumnPickerClose.emit(true);
    }

    ngAfterViewInit() {
        if (this.flex && this.flex.columns && this.flex.columns.length) {
            const excludeheader =  this._wijimoUtils.setExcludeCols(this.WijmoKey);
            /*const itemSource = [];
            this.flex.columns.map((item) => {
                if (item["_hdr"] && excludeheader.indexOf(item["_hdr"]) === -1) {
                    itemSource.push(item);
                }
            });*/
            this.columnPicker.itemsSource = this.flex.columns;
            this.columnPicker.checkedMemberPath = "visible";
            this.columnPicker.displayMemberPath = "header";
            this.columnPicker.formatItem.addHandler((s, e) => {
                this._enableDragItem(e["item"], true);
                if ((e?._data?._hdr || e?._data?._hdr == "") && excludeheader.indexOf((e?._data?._hdr)) !== -1) {
                    (e["item"]).style.display = "none";
                }
            });
            /*this.columnPicker.collectionView.filter = (item) => {
                if (excludeheader.indexOf(item.header) === -1) {
                    return item;
                }
            };*/
            this.getWijmoData();
        }
    }

    getWJSavedData(type?: boolean) {
        const savedData = this._commonSer.wjSavedList$["source"]["value"];
        let result = null;
        if (savedData && savedData.length) {
            const data = savedData.find(v => v["WijmoKey"] === this.WijmoKey && v["UserId"] === +this._userService.getToken("UserId"));
            if (data) {
                result = type ? data : JSON.parse(data["ColumnOrder"]);
            }
        }
        return result;
    }

  async storeWijimoStatus() {
        this.savedData = [];
        this.checkGridWidthAtSave();
        const d = this.getWJSavedData(true);
        let data: WjGridSaveModel = new WjGridSaveModel();
        if (d) {
            data = d;
        }
        data["WijmoKey"] = this.WijmoKey;
        data["WijmoId"] = wijimoIdsList[this.WijmoKey];
        let wjColData = [];
        if (this.flex) {
            wjColData = this.flex.columns.map(v => {
                const k = {};
                k["header"] = v["header"];
                k["width"] = v["width"];
                k["isVisible"] = v["isVisible"];
                return k;
            });
        }
        data["ColumnOrder"] = JSON.stringify(wjColData);
        this.savedData = data;
        await this._comnUtil.saveWjGridDet(data);
        // this._wijimoUtils.storeWjSavedData(data);
        this.closeModal();
    }

    private _enableDragItem(item: Element, enabled: boolean) {
        item.setAttribute("draggable", enabled.toString());
    }

    handleDragStart(e: DragEvent) {
        this._dragSrc = <HTMLElement>closest(e.target, ".wj-listbox-item");
        if (this._dragSrc) {
            e.dataTransfer.setData("text", this._dragSrc.innerHTML);
            e.dataTransfer.effectAllowed = "move";
        }
    }

    handleDragOver(e: DragEvent) {
        const dragOver = <HTMLElement>closest(e.target, ".wj-listbox-item");
        if (this._dragDst && this._dragDst !== dragOver) {
            this._removeDropMarker();
        }
        if (dragOver && dragOver !== this._dragSrc) {
            e.preventDefault();
            e.dataTransfer.dropEffect = "move";
            this._dragDst = dragOver;
            const src = this._getElementIndex(this._dragSrc);
            const dst = this._getElementIndex(this._dragDst);
            this._removeDropMarker();
            this._addDropMarker(dst > src);
        } else {
            this._dragDst = null;
        }
    }

    handleDrop(e: DragEvent) {
        if (this._dragSrc && this._dragDst) {
            e.preventDefault();
            const src = this._getElementColumnIndex(this._dragSrc);
            const dst = this._getElementColumnIndex(this._dragDst);
            this.flex.columns.moveElement(src, dst);
        }
    }

    handleDragEnd(e: DragEvent) {
        this._dragSrc = null;
        this._dragDst = null;
        this._removeDropMarker();
    }

    private _removeDropMarker() {
        removeChild(getElement(".drop-marker"));
    }

    private _addDropMarker(isAfterPos: boolean) {
        const itemsGap = 10;
        const width = 6;
        const margin = itemsGap / width;
        const height = this._dragDst && this._dragDst.clientHeight ? this._dragDst.clientHeight : 0;
        const topPos = this._dragDst.offsetTop;
        const leftPos = isAfterPos
            ? (this._dragDst.offsetLeft + this._dragDst.clientWidth + margin)
            : (this._dragDst.offsetLeft - itemsGap + margin);
        const css = `top:${topPos}px;left:${leftPos}px;height:${height}px;width:${width}px`;
        const html = `<div class="drop-marker" style="${css}">&nbsp</div>`;
        createElement(html, this.columnPicker.hostElement);
    }

    private _getElementIndex(element: Element): number {
        const parent = element?.parentElement;
        const siblings = Array.prototype.slice.call(parent?.children);
        return siblings.indexOf(element);
    }

    private _getElementColumnIndex(element: Element): number {
        return this.flex.columns.findIndex(v => v["header"] === element.textContent.trim());
    }

    getDefaultHead(wijimoKey) {
        let result = null;
        const defHeaders = new DefaultHeaders();
        if (defHeaders && defHeaders[wijimoKey]) {
            result = defHeaders[wijimoKey];
        }
        return result;
    }

    getStandardWidth(data, gridWidth?) {
        return (gridWidth && (data.width > gridWidth/6)) ? 100 : data.width
    }

    selectDeselectColumnsGridFit(event) {
        if((event.target.checked === true) || (event.target.checked === false)) {
            let visibleColumnsCount = 0;
            let visibleColumnsWidth = 0;
                if (this.flex && this.flex["_e"] && this.flex["_e"].clientWidth) {
                    const gridWidth = this.flex["_e"].clientWidth;
                    const totCols = this.flex.columns.filter(v => !(this.excludeheaders.includes(v.header)));
                    const noOfVisibleCols = this.flex.columns.filter(v => !(this.excludeheaders.includes(v.header)) && v.isVisible);
                    if((event.target.checked == false) && (noOfVisibleCols.length == 0)){
                        event.target.checked = true;
                        this._alertService.warning("Atleast one column should be selected.");
                        this.flex.columns.map((v:any) =>{
                            if(event.srcElement.labels[0].innerText.trim() === v._hdr.trim()){
                                v.visible = true;
                            }
                        })
                    }
                     const isExcludeCheck = this.excludeWijmoKeys.includes(this.WijmoKey);
                    let actionColumnWidth = 0;
                    this.flex.columns.map(v => {
                        this.wjHeaders.map((wj) => {
                            if ((v.header === wj.hName) && !(this.excludeheaders.includes(v.header)) && v.isVisible) {
                                visibleColumnsCount++;
                                visibleColumnsWidth = visibleColumnsWidth + wj.width;
                                v.width = wj.width;
                            }
                        });
                        if (this.excludeheaders.includes(v.header)) {
                            actionColumnWidth = actionColumnWidth + v.width;
                        }
                    });
                    let tempWidth = 0;
                    if (totCols.length !== noOfVisibleCols.length) {
                        if ((visibleColumnsCount <= 11)) {
                            if ((visibleColumnsWidth <= gridWidth)) {
                                const diff = (gridWidth - actionColumnWidth) - visibleColumnsWidth;
                                const eachWidth = (diff-7)/visibleColumnsCount;                           
                                this.flex.columns.map(v => {
                                    if (!(this.excludeheaders.includes(v.header)) && v.isVisible) {
                                        const data:any = this.wjHeaders.find((item: any) => {return (v.header === item.header) });
                                        v.width = isExcludeCheck ? (data ? data.width :  v.width) : (eachWidth>=0 ?  v.width + eachWidth : v.width);
                                    }
                                });
                            } 
                            else {  
                                this.flex.columns.map(v => {
                                    this.wjHeaders.map((wj) => {
                                        if ((v.header === wj.hName)) {
                                            if (!(this.excludeheaders.includes(v.header)) && v.isVisible) {
                                                 v.width =  isExcludeCheck ? wj.width : this.getStandardWidth(v, gridWidth);
                                                 tempWidth = tempWidth + v.width;
                                            }
                                        }
                                    });
                                });
                                if ((tempWidth <= gridWidth)) {
                                    const diff = (gridWidth - actionColumnWidth) - tempWidth;
                                    const eachWidth = (diff-7)/visibleColumnsCount;                           
                                    this.flex.columns.map(v => {
                                        if (!(this.excludeheaders.includes(v.header)) && v.isVisible) {
                                            const data:any = this.wjHeaders.find((item: any) => {return (v.header === item.header) });
                                            v.width = isExcludeCheck ? (data ? data.width :  v.width) : (eachWidth>=0 ? (v.width + eachWidth) : v.width);
                                        }
                                    });
                                } 
                            }
                        }
                        else {
                            if (visibleColumnsWidth <= gridWidth) {
                                const diff = (gridWidth - actionColumnWidth) - visibleColumnsWidth;
                                const eachWidth = (diff-7)/visibleColumnsCount;
                                this.flex.columns.map(v => {
                                    this.wjHeaders.map((wj) => {
                                        if ((v.header === wj.hName)) {
                                            v.width = isExcludeCheck ? wj.width : (eachWidth>=0 ?  v.width + eachWidth : v.width);
                                        }
                                    });
                                });
                            } else {
                                this.flex.columns.map(v => {
                                    this.wjHeaders.map((wj) => {
                                        if ((v.header === wj.hName)) {
                                            v.width =  this.getStandardWidth(v, gridWidth);
                                            tempWidth = tempWidth + v.width;
                                        }
                                    });
                                }); 
                                if ((tempWidth <= gridWidth)) {
                                    const diff = (gridWidth - actionColumnWidth) - tempWidth;
                                    const eachWidth = (diff-7)/visibleColumnsCount;                           
                                    this.flex.columns.map(v => {
                                        if (!(this.excludeheaders.includes(v.header)) && v.isVisible) {
                                            const data:any = this.wjHeaders.find((item: any) => {return (v.header === item.header) });
                                            v.width = isExcludeCheck ? (data ? data.width :  v.width) : (eachWidth>=0 ? (v.width + eachWidth) : v.width);
                                        }
                                    });
                                }
                            }
                        }
                    } else {
                        const eachWidth = (gridWidth - actionColumnWidth - 7) / visibleColumnsCount;
                        this.flex.columns.map(v => {
                            this.wjHeaders.map((wj) => {
                                if (v.header === wj.hName) {
                                    v.width = isExcludeCheck ? wj.width : (visibleColumnsCount <= 11 ? eachWidth : this.getStandardWidth(v, gridWidth));
                                }
                            });
                        });
                    }
                }
        }
    }

    async getWijmoData() {
        const storedWJ = await this._wijimoUtils.getWJSavedData(this.WijmoKey);
        this.wjHeaders = this._wijimoUtils.patchDefHeader(this.WijmoKey, storedWJ, true);
    }

    async setDefaultWijmoData() {
        const storedWJ = await this._wijimoUtils.getDefaultHead(this.WijmoKey);
        this.wjDefHeaders = this._wijimoUtils.patchDefHeader(this.WijmoKey, storedWJ);
        this.flex.columns.map(v => {
            this.wjDefHeaders.map((wj) => {
                if (v.header === wj.hName) {
                    v.width = wj.width;
                }
            });
        });
    }

    generateExcludeHeaders() {
        if (this.WijmoKey === "erxListingWJ") {
            this.excludeheaders.push("Process");
        } else if (this.WijmoKey === "reviewRxVerfWJ" || this.WijmoKey === "reviewdrugVerfWJ") {
            this.excludeheaders.push("Edit Rx");
            this.excludeheaders.push("History");
        } else if (this.WijmoKey === "reviewFollowupWJ") {
            this.excludeheaders.push("Tag History");
        } else if (this.WijmoKey === "controlResubmitListWJ" || this.WijmoKey === "rxBasicDisplayListWJ") {
            this.excludeheaders.push("Status");
        }
    }

    checkGridWidthAtSave() {
        if (this.flex && this.flex["_e"] && this.flex["_e"].clientWidth) {
            const gridWidth = this.flex["_e"].clientWidth;
            let actionColumnWidth = 0;
            let visibleColumnsCount = 0;
            let visibleColumnsWidth = 0;
            let wjHeadersWidth = 0;
            this.flex.columns.map(v => {
                this.wjHeaders.map((wj) => {
                    if (v.header === wj.hName && v.isVisible && !(this.excludeheaders.includes(v.header)) ) {
                            visibleColumnsCount++;
                            visibleColumnsWidth = visibleColumnsWidth + v.width;
                            wjHeadersWidth = wjHeadersWidth + wj.width;
                    }
                });
                if (this.excludeheaders.includes(v.header)) {
                    actionColumnWidth = actionColumnWidth + v.width;
                }
            });
            const isExcludeCheck = this.excludeWijmoKeys.includes(this.WijmoKey);
            if (visibleColumnsWidth < gridWidth) {
                const diff  = (gridWidth - visibleColumnsWidth) - actionColumnWidth - 7;
                const eachWidth = diff > 0 ? diff / visibleColumnsCount : 0;
                this.flex.columns.map(v => {
                    this.wjHeaders.map((wj) => {
                                if (v.header === wj.hName) {
                                    if (!(this.excludeheaders.includes(v.header)) && (v.isVisible === true)) {
                                        if (visibleColumnsCount <= 11) {
                                            v.width = isExcludeCheck ? wj.width :  (eachWidth>=0 ?  v.width + eachWidth : v.width);
                                        } else {
                                            v.width = wj.width ; 
                                        }
                                    } 
                                } else if (this.excludeheaders.includes(v.header)) {
                                    v.width = isExcludeCheck ? wj.width :  v.width;
                                }
                    });
                });
            } else {
                this.flex.columns.map(v => {
                    this.wjHeaders.map((wj) => {
                                if (v.header === wj.hName) {
                                    if (!(this.excludeheaders.includes(v.header)) && (v.isVisible === true)) {
                                        v.width = isExcludeCheck ? wj.width : v.width;
                                    }  
                                }  else if (this.excludeheaders.includes(v.header)) {
                                    v.width = v.width;
                                }
                    });
                });
            }
        }
    }
}
