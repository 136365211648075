import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MsgConfig } from 'src/app/app.messages';
import { Pharmacy, Pharmphone } from 'src/app/models';
import { PharmacyLabels } from 'src/app/models/labels.models';

@Component({
  selector: 'app-pharmacy-selection',
  templateUrl: './pharmacy-selection.component.html',
  styleUrls: ['./pharmacy-selection.component.scss']
})
export class PharmacySelectionComponent implements OnInit {
@Input() isfromPatientHistory:boolean;
selectedpharmacyDetails: any;
IsFocusField: any;
pharmacyabels = new PharmacyLabels();
@Output() selectedPharm = new EventEmitter();
pharmacySearchValue: any = "";
addPharFG: FormGroup;
msgConfig = MsgConfig;
  constructor(private _fb: FormBuilder) {
    this.addPharFG = this._fb.group({
      TransferPharmacies: this._fb.group(new Pharmacy()),
      phone1: null,
      phone2: null,
      Fax: null,
      Phone: this._fb.array([new Pharmphone()])
  });
   }

  ngOnInit(): void {
    
  }
  checkIsPharmacy() {

  }

  selectedPharmDetails(pharmacy) {
    this.selectedpharmacyDetails = pharmacy;
      this.selectedPharm.emit(pharmacy);
  }
}
