import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'integerfloat'
})
export class IntegerFloatPipe implements PipeTransform {

  transform(value: any): unknown {
    let retVal;
    const splitValue = value ? value.toString().split(".") : [];
    if (splitValue?.length > 1 && splitValue[1]) {
      if(splitValue[1].length > 1) {
        if (splitValue[1].charAt(splitValue[1].length - 1) === 0) {
          splitValue[1].map((_item,i) => {
            if (i < splitValue[1].length && splitValue[1].charAt(splitValue[1].length - 1) === 0) {
              splitValue[1] = splitValue[1].slice(0, (splitValue[1].length - 1))
            }
          })
          retVal = +(splitValue[0]+"."+splitValue[1])
        } else {
          retVal = value.toFixed(2)
        }
      } else {
        retVal = (+splitValue[1] === 0) ? +(splitValue[0]) : value;
      }
      return retVal;
    } else {
      return value;
    }
  }
}
