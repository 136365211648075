import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from "rxjs/internal/Subscription";
import { CommonService, UserService } from 'src/app/services';
import * as wjcCore from "@grapecity/wijmo";
import { environment } from "src/environments/environment";


@Component({
  selector: 'app-sso-redirection',
  templateUrl: './sso-redirection.component.html'
})

export class SsoRedirectionComponent implements OnInit {
  subscriptions = new Subscription();
  excludeColumns: string[] = [
    "token_type",
    "expires_in",
    ".issued",
    ".expires",
    "Privs",
    "SfaxEncryptionVector",
    "redirectUrl"
  ];
  popupWindow: Window;
  loggedIn = true;
  pharName: string;
  pharmacyID: number;
  isLoaderPresent: any;
  errorMessage: string;
  isLoggedMultipleUser: boolean = false;
  formSubmitted = false;
  imgSource: string;
  LoginFrmGrp: FormGroup;
  wijimoDistKey: any;
  redirectUrl:any
  
  constructor(private _actvRoute: ActivatedRoute,
    private _userServ: UserService,
    private _commonServ: CommonService,
    private _router: Router
    ) { 

    }

  ngOnInit(): void {
    this.subscriptions.add(
      this._actvRoute.params.subscribe(params => {
          if (params && params["accesstoken"]) {
            this.setCookie("access_token", this._commonServ.DecryptData(params["accesstoken"], true), 0);
            localStorage.setItem("SSO", "true");
          }
          if (params && params["redirectUrl"]) {
            this.redirectUrl = this._commonServ.DecryptData(params["redirectUrl"], true)
            localStorage.setItem("redirectUrl",this.redirectUrl);
          }
      }));

      this._userServ.getSSORequestData().subscribe(resp => {
        if (resp) {
          if (resp.access_token) {
            localStorage.setItem("SfaxEncryptionVector" , resp["SfaxEncryptionVector"]);
            for (const key in resp) {
                if (!this.excludeColumns.includes(key) && resp.hasOwnProperty(key)) {
                      if (key === 'access_token') {
                          this.setCookie(key, resp[key], 0); // Set access_token in cookie
                      } else {
                          this.setLocalStorageData(key, resp[key]); // Set other data in local storage
                      }
                    }
            }
            localStorage.setItem("userLogged", this._commonServ.encryptData("true"));
            localStorage.setItem("Privs", this._commonServ.encryptData(resp["Privs"]));
            localStorage.setItem("ExpireTime", this._commonServ.encryptData(moment().add(15 * 60, 'seconds').toISOString()));
            this.getWijimoAndDWTDWTLicenseKey();
            this._router.navigate(["/eprx/dashboard"]);
          }
          else{
            localStorage.clear();
            window.close();
            if(this.redirectUrl){
              window.location.href = this.redirectUrl;
            } else {
              window.history.back(); 
            }    
          }    
        }
      });
  }

  setLocalStorageData(name: string, value: string) {
    value = this._commonServ.encryptData(value);
    localStorage.setItem(name, value);
  }

  routingAfterLogin(isFrmLogin: boolean = false): void {
    let endURL = "/login";
    const authenticationToken = this._userServ.getAccessToken();
    if (authenticationToken) {
        endURL = "eprx/dashboard";
        // this.initiateFullScreenMode();
        this.loggedIn = true;

        /*var sessionStorage = window.sessionStorage.getItem("userLogged");
        if(!sessionStorage) {
            this.isLoggedMultipleUser = true;
            this.displayMessage();
        }  else {
            
        } */
        this.openApplicationWindow(isFrmLogin);

    }
  }
  
  setCookie(name: string, value: string, secs: number) {
    let expires = "";
    value = this._commonServ.encryptData(value);
    if (secs) {
        let date = new Date();
        date.setTime(date.getTime() + secs);
        expires = "; expires=" + date.toUTCString();
    }
    // document.cookie = name + "=" + (value || "") + expires + "; path=/";
    document.cookie = name + "=" + (value || "") + "" + "; path=/;secure" ;
}

  openApplicationWindow(isFrmLogin: boolean = false) {
    this.popupWindow=window.open(window.location.origin + "/#/eprx/dashboard", "ePrimeRx", "fullscreen=yes,resizable=no,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,top=0,left=0,height="+screen.height+",width="+screen.width);
   if (this.popupWindow) {
       this.popupWindow.moveTo(0, 0);
       this.popupWindow.resizeTo(screen.width, screen.height);
   }
   if(isFrmLogin)
         localStorage.setItem("IsFromAfterlLogin", isFrmLogin+"");
   }

   getWijimoAndDWTDWTLicenseKey() {
    this._userServ.getWijimoAndDWTDWTLicenseKey().subscribe(resp => {
        if (resp) {
            this.wijimoDistKey =  resp.find(obj => obj.KeyName === "wijmoDistrKey");
            const Fontawesome =
             resp.find(obj => obj.KeyName === "FONTAWESOME_NPM_AUTH_TOKEN");
            const DistKeyVal = this._commonServ.DecryptData(this.wijimoDistKey.KeyValue);
            wjcCore.setLicenseKey(
                DistKeyVal
            );
            const DWTLicenseKey = resp.find(obj => obj.KeyName === "DWTLicenseKey");
            if (DWTLicenseKey) {
                localStorage.setItem("DWTLicensekey", DWTLicenseKey.KeyValue);
            }
            environment.FONTAWESOME_NPM_AUTH_TOKEN = Fontawesome;
        }
    });
}

}
