import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { constant } from "../app.constants";
import { State } from "../models";
import { ControlRxFilterParams } from "../models/control-file.model";
import { CommonService } from "./common.service";
const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({providedIn: "root"})
export class ControlFileService {

    constructor(private _http: HttpClient, private _cmmnServ: CommonService) {}

    getControlFileList(
        controlFileParams: ControlRxFilterParams
    ): Observable<any> {
        return this._http.post<any>(
            constant.POST_ControlFile_List,
            controlFileParams
        );
    }

    addControlFile(controlFileParams: ControlRxFilterParams): Observable<any> {
        return this._http.post<any>(constant.POST_ControlFile_List, controlFileParams);
    }

    continueControlFile(
        controlFileParams: ControlRxFilterParams
    ): Observable<any> {
        return this._http.put<any>(
            constant.PUT_Transmit_ControlFile_Continue,
            controlFileParams
        );
    }

    getControlFileData(searchParams: any): Observable<any> {
        return this._http.post<any>(constant.GET_ControlFile, searchParams);
    }

    getRxResubmissionList(resubmitParams: any): Observable<any> {
        return this._http.post<any>(constant.POST_ControlFile_ResubmitListState, resubmitParams);
    }

    resubmitControlFileInfo(resubmitList: any): Observable<any> {
        return this._http.post<any>(constant.POST_ControlFile_Resubmi_HardStop, resubmitList);
    }

    previewControlFile(payload : any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(
            constant.POST_Reports_Asap,payload,
            {
                headers: headers,
                responseType: "blob"
            }
        );
    }

    getControlFileSettings(): Observable<any> {
        return this._http.get<any>(constant.GET_ControlFile_Settings_List);
    }

    getControlFileSettingsAsObserv(addHdrs?:boolean) {
        if (addHdrs) {
            return this._http.get<any>(constant.GET_ControlFile_Settings_List, reqOptns)
            .subscribe(resp => this.setControlFileSettings(resp));
        } else {
            return this._http.get<any>(constant.GET_ControlFile_Settings_List)
            .subscribe(resp => this.setControlFileSettings(resp));
        }
    }

    setControlFileSettings(resp) {
        this._cmmnServ.setControlFileSettings(resp);
    }

    getControlFiles(searchParams: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_ControlFile_Settings_List,
            searchParams
        );
    }

    getStateList(): Observable<State> {
        return this._http.get<State>(constant.GET_Utility_States_List);
    }

    getControlFileSettingsBSDState(StateId): Observable<any> {
        return this._http.get<any>(
            constant.GET_ControlFile_Settings.replace("{StateId}", "" + StateId)
        );
    }

    getControlFileRefillOptionsSettings(stateId): Observable<any> {
        return this._http.get<any>(
            constant.GET_ControlFile_Settings_RefillOptions.replace(
                "{stateId}",
                "" + stateId
            )
        );
    }

    getControlFilePatIdPriorties(stateId): Observable<any> {
        return this._http.get<any>(
            constant.GET_Control_PatIdPriorties.replace(
                "{StateId}",
                "" + stateId
            )
        );
    }

    addControlSettings(settingsInfo): Observable<any> {
        return this._http.post<any>(
            constant.POST_ControlFile_Settings_Add,
            settingsInfo
        );
    }

    updateControlRefillOptions(refOptions): Observable<any> {
        return this._http.put<any>(
            constant.PUT_ControlFile_ControlRefillOptions,
            refOptions
        );
    }

    updateStateSettings(data): Observable<any> {
        return this._http.post<any>(
            constant.POST_ControlFile_Settings_Update,
            data
        );
    }

    updateStatus(data): Observable<any> {
        return this._http.put<any>(
            constant.PUT_ControlFile_UpdateStatus,
            data
        );
    }

    addPrescToControlResubList(payload: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_ControlFile_ResubmitList,
            payload
        );
    }

    deleteRxFromResubmission(data: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_ControlFile_Delete, data);
    }

    CheckIsControlSubmitted(data: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_ControlFile_CheckIsControlSubmitted, data);
    }
    removingControlErrorFile(id) {
        return this._http.delete<any>(constant.Delete_ControlFile_Remove.replace("{id}", "" + id));
    }
    checkIfStateSettingsExist(stateId): Observable<any> {
        return this._http.get<any>(
            constant.GET_ControlFile_CheckIfSettingExist.replace(
                "{stateId}",
                "" + stateId
            )
        );
    }
}

