import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    HostListener,
    TemplateRef,
    AfterViewInit
} from "@angular/core";
import {
    EPatient,
    SystemData,
    NotesLog,
    RxModel
} from "src/app/models";
import {
    CommonService,
    PatientService,
    NotesLogService,
    InsuranceService,
    AlertService,
    AdditionalInsuranceService,
    RxService,
    LoaderService,
    TransmissionService
} from "src/app/services";
import { FormControl, FormGroup } from "@angular/forms";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { BaseUtils, RxBillingUtil, NRxUtils } from "src/app/utils";
import * as moment from "moment";
import { NRxSaveUtils } from "src/app/utils/nRx-save.util";
import { PatientStore } from "src/app/store";
import { CommonStore, RxStore } from "src/app/store";
import { MsgConfig } from "src/app";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { CollectionView } from "@grapecity/wijmo";
import { WijimoUtil } from "src/app/utils";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonNotesLogComponent } from "src/app/modules/shared/common-notes-log/common-notes-log.component";
import { Router } from "@angular/router";

@Component({
    selector: "app-p-popups",
    templateUrl: "./p-popups.component.html"
})
export class PPopupsComponent implements OnInit, AfterViewInit {

    @Input() rxHistry: any;
    @Input() rxFG: FormGroup;
    @Input() systemData: SystemData;
    @Input() rxType: string;
    @Input() isInsuChanged: boolean;
    @Input() notesLog: NotesLog;
    @Input() acbScore: any;
    @Input() isCash: boolean;
    @Input() isERx: boolean;
    rxDetlsID: { PatientId: any; PrescriberId: any; DrugId: any; PrescNum: any; };
    patientInfo: any;
    rFData: any;
    IsPatient340: any;
    fromBillToChange: any;
    gotReponse: boolean;
    pregantPatient: number;
    payRecords: any;
    disablPayRecExist: boolean;
    InsuId: any;
    @Input()
    set rxDetailList(data) {
        this.rxDetails = data;
        this.openBillToPopupModal();
    }
    @Input() fromTrActions: boolean;
    @Input() systemDataList: any;
    @Input() refDet: any;
    @Input()
    set fromQuickRefill(frmQR) {
        if (frmQR) {
            this.fromQuickRef = frmQR;
            // this.rxFG.value["rxType"] = "rf";
            // this.checkBillTo();
        }
    }
    @Input()
    set fromFiletoFill(frmFiletoFill) {
        if (frmFiletoFill) {
            this.fromFileToFill = frmFiletoFill;
            this.rxType = "er";
            this.checkBillTo();
        }
    }
    @Input()
    set fromBilltoChange(frmBillToChnge) {
        if (frmBillToChnge) {
            this.fromBillToChange = frmBillToChnge;
            this.rxType = "er";
            this.checkBillTo();
        }
    }
    refRxDetail: any;
    @Input()
    set refRxDetails(data) {
        if (data) {
            this.refRxDetail = data;
        }
    }
    @Input()
    set PatientData(data) {
        if(data) {
            this.patientInfo = data;
        }
    }
    @Input() inactInsuIdFrmBillTo: any;
    @Output() emitPatientDeceased = new EventEmitter();

    @Output()
    PatientPopUpsClosed = new EventEmitter<any>();

    @Output()
    patchPatientChange = new EventEmitter<any>();

    @Output()
    InactIsPopUpClosed = new EventEmitter<any>();

    @Output()
    FileToFillPopUpClosed = new EventEmitter<any>();

    @ViewChild("PRIVACYACK", { static: true })
    PRIVACYACK: ElementRef;

    @ViewChild("PATIENTNOTES", { static: true })
    PATIENTNOTES: ElementRef;

    @ViewChild("INSUNOTES", { static: true })
    INSUNOTES: ElementRef;

    @ViewChild("BILLTO", { static: true })
    BILLTO: ElementRef;

    @ViewChild("FILEDRXPOP", { static: true })
    FILEDRXPOP: ElementRef;

    @ViewChild("ISPATIENTDISEASED", { static: true })
    ISPATIENTDISEASED: ElementRef;

    @ViewChild("HIGHRISKPATIENT", { static: true })
    HIGHRISKPATIENT: ElementRef;

    @ViewChild("BillToDeflActnOK")
    billToDeflActnOK: any;

    @ViewChild("BillToDeflActnCancl")
    billToDeflActnCancl: any;

    @ViewChild("INSUCHANGEWARNING", { static: true })
    INSUCHANGEWARNING: any;

    @ViewChild("INSUDELETEWARNING", { static: true })
    INSUDELETEWARNING: any;

    @ViewChild("INSUREFILLSETTINGS", { static: true })
    INSUREFILLSETTINGS: any;

    @ViewChild("INSUEXPIREWARNING")
    INSUEXPIREWARNING: any;

    @ViewChild("ISPATIENT340B", {static: true})
    ISPATIENT340B: TemplateRef<any>;

    @ViewChild("RefillInsInactive", {static: true})
    RefillInsInactive: any;

    @ViewChild("ALLInsInactive", {static: true})
    ALLInsInactive: any;

    @ViewChild("INACTIVEINSURANCE", {static: true})
    INACTIVEINSURANCE: any;
    @HostListener("document:keydown.alt.z", ["$event"])
    @HostListener("document:keydown.alt.y", ["$event"])
    @HostListener("document:keydown.alt.x", ["$event"])
    keyEventZ(event: KeyboardEvent) {
        if(this.modelRef  && this._modalService.hasOpenModals()) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

    }

    patientData: EPatient;
    modelRef: any;
    actvModalId: string;
    insureList: any;
    isFirstTime = true;
    optedInsurence: any;
    privcAck: string;
    lastInsuUsed: any;
    maxRefillsAllowed: number;
    currentRefillNumberFromErx: any;
    insuSettings: any;
    rxInfoFromErx: any;
    years: number;
    geriatricAge: number;
    youthUnderAge: number;
    insuDelete: any;
    onlyInsuList: any[];
    popUpCloseText: any;
    noOfFileRx: any;
    insuranceToBillWJ: CollectionView;
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
    fileRecordsFlex: any;
    patData: any;
    modelRefInsu: any;
    insuranceList: any;
    warnMsg: string;
    cashIndex: number;
    patInsuList: any;
    cashIns: any;
    patientInslist: any;
    disabled: boolean;
    modelRefInact: any;
    notCashIns: any;
    count = 0;
    frmInsuFileInact = false;
    isCheckedInsurance = false;
    tertCheck = false;
    secCheck = false;
    primCheck = false;
    cashCheck = false;
    optedData: any;
    optedDataInsurance: any;
    frminsnotchanged = true;
    insuranceNameList: any;
    insuranceName: any;
    buckNameBeforeChange: any;
    buckNameAfterChange: any;
    rxDetails: any;
    rxrefDetails: any;
    fromQuickRef: boolean;
    alreadychecked = false;
    fromFileToFill: boolean;
    pricSchdlLst$: Observable<any>;
    ChangedInsData: any;
    singleBucketMode: any;
    insuranceinactv = false;
    billToCheckedInProgressRefill = false;
    allFuncLoaded = false;
    conditionChecked = false;
    loader = false;
    unsubscribe$: Subject<void> = new Subject<void>();

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event && event.which === 27) {
            if (this.actvModalId === "HIGHRISKPATIENT" && this.modelRef) {
                event.preventDefault();
                this.modelRef.close();
                this.isPatientHasNotes();
                //this.warnPrivacyAcknowledgement();
            } else if (this.actvModalId === "ISPATIENTDISEASED" && this.modelRef) {
                event.preventDefault();
                this.modelRef.close();
                //this.checkIsHighRiskPatient();
                this.isPatientHasNotes()
            } else if (this.actvModalId === "PatientNotesDeflActn" && this.modelRef) {
                event.preventDefault();
                this.modelRef.close();
                this.openBillToPopupModal();
            } else if (this.actvModalId === "BillToDeflActn" && this.modelRef) {
                event.preventDefault();
                this.modelRef.close();
                this.maxRefillsPopupFromErx();
            } else if (this.actvModalId === "INSUREFILLSETTINGS" && this.modelRef) {
                event.preventDefault();
                this.modelRef.close();
                this.isInsurenceHasNotes();
            } else if (this.actvModalId === "insuNotesDefActn" && this.modelRef) {
                event.preventDefault();
                this.modelRef.close();
                this.openFiledRxCountPopUp();
            } else if (this.actvModalId === "FILEDRXPOP" && this.modelRef) {
                event.preventDefault();
                this.modelRef.close();
                this.pat340BSettingsInfo();
            } else if (this.actvModalId === "INSUCHANGEWARNING" || this.actvModalId === "INSUDELETEWARNING") {
                event.preventDefault();
                this.closeWarnPatientInsuranceChange();
            } else if (this.actvModalId === "INSUEXPIREWARNING" && this.modelRef) {
                event.preventDefault();
                this.modelRef.close();
                this.checkInsuranceExpiration();
            }
         } //else if (
        //     event.key === "c" ||
        //     event.key === "s" ||
        //     event.key === "r"
        // ) {
        //     if (this.actvModalId === "Acknowled") {
        //         event.preventDefault();
        //         this.selectedPrivAckValue(event.key);
        //     }
        // }

        if (this.actvModalId === "BillToDeflActn") {
            if (event.which === 37) {
                document.getElementById("BillToDeflActnOK").focus();
            } else if (event.which === 39) {
                document.getElementById("BillToDeflActnCancl").focus();
                // this.billToDeflActnCancl.nativeElement.focus();
            } else if (event.which === 38) {
                const nodeevent: any = event.target;
                if (nodeevent.nodeName === "BUTTON") {
                    document
                        .getElementById("" + this.patientData.insuranceid)
                        .focus();
                }
            } else if (event.which === 39) {
                document
                    .getElementById("" + this.patientData.insuranceid)
                    .focus();
            }
        }
    }

    constructor(
        private _modalService: NgbModal,
        config: NgbModalConfig,
        private _commonServ: CommonService,
        private _commonStore: CommonStore,
        private _patientService: PatientService,
        private _notesLog: NotesLogService,
        private _insurService: InsuranceService,
        private _billingUtils: RxBillingUtil,
        private _saveRxUtls: NRxSaveUtils,
        private _rxStore: RxStore,
        private _nrxUtls: NRxUtils,
        private _alrtSvc: AlertService,
        private _drugBuckUtil: DrugBucketUtil,
        private _additionalInsu: AdditionalInsuranceService,
        private _wijimoUtils: WijimoUtil,
        private _rxService: RxService,
        private _patientStore: PatientStore,
        private _loadrServ: LoaderService,
        private _trnsmsnServ: TransmissionService,
        private _router: Router
    ) {
        config.backdrop = "static";
        this.pricSchdlLst$ = this._commonServ.priceSchedule$;
        // this._patientStore.patientEditInfo$.subscribe(resp => {
        //     this.patData = resp;
        // });
        this.rFData = this._commonStore.rxSelectedForRf$["source"]["value"];
        this.payRecords = this._trnsmsnServ.payRecords$["source"]["value"];
    }

    ngOnInit() {
        if (this.rxFG && this.rxFG.value["Patient"] && this.rxFG.value["Patient"]["patientid"] && this.rxFG.value.frmInprog) {
            this._patientService.getPatientInsuranceData(this.rxFG.value["Patient"]["patientid"])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((response: any) => {
                if (response && response.length) {
                    this.rxFG.controls["Patient"].patchValue({insurance: response});
                    this.continues();
                } else {
                    this.continues();
                }
            });
        } else {
            this.continues();
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "insuranceToBillWj") {
                this.patchDefaultValHeaders();
            }
        });
        this.singleBucketMode = this._drugBuckUtil.getInventoryStngs("Single_Bucket_Mode");
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    
    continues() {
        this.patientData = this.rxFG.get("Patient").value;
        if (!this.fromTrActions) {
            if (this.rxFG.get("patPopCompl").value !== "Completed") {
                if (!this.rxFG.value.frmInprog) {
                    this.isPatientHasNotes();
                    //this.checkIsHighRiskPatient();
                    // if (this.rxType === "rf") {
                    //     //this.warnPrivacyAcknowledgement();
                    // } else {
                    //     this.checkIsHighRiskPatient();
                    // }
                }
                //  else {
                //     this.checkBillTo();
                // }
            } else if (this.rxFG.get("check340BP").value) {
                this.rxFG.controls["check340BP"].patchValue(false);
                this.pat340BSettingsInfo();
            }
            //  else {
            //     this.triggerPatPopUpsCompl();
            // }
        }
    }

    ngAfterViewInit(){
        this.billToCheckedInProgressRefill = false;
        this.allFuncLoaded = true;
    }

    get PATIENT(): FormGroup {
        return this.rxFG.get("Patient") as FormGroup;
    }

    get PRESCRIBER(): FormGroup {
        return this.rxFG.get("Prescriber") as FormGroup;
    }

    get DRUG(): FormGroup {
        return this.rxFG.get("Drug") as FormGroup;
    }

    get RefillInsu(): FormControl {
        return this.rxFG.get("RefillInsu") as FormControl;
    }

    get PrescReFill(): FormControl {
        return this.rxFG.get("PrescReFill") as FormControl;
    }

    pat340BSettingsInfo(popUpCloseText?: any) {
        this.popUpCloseText = popUpCloseText;
        if (!this.isCash && (!this.PrescReFill.value.Is340B)) { // Is340B - false then only check.
            this.check340BSettingsAndOpenPopUp();
        } else {
            if (this.isCash) {
                this.setRxAs340BRx(false);
            } else {
                this.triggerPatPopUpsCompl(this.popUpCloseText);
            }
        }
    }

    check340BSettingsAndOpenPopUp() { // check whether 340b patient req and both prescriber and drug not req.()
        // const IsPatient340 = JSON.parse(localStorage.getItem("IsPatient340"));
        if (localStorage.getItem("IsPatient340")) {
            this.IsPatient340 = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("IsPatient340")));
        }
        const IsSamePatient =  (this.IsPatient340 ? this.IsPatient340.patientId  === this.rxFG.value["Patient"]["patientid"] : false);
        if (this.PATIENT.value.Is340B && !this._drugBuckUtil.checkPatSlctdExcludedIns(this.PATIENT, this.rxFG) &&
            this._drugBuckUtil.get340BSettingInfo("patient")) { // "PATIENT" true - openPOPUp
                 if (!this.IsPatient340 || !(this.IsPatient340.set340B == true || this.IsPatient340.set340B == false)) {
                    this._modalService.open(this.ISPATIENT340B, {centered: true, backdrop: false, keyboard: false, windowClass: "large--content"});
                }   else {
                    this.setRxAs340BRx(this.IsPatient340 ? this.IsPatient340.set340B : false);
                }
        } else if (( this.IsPatient340 && ((this.IsPatient340.set340B === true || this.IsPatient340.set340B === false) && IsSamePatient))) {
            this.setRxAs340BRx(this.IsPatient340.set340B);
        } else {
            this.setRxAs340BRx(false);
        }
    }

    async setRxAs340BRx(set340B: boolean = true) { // setting rx as 340b rx.
        // localStorage.setItem("IsPatient340", JSON.stringify(set340B));
        const isPatientObj = {
                set340B: set340B,
                patientId: this.rxFG.value["Patient"]["patientid"],
            };
        localStorage.setItem("IsPatient340", this._commonServ.encryptData(JSON.stringify(isPatientObj)));
        this._drugBuckUtil.setRxAs340bRx(this.rxFG, set340B);
        if (set340B) {
            this.rxFG.controls["Is340BBy"].patchValue("Patient");
        }
        if (!this.singleBucketMode) {
            const bucketList = this._commonServ.bucketList$["source"][
                "value"
            ];
            if(bucketList && bucketList.length)
                     bucketList.map((val) => Object.assign(val, { Qty: 0 }));
            const patFacilities = this.PATIENT.value.PatientFacilityInfo
                ? this.PATIENT.get("PatientFacilityInfo").value.Facilities
                : null;
            if (this.PrescReFill && this.PrescReFill.value && this.PrescReFill.value.BucketId && bucketList && bucketList.length) {
                this.buckNameBeforeChange = bucketList.find((item: any) => {
                    if (item.Id === this.PrescReFill.value.BucketId) {
                        return item.Name;
                    }
                });
            }
            this.PrescReFill.patchValue({
                BucketName: this.buckNameBeforeChange
                    ? this.buckNameBeforeChange.Name
                    : null,
            });
            if (this.rxFG.value["Drug"]["id"] && this.rxType === "er") {
                if (!this.rxFG.value["IsPatientPrimaryInsuranceChanged"]) {
                    // this._rxStore.StoreRxFG(this.rxFG);
                    this._rxStore.storeRxBucketId(
                        this.PrescReFill.value.BucketId
                    );
                }
            }
            this.PrescReFill.patchValue({
                BucketId:
                    patFacilities && patFacilities.length
                        ? +patFacilities[0][
                              set340B ? "Is340BucketId" : "GeneralBucketId"
                          ] || +this._drugBuckUtil.getDefBucketId(set340B)
                        : +this._drugBuckUtil.getDefBucketId(set340B),
            });
            if (this.PrescReFill && this.PrescReFill.value && this.PrescReFill.value.BucketId && bucketList && bucketList.length) {
                this.buckNameAfterChange = bucketList.find((item: any) => {
                    if (item.Id === this.PrescReFill.value.BucketId) {
                        return item.Name;
                    }
                });
            }
            this.PrescReFill.patchValue({
                BucketName: this.buckNameAfterChange
                    ? this.buckNameAfterChange.Name
                    : null,
            });
            if (this.rxFG.value["Drug"]["id"]) {
                this.rxFG.controls["bucketChanged"].patchValue(true);
            }
        } else {
            this.PrescReFill.patchValue({
                BucketId: this._drugBuckUtil.getDefaultBucketId(),
            });
        }
        if (this.rxFG.value.frmInprog && !this.billToCheckedInProgressRefill) {
            await this.checkBillTo();
            this.billToCheckedInProgressRefill = (this.rxType === "rf") ? true : false; // && !this.allFuncLoaded
        } else {
            this.triggerPatPopUpsCompl(this.popUpCloseText);
        }
    }

    patientIsDeceased() {
        this.emitPatientDeceased.emit(true);
    }

    warnPatientDiseased() {
        if (this.patientData && this.patientData.isdeceased) {
            this.modelRef = this._modalService.open(
                this.ISPATIENTDISEASED,
                {centered: true, keyboard: false, backdrop: false, windowClass: "large--content" }
            );
            this.actvModalId = "ISPATIENTDISEASED";
        } else {
            //this.checkIsHighRiskPatient();
            this.isPatientHasNotes();
        }
    }

    checkInsuChanged(patInfo?, isDeleted?, open340B?) {
        if (open340B) {
            this.closeModal();
            this.closeWarnandtriggerPatPopUpsCompl();
        } else {
            this.optedInsurence = patInfo && patInfo.insurance && patInfo.insurance.length ?
            patInfo.insurance.filter(ins => +ins.insuranceid === +patInfo.insuranceid)[0] : null;
            const refInsu = this.rxFG.controls["RefillInsu"] as FormGroup;
            let filteredInsus;
            if (isDeleted) {
                filteredInsus = this.patientData && this.patientData.insurance && this.patientData.insurance.length
                ? this.patientData.insurance.filter(ins => +ins.insuranceid === +refInsu.value.InsuId) : null;
            } else {
                filteredInsus = this.patientData && this.patientData.insurance && this.patientData.insurance.length ?
                this.patientData.insurance.filter(ins => +ins.insuranceid === +this.patientData.insuranceid) : null;
            }
            if (filteredInsus && filteredInsus.length) {
                this.lastInsuUsed = filteredInsus[0];
                this.patientData = patInfo;
                this.warnPatientInsuranceChange(isDeleted);
            } else {
                this.closeModal();
                this.closeWarnandtriggerPatPopUpsCompl();
            }
        }
    }

    checkIsHighRiskPatient() {
        const dob = moment(this.patientData.dob);
        this.years = moment().diff(dob, "year");

        const isPrmptHighRsk = this._nrxUtls.getSetngKeyVal("PatientSettings", "HIGH_RISK_PATIENT");
        this.geriatricAge = +this._nrxUtls.getSettingValue("PatientSettings", "GERIATRIC_OVER_AGE");
        this.youthUnderAge = +this._nrxUtls.getSettingValue("PatientSettings", "YOUTH_UNDER_AGE");
        this.pregantPatient = +this._nrxUtls.getSettingValue("PatientSettings", "PREGNANT_PATIENT");

        if (isPrmptHighRsk && ((this.years > this.geriatricAge) ||  (this.years < this.youthUnderAge) ||
        (this.pregantPatient && this.patientData && this.patientData.gender === "Female"
         && this.patientData.PatientInfo && this.patientData.PatientInfo.PregnancyCdId === 3))) {
            this.modelRef = this._modalService.open(this.HIGHRISKPATIENT, {centered: true,
                keyboard: false, backdrop: false, windowClass: "large--content"
            });
            this.actvModalId = "HIGHRISKPATIENT";
         } else {
            this.isPatientHasNotes();
        //     this.warnPrivacyAcknowledgement();
         }
    }

    // warnPrivacyAcknowledgement() {
    //     const partialFillNo = this._commonServ.checkIsPartialFill(this.rxFG.value["PrescReFill"]["PartialFillNo"],
    //      this.rxFG.value["PrescReFill"]["PartialIndStatus"]) ? this.rxFG.value["PrescReFill"]["PartialFillNo"] : "";

    //     if (
    //         // TODO:
    //         !this.patientData.isPatientAck && (!partialFillNo || partialFillNo <= 1) &&
    //         this._commonServ.getSetttingValue(
    //             "RxSettings",
    //             "WARN_PRIVA_ACKW"
    //         ) === "1"
    //     ) {
    //         this.isPrivacyAcknowledgementNotOnFile();
    //     } else {
    //         this.isPatientHasNotes();
    //     }
    // }

    // isPrivacyAcknowledgementNotOnFile() {
    //     this.closeModal();
    //     this.modelRef = this._modalService.open(this.PRIVACYACK, {
    //         keyboard: false, backdrop: false, windowClass: "large--content"
    //     });
    //     this.actvModalId = "Acknowled";
    // }

    isPatientHasNotes() {
        if (
            this.notesLog &&
            this.notesLog.Note &&
            this.notesLog.Note.length > 0 &&
            this.notesLog.Note.filter(
                elm => elm.IsShownAsPopup
            ).length > 0
        ) {
            this.actvModalId = "PatientNotesDeflActn";
            this.modelRef = this._modalService.open(CommonNotesLogComponent,{keyboard : false, backdrop : false, centered : true , windowClass: "max-modal-970"});
            this.modelRef.componentInstance.HeaderText =   "Patient Notes";
            this.modelRef.componentInstance.RxType =  this.rxFG.value['NotePopupscreenType'] ?  this.rxFG.value['NotePopupscreenType']: this.rxFG.value['rxType'];
                this.modelRef.componentInstance.NotesListFrmPage =  {
                Note: this.notesLog.Note.filter((item: any) => {
                    return item.IsShownAsPopup;
                })
            }
            this.modelRef.componentInstance.IsNotesLogPopupClosed.subscribe((res : any)=>{
                if( this.modelRef)
                {
                    this.modelRef.close();
                    this.modelRef = null;
                    console.log(this.rxFG , "= this.rxFG= this.rxFG");
                    if(this.rxFG.value['rxType'] == 'rf')
                             this.isInsurenceHasNotes(true);
                    else 
                         this.checkBillTo();
                         
                }
            })
        } else {
            if (this.fromQuickRef) {
                this.rxType = "rf";
            }
            if(this.rxFG.value['rxType'] == 'rf')
                             this.isInsurenceHasNotes(true);
                    else 
                         this.checkBillTo();
        }
    }

    async checkBillTo() {
        this.patientData = this.rxFG.get("Patient").value;
        if (!this.patientInfo) {
            this.patData = await this._commonServ.getEditPatientInformation(this.rxFG.value["Patient"]["patientid"]).toPromise();
        } else {
            this.patData = this.patientInfo;
        }

        if (this.patData && this.patData.PatientInsurances && this.patData.PatientInsurances.length > 0) {
            this.patInsuList = this.patData.PatientInsurances.filter((item) => item.insurerActiveStatus);
        }
        // this.patientInslist = this.patData.PatientInsurances;
        this.insuranceList = (this.patInsuList && this.patInsuList.length) ? this.patInsuList  : -1;
        this.insureList = this._nrxUtls.getSortedInusuDtls((this.patData && this.patData.PatientInsurances) ?
         this.patData.PatientInsurances : []);
        this.patientInslist =  this.insureList && this.insureList.length ?
        this.insureList.filter((item) => item.insurerActiveStatus) : null;
        this.cashIns =  this.insureList && this.insureList.length ? this.insureList.filter((item) => item.IsCash) : null;
        this.notCashIns = this.patientInslist && this.patientInslist.length ? this.patientInslist.filter((x: any) => !x.IsCash) : null;
        let rxNo: any;
        if (this.rFData && this.rFData.queueData && this.rFData.queueData.length) {
            this.rFData.queueData.map(item => {
                if (this.rxFG.value && this.rxFG.value.PrescReFill  && this.rxFG.value.PrescReFill.Id
                    && item.PrescrefillId === this.rxFG.value.PrescReFill.Id) {
                    rxNo = item.Prescnum;
                }
            });
        }
        if (this.rxType === "rf" || this.rxType === "er") {
            if (!this.refRxDetail) {
            this.rxDetlsID = {
                PatientId: this.patData.Patient?.Id,
                PrescriberId: this.rxFG.value["Prescriber"]["prescriberid"],
                DrugId: this.rxFG.value["Drug"]["id"],
                PrescNum: rxNo ? rxNo : this.rxFG.value["Prescription"]["PrescNum"]
            };
            const valrxRefDet = this._rxStore.rxRefDet$["source"]["value"];
            this.rxrefDetails = !this.fromBillToChange ? (valrxRefDet ? valrxRefDet :
                 await this._rxService.refillRxDetails(this.rxDetlsID).toPromise()) : null;
            } else {
                this.rxrefDetails = !this.fromBillToChange ? this.refRxDetail : null;
            }
        }
        if (this.rxType === "rf" && !this.alreadychecked && this.patData && this.patData.PatientInsurances
         && this.patData.PatientInsurances.length > 0 && this.rxrefDetails) {
            this.insuranceNameList = this.patData.PatientInsurances.filter((ins: any) =>
             ins.InsuranceId === +this.rxrefDetails["Refdetail"]["Refilldetails"][0].RefillInsu.InsuId);
            this.insuranceName = this.insuranceNameList && this.insuranceNameList.length > 0 ?
                 this.insuranceNameList[0].insurancename : null;
            this.onlyInsuList = this.insureList && this.insureList.length ? this.insureList.filter((ins: any) => !ins.IsCash) : null;
            const isDeleted = this.patientData.insurance.findIndex(ins =>
                +ins.insuranceid === (this.rxFG.value["RefillInsu"]["InsuId"]));
            const isInactive = this.patientData.insurance.findIndex(ins =>
                +ins.insuranceid === (+this.rxFG.value["RefillInsu"]["InsuId"])
                 && +ins["ActiveStatus"] === 1);
            if ((this.insuranceList && this.cashIns && this.insuranceList.length === 2 && this.cashIns.length > 0)) {
                this.optedData = this.notCashIns[0];
                this.optedInsurence = this.notCashIns[0];
            }
            if (this.insuranceList && this.cashIns && this.insuranceList.length === 1 && this.cashIns.length > 0) {
                this.optedData = this.cashIns[0];
                this.optedInsurence = this.cashIns[0];
            }
            if (this.insuranceList && this.cashIns && this.insuranceList.length === 1 && !this.cashIns.length) {
                this.optedData = this.notCashIns[0];
                this.optedInsurence = this.notCashIns[0];
            }
            this.frminsnotchanged = true;
            this.patData.PatientInsurances.map((ins: any) => {
                if (!ins.insurerActiveStatus && ins.InsuranceId === +this.rxrefDetails["Refdetail"]["Refilldetails"][0].RefillInsu.InsuId && !this.alreadychecked ) {
                    this.frmInsuFileInact = true;
                    this.alreadychecked = true;
                    this._loadrServ.display(false);
                    this.modelRefInact = this._modalService.open(this.INACTIVEINSURANCE,
                         {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"});
                }
            });
            if (isDeleted === -1 ) {
                if (this.modelRefInsu) {
                    this.modelRefInsu.close();
                }
                this.insuDelete = isDeleted === -1 ? "D" : "I";
                if (this.onlyInsuList.length === 0 || this.onlyInsuList.length === 1) {
                    this.checkInsuChanged(this.patientData, isDeleted === -1 ? "D" : "I");
                } else {
                    this.openBillToPopupModal();
                }
            } else if (isInactive === -1) {
                if (this.modelRefInsu) {
                    this.modelRefInsu.close();
                }
                this.modelRef = this._modalService.open(this.RefillInsInactive, { centered: true, backdrop: false,
                     keyboard: false, windowClass: "large--content"});
            } else if (!this.frmInsuFileInact) {
                if (this.modelRefInsu) {
                    this.modelRefInsu.close();
                }
               this.afterCheckToBill();
            }
        } else {
            if (this.patData && this.patData.PatientInsurances && this.patData.PatientInsurances.length > 0) {
                this.insuranceNameList = this.rxType !== "er" ? (this.patData.PatientInsurances.filter((ins: any) =>
                ins.insupriority === "Primary")) :
                (this.patData.PatientInsurances.filter((ins: any) => {
                if (this.rxrefDetails && !this.fromBillToChange) {
                    return ins.InsuranceId === +this.rxrefDetails["Refdetail"]["Refilldetails"][0].RefillInsu.InsuId;
                } else if (this.fromBillToChange && this.inactInsuIdFrmBillTo) {
                    return ins.InsuranceId === +this.inactInsuIdFrmBillTo;
                }
                }));
            }
            this.insuranceName = this.insuranceNameList && this.insuranceNameList.length > 0 ?
                 this.insuranceNameList[0].insurancename : null;
            let activeInsurances: any;
            if(this.patData && this.patData.PatientInsurances && this.patData.PatientInsurances.length > 0){
                activeInsurances = this.patData.PatientInsurances.filter((ins: any) => ins.ActiveStatus === 1 && ins.insurerActiveStatus);
            }
            if(this.patData && this.patData.PatientInsurances && this.patData.PatientInsurances.length > 0 && activeInsurances.length == 0) {
                this.modelRef = this._modalService.open(this.ALLInsInactive, { centered: true, backdrop: false,
                    keyboard: false, windowClass: "large--content"});  
                return;         
            }
            else if (this.insuranceList && this.cashIns && this.insuranceList.length === 1 && this.cashIns.length > 0) {
                this.insuranceNameList = this.insureList && this.insureList.length ?
                this.insureList.filter((item: any) => !item.insurerActiveStatus) : null;
                this.insuranceName = this.insuranceNameList && this.insuranceNameList.length > 0 ?
                 this.insuranceNameList[0].insurancename : null;
                this.optedData = this.cashIns[0];
                this.frminsnotchanged = true;
                this.warnMsg = "<span class='font-weight-bold'>" + (this.insuranceName ? this.insuranceName.toUpperCase() : "")
                 + "</span>" + " is marked as Inactive. Setting Bill to as" + "<span class='font-weight-bold'>" + " CASH" + "</span>" + ".";
            } else {
                    this.warnMsg = "<span class='font-weight-bold'>" + (this.insuranceName ? this.insuranceName.toUpperCase() : "")
                    + "</span>" + " is marked as inactive. Please select an active insurance to bill.";
            }
            if (!this.alreadychecked && this.patData && this.patData.PatientInsurances && this.patData.PatientInsurances.length > 0) {
                if (this.rxType !== "er") {
                    this.patData.PatientInsurances.map(async (ins: any) => {
                        if (!ins.insurerActiveStatus && ins.insupriority === "Primary" && !this.alreadychecked) {
                            this._loadrServ.display(false);
                            this.insuranceinactv = true;
                            this.loader = true;
                            await this.openInactiveInsurancePopup();
                        }
                    });
                    if (!this.insuranceinactv) {
                        if (this.rxFG.value.frmInprog) {
                            this._loadrServ.display(true);
                            this.triggerPatPopUpsCompl(this.popUpCloseText);
                        }
                        if (!this.frmInsuFileInact && !this.rxFG.value.frmInprog) {
                            this.openBillToPopupModal();
                        }
                    }
                } else {
                    this.patData.PatientInsurances.map(async (ins: any) => {
                        if (!ins.insurerActiveStatus && !this.alreadychecked &&
                             ins.InsuranceId === ((this.fromBillToChange && this.inactInsuIdFrmBillTo) ?
                                 this.inactInsuIdFrmBillTo : +this.rxrefDetails["Refdetail"]["Refilldetails"][0].RefillInsu.InsuId)) {
                                this.insuranceinactv = true;
                                this._loadrServ.display(false);
                           await this.openInactiveInsurancePopup();
                        }
                    });
                    if (!this.insuranceinactv) {
                        if (this.rxFG.value.frmInprog) {
                            this.triggerPatPopUpsCompl(this.popUpCloseText);
                        }
                        if (!this.frmInsuFileInact && !this.rxFG.value.frmInprog) {
                            this.openBillToPopupModal();
                        }
                    }
                }
            }
        }
    }

    async openInactiveInsurancePopup() {
        this.frmInsuFileInact = true;
        this.alreadychecked = true;
        this.modelRefInsu = await this._modalService.open(this.INACTIVEINSURANCE,
            {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"});
    }

    async afterCheckToBill() {
        if (this.patientData && this.patientData.insurance && this.patientData.insurance.length) {
            const optedInsu = this.patientData.insurance.find(ins =>
                +ins.insuranceid === this.rxFG.value["RefillInsu"]["InsuId"]);
            await this.getInsSettingsData(+optedInsu.insuplanId);
            await this.selectedInsurence(true, optedInsu);
        }
        this.pat340BSettingsInfo();
    }

    async setInsuranceActive() {
        await this._additionalInsu.insuranceStatusSet(this.rxFG.value["RefillInsu"]["InsuId"],1).toPromise();
        const patData = await this._commonServ.getRxPatientDetailsById(this.rxFG.value["Patient"]["patientid"]).toPromise();
        this.rxFG.controls["Patient"].patchValue(patData);
        this.afterCheckToBill();

    }

    async setPatientPrimaryInsurance() {
        this.insuDelete = "I";
        if (this.onlyInsuList.length === 0 || this.onlyInsuList.length === 1) {
            this.checkInsuChanged(this.patientData, "I");
        } else {
            this.openBillToPopupModal();
        }
    }

    async changeStatus(notesItem, isChecked) {
        await this._notesLog.updateNotesStatus(notesItem.Id, isChecked, "Patient").toPromise();
    }

    ProceedBack(){
        this._router.navigate(["eprx/dashboard"]);
    }

    async openBillToPopupModal() {
        this.insuranceinactv = false;
        if (this.modelRefInsu) {
            this.modelRefInsu.close();
            this.modelRefInsu = null;
        }
        if (this.modelRefInact) {
            this.modelRefInact.close();
            this.modelRefInact = null;
        }
        this.patientData = this.rxFG.get("Patient").value;
        this.systemData = (this.fromTrActions || this.fromQuickRef || this.fromFileToFill || this.fromBillToChange) ? this.systemDataList : this.systemData;
        this.insureList = this._nrxUtls.getSortedInusuDtls(this.patientData.insurance);
        this.onlyInsuList = this.insureList && this.insureList.length ? this.insureList.filter((ins: any) => !ins.IsCash) : null;
        this.patientData = this._nrxUtls.checkDefaultInsuranceInfo(this.patientData,
                this.insureList, this.systemData);
        var selcIns = this._nrxUtls.getDefaultInsuranceInfo(this.patientData,
            this.insureList, this.systemData);
            if (this.fromBillToChange) {
                this.insureList = this.insureList && this.insureList.length ? this.insureList.filter((item: any) => !item.IsCash) : null;
            }
        this.generateInsuranceToBillWJ(this.insureList);
        if (selcIns && !this.fromFileToFill && !this.fromTrActions && !this.fromBillToChange) {
            await this.getInsSettingsData(+selcIns.insuplanId, false, false, "openBillTo");
        }
        if (this.fromTrActions || this.fromQuickRef || this.fromFileToFill || this.fromBillToChange) {
            this.frmInsuFileInact = true;
        }
        if (!this.frmInsuFileInact) {
            if(this.rxFG.controls?.isNewFrmExisting?.value === true || this.rxFG.controls?.fileToFill?.value || this.rxFG.value['fileToFillNewRx']) {
                if(this.rxFG.controls?.RefillInsuList.value) {
                    this.InsuId = this.rxFG.controls.RefillInsuList.value.find(val => val.InsuPriId === 1);
                    if(this.InsuId) selcIns = this.insureList.find(val => val.insuranceid === this.InsuId.InsuId)
                } else {
                    this.InsuId = this.rxFG.controls.RefillInsu.value;
                    if(this.InsuId) selcIns = this.insureList.find(val => val.insuranceid === this.InsuId.InsuId)
                }
            } 
            await this.selectedInsurence(true, selcIns);
        }
        if ((this.onlyInsuList.length === 0 || this.onlyInsuList.length === 1) && (this.insuranceList && this.insuranceList.length <= 2)) {
            if (this.rxFG.get("patPopCompl").value !== "Completed") {
                this.checkInsuranceExpiration();
            } else {
                this.pat340BSettingsInfo();
            }
            this.loader = false;
        } else {
            this.loader = true;
            this.modelRef = this._modalService.open(this.BILLTO, {centered: true,
                size: "lg",
                keyboard: false
            });
            this.actvModalId = "BillToDeflActn";
        }
        if (this.rxFG.value.frmInprog && !this.loader) {
            this.triggerPatPopUpsCompl(this.popUpCloseText);
        }
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.modelRefInsu) {
            this.modelRefInsu.close();
        }
        this.isFirstTime = true;
    }


    checkInsuranceExpiration() {
        if (this.optedInsurence && this.optedInsurence.expirydt && moment().isAfter(moment(this.optedInsurence.expirydt))) {
            this.modelRef = this._modalService.open(this.INSUEXPIREWARNING, {
                keyboard: false, backdrop: false, centered: true,
                windowClass: "large--content"
            });
            this.actvModalId = "INSUEXPIREWARNING";
        } else {
            this.isInsurenceHasNotes();
        }
    }

    async isInsurenceHasNotes(frmPatNotes?:boolean) {
        let insPlanId = null;
        if (this.optedInsurence && this.optedInsurence.insuplanId) {
            insPlanId = this.optedInsurence.insuplanId;
        }
        if( this.rxFG.value['rxType'] == 'rf' ) {
            const rxInfo = this.rxFG.getRawValue();
            const insurance: any = rxInfo.Patient.insurance.filter(
                a => a.insuranceid === +rxInfo.RefillInsu.InsuId
            )[0];
            insPlanId =  insurance?.insuplanId;
        }
        if (insPlanId) {
            this.notesLog = await this._nrxUtls.getNotesLog("Insurance", insPlanId, {RxType :  
                this.rxFG.value['NotePopupscreenType'] ?  this.rxFG.value['NotePopupscreenType']: this.rxFG.value['rxType']}) as any;
            if (
                this.notesLog &&
                this.notesLog.Note &&
                this.notesLog.Note.length > 0 &&
                this.notesLog.Note.filter(
                    elm => elm.IsShownAsPopup
                ).length > 0
            ) {
                this.modelRef = this._modalService.open(CommonNotesLogComponent,{keyboard : false, backdrop : false, centered : true , windowClass: "max-modal-970"});
                this.modelRef.componentInstance.HeaderText =   "Insurance Notes";
                this.modelRef.componentInstance.RxType =  this.rxFG.value['NotePopupscreenType'] ?  this.rxFG.value['NotePopupscreenType']: this.rxFG.value['rxType'];
                this.modelRef.componentInstance.NotesListFrmPage =  {
                    Note: this.notesLog.Note.filter((item: any) => {
                        return item.IsShownAsPopup;
                    })
                }
                this.modelRef.componentInstance.IsNotesLogPopupClosed.subscribe((res : any)=>{
                    if( this.modelRef)
                    {
                        this.modelRef.close();
                        this.modelRef = null;
                        this.rxFG.value['rxType'] == 'rf' && frmPatNotes? this.checkBillTo() : this.openFiledRxCountPopUp();

                    }
                })
                this.actvModalId = "insuNotesDefActn";
            } else {
                this.rxFG.value['rxType'] == 'rf' && frmPatNotes? this.checkBillTo() : this.openFiledRxCountPopUp();
            }
        } else {
            this.rxFG.value['rxType'] == 'rf' && frmPatNotes? this.checkBillTo() : this.openFiledRxCountPopUp();
        }
    }

    maxRefillsPopupFromErx() {
        const rxInfObj: RxModel = this.rxFG.getRawValue();
        const obj: any =
            rxInfObj && rxInfObj.eRxDetailsViewModel
                ? rxInfObj.eRxDetailsViewModel
                : null;
        if (obj && obj["DrugClass"] && obj.DrugClass < 2) {
            if (this.insuSettings && this.insuSettings["InsuPlanOther"]) {
                if (!this.insuSettings["InsuPlanOther"].MaxRefills) {
                    this.insuSettings["InsuPlanOther"].MaxRefills = 99;
                }
                this.maxRefillsAllowed = this.insuSettings[
                    "InsuPlanOther"
                ].MaxRefills;
                if (obj && obj.RefillNum && obj.DrugClass < 2) {
                    this.currentRefillNumberFromErx = obj.RefillNum;
                    if (obj.RefillNum > this.maxRefillsAllowed) {
                        if (localStorage.getItem("RxInfo")) {
                            this.rxInfoFromErx = JSON.parse(this._commonServ.DecryptData(
                                localStorage.getItem("RxInfo")
                            ));
                        }
                        if (
                            this.rxInfoFromErx &&
                            this.rxInfoFromErx.Prescription &&
                            this.rxInfoFromErx.Prescription.ReFillNum
                        ) {
                            localStorage.removeItem("RxInfo");
                            this.rxInfoFromErx.Prescription.ReFillNum = this.maxRefillsAllowed;
                            localStorage.setItem(
                                "RxInfo",
                                this._commonServ.encryptData(JSON.stringify(this.rxInfoFromErx))
                            );
                        }
                        this.modelRef = this._modalService.open(
                            this.INSUREFILLSETTINGS,
                            { centered: true, keyboard: false, backdrop: false, windowClass: "large--content"}
                        );
                        this.actvModalId = "INSUREFILLSETTINGS";
                    } else {
                        this.isInsurenceHasNotes();
                    }
                }
            } else {
                this.isInsurenceHasNotes();
            }
        } else {
            this.isInsurenceHasNotes();
        }
    }

    async getInsSettingsData(insuplanId: number, retunInsStngs: boolean = false, restrictCalc: boolean = false, type?) {
        let response: any;
        if (type === "openBillTo" || this.ChangedInsData) {
        response = await this._insurService.getInsSettingsInfo(+insuplanId).toPromise();
        this._insurService._insuSettings$.next(response);
        } else {
           response = this._insurService.insuSettings$["source"]["value"];
        }
        this.insuSettings = response;
        const isCashRx = await this._billingUtils.checkIsCahsRx(this.rxFG.getRawValue(), this.PATIENT.value, this.systemData);
        this._rxStore.storeIsCashRx(isCashRx);
        if (this.rxType !== "rf") { // refill flow -> ins.inactive -> restrict price calc.
            await this._billingUtils.getInsuranceManualCalc(this.rxFG, response);
            this._billingUtils.initiateManualCal(this.rxFG, response, this.rxType);
        }
        if(retunInsStngs) {
            return response;
        }
    }

    openFiledRxCountPopUp() {
        let filedCount = this.rxHistry && this.rxHistry.FiledRxCount ? this.rxHistry.FiledRxCount : 0;
        if ((this.rxType === "rf" || this.rxType === "nrf")
        && (this.rxFG.value.fileToFill || this.rxFG.value.isDetOldRx)) {
            filedCount = filedCount - 1;
        }
        if (this.rxHistry && filedCount && filedCount > 0) {
            this.noOfFileRx = filedCount;
            this.modelRef = this._modalService.open(this.FILEDRXPOP, {centered: true,
                keyboard: false, backdrop: false, windowClass: "large--content"
            });
            this.actvModalId = "FILEDRXPOP";
        } else {
            this.pat340BSettingsInfo();
        }
    }

    selectedInsurence(isChecked: boolean, checkedVal: any, fromInput?: any) {
        if (isChecked && checkedVal && (checkedVal.insuranceid || checkedVal.InsuranceId)) {
            if (!fromInput) {
                this.frminsnotchanged = false;
                // this.optedInsurence = checkedVal;
                this.optedData = checkedVal;
                this.optedDataInsurance = this.insureList && this.insureList.length ? this.insureList.filter((item: any) => {
                    if (this.optedData && ((item.insuranceid ? item.insuranceid : item.InsuranceId ) ===
                    ((this.optedData["insuranceid"]) ? this.optedData["insuranceid"] : this.optedData["InsuranceId"]))) {
                        return item;
                    }
                }) : null;
                if (this.optedDataInsurance && this.optedDataInsurance.length) {
                    this.optedInsurence = this.optedDataInsurance[0];
                }                
                const rxRefilInsu = this.rxFG.controls.RefillInsu as FormGroup;
                rxRefilInsu.controls["InsuId"].setValue(+(checkedVal.insuranceid ?  checkedVal.insuranceid : checkedVal.InsuranceId));
                this.patchIsPatAssign();
            } else {
                this.ChangedInsData = checkedVal;
            }
        }
    }

    async patchIsPatAssign() {
        let editPat;
        if (!this.patData) {
         editPat = await this._commonServ.getEditPatientInformation(this.rxFG.value["Patient"]["patientid"]).toPromise();
        } else {
            editPat = this.patData;
        }
        if (editPat && editPat["PatientInsurances"] && editPat["PatientInsurances"].length) {
            const insuSelect = editPat["PatientInsurances"].find(v => v["InsuranceId"] === this.rxFG.value["RefillInsu"]["InsuId"]);
            this.rxFG.controls["isPatIndicator"].patchValue(insuSelect ? insuSelect["IsPatAssigned"] : true);
        }
    }

    async selctCloseBillToPopup(okBtn?: any) {
        this.loader = false;
        this.insuranceinactv = false;
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.modelRefInact) {
            this.modelRefInact.close();
        }
        if (this.modelRefInsu) {
            this.modelRefInsu.close();
        }
        if ((this.insuranceList && (this.insuranceList.length > 2 || (this.insuranceList.length === 2 && !this.cashIns)))
         && !this.conditionChecked && this.actvModalId !== "BillToDeflActn") {
            this.conditionChecked = true;
            this.openBillToPopupModal();
            return;
        }
        this.rxFG.controls["insuChanges"].patchValue(false);
        setTimeout(() => {
            this.rxFG.controls["insuChanges"].patchValue(true);
        }, 100);
        const rxInfo: RxModel = this.rxFG.getRawValue();

        if (okBtn && this.ChangedInsData) {
            this.selectedInsurence(true, this.ChangedInsData);
        } else if (this.frminsnotchanged) {
            this.optedDataInsurance = this.insureList && this.insureList.length ? this.insureList.filter((item: any) => {
                if (this.optedData && ((item.insuranceid ? item.insuranceid : item.InsuranceId ) ===
                ((this.optedData["insuranceid"]) ? this.optedData["insuranceid"] : this.optedData["InsuranceId"]))) {// InsuranceId
                    return item;
                }
            }) : null;
            if (this.optedDataInsurance && this.optedDataInsurance.length) {
            this.optedInsurence = this.optedDataInsurance[0];
            this.selectedInsurence(true, this.optedInsurence);
            }
        }
        let insStngs = null;
        if (this.optedInsurence && !this.fromFileToFill && !this.fromBillToChange) {
            insStngs = await this.getInsSettingsData(+this.optedInsurence.insuplanId, false, false, "popupClose");
        }
        if (!this.fromFileToFill && !this.fromTrActions && !this.fromBillToChange && !this.rxFG.value["frmInprog"]) {
            if (!this.lastInsuUsed) {
            // this.checkInsuranceExpiration();
                this.checkInsuranceExpiration();
            } else {
                this.pat340BSettingsInfo("insuCmpltd");
            }
        }
        if (this.fromTrActions || this.fromQuickRef || this.fromBillToChange) {
            if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
            }
            if (this.optedInsurence && this.optedInsurence.IsCash && !this.fromQuickRef && !this.fromBillToChange) {
                const type = null;
                const insuSetngs = this._insurService.insuSettings$["source"]["value"];
                await this._billingUtils.getInsuranceManualCalc(this.rxFG, insuSetngs);
                const isCashRx = this._billingUtils.checkIsCahsRx(this.rxFG.value, this.rxFG.value.Patient,
                    this.systemData);
                this._rxStore.storeIsCashRx(isCashRx);
                await this._billingUtils.getPriceDetlsNdCalc(type, this.rxFG, this.pricSchdlLst$, insuSetngs, this.rxType);
                this.InactIsPopUpClosed.emit("Cash");
            } else {
                this.InactIsPopUpClosed.emit(this.rxFG.value.RefillInsu);
            }
            this.fromTrActions = false;
            this.fromQuickRef = false;
            this.fromBillToChange = false;
        }
        if (this.fromFileToFill) {
            if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
            }
            this.fromFileToFill = false;
            this.FileToFillPopUpClosed.emit(this.rxFG.value.RefillInsu);
        }
        // if(this.rxType === "rf" && insStngs) { // this.insuSettings
        //     if (insStngs && insStngs["InsuPlanOther"] && insStngs["InsuPlanOther"]["IsRefillRepriced"]) {
        //         await this._billingUtils.getPriceDetlsNdCalc(null, this.rxFG, this.pricSchdlLst$, insStngs, this.rxType);
        //         console.log("this.rxFGAwait", this.rxFG);
        //     }
        // }
        if (this.rxFG.value.frmInprog) {
            this.triggerPatPopUpsCompl(this.popUpCloseText);
        }
    }

    triggerPatPopUpsCompl(val = "0") {
        if (this.fromQuickRef) {
            this.InactIsPopUpClosed.emit(this.rxFG.value.RefillInsu);
        } else {
            this.PatientPopUpsClosed.emit(val);
        }
    }

    // async selectedPrivAckValue(val) {
    //     if (!this.privcAck && val === "s") {
    //         await this._patientService
    //             .savePrivacyAcknowledgement(this.patientData.patientid, true).toPromise();
    //         const patientFG = this.rxFG.controls["Patient"] as FormGroup;
    //         patientFG.controls["isPatientAck"].setValue(true);
    //     }
    //     setTimeout(() => {
    //         if (this.modelRef) {
    //         this.modelRef.close();
    //         }
    //         this.isPatientHasNotes();
    //     }, 200);
    // }

    isChecked(item: any, index: number) {
        if (item.insuranceid === +this.patientData.insuranceid) {
            if (
                document.getElementById("insu" + index) &&
                this.isFirstTime
            ) {
                document.getElementById("insu" + index).focus();
                this.isFirstTime = false;
            }
            return true;
        } else {
            return false;
        }
    }

    async warnPatientInsuranceChange(isDeleted) {
        if (this.rxType !== "rnr") {
            if (isDeleted) {
                this.insuDelete = isDeleted;
                this.modelRef = this._modalService.open(this.INSUDELETEWARNING, {centered: true,
                    keyboard: false, backdrop: false,
                    windowClass: "large--content"
                });
                this.actvModalId = "INSUDELETEWARNING";
            } else {
                const isEditRxClose = this._commonStore.ngOnchangesRestriction$["source"]["value"];
                if (!isEditRxClose) {
                    this.rxFG.controls["IsPatientPrimaryInsuranceChanged"].patchValue(true);
                    this.modelRef = this._modalService.open(this.INSUCHANGEWARNING, {
                        keyboard: false, backdrop: false, centered: true,
                        windowClass: "large--content"
                    });
                } else {
                    this._commonStore.ngOnchangesRestrictionAfterClosingEditRx(false);
                     await  this.closeWarnPatientInsuranceChange();
                    this.rxFG.controls["IsPatientPrimaryInsuranceChanged"].patchValue(false);
                }
                this.actvModalId = "INSUCHANGEWARNING";
            }
        } else {
            this.pat340BSettingsInfo();
        }
    }

    async saveRxOnChangeOfInsu(isCash) {
        const rxInfo = this._saveRxUtls.setValuesBfrSave(this.rxFG, this.rxType,
             this._rxStore.drugInterctn$["source"]["value"], isCash);
        const resp = await this._saveRxUtls.updateRxInfo(rxInfo);
        if (resp && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
            this.rxFG.controls["PrescReFill"].patchValue({"ModifiedDtTm": new Date()});
            this._rxStore.storeRxInfo(this.rxFG.getRawValue());
        } else if (resp && isNaN(+resp.Message)) {
            if (resp.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                this._alrtSvc.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
            } else if (typeof resp.Message === "string") {
                this._alrtSvc.error("Unable to update Rx.Missing " + resp.Message + " fields.");
            }
        }
    }

    async closeWarnPatientInsuranceChange() {
        this.patientData.insuranceid = this.optedInsurence? this.optedInsurence["insuranceid"] : null;
        const refInsu = this.rxFG.controls["RefillInsu"] as FormGroup;
        const rxBill = this.rxFG.controls["RxBill"] as FormGroup;
        this.patchPatientChange.emit();
        if (this.optedInsurence?.insurerid === this.systemData["DefaultValuese"][0]["CashInsurerId"] ||
        (this.optedInsurence && this.optedInsurence["IsCash"])) {
            if (this.rxFG.value["RxBill"]["StatusId"] === 1 &&
            !([2, 4].indexOf(this.rxFG.value["PrescReFill"]["StatusId"]) > -1 ||
            [2, 4].indexOf(this.rxFG.value["Prescription"]["RxStatusId"]) > -1)) {
                refInsu.controls["InsuId"].patchValue(this.optedInsurence? this.optedInsurence["insuranceid"] : null);
                this.rxFG.controls["insuChanges"].patchValue(false);
                setTimeout(() => {
                    this.rxFG.controls["insuChanges"].patchValue(true);
                }, 150);
                if (this.rxType === "er") {
                    rxBill.controls["StatusId"].patchValue(2);
                    this.saveRxOnChangeOfInsu(true);
                }
            }
        } else {
            this.rxFG.controls["insuChanges"].patchValue(false);
                setTimeout(() => {
                    this.rxFG.controls["insuChanges"].patchValue(true);
                }, 150);
            refInsu.controls["InsuId"].patchValue(this.optedInsurence? this.optedInsurence["insuranceid"] : null);
            rxBill.controls["StatusId"].patchValue(1);
            if (this.rxType === "er") {
                this.saveRxOnChangeOfInsu(true);
            }
        }
        if (this.modelRef) {
            this.modelRef.close();
        }
        if(this.optedInsurence && this.optedInsurence.insuplanId) {
            const insuResp = await this._insurService.getInsSettingsInfoForRx(this.optedInsurence.insuplanId);
            this._billingUtils.getInsuranceManualCalc(this.rxFG, insuResp);
        }
        refInsu.updateValueAndValidity();
        rxBill.updateValueAndValidity();
        this.rxFG.updateValueAndValidity();
        this.pat340BSettingsInfo();
    }

    closeWarnandtriggerPatPopUpsCompl() {
        if (this.rxFG.value.frmInprog) {
            this._loadrServ.display(true);
        }
        this.pat340BSettingsInfo("insuCmpltd");
    }

    generateInsuranceToBillWJ(insureList) {
        this.insuranceToBillWJ = new CollectionView(
            insureList.map((insList: any, i) => {
                const k = {};
                k["Ins Code"] = insList.insuType ? insList.insuType : "";
                k["Insurance Name"] = insList.insurancename ? insList.insurancename : "";
                k["Insurance #"] = k["Insurance#"] = insList.policynum ? insList.policynum : "";
                k["Bill Order"] = insList.InsuPriority ? insList.InsuPriority : "";
                k["Bin #"] =  k["BIN"] = insList.binnum ? insList.binnum : "";
                k["PCN"] = insList.ProcessorNum ? insList.ProcessorNum : "";
                k["insuranceid"] = insList.insuranceid ? insList.insuranceid : "";
                k["Active Status"] = insList.insurerActiveStatus;
                k["IsCash"] = insList.IsCash ? insList.IsCash : false;
                return k;
            })
        );
    }

    init(flex: wjcGrid.FlexGrid) {
        this.fileRecordsFlex = flex;
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("insuranceToBillWj");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("insuranceToBillWj", storedWJ);
    }

     isCheckedIns(data: any) {
        const PrescNumber =  this.payRecords && this.payRecords.length && this.payRecords[0].PrescNum;
        data["Active Status"] = this.rxFG && this.rxFG.value.Drug.id && this.payRecords && this.payRecords.length && data["Active Status"] && (this.rxFG.value.Prescription.PrescNum === PrescNumber) ? !this.InsDisableOrNot(this.payRecords, data["Ins Code"]) : data["Active Status"];
        if (data && data["Active Status"]) {
            this.isCheckedInsurance = false;
            this.disabled = false;
            if (data["Bill Order"] === "Primary" && this.isCheckedInsurance) {
                this.count = 1;
                this.isCheckedInsurance = true;
                this.disabled = false;
                this.primCheck = true;
                this.optedData = data;
            } else if (data["Bill Order"] === "Secondary" && !this.isCheckedInsurance && !this.primCheck) {
                this.count = 1;
                this.isCheckedInsurance = true;
                this.disabled = false;
                this.secCheck = true;
                this.optedData = data;
            } else if (data["Bill Order"] === "Tertiary" && !this.isCheckedInsurance && (!this.secCheck && !this.primCheck)) {
                this.count = 1;
                this.isCheckedInsurance = true;
                this.disabled = false;
                this.tertCheck = true;
                this.optedData = data;
            } else if (data["IsCash"] && !this.isCheckedInsurance && (!this.tertCheck && !this.secCheck && !this.primCheck)) {
                this.count = 1;
                this.isCheckedInsurance = true;
                this.disabled = false;
                this.optedData = data;
                this.cashCheck = true;
            } else if (!this.isCheckedInsurance && (!this.tertCheck && !this.secCheck && !this.primCheck && !this.cashCheck)) {
                this.optedData = data;
                this.count = 1;
                this.disabled = false;
                this.isCheckedInsurance = true;
            }
            return (this.isCheckedInsurance && this.count === 1) ?  true : false;
        } else {
            this.isCheckedInsurance = false;
            if (data["Bill Order"] === "Primary" && !this.isCheckedInsurance) {
                this.isCheckedInsurance = false;
                this.disabled = true;
            } else if (data["Bill Order"] === "Secondary" && !this.isCheckedInsurance) {
                this.isCheckedInsurance = false;
                this.disabled = true;
            } else if (data["Bill Order"] === "Tertiary" && !this.isCheckedInsurance) {
                this.isCheckedInsurance = false;
                this.disabled = true;
            } else if (!this.isCheckedInsurance) {
                this.isCheckedInsurance = false;
                this.disabled = true;
            }
            this.disablPayRecExist = this.rxFG && this.rxFG.value.Drug.id && this.payRecords && this.payRecords.length && (this.rxFG.value.Prescription.PrescNum === PrescNumber) ? this.InsDisableOrNot(this.payRecords, data["Ins Code"]) : false;
            return (!this.isCheckedInsurance) ? this.isCheckedInsurance : false;
        }
    }

    isDisabled(item: any) {
        return (item ? false : true);
    }

    InsDisableOrNot(payRecs, InsCode) {
        let result = false;
        payRecs.find(pay => {
            if ((InsCode.toLowerCase()).trim() === (pay.Ins_Code.toLowerCase()).trim()) {
                result =  true;
            }
        });
        return result;
    }
}
