import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "SortBy" })
export class SortByProp implements PipeTransform {
    transform(list: any, prop: string, prop2?: string, prop3?: string, prop4?: string, _sortType?: string, value?: any): any {
        let startList = [];
        let includeList = [];
        if (value && list && list.length > 0) {
            const startList1 = this.returnFilterList(prop, list, value);
            const startList2 = prop2 ? this.returnFilterList(prop2, list, value, prop) : [];
            const startList3 = prop3 ? this.returnFilterList(prop3, list, value, prop2) : [];
            const startList4 = prop4 ? this.returnFilterList(prop4, list, value, prop3) : [];
            startList = [...startList1, ...startList2, ...startList3, ...startList4];
        }
        if (value && list && list.length > 0) {
            includeList = list.filter(v => {
                if (v[prop]) {
                    v[prop] = (v[prop] + "").trim(); 
                    return (
                        (v[prop] + "")
                            .toLowerCase()
                            .trim()
                            .includes(value.toLowerCase()) &&
                        !((v[prop] + "")
                            .toLowerCase()
                            .trim()
                            .startsWith(value.toLowerCase()))
                    );
                } else {
                    return false;
                }
            });
             includeList.sort(function(a, b) {
                if (a[prop] && b[prop]) {
                    a[prop] = a[prop].trim();
                    b[prop] = b[prop].trim();
                    let nameA = a[prop]+"".toLowerCase();
                    let nameB = b[prop]+"".toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    } else if (nameA > nameB) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            });
        }
        if(startList && startList.length) {
            startList.sort(function(a, b) {
                if (a[prop] && b[prop]) {
                    a[prop] = a[prop].trim();
                    b[prop] = b[prop].trim();
                    let nameA = a[prop]+"".toLowerCase();
                    let nameB = b[prop]+"".toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    } else if (nameA > nameB) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
                
            });
        }
        if (includeList && includeList.length > 0) {
            // includeList.map(v => startList.push(v));
            list = startList;
        } else {
            list = startList;
        }
        list = startList;
        //list.sort((a, b) => a.InsurerCode.localeCompare(b.InsurerCode, undefined, { numeric: true, sensitivity: 'base' }))
        return list;
    }

    returnFilterList(property, list, value, prevPro1?, prevPro2?, prevPro3?) {
        let result = [];
        let result1 = [];
        result = list.filter(v => {
            if (v[property]) {
                return (v[property] + "")
                    .toLowerCase()
                    .trim()
                    .startsWith(value.toLowerCase().trim()) &&
                    !(prevPro1 && (v[prevPro1] + "")
                    .toLowerCase()
                    .trim()
                    .startsWith(value.toLowerCase().trim())) &&
                    !(prevPro2 && (v[prevPro2] + "")
                    .toLowerCase()
                    .trim()
                    .startsWith(value.toLowerCase().trim())) &&
                    !(prevPro3 && (v[prevPro3] + "")
                    .toLowerCase()
                    .trim()
                    .startsWith(value.toLowerCase().trim()));
            } else {
                return false;
            }
        });
        if (result && result.length) {
            result1 = result.reverse();
        }
        return result1;
    }
}
