<app-custom-modal (oncrossBtnClick)="cancelUCPopup()">
    <h4 header>U&C Options</h4>
    <span body>
        <div class="col-xs-12 col-md-12 col-lg-12 margin_left_n1rem">
            <div class="row transmit--block padding-0">
                <div class="col-md-12 p-0">
                    <h4 class="fw-600">
                        Usual and Customary is less than AWP
                    </h4>
                    <div class="row margin_top_1rem" >
                        <div
                        class="col-2"
                    >
                        <div class="font_size_17px">
                            <label class="font_size_17px"> Rx#: </label>
                            <!-- <div class="label--data"> -->
                                {{ rxFG?.value?.Prescription?.PrescNum }}
                            <!-- </div> -->
                        </div>
                    </div>
                        <div
                            class="col-8"
                        >
                            <div class="font_size_17px">
                                <label class="font_size_17px"> Patient:  </label>
                                <!-- <div class="label--data"> -->
                                    {{rxFG?.value?.Patient?.lastname |uppercase }},&nbsp;{{
                                        rxFG?.value?.Patient?.firstname | uppercase
                                    }}
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div>&nbsp;</div>
                <div class="col-md-12 p-0">
                    <div class="row">
                        <div
                            class="col-lg-4 col-md-4 col-sm-4 col-xs-12 trasmit--rx"
                        >
                            <eprx-input
                                [RxSelectId]="'rxUnitPriceUNCC'"
                                [LabelText]="'U&amp;C'"
                                [PlaceHolder]="''"
                                [ControlName]="'UnitPriceUNC'"
                                [FormGroupName]="
                                    this?.rxFG?.controls?.PrescReFill
                                "
                                [TabIndex]="44"
                                [InputType]="'CURRENCY'"
                                (TriggerSearchValue)="
                                    setNumberFormat('UnitPriceUNC')
                                "
                                [IsInputElemHidden]="false"
                                [IsDisabled]="false"
                                [DecimalValues]="2"
                                (TriggerOnShiftTabbing)="
                                    undokeytab(
                                        $event,
                                        this?.rxFG?.controls?.PrescReFill,
                                        'UnitPriceUNC'
                                    )
                                "
                                (TriggerOnEnterValue)="
                                    keytab(
                                        $event,
                                        this?.rxFG?.controls?.PrescReFill,
                                        'UnitPriceUNC'
                                    )
                                "
                                [AutoFocus]="true"
                            >
                            </eprx-input>
                        </div>
                        <div
                            class="col-lg-4 col-md-4 col-sm-4 col-xs-12 trasmit--rx"
                        >
                            <eprx-input
                                [RxSelectId]="'rxUnitPriceAWPC'"
                                [LabelText]="'Awp'"
                                [PlaceHolder]="''"
                                [ReadOnly]="true"
                                [ControlName]="'UnitPriceAWP'"
                                [FormGroupName]="
                                    this?.rxFG?.controls?.PrescReFill
                                "
                                [InputType]="'CURRENCY'"
                                (TriggerSearchValue)="
                                    setNumberFormat('UnitPriceAWP')
                                "
                                [IsDisabled]="true"
                                (TriggerOnShiftTabbing)="
                                    undokeytab(
                                        $event,
                                        this?.rxFG?.controls?.PrescReFill,
                                        'UnitPriceAWP'
                                    )
                                "
                            >
                            </eprx-input>
                        </div>
                        <div
                            class="col-lg-4 col-md-4 col-sm-4 col-xs-12 trasmit--rx"
                        >
                            <eprx-input
                                [RxSelectId]="'rxPriceC'"
                                [LabelText]="'Price'"
                                [PlaceHolder]="''"
                                [ControlName]="'Price'"
                                [FormGroupName]="
                                    this?.rxFG?.controls?.PrescReFill
                                "
                                (TriggerOnEnterValue)="
                                    keytab(
                                        $event,
                                        this?.rxFG?.controls?.PrescReFill,
                                        'Price'
                                    )
                                "
                                [IsDisabled]="true"
                                [InputType]="'CURRENCY'"
                                (TriggerSearchValue)="setNumberFormat('Price')"
                                (TriggerOnShiftTabbing)="
                                    undokeytab(
                                        $event,
                                        this?.rxFG?.controls?.PrescReFill,
                                        'Price'
                                    )
                                "
                            >
                            </eprx-input>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    </span>
    <div class="row" footer>
        <div class="col UC-Button__width">
            <button
            footer
            id="saveuccontinue"
            tabindex="45"
            class="hotkey_success mb-1"
            (click)="emitPOPUpClosed()"
            appShortcutKey
            [AltKey]="'s'"
        >
            <span>S</span> Save U&C and continue
        </button>
        <button
        footer
        id="makeucsameasprice"
        tabindex="46"
        class="hotkey_success"
        (click)="makeUCsameasPrice()"
        appShortcutKey
        [AltKey]="'p'"
    >
        <span>P</span> Make U&C same as Price
    </button>
        <button
                footer
                id="saveCnfmBillCashC"
                tabindex="47"
                class="hotkey_success"
                (click)="reCalculateUC()"
                appShortcutKey
                [AltKey]="'r'"
            >
                <span>R</span> Recalculate U&C
            </button>
   
<button
footer
id="makeucsameasawp"
tabindex="48"
class="hotkey_success"
(click)="makeUCsameasAWP()"
appShortcutKey
[AltKey]="'a'"
>
<span>a</span> Make U&C same as AWP
</button>
           
          
            <button
                footer
                id="ignorecontinueuc"
                tabindex="49"
                class="hotkey_success"
                (click)="emitPOPUpClosed()"
                appShortcutKey
                [AltKey]="'i'"
                *ngIf="ignoreContinueBtnEnable"
            >
                <span class="px-2">I</span> Ignore and Continue
            </button>
            <button
                footer
                id="ignorecontinueuc"
                tabindex="50"
                class="inactive"
                *ngIf="!ignoreContinueBtnEnable"
            >
              Ignore and Continue
            </button>
         
          
            <button
                footer
                id="makeucsameascancel"
                class="hotkey_success"
                (click)="cancelUCPopup()"
                appShortcutKey
                [AltKey]="'c'"
                tabindex="51"
            >
            <span>C</span> Cancel
            </button>
        </div>
    </div>
</app-custom-modal>
