import { Directive, HostListener, EventEmitter, Output } from '@angular/core';

@Directive({
    selector: '[licenseFormat]'
})
export class LicenseDirective {

    @Output() blurLicenseEvent = new EventEmitter<string>(); 
    @HostListener('input', ['$event'])
    onInput(event: any): void {
    if (event?.target && typeof event.target.value === 'string' && event.target.title?.toLowerCase() === 'license#') {
        let inputElement = event.target;
        let inputValue = inputElement.value; 
        let sanitizedValue = inputValue.replace(/[^a-zA-Z0-9/-]/g, ''); 
        sanitizedValue = sanitizedValue.replace(/([/-]){2,}/g, '$1');  
        inputElement.value = sanitizedValue;
    }
}

    @HostListener('blur', ['$event'])
    emitLicenseBlur(event: any): void {
    if (event?.target && typeof event.target.value === 'string' && event.target.title?.toLowerCase() === 'license#') {
        let inputElement = event.target;
        let inputValue = inputElement.value;
        let trimmedValue = inputValue.replace(/^[/-]+|[/-]+$/g, ''); 
        inputElement.value = trimmedValue;
        this.blurLicenseEvent.emit(trimmedValue);
    }
} 
}
