

    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>New Rx Request</h4>
        <span body class="small--erx__screen ">
           <div class="row pop_searches erx_request  ">
                <div class="col-12  erx_newRequest">
                    <div class="exprx--common-block">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading"> Patient - {{(rxPatientModel && patientSearchValue) ? ((rxPatientModel?.lastname + ",  " + rxPatientModel?.firstname | uppercase)) : ""}} </div>
                            </div>
                            <div class="eprx--block__content">
                                    <div class="row">
                                        <div class="col-4 pr-0 margin-top_8">
                                            <div class="row">
                                                <div class="col-12" >
                                                    <app-searchbox-global class="common_global_search_max_content_display"  id="Patient" [RxSelectId]="'Patient101'" [SearchFor]="'Patient'"  [IsFocused]="true"  [inputdisabled]="SearchPatient"
                                                    [IsAddNew]="false" [DisableSerchFil]="true" [DisableRadioButtons]="true"
                                                    (SelectedPatient)="selectPatient($event)" [ClearInputValue]="patientSearchValue">
                                                    </app-searchbox-global>
                                                </div>

                                                <div class="col-9 mt-1">
                                                    <eprx-input [LabelText]="'Mobile#'" [PlaceHolder]="'Mobile#'"   [InputValue]="''"
                                                        [Title]="'Mobile'"   [FormGroupName]="ErxChangeReqFG"  [ControlName]="'patientMobile'"
                                                        [MaskPattern]="'(000)000-0000'" [ShowMask]="true">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-3 mt-3 px-0">
                                                    <button class="  btn  btn-primary p-2 mt-2" (click)="PatientHist()">Pat. History</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <app-rx-patient-card  [primaryInsuFromErxtoRequestObejct]="primaryInsuFromErxtoRequest" [primaryInsuFromErxtoRequestForCashObejct]="primaryInsuFromErxtoRequestForCash"  [IsFromErxRequest]="'true'" [RxPatientInfo]="rxPatientModel"></app-rx-patient-card>
                                        </div>
                                    </div>
                            </div>
                    </div>
                </div>
           </div>
           <div class="row pop_searches erx_request" >
            <div class="col-12 erx_newRequest "  >
                <div class="exprx--common-block margin_bottom_n1_5rem">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading"> Prescriber - {{(prescriberInfo && prescInputValue) ? ((prescriberInfo?.prescriberlastname + "," + prescriberInfo?.prescriberfirstname) | uppercase) : ""}} </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col-4 pr-0 margin-top_8" >
                                    <div class="row">
                                        <div class="col-12" >
                                            <app-searchbox-global class="common_global_search_max_content_display" [SearchFor]="'Prescriber'" [DisableRadioButtons]="true" [inputdisabled]="SearchPrecriber" [IsFocused]="searchPrescriber"
                                            [IsAddNew]="false" (SelectedPrescriber)="selectedPresInfo($event)"
                                            [ClearInputValue]="prescInputValue">
                                        </app-searchbox-global>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <eprx-input [LabelText]="'Mobile#'" [PlaceHolder]="'Mobile#'"  [InputValue]="''"
                                                [Title]="'Mobile'"  [MaskPattern]="'(000)000-0000'" [ShowMask]="true"  [ControlName]="'prescriberMobile'"
                                                [FormGroupName]="ErxChangeReqFG">
                                            </eprx-input>
                                        </div>
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-10">
                                                    <eprx-select class="change-select"
                                                    [AddItem]="
                                                        true
                                                    " [RxSelectId]="
                                                        'RxSig'
                                                    " [LabelText]="
                                                        'SPI'
                                                    " [HasControl]="
                                                        false
                                                    " [InputValue]="
                                                        PrescAddress
                                                            ? PrescAddress?.SPINo
                                                            : ''
                                                    " [IsRequired]="true"  [HasMultiple]="
                                                        false
                                                    " [HasBindLabel2]="
                                                        false
                                                    " [BindValueML]="
                                                        SPIDetails
                                                    " [HasBindLabelM]="
                                                        true
                                                    "         [ErrorDefs]="{ required: 'Required'}"  [BindLabel]="
                                                        'SPINo'
                                                    " [BindValue]="
                                                        'SPINo'
                                                    " [LabelForId]="
                                                        'SPINo'
                                                    " [List]="
                                                        SPIDetails
                                                            ? SPIDetails
                                                            : ''
                                                    " (TriggerSelectValue)="
                                                        changeSPIADD(
                                                            $event
                                                        );
                                                    " [CheckSpiServiceLevel]="checkServiceLevel(PrescAddress ? PrescAddress:'')"
                                                    [Title]="checkServiceLevel(PrescAddress ? PrescAddress:'') === true ? 'Service Level found' : 'Service Level Not found'">
                                                </eprx-select>
                                                </div>
                                             <div class="col-2 refresh--icon-style">
                                                    <a (click)="refreshSpi()" [ngStyle]="{color: checkServiceLevel(PrescAddress ? PrescAddress:'') === true ? '#0fa20f' : '#f6251a'}">
                                                            <i class="far fa-redo-alt fa-md font-weight-bold" title="Refresh SPI"></i>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <app-rx-prescriber-card [IsFromErxRequest]="'true'" [IsFromERx]="'true'" [PrescriberInfo]="prescriberInfo" ></app-rx-prescriber-card>
                                </div>
                            </div>
                        </div>

                </div>
            </div>
       </div>
      <div class="row pop_searches margin_top_1_8rem">
        <div class="col-4 " >
            <div class="custom-control custom-radio">
                <input class="radio_common_sizes" type="radio" id="drugNdc" value="2"
                (change)="setDrugType('NDC')"    [checked]="selectDrugType === 'NDC'"/>
                    <label type="label" class="form-check-label" [for]="2" id="'expired Report'">
                        Drug Name/NDC
                    </label>
            </div>
         </div>
          <div class="col-6 px-0" >
                <div class="custom-control custom-radio">
                    <input class="radio_common_sizes"  type="radio" [id]="161" value="1"
                    (change)="setDrugType('Description')"  [checked]="selectDrugType === 'Description'"/>
                    <label type="label" class="form-check-label" id="'IncludeFacilityPatients'" [for]="1">
                        Drug Description(Therapy)
                    </label>
                </div>
            </div>

        <div class="col-12 erx_newRequest margin_top-2 erx_request"  >
            <div class="exprx--common-block" >
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading"> Drug - {{(drugInfo && drugInputValue) ? (drugInfo?.drugname | uppercase) + " " + drugInfo?.strength + " "  + drugInfo?.drugformname  : ""}} </div>
                    </div>
                    <div class="eprx--block__content">
                            <div class="row">
                                <div class="col-4 pr-0 margin-top_8" *ngIf="(selectDrugType !== 'Description')">
                                    <div class="row">
                                        <div class="col-12">
                                            <app-searchbox-global class="common_patient_presciber_drug_suggest_dropdown_changes common_global_search_max_content_display"  [SearchFor]="'Drug'" [DisableRadioButtons]="true" [IsAddNew]="false" [inputdisabled]="SearchDrug" [IsFocused]="drugFocus"
                                            (SelectedDrug)="selectDrug($event)" [ClearInputValue]="drugInputValue" [SearchFrm]="'commonDrugSearch'">
                                        </app-searchbox-global>
                                        </div>

                                        <div class="col-12 mt-1">
                                            <eprx-input [LabelText]="'NDC'" [PlaceHolder]="'NDC'"   [InputValue]="''"
                                                [Title]="'NDC'"  [ReadOnly]="true"   [ControlName]="'NpiNum'"
                                                [FormGroupName]="ErxChangeReqFG">
                                            </eprx-input>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-8" *ngIf="(selectDrugType !== 'Description')">
                                    <app-rx-drug-card [IsFromErxRequest]="'true'" [DrugInfo]="drugInfo"></app-rx-drug-card>
                                </div>
                                <div  class="col-12" *ngIf="(selectDrugType === 'Description')">
                                    <eprx-text-area [RxSelectId]="
                                                        'erxReNotes'
                                                    " [AutoFocus]="
                                                    (selectDrugType === 'Description') ? true: false
                                                    "  [PlaceHolder]="
                                                        ''
                                                    " [Cols]="30" [Rows]="3" [MaxLength]="
                                                        150
                                                    " [FormGroupName]="
                                                        ErxChangeReqFG
                                                    " [ControlName]="
                                                        'DrugDescription'
                                                    ">
                                                </eprx-text-area>
                                </div>
                            </div>
                    </div>

            </div>

        </div>
   </div>
<div class="row mt-2">
    <div class="col-12">
        <app-rx-sig-qty-daw  [DrugInfo]="drugInfo"  [erxReq]="ErxChangeReqFG"></app-rx-sig-qty-daw>
     </div>
</div>
   <div class="row margin_top_n5px">
        <div class="col-8">
            <div class="exprx--common-block" >
                <div class="eprx--block__header">
                    <div class="eprx--header__heading"> Diagnosis Code </div>
                </div>
                <div class="eprx--block__content">
                    <app-diagnis-code [ErxChangeReqFG]="ErxChangeReqFG"></app-diagnis-code>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">   Pharmacist Notes</div>
                </div>
                <div class="eprx--block__content height_6_1rem">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <eprx-text-area [RxSelectId]="
                                    'erxReNotes'
                                " [AutoFocus]="
                                    false
                                "  [PlaceHolder]="
                                    'Enter text'
                                " [Cols]="30" [Rows]="3.5" [MaxLength]="
                                    120
                                " [FormGroupName]="
                                    ErxChangeReqFG
                                " [ControlName]="
                                    'PharmacistNotes'
                                ">
                            </eprx-text-area>
                        </div>
                    </div>
                    <div class="row p-1" >
                        <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <button  class="hotkey_success"  (keydown.enter)="checkSPIServiceLevel($event)"   (click)="checkSPIServiceLevel($event)" appShortcutKey [AltKey]="'s'"
                                InputKey="s"><span>S</span> Send</button>
                            <button class="hotkey_success" (click)="closeModal()"  [AltKey]="'c'"  appShortcutKey InputKey="c">
                                <span>C</span> CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
 </span>
<!-- <div footer class="row p-1" >
    <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button  class="hotkey_primary"  (keydown.enter)="checkSPIServiceLevel($event)"   (click)="checkSPIServiceLevel($event)" appShortcutKey [CtrlKey]="'s'"
            InputKey="s"><span>S</span> Send</button>
        <button class="hotkey_primary" (click)="closeModal()"  [CtrlKey]="'c'"  appShortcutKey InputKey="c">
            <span>C</span> CANCEL</button>
    </div>
</div> -->
</app-custom-modal>
<app-drug-missing-form (closeDrugMissingValues)="drugMissingValuesClosingPop($event)" *ngIf="isShowDrugValuesPop" [drugInfo]="drugInfo" [ErxChangeReqFG]="ErxChangeReqFG"></app-drug-missing-form>
<ng-template #canDoRxChange let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalForSpi()">
        <h4 header>SPI Does Not Support Rx Change Request</h4>
        <span body>
            <p>
                The Selected SPI for this Prescriber may not have valid service level to process 'Rx Change Request' and may get
                rejected, you can try with another SPI with Rx change service level if available.
            </p>
            <p>
                Are you sure you want to send it anyway?
            </p>
        </span>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="sendErxRequest();"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer type="button" class="hotkey_primary" (click)="closeModalForSpi()" (keydown.enter)="closeModalForSpi()" appShortcutKey InputKey="n">
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #INACTIVEINSURANCE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeInactModal()">
        <h4 header> Warning </h4>
        <span body>
            <span [innerHTML]="warnMsg"></span>
        </span>
        <span footer>
            <button  id="InActvIns2" *ngIf="insuranceList > 1"
            ngbAutofocus class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeInactModal();c('Close click')"> <b>O</b> Ok
            </button>
            <button  id="InActvIns3" *ngIf="(insuranceList === 1 && cashIns)"
            ngbAutofocus class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeInactModal();c('Close click')"> <b>O</b> Ok
            </button>
        </span>
    </app-custom-modal>
</ng-template>
