import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import {KeyCodes} from "../models";

@Directive({
    selector: "[appShortcutKey]"
})
export class ShortcutKeyDirective {
    keyCodes = KeyCodes;
    IPKeycode: number;
    altKey: boolean;
    ctrlKey: boolean;
    constructor(private _el: ElementRef) {}


    @Input()
    set AltKey(ri: string) {
        if (ri) {
            this.altKey = true;
            this.IPKeycode = this.keyCodes[ri.toLowerCase()];
        }
    }

    @Input()
    set CtrlKey(ri: string) {
        if (ri) {
            this.ctrlKey = true;
            this.IPKeycode = this.keyCodes[ri.toLowerCase()];
        }
    }


    @Input()
    set InputKey(ri: string) {
        this.IPKeycode = this.keyCodes[ri.toLowerCase()];
    }

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {

        if (this.altKey && (event.altKey || event.keyCode === 18)) {
            if (event.which === this.IPKeycode) {
                this.clickEvent(event);
            }
        } else if (this.ctrlKey && event.ctrlKey) {
            if (event.which === this.IPKeycode) {
                this.clickEvent(event);
            }
        } else if (!this.ctrlKey && !this.altKey && !event.ctrlKey && !event.altKey) {
            if (event.which === this.IPKeycode) {
                this.clickEvent(event);
            }
        }
    }

    clickEvent(event) {
        const targetElem = document.elementFromPoint(
            Math.ceil(this._el.nativeElement.getBoundingClientRect().left + 2),
            Math.ceil(this._el.nativeElement.getBoundingClientRect().top) + 2);
        if (targetElem && targetElem.isSameNode(this._el.nativeElement)) {
            this._el.nativeElement.click();
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
