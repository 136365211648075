<div class="row">
<div class="col-6 exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Messaging Info
        </div>
    </div>
    <div class="eprx--block__content insurance--body">
       <!--- <div class="row" formArrayName="Patient_MsgAlert">
            <div class="col-sm-3" *ngFor="let patient of patientMsgAlertFG?.get('Patient_MsgAlert')['controls']; let i=index">
                <div class="col-md-12 messsage-height">
                    <label class="col-md-12 padding-0"> (i === 0) ? (Send Refill Reminder Alerts ?) : (i === 1) ? (Send Dose Reminder Alerts ?)
                    : (i === 2) ? (Send General Alerts ?) : (Send Pickup Alerts ?) </label>
                    <eprx-radio [ControlName]="'IsActive'" [FormGroupName]="patient"
                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [AutoFocus]="(i === 0) ? true : false" [IDForList]="[i,i+1]" [LabelForId]=""
                   (TriggerSelectValue)="changeMessageValues($event, i)">
                   </eprx-radio>
                </div>
                <div class="col-sm-12 padding-0" *ngIf="patient.value['IsActive']">
                    <div class="col-md-5" [formGroup]="patient">
                        <input class="form-check-input" type="checkbox" tabindex="-1" id="RefillEmail + i"
                            formControlName="IsEmailAllowed" (change)="changeMessageValues('',i,'email',$event)">
                        <label class="form-check-label" for="RefillEmail + i">
                            Email
                        </label>
                    </div>
                    <div class="col-md-5" [formGroup]="patient">
                        <input class="form-check-input" type="checkbox" tabindex="-1" id="RefillSms"
                            formControlName="IsSMSAllowed" (change)="changeMessageValues('',i,'phn',$event)">
                        <label class="form-check-label" for="RefillSms">
                            SMS
                        </label>
                    </div>
                    <div class="col-md-5" [formGroup]="patient">
                        <input class="form-check-input" type="checkbox" tabindex="-1" id="RefillPhone"
                            formControlName="IsPhoneAllowed" (change)="changeMessageValues('',i,'phn',$event)">
                        <label class="form-check-label" for="RefillPhone">
                            Phone
                        </label>
                    </div>
                    <div class="align--msg--top" *ngIf="i === 3">
                        <label class="col-md-12"> Send Ready for Pickup Alert when ? </label>
                        <div class="col-md-12 inline--class">
                            <eprx-radio [ControlName]="'PickAlertCatId'" [FormGroupName]="patientMsgAlertFG?.get('PatientAlert_PickAlert')"
                                [List]="['Label is printed','Pharmacist Verification is done','Rx is entered in the Bin','Mark Rx as ready for Pick up']"
                                [ValueList]="[1,2,3,4]" [IDForList]="[20,21,22,23]" [LabelForId]="">
                            </eprx-radio>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
            <div class="col-12 row">
            <div class="col-6">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                           Patient Consent
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-6 p-0 margin_left_12px">
                                <eprx-radio
                                [LabelText]="'Patient Consent Received?'"
                                [ControlName]="'SubscriptionResponse'"
                                [FormGroupName]="PatientMessageSettings"
                                [List]="['Yes', 'No']"
                                [Name]="'SubscriptionResponse'"
                                [ValueList]="[true, false]"
                                [IDForList]="['SubscriptionResp1', 'SubscriptionRes2']"
                                (TriggerSelectValue) = "patchDateTime();ChangeToDefaultValues()"
                            >
                            </eprx-radio>
                            </div>
                           <div class="col-12 mt-n0">
                            <div class="row">
                                <div class="col-6 pd-0">
                                    <eprx-date-picker [LabelText]="'Date/Time Received'" [ControlName]="'SubscriptionResponseDate'" [FormGroupName]="tempFG" [PlaceHolder]="'MM/DD/YYYY'"
                                    [IsDisabled]="!(PatientMessageSettings?.controls['SubscriptionResponse'].value)" (TriggerOnDateSelect)="saveDateTime()">
                                    </eprx-date-picker>
                                </div>
                                <div class="col-6 grid_custom_timePicker btn-time-picker align-time btn-meridian btn-align  checkin-custom--input left_n2_6rem margin_top_17px">
                                    <ngb-timepicker [(ngModel)]="time" [meridian]="true" [required]="true"
                                    [readonlyInputs] = "!(PatientMessageSettings?.controls['SubscriptionResponse'].value)" (ngModelChange) = "saveDateTime()">
                                </ngb-timepicker>
                                </div>
                            </div>
                           </div>
                            <!-- <div class="col-8 grid_custom_timePicker btn-time-pick align-time btn-meridian btn-align grid_custom_timePicker checkin-custom--input" style="left:-2px;">
                                <label>Time Received</label>
                                <ngb-timepicker [(ngModel)]="time" [meridian]="true" [required]="true"
                                    [readonlyInputs] = "!(PatientMessageSettings?.controls['SubscriptionResponse'].value)">
                                </ngb-timepicker>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                           General
                        </div>
                    </div>
                    <div class="eprx--block__content">

                        <eprx-radio
                        [LabelText]="'Send General Alerts?'"
                        [ControlName]="'SendGeneralAlerts'"
                        [FormGroupName]="PatientMessageSettings"
                        [List]="['Yes', 'No']"
                        [Name]="'SendGeneralAlerts'"
                        [ValueList]="[true, false]"
                        [IDForList]="['IsRefillgeneralAlertsSet1', 'IsRefillgeneralAlertsSet2']"
                        [IsDisabled] = "!PatientMessageSettings?.value['SubscriptionResponse'] ? true : (!accGeneralSettings ? true : false)"
                    >
                    </eprx-radio>


                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                           Refill Reminder
                        </div>
                    </div>
                    <div class="eprx--block__content">

                        <eprx-radio
                        [LabelText]="'Send Refill Reminder Alerts?'"
                        [ControlName]="'SendRefillReminderAlerts'"
                        [FormGroupName]="PatientMessageSettings"
                        [List]="['Yes', 'No']"
                        [Name]="'SendRefillReminderAlerts'"
                        [ValueList]="[true, false]"
                        [IDForList]="['IsRefillRemAlertsSet1', 'IsRefillAlertsRemSet2']"
                        [IsDisabled] = "!PatientMessageSettings?.value['SubscriptionResponse'] ? true : (!accRefRemStng ? true : false)"
                    >
                    </eprx-radio>

                    </div>
                </div>
            </div>

            <div class="col-6 margin_top_n5rem">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                           Ready for Pickup
                        </div>
                    </div>
                    <div class="eprx--block__content">

                        <eprx-radio
                        [LabelText]="'Send Ready for Pickup  Alerts?'"
                        [ControlName]="'SendReadyForPickupAlerts'"
                        [FormGroupName]="PatientMessageSettings"
                        [List]="['Yes', 'No']"
                        [Name]="'SendReadyForPickupAlerts'"
                        [ValueList]="[true, false]"
                        [IDForList]="['SendReadyForPickupAlerts1',
                         'SendReadyForPickupAlerts2']"
                         [IsDisabled] = "!PatientMessageSettings?.value['SubscriptionResponse'] ? true :(!accPickUpStng  ? true : false)"
                    >
                    </eprx-radio>


                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                          Birthday
                        </div>
                    </div>
                    <div class="eprx--block__content">

                        <eprx-radio
                        [LabelText]="'Send Birthday Alerts?'"
                        [ControlName]="'SendBirthdayGreetingAlerts'"
                        [FormGroupName]="PatientMessageSettings"
                        [List]="['Yes', 'No']"
                        [Name]="'SendBirthdayGreetingAlerts'"
                        [ValueList]="[true, false]"
                        [IDForList]="['SendBirthdayGreetingAlerts1',
                         'SendBirthdayGreetingAlerts2']"
                         [IsDisabled] = "!PatientMessageSettings?.value['SubscriptionResponse'] ? true :(!accBirthdayStng ? true : false)"
                    >
                    </eprx-radio>


                    </div>
                </div>
            </div>

            <div class="col-6 margin_top_n5rem">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                          New Rx Received
                        </div>
                    </div>
                    <div class="eprx--block__content">

                        <eprx-radio
                        [LabelText]="'New Rx Received  Message Alerts?'"
                        [ControlName]="'SendNewRxMessageAlerts'"
                        [FormGroupName]="PatientMessageSettings"
                        [List]="['Yes', 'No']"
                        [Name]="'SendNewRxMessageAlerts'"
                        [ValueList]="[true, false]"
                        [IDForList]="['SendNewRxMessageAlerts1', 'SendNewRxMessageAlerts2']"
                        [IsDisabled] = "!PatientMessageSettings?.value['SubscriptionResponse'] ? true :(!accNewRxStng  ? true : false)"
                    >
                    </eprx-radio>


                    </div>
                </div>
            </div>

        </div>


    </div>

</div>
    <!--  Secure Patient Communication start -->
    <div class="col-6 exprx--common-block isCloudApp" *ngIf="isSecurePatientComm"> 
        <div class="spc-history">
            <app-spc-cloud [userData]="selectedPatient"></app-spc-cloud>
        </div>
    </div>
</div>
<!--  Secure Patient Communication end  -->
<ng-template #warningData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>Kindly enable refill reminder setting at patient information.</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click')"
            appShortcutKey InputKey="o"><b>O</b> OK</button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="c"><b>C</b>
            CANCEL</button>
    </div>
</ng-template>
<ng-template #warningEmailData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>Email is not available, kindly update Email at Patient Information.</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click')"
            appShortcutKey InputKey="o"><b>O</b> OK</button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="c"><b>C</b>
            CANCEL</button>
    </div>
</ng-template>
<ng-template #warningPhnData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>Mobile No is not available, kindly update Mobile No at Patient Information.</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click')"
            appShortcutKey InputKey="o"><b>O</b> OK</button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="c"><b>C</b>
            CANCEL</button>
    </div>
</ng-template>
