declare var require: any;
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as qz from "qz-tray";
import { environment } from "src/environments/environment";
import { constant } from "../app.constants";
import { QzTrayStepsComponent } from "../components";
import { DefaultLabelPrintsetComponent } from "../modules/shared/default-label-printset/default-label-printset.component";
import { DefaultReportPrinterComponent } from "../modules/shared/default-report-printer/default-report-printer.component";
import { ReleaseNotesComponent } from "../modules/shared/release-notes/release-notes.component";
import { CommonStore } from "../store";
import { AlertService } from "./alert.service";
import { CommonService } from "./common.service";
import { UserService } from "./user.service";
const sha1 = require("js-sha256");

qz.api.setSha256Type(function (data) {
    const hashed = sha1(data);
    return hashed;
});


qz.api.setPromiseType(function promise(resolver) {
    return new Promise(resolver);
});

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
};

@Injectable({
    providedIn: "root",
})
export class PrintService {
    defaultPrinter: any;
    allPrinters: any[];
    // allPrinters$ = new BehaviorSubject(null);
    modelRef: any;
    labelPrinter: number = 0;
    installedVersion: any;
    isQzLatestVersion: any;
    isSystemplatform: string = "";
    modelRefReleaseNotes: any;
    isVisible : boolean;
    jVersion: any;
    pharName: string;
    userId : any;
    userIdInt: number;
    constructor(
        private _alertSer: AlertService,
        private _modalService: NgbModal,
        private _http: HttpClient,
        private _commonStore: CommonStore,
        private _commonServ: CommonService,
        private _userService:UserService

    ) {
        qz.security.setCertificatePromise((resolve, reject) => {
            this._http.get(constant.GET_QZTRAY_CERT, reqOptns).toPromise().then((data: string) => {
                if (data) {
                    let decodedStrng = atob(atob(data));
                    resolve(decodedStrng);
                } else {
                    reject();
                }
            });
        });

        qz.security.setSignaturePromise((hash) => {
            return (resolve, reject) => {
                this._http.post(constant.POST_QZTRAY_KEY, { message: hash }, reqOptns).toPromise().then((data: string) => {
                    if (data) {
                        resolve(data);
                    } else {
                        reject();
                    }
                });
            };
        });



        if (!qz.websocket.isActive()) {
            this.isVisible = environment.isReleaseNotes;                 
            qz.websocket
                .connect()
                .then(qz.printers.getDefault)
                .then(
                    function (printer) {
                        this.defaultPrinter = printer;
                    }.bind(this)
                )
                .then(qz.printers.find)
                .then(
                    function (printers) {
                        this.allPrinters = printers;
                        this._commonStore.storeAllPrinters(printers);
                        // this.allPrinters$.next(printers);
                    }.bind(this)
                )
                .then(qz.api.getVersion)
                .then(function (Version) {
                    this.isSystemplatform = window.navigator.platform;;
                    if (this.isSystemplatform && (this.isSystemplatform === 'Mac68K' || this.isSystemplatform === 'MacPPC' || this.isSystemplatform === 'MacIntel')) {
                        console.log(Version);
                        if (Version == "QZ Tray 2.2.2") {
                            return true;
                        }
                        else {
                            this.isQzLatestVersion = false;
                            this.openQzInstallSteps();
                        }
                    }
                    else {
                        if (Version == "2.2.2+1") {
                            if(this.isVisible){
                                this.openRelaseNotesAfterBuild();
                            }
                            return true;
                        }
                        else {
                            this.isQzLatestVersion = false;
                            if(this.isVisible){
                                this.openRelaseNotesAfterBuild();
                            } else{
                                this.openQzInstallSteps();
                            }
                        }

                    }

                    console.log(Version);
                }.bind(this))
                .catch((err) => {
                    if (!qz.websocket.isActive()) {
                        window.location.assign("qz:launch");
                        qz.websocket
                            .connect({ retries: 2, delay: 20 })
                            .then(qz.printers.getDefault)
                            .then(
                                function (printer) {
                                    this.defaultPrinter = printer;
                                }.bind(this)
                            )
                            .then(qz.printers.find)
                            .then(
                                function (printers) {
                                    this.allPrinters = printers;
                                    this._commonStore.storeAllPrinters(printers);
                                    // this.allPrinters$.next(printers);
                                }.bind(this)
                            )
                            .catch((err) => {
                                this.isQzLatestVersion = true;
                                this.openQzInstallSteps();
                            });
                    }
                });
        }
        //  else if(qz.websocket.isActive())
        //  {
        //      qz.websocket
        //      .connect().then(qz.api.getVersion)
        //      .then(function (getVersion) 
        //      {
        //          console.log(getVersion);
        //      }.bind(this))
        //  }

    }

    getDefaultPrinter() {
        qz.printers.getDefault().then((printer) => {
            this.defaultPrinter = printer;
        });
    }

    getAllPrinters(): any {
        return this.allPrinters;
    }
    checkLabelPrinterBeforePrint(fileTpPrint: any,
        printerName: string,
        noOfCopies: number,
        size: any = { width: null, height: null },
        isLanscape: boolean) {
        if(!printerName || !localStorage.getItem("DefaultLabelPrinter")) {
            const modelRef = this._modalService.open(DefaultLabelPrintsetComponent, {
                centered: true,
                backdrop: false,
                keyboard: false
            });
            modelRef.componentInstance.PrinterList = this.allPrinters;
            modelRef.componentInstance.IsPopUpClosed
                .subscribe(resp => {
                    modelRef.close();
                    if (resp) {
                        let defaultSet = localStorage.getItem("DefaultLabelPrinter") ? (this._commonServ.DecryptData(localStorage.getItem("DefaultLabelPrinter"))) : null;
                        this.printWithConfig(fileTpPrint, defaultSet, noOfCopies, size, isLanscape);
                    } else {
                        this._alertSer.error("Unable to print as default report printer is not selected.");
                    }
                });

        } else {
                this.printWithConfig(fileTpPrint, printerName, noOfCopies, size, isLanscape);
        }

    }


    print(fileTpPrint: any, orientationType?: string) {
        let printerName = null;
        let defPrinter = this._commonServ.DecryptData(localStorage.getItem("DefaultReportPrinter"));
        if (defPrinter && this.allPrinters && this.allPrinters.length && this.allPrinters.find(elm => elm === defPrinter)) {
            printerName = defPrinter;
            this.printFromReportPrinter(fileTpPrint, printerName, orientationType);
        } else {
            const modelRef = this._modalService.open(DefaultReportPrinterComponent, {
                centered: true,
                backdrop: false,
                keyboard: false
            });
            modelRef.componentInstance.PrinterList = this.allPrinters;
            modelRef.componentInstance.IsPopUpClosed
                .subscribe(resp => {
                    modelRef.close();
                    if (resp) {
                        printerName = resp;
                        this.printFromReportPrinter(fileTpPrint, printerName, orientationType);
                    } else {
                        this._alertSer.error("Unable to print as default report printer is not selected.");
                    }
                });
        }
    }

    printFromReportPrinter(fileTpPrint: any, printerName: string, orientationType?: string) {
        let config = null;
        if (orientationType) {
            config = qz.configs.create(printerName, {
                size: { width: 6, height: 3 },
                units: "in",
                orientation: "landscape",
            });
        } else {
            config = qz.configs.create(printerName, {
            });
        }
        const base64string = fileTpPrint && fileTpPrint.includes(",") ? fileTpPrint.split(",")[1] : fileTpPrint;
        const data = [
            {
                type: "image",
                format: "base64",
                data: base64string,
            },
        ];
        this.sendDataToPrinter(config, data, printerName);
    }
   
    printWithConfig(
        fileTpPrint: any,
        printerName: string,
        noOfCopies: number,
        size: any = { width: null, height: null },
        isLanscape: boolean
    ) {
        if (!noOfCopies) {
            noOfCopies = 1;
        }
        if (isLanscape) {
            const newSize = {
                width: size.height,
                height: size.width > 11 ? size.width : 11,
            };
            const config = qz.configs.create(printerName, {
                copies: noOfCopies,
                units: "in",
                size: newSize,
                scaleContent: true,
                orientation: "landscape",
                rasterize: false
            });
            const base64string = fileTpPrint && fileTpPrint.includes(",") ? fileTpPrint.split(",")[1] : fileTpPrint;
            const data = [
                {
                    type: "image",
                    format: "base64",
                    data: base64string
                },
            ];
            // get the status of a specific printer


            this.sendDataToPrinter(config, data, printerName);
        } else {
            const config = qz.configs.create(printerName, {
                copies: noOfCopies,
                units: "in",
                size: size,
                scaleContent: true,
                //orientation: isLanscape ? "landscape" : "portrait",
                orientation: "portrait",
                rasterize: false
            });
            const base64string = fileTpPrint && fileTpPrint.includes(",") ? fileTpPrint.split(",")[1] : fileTpPrint;
            const data = [
                {
                    type: "image",
                    format: "base64",
                    data: base64string
                },
            ];
            this.sendDataToPrinter(config, data, printerName);
        }
    }

    printPdf(fileTpPrint: any, printerName?: any, extension?: string, frmReturntoStock?: boolean, documents?: any, type?: any) {
        let defPrinter = this._commonServ.DecryptData(localStorage.getItem("DefaultReportPrinter"));
        if (defPrinter && this.allPrinters && this.allPrinters.length > 1 && this.allPrinters.find(elm => elm === defPrinter)) {
            printerName = defPrinter;
            this.printPdfFromDefault(fileTpPrint, printerName, extension);
            setTimeout(() => {
                this._commonStore._afterPrint$.next(true)
            }, 3000)
            if (type === "add" && documents.length > 1) {
                documents.shift();
                this._commonStore._labelDocs$.next(documents);
            }
        } else {
            if (!frmReturntoStock) {
                const modelRef = this._modalService.open(DefaultReportPrinterComponent, {
                    centered: true,
                    backdrop: false,
                    keyboard: false
                });
                modelRef.componentInstance.PrinterList = this.allPrinters;
                modelRef.componentInstance.IsPopUpClosed
                    .subscribe(resp => {
                        modelRef.close();
                        if (resp) {
                            printerName = resp;
                            this.printPdfFromDefault(fileTpPrint, printerName, extension);
                            if (type === "add" && documents.length > 1) {
                                documents.shift();
                                this._commonStore._labelDocs$.next(documents);
                                this._commonStore._afterPrint$.next(true)
                            }
                        } else {
                            this._alertSer.error("Unable to print as default report printer is not selected.");
                            this._commonStore._afterPrint$.next(true)
                        }
                    });

            }
        }
    }
    printPdfFromDefault(fileTpPrint: any, printerName: any, extension: string = "pdf") {
        const config = qz.configs.create(printerName, {rasterize: false, duplex: true});
        const base64string = fileTpPrint && fileTpPrint.includes(",") ? fileTpPrint.split(",")[1] : fileTpPrint;
        const data = [
            {
                type: (extension && extension === 'pdf') ? "pdf" : "image",
                format: "base64",
                data: base64string,
            }
        ];
        this.sendDataToPrinter(config, data, printerName);
    }

    sendDataToPrinter(config, data, printerName) {
        this.labelPrinter = 0;
        console.log(this.labelPrinter, "intially");
        /* qz.printers.find(printerName).then(printer => {
             // listen to the printer
             qz.printers.startListening(printer).then(() => {
                 qz.printers.getStatus();
                 this.labelPrinter--;
                 console.log(this.labelPrinter, "labelPrinter");
 
             });
         }).catch(function (e) {
             console.error(e);
         });*/

        if (qz.websocket.isActive() && !this.labelPrinter) {
            qz.print(config, data).catch((err) => err);
            this.triggerLabelPrinterElement();
        } else {
            qz.websocket
                .connect()
                .then(() => {
                    qz.print(config, data).catch((err) => {
                        return err;
                    });
                    this.triggerLabelPrinterElement();
                })
                .catch((err) => {
                    window.location.assign("qz:launch");
                    console.log("Re starting Qz tray");
                    qz.websocket
                        .connect({ retries: 4, delay: 40 })
                        .then(() => {
                            console.log("retrying");
                            qz.print(config, data).catch((err) => {
                                return err;
                            });
                            this.triggerLabelPrinterElement();
                        })
                        .catch((err) => {
                            console.log("catch block");
                        });

                    return err;
                });
        }
    }

    // Disconnect QZ Tray from the browser
    removePrinter(): void {
        qz.websocket.disconnect();
    }

    printBase64Image() {
        qz.websocket
            .connect()
            .then(qz.printers.getDefault)
            .then(function (printer) { })
            .then(qz.websocket.disconnect)
            .catch((err) => err);
    }

    triggerLabelPrinterElement() {
        const labelElement = document.getElementById("labelPrintElement");
        labelElement.style.display = "block";
        setTimeout(() => {
            const labelElement = document.getElementById("labelPrintElement");
            labelElement.style.display = "none";
        }, 30000);
    }

    openQzInstallSteps() {
        if (!this.modelRef) {
            this.modelRef = this._modalService.open(QzTrayStepsComponent, {
                centered: true,
                backdrop: false,
                keyboard: false,
                size: "lg",
            });
            this.modelRef.componentInstance.CloseModal.subscribe((resp) => {
                if (this.modelRef) {
                    this.modelRef.close();
                    this.modelRef = null;
                }
            });
            //alert(isOldVersionInstalled);
            //this.modelRef.IsFromOldVersion = isOldVersionInstalled;
        }
    }
    openReleaseNotes(val : boolean) {
        if (!this.modelRefReleaseNotes) {
            this.modelRefReleaseNotes = this._modalService.open(ReleaseNotesComponent, {
                centered: true,
                backdrop: false,
                keyboard: false,
                size: "lg",
            });
            this.modelRefReleaseNotes.componentInstance.CloseReleaseModal.subscribe((resp) => {
                this.modelRefReleaseNotes.close();
                this.modelRefReleaseNotes = null;
                if(val){
                    this.openQzInstallSteps();
                }
            });
        }
    }
    openRelaseNotesAfterBuild(){
        this.userId = this._userService.getToken("UserId");
        this.userIdInt = parseInt(this.userId)
        this._commonServ.getAppVersion(true).subscribe(resp => {
            if (resp && resp.version) {
                this.jVersion = resp.version;
            }
        });
        this._userService.getBuildVersion(true).subscribe((resp)=>{
            if(resp && resp.UserId === this.userIdInt){
                if(resp.BuildVersion !== this.jVersion && !resp.ShowReleasesAfterLogin){
                    this.openReleaseNotes(false);
                }
            }
        })  
    }
}
