<div class="col-sm-12  erx--body">
    <div class="row">
        <div class="col-md-8">
            <div class="box-default mb-2">
                <div class="eprx--block__header">
                    <span class="eprx--header__heading">
                        Rx Info
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>