<div *ngIf="!openAsModal" class="exprx--common-block Notes_display">
    <div class="row content--heading mb-2" *ngIf="parentPage === 'RxAnnotation'">
        <div class="col-md-8 padding-0">
            <div class="row">
                <div class="icon">
                    <img src="assets/dist/Icons/Icon_rx2.png">
                </div>
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title padding-0"> Notes
                        <span class="content--name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-4 padding-0">
            <button class="hotkey_success" (click)="previousPage()" id="cancelButton" appShortcutKey [AltKey]="'c'">
                <span>C</span>Cancel
            </button>
        </div>
    </div>
    <div class="eprx--block__header">
        <div class="row">
            <div class="col">
                <span class="eprx--header__heading">{{notesInfoFor ? "Notes for "+ (notesInfoFor | uppercase) :
                    parentPage + " Notes"}}
                </span>
            </div>
            <div class="col pull-right text-right">
                <!--- this Button will be  enable for Patient screen  if privillege there for ADD-->
                <button class="hotkey_primary--Override" aria-hidden="true" triggers="mouseover:mouseleave"
                    [autoClose]="true" placement="top" tooltipClass="auto-title-tooltip" autofocus *ngIf="
                        parentPage === 'Patient' &&
                        hasADDPatNotesPrivs
                    " (click)="enableAddButton() ? '' : addNewNotes()" [ngbTooltip]="enableAddButton() ? addbuttonHover.Add_Notes_Disabled : 
                    addbuttonHover.Add_Notes_Enabled" [ngClass]="(enableAddButton())? 'inactive' : 'hotkey_success'"
                    appShortcutKey [AltKey]="'a'" (keydown.tab)="focusOutFrmAdd($event)"><span>A</span>ADD NOTES
                </button>
                <!--- this Button will enable for Prescriber screen  if privillege there for ADD-->
                <button class=" hotkey_primary--Override" aria-hidden="true" autofocus triggers="mouseover:mouseleave"
                    [autoClose]="true" placement="top" tooltipClass="auto-title-tooltip" [ngbTooltip]="enableAddButton() ? addbuttonHover.Add_Notes_Disabled : 
                    addbuttonHover.Add_Notes_Enabled" *ngIf="
                        parentPage === 'Prescriber' && hasADDPrescNotesPrivs"
                    (click)="enableAddButton() ? '' : addNewNotes()" appShortcutKey [AltKey]="'a'"
                    (keydown.tab)="focusOutFrmAdd($event)"
                    [ngClass]="enableAddButton()? 'inactive' : 'hotkey_success'"><span>A</span>ADD NOTES </button>
                 <!--- this Button will enable for Drug screen  if privillege   there for ADD-->
                 <button class="hotkey_primary--Override" aria-hidden="true"  autofocus 
                 [autoClose]="true" placement="top" tooltipClass="auto-title-tooltip" [ngbTooltip]="enableAddButton() ? addbuttonHover.Add_Notes_Disabled : 
                    addbuttonHover.Add_Notes_Enabled"
                 *ngIf="
                 parentPage === 'Drug' &&
                 hasADDDrugNotesPrivs
             "  (click)="enableAddButton() ? '' : addNewNotes()" [ngClass]="enableAddButton()? 'inactive' : 'hotkey_success'"
                     appShortcutKey [AltKey]="'a'" (keydown.tab)="focusOutFrmAdd($event)"><span>A</span>ADD NOTES
                 </button>
                <!--- this Button will be  enable for Insurance if privillege there for ADD-->
                <button class="hotkey_primary--Override" [ngClass]="enableAddButton()? 'inactive':'hotkey_success'"
                    triggers="mouseover:mouseleave" [autoClose]="true" placement="top" tooltipClass="auto-title-tooltip"
                    [ngbTooltip]="enableAddButton() ? addbuttonHover.Add_Notes_Disabled : 
                    addbuttonHover.Add_Notes_Enabled" aria-hidden="true" autofocus *ngIf="
                        parentPage === 'Insurance' &&
                        hasADDInsuNotesPrivs
                    " (click)="enableAddButton() ? '' : addNewNotes()" appShortcutKey [AltKey]="'a'"
                    (keydown.tab)="focusOutFrmAdd($event)"><span>A</span>ADD NOTES</button>
                 <!--- this Button will be  enable for Rx Annotation-->
                    <button [ngClass]="enableAddButton()? 'inactive' : 'hotkey_success'" class="hotkey_primary--Override"
                    aria-hidden="true" autofocus 
                    *ngIf="parentPage === 'RxAnnotation'" triggers="mouseover:mouseleave" [autoClose]="true" placement="top" 
                    tooltipClass="auto-title-tooltip"
                    [ngbTooltip]="enableAddButton() ? addbuttonHover.Add_Notes_Disabled : 
                    addbuttonHover.Add_Notes_Enabled"
                    (click)="enableAddButton() ? '' : addNewNotes()"  appShortcutKey [AltKey]="'a'"
                    (keydown.tab)="focusOutFrmAdd($event)"><span>A</span>ADD NOTES </button>

                <!--- this Button will enable for Patient screen  if there is no privillege  for ADD-->
                <button class=" hotkey_primary--Override" aria-hidden="true" autofocus title="User has No privilege"
                    (click)="openOveride(null, null, 'PatientNotesLog', 'Add','POST_Utility_NotesLog')" *ngIf="
                    parentPage === 'Patient' &&
                    !hasADDPatNotesPrivs
                " appShortcutKey [AltKey]="'a'" [ngClass]="enableAddButton()? 'inactive' : 'hotkey_success'"
                    (keydown.tab)="focusOutFrmAdd($event)"><span>A</span>ADD NOTES </button>
                <!--- this Button will enable for Prescriber screen  if privillege is not  there for ADD-->
                <button class="hotkey_primary--Override" aria-hidden="true" autofocus title="User has No privilege"
                    (click)="openOveride(null, null, 'PrescriberNotesLog', 'Add','POST_Utility_NotesLog')" *ngIf="
                        parentPage === 'Prescriber' &&
                        !hasADDPrescNotesPrivs
                    " appShortcutKey [AltKey]="'a'" [ngClass]="enableAddButton()? 'inactive' : 'hotkey_success'"
                    (keydown.tab)="focusOutFrmAdd($event)"><span>A</span>ADD NOTES </button>
                <!--- this Button will enable for Drug screen  if privillege is not  there for ADD-->
                <button class="hotkey_success hotkey_primary--Override" aria-hidden="true" autofocus
                    title="User has No privilege"
                    (click)="openOveride(null, null, 'DrugNotesLog', 'Add','POST_Utility_NotesLog')" *ngIf="
                        parentPage === 'Drug' &&
                        !hasADDDrugNotesPrivs
                    " appShortcutKey [AltKey]="'a'" (keydown.tab)="focusOutFrmAdd($event)"><span>A</span>ADD</button>
                <!--- this Button will enable for Erx  screen  if privillege is not  there for ADD-->
                <button class=" hotkey_primary--Override" [ngClass]="enableAddButton()? 'inactive' : 'hotkey_success'"
                    aria-hidden="true" autofocus title="Add" *ngIf="parentPage === 'Erx'"
                    (click)="setAddMode(); resetForm()" appShortcutKey [AltKey]="'a'"
                    (keydown.tab)="focusOutFrmAdd($event)"><span>A</span>ADD NOTES </button>
                 <!--- Export Icon-->
                <i class="far fa-file-export text-white add--icon notes_export" aria-hidden="true" title="Export"
                    (click)="openModalLG(printNotes)"></i>
            </div>
        </div>
    </div>
    <div class="eprx--block__content erx--body mt-0">
        <div class="row" *ngIf="(isDataExists ||  this.showAdd[0])">
            <div class="col-md-12">
                <ul class="row notes-log-content notesList_scroll">
                    <li class="col-4" [ngClass]="indexOfelement > 3 ? 'mt-2':''"
                        *ngFor="let noteItem of notesLogFormattedData;let indexOfelement=index;">
                        <div class="card border_10 border_transparent ">
                            <div class="card-header border_card_top  height_35"
                                [ngClass]="showAdd[indexOfelement]?'':(showEdit[indexOfelement]?'edit_Note ':'light_orange')">
                                <div class="row">
                                    <div class="col-10" [ngClass]="showAdd[indexOfelement] ? 'd-none' : ''">
                                        <div class="row col">
                                            <div class="ellipsis_longText" [title]="noteItem?.categoryName | uppercase">
                                                {{noteItem?.categoryName | uppercase}}
                                            </div>
                                            <span *ngIf="!showAdd[indexOfelement]">&nbsp; | &nbsp;</span>
                                            <div class="">
                                                <span *ngIf="IsFromRxSave">
                                                    {{noteItem?.ModifiedDtTm | date : 'MM/dd/yyyy hh:mm a'}}
                                                </span>
                                                <span *ngIf="!IsFromRxSave">
                                                    {{noteItem?.ModifiedDtTm | localTime | date : 'MM/dd/yyyy hh:mm a'}}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-2 text-right pl-0"
                                        [ngClass]="showAdd[indexOfelement]?'offset-10':''">
                                        <span class="cross-btn">
                                            <i [ngClass]="showTrackChanges[indexOfelement] ? 'text-success' : 'lightGray_color'"
                                                class="far   fa-history actions actions--Highlightfocus--icon"
                                                *ngIf="noteItem?.IsNoteUpdate && !IsFromRxSave  && !showAdd[indexOfelement] && !showEdit[indexOfelement]"
                                                title="Notes History"
                                                (click)="getNoteTrackChanges(noteItem,indexOfelement)"></i>
                                        </span>

                                        <span class="cross-btn left-edit">
                                            <!--Edit Note for RxAnnotation if privillege is there for Rx-->
                                            <i class="far fa-edit actions lightGray_color" title="Edit"
                                                *ngIf="(parentPage === 'RxAnnotation') && hasChangRxNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]"
                                                (click)="setEditMode(indexOfelement, noteItem);
                                                                resetForm()
                                                            "></i>
                                              <!--Edit Note for RxAnnotation if privillege is not there for Rx-->
                                            <i class="far fa-edit actions lightGray_color" title="User has No privilege"
                                                (click)="openOveride(null, noteItem, 'RxNotesLog', 'Edit','PUT_Utility_NotesLog')"
                                                *ngIf="
                                                                (parentPage === 'Rx' || parentPage === 'Annotation' ||  parentPage === 'RxAnnotation') &&
                                                                !hasChangRxNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]
                                                            "></i>
                                            <!--Edit Note for Insurance if privillege is  there for Insurance-->
                                            <i class="far fa-edit actions lightGray_color" title="Edit" *ngIf="
                                                            parentPage === 'Insurance' &&
                                                            hasChangInsNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]
                                                        " (click)="
                                                            setEditMode(indexOfelement, noteItem);
                                                            resetForm()
                                                        "></i>
                                            <!--Edit Note for Insurance if privillege is not there for Insurance-->
                                            <i class="far fa-edit actions lightGray_color" title="User has No privilege"
                                                (click)="openOveride(null, noteItem, 'InsuranceNotesLog', 'Edit','PUT_Utility_NotesLog')"
                                                *ngIf="
                                                            parentPage === 'Insurance' &&
                                                            !hasChangInsNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]
                                                        "></i>
                                            <!--Edit Note for Drug if privillege is there for Drug-->
                                            <i class="far fa-edit actions lightGray_color" title="edit" *ngIf="
                                                            parentPage === 'Drug' &&
                                                            hasChangDrugNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]
                                                        " (click)="setEditMode(indexOfelement, noteItem);
                                                            resetForm()
                                                        "></i>
                                            <!--Edit Note for Drug if privillege is not there for Drug-->
                                            <i class="far fa-edit actions lightGray_color" title="User has No privilege"
                                                (click)="openOveride(null, noteItem, 'DrugNotesLog', 'Edit','PUT_Utility_NotesLog')"
                                                *ngIf="
                                                            parentPage === 'Drug' &&
                                                            !hasChangDrugNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]
                                                        "></i>
                                            <!--Edit Note for Patient if privillege is  there for Patient-->
                                            <i class="far fa-edit actions lightGray_color" title="Edit" *ngIf="
                                                            parentPage === 'Patient' &&
                                                            hasChangPatNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]
                                                        " (click)="
                                                            setEditMode(indexOfelement, noteItem);
                                                            resetForm()
                                                        "></i>
                                            <!--Edit Note for Patient if privillege is not  there for Patient-->
                                            <i class="far fa-edit actions lightGray_color" title="User has No privilege"
                                                (click)="openOveride(null, noteItem, 'PatientNotesLog', 'Edit','PUT_Utility_NotesLog')"
                                                *ngIf="
                                                            parentPage === 'Patient' &&
                                                            !hasChangPatNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]
                                                        "></i>
                                             <!--Edit Note for Prescriber if privillege is  there for Prescriber-->
                                            <i class="far fa-edit actions lightGray_color" title="Edit" *ngIf="
                                                            parentPage === 'Prescriber' &&
                                                            hasChangPresNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]
                                                        " (click)="
                                                            setEditMode(indexOfelement, noteItem);
                                                            resetForm()
                                                        "></i>
                                             <!--Edit Note for Prescriber if privillege is  not  there for Prescriber-->
                                            <i class="far fa-edit actions lightGray_color" title="User has No privilege"
                                                (click)="openOveride(null, noteItem, 'PrescriberNotesLog', 'Edit','PUT_Utility_NotesLog')"
                                                *ngIf="
                                                            parentPage === 'Prescriber' &&
                                                            !hasChangPresNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]
                                                        "></i>
                                             <!--Edit Note for eRx without any privillege check for  Erx-->
                                            <i class="far fa-edit actions lightGray_color" title="Edit"
                                                *ngIf="parentPage === 'Erx' && !showAdd[indexOfelement] && !showEdit[indexOfelement]"
                                                (click)="
                                                            setEditMode(indexOfelement, noteItem);
                                                            resetForm()
                                                        "></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body   p-0 p-2"
                                [ngClass]="(showAdd[indexOfelement]) ? ' add_Note_border height_285 border_card_bottom_forAdd' : (showEdit[indexOfelement]?'bg-white border_card_bottom_forAdd edit_Note_border height_285':'light_border  lightColor_orange  height_202')">
                                <div *ngIf="!showEdit[indexOfelement] && !showAdd[indexOfelement]">
                                    <div class="p-2" [innerHTML]="noteItem.note"></div>
                                    <div *ngIf="notesTrackHistory?.length && showTrackChanges[indexOfelement]">
                                        <ul class="list-unstyled noteTrackHistory">
                                            <div *ngFor="let x of notesTrackHistory" class="track">
                                                <li [innerHTML]="x.NoteContent">
                                                </li>
                                                <div class="row">
                                                    <div class="col-6 pr-0">
                                                        Updated By: {{x.UserName}}
                                                    </div>
                                                    <div class="col-6 px-0">
                                                        Updated on: {{x.ModifiedDtTm | date : 'MM/dd/yyyy hh:mm a'}}
                                                    </div>
                                                </div>
                                            </div>

                                        </ul>
                                    </div>
                                </div>
                                <div *ngIf="showEdit[indexOfelement] && !showAdd[indexOfelement]">
                                    <div [formGroup]="notesLogFG">
                                        <div formArrayName="Note">
                                            <div class="row" *ngFor="
                                                        let notefg of Note?.controls;
                                                        let i = index
                                                    ">

                                                <div *ngIf="showEdit[indexOfelement]" class="col-5">
                                                    <eprx-select [RxSelectId]="'notesLogCategry'"
                                                        [LabelText]="'Subcategory'" [IsTitle]="true" [AddItem]="true"
                                                        [SelectOnTab]="true" [PlaceHolder]="'Category'"
                                                        [ControlName]="'NoteCatId'" [AutoFocus]="true"
                                                        [IsDisabled]="showEdit[indexOfelement] ? true :  false"
                                                        [FormGroupName]="notefg" [addItemMaxLength]="15"
                                                        [List]="(NotesFr === 'RxAnnotation' && showAdd[indexOfelement]) ? notesSubcatList : notesLogInfo?.NoteCat"
                                                        [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'"
                                                        [ErrorDefs]="{required: 'Required'}"
                                                        (TriggerSelectValue)="addedCateg($event?.value)"
                                                        [IsRequired]="true">
                                                    </eprx-select>
                                                </div>
                                                <div *ngIf="showAdd[indexOfelement]" class="col-5">
                                                    <eprx-select [RxSelectId]="'notesLogCategry'" [AddItem]="true"
                                                        [SelectOnTab]="true" [LabelText]="'Subcategory'"
                                                        [PlaceHolder]="''" [IsTitle]="true" [addItemMaxLength]="15"
                                                        [IsDisabled]="showEdit[indexOfelement] ? true :  false"
                                                        [ControlName]="'NoteCatId'" [FormGroupName]="notefg"
                                                        [List]="(NotesFr === 'RxAnnotation' && showAdd[indexOfelement]) ? notesSubcatList : notesLogInfo?.NoteCat"
                                                        [BindLabel]="'Name'"
                                                        [BindValue]="'Id'"
                                                        (TriggerOnTabbing)="focusOutFromSelect($event)"
                                                        [LabelForId]="'Name'" [ErrorDefs]="{required: 'Required'}"
                                                        [IsRequired]="true" [IsFirstElem]="true" [secondCntrlN]="'Name'"
                                                        [bindScnCntrlV]="'Name'"
                                                        [IsDisabled]="showEdit[indexOfelement] ? true: false"
                                                        (TriggerSelectValue)="addedCateg($event?.value)">
                                                    </eprx-select>
                                                </div>

                                                <!-- <div class="col-3  pr-0" *ngIf="showEdit[indexOfelement] && parentPage != 'RxAnnotation'">
                                                        <eprx-toggle [ControlName]="'IsShownAsPopup'" [FormGroupName]="notefg" [AutoFocus]="true" [IsChecked] = "notefg?.get('IsShownAsPopup').value" [RxselectId]="'noteLog'+ indexOfelement"   [LabelText]="'Show as Pop-up?'"
                                                        [IsFrom]="'noteLog'">
                                                        </eprx-toggle>
                                                    </div>
                                                    <div class="col-3  pr-0" *ngIf="showAdd[indexOfelement]  && parentPage != 'RxAnnotation'"> 
                                                        <eprx-toggle [ControlName]="'IsShownAsPopup'" [FormGroupName]="notefg" [AutoFocus]="true" [IsChecked] = "notefg?.get('IsShownAsPopup').value" [RxselectId]="'noteLog'+ indexOfelement" [IsFrom]="'noteLog'" [LabelText]="'Show as Pop-up?'">
                                                        </eprx-toggle>
                                                    </div>   -->
                                                <div class="col-7">
                                                    <eprx-select [RxSelectId]="'popupscreen'"
                                                        [LabelText]="'Popup Screen'" [IsTitle]="true"
                                                        [PlaceHolder]="''"
                                                        [ControlName]="'PopupScreenCodes'" [AutoFocus]="true"
                                                        [HasMultiple]="true" [FormGroupName]="notefg"
                                                        [List]="(parentPage !== 'RxAnnotation') ? popUpScreenList: [{Name:'All', Id: 0, type:'all'}, {Name: 'Edit Rx', Id: 4, type:'er'}, 
                                                            {Name:'RPh Vrf', Id:3, type:'rph'}, {Name: 'DPV', Id: 5, type:'dpv'}]" [BindLabel]="'Name'"
                                                        [BindLabel]="'Name'" [BindValue]="'type'" [LabelForId]="'Name'"
                                                        (TriggerSelectValue)="filterAllinList(notefg , PopupScreenCodes)">
                                                    </eprx-select>

                                                </div>
                                                <div class="col-12" [formGroup]="notefg">
                                                    <!-- <eprx-text-area [LabelText]="'Notes'" [ControlName]="'xNote'"
                                                    
                                                    <div class="col-12">
                                                        <eprx-text-area [LabelText]="'Notes'" [ControlName]="'xNote'"
                                                            [FormGroupName]="notefg" [PlaceHolder]="'Notes'" [Rows]="4"
                                                            [IsRequired]="true" [InputErrors]="notefg['controls'].xNote?.errors" [MaxLength]="4000" [IsFromNotes]="true"
                                                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="IsSubmitted">
                                                        </eprx-text-area> -->

                                                    <angular-editor formControlName="xNote"
                                                        [config]="editorConfig"></angular-editor>
                                                    <span class="text-danger"
                                                        *ngIf="(IsSubmitted && !(notefg['controls'].xNote.value))">Required
                                                    </span>
                                                </div>
                                                <div class="col-5 mt-2" [ngClass]="!(showEdit[indexOfelement] || showAdd[indexOfelement])?'d-none':(
                                                        (IsSubmitted && !(notefg['controls'].xNote.value)) ? '' : 'mt-4'
                                                    )">
                                                    <span *ngIf="false"> Pop-up:
                                                        {{noteItem.IsShownAsPopup ? "Y" : "N"}} |</span> By:
                                                    {{noteItem.usename | uppercase}}
                                                </div>
                                                <div class="col-7 mt-2  text-right"
                                                    [ngClass]="(IsSubmitted && !(notefg['controls'].xNote.value)) ? '' : 'mt-4' ">
                                                    <button *ngIf="showAdd[indexOfelement]" type="button"
                                                        class="col-5 btn btn-sm btn-success " (click)="addNote()">
                                                        SAVE
                                                    </button>
                                                    <button *ngIf="showEdit[indexOfelement]" type="button"
                                                        class="col-5 btn btn-sm btn-success "
                                                        (click)="updateNote(indexOfelement)">
                                                        Update
                                                    </button>
                                                    &nbsp;
                                                    <button type="button" class="col-5 btn  btn-sm btn-gray text-white "
                                                        (click)="cancelNote(indexOfelement, this.showAdd[indexOfelement]);clearNotes()">
                                                        CANCEL
                                                    </button>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showAdd[indexOfelement]">
                                    <div [formGroup]="notesLogFG">
                                        <div formArrayName="Note">
                                            <div class="row" *ngFor="let notefg of Note?.controls; let i = index">
                                                <div class="col-5">
                                                    <eprx-select [RxSelectId]="'notesLogCategry'" [AddItem]="true"
                                                        [SelectOnTab]="true" [LabelText]="'Subcategory'"
                                                        [PlaceHolder]="''" [IsTitle]="true" [addItemMaxLength]="15"
                                                        [IsDisabled]="showEdit[indexOfelement] ? true :  false"
                                                        [ControlName]="'NoteCatId'" [FormGroupName]="notefg"
                                                        [List]="(NotesFr === 'RxAnnotation' && showAdd[indexOfelement]) ? notesSubcatList : notesLogInfo?.NoteCat"
                                                        [AutoFocus]="true"
                                                        [BindLabel]="'Name'" [BindValue]="'Id'"
                                                        (TriggerOnTabbing)="focusOutFromSelect($event)"
                                                        [LabelForId]="'Name'" [ErrorDefs]="{required: 'Required'}"
                                                        [IsRequired]="true" [IsFirstElem]="true" [secondCntrlN]="'Name'"
                                                        [bindScnCntrlV]="'Name'"
                                                        (TriggerSelectValue)="addedCateg($event?.value)">
                                                    </eprx-select>
                                                </div>

                                                <!-- <div class="col-3  pr-0" *ngIf="parentPage != 'RxAnnotation'">
                                                        <eprx-toggle [ControlName]="'IsShownAsPopup'" [FormGroupName]="notefg" [AutoFocus]="true" [IsChecked] = "notefg?.get('IsShownAsPopup').value" [RxselectId]="'noteLog'+ i" [IsFrom]="'noteLog'" [LabelText]="'Show as Pop-up?'">
                                                        </eprx-toggle>
                                                    </div>   -->
                                                <div class="col-7">
                                                    <eprx-select [RxSelectId]="'popupscreen'"
                                                        [LabelText]="'Popup Screen'" [IsTitle]="true"
                                                        [HasMultiple]="true" [PlaceHolder]="''"
                                                        [ControlName]="'PopupScreenCodes'" [FormGroupName]="notefg"
                                                        [List]="(parentPage !== 'RxAnnotation') ? popUpScreenList: [{Name:'All', Id: 0, type:'all'}, {Name: 'Edit Rx', Id: 4, type:'er'}, 
                                                            {Name:'RPh Vrf', Id:3, type:'rph'}, {Name: 'DPV', Id: 5, type:'dpv'}]"
                                                        [BindLabel]="'Name'" [BindLabel]="'Name'" [BindValue]="'type'"
                                                        [LabelForId]="'Name'"
                                                        (TriggerSelectValue)="filterAllinList(notefg , PopupScreenCodes)">
                                                    </eprx-select>

                                                </div>
                                            </div>
                                            <div class="row" *ngFor="let notefg of Note?.controls; let i = index">
                                                <div class="col-md-12" [formGroup]="notefg">
                                                    <!-- <eprx-text-area [LabelText]="'Notes'" [ControlName]="'xNote'" [FormGroupName]="notefg" [MaxLength]="4000" [IsFromNotes]="true"
                                                            [PlaceHolder]="'Notes'" [Rows]="4" [IsRequired]="true"
                                                            [InputErrors]="notefg['controls'].xNote?.errors"
                                                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="IsSubmitted"
                                                            (blur)="validate()">
                                                        </eprx-text-area> -->

                                                    <angular-editor formControlName="xNote"
                                                        [config]="editorConfig"></angular-editor>
                                                    <span class="text-danger"
                                                        *ngIf="(IsSubmitted && !(notefg['controls'].xNote.value))">Required
                                                    </span>
                                                </div>
                                                <div class="col-5"
                                                    [ngClass]="(IsSubmitted && !(notefg['controls'].xNote.value)) ? '' : 'mt-4'">
                                                    Notes By: {{userFullName}}
                                                </div>
                                                <div class="col-7  text-right"
                                                    [ngClass]="(IsSubmitted && !(notefg['controls'].xNote.value)) ? '' : 'mt-4'">
                                                    <button type="button" class="col-5 btn btn-sm btn-success "
                                                        (click)="addNote()" *ngIf="showAdd[indexOfelement]"
                                                        appShortcutKey [AltKey]="'s'">
                                                        SAVE
                                                    </button>
                                                    <button *ngIf="showEdit[indexOfelement]" type="button"
                                                        class="col-5 btn btn-sm btn-success"
                                                        (click)="updateNote(indexOfelement)" appShortcutKey
                                                        [AltKey]="'u'">
                                                        UPDATE
                                                    </button> &nbsp;
                                                    <button type="button" class="col-5 btn  btn-sm btn-gray text-white"
                                                        (keydown.tab)="focusOutAdd($event)"
                                                        (click)="cancelNote(indexOfelement, this.showAdd[indexOfelement]);clearNotes()"
                                                        appShortcutKey [AltKey]="'c'">
                                                        CANCEL
                                                    </button>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div [ngClass]="(showEdit[indexOfelement] || showAdd[indexOfelement])?'d-none':''"
                                class="light_border card-footer  border_card_bottom_forAdd lightColor_orange height_35 border-top-0 pt-2 pl-2">
                                <span *ngIf="false"> Pop-up:
                                    {{noteItem.IsShownAsPopup ? "Y" : "N"}} |</span> By: {{noteItem.usename |
                                uppercase}}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="!isDataExists && !this.showAdd[notesLogFormattedData.length-1]" class="eprx--block__footer">
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </div>
    </div>
</div>

<div class="row hide--tag" *ngIf="!openAsModal">
    <wj-flex-grid #notesData [headersVisibility]="'Column'" [itemsSource]="notesLogFilteredWJ" [isReadOnly]="true"
        [columnPicker]="''">
        <wj-flex-grid-column [header]="'Note'" [width]="'2*'" [binding]="'note'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'User'" [width]="'1*'" [binding]="'usename'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Date & Time'" [binding]="'ModifiedDtTm'" [width]="'1*'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Category'" [binding]="'categoryName'" [width]="'2*'">
        </wj-flex-grid-column>
    </wj-flex-grid>
</div>

<!-- Notes  popup modal -->
<ng-template #notesLogModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title"> <span *ngIf="parentPage !== 'RxAnnotation'">Notes for </span>
            {{(parentPage === 'RxAnnotation' ? 'Rx Annotations': (parentPage == 'Erx' ? 'eRx' : parentPage)) }}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="exprx--common-block">
            <div class="eprx--block__header eprx--block__header_height">
                <div class="row">
                    <div class="col pull-right text-right">
                        <!--- this Button will enable for other than eRx  screen without any privillege-->
                        <button class="hotkey_primary--Override"
                            [ngClass]="enableAddButton()? 'inactive' : 'hotkey_success'" aria-hidden="true" title="Add"
                            *ngIf="parentPage !== 'Erx'"
                            triggers="mouseover:mouseleave"
                    [autoClose]="true" placement="top" tooltipClass="auto-title-tooltip" autofocus 
                    (click)="enableAddButton() ? '' : addNewNotes()" [ngbTooltip]="enableAddButton() ? addbuttonHover.Add_Notes_Disabled : 
                    addbuttonHover.Add_Notes_Enabled"
                            appShortcutKey [AltKey]="'a'"><span>A</span>ADD NOTES </button>
                        <!--- this Button will enable for other Erx  screen if privillege is there -->
                        <button class=" hotkey_primary--Override" aria-hidden="true" autofocus
                        (click)="enableAddButton() ? '' : addNewNotes()" [ngbTooltip]="enableAddButton() ? addbuttonHover.Add_Notes_Disabled : 
                    addbuttonHover.Add_Notes_Enabled"  triggers="mouseover:mouseleave"
                    [autoClose]="true" placement="top" tooltipClass="auto-title-tooltip"
                            [ngClass]="(enableAddButton())? 'inactive' : 'hotkey_success'"
                            *ngIf="parentPage === 'Erx' && hasADDErxNotesPrivs" appShortcutKey
                            [AltKey]="'a'"><span>A</span>ADD NOTES </button>
                        <!--- this Button will enable for other Erx  screen if there is no  privillege -->
                        <button class=" hotkey_primary--Override" aria-hidden="true" autofocus
                            title="User has No privilege" [ngClass]="enableAddButton()? 'inactive' : 'hotkey_success'"
                            (click)="openOveride(null, null, 'ERxRphNotes', 'Add','POST_Utility_NotesLog')"
                            *ngIf="parentPage === 'Erx' && !hasADDErxNotesPrivs" appShortcutKey
                            [AltKey]="'a'"><span>A</span>ADD NOTES </button>
                        <i class="notes_export far fa-file-export text-white add--icon ml-2" aria-hidden="true"
                            title="Export" (click)="openModalLG(printNotes)"></i>
                    </div>
                </div>
            </div>
            <div class="eprx--block__content">
                <div *ngIf="parentPage === 'Patient'">
                    <div class="row">
                        <div class="col">
                            <label>Patient</label>
                            <div class="label--data">
                                {{ patientInfo?.lastname }},&nbsp;
                                {{ patientInfo?.firstname }}
                            </div>
                        </div>
                        <div class="col">
                            <label>Address</label>
                            <div class="label--data">
                                {{
                                patientInfo?.address1
                                ? patientInfo?.address1 + ","
                                : ""
                                }}
                                {{
                                patientInfo?.address2
                                ? patientInfo?.address2 + ","
                                : ""
                                }}
                                {{
                                patientInfo?.city
                                ? patientInfo?.city + ","
                                : ""
                                }}
                                {{
                                patientInfo?.state
                                ? patientInfo?.state + ","
                                : ""
                                }}
                                {{
                                patientInfo?.zipcode
                                ? patientInfo?.zipcode + ","
                                : ""
                                }}
                            </div>
                        </div>
                        <div class="col">
                            <label>DOB</label>
                            <div class="label--data">
                                {{ patientInfo?.dob | date: "MM/dd/yyyy" }}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="parentPage === 'Prescriber'">
                    <div class="row">
                        <div class="col">
                            <label>Prescriber</label>
                            <div class="label--data">
                                {{ prescriberInfo?.prescriberlastname }},&nbsp;
                                {{ prescriberInfo?.prescriberfirstname }}
                            </div>
                        </div>
                        <div class="col">
                            <label>Address</label>
                            <div class="label--data">
                                {{PrescAddress?.AddressLine1}},
                                <span *ngIf="PrescAddress?.AddressLine2">{{PrescAddress?.AddressLine2}}, </span>
                                <span *ngIf="PrescAddress?.CityName">{{PrescAddress?.CityName}}, </span>
                                <span *ngIf="PrescAddress?.StateName">{{PrescAddress?.StateName}}, </span>
                                <span *ngIf="PrescAddress?.ZipCode">{{PrescAddress?.ZipCode}} </span>
                            </div>
                        </div>
                        <div class="col">
                            <label>NPI#</label>
                            <div class="label--data">
                                {{prescriberInfo?.prescribernpinum? prescriberInfo?.prescribernpinum : "--"}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="parentPage === 'Drug'">
                    <div class="row">
                        <div class="col">
                            <label>Drug</label>
                            <div class="label--data">
                                {{(drugInfo && drugInfo?.drugname) ? drugInfo?.drugname + " " : "
                                "}}&nbsp;{{drugInfo?.strength}}&nbsp;{{drugInfo?.drugformname}}
                            </div>
                        </div>
                        <div class="col">
                            <label>NDC</label>
                            <div class="label--data">
                                {{ this._formatsUtil.getNDCFormat(drugInfo?.drugcode) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="(parentPage === 'Rx' || parentPage === 'Annotation'  || parentPage === 'RxAnnotation')">
                    <div class="row">
                        <div class="col">
                            <label>Rx#</label>
                            <div class="label--data">
                                {{!IsFromRxSave ? notesInfoFor: '--'}}
                            </div>
                        </div>
                        <div class="col">
                            <label>Patient</label>
                            <div class="label--data">
                                {{ rxInfo?.Patient?.lastname }},&nbsp;
                                {{ rxInfo?.Patient?.firstname }}
                            </div>
                        </div>
                        <div class="col">
                            <label>Drug</label>
                            <div class="label--data">
                                {{(rxInfo?.Drug?.drugname | uppercase)+ ' '}}
                                {{rxInfo?.Drug?.strength? rxInfo?.Drug?.strength :' '}}
                            </div>
                        </div>
                        <div class="col">
                            <label>Date Filled</label>
                            <div class="label--data">
                                {{ rxInfo?.PrescReFill?.FillDtTm | date: "MM/dd/yyyy" }}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="parentPage === 'Erx'">
                    <div class="row">
                        <div class="col">
                            <label>Patient</label>
                            <div class="label--data">
                                {{ erxInfo?.PatientName }}
                            </div>
                        </div>
                        <div class="col">
                            <label>Prescriber</label>
                            <div class="label--data">
                                {{ erxInfo?.PrescriberName }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="eprx--block__content erx--body">
                <div class="row" *ngIf="(totalcount ||  this.showAdd[0])">
                    <div class="col-md-12">
                        <ul class="row notes-log-content notesList_scroll">
                            <li class="col-4" [ngClass]="indexOfelement >= 3 ? 'mt-2':''"
                                *ngFor="let noteItem of notesLogFormattedData; let indexOfelement=index;">
                                <div class="card border_10 border_transparent">
                                    <div class="card-header border_card_top  height_35"
                                        [ngClass]="showAdd[indexOfelement]?'':(showEdit[indexOfelement]?'edit_Note ':'light_orange')">
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="row col">
                                                    <div class="ellipsis_longText"
                                                        [title]="noteItem?.categoryName | uppercase">
                                                        {{noteItem?.categoryName | uppercase}}
                                                    </div> <span *ngIf="!showAdd[indexOfelement]">&nbsp; | &nbsp;</span>
                                                    <div>
                                                        <span *ngIf="IsFromRxSave">
                                                            {{noteItem?.ModifiedDtTm | date : 'MM/dd/yyyy hh:mm a'}}
                                                        </span>
                                                        <span *ngIf="!IsFromRxSave">
                                                            {{noteItem?.ModifiedDtTm | localTime | date : 'MM/dd/yyyy
                                                            hh:mm a'}}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-2 text-right pl-0">
                                                <span class="cross-btn left-edit">

                                                    <i   class="far fa-history actions actions--Highlightfocus--icon " title="
                                                     Notes History"  *ngIf="noteItem?.IsNoteUpdate && !IsFromRxSave && !showAdd[indexOfelement] && !showEdit[indexOfelement]" [ngClass]="showTrackChanges[indexOfelement] ? 'text-success' : 'lightGray_color'"  (click)="getNoteTrackChanges(noteItem, indexOfelement)" ></i>
                                                    
                                                    <i class="far fa-edit actions lightGray_color" title="Edit" *ngIf="(parentPage === 'Rx' || parentPage === 'RxAnnotation' || parentPage === 'Annotation') && hasChangRxNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]"
                                                        (click)="setEditMode(indexOfelement, noteItem);resetForm()"></i>
                                                    <i class="far fa-edit actions lightGray_color"
                                                        title="User has No privilege"
                                                        (click)="openOveride(null, noteItem, 'RxNotesLog', 'Edit','PUT_Utility_NotesLog')"
                                                        *ngIf="(parentPage === 'Rx' || parentPage === 'Annotation' || parentPage === 'RxAnnotation') &&!hasChangRxNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]"></i>
                                                    <i class="far fa-edit actions lightGray_color" title="Edit" *ngIf="
                                                                parentPage === 'Insurance' && hasChangInsNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]" 
                                                                (click)="setEditMode(indexOfelement, noteItem); resetForm()"></i>
                                                    <i class="far fa-edit actions lightGray_color"
                                                        title="User has No privilege"
                                                        (click)="openOveride(null, noteItem, 'InsuranceNotesLog', 'Edit','PUT_Utility_NotesLog')"
                                                        *ngIf="
                                                                parentPage === 'Insurance' && !hasChangInsNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]"></i>
                                                    <i class="far fa-edit actions lightGray_color" title="edit" *ngIf="
                                                                parentPage === 'Drug' && hasChangDrugNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]" 
                                                                (click)="setEditMode(indexOfelement, noteItem);resetForm()"></i>
                                                    <i class="far fa-edit actions lightGray_color"
                                                        title="User has No privilege"
                                                        (click)="openOveride(null, noteItem, 'DrugNotesLog', 'Edit','PUT_Utility_NotesLog')"
                                                        *ngIf="
                                                                parentPage === 'Drug' &&!hasChangDrugNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]"></i>
                                                    <i class="far fa-edit actions lightGray_color" title="Edit" *ngIf="
                                                                parentPage === 'Patient' && hasChangPatNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]" (click)="setEditMode(indexOfelement, noteItem);resetForm()"></i>
                                                    <i class="far fa-edit actions lightGray_color"
                                                        title="User has No privilege"
                                                        (click)="openOveride(null, noteItem, 'PatientNotesLog', 'Edit','PUT_Utility_NotesLog')"
                                                        *ngIf="parentPage === 'Patient' && !hasChangPatNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]"></i>
                                                    <i class="far fa-edit actions lightGray_color" title="Edit" *ngIf="parentPage === 'Prescriber' &&hasChangPresNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]" 
                                                    (click)="setEditMode(indexOfelement, noteItem); resetForm()"></i>
                                                    <i class="far fa-edit actions lightGray_color"
                                                        title="User has No privilege"
                                                        (click)="openOveride(null, noteItem, 'PrescriberNotesLog', 'Edit','PUT_Utility_NotesLog')"
                                                        *ngIf="
                                                                parentPage === 'Prescriber' && !hasChangPresNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]"></i>
                                                    <i class="far fa-edit actions lightGray_color" title="Edit"
                                                        *ngIf="parentPage === 'Erx' &&hasEditErxNotesPrivs && !showAdd[indexOfelement]"
                                                        (click)="
                                                                setEditMode(indexOfelement, noteItem);resetForm()"></i>
                                                    <i class="far fa-edit actions lightGray_color"
                                                        title="User has No privilege"
                                                        *ngIf="parentPage === 'Erx' && !hasEditErxNotesPrivs && !showAdd[indexOfelement] && !showEdit[indexOfelement]"
                                                        (click)="openOveride(null, noteItem, 'ERxRphNotes', 'Edit','PUT_Utility_NotesLog')"></i>
                                                </span>
                                                <span class="cross-btn">

                                                    <!-- <i class="far fa-times actions lightGray_color" title="Remove" 
                                                        (click)="IsSubmitted = false;notesLogFormattedData.splice(indexOfelement,1);clearNotes()" *ngIf="showAdd[indexOfelement]"></i> -->
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body   p-0 p-2"
                                        [ngClass]="(showAdd[indexOfelement]) ? ' add_Note_border height_285 border_card_bottom_forAdd' : (showEdit[indexOfelement]?'bg-white border_card_bottom_forAdd edit_Note_border height_285':'light_border  lightColor_orange  height_202')">
                                        <div *ngIf="!showEdit[indexOfelement] && !showAdd[indexOfelement]">
                                            <div class="p-2" [innerHTML]="noteItem?.note"></div>
                                            <div *ngIf="notesTrackHistory?.length && showTrackChanges[indexOfelement]">
                                                <ul class="list-unstyled noteTrackHistory">
                                                    <div *ngFor="let x of notesTrackHistory" class="track">
                                                        <li [innerHTML]="x.NoteContent">
                                                        </li>
                                                        <div class="row mt-20">
                                                            <div class="col-6 pr-0">
                                                                <spa>Updated By:</spa> {{x.UserName | uppercase}}
                                                            </div>
                                                            <div class="col-6 px-0">
                                                                <span>Updated on:</span> {{x.ModifiedDtTm | localTime |
                                                                date : 'MM/dd/yyyy hh:mm a'}}
                                                            </div>
                                                        </div>

                                                    </div>

                                                </ul>
                                            </div>
                                        </div>
                                        <div *ngIf="showEdit[indexOfelement] && !showAdd[indexOfelement]">
                                            <div [formGroup]="notesLogFG">
                                                <div formArrayName="Note">
                                                    <div class="row" *ngFor="
                                                            let notefg of Note?.controls;
                                                            let i = index
                                                        ">
                                                        <div class="col-5" *ngIf="showEdit[indexOfelement]">
                                                            <eprx-select [RxSelectId]="'notesLogCategry'"
                                                                [AddItem]="true" [SelectOnTab]="true"
                                                                [LabelText]="'Subcategory'" [IsTitle]="true"
                                                                [PlaceHolder]="''" [ControlName]="'NoteCatId'"
                                                                [AutoFocus]="true" [addItemMaxLength]="15"
                                                                [IsDisabled]="showEdit[indexOfelement] ? true :  false"
                                                                [FormGroupName]="notefg" 
                                                                [List]="(NotesFr === 'RxAnnotation' && showAdd[indexOfelement]) ? notesSubcatList : notesLogInfo?.NoteCat"
                                                                [BindLabel]="'Name'" [BindValue]="'Id'"
                                                                [LabelForId]="'Name'"
                                                                [ErrorDefs]="{required: 'Required'}"
                                                                [IsRequired]="true">
                                                            </eprx-select>
                                                        </div>
                                                        <div   class="col-5" *ngIf="showAdd[indexOfelement]">
                                                            <eprx-select [RxSelectId]="'notesLogCategry'" [AddItem]="true" [SelectOnTab]="true" [LabelText]="'Subcategory'" [PlaceHolder]="''" [IsTitle]="true"  [IsDisabled]="showEdit[indexOfelement] ? true :  false" 
                                                                [ControlName]="'NoteCatId'" [FormGroupName]="notefg" [addItemMaxLength]="15"
                                                                [List]="(NotesFr === 'RxAnnotation' && showAdd[indexOfelement]) ? notesSubcatList : notesLogInfo?.NoteCat"
                                                                [BindLabel]="'Name'" [BindValue]="'Id'" (TriggerOnTabbing)="focusOutFromSelect($event)"
                                                                [LabelForId]="'Name'" [ErrorDefs]="{required: 'Required'}" [IsRequired]="true"
                                                                [IsFirstElem]="true" [secondCntrlN]="'Name'" [bindScnCntrlV]="'Name'"
                                                                (TriggerSelectValue)="addedCateg($event?.value)">
                                                            </eprx-select>
                                                        </div>

                                                        <!-- <div class="col-3  pr-0" *ngIf="showEdit[indexOfelement]  && parentPage != 'RxAnnotation'">
                                                            <eprx-toggle [ControlName]="'IsShownAsPopup'" [FormGroupName]="notefg" [AutoFocus]="true" [IsChecked] = "notefg?.get('IsShownAsPopup').value" [RxselectId]="'noteLog'+ indexOfelement"   [LabelText]="'Show as Pop-up?'"
                                                            [IsFrom]="'noteLog'">
                                                            </eprx-toggle>
                                                        </div>
                                                        <div class="col-3  pr-0" *ngIf="showAdd[indexOfelement]  && parentPage != 'RxAnnotation'"> 
                                                            
                                                            <eprx-toggle [ControlName]="'IsShownAsPopup'" [FormGroupName]="notefg" [AutoFocus]="true" [IsChecked] = "notefg?.get('IsShownAsPopup').value" [RxselectId]="'noteLog'+ indexOfelement" [IsFrom]="'noteLog'"  
                                                            [LabelText]="'Show as Pop-up?'">
                                                            </eprx-toggle>
                                                        </div>   -->

                                                        <div class="col-7 pl-0">
                                                            <eprx-select [RxSelectId]="'popupscreen'"
                                                                [LabelText]="'Popup Screen'" [IsTitle]="true"
                                                                [PlaceHolder]="''"
                                                                [ControlName]="'PopupScreenCodes'" [AutoFocus]="true"
                                                                [HasMultiple]="true" [FormGroupName]="notefg"
                                                                [List]="(parentPage !== 'RxAnnotation') ? popUpScreenList: [{Name:'All', Id: 0, type:'all'}, {Name: 'Edit Rx', Id: 4, type:'er'}, {Name:'RPh Vrf', Id:3, type:'rph'}, {Name: 'DPV', Id: 5, type:'dpv'}]"
                                                                [BindLabel]="'Name'" [BindValue]="'type'"
                                                                [LabelForId]="'Name'"
                                                                (TriggerSelectValue)="filterAllinList(notefg , PopupScreenCodes)">
                                                            </eprx-select>
                                                        </div>
                                                        <div class="col-12" [formGroup]="notefg">
                                                            <!-- <eprx-text-area [LabelText]="'Notes'" [ControlName]="'xNote'"
                                                        
                                                        <div class="col-12">
                                                            <eprx-text-area [LabelText]="'Notes'" [ControlName]="'xNote'"
                                                                [FormGroupName]="notefg" [PlaceHolder]="'Notes'" [Rows]="4" [MaxLength]="4000" [IsFromNotes]="true"
                                                                [IsRequired]="true" [InputErrors]="notefg['controls'].xNote?.errors"
                                                                [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="IsSubmitted">
                                                            </eprx-text-area> -->

                                                            <angular-editor formControlName="xNote"
                                                                [config]="editorConfig"></angular-editor>
                                                            <span class="text-danger"
                                                                *ngIf="(IsSubmitted && !(notefg['controls'].xNote.value))">Required
                                                            </span>
                                                        </div>
                                                        <div class="col-5 mt-2"
                                                            [ngClass]="!(showEdit[indexOfelement] || showAdd[indexOfelement])?'d-none': ( (IsSubmitted && !(notefg['controls'].xNote.value)) ? '' : 'mt-4')">
                                                            <span *ngIf="false">Pop-up:
                                                                {{noteItem.IsShownAsPopup ? "Y" : "N"}} |</span> By:
                                                            {{noteItem.usename | uppercase}}
                                                        </div>
                                                        <div class="col-7   text-right"
                                                            [ngClass]="(IsSubmitted && !(notefg['controls'].xNote.value)) ? '' : 'mt-4' ">
                                                            <button *ngIf="showAdd[indexOfelement]" type="button"
                                                                class="col-5 btn btn-sm btn-success "
                                                                (click)="addNote()">
                                                                SAVE
                                                            </button>
                                                            <button *ngIf="showEdit[indexOfelement]" type="button"
                                                                class="col-5 btn btn-sm btn-success "
                                                                (click)="updateNote(indexOfelement)">
                                                                UPDATE
                                                            </button> &nbsp;
                                                            <button type="button"
                                                                class="col-5 btn mt-1 btn-sm btn-gray text-white "
                                                                (click)="cancelNote(indexOfelement, this.showAdd[indexOfelement]);clearNotes()">
                                                                CANCEL
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showAdd[indexOfelement]">
                                            <div [formGroup]="notesLogFG">
                                                <div formArrayName="Note">
                                                    <div class="row" *ngFor="
                                                            let notefg of Note?.controls;
                                                            let i = index
                                                        ">

                                                        <div class="col-5">
                                                            <eprx-select [RxSelectId]="'notesLogCategry'"
                                                                [LabelText]="'Subcategory'" [IsTitle]="true"
                                                                [AddItem]="true" [SelectOnTab]="true"
                                                                [PlaceHolder]="''" [addItemMaxLength]="15"
                                                                [ControlName]="'NoteCatId'" [AutoFocus]="true"
                                                                [IsDisabled]="showEdit[indexOfelement] ? true :  false"
                                                                [FormGroupName]="notefg" 
                                                                [List]="(NotesFr === 'RxAnnotation' && showAdd[indexOfelement]) ? notesSubcatList : notesLogInfo?.NoteCat"
                                                                [BindLabel]="'Name'" [BindValue]="'Id'"
                                                                [LabelForId]="'Name'"
                                                                [ErrorDefs]="{required: 'Required'}"
                                                                (TriggerSelectValue)="addedCateg($event?.value)"
                                                                [IsRequired]="true">
                                                            </eprx-select>
                                                        </div>

                                                        <!-- <div  class="col-3  pr-0" *ngIf="parentPage != 'RxAnnotation'">
                                                      
                                                            <eprx-toggle [ControlName]="'IsShownAsPopup'" [FormGroupName]="notefg" [AutoFocus]="true" [IsChecked] = "notefg?.get('IsShownAsPopup').value" [RxselectId]="'noteLog'+ i"  [IsFrom]="'noteLog'" [LabelText]="'Show as Pop-up?'">
                                                            </eprx-toggle>
                                                        </div> -->
                                                        <div class="col-7 pl-0">
                                                            <eprx-select [RxSelectId]="'popupscreen'"
                                                                [LabelText]="'Popup Screen'" [IsTitle]="true"
                                                                [PlaceHolder]="''"
                                                                [ControlName]="'PopupScreenCodes'" [AutoFocus]="true"
                                                                [HasMultiple]="true" [FormGroupName]="notefg"
                                                                [List]="(parentPage !== 'RxAnnotation')? popUpScreenList: [{Name:'All', Id: 0, type:'all'}, {Name: 'Edit Rx', Id: 4, type:'er'}, {Name:'RPh Vrf', Id:3, type:'rph'}, {Name: 'DPV', Id: 5, type:'dpv'}]"
                                                                [BindLabel]="'Name'" [BindLabel]="'Name'"
                                                                [BindValue]="'type'" [LabelForId]="'Name'"
                                                                (TriggerSelectValue)="filterAllinList(notefg , PopupScreenCodes)">
                                                            </eprx-select>
                                                        </div>
                                                        <div class="col-12" [formGroup]="notefg">
                                                            <!-- <eprx-text-area [LabelText]="'Notes'" [ControlName]="'xNote'"
                                                                [FormGroupName]="notefg" [PlaceHolder]="'Notes'" [Rows]="4" [MaxLength]="4000" [IsFromNotes]="true"
                                                                [IsRequired]="true" [InputErrors]="notefg['controls'].xNote?.errors"
                                                                [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="IsSubmitted">
                                                            </eprx-text-area> -->

                                                            <angular-editor formControlName="xNote"
                                                                [config]="editorConfig"></angular-editor>
                                                            <span class="text-danger"
                                                                *ngIf="(IsSubmitted && !(notefg['controls']?.xNote?.value))">Required
                                                            </span>
                                                        </div>
                                                        <div class="col-5  "
                                                            [ngClass]="(IsSubmitted && !(notefg['controls']?.xNote?.value)) ? '' : 'mt-4'">
                                                            Notes By: {{userFullName}}
                                                        </div>
                                                        <div class="col-7   text-right"
                                                            [ngClass]="(IsSubmitted && !(notefg['controls']?.xNote?.value)) ? '' : 'mt-4'">
                                                            <button *ngIf="showAdd[indexOfelement]" type="button"
                                                                class="col-5 btn btn-sm btn-success w-75"
                                                                (click)="addNote()" appShortcutKey [AltKey]="'s'">
                                                                SAVE
                                                            </button>
                                                            <button *ngIf="showEdit[indexOfelement]" type="button"
                                                                class="col-5 btn btn-sm btn-success w-75"
                                                                (click)="updateNote(indexOfelement)" appShortcutKey
                                                                [AltKey]="'s'">
                                                                UPDATE
                                                            </button> &nbsp;
                                                            <button type="button"
                                                                class="col-5 btn mt-1 btn-sm btn-gray text-white w-75"
                                                                (click)="cancelNote(indexOfelement, this.showAdd[indexOfelement]);clearNotes()"
                                                                appShortcutKey [AltKey]="'c'">
                                                                CANCEL
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div [ngClass]="(showEdit[indexOfelement] || showAdd[indexOfelement])?'d-none':''"
                                        class="light_border card-footer   border_card_bottom_forAdd lightColor_orange height_35 border-top-0 pt-2 pl-2">
                                        <span *ngIf="false">Pop-up:
                                            {{noteItem.IsShownAsPopup ? "Y" : "N"}} |</span> By: {{noteItem.usename |
                                        uppercase}}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="eprx--block__footer" *ngIf="!totalcount && !addmode">
                    <div>
                        <span></span>
                        <i class="fa fa-file"></i><br />
                        No Data Found!
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button ngbAutofocus type="button" class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'">
            <span>C</span> CLOSE
        </button>
    </div>
</ng-template>

<!-- delete Notes Log -->

<ng-template #deleteNotes let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click');IsDeleteNotes=false">
        <h4 header>Confirmation</h4>
        <span body>Are you sure you want to delete the selected note(s)?
        </span>
        <button footer ngbAutofocus id="deletNotesBtn" type="button" class="hotkey_primary" appShortcutKey
            (click)="deleteNote()" InputKey="y"><b>Y</b> YES</button>
        <button footer type="button" class="hotkey_primary" appShortcutKey InputKey="n"
            (click)="c('Close click');IsDeleteNotes=false">
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #printNotes let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close Click')">
        <h4 header>Export Notes</h4>
        <span body>
            <div class="row">
                <div class="col-md-6">
                    <eprx-date-picker [AutoFocus]="true" [LabelText]="'Date From'" [PlaceHolder]="''"
                        [ControlName]="'FromDate'" [FormGroupName]="exportFG" [MinDate]="" [MaxDate]="minDate"
                        [IsDisabled]="false" [IsFirstElem]="true">
                    </eprx-date-picker>
                </div>
                <div class="col-md-6">
                    <eprx-date-picker [LabelText]="'Date To'" [PlaceHolder]="''" [ControlName]="'ToDate'"
                        [FormGroupName]="exportFG" [MinDate]="" [MaxDate]="minDate" [IsDisabled]="false">
                    </eprx-date-picker>
                </div>
            </div>
        </span>
        <button footer type="button" class="hotkey_success" (click)="exportExcel(true, true)" appShortcutKey
            [AltKey]="'j'">
            <span>J</span> Export
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #addnoteslog let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{notesInfoFor ? "Notes for "+ (notesInfoFor | uppercase) : parentPage + " Notes"}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div [formGroup]="notesLogFG">
            <div formArrayName="Note">
                <div class="row" *ngFor="let notefg of Note?.controls; let i = index">
                    <div class="col-md-3">
                        <label>Notes By:</label>
                        <!-- get user name  by user privs -->
                        <div class="label--data">{{userFullName}}</div>
                    </div>
                    <div class="col-md-5">
                        <eprx-select [RxSelectId]="'notesLogCategry'" [LabelText]="'Subcategory'"
                            [PlaceHolder]="''" [IsTitle]="true" [AddItem]="true" [SelectOnTab]="true"
                            [ControlName]="'NoteCatId'" [FormGroupName]="notefg" 
                           [List]="(NotesFr === 'RxAnnotation' && showAdd[indexOfelement]) ? notesSubcatList : notesLogInfo?.NoteCat"
                            [IsDisabled]="showEdit[indexOfelement] ? true :  false" [BindLabel]="'Name'"
                            [BindValue]="'Id'" (TriggerOnTabbing)="focusOutFromSelect($event)" [LabelForId]="'Name'"
                            [ErrorDefs]="{required: 'Required'}" [IsRequired]="true" [IsFirstElem]="true"
                            [secondCntrlN]="'Name'" [bindScnCntrlV]="'Name'" [addItemMaxLength]="15"
                            (TriggerSelectValue)="addedCateg($event?.value)">
                        </eprx-select>
                    </div>

                    <!-- <div class="col-md-3">
                        <eprx-toggle [ControlName]="'IsShownAsPopup'" [LabelText]="'Show as Pop-up?'"  [FormGroupName]="notefg" [AutoFocus]="true" [IsChecked] = "notefg?.get('IsShownAsPopup').value" [RxselectId]="'noteLog'+ i" [IsFrom]="'noteLog'">
                        </eprx-toggle>
                    </div>   -->
                    <div class="col-7">
                        <eprx-select [RxSelectId]="'popupscreen'" [LabelText]="'Popup Screen'" [IsTitle]="true"
                            [PlaceHolder]="''" [ControlName]="'PopupScreenCodes'" [AutoFocus]="true"
                            [FormGroupName]="notefg"
                            [List]="(parentPage !== 'RxAnnotation') ? popUpScreenList: [{Name:'All', Id: 0, type:'all'}, {Name: 'Edit Rx', Id: 4, type:'er'}, {Name:'RPh Vrf', Id:3, type:'rph'}, {Name: 'DPV', Id: 5, type:'dpv'}]"
                            [BindLabel]="'Name'" [BindLabel]="'Name'" [BindValue]="'type'" [LabelForId]="'Name'"
                            [HasMultiple]="true" (TriggerSelectValue)="filterAllinList(notefg , PopupScreenCodes)">
                        </eprx-select>
                    </div>
                </div>
                <div class="row" *ngFor="let notefg of Note?.controls; let i = index">
                    <div class="col-md-12" [formGroup]="notefg">
                        <!-- <eprx-text-area [LabelText]="'Notes'" [ControlName]="'xNote'" [FormGroupName]="notefg"
                            [PlaceHolder]="'Notes'" [Rows]="4" [IsRequired]="true" [IsFromNotes]="true"
                            [InputErrors]="notefg['controls'].xNote?.errors" [MaxLength]="4000"
                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="IsSubmitted"
                            (blur)="validate()">
                        </eprx-text-area> -->

                        <angular-editor formControlName="xNote" [config]="editorConfig"></angular-editor>
                        <span class="text-danger" *ngIf="(IsSubmitted && !(notefg['controls'].xNote.value))">Required
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="addNote()" *ngIf="addmode" appShortcutKey [AltKey]="'s'">
            <span>S</span> SAVE
        </button>
        <button *ngIf="showEdit[indexOfelement]" type="button" class="hotkey_success" (click)="updateNote()"
            appShortcutKey [AltKey]="'u'">
            <span>U</span> UPDATE
        </button>
        <button type="button" class="hotkey_success" (click)="c('Close click')" (keydown.tab)="focusOutAdd($event)"
            (click)="resetMode();resetForm()" appShortcutKey [AltKey]="'c'">
            <span>C</span> CLOSE
        </button>
    </div>
</ng-template>
<!-- <ng-template #deleteNotes let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure to delete selected note(s)?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="deleteNote()">Yes</button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')">
            No
        </button>
    </div>
</ng-template> -->

<!-- Export Notes -->
<!-- <ng-template #printNotes let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Export Notes</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <eprx-date-picker
                    [AutoFocus]="true"
                    [LabelText]="'Date From'"
                    [PlaceHolder]="''"
                    [ControlName]="'FromDate'"
                    [FormGroupName]="exportFG"
                    [MinDate]=""
                    [MaxDate]="minDate"
                    [IsDisabled]="false"
                >
                </eprx-date-picker>
            </div>
            <div class="col-md-6">
                <eprx-date-picker
                    [LabelText]="'Date To'"
                    [PlaceHolder]="''"
                    [ControlName]="'ToDate'"
                    [FormGroupName]="exportFG"
                    [MinDate]=""
                    [MaxDate]="minDate"
                    [IsDisabled]="false"
                >
                </eprx-date-picker>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="exportExcel()">
            Export
        </button>
    </div>
</ng-template> -->

<!-- Exported excel section hidden in UI -->




<app-override-log *ngIf="openOverride" [PrivMask]="privType" [CatId]="categoryId" [OverrideEndpoint]="overrideEndpoint"
    (OverrideLoginDet)="OverrideRes($event)"></app-override-log>