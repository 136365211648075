<div class="modal-header" *ngIf="!isGenrcEql">
    <h4 class="modal-title">
        {{ (!equivalentDrugWJ?.items?.length && equlDrugPopType !== 'eRx') ? 'Warning!!' : (equlDrugPopType !== 'eRx') ? 'Equivalent Drugs' :
        'Equivalent Preferred Drugs for the selected drug' }}
  &nbsp;
        <span *ngIf="this.equlDrugPopType !== 'rxScreen' && !isGenrcEql && (equivalentDrugWJ?.items?.length || equivalentDrugWJ?.items?.length)">
            <span> <span class="font-weight-bold" [title]="this.discDrugInfo?.drugname ? this.discDrugInfo?.strength ?  this.discDrugInfo?.drugname + this.discDrugInfo?.strength : this.discDrugInfo?.drugname : '' ">Drug Name:</span>
            {{ (discDrugInfo?.drugname | uppercase) + ' ' +
                  (discDrugInfo?.strength ? (discDrugInfo.strength + '' | uppercase) : '') + ' ' +
                  (discDrugInfo?.drugformname ? discDrugInfo.drugformname : '')  
            }}
                &nbsp; <span class="font-weight-bold">NDC:</span> {{this._formatsUtil.getNDCFormat(this.discDrugInfo?.drugcode)}}
            </span>
        </span>
        </h4>
    <button *ngIf="(this.prefDrugSetting == 2 && (this.equlDrugPopType == 'notPref' || this.equlDrugPopType == 'eRx')) ? false : true" type="button" [tabindex]="-1" class="close" aria-label="Close" (keydown.esc)="closepopmodal('Close')" (click)="closepopmodal('Close')">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="!(rxFG?.PrescReFill?.DAWId === 4 || rxFG?.PrescReFill?.DAWId === 7 || rxFG?.PrescReFill?.DAWId === 10)">
    <h4 *ngIf="this.equlDrugPopType == 'rxScreen'  && !isGenrcEql && (equivalentDrugWJ?.items?.length || equivalentDrugWJ?.items?.length)">
        <span> <span class="font-weight-bold">Drug Name:</span> {{ (this.discDrugInfo?.drugname | uppercase) + " " +
            (this.discDrugInfo?.strength ? (this.discDrugInfo?.strength+'' | uppercase) : '') + " "
            + ((this.discDrugInfo?.drugformname) ? this.discDrugInfo?.drugformname : "") }}
            &nbsp; <span class="font-weight-bold">NDC:</span> {{this._formatsUtil.getNDCFormat(this.discDrugInfo?.drugcode)}}
        </span>
    </h4>
    <div *ngIf="this.equlDrugPopType !== 'rxScreen' && (equivalentDrugWJ?.items?.length)" >
        <form [formGroup]="refreshFG" >
            <div class="col-sm-12 padding-0 margin_bottom_1rem">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <eprx-input [AutoFocus]="true" [LabelText]="'Drug Name/NDC'"
                        [PlaceHolder]="'Search Drug Name/NDC'" [ControlName]="'DrugName'" [FormGroupName]="refreshFG" [RxSelectId]="'DrugNameMMS'"
                        (TriggerValWhileEnter)="getDrugMMS()" [TabIndex]="20" >
                        </eprx-input>
                        <div class="position-relative font-weight-bolder color_white   height_18px width_19px text-center margin_left_8rem font_size_0_9rem margin_top_n5rem background_orange border_radius_50per" [ngbTooltip]="eqvSearchRest.Eqv_Search_Restriction" container="body" tooltipClass="auto-title-tooltip"  triggers="mouseover:mouseleave" [autoClose]="true" placement="right">𝒊</div>
                    </div>
                    <div class="col-lg-8 col-md-4 col-sm-4 col-xs-4 align-button text-right mt-3">
                        <!---<button title="Search / Refresh" tabindex="3" type="button" class="hotkey_primary pull-right" (click)="getDrugMMS()"
                            appShortcutKey [CtrlKey]="'s'">
                            <span>S</span> Search
                        </button>-->
                        <button type="button"  class="hotkey_success pull-right" (click)="clearFilters()"
                            appShortcutKey [AltKey]="'l'">
                            <span>L</span> CLEAR
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="IsFrmeRxToNewRxwithutDrug">
                <b class="gsddHasEqus_text">Selected drug not found. Please select an equivalent drug from the list below or add a new drug.</b>
            </div>
        </form>
    </div>
    <div class="background--orange p-2" *ngIf="isGenrcEql">
        <h4 class="modal-title">Equivalent Drugs</h4>
        <button type="button" [tabindex]="-1" class="close custom--close pt-3" aria-label="Close"
            (click)="closeEquavalentDrgsPoup()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <h4 *ngIf="equlDrugPopType === 'Disc'">
        <span *ngIf="equivalentDrugWJ?.items?.length >0">
            Discontinued Drug cannot be used, Please select equivalent drug from below.
        </span>
      <div class="mt-2">
        <b>Discontinued Drug :</b> {{(discDrugInfo?.drugname | uppercase)+ ' '}}
                {{discDrugInfo?.strength? discDrugInfo?.strength :' '}}<br>
        <b>NDC :</b> {{this._formatsUtil.getNDCFormat(discDrugInfo?.drugcode)}}<br>
        <b>Discontinued Status :</b> {{discDrugInfo?.isdiscontdate? discDrugInfo?.isdiscontdate+' ':' '}}
            </div>
            <br>
        <span *ngIf="equivalentDrugWJ?.items?.length == 0">
            Discontinued Drug cannot be used. There are no equivalent drugs in the system, please select another drug to
            proceed.
        </span>
    </h4>
    <h4 *ngIf="equlDrugPopType === 'QuickInactive'">
        Selected drug is inactive, Please select equivalent drug from below.
        <div>
            Inactive Drug: {{(discDrugInfo?.drugname | uppercase)+ ' '}}
            {{discDrugInfo?.strength? discDrugInfo?.strength :' '}}
            NDC: {{this._formatsUtil.getNDCFormat(discDrugInfo?.drugcode)}}
        </div>
        <div>&nbsp;</div>
    </h4>
    <div class="modal-bodyDrug">
        <div class="row">
            <div class="col-4">
                <h4 *ngIf="(equivalentDrugWJ?.items?.length > 0)
                    && ((equlDrugPopType === 'notPref' ||  equlDrugPopType === 'eRx') ? (!discDrugInfo['isbranded']) : true)">
                    <div>
                        Equivalent Generic Drugs
                    </div>
                </h4>
            </div>
            <div class="col-8">
                <div  *ngIf="(equivalentDrugWJ?.items?.length > 0)">
                    <div class="col-12 p-0 text-right">
                        <button  class="submit print-med-guide background_orange" (click)="toggleColorLegend()">Color Legend</button>
                    </div>
                </div>
            </div>
        </div>
    
        <wj-flex-grid #EqulentGenericDrugs [headersVisibility]="'Column'" [isReadOnly]="true" tabindex="22" (Keydown.tab) = "drugFieldFocus()"
            [itemsSource]="equivalentGenDrugWJ"    [itemFormatter]="itemFormatter"   class="equivalent-drugs-wijmo" [ngClass]="!equivalentGenDrugWJ?.items?.length ? 'no-data':''"
            (dblclick)="onRowDblclicked(EqulentGenericDrugs, $event)"
            (click)="onRowDblclicked1(EqulentGenericDrugs, $event)" (initialized)="init(EqulentGenericDrugs)" (keydown.enter)="onRowDblclicked(EqulentGenericDrugs, $event)"
            *ngIf="(equivalentDrugWJ?.items?.length && this.equlDrugPopType === 'rxScreen') " [selectionMode]="'Row'">
            <wj-flex-grid-column [header]="rxheader" [binding]="rxheader"
                *ngFor="let rxheader of rxHeaders;"
                [width]="rxheader === 'Drug Name'? 231: rxheader === 'NDC'? 121: rxheader === 'Manufacturer' ? 113:
                rxheader === 'Inv?'? 46 : rxheader === 'Is Pref?' ?65: rxheader === 'AWP' ? 56: rxheader==='Stock Qty' ?76 : rxheader==='Size'? 80: rxheader ==='Cost' ? 72 : rxheader ==='Is 340B?'? 94 : 110">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div [ngClass]="(rxheader === 'Drug Name')?
                        (item.Isdiscontdate === 'YES' && item.Ispreferred)? 'card drug-card-red drug-card-brown' :
                    (item['LastDispensedDate'] && item.Ispreferred)? 'card drug-card-green drug-card-brown':
                    (item.Isdiscontdate === 'YES')? 'card drug-card-red':
                    (item['LastDispensedDate'])? 'card drug-card-green' :
                    (item.Ispreferred)? 'card drug-card-brown' : 'card':''" [title] = "(rxheader === 'Manufacturer'|| rxheader === 'Drug Name') ? (item[rxheader]|uppercase)+'': '' ">
                        {{ item[rxheader]|uppercase }}
                    </div>
                </ng-template>
                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                    <div title="{{((rxheader && rxheader === 'Is Pref?')) ? 'Is Preferred?' : (rxheader && rxheader === 'Inv?') ? 'Manage Inventory?': (rxheader && rxheader === 'Cost') ? 'Unit Cost':
                    '' }}">
                        {{ rxheader}}
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!equivalentGenDrugWJ?.items?.length"></app-no-data-found>
        </wj-flex-grid>
        </div>
        <wj-flex-grid #EqulentGenericDrugs [headersVisibility]="'Column'" [isReadOnly]="true" tabindex="22"
        [columnPicker]="'equivalentGenDrugs'"    [itemFormatter]="itemFormatter"
         (dblclick)="onRowDblclicked(EqulentGenericDrugs, $event)"
         (click)="onRowDblclicked1(EqulentGenericDrugs, $event)" 
         (keydown.enter)="onRowDblclicked(EqulentGenericDrugs, $event)" class="equivalent-drugs-wijmo" [itemsSource]="equivalentGenDrugWJ"
        *ngIf="equivalentDrugWJ?.items?.length  && this.equlDrugPopType !== 'rxScreen'" [selectionMode]="'Row'" [ngClass]="!equivalentGenDrugWJ?.items?.length ? 'no-data':''">
        <wj-flex-grid-column [header]="'Select'" [width]="41">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row = "row">
                <i><input type="radio"  id="generic{{row.index}}"
                    name="{{item.index}}" [attr.disabled]="((equlDrugPopType === 'Disc' || equlDrugPopType === 'inactive' || equlDrugPopType === 'QuickInactive' || equlDrugPopType === 'notPref') && item.Isdiscontdate === 'YES') ? true : null"
                    [value]=" checkedGenDrug ?  item.index : null" [title]="((equlDrugPopType === 'Disc' || equlDrugPopType === 'inactive' || equlDrugPopType === 'QuickInactive' || equlDrugPopType === 'notPref') && item.Isdiscontdate === 'YES') ? 'Discontinued Drug' : ''"
                    [checked]="(item['item']?.['ndc'] ===
                        checkedGenDrug)" (change)="selectedRxGen($event?.target?.checked, item, 'gen')" />
                    </i>
    
            </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="rxheader['hName']" [visible]="rxheader['isVisible']"
        *ngFor="let rxheader of wjHeadersForGen;" [width]="rxheader['width']">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                <div [ngClass]="(rxheader['hName'] === 'Drug Name')?
                    (item.Isdiscontdate === 'YES' && item.Ispreferred)? 'card drug-card-red drug-card-brown' :
                (item['LastDispensedDate'] && item.Ispreferred)? 'card drug-card-green drug-card-brown':
                (item.Isdiscontdate === 'YES')? 'card drug-card-red':
                (item['LastDispensedDate'])? 'card drug-card-green' :
                (item.Ispreferred)? 'card drug-card-brown' : 'card':''">
                    {{ item[rxheader['hName']] }}
                </div>
            </ng-template>
        </wj-flex-grid-column>
        <app-no-data-found *ngIf="!equivalentGenDrugWJ?.items?.length"></app-no-data-found>
     </wj-flex-grid>
    <div class="row">
        <div class="col-4">
            <h4 *ngIf="(equivalentDrugWJ?.items?.length > 0 && !isGenrcEql) && ((equlDrugPopType === 'notPref' ||  equlDrugPopType === 'eRx') ? (discDrugInfo['isbranded']) : true)">
                <div>
                    Equivalent Brand Drugs
                </div>
            </h4>
        </div>
    </div>
    <wj-flex-grid #EquivalentBrandDrugs [headersVisibility]="'Column'" [isReadOnly]="true"  tabindex="21"
        [columnPicker]="'equivalentBrandDrugs'" [itemsSource]="equivalentBrandDrugWJ"  class="equivalent-drugs-wijmo"   [itemFormatter]="itemFormatter" (keydown.enter)="onRowDblclicked(EquivalentBrandDrugs, $event)" (dblclick)="onRowDblclicked(EquivalentBrandDrugs, $event)"
        (click)="onRowDblclicked1(EquivalentBrandDrugs, $event)" (initialized)="initBrand(EquivalentBrandDrugs)"
        *ngIf="equivalentDrugWJ?.items?.length && !isGenrcEql && this.equlDrugPopType !== 'rxScreen'" [selectionMode]="'Row'" [ngClass]="!equivalentBrandDrugWJ?.items?.length ? 'no-data':''">
        <wj-flex-grid-column [header]="'Select'" [width]="41">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row = "row">
                <i><input type="radio"  id="brand{{row.index}}"
                    name="{{item.index}}" [attr.disabled]="((equlDrugPopType === 'Disc' || equlDrugPopType === 'inactive' || equlDrugPopType === 'QuickInactive' || equlDrugPopType === 'notPref') && item.Isdiscontdate === 'YES') ? true : null"
                    [value]="checkedBrandDrug ? item.index : null" [title]="((equlDrugPopType === 'Disc' || equlDrugPopType === 'inactive' || equlDrugPopType === 'QuickInactive' || equlDrugPopType === 'notPref') && item.Isdiscontdate === 'YES') ? 'Discontinued Drug' : ''"
                    [checked]="(item['item']?.['ndc'] ===
                    checkedBrandDrug)" (change)="selectedRxBrand($event?.target?.checked, item, 'brand')" /></i>
            </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="rxheader['hName']" [visible]="rxheader['isVisible']"
            *ngFor="let rxheader of wjHeaders;" [width]="rxheader['width']">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                <div [ngClass]="(rxheader['hName'] === 'Drug Name')?
                        ((item.Isdiscontdate === 'YES' && item.Ispreferred)? 'card drug-card-red drug-card-brown' :
                        (item['LastDispensedDate'] && item.Ispreferred)? 'card drug-card-green drug-card-brown':
                        (item.Isdiscontdate === 'YES')? 'card drug-card-red':
                        (item['LastDispensedDate'])? 'card drug-card-green' :
                        (item.Ispreferred)? 'card drug-card-brown' : 'card'):''">
                    {{ item[rxheader['hName']] }}
                </div>
            </ng-template>
        </wj-flex-grid-column>
        <app-no-data-found *ngIf="!equivalentBrandDrugWJ?.items?.length"></app-no-data-found>
    </wj-flex-grid>

    <wj-flex-grid #EquivalentBrandDrugs [headersVisibility]="'Column'" [isReadOnly]="true"
   [itemsSource]="equivalentBrandDrugWJ"   [itemFormatter]="itemFormatter"  class="equivalent-drugs-wijmo"
    *ngIf="equivalentDrugWJ?.items?.length && !isGenrcEql && this.equlDrugPopType === 'rxScreen' &&
    ((equlDrugPopType === 'notPref' ||  equlDrugPopType === 'eRx') ? (discDrugInfo['isbranded']) : true)"  (keydown.enter) ="onRowDblclicked(EquivalentBrandDrugs, $event)" [selectionMode]="'Row'" (dblclick)="onRowDblclicked(EquivalentBrandDrugs, $event)"
    (click)="onRowDblclicked1(EquivalentBrandDrugs, $event)" [ngClass]="!equivalentBrandDrugWJ?.items?.length ? 'no-data':''">
    <wj-flex-grid-column [header]="rxheader['hName']" [visible]="rxheader['isVisible']"
        *ngFor="let rxheader of wjHeaders;" [width]="rxheader['width']">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
            <div [ngClass]="(rxheader['hName'] === 'Drug Name')?
                    ((item.Isdiscontdate === 'YES' && item.Ispreferred)? 'card drug-card-red drug-card-brown' :
                    (item['LastDispensedDate'] && item.Ispreferred)? 'card drug-card-green drug-card-brown':
                    (item.Isdiscontdate === 'YES')? 'card drug-card-red':
                    (item['LastDispensedDate'])? 'card drug-card-green' :
                    (item.Ispreferred)? 'card drug-card-brown' : 'card'):''">
                {{ item[rxheader['hName']] }}
            </div>
        </ng-template>
    </wj-flex-grid-column>
    <app-no-data-found *ngIf="!equivalentBrandDrugWJ?.items?.length"></app-no-data-found>
  </wj-flex-grid>
  <div class="mt-2" *ngIf="this.equlDrugPopType === 'rxScreen' &&  equivalentDrugWJ?.items?.length">
    <form [formGroup]="refreshFG" >
        <div class="col-sm-12 padding-0">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <eprx-input [AutoFocus]="true" [LabelText]="'Drug Name'"
                    [PlaceHolder]="'Search Drug Name/NDC'" [ControlName]="'DrugName'" [FormGroupName]="refreshFG" [RxSelectId]="'DrugNameMMS'"
                    (TriggerValWhileEnter)="getDrugMMS()" [TabIndex]="21">
                    </eprx-input>
                    <div class="font-weight-bolder color_white top_n2px right_n30px height_18px width_19px text-center margin_left_8rem font_size_0_9rem margin_top_n5rem background_orange border_radius_50per" [ngbTooltip]="eqvSearchRest.Eqv_Search_Restriction" container="body" tooltipClass="auto-title-tooltip"  triggers="mouseover:mouseleave" [autoClose]="true" placement="right">𝒊</div>
                </div>
                <!--<  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <eprx-input [LabelText]="'NDC'" [PlaceHolder]="'Search NDC'" [ControlName]="'NDCCode'" [FormGroupName]="refreshFG"
                    [RxSelectId]="'NDCMMS'"
                    (TriggerValWhileEnter)="getDrugMMS()" [TabIndex]="2">
                    </eprx-input>
                </div>-->
                <div class="col-lg-8 col-md-4 col-sm-4 col-xs-4 align-button text-right mt-3">
                    <!---<button title="Search / Refresh" tabindex="3" type="button" class="hotkey_primary pull-right" (click)="getDrugMMS()"
                        appShortcutKey [CtrlKey]="'s'">
                        <span>S</span> Search
                    </button>-->
                    <button type="button"  class="hotkey_success pull-right" (click)="clearFilters()"
                        appShortcutKey [AltKey]="'l'">
                        <span>L</span> CLEAR
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

    <div *ngIf="equlDrugPopType !== 'Disc' && (!equivalentDrugWJ?.items?.length) else thenBlock" class="eprx--block__content text-center font_size_18px">
        There are no equivalent drugs found in the system.
    </div>
    <ng-template #thenBlock>
         <div class="eprx--block__content text-center"
        *ngIf="equlDrugPopType !== 'Disc' &&((!equivalentGenDrugWJ?.items?.length && !equivalentBrandDrugWJ?.items?.length))">
    </div>
 </ng-template>

</div>
<div class="modal-body" *ngIf="(rxFG?.PrescReFill?.DAWId === 4 || rxFG?.PrescReFill?.DAWId === 7 || rxFG?.PrescReFill?.DAWId === 10)">
    <h4 *ngIf="this.equlDrugPopType == 'rxScreen'  && !isGenrcEql && (equivalentDrugWJ?.items?.length || equivalentDrugWJ?.items?.length)">
        <span> <span class="font-weight-bold">Drug Name:</span> {{ (this.discDrugInfo?.drugname | uppercase) + " " +
            (this.discDrugInfo?.strength ? (this.discDrugInfo?.strength+'' | uppercase) : '') + " "
            + ((this.discDrugInfo?.drugformname) ? this.discDrugInfo?.drugformname : "") }}
            &nbsp; <span class="font-weight-bold">NDC:</span> {{this._formatsUtil.getNDCFormat(this.discDrugInfo?.drugcode)}}
        </span>
    </h4>
    <div *ngIf="this.equlDrugPopType !== 'rxScreen' && (equivalentDrugWJ?.items?.length)" >
        <form [formGroup]="refreshFG" >
            <div class="col-sm-12 padding-0 margin_bottom_1rem">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <eprx-input [AutoFocus]="true" [LabelText]="'Drug Name/NDC'"
                        [PlaceHolder]="'Search Drug Name/NDC'" [ControlName]="'DrugName'" [FormGroupName]="refreshFG" [RxSelectId]="'DrugNameMMS'"
                        (TriggerValWhileEnter)="getDrugMMS()" [TabIndex]="20" >
                        </eprx-input>
                        <div class="position-relative font-weight-bolder color_white  height_18px width_19px text-center margin_left_8rem font_size_0_9rem margin_top_n5rem background_orange border_radius_50per" [ngbTooltip]="eqvSearchRest.Eqv_Search_Restriction" container="body" tooltipClass="auto-title-tooltip"  triggers="mouseover:mouseleave" [autoClose]="true" placement="right">𝒊</div>
                    </div>
                    <div class="col-lg-8 col-md-4 col-sm-4 col-xs-4 align-button text-right mt-3">
                        <!---<button title="Search / Refresh" tabindex="3" type="button" class="hotkey_primary pull-right" (click)="getDrugMMS()"
                            appShortcutKey [CtrlKey]="'s'">
                            <span>S</span> Search
                        </button>-->
                        <button type="button"  class="hotkey_success pull-right" (click)="clearFilters()"
                            appShortcutKey [AltKey]="'l'">
                            <span>L</span> CLEAR
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="IsFrmeRxToNewRxwithutDrug">
                <b class="gsddHasEqus_text">Selected drug not found. Please select an equivalent drug from the list below or add a new drug.</b>
            </div>
        </form>
    </div>
    <div class="background--orange p-2" *ngIf="isGenrcEql">
        <h4 class="modal-title">Equivalent Drugs</h4>
        <button type="button" [tabindex]="-1" class="close custom--close pt-3" aria-label="Close"
            (click)="closeEquavalentDrgsPoup()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <h4 *ngIf="equlDrugPopType === 'Disc'">
        <span *ngIf="equivalentDrugWJ?.items?.length >0">
            Selected Drug is Discontinued Drug, Equivalents are available for this drug.
        </span>
      <div class="mt-2">
        <b>Discontinued Drug :</b> {{(discDrugInfo?.drugname | uppercase)+ ' '}}
                {{discDrugInfo?.strength? discDrugInfo?.strength :' '}}<br>
        <b>NDC :</b> {{this._formatsUtil.getNDCFormat(discDrugInfo?.drugcode)}}<br>
        <b>Discontinued Status :</b> {{discDrugInfo?.isdiscontdate? discDrugInfo?.isdiscontdate+' ':' '}}
            </div>
            <br>
        <span *ngIf="equivalentDrugWJ?.items?.length == 0">
            The selected drug has been discontinued and no equivalent alternatives are available, which may impact insurance reimbursement. Do you want to proceed?
        </span>
    </h4>
    <h4 *ngIf="equlDrugPopType === 'QuickInactive'">
        Selected drug is inactive, Please select equivalent drug from below.
        <div>
            Inactive Drug: {{(discDrugInfo?.drugname | uppercase)+ ' '}}
            {{discDrugInfo?.strength? discDrugInfo?.strength :' '}}
            NDC: {{this._formatsUtil.getNDCFormat(discDrugInfo?.drugcode)}}
        </div>
        <div>&nbsp;</div>
    </h4>
    <div class="row">
        <div class="col-4">
            <h4 *ngIf="(equivalentDrugWJ?.items?.length > 0 && !isGenrcEql) && ((equlDrugPopType === 'notPref' ||  equlDrugPopType === 'eRx' || DAWBsdDrugSug) ? (discDrugInfo['isbranded']) : true)">
                <div>
                    Equivalent Brand Drugs
                </div>
            </h4>
        </div>

    <div class="col-8" *ngIf="(equivalentDrugWJ?.items?.length > 0 && !isGenrcEql) && ((equlDrugPopType === 'notPref' ||  equlDrugPopType === 'eRx' || DAWBsdDrugSug) ? (discDrugInfo['isbranded']) : true)">
        <div class="col-12 p-0 text-right">
            <button  class="submit print-med-guide background_orange" (click)="toggleColorLegend()">Color Legend</button>
        </div>
    </div>
    </div>
    <wj-flex-grid #EquivalentBrandDrugs [headersVisibility]="'Column'" [isReadOnly]="true"  tabindex="21"
        [columnPicker]="'equivalentBrandDrugs'" [itemsSource]="equivalentBrandDrugWJ"  class="equivalent-drugs-wijmo"   [itemFormatter]="itemFormatter" (keydown.enter)="onRowDblclicked(EquivalentBrandDrugs, $event)" (dblclick)="onRowDblclicked(EquivalentBrandDrugs, $event)"
        (click)="onRowDblclicked1(EquivalentBrandDrugs, $event)" (initialized)="initBrand(EquivalentBrandDrugs)"
        *ngIf="equivalentDrugWJ?.items?.length > 0 && !isGenrcEql && this.equlDrugPopType !== 'rxScreen' &&
        ((equlDrugPopType === 'notPref' ||  equlDrugPopType === 'eRx') ? (discDrugInfo['isbranded']) : true)" [selectionMode]="'Row'" [ngClass]="!equivalentBrandDrugWJ?.items?.length ? 'no-data':''">
        <wj-flex-grid-column [header]="'Select'" [width]="55">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row = "row">
                <i><input type="radio"  id="brand{{row.index}}"
                    name="{{item.index}}"
                    [value]="checkedBrandDrug ? item.index : null"
                    [checked]="(item['item']?.['ndc'] ===
                    checkedBrandDrug)" (change)="selectedRxBrand($event?.target?.checked, item, 'brand')" /></i>
            </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="rxheader['hName']" [visible]="rxheader['isVisible']"
            *ngFor="let rxheader of wjHeaders;" [width]="rxheader['width']">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                <div [ngClass]="(rxheader['hName'] === 'Drug Name')?
                        ((item.Isdiscontdate === 'YES' && item.Ispreferred)? 'card drug-card-red drug-card-brown' :
                        (item['LastDispensedDate'] && item.Ispreferred)? 'card drug-card-green drug-card-brown':
                        (item.Isdiscontdate === 'YES')? 'card drug-card-red':
                        (item['LastDispensedDate'])? 'card drug-card-green' :
                        (item.Ispreferred)? 'card drug-card-brown' : 'card'):''">
                    {{ item[rxheader['hName']] }}
                </div>
            </ng-template>
        </wj-flex-grid-column>
        <app-no-data-found *ngIf="!equivalentBrandDrugWJ?.items?.length"></app-no-data-found>
    </wj-flex-grid>

    <wj-flex-grid #EquivalentBrandDrugs [headersVisibility]="'Column'" [isReadOnly]="true"
   [itemsSource]="equivalentBrandDrugWJ"   [itemFormatter]="itemFormatter"  class="equivalent-drugs-wijmo"
    *ngIf="equivalentDrugWJ?.items?.length > 0 && !isGenrcEql && this.equlDrugPopType === 'rxScreen'"  (keydown.enter) ="onRowDblclicked(EquivalentBrandDrugs, $event)" [selectionMode]="'Row'" (dblclick)="onRowDblclicked(EquivalentBrandDrugs, $event)"
    (click)="onRowDblclicked1(EquivalentBrandDrugs, $event)" [ngClass]="!equivalentBrandDrugWJ?.items?.length ? 'no-data':''">
    <wj-flex-grid-column [header]="rxheader['hName']" [visible]="rxheader['isVisible']"
        *ngFor="let rxheader of wjHeaders;" [width]="rxheader['width']">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
            <div [ngClass]="(rxheader['hName'] === 'Drug Name')?
                    ((item.Isdiscontdate === 'YES' && item.Ispreferred)? 'card drug-card-red drug-card-brown' :
                    (item['LastDispensedDate'] && item.Ispreferred)? 'card drug-card-green drug-card-brown':
                    (item.Isdiscontdate === 'YES')? 'card drug-card-red':
                    (item['LastDispensedDate'])? 'card drug-card-green' :
                    (item.Ispreferred)? 'card drug-card-brown' : 'card'):''">
                {{ item[rxheader['hName']] }}
            </div>
        </ng-template>
    </wj-flex-grid-column>
    <app-no-data-found *ngIf="!equivalentBrandDrugWJ?.items?.length"></app-no-data-found>
  </wj-flex-grid>
  <div class="mt-2" *ngIf="this.equlDrugPopType === 'rxScreen' &&  equivalentDrugWJ?.items?.length">
    <form [formGroup]="refreshFG" >
        <div class="col-sm-12 padding-0">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <eprx-input [AutoFocus]="true" [LabelText]="'Drug Name'"
                    [PlaceHolder]="'Search Drug Name/NDC'" [ControlName]="'DrugName'" [FormGroupName]="refreshFG" [RxSelectId]="'DrugNameMMS'"
                    (TriggerValWhileEnter)="getDrugMMS()" [TabIndex]="21">
                    </eprx-input>
                </div>
                <!--<  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <eprx-input [LabelText]="'NDC'" [PlaceHolder]="'Search NDC'" [ControlName]="'NDCCode'" [FormGroupName]="refreshFG"
                    [RxSelectId]="'NDCMMS'"
                    (TriggerValWhileEnter)="getDrugMMS()" [TabIndex]="2">
                    </eprx-input>
                </div>-->
                <div class="col-lg-8 col-md-4 col-sm-4 col-xs-4 align-button text-right mt-3">
                    <!---<button title="Search / Refresh" tabindex="3" type="button" class="hotkey_primary pull-right" (click)="getDrugMMS()"
                        appShortcutKey [CtrlKey]="'s'">
                        <span>S</span> Search
                    </button>-->
                    <button type="button"  class="hotkey_success pull-right" (click)="clearFilters()"
                        appShortcutKey [AltKey]="'l'">
                        <span>L</span> CLEAR
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

  <div class="modal-bodyDrug">
    <div class="row">
        <div class="col-4">
            <h4 *ngIf="(equivalentDrugWJ?.items?.length > 0)
                && ((equlDrugPopType === 'notPref' ||  equlDrugPopType === 'eRx') ? (!discDrugInfo['isbranded']) : true)">
                <div>
                    Equivalent Generic Drugs
                </div>
            </h4>
        </div>
    </div>

    <wj-flex-grid #EqulentGenericDrugs [headersVisibility]="'Column'" [isReadOnly]="true" tabindex="22" (Keydown.tab) = "drugFieldFocus()"
        [itemsSource]="equivalentGenDrugWJ"    [itemFormatter]="itemFormatter"   class="equivalent-drugs-wijmo"
        (dblclick)="onRowDblclicked(EqulentGenericDrugs, $event)"
        (click)="onRowDblclicked1(EqulentGenericDrugs, $event)" (initialized)="init(EqulentGenericDrugs)" (keydown.enter)="onRowDblclicked(EqulentGenericDrugs, $event)"
        *ngIf="(equivalentDrugWJ?.items?.length >0 &&   this.equlDrugPopType === 'rxScreen') " [selectionMode]="'Row'" [ngClass]="!equivalentGenDrugWJ?.items?.length ? 'no-data':''">
        <wj-flex-grid-column [header]="rxheader" [binding]="rxheader"
            *ngFor="let rxheader of rxHeaders;"
            [width]="rxheader === 'Drug Name'? 231: rxheader === 'NDC'? 121: rxheader === 'Manufacturer' ? 113:
            rxheader === 'Inv?'? 46 : rxheader === 'Is Pref?' ?65: rxheader === 'AWP' ? 56: rxheader==='Stock Qty' ?76 : rxheader==='Size'? 80: rxheader ==='Cost' ? 72 : rxheader ==='Is 340B?'? 94 : 110">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                <div [ngClass]="(rxheader === 'Drug Name')?
                    (item.Isdiscontdate === 'YES' && item.Ispreferred)? 'card drug-card-red drug-card-brown' :
                (item['LastDispensedDate'] && item.Ispreferred)? 'card drug-card-green drug-card-brown':
                (item.Isdiscontdate === 'YES')? 'card drug-card-red':
                (item['LastDispensedDate'])? 'card drug-card-green' :
                (item.Ispreferred)? 'card drug-card-brown' : 'card':''" [title] = "(rxheader === 'Manufacturer'|| rxheader === 'Drug Name') ? (item[rxheader]|uppercase)+'': '' ">
                    {{ item[rxheader]|uppercase }}
                </div>
            </ng-template>
            <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                <div title="{{((rxheader && rxheader === 'Is Pref?')) ? 'Is Preferred?' : (rxheader && rxheader === 'Inv?') ? 'Manage Inventory?': (rxheader && rxheader === 'Cost') ? 'Unit Cost':
                '' }}">
                    {{ rxheader}}
                </div>
            </ng-template>
        </wj-flex-grid-column>
        <app-no-data-found *ngIf="!equivalentGenDrugWJ?.items?.length"></app-no-data-found>
    </wj-flex-grid>
    </div>
    <wj-flex-grid #EqulentGenericDrugs [headersVisibility]="'Column'" [isReadOnly]="true" tabindex="22"
    [columnPicker]="'equivalentGenDrugs'"    [itemFormatter]="itemFormatter"
     (dblclick)="onRowDblclicked(EqulentGenericDrugs, $event)"
     (click)="onRowDblclicked1(EqulentGenericDrugs, $event)"
     (keydown.enter)="onRowDblclicked(EqulentGenericDrugs, $event)" class="equivalent-drugs-wijmo" [itemsSource]="equivalentGenDrugWJ"
    *ngIf="equivalentDrugWJ?.items?.length >0  && this.equlDrugPopType !== 'rxScreen' &&((equlDrugPopType === 'notPref' || equlDrugPopType === 'eRx') ? (!discDrugInfo['isbranded']) : true)" [selectionMode]="'Row'" [ngClass]="!equivalentGenDrugWJ?.items?.length ? 'no-data':''">
    <wj-flex-grid-column [header]="'Select'" [width]="55">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row = "row">
            <i><input type="radio"  id="generic{{row.index}}"
                name="{{item.index}}" [attr.disabled]="((equlDrugPopType === 'Disc' || equlDrugPopType === 'inactive' || equlDrugPopType === 'QuickInactive' || equlDrugPopType === 'notPref') && item.Isdiscontdate === 'YES') ? true : null"
                [value]=" checkedGenDrug ?  item.index : null" [title]="((equlDrugPopType === 'Disc' || equlDrugPopType === 'inactive' || equlDrugPopType === 'QuickInactive' || equlDrugPopType === 'notPref') && item.Isdiscontdate === 'YES') ? 'Discontinued Drug' : ''"
                [checked]="(item['item']?.['ndc'] ===
                    checkedGenDrug)" (change)="selectedRxGen($event?.target?.checked, item, 'gen')" />
                </i>

        </ng-template>
    </wj-flex-grid-column>
    <wj-flex-grid-column [header]="rxheader['hName']" [visible]="rxheader['isVisible']"
    *ngFor="let rxheader of wjHeadersForGen;" [width]="rxheader['width']">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
            <div [ngClass]="(rxheader['hName'] === 'Drug Name')?
                (item.Isdiscontdate === 'YES' && item.Ispreferred)? 'card drug-card-red drug-card-brown' :
            (item['LastDispensedDate'] && item.Ispreferred)? 'card drug-card-green drug-card-brown':
            (item.Isdiscontdate === 'YES')? 'card drug-card-red':
            (item['LastDispensedDate'])? 'card drug-card-green' :
            (item.Ispreferred)? 'card drug-card-brown' : 'card':''">
                {{ item[rxheader['hName']] }}
            </div>
        </ng-template>
    </wj-flex-grid-column>
    <app-no-data-found *ngIf="!equivalentGenDrugWJ?.items?.length"></app-no-data-found>
 </wj-flex-grid>

    <div *ngIf="equlDrugPopType !== 'Disc' && (!equivalentDrugWJ?.items?.length) else thenBlock" class="eprx--block__content text-center font_size_18px">
        There are no equivalent drugs found in the system.
    </div>
    <ng-template #thenBlock>
         <div class="eprx--block__content text-center"
        *ngIf="equlDrugPopType !== 'Disc' &&((!equivalentGenDrugWJ?.items?.length && !equivalentBrandDrugWJ?.items?.length))">
    </div>
 </ng-template>

</div>
<div class="row p-3" *ngIf="!isGenrcEql">
    <!-- Based on this card, commented these changes EP-4643 : Improvements to Enter and Edit Rx > Equivalent Drugs. Improve verbiage of "Continue with current drug" and fix flow of Enter Rx > Equivalent Drugs > "Continue with current drug". -->
    <!-- <button footer  type="submit" class="hotkey_success" appShortcutKey [AltKey]="'d'" *ngIf="equlDrugPopType !== 'Disc' && !isFromRecall && (equlDrugPopType && (equlDrugPopType !== 'eRx' && equlDrugPopType !== 'DrugHeader' && equlDrugPopType!== 'QuickInactive')) && ((this.prefDrugSetting == 2 && (this.equlDrugPopType == 'notPref' || this.equlDrugPopType == 'eRx')) ? false : true) && (equlDrugPopType !== 'inactive') && !isPaidPayRecAvail"  [tabindex]="23"
    <button footer  type="submit" class="hotkey_success" appShortcutKey [AltKey]="'d'" *ngIf="equlDrugPopType !== 'Disc' && !isFromRecall && (equlDrugPopType && (equlDrugPopType !== 'eRx' && equlDrugPopType !== 'DrugHeader' && equlDrugPopType!== 'QuickInactive')) && ((this.prefDrugSetting == 2 && (this.equlDrugPopType == 'notPref' || this.equlDrugPopType == 'eRx')) ? false : true) && (equlDrugPopType !== 'inactive') && !isPaidPayRecAvail"  [tabindex]="23"
        (click)="closepopmodal('Continue')"> <span>D</span> CONTINUE WITH CURRENT DRUG
    </button> -->
    <div footer class="col-lg-3" *ngIf="equivalentDrugWJ?.items?.length">
        <div class="d-inline-flex">
            <input class="form-check-input" type="checkbox" [(ngModel)]="isDiscChecked" (click)="discontinuedDrugFilter($event?.target?.checked,brandedDrugList,genricDrugList)">
            <label class="font_size_16 ml-4 pl-1">Show Discontinued Drugs</label>                
        </div>
    </div>
    <div [ngClass]="equivalentDrugWJ?.items?.length? 'col-9' : 'col-12' " class="d-flex justify-content-end">
    <button footer type="submit" *ngIf="equivalentDrugWJ?.items?.length >0 && (equlDrugPopType && (equlDrugPopType !== 'eRx') && (equlDrugPopType == 'popup' || equlDrugPopType == 'DrugHeader'))" class="hotkey_success" appShortcutKey [AltKey]="'m'" (click)="markAsPref()">
        <span>M</span> Mark As Preferred
    </button>
    <button footer
     *ngIf="selectedItem && (((equlDrugPopType && (equlDrugPopType === 'eRx') || (isFrom == 'nDrug')) && (equlDrugPopType !== 'popup')) || (selectedDrugInfo && equlDrugPopType == 'QuickInactive' || equlDrugPopType == 'Disc'))" type="submit" class="hotkey_success" appShortcutKey [AltKey]="'e'" (click)="closepopmodalForeRx('Continue')"  [tabindex]="getFocusVal()?'24':'-1'" >
       <span>E</span> Continue
    </button>
    <button
     *ngIf="(equivalentDrugWJ?.items?.length) &&  (!selectedItem &&  (equlDrugPopType === 'Disc'  || (equlDrugPopType && (equlDrugPopType === 'eRx') || (isFrom == 'nDrug')) && (equlDrugPopType !== 'popup') )) ||  (!selectedDrugInfo && equlDrugPopType == 'QuickInactive' && equlDrugPopType == 'Disc')" type="submit" class="inactive" appShortcutKey [AltKey]="'e'"> <span>E</span> Continue
    </button>
    <button footer autofocus *ngIf="(prefDrugSetting == 1) && (equlDrugPopType && (equlDrugPopType === 'eRx' || equlDrugPopType === 'notPref')) && (equlDrugPopType !== 'popup')" type="submit" class="hotkey_success" appShortcutKey [AltKey]="'i'" (click)="closepopmodalForeRx('IGNORE')">
        <span>I</span> IGNORE
    </button>
    <button footer
    *ngIf="isHideButton && !equivalentDrugWJ?.items?.length && equlDrugPopType === 'Disc'" type="submit" class="hotkey_success" appShortcutKey [AltKey]="'y'" (click)="allowDiscontinue()">
       <span>Y</span> YES
   </button>
   <button footer
    *ngIf="isHideButton && equlDrugPopType === 'Disc' && !equivalentDrugWJ?.items?.length" type="submit" class="hotkey_success" appShortcutKey [AltKey]="'n'" (click)="closepopmodal('Close')">
       <span>N</span> NO
   </button>
    <button footer
    *ngIf="isHideButton && equlDrugPopType !== 'Disc'" type="submit" class="hotkey_success" appShortcutKey [AltKey]="'c'"  [tabindex]="getFocusVal()? '25': '24'"  (click)="closepopmodal('Close')">
       <span>C</span> CLOSE
   </button>
   </div>
</div>
<ng-template #MarkAsPrefDrug let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="cancelMarkPref();c('Close click')">
        <h4 header>Confirmation</h4>
        <span body>
            <span *ngIf="DrugList && DrugList?.length">
            The following NDC(s)
            <b>{{DrugList.join(", ") }}</b>
            <span *ngIf="(PrefDrugStng === '1')"> is(are) already marked as preferred. Each group can only have one preferred NDC. Would you like to remove this NDC(s) and mark
            <b>{{selectedDrugItem}}</b> as preferred drug?</span>
            <span *ngIf="(PrefDrugStng !== '1')"> is(are) already marked as preferred. Do you want to include
            <b>{{selectedDrugItem}}</b> to the Preferred list?</span>
            </span>
            <span *ngIf="!DrugList?.length">
                Do you mark <b>{{selectedDrugItem}}</b> as Preferred Drug?
            </span>
        </span>
        <span footer>
            <button class="hotkey_primary" ngbAutofocus appShortcutKey InputKey="y"
        (click)="continueMarkPref()"><b>Y</b>YES</button>
        <button class="hotkey_primary" appShortcutKey InputKey="n"
        (click)="cancelMarkPref(); c('Close click')"><b>N</b>NO</button>
        </span>
    </app-custom-modal>
</ng-template>
