import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'threeFixedDollar'
})
export class ThreeFixedDollarPipe implements PipeTransform {

  transform(value: any): unknown {
    return value ;
  }

}
