import { MsgConfig } from './../../../app.messages'; 
import { AlertService, CommonService } from "src/app/services";
import { InsuranceService } from "../../../services/insurance.service";
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    OnDestroy,
    AfterViewInit,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    HostListener
} from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { Ncpdp, NcpdpSeg, InsuTransOther } from "../../../models";
import { CopyncdpComponent } from "../copyncdp/copyncdp.component";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: "app-ncpdp-payerset",
    templateUrl: "./ncpdp-payerset.component.html"
})
export class NcpdpPayersetComponent
    implements OnInit, AfterViewInit, OnDestroy {
    ncpdpData: any;
    modelRef: any;
    ncpdpInfoFG: FormGroup;
    ncpdpInfo: any;
    showPatient = false;
    showIns = false;
    showClaim = false;
    showPhar = false;
    showPresc = false;
    showCOB = false;
    showworker = false;
    showDUR = false;
    showNarr = false;
    showFacility = false;
    showAdd = false;
    showClinical = false;
    showPrior = false;
    showCompound = false;
    showCoupon = false;
    showpricing = false;
    transsetingsDropValues: any;
    copyInfoFG: FormGroup;
    ncpdpId: any;
    ncpdpCode: any;
    ncpdpsegId: any;
    ncpdpsegncpId: any;
    updateInfo: any;
    ncpdpdata: any;
    ID: number;
    unsubscribe$: Subject<void> = new Subject();
    @Output()
    setPopUpValue = new EventEmitter();
    addPopUp = false;
    editPopUp = false;
    formGroupInvalid = false;
    routeFrom: any;
    NCPDPID: any;
    editModeS: boolean;
    clickType: any;
    copyTans: boolean;
    modalref: any;

    @Input()
    set From(routerFrom: any) {
        this.routeFrom = routerFrom;
    }
    @Input()
    set NCPDPData(data: any) {
        this.ncpdpData = data;
    }
    @Input()
    set PopUpType(type: string) {
        if (type === "ADD") {
            this.editModeS = false;
            this.addPopUp = true;
            this.editPopUp = false;
        } else {
            this.editModeS = true;
            this.editPopUp = true;
            this.addPopUp = false;
        }
    }

    @ViewChild("ncpdpDataPopup", { static: true })
    ncpdpDataPopup: ElementRef;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modalService.hasOpenModals() && this.modelRef) { // esc
            this.modelRef.close();
            this.modelRef = null;
            this.emitEditValue();
            event.preventDefault();
        }
    }

    constructor(
        private modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _fb: FormBuilder,
        private _cmnServ: CommonService,
        private _insurService: InsuranceService,
        private _alertService: AlertService,
        public activeModal: NgbActiveModal,
    ) {
    
    }

    ngOnInit() {
        this._cmnServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp: any) => {
                if(resp) 
                    this.transsetingsDropValues = resp.nCPDPCats;
        });
        this.createFG();
        if (this.ncpdpData) {
            if (this.routeFrom === "Insurance") {
                this.NCPDPID = this.ncpdpData.NCDPId;
            } else {
                this.NCPDPID = this.ncpdpData.NCPId;
            }
        }
        if (this.editModeS) {
            this.EditTransSettings("Edit");
        }
    }

    createFG() {
        this.ncpdpInfoFG = this._fb.group({
            Ncpdp: this._fb.group(new Ncpdp()),
            NcpdpSeg: this._fb.group(new NcpdpSeg())
        });
        if (this.addPopUp) {
            const dataInfo: any = this.ncpdpInfoFG.controls["NcpdpSeg"];
                dataInfo.controls["IsInsuIncl"].patchValue(false);
                dataInfo.controls["IsPatientIncl"].patchValue(false);
                dataInfo.controls["IsClaimsIncl"].patchValue(false);
                dataInfo.controls["IsPharmProvIncl"].patchValue(false);
                dataInfo.controls["IsPrescIncl"].patchValue(false);
                dataInfo.controls["IsCOBIncl"].patchValue(false);
                dataInfo.controls["IsWCompIncl"].patchValue(false);
                dataInfo.controls["IsDURIncl"].patchValue(false);
                dataInfo.controls["IsPricingIncl"].patchValue(false);
                dataInfo.controls["IsCouponIncl"].patchValue(false);
                dataInfo.controls["IsCompoundIncl"].patchValue(false);
                dataInfo.controls["IsPriorApprIncl"].patchValue(false);
                dataInfo.controls["IsClinicalIncl"].patchValue(false);
                dataInfo.controls["IsAddDocIncl"].patchValue(false);
                dataInfo.controls["IsFacilityIncl"].patchValue(false);
                dataInfo.controls["IsNarrativeIncl"].patchValue(false);
        }
        this.modelRef = this.modalService.open(this.ncpdpDataPopup, {
            size: "lg",
            windowClass: "modal-md modal-center-to-page",
            keyboard: false
        });
    }

    get Ncpdp(): FormGroup {
        return this.ncpdpInfoFG.get("Ncpdp") as FormGroup;
    }

    get NcpdpSeg(): FormGroup {
        return this.ncpdpInfoFG.get("NcpdpSeg") as FormGroup;
    }

    get Code(): FormControl {
        return this.ncpdpInfoFG.controls["Ncpdp"].get("Code") as FormControl;
    }

    get NCPDPCatId(): FormControl {
        return this.ncpdpInfoFG.controls["Ncpdp"].get(
            "NCPDPCatId"
        ) as FormControl;
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    EditTransSettings(type) {
        this._insurService.getNcpdpInfo(this.NCPDPID)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                if (type === "Copy" && this.ncpdpInfo) {
                    resp["Ncpdp"]["Code"] = this.ncpdpInfo["Ncpdp"]["Code"];
                } else if (type === "Copy" && !this.ncpdpInfo) {
                    resp["Ncpdp"]["Code"] = null;
                }
                this.ncpdpInfo = resp;
                if (this.ncpdpInfo) {
                  this.generateNCPDPValues();
                }
            }
        });
    }

    generateNCPDPValues() {
        if (this.ncpdpInfo) {
            if (this.ncpdpInfo.NcpdpSeg) {
                if (this.ncpdpInfo.NcpdpSeg.IsPatientIncl) {
                    this.showPatient = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsInsuIncl) {
                    this.showIns = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsClaimsIncl) {
                    this.showClaim = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsPharmProvIncl) {
                    this.showPhar = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsPrescIncl) {
                    this.showPresc = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsCOBIncl) {
                    this.showCOB = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsWCompIncl) {
                    this.showworker = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsDURIncl) {
                    this.showDUR = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsPricingIncl) {
                    this.showpricing = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsCouponIncl) {
                    this.showCoupon = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsCompoundIncl) {
                    this.showCompound = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsPriorApprIncl) {
                    this.showPrior = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsClinicalIncl) {
                    this.showClinical = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsAddDocIncl) {
                    this.showAdd = true;
                }

                if (this.ncpdpInfo.NcpdpSeg.IsFacilityIncl) {
                    this.showFacility = true;
                }
                if (this.ncpdpInfo.NcpdpSeg.IsNarrativeIncl) {
                    this.showNarr = true;
                }
            }
            this.patchValues();
        }
    }

    patchValues() {
        // tslint:disable-next-line:prefer-const
        for (let key in this.ncpdpInfo) {
            if (key && this.ncpdpInfo[key]) {
                this.ncpdpInfoFG.controls[key].patchValue(this.ncpdpInfo[key]);
            }
        }
    }

    getVal(val: any, type: any) {
        this.editModeS = false;
        this.clickType = type;
        if (type === "Patient") {
            if (val.target.id === "IsPatientIncl1") {
                this.showPatient = true;
            } else {
                this.showPatient = false;
            }
        } else if (type === "Insurance") {
            if (val.target.id === "ncpdp3") {
                this.showIns = true;
            } else {
                this.showIns = false;
            }
        } else if (type === "Claims") {
            if (val.target.id === "ncpdp5") {
                this.showClaim = true;
            } else {
                this.showClaim = false;
            }
        } else if (type === "Pharmacy") {
            if (val.target.id === "ncpdp7") {
                this.showPhar = true;
            } else {
                this.showPhar = false;
            }
        } else if (type === "Prescriber") {
            if (val.target.id === "ncpdp9") {
                this.showPresc = true;
            } else {
                this.showPresc = false;
            }
        } else if (type === "COB") {
            if (val.target.id === "ncpdp11") {
                this.showCOB = true;
            } else {
                this.showCOB = false;
            }
        } else if (type === "worker") {
            if (val.target.id === "ncpdp13") {
                this.showworker = true;
            } else {
                this.showworker = false;
            }
        } else if (type === "DUR") {
            if (val.target.id === "ncpdp41") {
                this.showDUR = true;
            } else {
                this.showDUR = false;
            }
        } else if (type === "pricing") {
            if (val.target.id === "ncpdp17") {
                this.showpricing = true;
            } else {
                this.showpricing = false;
            }
        } else if (type === "Coupon") {
            if (val.target.id === "ncpdp19") {
                this.showCoupon = true;
            } else {
                this.showCoupon = false;
            }
        } else if (type === "Compound") {
            if (val.target.id === "ncpdp21") {
                this.showCompound = true;
            } else {
                this.showCompound = false;
            }
        } else if (type === "prior") {
            if (val.target.id === "ncpdp23") {
                this.showPrior = true;
            } else {
                this.showPrior = false;
            }
        } else if (type === "Clinical") {
            if (val.target.id === "ncpdp25") {
                this.showClinical = true;
            } else {
                this.showClinical = false;
            }
        } else if (type === "additional") {
            if (val.target.id === "ncpdp27") {
                this.showAdd = true;
            } else {
                this.showAdd = false;
            }
        } else if (type === "facility") {
            if (val.target.id === "ncpdp29") {
                this.showFacility = true;
            } else {
                this.showFacility = false;
            }
        } else if (type === "narrative") {
            if (val.target.id === "ncpdp31") {
                this.showNarr = true;
            } else {
                this.showNarr = false;
            }
        }
    }

    copyTransPopUp() {
        this.copyTans = true;
         this.modalref = this.modalService.open(CopyncdpComponent, {
            centered: true,
            backdrop: "static",
            keyboard:false
        });
        this.modalref.result.then(res => {
            this.copy(res);
        }) .catch(error => error);
    }

    saveNcpdpInfo() {
        if (this.ncpdpInfoFG.value["Ncpdp"]["Code"] && this.ncpdpInfoFG.value["Ncpdp"]["NCPDPCatId"]) {
            const dataInfo = this.ncpdpInfoFG.value;
            dataInfo.Ncpdp.IsActive = true;
            this._insurService.addNCPDPInfo(dataInfo)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp === 1) {
                    this._alertService.success(MsgConfig.SAVE_SUCCESS);
                   this.emitEditValue();
                   if (this.modelRef) {
                        this.modelRef.close();
                    }
                } else if (resp === 2) {
                    this._alertService.error(MsgConfig.DUPLICATE_NCDP_PAYER_SET);
                } else {
                    this._alertService.error(MsgConfig.SAVE_FAIL);
                    if (this.modelRef) {
                        this.modelRef.close();
                    }
                }
            });
        } else {
            this._alertService.error(MsgConfig.ENTER_REQ_FIELD);
            this.formGroupInvalid = true;
        }
    }

    updateNcpdpInfo() {
        const dataInfo: any = this.ncpdpInfoFG.controls["NcpdpSeg"];
        if (this.updateInfo) {
            this.ncpdpdata = this.ncpdpInfoFG.value;
            this.ncpdpdata.Ncpdp.Id = this.updateInfo.Ncpdp.Id;
            this.ncpdpdata.Ncpdp.Code = this.updateInfo.Ncpdp.Code;
            this.ncpdpdata.NcpdpSeg.Id = this.updateInfo.NcpdpSeg.Id;
            this.ncpdpdata.NcpdpSeg.NCPDPId = this.updateInfo.NcpdpSeg.NCPDPId;
        } else {
            this.ncpdpdata = this.ncpdpInfoFG.value;
        }
        this._insurService.updateNcpdpInfo(this.ncpdpdata)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.emitEditValue();
                if (this.modelRef) {
                    this.modelRef.close();
                }
                this._alertService.success(MsgConfig.UPDATE_SUCCESS);
            } else {
                this._alertService.error(MsgConfig.UPDATE_FAIL);
            }
        });
    }

    copy(value) {
        this.NCPDPID = value;
        this.updateInfo = this.ncpdpInfo;
        this.EditTransSettings("Copy");
    }

    emitEditValue() {
        this.setPopUpValue.emit(false);
    }
}
