
       <div class="row">
        <div class="col-md-12">
            <div class="exprx--common-block">
                <!--<h5 class="erx--label--primary">
                    Diagnosis Code
                </h5>-->
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <eprx-select
                                    (TriggerSelectValue)="checkIsExist($event?.value, 1)"
                                    [RxSelectId]="
                                            'priCode1'
                                        "  [LabelText]="
                                            'Primary Code 1'
                                        " [ControlName]="
                                            'DiagnosisCode'
                                        " [FormGroupName]="
                                            PrimaryDiagnosis1
                                        " [InputValue]="''" [HasMultiple]="false"
                                        [HasBindLabel2]="true" [BindLabel2]="
                                            'DIAGDESCR'
                                        " [BindLabel]="'DIAGCODE'" [BindValue]="'DIAGCODE'"
                                        [LabelForId]="'pr1'" [List]="
                                        (patDiagnis?.TempDiagnosis) ? (patDiagnis?.TempDiagnosis['length'] > 0
                                        ? patDiagnis?.TempDiagnosis
                                        : '') : ''
                                        ">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <eprx-input [LabelText]="'Qualifier'" [HasControl]="false"
                                    [InputValue]="PrimaryDiagnosis1?.value['DiagnosisCode'] ? 'ICD10' : null"
                                    [ReadOnly]="true"></eprx-input>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding_top_25px edit--drug pl-0"
                                    >
                                    <i class="far fa-search fa-lg actions" (click)="
                                            openDiagList('1')
                                        "></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <eprx-select
                                    (TriggerSelectValue)="checkIsExist($event?.value, 2)"
                                    [RxSelectId]="
                                            'priCode2'
                                        " [LabelText]="
                                            'Primary Code 2'
                                        " [ControlName]="
                                            'DiagnosisCode'
                                        " [FormGroupName]="
                                            PrimaryDiagnosis2
                                        " [InputValue]="''" [HasBindLabel2]="true" [BindLabel2]="
                                            'DIAGDESCR'
                                        " [BindLabel]="'DIAGCODE'" [BindValue]="'DIAGCODE'"
                                        [LabelForId]="'pr2'" [List]="
                                        (patDiagnis?.TempDiagnosis) ? (patDiagnis?.TempDiagnosis['length'] > 0
                                        ? patDiagnis?.TempDiagnosis
                                        : '') : ''
                                        ">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <eprx-input [LabelText]="'Qualifier'" [HasControl]="false"
                                    [InputValue]="PrimaryDiagnosis2?.value['DiagnosisCode'] ? 'ICD10' : null"
                                    [ReadOnly]="true"></eprx-input>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding_top_25px edit--drug pl-0"
                                    >
                                    <i class="far fa-search fa-lg actions" (click)="
                                            openDiagList('2')
                                        "></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <eprx-select [RxSelectId]="
                                            'secPrime1'
                                        " [LabelText]="
                                            'Secondary Code 1'
                                        " [ControlName]="
                                            'DiagnosisCode'
                                        "
                                        (TriggerSelectValue)="checkIsExist($event?.value, 3)"
                                        [FormGroupName]="
                                            SecondaryDiagnosis1
                                        " [InputValue]="''" [HasMultiple]="false"
                                        [HasBindLabel2]="true" [BindLabel2]="
                                            'DIAGDESCR'
                                        " [BindLabel]="'DIAGCODE'" [BindValue]="'DIAGCODE'"
                                        [LabelForId]="'sc1'" [List]="
                                        (patDiagnis?.TempDiagnosis) ? (patDiagnis?.TempDiagnosis['length'] > 0
                                        ? patDiagnis?.TempDiagnosis
                                        : '') : ''
                                        " [DropdownPosition]="'top'">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <eprx-input [LabelText]="'Qualifier'" [HasControl]="false"
                                    [InputValue]="SecondaryDiagnosis1?.value['DiagnosisCode'] ? 'ICD10' : null"
                                    [ReadOnly]="true"></eprx-input>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding_top_25px edit--drug pl-0"
                                    >
                                    <i class="far fa-search fa-lg actions" (click)="
                                            openDiagList('3')
                                        "></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <eprx-select
                                    (TriggerSelectValue)="checkIsExist($event?.value, 4)"
                                    [RxSelectId]="
                                            'secPrime1'
                                        " [LabelText]="
                                            'Secondary Code 2'
                                        " [ControlName]="
                                            'DiagnosisCode'
                                        " [FormGroupName]="
                                            SecondaryDiagnosis2
                                        " [InputValue]="''" [HasMultiple]="false"
                                        [HasBindLabel2]="false" [BindLabel2]="
                                            'DIAGDESCR'
                                        " [BindLabel]="'DIAGCODE'" [BindValue]="'DIAGCODE'"
                                        [LabelForId]="'sc2'" [List]="
                                        (patDiagnis?.TempDiagnosis) ? (patDiagnis?.TempDiagnosis['length'] > 0
                                        ? patDiagnis?.TempDiagnosis
                                        : '') : ''
                                        " [DropdownPosition]="'top'">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <eprx-input [LabelText]="'Qualifier'" [HasControl]="false"
                                    [InputValue]="SecondaryDiagnosis2?.value['DiagnosisCode'] ? 'ICD10' : null"
                                    [ReadOnly]="true"></eprx-input>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding_top_25px edit--drug pl-0"
                                    >
                                    <i class="far fa-search fa-lg actions" (click)="
                                            openDiagList('4')
                                        "></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #diagList let-c="close" let-d="dismiss" class="el--popup">
        <div class="modal-header">
            <h4 class="modal-title">Diagnosis List</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <app-diag-list [FmChangeReq]="true" [isPopup]="true" (emitOnDbClick)="closeDiagList($event)"></app-diag-list>
        </div>
        <div class="modal-footer">
            <div class="text-right pull-right col-md-10 padding-0">
                <div>
                    <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="c">
                        <b>C</b> Cancel
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
