import { Pipe, PipeTransform } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Pipe({ name: "getMaxLen" })
export class CustmLn implements PipeTransform {
    Maxlengthkey = [
        { DEF_DAYS_SUPPLY: 3 },
        { DEF_DOSE_TIME_INTERVAL: 2 },
        { TNT_REGI: 8 },
        { TNT_DEA_REGI: 9 },
        { TNT_STATE_SPECIFIC_ID: 15 },
        { TNT_NPI_ID: 15},
        { TNT_NPI_NO: 15},
        { DDI_SCAN_DAYS: 5},
        { TNT_CONTACT_NAME: 50},
        { TNT_PHARM_EMAIL: 320},
        // { SELECT_RXSERIAL_LENGTH: 2}
    ];
    transform(name: string): any {
        let data: FormGroup;
        this.Maxlengthkey.map(sdkey => {
            if (sdkey[name]) {
                data = sdkey[name];
            }
        });
        return data;
    }
}
