import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    HostListener,
    OnChanges
} from "@angular/core";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { CollectionView } from "@grapecity/wijmo";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import {
    SystemData,
    FamilyAddress,
    FamilyInsuCard,
    FamilyInsuCarrier,
    FamilyInsuGroup,
    FamilyInsurance,
    FamilyPhone,
    PatFamily,
    ExistngFamily,
    State,
    City,
    LinkFamily,
    PaginationModel
} from "src/app/models";
import { RegEx } from "src/app/app.regex";
import {
    PatientService,
    AdditionalInsuranceService,
    CommonService,
    AlertService,
    PatPreDrugModalService
} from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"; 
import { debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx"; 
import { PatientInfoUtil } from "src/app/utils/patient-info.util"; 

@Component({
    selector: "app-family-info",
    templateUrl: "./family-info.component.html"
})
export class FamilyInfoComponent implements OnInit, OnChanges {
    paginationModel: PaginationModel = new PaginationModel();
    @ViewChild("SearchFamilyInfo")
    flex: wjcGrid.FlexGrid;

    @ViewChild("familyLink")
    familyLink: any;

    @ViewChild("familyInfoNotExist", { static: true })
    familyInfoNotExist: any;

    @ViewChild("FamilyExistPopUp", { static: true })
    FamilyExistPopUp: any

    citySelected = null;
    stateSelected = null;
    searchFamilyFG: any;
    familyInfo: any;
    modelRef: any;
    totalCount: any;
    pageCount: number;
    searchFamilyInfoWJ: CollectionView;
    searchActiveHeaders: string[];
    pageSize: any;
    pageNumber: number;
    familyInfoFG: FormGroup;
    linkFG: FormGroup;
    editMode: boolean;
    rowinfo: any;
    FamilyLastName: any;
    Address: any;
    patientInfo: any;
    patFamilyData: any;
    filteredGroups: any;
    insuParams: any;
    editFrom: any;
    EditFromOut: any;
    modalName: string;
    modelRef2: any;
    linkModal: any;
    patientsFamilyInfo: any;
    hasFamily: boolean;
    editFamilyInfo: any;
    regex = RegEx;
    inputValue = "";
    existingMembers = [];
    FrmSearchFamily: any;
    familyAddedInfo: any;
    memberSelected: any;
    patFrLastName: any;
    patSearchFr: string;
    hasToOpenPopup: any;
    formGroupInvalid: boolean;
    familymemId: any;
    familyMembersWJ: CollectionView;
    familyHeader: string[];
    familymemSlct: any;
    familyAddPatInfo: any;
    linkedData: any;
    unsubscribe$: Subject<void> = new Subject();
    rxFamilyAdd: boolean;
    patmodelRef: any;

    @Input() systemData: SystemData;
    @Input() familyInfoFrom: any;
    @Input() patientId: number;
    @Input() FrmFamily: boolean;
    @Input() patFG: FormGroup;
    insuranceData$: Observable<any>;
    importFamilyId: any;

    @Input()
    set PatientInfo(patient: any) {
        if (patient) {
            this.patientInfo = patient;
            this.patFamilyData = this.patientInfo["PatFamily"];
        }
    }

    @Output()
    HasLinked = new EventEmitter<any>();

    @Output()
    familyUpdated = new EventEmitter<any>();

    @Output()
    emitPatSelectId = new EventEmitter<any>();

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && (this.modelRef || this.linkModal)) {
            // esc
            if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
            } else if (this.linkModal) {
                this.linkModal.close();
                this.linkModal = null;
            } else {
                this.popUpClosed();
                if (this.modelRef2) {
                this.modelRef2.close();
                this.modelRef2 = null;
                }
            }
            event.preventDefault();
        } if (event.ctrlKey && !this.modalService.hasOpenModals() && this.familyInfoFrom === "Rx") {
            if (event.which === 70) {
                this.patFamilyAdd(this.FamilyExistPopUp, this.familyInfoNotExist, this.familyInfo);
            }
        }
    }

    constructor(
        private _fb: FormBuilder,
        private _editPatient: PatientService,
        private modalService: NgbModal,
        private _additionalInsu: AdditionalInsuranceService,
        private _commonServ: CommonService,
        private _alertService: AlertService,
        private _patUtil: PatientInfoUtil,
        private _patPreDrugModalServ: PatPreDrugModalService
    ) {
        this.insuranceData$ = this._commonServ.insuranceData$;
        this.createFG();
    }

    createFG() {
        this.familyInfoFG = this._fb.group({
            FamilyAddress: this._fb.group(new FamilyAddress()),
            FamilyInsuCard: this._fb.group(new FamilyInsuCard()),
            FamilyInsuCarrier: this._fb.group(new FamilyInsuCarrier()),
            FamilyInsuGrp: this._fb.group(new FamilyInsuGroup()),
            FamilyInsurance: this._fb.group(new FamilyInsurance()),
            FamilyPhone: this._fb.group(new FamilyPhone()),
            PatFamily: this._fb.group(new PatFamily()),
            ExistngFamilyMembers: this._fb.array([new ExistngFamily()])
        });

        this.searchFamilyFG = this._fb.group({
            gridParam: this._fb.group({
                SearchText: null,
                SearchBy: null,
                OrderByColumn: null,
                SortDirection: null,
                PageNumber: this.paginationModel.pageNumber,
                PageSize: this.paginationModel.pageSize
            }),
            Name: null,
            ZipCode: null,
            Insurance: null
        });
    }

    ngOnInit() {
        if (this.familyInfoFrom === "Patient") {
            this.getFamilies();
        }

        if (this.familyInfoFrom === "Rx" && this.patientId) {
            this.getEditPatientInfo();
        }
    }

    ngOnChanges(changes?: any) {
        if (changes && changes.patFG && changes.patFG.currentValue) {
            this.getEditPatientInfo();
        }
    }

    async getEditPatientInfo() {
        const resp = await this._commonServ.getEditPatientInformation(this.patientId).toPromise();
        this.patientInfo = resp;
        this.patFamilyData = this.patientInfo["PatFamily"];
    }

    getPatientFamilyDetails(content) {
        if (
            this.patientInfo &&
            this.patientInfo.PatFamily &&
            this.patientInfo.PatFamily["Id"]
        ) {
            this._editPatient
                .getPatientPatDetails(this.patientInfo.PatFamily["Id"])
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.patientsFamilyInfo = resp;
                        this.hasFamily = true;
                    } else {
                        this.hasFamily = false;
                    }
                    this.openFamilyPopUp(content);
                });
        } else {
            this.hasFamily = false;
            this.openFamilyPopUp(content);
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    openFamilyPopUp(content) {
        if (this.hasFamily) {
            this.modelRef = this.modalService.open(content, {
                centered: true,
                backdrop: false,
                keyboard: false,
                windowClass: "medium-modal",
                size: "sm"
            });
        } else {
            this.modelRef = this.modalService.open(this.familyInfoNotExist, {
                centered: true,
                backdrop: false,
                keyboard: false,
                windowClass: "medium-modal",
                size: "sm"
            });
        }
    }

    // check Family Exist For RxPat
    openRxFamilyEdit(content) {
        this._editPatient
            .getPatientPatDetails(this.patFamilyData["Id"])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.editFamilyInfo = resp;
                    this.patchEditFamilyValues(content);
                }
            });
    }

    // To Open Add Family Popup
    openPopUpModalAdd(content, type?: any) {
        this.modalName = "Family Add";
        this.formGroupInvalid = false;
        this.familyInfoFG.reset();
        this.clearCityStateValues();
        this.editMode = false;
        if (type && type === "StartNew") {
            this.FrmSearchFamily = "StartNew";
            this.patchInfoForFamilyAdd();
        } else {
            this.FrmSearchFamily = null;
        }
        this.modelRef = this.modalService.open(content, {
            size: "lg",
            backdrop: false,
            keyboard: false
        });
    }

    // Patch default values while Add Family
    patchInfoForFamilyAdd(data?: any) {
        this.stateSelected = new State();
        this.citySelected = new City();
        let patchInfoData;
        if (this.FrmSearchFamily === "StartNew") {
            patchInfoData = this.patientInfo;
        } else if (data) {
            patchInfoData = data;
        }
        if (patchInfoData["Addresses"]) {
            this.citySelected.Id = patchInfoData["Addresses"]["CityId"];
            this.citySelected.StateId = patchInfoData["Addresses"]["StateId"];
            this.stateSelected.Id = patchInfoData["Addresses"]["StateId"];
            this.citySelected.Name = patchInfoData["Addresses"]["CityName"];
            this.stateSelected.Name = patchInfoData["Addresses"]["StateName"];
        }
        if (patchInfoData["PatFamily"]) {
            this.familyInfoFG.controls["PatFamily"].patchValue(patchInfoData["PatFamily"]);
        } else if (patchInfoData["Person"] && patchInfoData["Person"]["LastName"]) {
            this.PatFamily.controls["Name"].patchValue(
                patchInfoData["Person"]["LastName"]
            );
        }
        const controlNames = [
            { patCntrl: "Insurances", familyCntrl: "FamilyInsurance" },
            { patCntrl: "InsuCards", familyCntrl: "FamilyInsuCard" },
            { patCntrl: "InsuCarrier", familyCntrl: "FamilyInsuCarrier" },
            { patCntrl: "InsuGroups", familyCntrl: "FamilyInsuGrp" },
            { patCntrl: "Addresses", familyCntrl: "FamilyAddress" }
        ];
        controlNames.map(cntrlName => {
            if (patchInfoData[cntrlName["patCntrl"]]) {
                if (cntrlName["patCntrl"] === "Insurances") {
                    const insurance = this.insuranceData$["source"]["value"].filter(
                        val =>
                            val.InsuCarrierId ==
                            patchInfoData.Insurances.InsurerId
                    );
                    this.inputValue = insurance[0].PlanCode;
                    this.filterGroupsBasedOnInsurerId(
                        patchInfoData.Insurances.InsurerId
                    );
                }
                const fg: any = this.familyInfoFG.controls[
                    cntrlName["familyCntrl"]
                ];
                this.familyInfoFG.controls[cntrlName["familyCntrl"]].patchValue(
                    patchInfoData[cntrlName["patCntrl"]]
                );
                fg.controls["Id"].patchValue(null);
            }
        });
    }

    // patient search for last name
    lastNameSelected(value, content) {
        if (value && value["patientid"]) {
            this._commonServ
                .getEditPatientInformation(value["patientid"])
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    this.patFrLastName = resp;
                    this._commonServ._patientInfo$.next(resp);
                    let hasFamily = false;
                    if (
                        this.existingMembers &&
                        this.existingMembers.length > 0
                    ) {
                        hasFamily = this.existingMembers.find(
                            val => val.PatientId === value["patientid"]
                        );
                    }
                    if (!hasFamily) {
                        this._editPatient
                            .checkPatientHasFamily(value["patientid"], 0)
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(check => {
                                this.memberSelected = {
                                    PatientId: value["patientid"],
                                    LastName: value["lastname"],
                                    FirstName: value["firstname"],
                                    Phone: value["telephone"]
                                };
                                if (!check || check === 0) {
                                    this.existingMembers.push(
                                        this.memberSelected
                                    );
                                    this.generateFamilyWJ(this.existingMembers);
                                    if (this.hasToOpenPopup) {
                                        this.openPopUpModalAdd(
                                            this.hasToOpenPopup
                                        );
                                    }
                                    this.patchInfoForFamilyAdd(resp);
                                } else {
                                    this.modalService.open(content, {
                                        centered: true,
                                        backdrop: false
                                    });
                                }
                            });
                    } else {
                        if (this.hasToOpenPopup) {
                            this.openPopUpModalAdd(this.hasToOpenPopup);
                        }
                        this.patchInfoForFamilyAdd(resp);
                    }
                });
        }
    }

    // To Open Patient SearchPopUp
    openPatientSearch(content, type?: any, content2?: any) {
        if (type) {
            this.patSearchFr = "addPatientData";
        } else {
            this.patSearchFr = null;
        }
        if (content2) {
            this.hasToOpenPopup = content2;
        } else {
            this.hasToOpenPopup = null;
        }
        this.patmodelRef = this.modalService.open(content, {
            centered: true,
            backdrop: false,
            keyboard: false,
            windowClass: "medium-modal",
            size: "sm"
        });
    }

    // After member selected
    patientSelected(value, content, isFrmRxPat?: any) {
        if (value && value["patientid"]) {
            if (this.patSearchFr === "addPatientData") {
                if (this.patmodelRef) {
                    this.patmodelRef.close();
                    this.patmodelRef = null;
                }
                this.lastNameSelected(value, content);
                return;
            }
            let hasFamily = false;
            if (this.existingMembers && this.existingMembers.length > 0) {
                hasFamily = this.existingMembers.find(
                    val => val.PatientId === value["patientid"]
                );
            }
            if (value["patientid"] === this.patientInfo["Patient"]["Id"]) {
                hasFamily = true;
            }
            if (hasFamily) {
                this._alertService.warning(
                    "Patient is already linked to this family. Please select another",
                    false,
                    "long"
                );
            } else {
                if (this.patmodelRef) {
                    this.patmodelRef.close();
                    this.patmodelRef = null;
                }
                const familyId: any = this.familyInfoFG.controls["PatFamily"];
                this._editPatient
                    .checkPatientHasFamily(
                        value["patientid"],
                        familyId.value["Id"] ? familyId.value["Id"] : 0
                    )
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(resp => {
                        this.memberSelected = {
                            PatientId: value["patientid"],
                            LastName: value["lastname"],
                            FirstName: value["firstname"],
                            Phone: value["telephone"]
                        };
                        if (!resp || resp === 0) {
                            if (!isFrmRxPat) {
                            this.existingMembers.push(this.memberSelected);
                            this.generateFamilyWJ(this.existingMembers);
                            }
                            if (this.editMode) {
                                this.addMember();
                            }
                            if (isFrmRxPat) {
                                this._commonServ.getEditPatientInformation(value["patientid"])
                                .pipe(takeUntil(this.unsubscribe$))
                                .subscribe(patInfo => {
                                    this.patientInfo = patInfo;
                                    this.modelRef = this.modalService.open(
                                        isFrmRxPat,
                                        {
                                            windowClass: "medium-modal",
                                            size: "sm",
                                            centered: true,
                                            backdrop: false
                                        }
                                    );
                                });
                            }
                        } else {
                            this.modalService.open(content, {
                                centered: true,
                                backdrop: false,
                                keyboard: false,
                            });
                        }
                    });
            }
        }
    }

    // Open Add Family popup
    patFamilyAdd(content, content2, content3) {
        this.createFG();
        this.rxFamilyAdd = true;
        this.patchInfoForFamilyAdd(this.patientInfo);
        this.addPatientFromFamily("Patient", content, content2, content3);
    }

    // If Patient Not Found

    addPatientFromFamily(type, content, content2?: any, content3?: any) {
        if (type === "Patient") {
            this._patPreDrugModalServ._patPreDrugModal$.next("patient");
            const medalRef = this._patPreDrugModalServ.modalInstanceRef;
            const modalRefPAt = this._patPreDrugModalServ.modalInstanceRef.componentInstance;
            const familyFG = this.familyInfoFG.value;
            const addFamily = {
                PatFamily: [],
                FamilyAddress: [],
                FamilyPhone: [],
                FamilyInsuCard: [],
                FamilyInsurance: [],
                FamilyInsuCarrier: [],
                FamilyInsuGrp: [],
                ExistngFamilyMembers: []
            };
            addFamily.PatFamily.push(familyFG.PatFamily);
            addFamily.FamilyAddress.push(familyFG.FamilyAddress);
            addFamily.FamilyPhone.push(familyFG.FamilyPhone);
            addFamily.FamilyInsuCard.push(familyFG.FamilyInsuCard);
            addFamily.FamilyInsurance.push(familyFG.FamilyInsurance);
            addFamily.FamilyInsuCarrier.push(familyFG.FamilyInsuCarrier);
            addFamily.FamilyInsuGrp.push(familyFG.FamilyInsuGrp);
            const FG = this._patUtil.generatePatientInfoFG();
            this._patUtil.patchPatAddDefaultVal(FG);
            this._patUtil.patchPatDataFrmFamily(this.patientInfo , FG);
           // this._patUtil.patchPatientFrmFamily(addFamily, FG);
            modalRefPAt.patientID = null;
            modalRefPAt.addPatientModel = FG;
            modalRefPAt.FrmFamily = true;
            modalRefPAt.ClosePatientModal
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                    medalRef.close();
                    if (this.patmodelRef) {
                        this.patmodelRef.close();
                        this.patmodelRef = null;
                    }
                    if (res["Type"] !== "Cancel") {
                        if (res &&
                            (!(this.patientInfo && this.patientInfo["PatFamily"] && this.patientInfo["PatFamily"]["Id"]) || !content3)) {
                            this._commonServ.getRxPatientDetailsById(res["PatientId"])
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(patDetails => {
                                    if (patDetails) {
                                        if (content2) {
                                            this.familyAddPatInfo = patDetails;
                                        }
                                        this.patientSelected(patDetails, content, content2);
                                    }
                                });
                        } else if (res && (this.patientInfo && this.patientInfo["PatFamily"] && this.patientInfo["PatFamily"]["Id"])) {
                            const MembersSelected = {
                                PatientId: res["PatientId"],
                                FamilyId: this.patientInfo["PatFamily"]["Id"],
                                IsOverrideGeneralDetails: false,
                                IsOverrideInsuranceDetails: false,
                                FamilyLastName: null
                            };
                            this._editPatient.linkFamilyInfo([MembersSelected])
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(linked => {
                                if (linked) {
                                        this._commonServ.getEditPatientInformation(res["PatientId"])
                                        .pipe(takeUntil(this.unsubscribe$))
                                        .subscribe(patdet => {
                                            this.patientInfo = patdet;
                                            this.getPatientFamilyDetails(content3);
                                        });
                                    }
                            });
                        } else {
                            this._alertService.error("Patient Add Unsucessful.");
                            this.rxFamilyAdd = false;
                        }
                    }

                }

            );
        }
    }

    // Open Edit Family Popup
    openEditPopUpModal(item, content, type?: any) {
        if (type === "Out") {
            this.editFrom = "O";
        } else if (type === "FrmRx") {
            this.editFrom = "FrmRx";
        } else {
            this.editFrom = null;
        }
        this.formGroupInvalid = false;
        if (
            this.patientsFamilyInfo &&
            this.patientsFamilyInfo["PatFamily"]["0"]["Id"] === item["Id"]
        ) {
            this.editFamilyInfo = this.patientsFamilyInfo;
            this.patchEditFamilyValues(content);
        } else {
            this._editPatient
                .getPatientPatDetails(item["Id"])
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.editFamilyInfo = resp;
                        this.patchEditFamilyValues(content);
                    }
                });
        }
    }

    // Patch Edit Family details
    patchEditFamilyValues(content) {
        this.familyInfoFG.reset();
        this.familyInfoFG.markAsUntouched();
        for (const key in this.editFamilyInfo) {
            if (
                this.editFamilyInfo[key] &&
                this.editFamilyInfo[key][0] &&
                key !== "FamilyInsuGrp" &&
                key !== "ExistngFamilyMembers"
            ) {
                this.familyInfoFG.controls[key].patchValue(
                    this.editFamilyInfo[key][0]
                );
                if (key === "FamilyInsurance") {
                    const insurance = this.insuranceData$["source"]["value"].filter(
                        val =>
                            val.InsuCarrierId ==
                            this.editFamilyInfo[key][0]["InsurerId"]
                    );
                    this.inputValue = insurance[0].PlanCode;
                    this.filterGroupsBasedOnInsurerId(
                        this.editFamilyInfo[key][0]["InsurerId"]
                    );
                }
            }
        }

        this.existingMembers = Object.assign(
            [],
            this.editFamilyInfo["ExistngFamilyMembers"]
        );
        if (this.editFrom === "O" || this.editFrom === "FrmRx") {
            if (
                this.editFamilyInfo["ExistngFamilyMembers"] &&
                this.editFamilyInfo["ExistngFamilyMembers"].length > 0
            ) {
                if (
                    this.editFamilyInfo["ExistngFamilyMembers"].findIndex(
                        val =>
                            val.PatientId === this.patientInfo["Patient"]["Id"]
                    ) !== -1
                ) {
                    const index = this.existingMembers.findIndex(
                        val =>
                            val.PatientId === this.patientInfo["Patient"]["Id"]
                    );
                    this.existingMembers.splice(index, 1);
                    this.generateFamilyWJ(this.existingMembers);
                }
            }
        }
        this.stateSelected = new State();
        this.citySelected = new City();
        if (this.editFamilyInfo.FamilyAddress) {
            this.citySelected.Id = this.editFamilyInfo.FamilyAddress["0"][
                "CityId"
            ];
            this.citySelected.StateId = this.editFamilyInfo.FamilyAddress["0"][
                "StateId"
            ];
            this.stateSelected.Id = this.editFamilyInfo.FamilyAddress["0"][
                "StateId"
            ];
            this.citySelected.Name = this.editFamilyInfo.FamilyAddress["0"][
                "CityName"
            ];
            this.stateSelected.Name = this.editFamilyInfo.FamilyAddress["0"][
                "StateName"
            ];
        }
        this.familyInfoFG.markAsPristine();
        this.modelRef = this.modalService.open(content, {
            size: "lg",
            backdrop: false
        });
        this.modalName = "EditFamily";
        this.editMode = true;
    }

    generateFamilyWJ(data) {
        this.familymemId = data && data[0] ? data[0]["PatientId"] : null;
        this.familyMembersWJ = new CollectionView(
            data.map((family, i) => {
                const j = {};
                j["Pat#"] = family["PatientId"];
                j["Last Name"] = family["LastName"];
                j["First Name"] = family["FirstName"];
                j["Phone"] = family["Phone"];
                return j;
            })
        );
        this.familyHeader = [
            "Select",
            "Pat#",
            "Last Name",
            "First Name",
            "Phone"
        ];
    }

    // Patient Family Save
    addFamilyInfo(content) {
        // Sentry.captureException([
        //     {name: content},
        //     {name:this.familyInfoFG.valid}
        // ]);
        this.setDefaultValues();
        if (!this.familyInfoFG.valid) {
            this.formGroupInvalid = true;
            this._alertService.error("Enter Required Fields.");
        } else {
            this.formGroupInvalid = false;
            const familyFG = this.familyInfoFG.value;
            const addFamily = {
                PatFamily: [],
                FamilyAddress: [],
                FamilyPhone: [],
                FamilyInsuCard: [],
                FamilyInsurance: [],
                FamilyInsuCarrier: [],
                FamilyInsuGrp: [],
                ExistngFamilyMembers: []
            };
            addFamily.ExistngFamilyMembers = Object.assign(
                [],
                this.existingMembers
            );
            if (this.FrmSearchFamily === "StartNew" || (this.familyInfoFrom === "Rx" && this.patSearchFr !== "addPatientData") ) {
                const selectedPat = [
                    {
                        PatientId: this.patientInfo["Patient"]["Id"],
                        LastName: this.patientInfo["Person"]["LastName"],
                        FirstName: this.patientInfo["Person"]["FirstName"],
                        Phone:
                            this.patientInfo["Phones"] &&
                            this.patientInfo["Phones"][0]
                                ? this.patientInfo["Phones"][0]["Id"]
                                : null
                    }
                ];
                addFamily.ExistngFamilyMembers.push(selectedPat[0]);
            }
            addFamily.PatFamily.push(familyFG.PatFamily);
            addFamily.FamilyAddress.push(familyFG.FamilyAddress);
            addFamily.FamilyPhone.push(familyFG.FamilyPhone);
            addFamily.FamilyInsuCard.push(familyFG.FamilyInsuCard);
            addFamily.FamilyInsurance.push(familyFG.FamilyInsurance);
            addFamily.FamilyInsuCarrier.push(familyFG.FamilyInsuCarrier);
            addFamily.FamilyInsuGrp.push(familyFG.FamilyInsuGrp);
            this._editPatient.addFamilyInfo(addFamily)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    if (this.modelRef) {
                        this.modelRef.close();
                        this.modelRef = null;
                    }
                    this._alertService.success(
                        "Family info added successfully."
                    );
                    this.addMultipleMembers(
                        addFamily.ExistngFamilyMembers,
                        resp
                    );
                    familyFG.PatFamily["Id"] = resp;
                    if (this.FrmSearchFamily === "StartNew" || this.rxFamilyAdd === true) {
                        this.patFamilyData = familyFG.PatFamily;
                        this.familyInfoFG.value["PatFamily"]["Id"] = resp;
                        this.linkInfo(this.familyInfoFG.value);
                        this.openLinkModal(content);
                    } else {
                        if (
                            addFamily.ExistngFamilyMembers &&
                            addFamily.ExistngFamilyMembers.length > 0 &&
                            addFamily.ExistngFamilyMembers.find(
                                val =>
                                    val["PatientId"] ===
                                    this.patientInfo["Patient"]["Id"]
                            )
                        ) {
                            this.patFamilyData = familyFG.PatFamily;
                        }
                        this.searchFamilyFG.reset();
                        this.searchFamilyFG.markAsUntouched();
                        const fg: any = this.searchFamilyFG.controls[
                            "gridParam"
                        ];
                        fg.controls["PageNumber"].patchValue(1);
                        fg.controls["PageSize"].patchValue(15);
                        this.getFamilies();
                    }
                }
            });
        }
    }

    // Patient Family Update
    updateFamilyInfo(content) {
        this.setDefaultValues();
        if (!this.familyInfoFG.valid) {
            this.formGroupInvalid = true;
            this._alertService.error("Enter Required Fields.");
        } else {
            this.formGroupInvalid = false;
            const familyFG = this.familyInfoFG.value;
            const addFamily = {
                PatFamily: [],
                FamilyAddress: [],
                FamilyPhone: [],
                FamilyInsuCard: [],
                FamilyInsurance: [],
                FamilyInsuCarrier: [],
                FamilyInsuGrp: [],
                ExistngFamilyMembers: [],
                IsFamilyNameUpdated: false
            };
            addFamily.ExistngFamilyMembers = this.editFamilyInfo[
                "ExistngFamilyMembers"
            ];
            addFamily.PatFamily.push(familyFG.PatFamily);
            addFamily.FamilyAddress.push(familyFG.FamilyAddress);
            addFamily.FamilyPhone.push(familyFG.FamilyPhone);
            addFamily.FamilyInsuCard.push(familyFG.FamilyInsuCard);
            addFamily.FamilyInsurance.push(familyFG.FamilyInsurance);
            addFamily.FamilyInsuCarrier.push(familyFG.FamilyInsuCarrier);
            addFamily.FamilyInsuGrp.push(familyFG.FamilyInsuGrp);
            addFamily.IsFamilyNameUpdated = false;
            // addFamily.IsFamilyNameUpdated = this.editFamilyInfo["PatFamily"][0]["Name"] !== familyFG.PatFamily["Name"];
            this._editPatient.updateFamily(addFamily)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this._alertService.success(
                        "Family info updated successfully."
                    );
                    if (this.editFrom === "O" || this.editFrom === "FrmRx") {
                        if (this.modelRef) {
                            this.modelRef.close();
                            this.modelRef = null;
                        }
                        this.openLinkModal(content);
                        this.linkInfo(this.familyInfoFG.value);
                    } else {
                        if (this.modelRef) {
                            this.modelRef.close();
                            this.modelRef = null;
                        }
                        this.searchFamilyFG.reset();
                        this.searchFamilyFG.markAsUntouched();
                        const fg: any = this.searchFamilyFG.controls[
                            "gridParam"
                        ];
                        fg.controls["PageNumber"].patchValue(1);
                        fg.controls["PageSize"].patchValue(15);
                        this.getFamilies();
                    }
                }
            });
        }
    }

    // Open Import Modal
    openLinkModal(content) {
        this.modalName = "Link";
        this.linkModal = this.modalService.open(content, {backdrop: false, keyboard: false});
    }

    // Patching Data For Import Modal
    linkInfo(data) {
        if (!(this.editFrom === "O" || this.editFrom === "FrmRx" || this.FrmSearchFamily === "StartNew")) {
            this.rowinfo = data;
            this.importFamilyId = data["Id"];
            this.FamilyLastName = data["Name"];
            this.Address = data["Address1"] + (data["Address2"] ? data["Address2"] : "");
        } else {
            this.importFamilyId = data["PatFamily"]["Id"];
            this.rowinfo = data["PatFamily"];
            this.FamilyLastName = data["PatFamily"]["Name"];
            this.Address =
                data["FamilyAddress"]["AddressLine1"] +
                (data["FamilyAddress"]["AddressLine2"] ? data["FamilyAddress"]["AddressLine2"] : "");
        }
        this.linkFG = this._fb.group(new LinkFamily());
    }

    // Import Family Save
    importFamilyProfile() {
        if (this.linkModal) {
            this.linkModal.close();
        }
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.modelRef2) {
            this.modelRef2.close();
            this.modalName = null;
        }
        const MembersSelected = {
            PatientId: this.patientInfo["Patient"]["Id"],
            FamilyId: this.importFamilyId,
            IsOverrideGeneralDetails: this.linkFG.value["IsOverrideGeneralDetails"],
            IsOverrideInsuranceDetails: this.linkFG.value["IsOverrideInsuranceDetails"],
            FamilyLastName: this.linkFG.value["IsLastNameUpdate"] ? this.FamilyLastName : null
        };
        this._editPatient.linkFamilyInfo([MembersSelected])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async resp => {
            if (resp) {
                if (this.modelRef) {
                    this.modelRef.close();
                }
                if (this.modelRef) {
                    this.modelRef.close();
                    this.modelRef = null;
                }
                if (this.rxFamilyAdd === true) {
                    this.rxFamilyAdd = false;
                    this.patientId = this.patientInfo["Patient"]["Id"];
                    await this.getEditPatientInfo();
                    this.emitPatSelectId.emit(this.patientInfo["Patient"]["Id"]);
                } else {
                    this._editPatient
                    .getPatientPatDetails((this.linkedData && this.linkedData["Id"]) ? this.linkedData["Id"] : this.importFamilyId)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(async familyDetail => {
                        if (familyDetail) {
                            const patDet = await this._editPatient.getEditPatientInformation(this.patientInfo["Patient"]["Id"]).toPromise();
                            this.patientInfo["PatFamily"] = patDet["PatFamily"];
                            this.patFamilyData = familyDetail["PatFamily"][0];
                            this.patientsFamilyInfo = familyDetail;
                            this.hasFamily = true;
                            this.familyUpdated.emit({result: familyDetail, overRideName: this.linkFG.value["IsLastNameUpdate"],
                            overRideGeneral: this.linkFG.value["IsOverrideGeneralDetails"],
                            overRideInsu: this.linkFG.value["IsOverrideInsuranceDetails"]});
                        }
                    });
                }

            }
        });

    }

    closeImportPopup() {
        if (this.linkModal) {
            this.linkModal.close();
        }
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.modelRef2) {
            this.modelRef2.close();
            this.modalName = null;
        }
        if (this.rxFamilyAdd === true) {
            this.rxFamilyAdd = false;
        } else {
            this._editPatient
                    .getPatientPatDetails((this.linkedData && this.linkedData["Id"]) ? this.linkedData["Id"] : this.importFamilyId)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(async familyDetail => {
                        if (familyDetail) {
                            const patDet = await this._editPatient.getEditPatientInformation(this.patientInfo["Patient"]["Id"]).toPromise();
                            this.patientInfo["PatFamily"] = patDet["PatFamily"];
                            this.patFamilyData = familyDetail["PatFamily"][0];
                            this.patientsFamilyInfo = familyDetail;
                            this.hasFamily = true;
                        }
                    });
        }
    }

    // Delete Members
    deleteExistingMembers(Id) {
        if (this.editMode) {
            this._editPatient
                .deleteFamilyMember(Id, this.patFamilyData["Id"])
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    const index = this.existingMembers.findIndex(
                        val => val.PatientId === Id
                    );
                    this.existingMembers.splice(index, 1);
                    this.generateFamilyWJ(this.existingMembers);
                });
        } else {
            const index = this.existingMembers.findIndex(
                val => val.PatientId === Id
            );
            this.existingMembers.splice(index, 1);
            this.generateFamilyWJ(this.existingMembers);
        }
    }

    // Add Multiple members
    addMultipleMembers(Members, FamilyId) {
        const valueForLink = [];
        Members.map(val => {
            const MembersSelected = {
                PatientId: val["PatientId"],
                FamilyId: FamilyId,
                IsOverrideGeneralDetails: false,
                IsOverrideInsuranceDetails: false,
                FamilyLastName: null
            };
            valueForLink.push(MembersSelected);
        });
        this._editPatient.linkFamilyInfo(valueForLink)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {});
    }

    // Add Individual members
    addMember(type?: any) {
        if (this.editMode) {
            const familyId: any = this.familyInfoFG.controls["PatFamily"];
            const MembersSelected = {
                PatientId: this.memberSelected["PatientId"],
                FamilyId: familyId.value["Id"],
                IsOverrideGeneralDetails: false,
                IsOverrideInsuranceDetails: false,
                FamilyLastName: null
            };
            this._editPatient
                .linkFamilyInfo([MembersSelected])
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        if (type && type === "Update") {
                            this.existingMembers.push(this.memberSelected);
                            this.generateFamilyWJ(this.existingMembers);
                            this.memberSelected = null;
                        }
                    }
                });
        } else {
            this.existingMembers.push(this.memberSelected);
            this.generateFamilyWJ(this.existingMembers);
            this.memberSelected = null;
            if (this.patSearchFr === "addPatientData") {
                if (this.hasToOpenPopup) {
                    this.openPopUpModalAdd(this.hasToOpenPopup);
                }
                this.patchInfoForFamilyAdd(this.patFrLastName);
            }
        }
    }

    // Add Member On Link button Press
    linkData(data, content) {
        this.linkedData = data;
        this.editFrom = null;
        this.FrmSearchFamily = null;
        this.openLinkModal(content);
        this.linkInfo(data);
    }

    // Link Data On Key Press
    linkdataOnKeyPress(grid, content, event) {
        if (event && event.which) {
            event.preventDefault();
        }
        this.linkData(grid.selectedItems[0].data, content);
    }

    getFamilies() {
        this._editPatient
            .getFamilyInfo(this.searchFamilyFG.value)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp["length"] > 0) {
                    this.familyInfo = resp;
                    this.totalCount = this.familyInfo[0].TotalCount;
                    this.familyInofWJ(resp);
                } else {
                    this.totalCount = 0;
                }
            });
    }

    selectFamilyMember(isChecked: boolean, checkedVal: any) {
        if (isChecked) {
            this.familymemSlct = checkedVal;
            this.familymemId = checkedVal["Pat#"];
        }
    }

    selectRadioOnKeyEvents(grid) {
        this.selectFamilyMember(true, grid.selectedItems[0]);
    }

    init1(flex: wjcGrid.FlexGrid) {
        flex.focus();
    }

    keyeventOnFamilyWijimo(event) {
        if (event.ctrlKey) {
            if (event.which === 67) {
                event.preventDefault();
                if (this.modelRef) {
                    this.modelRef.close();
                    this.modelRef = null;
                }
            }
        }
    }

    // selected patient for rx
    selectedPatFrFill(Id) {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this._commonServ.getEditPatientInformation(Id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.patientInfo = resp;
                this.emitPatSelectId.emit(Id);
            }
        });
    }

    familyInofWJ(familySearch: any) {
        if (this.totalCount !== 0) {
            this.totalCount = familySearch[0].TotalCount;
            this.searchFamilyInfoWJ = new CollectionView(
                familySearch.map((family, i) => {
                    const j = {};
                    j["data"] = family;
                    j["FamilyId"] = family.Id;
                    j["Last Name"] = family.Name;
                    j["AddressLine 1"] = family.Address1;
                    j["AddressLine 2"] = family.Address2;
                    j["City"] = family.City;
                    j["State"] = family.State;
                    j["ZipCode"] = family.ZipCode;
                    j["Telephone"] = family.xPhone;
                    j["Primary Insurance"] = family.PrimaryInsurance;
                    j["Policy Number"] = family.PolicyNum;
                    return j;
                })
            );
            this.searchActiveHeaders = [
                "Actions",
                "Last Name",
                "AddressLine 1",
                "AddressLine 2",
                "City",
                "State",
                "ZipCode",
                "Telephone",
                "Primary Insurance",
                "Policy Number"
            ];
        }
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => (term.length < 1 ? [] : this.filterByPcnBin(term)))
        );

    filterByPcnBin(term) {
        const searchTerms = term.split(",");
        let filteredData = this.insuranceData$["source"]["value"].filter(v => {
            let valExists = false;
            ["PlanCode", "InsuranceName", "BinNum"].forEach(function(key) {
                const val = v[key] ? v[key] : "";
                valExists =
                    valExists ||
                    val
                        .toString()
                        .toLowerCase()
                        .startsWith(searchTerms[0].toLocaleLowerCase());
            });
            return valExists;
        });
        if (searchTerms[1]) {
            filteredData = filteredData.filter(v => {
                let valExists = false;
                const val = v["ProcessorNum"] ? v["ProcessorNum"] : "";
                valExists =
                    valExists ||
                    val
                        .toString()
                        .toLowerCase()
                        .startsWith(searchTerms[1].toLocaleLowerCase());
                return valExists;
            });
        }
        return filteredData.splice(0, 20);
    }

    formatter = (x: any) => {
        return x.InsurerCode;
    };

    selectedInsType(val) {
        this.inputValue = val.item.InsurerCode;
        this.FamilyInsurance.controls["InsurerId"].setValue(
            val.item.InsuCarrierId
        );
        this.FamilyInsurance.controls["InsuGrpId"].setValue(null);
        this.filterGroupsBasedOnInsurerId(val.item.InsuCarrierId);
    }

    filterGroupsBasedOnInsurerId(insuCarrierId) {
        this._additionalInsu.getInsuGroups(insuCarrierId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp["length"] !== 0) {
                this.filteredGroups = resp;
            }
        });
    }

    setDefaultValues() {
        const addFG: any = this.familyInfoFG.controls["FamilyAddress"];
        addFG.controls["IsActive"].setValue(true);
        addFG.controls["CountryId"].setValue(1);

        const insuFG: any = this.familyInfoFG.controls["FamilyInsurance"];

        insuFG.controls["ActiveStatusId"].setValue(1);
        insuFG.controls["IsPatAssigned"].setValue(false);

        const insuGrpFG: any = this.familyInfoFG.controls["FamilyInsuGrp"];
        if (insuGrpFG.controls["Name"].value !== null) {
            insuGrpFG.controls["IsActive"].setValue(true);
        }

        if (this.xPhone.value !== null) {
            const phoneFG: any = this.familyInfoFG.controls["FamilyPhone"];
            phoneFG.controls["IsActive"].setValue(true);
            phoneFG.controls["PhoneCatId"].setValue(3);
        }

        const familyFG: any = this.familyInfoFG.controls["PatFamily"];
        familyFG.controls["IsActive"].setValue(true);

        if (this.PolicyNum.value !== null) {
            const insCardFG: any = this.familyInfoFG.controls["FamilyInsuCard"];
            insCardFG.controls["IsActive"].setValue(true);
        }
    }

    openPopUpModal(content) {
        this.modalName = "Search";
        this.searchFamilyFG.controls["Name"].patchValue(
            this.patientInfo["Person"]["LastName"]
                ? this.patientInfo["Person"]["LastName"].trim()
                : null
        );
        this.searchFamilyFG.controls["ZipCode"].patchValue(
            this.patientInfo["Addresses"]["ZipCode"]
                ? this.patientInfo["Addresses"]["ZipCode"].trim()
                : null
        );
        this.getFilteredData();
        setTimeout(() => {
            this.modelRef2 = this.modalService.open(content,  {size: "lg", backdrop: false, keyboard: false});
        }, 800);
    }

    resetPageNumber() {
        this.searchFamilyFG.controls["gridParam"].value["PageNumber"] = 1;
    }

    getFilteredData() {
        this.resetPageNumber();
        this.getFamilies();
    }

    clearSearch() {
        this.resetPageNumber();
        this.searchFamilyFG.controls["Name"].patchValue(null);
        this.searchFamilyFG.controls["ZipCode"].patchValue(null);
        this.searchFamilyFG.controls["Insurance"].patchValue(null);
    }

    popUpClosed() {
        if (document.getElementById("familyInfo") && this.familyInfoFrom !== "Rx") {
            document.getElementById("familyInfo").focus();
        }
        this.clearSearch();
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    setPage(page: any) {
        this.pageNumber = page;
        this.searchFamilyFG.controls["gridParam"].value[
            "PageNumber"
        ] = this.pageNumber;
        this.getFamilies();
    }

    setSize(size: number) {
        this.pageSize = size;
        this.pageNumber = 1;
        this.resetPageNumber();
        this.searchFamilyFG.controls["gridParam"].value[
            "PageSize"
        ] = this.pageSize;
        this.getFamilies();
    }
    exportExcel() {
        wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
            this.flex,
            {formatItem: (args) =>  args.xlsxCell.formula = null},
            "FamilyInfo.xlsx"
        );
    }

    deleteFamilyInfo(data) {
        const familyId = data["FamilyId"];
        this._editPatient.deleteFamily(familyId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this._alertService.success(
                    "Patient family info deleted successfully."
                );
                this.getFamilies();
            }
        });
    }

    clearCityStateValues() {
        this.citySelected = "";
        this.stateSelected = "";
    }
    insCitySelected(val: any) {
        // this.citySelected = new City();
        this.FamilyAddress.controls.CityId.patchValue(val.Id);
        this.FamilyAddress.controls.CityName.patchValue(val.Name);
    }

    insStateSelected(val: any) {
        this.FamilyAddress.controls.StateName.patchValue(val.Name);
        this.FamilyAddress.controls.StateId.patchValue(val.Id);
        this.citySelected = new City();
    }
    insZipSelected(zipMaster: any) {
        this.citySelected = new City();
        this.citySelected.Id = zipMaster.CityId;
        this.citySelected.StateId = zipMaster.StateId;
        this.citySelected.Name = zipMaster.CityName;
        this.FamilyAddress.controls.CityId.patchValue(zipMaster.CityId);

        this.stateSelected = new State();
        this.stateSelected.Id = zipMaster.StateId;
        this.stateSelected.Name = zipMaster.StateName;
        this.FamilyAddress.controls.StateId.patchValue(zipMaster.StateId);
    }

    get FamilyAddress(): FormGroup {
        return this.familyInfoFG.get("FamilyAddress") as FormGroup;
    }

    get FamilyInsuCard(): FormGroup {
        return this.familyInfoFG.get("FamilyInsuCard") as FormGroup;
    }

    get FamilyInsuGrp(): FormGroup {
        return this.familyInfoFG.get("FamilyInsuGrp") as FormGroup;
    }

    get FamilyInsurance(): FormGroup {
        return this.familyInfoFG.get("FamilyInsurance") as FormGroup;
    }

    get FamilyPhone(): FormGroup {
        return this.familyInfoFG.get("FamilyPhone") as FormGroup;
    }

    get PatFamily(): FormGroup {
        return this.familyInfoFG.get("PatFamily") as FormGroup;
    }

    get InsurerId(): FormControl {
        return this.familyInfoFG.controls["FamilyInsurance"].get(
            "InsurerId"
        ) as FormControl;
    }

    get Name(): FormControl {
        return this.familyInfoFG.controls["PatFamily"].get(
            "Name"
        ) as FormControl;
    }

    get AddressLine1(): FormControl {
        return this.familyInfoFG.controls["FamilyAddress"].get(
            "AddressLine1"
        ) as FormControl;
    }

    get ZipCode(): FormControl {
        return this.familyInfoFG.controls["FamilyAddress"].get(
            "ZipCode"
        ) as FormControl;
    }

    get CityId(): FormControl {
        return this.familyInfoFG.controls["FamilyAddress"].get(
            "CityId"
        ) as FormControl;
    }

    get StateId(): FormControl {
        return this.familyInfoFG.controls["FamilyAddress"].get(
            "StateId"
        ) as FormControl;
    }

    get xPhone(): FormControl {
        return this.familyInfoFG.controls["FamilyPhone"].get(
            "xPhone"
        ) as FormControl;
    }

    get PolicyNum(): FormControl {
        return this.familyInfoFG.controls["FamilyInsuCard"].get(
            "PolicyNum"
        ) as FormControl;
    }
}
