import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { CommonService } from "src/app/services";
import { WijimoUtil } from "src/app/utils";
import { CollectionView } from "@grapecity/wijmo";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-rx-basic-display",
  templateUrl: "./rx-basic-display.component.html",
})
export class RxBasicDisplayComponent implements OnInit {
    modelRef: any;
    refillList: any[];
    controlRxWJ: CollectionView;
    actvHeaders: any;
    hasData = false;
    selectedRx = [];
    totalData = [];
    slctdRxObj: any = {};
    selectedRxs: any[] = [];
    wjHeaders: any;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set RxPick(rf: any) {
        if (rf) {
            this.refillList = rf;
        }
    }

    @Output()
    CloseRxDetailsPoP = new EventEmitter<null>();
  constructor(private _wijimoUtils: WijimoUtil, private _cmnSvc: CommonService) { }

  ngOnInit() {
      if (this.refillList && this.refillList.length) {
      this.generateList(this.refillList);
      }
      this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
        if (w && w["WijmoKey"] && w["WijmoKey"] === "rxBasicDisplayListWJ") {
                this.patchDefaultValHeaders();
        }
        });
  }
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("rxBasicDisplayListWJ");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("rxBasicDisplayListWJ", storedWJ);
 }
 ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}
  generateList(data) {
    this.controlRxWJ = new CollectionView(
        data.map((controlRx, i) => {
            const k = {};
            k["Rx#"] = controlRx.Prescription.PrescNum
                ? controlRx.Prescription.PrescNum.toString()
                : "";
            k["Date Filled"] =  (controlRx.PrescReFill.FillDtTm) ? moment.utc(
                controlRx.PrescReFill.FillDtTm
            ).local().format("MM/DD/YYYY") : "";
            k["Ref#"] = controlRx.PrescReFill.ReFillNum;
            k["Fill#"] = this._cmnSvc.checkIsPartialFill(controlRx.PrescReFill, controlRx.Prescription)
                ? controlRx.PrescReFill.PartialFillNo : " ";
            k["Insurance"] =
                controlRx.insuranceInfo &&
                    controlRx.insuranceInfo.insuCarrier
                    ? controlRx.insuranceInfo.insuCarrier.InsurerCode
                    : "--";
            k["Qty"] = controlRx.PrescReFill.DispQty;
            k["Supply Days"] = controlRx.PrescReFill.SupplyDays;
            k["Date Ordered"] = (controlRx.PrescReFill.OrderDtTm) ? moment.utc(
                controlRx.PrescReFill.OrderDtTm
            ).local().format("MM/DD/YYYY") : "";
            k["Auth#"] = controlRx.Prescription.ReFillNum;
            k["Patient Name"] =
                controlRx.Patient.lastname +
                ", " +
                controlRx.Patient.firstname;
            k["Drug Name"] = controlRx.Drug.drugname;
            k["Prescriber Name"] =
                controlRx.Prescriber.prescriberlastname +
                ", " +
                controlRx.Prescriber.prescriberfirstname;
            //TODO datepickup object is not done
            k["Pickup Date"] = "";
            k["RowId"] = i;
            k["PrescRefillId"] = controlRx.PrescReFill.Id;
            k["IsDeleted"] = controlRx.PrescReFill.IsDeleted;
            return k;
        })
    );
    this.hasData = true;
  }
 optedRx(isChecked: boolean, checkedVal: any) {
    if (isChecked) {
        this.slctdRxObj[checkedVal.RowId] = this.refillList[checkedVal.RowId];
    } else {
        delete this.slctdRxObj[checkedVal.RowId];
    }
    const convrtdArray = Object.keys(this.slctdRxObj).map(
        i => this.slctdRxObj[i]
    );
    this.selectedRxs = convrtdArray;
}
 closeModal(isSelectedType) {
    this.CloseRxDetailsPoP.emit(isSelectedType);
 }
  pickRxs() {
    const selectedRxsData: any = [];
    this.selectedRxs.map((item: any) => {
        selectedRxsData.push(item.PrescReFill.Id);
    });
    this.CloseRxDetailsPoP.emit(selectedRxsData);
  }

}
