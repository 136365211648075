import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter } from "@angular/core";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import { CollectionView } from "@grapecity/wijmo";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { FilesService, RphDashboardService, AlertService, BussineceIntelligenceService } from "../../../services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as wjcGrid from "@grapecity/wijmo.grid";
import { MsgConfig } from "src/app/app.messages";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PaginationModel } from "src/app/models/elastc-rx.model";

@Component({
    selector: "app-deferred-reason-list",
    templateUrl: "./deferred-reason-list.component.html"
})
export class DeferredReasonListComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    drugWarn: any;
    defReasonWJ: CollectionView;
    actvHeaders: string[];
    totalCount: any;
    isDataExists = true;
    defReasonFG: FormGroup;
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    hideFilterForm = false;
    code: string;
    unsubscribe$: Subject<void> = new Subject();
    description: string;
    @ViewChild("deferredlist")
    deferredlist: wjcGrid.FlexGrid;

    @ViewChild("DfrdReason", { static: true })
    DfrdReason: any;

    frmReq: any;
    editMode: any;
    formGroupInvalid: any;
    isPopup: any;
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }
    loadResp: boolean;
    displayGrid: boolean;
    loading: boolean;
    progressValue: number;

    @Input()
    set IsPopUp(ri: any) {
        this.isPopup = ri;
    }

    @Output()
    emitOnDbClick = new EventEmitter<any>();

    @Output()
    emitKeyDownEvent = new EventEmitter<any>();

    searchFG: FormGroup;
    modelref: any;
    deferredReasonList: any;
    deleteDataId: any;

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27 && this._modalService.hasOpenModals()) {
            if (this.modelref) {
                this.modelref.close();
                this.modelref = null;
            }
        }
    }


  constructor(private _fb: FormBuilder, private _modalService: NgbModal, private _bussinessSvc: BussineceIntelligenceService,
     private _defReason: RphDashboardService, private _alertService: AlertService) {
    this.defReasonFG = this._fb.group({
        Name: null,
        Id: null,
        IsActive: true,
        CreatedDtTm: null,
        ModifiedDtTm: null,
        TenantId: null
    });
    this.searchFG = this._fb.group({
        SearchText: [],
        PageNumber : this.pageNu,
        PageSize: this.Size,
    });
  }



  ngOnInit() {
      this.getInfo();
      this.actvHeaders = [
        "Actions",
        "Deferred Reason"
    ];
    this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
    this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
  }

  getInfo() {
    if (!this.isPopup) {
        if (document.getElementById('loader'))
            document.getElementById('loader').style.display = 'block';
        this.loadResp = false;
        this.startProgress();
    }
      this._defReason.getDeferredReasonList(this.searchFG.value, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
          if (resp && resp.length) {
            this.deferredReasonList = resp;
            this.generateWJ(resp);
            if (!this.isPopup) {
                this.displayGrid = true;
                this.isDataExists = true;
                this.loaderDisplay(); 
            }
            this.isDataExists = true;
            this.totalCount = resp[0]["TotalCount"];
          } else {
            if (!this.isPopup) {
                this.displayGrid = true;
                this.loaderDisplay(); 
            }
            this.totalCount = 0;
            this.isDataExists = false;
            this.defReasonWJ = new CollectionView(null);
          }
      }
  );
}

init(flex: wjcGrid.FlexGrid) {
  flex.columnHeaders.rows[0].wordWrap = true;
}

generateWJ(info: any) {
  this.totalCount = info.TotalRecords;
  if (info) {
      this.defReasonWJ = new CollectionView(
          info.map((defrred, i) => {
              const j = {};
              j["Deferred Reason"] = defrred.Name;
              j["Id"] = defrred.Id;
              return j;
          })
      );
      this.actvHeaders = [
          "Actions",
          "Deferred Reason"
      ];
  }
}
show() {
  this.hideFilterForm = !this.hideFilterForm;
}

clearSearchData() {
    this.searchFG.controls["SearchText"].patchValue(null);
    this.setSize(this.paginationModel.pageSize);
}

setPage(page: number) {
    this.pageNu = page;
    const dataInfo = this.searchFG.value;
    dataInfo.PageNumber  = page;
    this.getInfo();
}

setSize(size: any) {
    const dataInfo = this.searchFG.value;
    dataInfo.Pagesize = size;
    this.Size = size;
    this.setPage(this.paginationModel.pageNumber);
}

openModal(type, content, val?: any) {
    if (type === "Add") {
        this.editMode = false;
        this.defReasonFG.controls["Name"].patchValue(null);
    } else {
        this.editMode = true;
        const selectedData = this.deferredReasonList.find(data => data.Id === val.Id);
        this.defReasonFG.patchValue(selectedData);
    }
    this.modelref = this._modalService.open(content, {centered: true, backdrop: false});
}

addDfrdReason() {
    if (this.defReasonFG.value["Name"]) {
    this.formGroupInvalid = false;
    this.defReasonFG.value["IsActive"] = true;
    this._defReason.addDeferredReason(this.defReasonFG.value)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
        if (resp && resp !== 2) {
            this.getInfo();
            if (this.modelref) {
                this.modelref.close();
                this.modelref = null;
            }
            this._alertService.success("Deferred reason saved successfully.")
        } else if (resp === 2) {
            this.defReasonFG.controls["Name"].patchValue(null);
            if (document.getElementById("DfrdReason")) {
            document.getElementById("DfrdReason").focus();
            }
            this._alertService.error("Deferred Reason already exist. Please enter another one", false, "long");
        }
    });
    } else {
        this.formGroupInvalid = true;
    }
}

updateDfrsReason() {
    if (this.defReasonFG.value["Name"]) {
        this.formGroupInvalid = false;
        this._defReason.updateDeferredReason(this.defReasonFG.value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp !== 2) {
                this.getInfo();
                if (this.modelref) {
                    this.modelref.close();
                    this.modelref = null;
                }
                this._alertService.success("Deferred reason updated successfully.")
            } else if (resp === 2) {
                this.defReasonFG.controls["Name"].patchValue(null);
                if (document.getElementById("DfrdReason")) {
                document.getElementById("DfrdReason").focus();
                }
                this._alertService.error("Deferred Reason already exist. Please enter another one.", false, "long");
            }
        });
        } else {
            this.formGroupInvalid = true;
        }
}

deletePopUp(content, item) {
    this._defReason.getDeferredDeleteResponse(item)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
        if (resp === -1) {
            this._alertService.error("Selected Deferred Reason cannot be deleted as it is already in use by Rxs.");
        } else {
            this.modelref = this._modalService.open(content, { centered: true , keyboard: false, backdrop: false, windowClass: "large--content"});
            this.deleteDataId = item;
        }
    });
}

deleteDfrd() {
    this._defReason.deleteDeferredReason(this.deleteDataId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
        if (resp && resp !== -1) {
            this.setPage(1);
        }
        this._alertService.success("Deferred reason deleted successfully.")
    });
}

exportExcel() {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(this.deferredlist, {formatItem: (args) =>  args.xlsxCell.formula = null}, "diagnosislist.xlsx");
}

Init(grid) {
    grid?.cells.hostElement.addEventListener('keydown', (e) => {
        if (e.key == 'Enter') {
            if (this.isPopup) {
                this.emitOnDbClick.emit(grid.collectionView["_src"][grid.selection.row]);
            } else {
                if (e.which === 13) {
                    this.openModal("Edit", this.DfrdReason, grid.collectionView["_src"][grid.selection.row]);
                }
            }
        }
    });
}

onRowDblclicked(s: any, e: any) {
    if (e.which === 13) {
        e.preventDefault();
    }
    if (this.isPopup) {
    this.emitOnDbClick.emit(s.selectedRows[0].dataItem);
    } else {
        if (e.which === 13) {
            this.openModal("Edit", this.DfrdReason, s.selectedRows[0].dataItem);
        }
    }
}

keydownOnWijimo(event, content) {
    if (event.ctrlKey) {
        if (event.which === 65) {
            event.preventDefault();
            this.openModal("Add", content);
        } else if (event.which === 67 && this.isPopup) {
            event.preventDefault();
            this.emitKeyDownEvent.emit("Cancel");
        }
    }
}

focusToFirst(event) {
    if (document.getElementById("DeferredName")) {
        event.preventDefault();
        setTimeout(() => {
            document.getElementById("DeferredName").focus();
        }, 10);

    }
}
ngOnDestroy() {
    this.unsubscribe$.next();
     this.unsubscribe$.complete();
   }

   startProgress() {
    this.loading = true;
    this.progressValue = 0;
    const interval = setInterval(() => {
      if (this.loadResp) {
        this.progressValue = 100;
        clearInterval(interval);
      } else {
        this.progressValue += 25;
      }
    }, 100);
    }
    loaderDisplay() {
    this.loadResp = true;
    if (this.loadResp) {
        setTimeout(()=>{
            this.loading = false;
            if (document.getElementById('loader'))
                document.getElementById('loader').style.display = 'none';
        }, 300)
    }
    }

}
