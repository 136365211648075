import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxModel } from "src/app/models";
import { AlertService, RxService } from "src/app/services";
import { CommonUtil, InsuranceUtil } from "src/app/utils";
import { PriorApprovalResultComponent } from "../prior-approval-result/prior-approval-result.component"; 
import { Subject } from "rxjs";


@Component({
    selector: "app-prior-apprvl",
    templateUrl: "./prior-apprvl.component.html"
})
export class PriorApprvlComponent implements OnInit {
    @Input() rxInfo: RxModel;
    @Input() transmResp: any;
    @Input() rxAndActionType: any;

    @ViewChild("CMMPriorApproval", { static: true })
    CMMPriorApproval: any;

    @ViewChild("CMMWarning", { static: true })
    CMMWarning: any;

    cmmWarningResp: any;
    insuranceInfolatest: any;
    unsubscribe$: Subject<void> = new Subject();
    @Output() CancelPopUpClosed = new EventEmitter();

    constructor(private _modalService: NgbModal, private _rxService: RxService, private _alertServ: AlertService,
         private _cmnUtils: CommonUtil, private _insuUtils: InsuranceUtil) {}

    ngOnInit() {
        this.checkforPriorApproval();
    }

    async checkforPriorApproval() {
        const insurance: any = this.rxInfo.Patient.insurance.filter(
            a => a.insuranceid === +this.rxInfo.RefillInsu.InsuId
        )[0];
        this.insuranceInfolatest = await this._insuUtils.getInsuranceInfo(+insurance.insuplanId);
        const response: any = await this._cmnUtils.checkForPriorApprvlMandatoryFields(this.rxInfo, this.insuranceInfolatest, false, true, this.transmResp);
        if (response) {
            this._alertServ.error("To send Prior Approval request, following missing/invalid fields need to be changed: " + response);
            this.closeModal();
        } else {
            this.openModal(this.CMMPriorApproval);
        }
    }
    openModal(modalName: any) {
        const modalRef = this._modalService.open(modalName, {
            backdrop: false,
            centered: true,
            windowClass: "large--content",
            keyboard: false
        });
    }

    async submitCMM() {
        const res = await this._cmnUtils.checkForPriorApprvlMandatoryFields(this.rxInfo, this.insuranceInfolatest, true, true, this.transmResp);
        if (res.response) {
            let priorResultModal = this._modalService.open(
                PriorApprovalResultComponent,
                {
                    size: "lg",
                    backdrop: "static",
                    keyboard: false,
                    windowClass: "md-xx-lg"
                }
            );
            priorResultModal.componentInstance.rxData = this.rxInfo;
            priorResultModal.componentInstance.priorResp = res.response;
            priorResultModal.componentInstance.postData = res.dataToPost;
            priorResultModal.componentInstance.IsPopUpClosed
            .subscribe(
                (resp) => {
                    priorResultModal.close();
                    this.closeModal();
                }
            );
        } else {
            this.closeModal();
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    closeModal() {
        this.CancelPopUpClosed.emit(null);
    }
}
