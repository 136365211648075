import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-sso-errors',
  templateUrl: './sso-errors.component.html'
})
export class SsoErrorsComponent implements OnInit {
    pageType: string;
    EventKey: string;
    Message: string;

  constructor(private _ar: ActivatedRoute,) {
   }

  ngOnInit() {
    this.getMessageAndEventKey();
  }

  getMessageAndEventKey() {
        this._ar.params.subscribe((params) => {
            this.pageType = params["type"];
        });
        if (this.pageType === "error" || this.pageType === "validationerror" && sessionStorage.getItem("$1")) {
            const errorresponseText = JSON.parse(sessionStorage.getItem("$1"));
            if (!this.isEmpty(errorresponseText) && !this.isEmpty(errorresponseText.EventKey)) {
                this.EventKey = errorresponseText.EventKey;
            }
            if (!this.isEmpty(errorresponseText) && !this.isEmpty(errorresponseText.Message)) {
                this.Message = errorresponseText.Message;
            }
        }
    }
    isEmpty(input) {
        /*if (input === null || input === undefined) {
            return !input;
        } else if (typeof input === "object" && input.constructor === Object) {
            return !(Object.keys(input).length > 0);
        } else if (
            (typeof input === "object" && input.constructor === Array) ||  typeof input === "string") {
            return !(input.length > 0);
        } else {
            return false;
        }*/
        if(!input || (typeof input === "object" && !(Object.keys(input).length)) ||
            ((typeof input === "object" && input.constructor === Array) || (typeof input === "string") &&  !(input.length))) {
            return true;
        } else {
            return false;
        }
    }

}
