<ng-template #RxNotesLogListPopUp let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4> {{HeaderText ? HeaderText : "Rx Notes"}}  </h4>
        <button type= button [tabindex]="-1" class="close"
        aria-label="close" (click)="closeModal()" >
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
      </div>
        <div class="modal-body notes_display_scroll">
            <div>
                <div class="card mt-3 border_10" *ngFor="let notes of notesList?.Note; let i=index" > 
                    <div class="card-header card_header">
                          <div class="row">
                              <div class="col-4 px-0">
                                  <div class="row col">
                                      <div class="col-4 pr-0">
                                          # {{i+1}}
                                      </div>
                                      <div class="col px-0">
                                          Category: {{notes?.NoteType}}
                                      </div>
                                  </div>
                              </div>
                              <div class="col-8 px-0">
                                      <div class="row col px-0">
                                          <div class="col-5 px-0">
                                              Created Date & Time: {{notes?.ModifiedDtTm | datetimeUtc:'time'}}
                                         </div>
                                         <div class="col  text-center px-0">
                                              Created By: {{notes?.UserName | uppercase}}
                                         </div>
                                         <div class="col pr-0 text-right">
                                          <div class="row">
                                              <div class="col-8 px-0">
                                                Popup Screen
                                              </div>
                                              <div class="col-1">
                                                  <div class="checkbox-toggle">
                                                      <input
                                                      class="form-check-input-notes" type="checkbox"
                                                      [id]="'notes'+i" name=""
                                                      title="{{titleMsg}}"
                                                      [disabled]="IsFromPage"
                                                      [checked]="notes?.IsShownAsPopup"
                                                      (change)="changeStatus(notes,$event?.target?.checked,notes?.NoteType)"
                                                      />
                                                     </div>
                                                      <label class="form-check-label" [for]="'notes'+i"></label>
                                              </div>
                                          </div>
                                         </div>
                                      </div>
                              </div>
                          </div>
                  </div>
                  <div class="card-body" style="    max-height: 8rem;
                  overflow-y: scroll;">
                     <span [innerHTML]="(notes?.xNote) ? notes?.xNote  : notes?.Notes"></span>
                  </div>
  
              </div>
            </div>

        <div *ngIf="!notesList?.Note?.length" class="text-center">
            <h5>No Notes available for this Rx.</h5>
        </div>
    </div>
        <div class="modal-footer">
            <button    *ngIf="IsFromPage" type="submit" class="hotkey_primary" (click)="closeModal('add')" appShortcutKey InputKey="a"  (keydown.enter)="closeModal('add')"><b>A</b>Add Notes </button>
            <button footer autofocus type="submit" class="hotkey_primary"  (keydown.enter)="closeModal()" (click)="d('Cross click');closeModal()" appShortcutKey InputKey="o"><b>O</b>OK </button>
        </div>
</ng-template>