import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, HostListener, ViewChild } from "@angular/core";
import { DrugService, AlertService, CommonService, InsuranceService, EditDrugService, RxService } from "src/app/services";
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import * as _ from "lodash";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from "rxjs/operators";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuditLogUtils } from "../../../utils/audit-log.util";
import { UdpCurrencyMaskPipe } from "src/app/pipes";
import { SystemData } from "src/app/models";
import { CollectionView } from "@grapecity/wijmo";
import * as moment from "moment";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { CommonUtil, NRxUtils, WijimoUtil } from "src/app/utils";
import { EditDrugComponent } from "../../edit-drug";
import { RxStore } from "src/app/store";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-comp-ingred-list",
    templateUrl: "./comp-ingred-list.component.html"
})
export class CompIngredListComponent implements OnInit {
    DrugIngredFG: FormGroup;
    formArrayCount = 0;
    drugLots: any;
    tempDrugId = 0;
    drugId: number;
    originalData: any;
    lotNumList = [];
    isAddDrug: boolean;
    drugData: any;
    isFromDrug: boolean;
    numberPipe = new UdpCurrencyMaskPipe();
    selectedRecord: any;
    systemData: SystemData;
    minDate: { year: number; month: number; day: number; };
    totalCount: string;
    totalAwp: string;
    totalCost: string;
    actvHeaders: any[];
    ingredWJ: CollectionView;
    editMode: boolean;
    AddOrUpdateValue: FormGroup;
    openAddOrEdit: boolean = false;
    totalIngredLsit: any[] = [];
    totalIngredLsitForOrginalDrug: any[] = [];
    editedId: any;
    focusOnDrug: boolean;
    inglistWJ: wjcGrid.FlexGrid;
    IsQtySufficientExist = 0;
    confirmModel: any;
    confirmModalName: string;
    unitawp = 0;
    unitCost = 0;
    erxPrescribedDrugs: any;
    restricDrugSearch: boolean;
    modalRef: any;
    message: any;
    drugInfo: Object;
    RemoveDrugIngredientsIds = "";
    drugInfoToBeEdited: any;
    SelectedDrugName: string;
    RemoveCompIds = "";
    eventRaised = false;
    isDisabledEXP: boolean;
    hasStopLotExp : boolean;
    druglotFC : FormGroup
    compDrugId : number;
    unsubscribe$: Subject<void> = new Subject();
    finalLotList = [];
    modalRef1: any;
    hasStopLot : boolean;
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
    compoundDrugCostBasis: any;
    isBilled: any;
    dispQty: any;
    totalPrice: any;
    newTotalPrice: any;
    @Input()
    set DrugData(data: any) {
        this.drugData = data;
    }

    @Input()
    set DrugId(drugId: number) {
        this.drugId = drugId;
    }

    @Input()
    set IsFromDrug(data: boolean) {
        this.isFromDrug = data;
    }

    @Input()
    set ErxPrescribedDrugs(data: any) {
        this.erxPrescribedDrugs = data;
    }

    @Input() dataFmRx: any;
    @Input() prescRef: number;
    @Input() rxType: any;
    @Input() openCompIng: any;
    @Input() rxFG: any;
    @Input()
    set isGreenBilled(data: any) {
        this.isBilled = data;
    }

    @Output() EmitDrugData = new EventEmitter<any>();

    @Output() EmitPopUpClosed = new EventEmitter<any>();

    @Output() EmitCompoundData = new EventEmitter<any>();

    @ViewChild("WARNINGFORQTYSUFFICIENT")
    WARNINGFORQTYSUFFICIENT: any;

    @ViewChild("COMPOUNDINGREDIENTRESTRICTION", { static: true })
    COMPOUNDINGREDIENTRESTRICTION: any;

    @ViewChild("COMPOUNDING", { static: true })
    COMPOUNDING: any;

    constructor(
        private _drugServ: DrugService,
        private _fb: FormBuilder,
        public activeModal: NgbActiveModal,
        private _alertService: AlertService,
        private _cdr: ChangeDetectorRef,
        private _auditUtils: AuditLogUtils,
        private modalService: NgbModal,
        private _commonServ: CommonService,
        private _insuServ: InsuranceService,
        private _dateUtil: DateCheckUtil,
        private _wijimoUtils: WijimoUtil,
        private _drgSvc:EditDrugService,
        private _nrxUtls:NRxUtils,
        private _formatsUtil:FormatsUtil,
        private _rxStore: RxStore, public _cmnUtils: CommonUtil
    ) {
        this.DrugIngredFG = this._fb.group({
            Notes: [],
            IngredList: this._fb.array([]),
            EffortLvlId: [],
            DispQty: ["0.000"],
            BeyondUseDays: null,
            TimeToMake: null,
            Base: null,
            Price: null
        });
        this.AddOrUpdateValue = this._fb.group({
            DrugName: [],
            DrugId: [],
            Id: [0],
            NDC: [],
            Qty: ["0.000"],
            AWP: [],
            OrginalAwp: [],
            Basis: [],
            LotNum: [],
            ExpDt: [],
            CreatedDtTm: [],
            DispQty: ["0.000"],
            EffortLvlId: [],
            drugUnit: [],
            Mod1: [],
            Mod2: [],
            Mod3: [],
            IngPropPer: [],
            Cost: ["0.00"],
            IsActive: [],
            CompDrugId: this.drugId ? this.drugId : null,
            Name: [],
            IsQtySufficient: [],
            IsOptional: []
        });
    }

    ngOnInit() {
        if (this.drugId && (this.isFromDrug || this.prescRef)) {
            this.getIngredients(this.drugId);
            this.isAddDrug = false;
        } else if (this.drugId && !this.prescRef) {
            this.isAddDrug = false;
            if (this.drugData) {
                const insuStng = this._insuServ.insuSettings$["source"]["value"];
                //  “EP-4569 : hiding the setting as per Ketan's inputs.”
                // if (!(insuStng && insuStng["InsuPlanOther"] && insuStng["InsuPlanOther"]["CompoundIngrcandiffer"])) {
                    this.drugData = this.reCalculateByDispQty(this.drugData);
                // }
                this.addDrugPatchValues();
            } else {
                this.getIngredients(this.drugId);
            }
        } else {
            this.isAddDrug = true;
            this.addDrugPatchValues();
            this.openAddWindow();
        }
        this._commonServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.systemData = resp;
        });
        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "compoundIngListWJ") {
                this.patchDefaultValHeaders();
            }
        });
        if (this.rxType !== "rf" && !this.openCompIng) {
            this.modalRef1 = this.modalService.open(this.COMPOUNDING, { centered: true, keyboard: false, windowClass: "md-x-lg", size:"lg"});
        }
        const resp = this._commonServ.priceSchedule$["source"]["value"];
        if (resp && resp.PriceScheduleList && resp.PriceScheduleList.length && this.dataFmRx)
            this.DrugIngredFG.controls.Base.patchValue(resp.PriceScheduleList.find(v => v['Id'] === this.dataFmRx?.['PriceCD'])?.BaseName)
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("compoundIngListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("compoundIngListWJ", storedWJ);
    }

    get IngredList() {
        return this.DrugIngredFG.get("IngredList") as FormArray;
    }
    reCalculateByDispQty(values) {
        this.newTotalPrice = 0;
        const data = values["list"];
        this.dispQty = values["DispQty"] ? values["DispQty"] : ((values && values["list"] && values["list"].length && values["list"][0] && values["list"][0].DispQty) ? values["list"][0].DispQty : null);
        let totalQty = 0;
        let totalAwp = 0;
        let totalPrice = 0
        data.map(val => {
            val['OrginalAwp'] ? val['OrginalAwp'] : val['OrginalAwp'] = val['AWP'];
            if (val["IsActive"]) {
                totalQty = (val["Qty"]) ? totalQty + parseFloat(val["Qty"]) : totalQty;
                totalAwp = (val["OrginalAwp"]) ? totalAwp + parseFloat(val["OrginalAwp"]) : totalAwp;
                totalPrice = (val["Price"]) ? totalPrice + parseFloat(val["Price"]) : totalPrice;
            }
        });
        const qtyPatchStng = this._commonServ.getSetttingValue("DrugSettings", "COMP_DISPQTY_AS_RXQTY");
        if (qtyPatchStng && qtyPatchStng === "1" && +this.dispQty) {
            totalQty = parseFloat(this.dispQty);
        }
        if (this.dataFmRx && this.dataFmRx["Qty"] && parseFloat(this.dataFmRx["Qty"]) && totalQty) {
            values["DispQty"] = parseFloat(values["DispQty"]) * (parseFloat(this.dataFmRx["Qty"]) / totalQty);
        }
        data.map(ing => {
            if (ing["IsActive"]) {
                if (this.dataFmRx && this.dataFmRx["Qty"] && parseFloat(this.dataFmRx["Qty"])) {
                    ing["Qty"] = (parseFloat(ing["Qty"]) * (parseFloat(this.dataFmRx["Qty"]) / totalQty)).toFixed(3);
                }
                if (this.dataFmRx && this.dataFmRx["Price"] && parseFloat(this.dataFmRx["Price"])) {
                    let value: any;
                    value = (parseFloat(ing["Price"]) / totalPrice)
                    ing["Price"] = (value * parseFloat(this.dataFmRx["Price"]));
                    this.newTotalPrice = this.newTotalPrice + (+ing["Price"])
                }
            }
        });
        this.newTotalPrice.toFixed(2);
        this.DrugIngredFG.controls.Price.patchValue(this.newTotalPrice.toFixed(2));
        if (this.openCompIng && this.rxFG.value.rxType == 'er') {
            values["list"].map(val => val = Object.assign(val, {PrescReFillId: this.rxFG.value.PrescReFill.Id}));
            if (this.dataFmRx && this.dataFmRx['Price'] && this.newTotalPrice && (+this.dataFmRx['Price'] - +this.newTotalPrice)) {
                const diff = +this.dataFmRx['Price'] - +this.newTotalPrice;
                values["list"][values["list"].length - 1].Price = (+values["list"][values["list"].length - 1].Price + diff)
            }
                    const dataToPost = {
                        list: values["list"],
                        note: values["note"],
                        EffortLvlId: values["EffortLvlId"],
                        DispQty: +values["DispQty"] ? values["DispQty"] : values["list"]?.[0]?.DispQty,
                        BeyondUseDays: values["BeyondUseDays"],
                        TimeToMake: values["TimeToMake"],
                        PrescReFillId: this.rxFG.value.PrescReFill.Id,
                        RemoveDrugIngredientsIds: "",
                        IsForRx: true,
                        CompDrugId: this.rxFG.value.Drug.id
                    };
                    this._drugServ.AddIngredientDrug(dataToPost).subscribe(res=>{});
        }
        return values;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async getIngredients(drugId: number) {
        let resp = await this._drugServ.getDrugRefIngredients(drugId, this.prescRef ? this.prescRef : 0).toPromise();
        if (resp) {
            this.compoundDrugCostBasis = resp["cmpDrugCostBasis"]
            if (resp["list"]) {
                if (this.dataFmRx) {
                    resp = this.reCalculateByDispQty(resp);
                }
                this.totalIngredLsit = resp["list"];
                this.generateIngWJ();
            }
            this.patchValuesFromData(resp, true);
        }
    }

    generateIngWJ() {
        let totalCount = 0;
        let totalAwp = 0;
        let totalCost = 0;
        let totalPrice = 0;
        this.ingredWJ = new CollectionView(
            this.totalIngredLsit?.map((ing, i) => {
                const j = {};
                j["RowId"] = i;
                j["Id"] = ing["Id"];
                j["DRUG NAME"] = ing["DrugName"];
                j["Name"] = ing["DrugName"];
                j["NDC#"] = j["NDC"] = this._formatsUtil.getNDCFormat(ing["NDC"]);
                j["QUANTITY"] = ing["Qty"] ? (parseFloat(ing["Qty"])).toFixed(3) : "0.000";
                if (ing["Qty"] && ing["IsActive"]) {
                totalCount += +ing["Qty"];
                }
                j["AWP"] = ing["AWP"] ? (parseFloat(ing["AWP"])) : "0.00";
                j["Price"] = ing["Price"] ? (parseFloat(ing["Price"])).toFixed(2) : "0.00";
                if (ing["AWP"] && ing["IsActive"]) {
                totalAwp += +ing["AWP"];
                }
                j["BASIS"] = ing["Basis"];
                j["LOT NO"] = ing["LotNum"];
                j["EXP DATE"] = ing["ExpDt"] ? moment.utc(ing["ExpDt"]).local().format("MM/DD/YYYY") : null;
                j["MOD.1"] = ing["Mod1"];
                j["MOD.2"] = ing["Mod2"];
                j["MOD.3"] = ing["Mod3"];
                j["INGR. PROP%"] = ing["IngPropPer"];
                j["COST"] = ing["Cost"];
                if (ing["Cost"]) {
                totalCost += +ing["Cost"];
                }
                j["ACTIVE"] = ing["IsActive"];
                j["Qty Sufficient"] = ing["IsQtySufficient"];
                j["Optional"] = ing["IsOptional"];
                return j;
            })
        );
        this.totalCount = totalCount.toFixed(3);
        this.totalAwp = totalAwp.toFixed(2);
        this.totalCost = totalCost.toFixed(2);
        if (!(this.isAddDrug || this.isFromDrug)) {
           this.wjHeaders.map((item: any) => {
                if (item.hName === "BASIS" || item.hName === "MOD.1" || item.hName === "MOD.2" || item.hName === "MOD.3") {
                    item.isVisible = true;
                }
            });
        }

        if (!this.totalIngredLsit || this.totalIngredLsit.length === 0) {
            if (document.getElementById("Addbutton")) {
                document.getElementById("Addbutton").focus();
            }
        }
        if (this.openCompIng && this.rxFG.value.rxType !== 'er' && this.totalIngredLsit && this.totalIngredLsit.length) {
            if (+this.dataFmRx['Price'] - +this.newTotalPrice) {
                const diff = +this.dataFmRx['Price'] - +this.newTotalPrice;
                this.totalIngredLsit[this.totalIngredLsit?.length - 1].Price = +this.totalIngredLsit[this.totalIngredLsit?.length - 1].Price + diff
            }
            const DataToPost = {
                list: this.totalIngredLsit,
                note: this.DrugIngredFG.value.Notes,
                EffortLvlId: this.DrugIngredFG.value.EffortLvlId,
                DispQty: +this.DrugIngredFG.value.DispQty ? this.DrugIngredFG.value.DispQty : this.totalIngredLsit?.[0]?.DispQty,
                BeyondUseDays: this.DrugIngredFG.value.BeyondUseDays,
                TimeToMake: this.DrugIngredFG.value.TimeToMake
            };
            this._rxStore.storeCompoundData(DataToPost);
        }
    }

    addDrugPatchValues() {
        if (this.drugData) {
            this.patchValuesFromData(this.drugData, false);
        }
    }

    patchValuesFromData(resp, fromApi) {
        this.originalData = resp.list;
        if (resp && resp.list && resp.list.length > 0) {
            this.totalIngredLsit = resp.list;
            setTimeout(() => {
                resp.list.forEach(IngElem => {
                    this.addIngredientFrmResp(IngElem);
                });
            }, 1);
            this.DrugIngredFG.controls.Notes.patchValue(resp.note);
            if (fromApi) {
                this.DrugIngredFG.controls.EffortLvlId.patchValue(resp.list[0].EffortLvlId);
                this.DrugIngredFG.controls.TimeToMake.patchValue(resp.list[0].TimeToMake);
                this.DrugIngredFG.controls.BeyondUseDays.patchValue(resp.list[0].BeyondUseDays);
            } else {
                this.DrugIngredFG.controls.EffortLvlId.patchValue(resp.EffortLvlId);
                this.DrugIngredFG.controls.TimeToMake.patchValue(resp.TimeToMake);
                this.DrugIngredFG.controls.BeyondUseDays.patchValue(resp.BeyondUseDays);
            }
            if(fromApi) {
                this.DrugIngredFG.controls.DispQty.patchValue(resp.list[0].DispQty ? resp.list[0].DispQty.toFixed(3):"0.000");
            } else {
                this.dispQtyPatching(resp.list);
            }
        }
    }
   async dispQtyPatching(list) {
            const qtyPatchStng = this._commonServ.getSetttingValue("DrugSettings", "COMP_DISPQTY_AS_RXQTY");
            if (qtyPatchStng && qtyPatchStng === "1") {
                this.DrugIngredFG.controls["DispQty"].patchValue(this.dataFmRx && this.dataFmRx["Qty"] ? this.dataFmRx["Qty"] : "0.000");
            } else {
                let totalQty = 0;
                let resp = await this._drugServ.getDrugRefIngredients(this.drugId, this.prescRef ? this.prescRef : 0).toPromise();
                if (resp && resp["list"] && resp["list"].length) {
                        this.totalIngredLsitForOrginalDrug  =  resp["list"];
                }
                this.totalIngredLsitForOrginalDrug.map(val => {
                    if (val["IsActive"]) {
                        totalQty = totalQty + (val["Qty"] ? parseFloat(val["Qty"]) : 0);
                    }
                });
                if(this.drugData) {
                    const dispQty = parseFloat(list[0].DispQty) * (parseFloat(this.dataFmRx && this.dataFmRx["Qty"] ?
                    this.dataFmRx["Qty"] : 0) / totalQty);
                    this.DrugIngredFG.controls.DispQty.patchValue(dispQty.toFixed(3));
                } else {
                    this.DrugIngredFG.controls.DispQty.patchValue(list[0].DispQty ? list[0].DispQty.toFixed(3):"0.000");
                }
              }
    }

    openAddWindow() {
        this.openAddOrEdit = true;
        this.editMode = false;
        this.AddOrUpdateValue.reset();
        this.AddOrUpdateValue.markAsUntouched();
        this.AddOrUpdateValue.controls["Qty"].patchValue("0.000");
        this.AddOrUpdateValue.controls["Cost"].patchValue("0.00");
        this.AddOrUpdateValue.controls["IsActive"].patchValue(true);
        this.AddOrUpdateValue.controls["IsQtySufficient"].patchValue(false);
        this.AddOrUpdateValue.controls["IsOptional"].patchValue(false);
        this.AddOrUpdateValue.controls["CompDrugId"].patchValue(this.drugId ? this.drugId : null);
        this.focusOnDrug = false;
        setTimeout(() => {
            this.focusOnDrug = true;
        }, 20);
    }

    async openEditWindow(id) {
        this.openAddOrEdit = false;
        this.editedId = id;
        const data = this.totalIngredLsit[id];
        this.AddOrUpdateValue.patchValue(data);
        this.AddOrUpdateValue.controls['LotNum'].patchValue(data.LotNum);
        this.AddOrUpdateValue.controls["ExpDt"].patchValue(data.ExpDt? moment.utc(data.ExpDt).local(): null);
        const drugdata = await this._commonServ.getRxDrugDetailsById(data["DrugId"]).toPromise();
        this.SelectedDrugName = drugdata.drugname + " " + drugdata.strength  + " " + drugdata.drugformname;
        this.drugInfoToBeEdited = drugdata;
        this.unitawp = drugdata.unitpriceawp;
        this.unitCost = drugdata.unitpricecost;
        this.editMode = true;
        this.focusOnDrug = false;
        this.restricDrugSearch = false;
        setTimeout(() => {
            this.restricDrugSearch = true;
            this.focusOnDrug = true;
        }, 20);
        this.getInfo(data["DrugId"]);
        this.openAddOrEdit = true;
    }

    removeRestrictionOnDrugSearch(val) {
        this.restricDrugSearch = false;
    }

    CloseWindow(val?) {
        this.openAddOrEdit = false;
        this.focusOnDrug = false;
        this.unitawp = 0;
        this.unitCost = 0;
        if (this.inglistWJ && !val) {
            this.inglistWJ.focus();
        }
    }

    addIngredient() {
        const compIngExpDtFG = this._fb.group({AccidentDt: this.AddOrUpdateValue.controls["ExpDt"].value});
        const dateChecks = [{
            control: "ExpDt",
            minDate: this.minDate,
            maxDate: "",
            isRequired: false
        }];
         const dateCheckFields: any = this._dateUtil.checkDateValidationAtTimeofSave(compIngExpDtFG, dateChecks);
        if (dateCheckFields) {
            return;
        } else if (!this.AddOrUpdateValue.value["Qty"] || this.AddOrUpdateValue.value["Qty"] === "0.000") {
            const element: any = document.getElementById("IngQty");
            if (element) {
                element.focus();
                element.select();
            }
            this._alertService.error("Quantity cannot be Blank/Zero.");
            return;
        }
        this.IsQtySufficientExist = 0;
        this.allowOnlyOneQtySufficientDrug();
        this.CheckCompoundIngredientRestriction();
    }

    AddCompoundIngredient() {
        if (this.IsQtySufficientExist >=  1 && this.AddOrUpdateValue.value["IsQtySufficient"] === true) {
            this._alertService.error("Only One Drug is allowed as Qty Sufficient.");
            return;
        }
       // this.calculatePriceOnQtyChange();
        if (this.editMode) {
            this.totalIngredLsit[this.editedId] = this.AddOrUpdateValue.value;
        } else {
            this.AddOrUpdateValue.value['OrginalAwp'] =   this.AddOrUpdateValue.value['AWP'] ;
            this.totalIngredLsit.push(this.AddOrUpdateValue.value);
        }
        this.generateIngWJ();
        this.openAddOrEdit = false;

        if (this.inglistWJ) {
            this.inglistWJ.focus();
        } else {
            if (document.getElementById("Addbutton")) {
                document.getElementById("Addbutton").focus();
            }
        }
        this.unitawp = 0;
        this.unitCost = 0;
    }

    setNumberFormat(ControlName, formFroup, decLen) {
        formFroup.controls[ControlName].patchValue(
            this.numberPipe.transform(formFroup.value[ControlName], decLen));
    }

    addIngredientFrmResp(IngElem: any) {
        IngElem.AWP = parseFloat(IngElem.AWP);
        this.generateIngWJ();
        this.formArrayCount++;
        const Ingred = this._fb.group({
            ...IngElem,
            ...{ CompDrugId: this.drugId }
        });
        this.IngredList.push(Ingred);
    }

    deleteIngredientFromArray(index: number) {
        this.formArrayCount--;
        this.totalIngredLsit.splice(index, 1);
        this.generateIngWJ();
    }

    slctdDrugId(drugId: number) {
        this.tempDrugId = drugId;
    }
    optedDrug(drug: any, ipFG: FormGroup) {
        this.compDrugId = drug && drug.id;
        ipFG.patchValue({Name: false});
        if (drug && drug.id && drug.isactive) {
            if (this.totalIngredLsit && this.totalIngredLsit.find(val => val.NDC === drug.ndc)) {
                if (this.editMode) {
                    this.unitawp = drug.unitpriceawp;
                    this.unitCost = drug.unitpricecost;
                }
                this._alertService.error("Cannot Add Drug . Since it is already part of the compound.");
                return;
            }
            this._cdr.detectChanges();
            ipFG.patchValue({
                NDC: drug.ndc,
                DrugName: drug.drugname + drug.strength + drug.drugformname,
                Name: drug.drugname,
                DrugId: drug.id
            });
            // setTimeout(() => {
            //     ipFG.patchValue({
            //         NDC: drug.ndc,
            //         DrugName: drug.drugname + drug.strength + drug.drugformname,
            //         Name: drug.drugname,
            //         DrugId: drug.id
            //     });
            // }, 100);
            console.log("ipFg:",ipFG)
            this.SelectedDrugName = drug.drugname + " " + drug.strength  + " " + drug.drugformname;
            this.unitawp = drug.unitpriceawp;
            this.unitCost = drug.unitpricecost;
        } else {
            if (drug && !drug.isactive) {
                this._alertService.error("Selected Drug is inactive Drug. Please select another Drug.");
            }
            ipFG.patchValue({
                NDC: null,
                DrugName: null,
                Name: null,
                DrugId: null
            });
            this.unitawp = 0;
            this.unitCost = 0;
        }
        if(drug && drug.id){
            this.getInfo(drug.id);
        }
    }

    calculatePriceOnQtyChange() {
        if (this.AddOrUpdateValue.value["Qty"] && this.AddOrUpdateValue.value["Qty"] !== "0.000") {
        this.AddOrUpdateValue.controls["AWP"].patchValue(
            (+this.AddOrUpdateValue.value["Qty"] * this.unitawp).toFixed(3)
        );
        if (this.unitCost && this.unitCost != 0) {
        this.AddOrUpdateValue.controls["Cost"].patchValue(
            (+this.AddOrUpdateValue.value["Qty"] * this.unitCost).toFixed(3)
        );}
        } else {
            this.AddOrUpdateValue.controls["AWP"].patchValue("0.000");
            this.AddOrUpdateValue.controls["Cost"].patchValue("0.000");
        }
    }

    openDrugLotModal(data, content, prevVal) {
        this.selectedRecord = data;
        if (prevVal.value.LotNum) {
            this.modalService.open(content, { centered: true, windowClass: "large--content"});
        } else {
            this.optedDrugLot(this.selectedRecord, prevVal);
        }
    }

    searchDrugLot = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(750),
            distinctUntilChanged(),
            switchMap(term => this.searchByDrugLot(term))
        )

    searchByDrugLot(value: string) {
        if (value) {
            this.drugLots = this._drugServ.getDruglotsBydrugId(
                this.tempDrugId,
                value
            );
        }
        return this.drugLots;
    }
    formatter = (x: any) => {
        return x.LotNum;
    }
    optedDrugLot(val: any, drugForm: FormGroup) {
        drugForm.patchValue({
            LotNum: val.item.LotNum,
            ExpDt: val.item.ExpDt
        });
    }

    cancelDrugLot(val: any, drugForm: FormGroup) {
        drugForm.patchValue({
            LotNum: null
        });
        this._cdr.detectChanges();
        drugForm.patchValue({
            LotNum: drugForm.value.LotNum
        });
        // setTimeout(() => {
        //     drugForm.patchValue({
        //         LotNum: drugForm.value.LotNum
        //     });
        // }, 5);

    }

    deleteDrugIngred(ingId: any, index: any) {
        if(this.totalIngredLsit && this.totalIngredLsit.length === 1){
            this._alertService.error("Cannot save Compound Drug without Ingredients");
            document.getElementById("Addbutton").focus();
        } else{
            if (ingId === 0 || !ingId || (!this.isFromDrug && !this.prescRef)) {
                this.deleteIngredientFromArray(index);
            } else if (this.prescRef) {
                const data = this.totalIngredLsit[index];
                this.RemoveDrugANDAddDrug(data["DrugId"]);
            } else {
                this._drugServ.deleteIngredientDrug(ingId)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this._auditUtils.deletedValues(
                            ingId,
                            "Delete Drug Ingridients",
                            "Drug",
                            this.drugId
                        );
                        this.deleteIngredientFromArray(index);
                    } else {
                        this._alertService.error("Delete drug ingredient unsuccessful.");
                    }
                });
            }
        }
    }
    saveDrugIngredients() {
        if (this.DrugIngredFG.value.DispQty && parseFloat(this.DrugIngredFG.value.DispQty) > 0) {
            if (this.prescRef) {
                this.totalIngredLsit.map(val => val = Object.assign(val, {PrescReFillId: this.prescRef}));
            }
            if (this.totalIngredLsit && this.totalIngredLsit.length && +this.DrugIngredFG.value?.DispQty) 
                this.totalIngredLsit[0].DispQty = this.DrugIngredFG.value.DispQty;
            const DataToPost = {
                list: this.totalIngredLsit,
                note: this.DrugIngredFG.value.Notes,
                EffortLvlId: this.DrugIngredFG.value.EffortLvlId,
                DispQty: this.DrugIngredFG.value.DispQty,
                BeyondUseDays: this.DrugIngredFG.value.BeyondUseDays,
                TimeToMake: this.DrugIngredFG.value.TimeToMake,
                PrescReFillId: this.prescRef ? this.prescRef : null,
                RemoveDrugIngredientsIds: this.RemoveDrugIngredientsIds,
                CompDrugId: this.drugId ? this.drugId : null,
                IsForRx: this.prescRef ? true : false,
                RemoveCompDrugIngredientsIds: this.RemoveCompIds
            };
            if (this.isAddDrug || (!this.isFromDrug && !this.prescRef)) {
                this.IsPopUpClosed();
            } else {
                this._drugServ.AddIngredientDrug(DataToPost)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this._auditUtils.getChangedValues(
                            this.originalData,
                            DataToPost.list,
                            "Add Compound Ingredients",
                            "Drug",
                            this.drugId
                        );
                        this._alertService.success("Saved Successfully");
                        // this.EmitDrugData.emit(DataToPost);
                        this.activeModal.dismiss("Cross click");
                        this.IsPopUpClosed();
                    } else {
                        this._alertService.error("Saved unSuccessfull.");
                    }
                });
            }
        } else {
            const element: any = document.getElementById("DispQty");
            if (element) {
                element.focus();
                element.select();
            }
            this._alertService.error("Dispensed Quantity cannot be Blank/Zero.");
        }
    }
    drugLotRestriction(e) {
        e.preventDefault()
    }
    getInfo(id) {
        let buckId = +this._commonServ.getSetttingValue("RxSettings", "DEF_GENERAL_BUCKET");
        const data = {
            term: null,
            drugId: id,
            bucketId: buckId,
            fetchInActiveRecords: this.rxType === "er" ? true: false
        }
        this._commonServ.getDrugLots(data)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                const data = resp;
                this.finalLotList = data;
               if(this.finalLotList && this.finalLotList.length <= 0 && !this.editMode){
                this.isDisabledEXP = true;
               }
            }
            if(!this.editMode){
                this._nrxUtls.patchDrugLot(this.rxType, this.AddOrUpdateValue,this.AddOrUpdateValue.value.DrugId,buckId,this.finalLotList);
            }
            
        });
    }
    enteredDrugLot(event: any, fg?: any, fc?: string) {
    if(!this.eventRaised){
        this.eventRaised = true;
        this.druglotFC = this.AddOrUpdateValue;
        if(event && event.value && event.value.LotNum){
                this.isDisabledEXP = false;
                this.druglotFC.controls["LotNum"].setValue(event.value.LotNum);
                this.druglotFC.controls["ExpDt"].setValue(event.value.ExpDt);
                this.druglotFC.controls["ExpDt"].setValidators([
                    Validators.required,
                ]);
                this.druglotFC.controls["ExpDt"].updateValueAndValidity();
                
        } else {
            if(event && event.value && !(event.value.LotNum)){
                this.isDisabledEXP = true;
                if(this.isDisabledEXP){
                    this.druglotFC.controls['ExpDt'].setValue(null);
                    this.druglotFC.controls["ExpDt"].setErrors({ required : false});
                    this.druglotFC.controls["ExpDt"].setValidators([
                          Validators.nullValidator,
                      ]);
                    this.druglotFC.controls['ExpDt'].markAsUntouched();
                    this.druglotFC.controls['ExpDt'].updateValueAndValidity();
                }
            }
            
        }
        setTimeout(()=> {
            this.eventRaised = false;
        }, 10)
    }
       this.hasStopLotExp = this.getLotExpTabIndex();
}
    getLotExpTabIndex() {
        this.hasStopLotExp = this._commonServ.getSetttingValue("RxSettings", "STOP_CUR_EXPIRATION_DATE") === "1";
         this.hasStopLot = this._commonServ.getSetttingValue("RxSettings", "STOP_CUR_DRUG_LOT") === "1";
        if ((this.hasStopLotExp && this.hasStopLot) || (this.AddOrUpdateValue.value['LotNum'])) {
            return true;
        } else {
            return false;
        }
    }
    getLotFocus(event?) {
        if(this.hasStopLotExp && !this.isDisabledEXP) {
            setTimeout(() =>{
             if (document.getElementById("ExpDt")) {
                 document.getElementById("ExpDt").focus();
             }
            },10);
        } else {
             setTimeout(() =>{
                 if (document.getElementById("Ingproportion")) {
                     document.getElementById("Ingproportion").focus();
                 }
             }, 10);
        }
     }
    IsPopUpClosed(type?: any) {
        if (this.dataFmRx && this.dataFmRx['Price'] && this.newTotalPrice && (+this.dataFmRx['Price'] - +this.newTotalPrice) && this.totalIngredLsit && this.totalIngredLsit.length) {
            const diff = +this.dataFmRx['Price'] - +this.newTotalPrice;
            this.totalIngredLsit[this.totalIngredLsit.length - 1].Price = +this.totalIngredLsit[this.totalIngredLsit.length - 1].Price + diff
        }
        const DataToPost = {
            list: this.totalIngredLsit,
            note: this.DrugIngredFG.value.Notes,
            EffortLvlId: this.DrugIngredFG.value.EffortLvlId,
            DispQty: +this.DrugIngredFG.value.DispQty ? this.DrugIngredFG.value.DispQty : this.dispQty,
            BeyondUseDays: this.DrugIngredFG.value.BeyondUseDays,
            TimeToMake: this.DrugIngredFG.value.TimeToMake,
            totalCount: parseFloat(this.totalCount),
            totalAwp: parseFloat(this.totalAwp),
            totalCost: parseFloat(this.totalCost),
            totalPrice: parseFloat(this.newTotalPrice),
            RemoveDrugIngredientsIds: this.RemoveDrugIngredientsIds,
            CompDrugId: this.drugId ? this.drugId : null,
            type: type
        };
        this.EmitCompoundData.emit(DataToPost);
        if (!this.isFromDrug && !this.prescRef) {
            this.activeModal.dismiss("Cross click");
        }
        if (!this.isAddDrug) {
            this.EmitPopUpClosed.emit(true);
        }
        if(this.modalRef1) {
            this.modalRef1.close()
        }
        if (type === "cancel" && this.isAddDrug) {
            return;
        } else {
            this.EmitDrugData.emit(DataToPost);
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        this.inglistWJ = flex;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    onRowDblclicked(s: any, e: any) {
        const ht = s.hitTest(e);
        if (s.selectedRows[0].dataItem && ht.cellType === 1) {
            this.openEditWindow(s.selectedRows[0].dataItem["RowId"]);
        }
    }

    keyeventOnWj(grid) {
        if (this.totalIngredLsit && this.totalIngredLsit.length !== 0) {
            event.preventDefault();
            this.openEditWindow(grid.selectedItems[0]["RowId"]);
        }
    }

    shortCutCOnWijimo(event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openAddWindow();
            } else if (event.which === 67) {
                event.preventDefault();
                this.activeModal.dismiss("Cross click");
                this.IsPopUpClosed("cancel");
            }
        }
    }

    allowOnlyOneQtySufficientDrug() {
        if (this.totalIngredLsit) {
            this.totalIngredLsit.forEach(element => {
                if (element.IsQtySufficient) {
                    if (element.IsQtySufficient === true) {
                        this.IsQtySufficientExist = this.IsQtySufficientExist + 1;
                    }
                }
            });
        }
    }

    async CheckCompoundIngredientRestriction() {
        let drugsList = "";
        if (this.totalIngredLsit && this.totalIngredLsit.length !== 0) {
             this.totalIngredLsit.map(val => {
                drugsList = drugsList + (drugsList !== "" ? "," : "") + val["DrugId"].toString();
        });
        const resp = await this._drugServ.checkDrugIngredientsRestriction(
            this.AddOrUpdateValue.value["DrugId"], drugsList).toPromise();
        if (resp) {
            // tslint:disable-next-line: max-line-length
            this.message = (resp["drugname"] + " " + resp["strength"]  + " " + resp["drugformname"]) + " drug is already selected, do you wish to remove " + (resp["drugname"] + " " + resp["strength"]  + " " + resp["drugformname"]) + " and add " + this.SelectedDrugName + " another equivalent?";
            this.drugInfo = resp;
            if ((this.editMode && this.drugInfoToBeEdited && this.drugInfoToBeEdited["id"] !== resp["id"]) || !this.editMode) {
                this.modalRef = this.modalService.open(this.COMPOUNDINGREDIENTRESTRICTION, { centered: true, keyboard: false, windowClass: "large--content"});
            }
        } else {
            this.AddCompoundIngredient();
        }} else {
            this.AddCompoundIngredient();
        }
    }

    RemoveDrugANDAddDrug(Id?: any) {
        if (this.editMode) {
            this.editMode = false;
            this.totalIngredLsit.splice(this.editedId, 1);
            this.generateIngWJ();
        }
        const index = this.totalIngredLsit.findIndex(x => x.DrugId === (Id ? Id : this.drugInfo["id"]));
        this.formArrayCount--;
        const data = this.totalIngredLsit[index];
        this.totalIngredLsit.splice(index, 1);
        if (this.prescRef && data && data["Id"]) {
            this.RemoveCompIds += this.RemoveCompIds !== "" ? "," + data["Id"] : data["Id"];
        } else {
            this.RemoveDrugIngredientsIds += this.RemoveDrugIngredientsIds !== "" ? "," +
            (Id ? data["Id"] : this.drugInfo["id"]) : (Id ? data["Id"] : this.drugInfo["id"]);
        }
        this.generateIngWJ();
        if (!Id) {
            this.AddCompoundIngredient();
        }
        if (this.modalRef) {
            this.modalRef.close();
            this.CloseWindow();
        }
    }

    closeNewConfirmModal() {
        if (this.confirmModel) {
            this.confirmModel.close();
            this.confirmModel = null;
        }
    }
}
