import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ElementRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "app-sub-price-list",
    templateUrl: "./sub-price-list.component.html"
})
export class SubPriceListComponent implements OnInit, AfterViewInit {
    priceListFG: FormGroup;
    controlName: any;
    autoFoucsFeild: any;
    formInvalid: any;
    priceScheduleList: any;

    @ViewChild("drugSearchR")
    drugSearchR: ElementRef;
    type: any;
    drugSearchInputValue: any;
    isInputFocused: boolean;

    @Input()
    set PriceListsFG(ed: FormGroup) {
      this.priceListFG = ed;
    }

    @Input()
    set CustControlName(ed: any) {
      this.controlName = ed;
    }

    @Input()
    set AutoFocus(ed: any) {
      this.autoFoucsFeild = ed;
    }


    @Input()
    set Type(ed: any) {
      this.type = ed;
    }

    @Input()
    set FormGroupInvalid(ed: any) {
      this.formInvalid = ed;
    }

    @Input()
    set PriceScheduleList(ed: any) {
      this.priceScheduleList = ed;
    }

    @Input()
    set DrugSearchInputValue(ed: any) {
        this.drugSearchInputValue = ed;
    }

    @Output()
    SelectedPrice = new EventEmitter<any>();

    @Output()
    OptedDrug = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.autoFoucsFeild) {
        const data: any = document.getElementById("PriceList").getElementsByTagName("input")[0];
        data.focus();
        this.isInputFocused = false;
        } else if (!this.autoFoucsFeild) {
            this.isInputFocused = true;
        }
    }

    selectedPrice(value) {
        this.SelectedPrice.emit(value);
    }

    optedDrugtoAdd(value) {
        this.OptedDrug.emit(value);
    }
}
