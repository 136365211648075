import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Documents, SharedDocuments } from 'src/app/models';
import { CommonService, DMSService, DocumentsService } from 'src/app/services';
import * as moment from "moment";
import * as wjcCore from "@grapecity/wijmo";
import { CommonUtil, RxUtils } from 'src/app/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-scan-document-fill',
    templateUrl: './scan-document-fill.component.html'
})
export class ScanDocumentFillComponent implements OnInit {
    addFileType: string;
    uploadedFile: any;
    imageSrc: any;
    pdfSrc: any;
    rxFormGroup: FormGroup;
    docUploadFG: any;
    selectedDocument: any;
    docQueueFG: FormGroup;
    today: string;
    docWJ: wjcCore.CollectionView;
    appUsers: any[] = [];
    unsubscribe$: Subject<void> = new Subject();
    actvHeaders = [
        "Name",
        "Patient",
        "Scan Date",
        "Marked By",
        "Title",
        "Comments"
    ];
    totalDocs: any;
    filteredDocs: any[];
    searchString: string = "";

    @ViewChild("PreviewDocument", { static: true })
    PreviewDocument: any;


    @Input() set rxFG(pi: FormGroup) {
        this.rxFormGroup = pi;
    }

    @Output() IsPopUpClosed = new EventEmitter<any>();

    constructor(
        private _fb: FormBuilder,
        private sanitizer: DomSanitizer,
        private _docService: DocumentsService,
        private _dmsSer: DMSService,
        private _commonServ: CommonService,
        private _rxUtils: RxUtils,
        private _modalSer:NgbModal,
        public _commonUtil: CommonUtil
    ) { }

    ngOnInit() {
        this.today = moment().format("MM/DD/YYYY");
        this.createFG();
        this.getSubCats();
        this._commonServ.appUserData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.appUsers = resp;
        });
    }

    createFG() {
        this.docUploadFG = this._fb.group(new Documents());
        this.docQueueFG = this._fb.group({
            CategoryId: [SharedDocuments.Prescription],
            SubCategoryId: [],
            ScannedFrom: [moment().subtract(1,'year').format("MM/DD/YYYY")],
            ScannedTo: [this.today]
        });
        this.docUploadFG.controls.DocCatId.patchValue(SharedDocuments.Prescription);
    }

    getSubCats() {
        this._dmsSer.getSubcats()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.length) {
                let rxSubCat = resp.find(elm => elm.Name.toLowerCase() === 'prescription');
                if (rxSubCat) {
                    this.docUploadFG.controls.DocSubCatId.patchValue(rxSubCat.Id);
                    this.docQueueFG.controls.SubCategoryId.patchValue(rxSubCat.Id);
                }
            }
        });
    }

    async fileUploaded(inputFile) {
        let fd = new FormData();       
        this.docUploadFG.value["FileName"] = inputFile.name;
        fd.append("DocInputParams", JSON.stringify(this.docUploadFG.value));
        let base64String = "";
        this._commonUtil.convertFile(inputFile).subscribe(base64 => {
            fd.append("fileKey", base64);
            this._docService.uploadNewDocument(fd)
           .pipe(takeUntil(this.unsubscribe$))
           .subscribe(resp => {
            if (resp) {
                this.docUploadFG.controls.Name.reset();
                this.selectedDocument = resp;
                this.saveDocument();
                // this.previewSelectedFile(resp);
            }
            });
        });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    OnDoubleClickOnWijimo(s: any, e: any) {
        const ht = s.hitTest(e);
        if (s.selectedRows[0].dataItem) {
            this.selectedDocument = s.selectedRows[0].dataItem.Data;
            this.selectedDocument["Name"] = this.selectedDocument.Title ? this.selectedDocument.Title : null;
            this.selectedDocument["DocFileName"] = this.selectedDocument.FileName;
            this.saveDocument();
        }
    }

    previewSelectedFile(val: any) {
        this.addFileType = null;
        this._docService.getDocumentByName({ DocFileName: val.FileName })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                if (resp.type === "application/pdf") {
                    this.createPdfFromBlob(resp);
                } else {
                    this.createImageFromBlob(resp);
                }
            }
        });
    }

    createPdfFromBlob(image: Blob) {
        this.addFileType = "pdf";
        const urlCreator = window.URL;
        this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
            urlCreator.createObjectURL(image) + "#toolbar=0"
        );
        this.openPreviewModal();
    }

    createImageFromBlob(image: Blob) {
        this.addFileType = "image";
        const urlCreator = window.URL;
        this.imageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
            urlCreator.createObjectURL(image)
        );
        this.openPreviewModal();
    }

    openPreviewModal() {
        this._modalSer.open(this.PreviewDocument, { keyboard: false, backdrop: 'static', size: 'lg'});
    }

    saveDocument() {
        if (this.selectedDocument) {
            const docFG: FormArray = this.rxFormGroup.get("Documents") as FormArray;
            docFG.push(this._fb.group(this.selectedDocument));
            docFG.updateValueAndValidity();
            this.closeModal();
        }
    }

    closeModal() {
        this.IsPopUpClosed.emit(true);
    }

    getDocs() {
        if (this.docQueueFG.valid) {
            this._dmsSer.getQueueDocs(this.docQueueFG.value)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.totalDocs = resp;
                if (this.totalDocs && this.totalDocs.length) {
                    this.totalDocs = this.totalDocs.map(elm => {
                        const user = (this.appUsers && this.appUsers.length) ? this.appUsers.find(elm => elm.AppUserId === elm.MarkedBy) : null;
                        if (user) {
                            elm["MarkedBy"] = user.MarkedBy;
                        } else {
                            elm["MarkedBy"] = null;
                        };
                        return elm;
                    });
                }
                this.generateDocWJ(resp);
            });
        }
    }

    searchDocs(searchValue) {
        if (searchValue) {
            this.filteredDocs = this._rxUtils.filterItems(this.totalDocs, ["UniqueDocumentId", "PatientName", "MarkedBy", "Title", "Comments"], searchValue);
        } else {
            this.filteredDocs = this.totalDocs;
        }
        this.generateDocWJ(this.filteredDocs);
    }

    generateDocWJ(docList: any[]) {
        if (!docList ) {
            docList = [];
        }
        this.docWJ = new wjcCore.CollectionView(
            docList.map((doc, i) => {
                const j = {};
                j["Data"] = doc;
                j["Name"] = doc.UniqueDocumentId;
                j["Patient"] = doc.PatientName;
                j["Scan Date"] = doc.ScanDate ? moment.utc(doc.ScanDate).local().format("MM/DD/YYYY") : null;
                j["Marked By"] = doc.MarkedBy;
                j["Title"] = doc.Title;
                j["Comments"] = doc.Comments;
                return j;
            })
        );
    }

    addEventOnWijimo(event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();

            }
        }
    }

}
