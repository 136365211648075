<div class="insurance--body">
    <ul class="insurance--icons">
        <span>
            <i class="far fa-sync-alt" title="Refresh Data from MMS" (click)="prescriberSearchPopUp(searchData)"></i>
        </span>
    </ul>
</div>

<ng-template #searchData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Search MMS Data</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal('cancel')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="erx--body">
            <div class="eprx--block__content">
                <form [formGroup]="refreshFG" *ngIf="!hideFilters">
                    <div class="col-sm-12 padding-0">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <eprx-input [LabelText]="'Last Name'" [PlaceHolder]="'Last Name - Enter min 3 Chars'" [Title]="'Last Name'" [MinLength]="3"  [RxSelectId]="'PrescLastNameMMS'"
                                    [MarkAsTouched]= "refreshFG.get('PrescLastName').touched" [ControlName]="'PrescLastName'" [FormGroupName]="refreshFG"
                                    [ErrorDefs]="{minlength: 'Enter min 3 Chars'}" [AutoFocus]="!hideFilters" [FormGroupInvalid] = "formGroupInvalid">
                                </eprx-input>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <eprx-input [LabelText]="'First Name'" [PlaceHolder]="'First Name - Enter min 3 Chars'" [Title]="'FirstName'" [RxSelectId]="'FirstNameMMS'"
                                    [MarkAsTouched]="refreshFG.get('PrescFirstName').touched" [ControlName]="'PrescFirstName'"
                                    [FormGroupName]="refreshFG" [MinLength]="3" [ErrorDefs]="{minlength: 'Enter min 3 Chars'}" [FormGroupInvalid] = "formGroupInvalid"
                                    >
                                </eprx-input>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <eprx-input [LabelText]="'DEA#'" [PlaceHolder]="'DEA# - Enter min 3 Chars'" [Title]="'DEA#'" [RxSelectId]="'DEAMMS'"
                                    [ControlName]="'Dea'" [FormGroupName]="refreshFG" [MarkAsTouched]="refreshFG.get('Dea').touched"
                                    (keydown.enter)="getPrescriberMMS()" [MinLength]="3" [ErrorDefs]="{minlength: 'Enter min 3 Chars'}" [FormGroupInvalid] = "formGroupInvalid">
                                </eprx-input>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <eprx-input [LabelText]="'Phone#'" [PlaceHolder]="'Phone# - Enter min 3 Chars'" [Title]="'Phone#'"[RxSelectId]="'PhoneMMS'"
                                    [ControlName]="'Phone'" [FormGroupName]="refreshFG" [MarkAsTouched]="refreshFG.get('Phone').touched" [FormGroupInvalid] = "formGroupInvalid"
                                    [MaskPattern]="'(000)000-0000'" (keydown.enter)="getPrescriberMMS()"
                                    [ShowMask]="true" [MinLength]="3" [ErrorDefs]="{minlength: 'Enter min 3 Chars'}">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <eprx-input [LabelText]="'License#'" [MaxLength]="15" [PlaceHolder]="'License# - Enter min 3 Chars'" [Title]="'License#'" [RxSelectId]="'LicenseMMS'"
                                    [ControlName]="'License'" [FormGroupName]="refreshFG" [MarkAsTouched]="refreshFG.get('License').touched"
                                    (keydown.enter)="getPrescriberMMS()" [MinLength]="3" [ErrorDefs]="{minlength: 'Enter min 3 Chars'}" [FormGroupInvalid] = "formGroupInvalid">
                                </eprx-input>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                                <eprx-input [LabelText]="'NPI#'" [PlaceHolder]="'NPI# - Enter min 3 Chars'" [Title]="'NPI#'" [RxSelectId]="'NPIMMS'"
                                    [ControlName]="'PrescNpi'" [FormGroupName]="refreshFG" [MarkAsTouched]="refreshFG.get('PrescNpi').touched"
                                    (keydown.enter)="getPrescriberMMS()" [MinLength]="3" [ErrorDefs]="{minlength: 'Enter min 3 Chars'}" [FormGroupInvalid] = "formGroupInvalid">
                                </eprx-input>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                                <eprx-input [LabelText]="'Zip'" [Title]="
                                        'Please enter valid zip and click enter to populate city and state' 
                                    " [PlaceHolder]="'Zip - Enter min 3 Chars'"  [MarkAsTouched]="refreshFG.get('Zip').touched" [ControlName]="'Zip'" [FormGroupName]="refreshFG"  [RxSelectId]="'ZipMMS'"
                                    (keydown.enter)="getPrescriberMMS()" [MaxLength]="5" [MaskPattern]="'00000'" [MinLength]="3" [ErrorDefs]="{minlength: 'Enter min 3 Chars'}" [FormGroupInvalid] = "formGroupInvalid">
                                </eprx-input>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 align-button text-right">
                                <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" (click)="getPrescriberMMS()"
                                    appShortcutKey [AltKey]="'f'">
                                    <span>F</span> Search
                                </button>
                                <button type="button" class="hotkey_success pull-right" (click)="clearFilters()"
                                    (keydown.tab)="focusToMMsData($event)" appShortcutKey [AltKey]="'l'">
                                    <span>L</span> CLEAR
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div [ngClass]="
                        hideFilters ? 'show-transistion' : 'hide-transistion'
                    ">
                    <hr />
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">MMS Data</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <!-- <wj-flex-grid #refreshData (keydown)="cancelEventOnWijimo($event)"
                                [headersVisibility]="'Column'" [itemsSource]="searchedMMSList" [isReadOnly]="true"
                                [selectionMode]="'Row'" [allowSorting]="true" class="grid max-height_35rem"
                                (updatedView)="init(refreshData)" (click)="onclickRefreshMMS(refreshData,$event)" (keydown.enter)="
                                    selectedMMSByEnter(refreshData, $event)
                                " (dblclick)="
                                    selectedMMSByEnter(refreshData, $event)
                                ">
                                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true"
                                    *ngFor="
                                        let actvHeader of actvHeaders;
                                        let i = index
                                    " title="{{ actvHeader }}" [width]="
                                        actvHeader === 'SELECT' ||
                                        actvHeader === 'ACTIVE' ||
                                        actvHeader === 'SERVICE LEVEL'
                                            ? 70
                                            : actvHeader === 'STREET' ||
                                              actvHeader ===
                                                  'SERVICE LEVEL NAME'
                                            ? 220
                                            : actvHeader === 'LAST NAME' ||
                                              actvHeader === 'FIRST NAME'
                                            ? 190
                                            : 120
                                    ">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="actvHeader === 'SELECT'" >
                                            <div class="insurance--body text-center">
                                                <span>
                                                    <i class="far fa-file-import action" title="Select" (click)="
                                                            selectMMSData(
                                                                item.row
                                                            )
                                                        "></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                          {{(actvHeader === 'PHONE#' || actvHeader === 'FAX#') ? (item[actvHeader] ? (item[actvHeader] | mask : "(000)000-00000") : "") :
                                          item[actvHeader] }}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid> -->
                            <wj-flex-grid #refreshData (keydown)="cancelEventOnWijimo($event)" [ngClass]="!hasMMSValues ? 'no-data grid max-height_35rem':'grid max-height_35rem'"
                            [headersVisibility]="'Column'" [itemsSource]="searchedMMSList" [isReadOnly]="true" [columnPicker]="'prescriberSearchWJ'"
                            [selectionMode]="'Row'" [allowSorting]="true" class="grid max-height_35rem"
                            (updatedView)="init(refreshData)" (click)="onclickRefreshMMS(refreshData,$event)" (keydown.enter)="
                                selectedMMSByEnter(refreshData, $event)
                            " (dblclick)="
                                selectedMMSByEnter(refreshData, $event)
                            ">
                            <wj-flex-grid-column [header]="'Select'" [width]="70">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <div *ngIf="actvHeader=== Select">
                                        <div class="insurance--body text-center">
                                            <span>
                                                <i class="far fa-file-import action actions--Highlightfocus--icon" title="Select" (click)="
                                                        selectMMSData(
                                                            item.row
                                                        )
                                                    "></i>
                                            </span>
                                        </div>
                                    </div> 
                                </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="actvHeader['hName']" [binding]="actvHeader['hName']" [visible]="actvHeader['isVisible']"
                                *ngFor="
                                    let actvHeader of wjHeaders;
                                    let i = index
                                " title="{{ actvHeader['hName'] }}" [width]="actvHeader['width']">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                   <div>
                                      {{(actvHeader['hName'] === 'PHONE#' || actvHeader['hName'] === 'FAX#') ? (item[actvHeader['hName']] ? (item[actvHeader['hName']] | mask : "(000)000-00000") : "") :
                                      item[actvHeader['hName']] }}
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                        </wj-flex-grid>
                            <app-eprx-pagination [TotalCount]="completeMMSData.length" [PageSize]="Size"
                                [WijmoName]="refreshData" [GridName]="'PrescriberMMS'"
                                (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)"
                                *ngIf="hasMMSValues" [FromModal]="true"></app-eprx-pagination>
                        </div>
                    </div>
                </div>
                <div [ngClass]="
                        hideFilters ? 'hide-transistion' : 'show-transistion'
                    " class="show-transistion">
                    <hr />
                    <div class="exprx--common-block">
                        <div class="eprx--block__content edit--drug">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <wj-flex-grid #MMSdata [headersVisibility]="'Column'" [selectionMode]="'Row'"
                                        [itemsSource]="refreshMMSModel" (keydown)="cancelEventOnWijimo($event)"
                                        [isReadOnly]="true" (keydown.space)="spaceEventOnWijimo($event, MMSdata)
                                        " (updatedView)="updateMMSInit(MMSdata)"
                                        (keydown.tab)="tabEventOnWijimo($event)">
                                        <wj-flex-grid-column [header]="'HEADING'" [width]="200" [binding]="'head'"
                                            [align]="'center'">
                                            {{ refreshMMSModel?.head }}
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'CURRENT VALUE'" [width]="350"
                                            [binding]="'currentvalue'" [align]="'center'">
                                            {{ refreshMMSModel?.currentvalue }}
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'MMS DATA VALUE'" [width]="350"
                                            [binding]="'MMsvalue'" [align]="'center'">
                                            {{ refreshMMSModel?.MMsvalue }}
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'USE THIS'" [width]="300" [align]="'center'">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <div *ngIf="item?.display">
                                                    <input class="form-check-input label-color" type="checkbox"
                                                        id="{{ item?.link }}" (change)="
                                                            refreshMMSData(
                                                                item,
                                                                $event.target
                                                                    .checked
                                                            )
                                                        " />
                                                    <label class="form-check-label" for="{{ item?.link }}">
                                                    </label>
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" id="updateMMSButton" *ngIf="hideFilters" (click)="c('Close click')" appShortcutKey [AltKey]="'u'">
           <span>U</span> UPDATE
        </button>
        <button type="button" class="hotkey_success" id="updateSPI" *ngIf="!hideFilters && hasMMSValues && mmsType && (mmsType === 'SPI' || mmsType === 'NPI')" (click)="updateNIPOrSPI()" (click)="c('Close click')" appShortcutKey [AltKey]="'o'">
            <span>O</span> UPDATE {{mmsType}} ONLY
         </button>
        <button type="button" *ngIf="hideFilters" class="hotkey_success"  (click)="clearRefreshData('cancel')" appShortcutKey [AltKey]="'c'">
           <span>C</span> CANCEL
        </button>
        <button type="button" *ngIf="!hideFilters" class="hotkey_success"  (click)="closeModal('cancel')" appShortcutKey [AltKey]="'c'">
          <span>C</span>  CANCEL
        </button>
    </div>
</ng-template>
