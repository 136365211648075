import { StringSepWithSpace } from "../pipes";

export enum AccCategoryE {
    Document = 1,
    Rx = 2,
    Rx1 = 3,
    FileMaintenance = 4,
    FileMaintenance1 = 5,
    Reports = 6,
    Inventory = 7,
    // ClaimProcessing = 8,

    eRx = 9,
    Tools = 10,
    RxEntry = 11,
    RefillRx = 12,
    EditRx = 13,
    BrowseRx = 14,
    PatientHistory = 15,
    //ViewProfit = 16,

    ControlRxProcessing = 17,
    RxNotesLog = 18,
    RxNotes = 19,
    PatientFile = 20,
    PrescriberFile = 21,
    DrugFile = 22,
    InsuranceFile = 23,
    SigFile = 24,

    FacilityFile = 25,
    PriceScheduleFile = 26,
    NcpdpPayerSet = 27,
    TransferPharmacy = 28,
    PriceList = 29,
    DiscountScheduleFile = 30,
    // PatientPayementPreference = 31,
    Category = 32,

    PatientNotesLog = 33,
    PrescriberNotesLog = 34,
    DrugNotesLog = 35,
    InsuranceNotesLog = 36,
    DailyLog = 37,
    VendorFile = 38,
    SecurityManager = 39,
    DoseScheduleFile = 40,
    Users = 41,
    Roles = 42,
    ControlFile = 43,
    Settings = 45,
    RxFollowupTagFile = 46,
    Label = 47,
    // LabelPrintSet = 48,
    LabelDesigner = 49,
    AccountSettings = 50,
    //E340BProgram = 51,
    PhysicalInventory = 52,
    Reports1 = 53,
    PrescriberSpecialities = 54,
    DeferredReason = 55,
    Bucket = 56,
    ElectronicSignature = 57,
    DMS = 58,
    Fax = 59,
    PayorFileMaintenance = 60,
    Rx2 = 61,
    RecallDeletedRx = 62,
    RxTransfer = 63,
    DrugPickVerification = 64,
    TransmissionHistory = 65,
    RefillDueReport = 66,
    BusinessReports = 67,
    AuditTrails = 68,
    InventoryTransactionType = 69,
    ERxActionList = 70,
    ERxRphNotes = 71,
    ScheduledTasks = 72,
    DMSSubcategory = 73,
    DocumentQueue = 74,
    SMS = 75,
    AccountSettings1 = 76,
    DrugCategory = 77,
    PriceReport = 78,
    ReportWriter = 79,
    PharmacistVerification = 80
   
}
export enum HeaderNames {
    "Patient Notes Log" = "Patient Notes",
    "Prescriber Notes Log" = "Prescriber Notes",
    "Drug Notes Log" = "Drug Notes",
    "Insurance Notes Log"  = "Insurance Notes",
    "Ncpdp Payer Set" = "NCPDP Payer Set",
    "Control File" = "ASAP Control Data"
}
export enum DocumentE {
    Documents = 1,
    Rx = 2,
    FileMaintenance = 4,
    Reports = 8,
    Inventory = 16,
    // ClaimProcessing = 32,
    eRx = 32,
    Tools = 64,
    Settings = 128,
    Label = 256,
    // E340BProgram = 512,
    ElectronicSignature = 512,
    DMS = 1024,
    Fax = 2048,
    SMS = 4096,
    ReportWriter = 8192
}



export enum RxE {
    RxEntry = 1,
    RefillRx = 2,
    EditRx = 4,
    BrowseRx = 8,
    RxTransfer = 16,
    RxFilledSummary = 32,
    PatientHistory = 64,
    // BatchTransmission = 128,
    QuickRefill = 128,
    OverrideSevereDrugToDrugInteraction = 256,
    OverrideModerateDrugToDrugInteraction = 512,
    ShowRxPriceInPatientHistory = 1024,
    // AllowDrugChangeInRefillAndEdit = 4096,
    OverridePatientDrugAllergyWarning = 2048,
    CanOverrideClaimsPaidLessThenCost = 4096,
    // ViewProfit = 8192
}

export enum Rx1E {
    ControlRxProcessing = 1,
    RxNotesLog = 2,
    RxNotes = 4,
    DrugPickVerification = 8,
    PharmacistVerification = 16,
    RefillQueue = 32,
    TransmissionHistory = 64,
    FiledQueue = 128,
    RphDashboard = 256,
    ReturnToStock = 512,
    InsuranceExceptionReport = 1024,
    LabelQueue = 2048,
    ReviewRxsTaggedForFollowUp = 4096,
    RxVerification = 8192,
    ReviewDrugPickVerification = 16384,
    ReviewPharmacistVerification = 32768,
}

export enum Rx2E {
    AllowChangingInventoryBucket = 1,
    ShowProfitOrLossInTransmissionResult = 2,
    ShowProfitOrLossInEditRx = 4
}

export enum FileMaintenanceE {
    PatientFile = 1,
    PrescriberFile = 2,
    DrugFile = 4,
    InsuranceFile = 8,
    RxFollowupTagFile = 16,
    SigFile = 32,
    FacilityFile = 64,
    PriceScheduleFile = 128,
    DrugWarning = 256,
    NcpdpPayerSet = 512,
    TransferPharmacy = 1024,
    PriceList = 2048,
    DiscountScheduleFile = 4096,
    DiagnosisFile = 8192,
    // PatientPayementPreference = 16384,
    Category = 16384
}

export enum FileMaintenance1E {
    PatientNotesLog = 1,
    PrescriberNotesLog = 2,
    DrugNotesLog = 4,
    InsuranceNotesLog = 8,
    LifestyleConcept = 16,
    DoseScheduleFile = 32,
    PrescriberSpecialities = 64,
    DrugDescription = 128,
    DeferredReason = 256,
    PayorFileMaintenance = 512,
    VendorFile = 1024,
    DrugCategory = 2048
}

export enum ReportsE {
    DailyLog = 1,
    RefillsDue = 2,
    UnbilledRxsReport = 4,
    RxsByPharmacist = 8,
    RxsByPrescriber = 16,
    ControlRxReport = 32,
    FiledRxs = 64,
    RefillExpirationReport = 128,
    BusinessReports = 256,
    // ProfitRevenueReport = 512,
    DrugDispensingReport = 512,
    ControlFile = 1024,
    AuditTrails = 2048,
    DrugDispensingEquivalencyReport = 4096,
    InventoryStatusReport = 8192,
    RxsNotPickedUpReport = 16384
}


export enum Reports1E {
    TransferredRxReport = 1,
    RxByInsurance = 2,
    SalesTaxSummary = 4,
    PriceReport = 8,
    PatientMedicationAdherence = 16,
    PartialFill = 32,
}

export enum InventoryE {
    EnterInventoryReceived = 1,
    // VendorFile = 2,
    InventoryReceived = 2,
    InventoryTransactionType = 4,
    PhysicalInventory = 8,
    Bucket = 16,
    AllowChangingInventoryBucket = 32,
    AllowTransferQtyBetweenInventoryBuckets = 64,
    // InventoryTransactionType = 128,
    EDI832PriceUpdateHistory = 128
}

// export enum ClaimProcessingE {
//     CreateNcpdp51SubFile = 1
// }

export enum eRxE {
    eRxActionList = 1,
    eRxExceptionReport = 2,
}

export enum SettingsE {
    SecurityManager = 1,
    AccountSettings = 2,
    ManageOverrideActions = 4
}

export enum ToolsE {
    PriceCheck = 1,
    MergePatients = 2,
    RecallDeletedRx = 4,
    DDICheck = 8,
    BusinessIntelligence = 16,
    ScheduledTasks = 32
}

export enum RxEntryE {
    Transmit = 1,
    DurEntry = 2,
    ChangePrice = 4,
    AttachDocumentToRx = 8,
    DeleteADocument = 16,
    EditADocument = 32,
    ScanADocument = 64,
    AllowDrugChangeoneRxToNewRx = 128
    // CanSaveInsRxAsBilledWithoutTransmitting = 8
}

export enum RefillRxE {
    Transmit = 1,
    CreateNew = 2,
    ChangeAuthorizedRefills = 4,
    // PrintFaxRefillAuthorization = 8,
    SendERefillRequest = 8,
    SendERefillRequestForControls = 16,
    AllowControlRxProcessing = 32,
    AllowDrugChangeOnRefill = 64,
    AttachDocumentToRx = 128,
    DeleteADocument =256,
    EditADocument =  512,
    ScanADocument = 1024
}

export enum EditRxE {
    Transmit = 1,
    ChangePrice = 2,
    ChangeRxStatusBilledUnBilled = 4,
    PrintLabelForUnbilledRx = 8,
    DeleteRx = 16,
    CheckUncheckPickupRecord = 32,
    ReverseTransmission = 64,
    TrackRx = 128,
    OrderStatus = 256,
    AllowDrugChangeOnEditRx = 512,
    AttachDocumentToRx = 1024,
    DeleteADocument = 2048,
    EditADocument = 4096,
    ScanADocument = 8192,
    AllowQtyDispenseChange = 16384,
    AllowQtyOrderChange = 32768

}

// export enum BrowseRxE {
//     ShowDailyTotals = 1
// }

export enum RxTransferE {
    AllowRxTransferIn = 1,
    AllowRxTransferOut = 2
}

export enum PatientHistoryE {
    // ViewPatientProfitability = 1
    SendERefillRequest = 1,
    SendERefillRequestForControls = 2,
    PrintPatientRxRecord = 4
}

export enum DrugPickVerificationE {
    AllowManualDrugPickVerification = 1
}

export enum PharmacistVerificationE {
    AllowRphVerificationIfDrugChange = 1
}

export enum ViewProfitE {
    View = 1
}

export enum ControlRxProcessingE {
    AllowControlRxProcessing = 1,
    AllowElectronicControlRxProcessing = 2
}

export enum RxNotesLogE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum RxNotesE {
    Edit = 1
}


export enum TransmissionHistoryE {
    Edit = 1,
    ViewTransmissionResult = 2
}

export enum PatientFileE {
    Add = 1,
    Edit = 2,
    Delete = 4,
    Export = 8,
    AllowEligibilityCheck = 16
}

export enum PrescriberFileE {
    Add = 1,
    Edit = 2,
    Delete = 4,
    CanUpdatePerscriberNPI = 8
}

export enum DrugFileE {
    Add = 1,
    Edit = 2,
    Delete = 4,
    CompoundFormulation = 8
}


export enum InsuranceFileE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum SigFileE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum FacilityFileE {
    Add = 1,
    Edit = 2,
    Delete = 4,
    AllowChangingInventoryBucket = 8
}

export enum PriceScheduleFileE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum NcpdpPayerSetE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum TransferPharmacyE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum PriceListE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum DiscountScheduleFileE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

// export enum PatientPayementPreferenceE {
//     Add = 1,
//     Edit = 2,
//     Delete = 4,
//     View = 8
// }

export enum CategoryE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum PatientNotesLogE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum PrescriberNotesLogE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum DrugNotesLogE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum InsuranceNotesLogE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum DailyLogE {
    DisplayTotalRxAmount = 1,
    ShowCopayAmount = 2
}

export enum RefillDueE {
    ProfitRange = 1,
    SendSMS = 2,
    ViewProfit = 4
}

export enum BusinessReportE {
    BusinessSummaryReport = 1,
    ProfitRevenueReport = 2,
    TopPerformerReport = 4
}

export enum VendorFileE {
    Add = 1,
    Edit = 2,
    Delete = 4,
    AllowChangingInventoryBucket = 8,
    ViewHistory = 16
}
export enum DrugCategoryE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum eRxActionListE {
    AllowProcessingErxPrescription = 1,
    AllowProcessingChangeRequest = 2,
    AllowProcessingBenefitCheck = 4,
    AllowRemovingErx = 8,
    eRxRphNotes = 16
}

export enum eRxRphNotesE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum PhysicalInventoryE {
    AllowProcessPhyiscalInventoryBatch = 1
}
export enum SecurityManagerE {
    Users = 1,
    Roles = 2,
    UsersLoginHistory = 4,
    OverrideLog = 8,
    AuditLog = 16
}

export enum InventoryTransactionTypeE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum UsersE {
    Add = 1,
    Edit = 2,
    Inactive = 4
}

export enum RolesE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum DoseScheduleFileE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum ControlFileE {
    Add = 1,
    Preview = 2,
    Download = 4
}

export enum AuditTrailsE {
    AnalyseRxAuditTrail = 1,
    ControlRxAndLogicalAccessAuditing = 2,
    ControlRxIncidentAuditing = 4
}
// export enum LabelPrintSetE {
//     Add = 1,
//     Change = 2,
//     Delete = 4
// }

export enum LabelDesignerE {
    Add = 1,
    Edit = 2,
    Delete = 4,
    Preview = 8
}

export enum RxFollowupTagFileE {
    Add = 1,
    Edit = 2,
    Delete = 4
}


export enum SecurityE {
    ManageUsersAndRoles = 1,
    ManageOverridePrivileges = 2
}

export enum AccountSettingsE {
    PharmacyInfo = 1,
    Settings = 2,
    RxSettings = 4,
    // PatientSettings = 8,
    DrugSettings = 8,
    LabelSettings = 16,
    MessagingSettings = 32,
    ControlFileSettings = 64,
    ErxSettings = 128,
    DMSSetings = 256,
    WorkFlowSettings = 512,
    ReportSettings = 1024,
    PriceUpdateSettings = 2048,
    TransmissionSettings = 4096,
    ThreeFourtyBSettings = 8192,
    InventorySettings = 16384
}
export enum AccountSettings1E
{
    ClinicalSettings = 1
}
export enum LabelE {
    // LabelPrintSet = 1,
    LabelDesigner = 1
}

export enum PrescriberSpecialitiesE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum DeferredReasonE {
    Add = 1,
    Edit = 2,
    Delete = 4,
}

export enum BucketE {
    Add = 1,
    Change = 2,
    Delete = 4,
}

export enum PayorFileMaintenanceE {
    Add = 1,
    Edit = 2,
    Delete = 4,
}

export enum ScheduledTasksE {
    AddATask = 1,
    RunATask = 2,
    EditATask = 4,
    DeleteATask = 8
}

export enum ElectronicSignatureE {
    SignatureByPatient = 1,
    SignatureByDate = 2,
    SignatureByRx = 4,
    HIPAAAcknowledgement = 8,
    PrintRxPickupLog = 16,
    SignatureClaimsLog = 32,
    RxPickupDetails = 64,
    POSCopayCollected = 128
}

export enum DMSE {
    DMSSubcategory = 1,
    BatchScan = 2,
    DocumentQueue = 4,
    PrintRxListWithNoAttachedDocuments = 8,
    PrintRxListWithAttachedDocuments = 16,
}

export enum DMSSubcategoryE {
    Add = 1,
    Edit = 2,
    Delete = 4
}

export enum DocumentQueueE {
    ScanNew = 1,
    ImportDocs = 2
}

export enum FAXE {
    RefillRequestFollowUp = 1,
    FaxLog = 2,
    IncomingFax = 4,
    BatchedFaxes = 8
}

export enum RecallDeletedRxE {
    AllowDrugChangeOnRecallDeletedRx = 1
}
export enum SMSE {
    MessageLog = 1,
    MessageQueue = 2
}
export enum PriceReportE {
    PriceChange = 1,
    PriceUpdate = 2,
}
export enum ReportWriterE {
    Add = 1,
    Edit = 2,
    Delete = 4,
    View = 8
}

export class RolesTreeView {
items = [
    {
        header: DocumentE[1],
        CategoryId: AccCategoryE.Document,
        parent: -1,
        index: 0,
        privMask: 1,
        items: [
            {
                header: DocumentE[1],
                CategoryId: AccCategoryE.Document,
                parent: 0,
                index: 0,
                privMask: DocumentE.Documents,
                categoryName : "Documents",
            },
            {
                header: AccCategoryE[2],
                CategoryId: AccCategoryE.Rx,
                parent: 0,
                index: 1,
                privMask: DocumentE.Rx,
                categoryName : "Documents",
                items: [
                    {
                        header: AccCategoryE[2],
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.Rx,
                        parent1: 0,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[11]
                        ),
                        CategoryId: AccCategoryE.RxEntry,
                        parent: 1,
                        index: 1,
                        privMask: RxE.RxEntry,
                        categoryName : "Rx",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[11]
                                ),
                                CategoryId: AccCategoryE.Rx,
                                parent: 2,
                                index: 0,
                                privMask: RxE.RxEntry,
                                parent1: 0,
                                parent2: 1,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RxEntryE[1]
                                ),
                                CategoryId: AccCategoryE.RxEntry,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 1,
                                categoryName : "RxEntry",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RxEntryE[2]
                                ),
                                CategoryId: AccCategoryE.RxEntry,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 0,
                                parent2: 1,
                                categoryName : "RxEntry",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RxEntryE[4]
                                ),
                                CategoryId: AccCategoryE.RxEntry,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 0,
                                parent2: 1,
                                categoryName : "RxEntry",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RxEntryE[8]
                                ),
                                CategoryId: AccCategoryE.RxEntry,
                                parent: 2,
                                index: 4,
                                privMask: 8,
                                parent1: 0,
                                parent2: 1,
                                categoryName : "RxEntry",
                            },
                            {
                                header: "Delete Document",
                                CategoryId: AccCategoryE.RxEntry,
                                parent: 2,
                                index: 5,
                                privMask: 16,
                                parent1: 0,
                                parent2: 1,
                                categoryName : "RxEntry",
                            },
                            {
                                header: "Edit Document",
                                CategoryId: AccCategoryE.RxEntry,
                                parent: 2,
                                index: 6,
                                privMask: 32,
                                parent1: 0,
                                parent2: 1,
                                categoryName : "RxEntry",
                            },
                            {
                                header: "Scan Document",
                                CategoryId: AccCategoryE.RxEntry,
                                parent: 2,
                                index: 7,
                                privMask: 64,
                                parent1: 0,
                                parent2: 1,
                                categoryName : "RxEntry",
                            },
                            {
                                header: "Allow Drug Change on eRx To New Rx",
                                CategoryId: AccCategoryE.RxEntry,
                                parent: 2,
                                index: 8,
                                privMask: 128,
                                parent1: 0,
                                parent2: 1,
                                categoryName : "RxEntry",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[12]
                        ),
                        CategoryId: AccCategoryE.RefillRx,
                        parent: 1,
                        index: 2,
                        privMask: RxE.RefillRx,
                        categoryName : "Rx",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[12]
                                ),
                                CategoryId: AccCategoryE.Rx,
                                parent: 2,
                                index: 0,
                                privMask: RxE.RefillRx,
                                parent1: 0,
                                parent2: 2,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillRxE[1]
                                ),
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillRxE[2]
                                ),
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillRxE[4]
                                ),
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillRxE[8]
                                ),
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 4,
                                privMask: 8,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillRxE[16]
                                ),
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 5,
                                privMask: 16,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillRxE[32]
                                ),
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 6,
                                privMask: 32,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillRxE[64]
                                ),
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 7,
                                privMask: 64,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillRxE[128]
                                ),
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 8,
                                privMask: 128,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            {
                                header: "Delete Document",
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 9,
                                privMask: 256,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            {
                                header: "Edit Document",
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 10,
                                privMask: 512,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            {
                                header: "Scan Document",
                                CategoryId: AccCategoryE.RefillRx,
                                parent: 2,
                                index: 11,
                                privMask: 1024,
                                parent1: 0,
                                parent2: 2,
                                categoryName : "RefillRx",
                            },
                            // {
                            //     header: new StringSepWithSpace().transform(
                            //         RefillRxE[2048]
                            //     ),
                            //     CategoryId: AccCategoryE.RefillRx,
                            //     parent: 2,
                            //     index: 12,
                            //     privMask: 2048,
                            //     parent1: 0,
                            //     parent2: 2
                            // }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[13]
                        ),
                        CategoryId: AccCategoryE.EditRx,
                        parent: 1,
                        index: 3,
                        privMask: 4,
                        categoryName : "Rx",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[13]
                                ),
                                CategoryId: AccCategoryE.Rx,
                                parent: 2,
                                index: 0,
                                privMask: RxE.EditRx,
                                parent1: 0,
                                parent2: 3,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[1]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[2]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: "Change Rx Status (Billed/Unbilled)",
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[8]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 4,
                                privMask: 8,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[16]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 5,
                                privMask: 16,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[32]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 6,
                                privMask: 32,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[64]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 7,
                                privMask: 64,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[128]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 8,
                                privMask: 128,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[256]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 9,
                                privMask: 256,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[512]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 10,
                                privMask: 512,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[1024]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 11,
                                privMask: 1024,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: "Delete Document",
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 12,
                                privMask: 2048,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: "Edit Document",
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 13,
                                privMask: 4096,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: "Scan Document",
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 14,
                                privMask: 8192,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[16384]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 15,
                                privMask: 16384,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    EditRxE[32768]
                                ),
                                CategoryId: AccCategoryE.EditRx,
                                parent: 2,
                                index: 16,
                                privMask: 32768,
                                parent1: 0,
                                parent2: 3,
                                categoryName : "EditRx",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[14]
                        ),
                        CategoryId: AccCategoryE.Rx,
                        parent: 1,
                        index: 4,
                        privMask: RxE.BrowseRx,
                        parent1: 0,
                        parent2: 4,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[63]
                        ),
                        CategoryId: AccCategoryE.RxTransfer,
                        parent: 1,
                        index: 5,
                        privMask: RxE.RxTransfer,
                        categoryName : "Rx",
                        // parent1: 0,
                        // parent2: 5,
                        // key: 1
                        items : [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[63]
                                ),
                                CategoryId: AccCategoryE.Rx,
                                parent: 2,
                                index: 0,
                                privMask: RxE.RxTransfer,
                                parent1: 0,
                                parent2: 5,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RxTransferE[1]
                                ),
                                CategoryId: AccCategoryE.RxTransfer,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 5,
                                categoryName : "RxTransfer",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RxTransferE[2]
                                ),
                                CategoryId: AccCategoryE.RxTransfer,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 0,
                                parent2: 5,
                                categoryName : "RxTransfer",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            RxE[32]
                        ),
                        CategoryId: AccCategoryE.Rx,
                        parent: 1,
                        index: 6,
                        privMask: 32,
                        parent1: 0,
                        parent2: 6,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[15]
                        ),
                        CategoryId: AccCategoryE.PatientHistory,
                        parent: 1,
                        index: 7,
                        privMask: RxE.PatientHistory,
                        categoryName : "Rx",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[15]
                                ),
                                CategoryId: AccCategoryE.Rx,
                                parent: 2,
                                index: 0,
                                privMask: RxE.PatientHistory,
                                parent1: 0,
                                parent2: 7,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    PatientHistoryE[1]
                                ),
                                CategoryId: AccCategoryE.PatientHistory,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 7,
                                categoryName : "PatientHistory",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    PatientHistoryE[2]
                                ),
                                CategoryId: AccCategoryE.PatientHistory,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 0,
                                parent2: 7,
                                categoryName : "PatientHistory",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    PatientHistoryE[4]
                                ),
                                CategoryId: AccCategoryE.PatientHistory,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 0,
                                parent2: 7,
                                categoryName : "PatientHistory",
                            }
                        ]
                    },
                    // {
                    //     header: new StringSepWithSpace().transform(
                    //         RxE[128]
                    //     ),
                    //     CategoryId: AccCategoryE.Rx,
                    //     parent: 1,
                    //     index: 8,
                    //     privMask: RxE.BatchTransmission,
                    //     parent1: 0,
                    //     parent2: 8,
                    //     key: 1
                    // },
                    {
                        header: new StringSepWithSpace().transform(
                            RxE[128]
                        ),
                        CategoryId: AccCategoryE.Rx,
                        parent: 1,
                        index: 8,
                        privMask: RxE.QuickRefill,
                        parent1: 0,
                        parent2: 8,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            RxE[256]
                        ),
                        CategoryId: AccCategoryE.Rx,
                        parent: 1,
                        index: 9,
                        privMask:
                            RxE.OverrideSevereDrugToDrugInteraction,
                        parent1: 0,
                        parent2: 9,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            RxE[512]
                        ),
                        CategoryId: AccCategoryE.Rx,
                        parent: 1,
                        index: 10,
                        privMask:
                            RxE.OverrideModerateDrugToDrugInteraction,
                        parent1: 0,
                        parent2: 10,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[64]
                        ),
                        CategoryId: AccCategoryE.DrugPickVerification,
                        parent: 1,
                        index: 11,
                        privMask: Rx1E.DrugPickVerification,
                        categoryName : "Rx",
                        // parent1: 0,
                        // parent2: 12,
                        // key: 1
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[64]
                                ),
                                CategoryId: AccCategoryE.Rx1,
                                parent: 2,
                                index: 0,
                                privMask: Rx1E.DrugPickVerification,
                                parent1: 0,
                                parent2: 11,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DrugPickVerificationE[1]
                                ),
                                CategoryId: AccCategoryE.DrugPickVerification,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 11,
                                categoryName : "DrugPickVerification",
                            },
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[80]
                        ),
                        CategoryId: AccCategoryE.PharmacistVerification,
                        parent: 1,
                        index: 12,
                        privMask: Rx1E.PharmacistVerification,
                        categoryName : "Rx",
                        // parent1: 0,
                        // parent2: 12,
                        // key: 1
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[80]
                                ),
                                CategoryId: AccCategoryE.Rx1,
                                parent: 2,
                                index: 0,
                                privMask: Rx1E.PharmacistVerification,
                                parent1: 0,
                                parent2: 12,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: "Allow Rph verification if Drug Change",
                                CategoryId: AccCategoryE.PharmacistVerification,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 12,
                                categoryName : "PharmacistVerification",
                            },
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            RxE[1024]
                        ),
                        CategoryId: AccCategoryE.Rx,
                        parent: 1,
                        index: 13,
                        privMask: RxE.ShowRxPriceInPatientHistory,
                        parent1: 0,
                        parent2: 13,
                        key: 1,
                        categoryName : "Rx",
                    },
                    // {
                    //     header: new StringSepWithSpace().transform(
                    //         RxE[4096]
                    //     ),
                    //     CategoryId: AccCategoryE.Rx,
                    //     parent: 1,
                    //     index: 15,
                    //     privMask: RxE.AllowDrugChangeInRefillAndEdit,
                    //     parent1: 0,
                    //     parent2: 15,
                    //     key: 1
                    // },
                    {
                        header: new StringSepWithSpace().transform(
                            RxE[2048]
                        ),
                        CategoryId: AccCategoryE.Rx,
                        parent: 1,
                        index: 14,
                        privMask: RxE.OverridePatientDrugAllergyWarning,
                        parent1: 0,
                        parent2: 14,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            RxE[4096]
                        ),
                        CategoryId: AccCategoryE.Rx,
                        parent: 1,
                        index: 15,
                        privMask: RxE.CanOverrideClaimsPaidLessThenCost,
                        parent1: 0,
                        parent2: 15,
                        key: 1,
                        categoryName : "Rx",
                    },
                    // {
                    //     header: new StringSepWithSpace().transform(
                    //         RxE[16384]
                    //     ),
                    //     CategoryId: AccCategoryE.Rx,
                    //     parent: 1,
                    //     index: 17,
                    //     privMask: RxE.ViewProfit,
                    //     parent1: 0,
                    //     parent2: 17,
                    //     key: 1
                    // },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[17]
                        ),
                        CategoryId: AccCategoryE.ControlRxProcessing,
                        parent: 1,
                        index: 16,
                        privMask: Rx1E.ControlRxProcessing,
                        categoryName : "Rx",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[17]
                                ),
                                CategoryId: AccCategoryE.Rx1,
                                parent: 2,
                                index: 0,
                                privMask: Rx1E.ControlRxProcessing,
                                parent1: 0,
                                parent2: 16,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    ControlRxProcessingE[1]
                                ),
                                CategoryId:
                                    AccCategoryE.ControlRxProcessing,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 16,
                                categoryName : "ControlRxProcessing",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    ControlRxProcessingE[2]
                                ),
                                CategoryId:
                                    AccCategoryE.ControlRxProcessing,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 0,
                                parent2: 16,
                                categoryName : "ControlRxProcessing",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[18]
                        ),
                        CategoryId: AccCategoryE.RxNotesLog,
                        parent: 1,
                        index: 17,
                        privMask: Rx1E.RxNotesLog,
                        categoryName : "Rx",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[18]
                                ),
                                CategoryId: AccCategoryE.Rx1,
                                parent: 2,
                                index: 0,
                                privMask: Rx1E.RxNotesLog,
                                parent1: 0,
                                parent2: 17,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RxNotesLogE[1]
                                ),
                                CategoryId: AccCategoryE.RxNotesLog,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 17,
                                categoryName : "RxNotesLog",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RxNotesLogE[2]
                                ),
                                CategoryId: AccCategoryE.RxNotesLog,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 0,
                                parent2: 17,
                                categoryName : "RxNotesLog",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RxNotesLogE[4]
                                ),
                                CategoryId: AccCategoryE.RxNotesLog,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 0,
                                parent2: 17,
                                categoryName : "RxNotesLog",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[19]
                        ),
                        CategoryId: AccCategoryE.RxNotes,
                        parent: 1,
                        index: 18,
                        privMask: Rx1E.RxNotes,
                        categoryName : "Rx",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[19]
                                ),
                                CategoryId: AccCategoryE.Rx1,
                                parent: 2,
                                index: 0,
                                privMask: Rx1E.RxNotes,
                                parent1: 0,
                                parent2: 18,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RxNotesE[1]
                                ),
                                CategoryId: AccCategoryE.RxNotes,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 18,
                                categoryName : "RxNotes",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx1E[32]
                        ),
                        CategoryId: AccCategoryE.Rx1,
                        parent: 1,
                        index: 19,
                        privMask: Rx1E.RefillQueue,
                        parent1: 0,
                        parent2: 19,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[65]
                        ),
                        CategoryId: AccCategoryE.TransmissionHistory,
                        parent: 1,
                        index: 20,
                        privMask: Rx1E.TransmissionHistory,
                        categoryName : "Rx",
                        // parent1: 0,
                        // parent2: 22,
                        // key: 1
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[65]
                                ),
                                CategoryId: AccCategoryE.Rx1,
                                parent: 2,
                                index: 0,
                                privMask: Rx1E.TransmissionHistory,
                                parent1: 0,
                                parent2: 20,
                                key: 1,
                                categoryName : "Rx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    TransmissionHistoryE[1]
                                ),
                                CategoryId: AccCategoryE.TransmissionHistory,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 0,
                                parent2: 20,
                                categoryName : "TransmissionHistory",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    TransmissionHistoryE[2]
                                ),
                                CategoryId: AccCategoryE.TransmissionHistory,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 0,
                                parent2: 20,
                                categoryName : "TransmissionHistory",
                            },
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx1E[128]
                        ),
                        CategoryId: AccCategoryE.Rx1,
                        parent: 1,
                        index: 21,
                        privMask: Rx1E.FiledQueue,
                        parent1: 0,
                        parent2: 21,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx1E[256]
                        ),
                        CategoryId: AccCategoryE.Rx1,
                        parent: 1,
                        index: 22,
                        privMask: Rx1E.RphDashboard,
                        parent1: 0,
                        parent2: 22,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx1E[512]
                        ),
                        CategoryId: AccCategoryE.Rx1,
                        parent: 1,
                        index: 23,
                        privMask: Rx1E.ReturnToStock,
                        parent1: 0,
                        parent2: 23,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx1E[1024]
                        ),
                        CategoryId: AccCategoryE.Rx1,
                        parent: 1,
                        index: 24,
                        privMask: Rx1E.InsuranceExceptionReport,
                        parent1: 0,
                        parent2: 24,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx1E[2048]
                        ),
                        CategoryId: AccCategoryE.Rx1,
                        parent: 1,
                        index: 25,
                        privMask: Rx1E.LabelQueue,
                        parent1: 0,
                        parent2: 25,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx1E[4096]
                        ),
                        CategoryId: AccCategoryE.Rx1,
                        parent: 1,
                        index: 26,
                        privMask: Rx1E.ReviewRxsTaggedForFollowUp,
                        parent1: 0,
                        parent2: 26,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx1E[8192]
                        ),
                        CategoryId: AccCategoryE.Rx1,
                        parent: 1,
                        index: 27,
                        privMask: Rx1E.RxVerification,
                        parent1: 0,
                        parent2: 27,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx1E[16384]
                        ),
                        CategoryId: AccCategoryE.Rx1,
                        parent: 1,
                        index: 28,
                        privMask: Rx1E.ReviewDrugPickVerification,
                        parent1: 0,
                        parent2: 28,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx1E[32768]
                        ),
                        CategoryId: AccCategoryE.Rx1,
                        parent: 1,
                        index: 29,
                        privMask: Rx1E.ReviewPharmacistVerification,
                        parent1: 0,
                        parent2: 29,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Rx2E[1]
                        ),
                        CategoryId: AccCategoryE.Rx2,
                        parent: 1,
                        index: 30,
                        privMask: Rx2E.AllowChangingInventoryBucket,
                        parent1: 0,
                        parent2: 30,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: "Show Profit/Loss in Transmission Result",
                        CategoryId: AccCategoryE.Rx2,
                        parent: 1,
                        index: 31,
                        privMask: Rx2E.ShowProfitOrLossInTransmissionResult,
                        parent1: 0,
                        parent2: 31,
                        key: 1,
                        categoryName : "Rx",
                    },
                    {
                        header: "Show Profit/Loss in Edit Rx",
                        CategoryId: AccCategoryE.Rx2,
                        parent: 1,
                        index: 32,
                        privMask: Rx2E.ShowProfitOrLossInEditRx,
                        parent1: 0,
                        parent2: 32,
                        key: 1,
                        categoryName : "Rx",
                    },
                ]
            },
            {
                header: new StringSepWithSpace().transform(
                AccCategoryE[4]),
                CategoryId: AccCategoryE.FileMaintenance,
                parent: 0,
                index: 2,
                privMask: DocumentE.FileMaintenance,
                categoryName : "Documents",
                items: [
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[4]
                        ),
                        CategoryId: AccCategoryE.Document,
                        parent: 2,
                        index: 0,
                        privMask: DocumentE.FileMaintenance,
                        parent1: 1,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[20]
                        ),
                        CategoryId: AccCategoryE.PatientFile,
                        parent: 1,
                        index: 1,
                        privMask: FileMaintenanceE.PatientFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[20]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask: FileMaintenanceE.PatientFile,
                                parent1: 1,
                                parent2: 1,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: PatientFileE[1],
                                CategoryId: AccCategoryE.PatientFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 1,
                                categoryName : "PatientFile",
                            },
                            {
                                header: PatientFileE[2],
                                CategoryId: AccCategoryE.PatientFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 1,
                                categoryName : "PatientFile",
                            },
                            {
                                header: PatientFileE[4],
                                CategoryId: AccCategoryE.PatientFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 1,
                                categoryName : "PatientFile",
                            },
                            {
                                header: PatientFileE[8],
                                CategoryId: AccCategoryE.PatientFile,
                                parent: 2,
                                index: 4,
                                privMask: 8,
                                parent1: 1,
                                parent2: 1,
                                categoryName : "PatientFile",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    PatientFileE[16]
                                ),
                                CategoryId: AccCategoryE.PatientFile,
                                parent: 2,
                                index: 5,
                                privMask: 16,
                                parent1: 1,
                                parent2: 1,
                                categoryName : "PatientFile",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[21]
                        ),
                        CategoryId: AccCategoryE.PrescriberFile,
                        parent: 1,
                        index: 2,
                        privMask: FileMaintenanceE.PrescriberFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[21]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenanceE.PrescriberFile,
                                parent1: 1,
                                parent2: 2,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: PrescriberFileE[1],
                                CategoryId: AccCategoryE.PrescriberFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 2,
                                categoryName : "PrescriberFile",
                            },
                            {
                                header: PrescriberFileE[2],
                                CategoryId: AccCategoryE.PrescriberFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 2,
                                categoryName : "PrescriberFile",
                            },
                            {
                                header: PrescriberFileE[4],
                                CategoryId: AccCategoryE.PrescriberFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 2,
                                categoryName : "PrescriberFile",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    PrescriberFileE[8]
                                ),
                                CategoryId: AccCategoryE.PrescriberFile,
                                parent: 2,
                                index: 4,
                                privMask: 8,
                                parent1: 1,
                                parent2: 2,
                                categoryName : "PrescriberFile",
                            },
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[22]
                        ),
                        CategoryId: AccCategoryE.DrugFile,
                        parent: 1,
                        index: 3,
                        privMask: FileMaintenanceE.DrugFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[22]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask: FileMaintenanceE.DrugFile,
                                parent1: 1,
                                parent2: 3,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: DrugFileE[1],
                                CategoryId: AccCategoryE.DrugFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 3,
                                categoryName : "DrugFile",
                            },
                            {
                                header: DrugFileE[2],
                                CategoryId: AccCategoryE.DrugFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 3,
                                categoryName : "DrugFile",
                            },
                            {
                                header: DrugFileE[4],
                                CategoryId: AccCategoryE.DrugFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 3,
                                categoryName : "DrugFile",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DrugFileE[8]
                                ),
                                CategoryId: AccCategoryE.DrugFile,
                                parent: 2,
                                index: 4,
                                privMask: 8,
                                parent1: 1,
                                parent2: 3,
                                categoryName : "DrugFile",
                            },
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[23]
                        ),
                        CategoryId: AccCategoryE.InsuranceFile,
                        parent: 1,
                        index: 4,
                        privMask: FileMaintenanceE.InsuranceFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[23]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenanceE.InsuranceFile,
                                parent1: 1,
                                parent2: 4,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: InsuranceFileE[1],
                                CategoryId: AccCategoryE.InsuranceFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 4,
                                categoryName : "InsuranceFile",
                            },
                            {
                                header: InsuranceFileE[2],
                                CategoryId: AccCategoryE.InsuranceFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 4,
                                categoryName : "InsuranceFile",
                            },
                            {
                                header: InsuranceFileE[4],
                                CategoryId: AccCategoryE.InsuranceFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 4,
                                categoryName : "InsuranceFile",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            FileMaintenanceE[16]
                        ),
                        CategoryId: AccCategoryE.RxFollowupTagFile,
                        parent: 1,
                        index: 5,
                        privMask: FileMaintenanceE.RxFollowupTagFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    FileMaintenanceE[16]
                                ),
                                CategoryId: AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask: FileMaintenanceE.RxFollowupTagFile,
                                parent1: 1,
                                parent2: 5,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: RxFollowupTagFileE[1],
                                CategoryId: AccCategoryE.RxFollowupTagFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 5,
                                categoryName : "RxFollowupTagFile",
                            },
                            {
                                header: RxFollowupTagFileE[2],
                                CategoryId: AccCategoryE.RxFollowupTagFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 5,
                                categoryName : "RxFollowupTagFile",
                            },
                            {
                                header: RxFollowupTagFileE[4],
                                CategoryId: AccCategoryE.RxFollowupTagFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 5,
                                categoryName : "RxFollowupTagFile",
                            },
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[24]
                        ),
                        CategoryId: AccCategoryE.SigFile,
                        parent: 1,
                        index: 6,
                        privMask: FileMaintenanceE.SigFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[24]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask: FileMaintenanceE.SigFile,
                                parent1: 1,
                                parent2: 6,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: SigFileE[1],
                                CategoryId: AccCategoryE.SigFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 6,
                                categoryName : "SigFile",
                            },
                            {
                                header: SigFileE[2],
                                CategoryId: AccCategoryE.SigFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 6,
                                categoryName : "SigFile",
                            },
                            {
                                header: SigFileE[4],
                                CategoryId: AccCategoryE.SigFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 6,
                                categoryName : "SigFile",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[25]
                        ),
                        CategoryId: AccCategoryE.FacilityFile,
                        parent: 1,
                        index: 7,
                        privMask: FileMaintenanceE.FacilityFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[25]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask: FileMaintenanceE.FacilityFile,
                                parent1: 1,
                                parent2: 7,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: FacilityFileE[1],
                                CategoryId: AccCategoryE.FacilityFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 7,
                                categoryName : "FacilityFile",
                            },
                            {
                                header: FacilityFileE[2],
                                CategoryId: AccCategoryE.FacilityFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 7,
                                categoryName : "FacilityFile",
                            },
                            {
                                header: FacilityFileE[4],
                                CategoryId: AccCategoryE.FacilityFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 7,
                                categoryName : "FacilityFile",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    FacilityFileE[8]
                                ),
                                CategoryId: AccCategoryE.FacilityFile,
                                parent: 2,
                                index: 4,
                                privMask: FacilityFileE.AllowChangingInventoryBucket,
                                parent1: 1,
                                parent2: 7,
                                categoryName : "FacilityFile",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[26]
                        ),
                        CategoryId: AccCategoryE.PriceScheduleFile,
                        parent: 1,
                        index: 8,
                        privMask: FileMaintenanceE.PriceScheduleFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[26]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenanceE.PriceScheduleFile,
                                parent1: 1,
                                parent2: 8,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: PriceScheduleFileE[1],
                                CategoryId:
                                    AccCategoryE.PriceScheduleFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 8,
                                categoryName : "PriceScheduleFile",
                            },
                            {
                                header: PriceScheduleFileE[2],
                                CategoryId:
                                    AccCategoryE.PriceScheduleFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 8,
                                categoryName : "PriceScheduleFile",
                            },
                            {
                                header: PriceScheduleFileE[4],
                                CategoryId:
                                    AccCategoryE.PriceScheduleFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 8,
                                categoryName : "PriceScheduleFile",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            FileMaintenanceE[256]
                        ),
                        CategoryId: AccCategoryE.FileMaintenance,
                        parent: 1,
                        index: 9,
                        privMask: 256,
                        parent1: 1,
                        parent2: 9,
                        key: 1,
                        categoryName : "FileMaintenance",
                    },
                    {
                        header: "NCPDP Payer Set",
                        CategoryId: AccCategoryE.NcpdpPayerSet,
                        parent: 1,
                        index: 10,
                        privMask: FileMaintenanceE.NcpdpPayerSet,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[27]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask: FileMaintenanceE.NcpdpPayerSet,
                                parent1: 1,
                                parent2: 10,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: NcpdpPayerSetE[1],
                                CategoryId: AccCategoryE.NcpdpPayerSet,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 10,
                                categoryName : "NCPDPPayerSet",
                            },
                            {
                                header: NcpdpPayerSetE[2],
                                CategoryId: AccCategoryE.NcpdpPayerSet,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 10,
                                categoryName : "NCPDPPayerSet",
                            },
                            {
                                header: NcpdpPayerSetE[4],
                                CategoryId: AccCategoryE.NcpdpPayerSet,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 10,
                                categoryName : "NCPDPPayerSet",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[28]
                        ),
                        CategoryId: AccCategoryE.TransferPharmacy,
                        parent: 1,
                        index: 11,
                        privMask: FileMaintenanceE.TransferPharmacy,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[28]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenanceE.TransferPharmacy,
                                parent1: 1,
                                parent2: 11,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: TransferPharmacyE[1],
                                CategoryId:
                                    AccCategoryE.TransferPharmacy,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 11,
                                categoryName : "TransferPharmacy",
                            },
                            {
                                header: TransferPharmacyE[2],
                                CategoryId:
                                    AccCategoryE.TransferPharmacy,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 11,
                                categoryName : "TransferPharmacy",
                            },
                            {
                                header: TransferPharmacyE[4],
                                CategoryId:
                                    AccCategoryE.TransferPharmacy,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 11,
                                categoryName : "TransferPharmacy",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[29]
                        ),
                        CategoryId: AccCategoryE.PriceList,
                        parent: 1,
                        index: 12,
                        privMask: FileMaintenanceE.PriceList,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[29]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask: FileMaintenanceE.PriceList,
                                parent1: 1,
                                parent2: 12,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: PriceListE[1],
                                CategoryId: AccCategoryE.PriceList,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 12,
                                categoryName : "PriceList",
                            },
                            {
                                header: PriceListE[2],
                                CategoryId: AccCategoryE.PriceList,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 12,
                                categoryName : "PriceList",
                            },
                            {
                                header: PriceListE[4],
                                CategoryId: AccCategoryE.PriceList,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 12,
                                categoryName : "PriceList",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[30]
                        ),
                        CategoryId: AccCategoryE.DiscountScheduleFile,
                        parent: 1,
                        index: 13,
                        privMask: FileMaintenanceE.DiscountScheduleFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[30]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenanceE.DiscountScheduleFile,
                                parent1: 1,
                                parent2: 13,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: DiscountScheduleFileE[1],
                                CategoryId:
                                    AccCategoryE.DiscountScheduleFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 13,
                                categoryName : "DiscountScheduleFile",
                            },
                            {
                                header: DiscountScheduleFileE[2],
                                CategoryId:
                                    AccCategoryE.DiscountScheduleFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 13,
                                categoryName : "DiscountScheduleFile",
                            },
                            {
                                header: DiscountScheduleFileE[4],
                                CategoryId:
                                    AccCategoryE.DiscountScheduleFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 13,
                                categoryName : "DiscountScheduleFile",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            FileMaintenanceE[8192]
                        ),
                        CategoryId: AccCategoryE.FileMaintenance,
                        parent: 1,
                        index: 14,
                        privMask: 8192,
                        parent1: 1,
                        parent2: 14,
                        key: 1,
                        categoryName : "FileMaintenance",
                    },
                    // {
                    //     header: new StringSepWithSpace().transform(
                    //         AccCategoryE[31]
                    //     ),
                    //     CategoryId:
                    //         AccCategoryE.PatientPayementPreference,
                    //     parent: 1,
                    //     index: 15,
                    //     privMask:
                    //         FileMaintenanceE.PatientPayementPreference,
                    //     items: [
                    //         {
                    //             header: new StringSepWithSpace().transform(
                    //                 AccCategoryE[31]
                    //             ),
                    //             CategoryId:
                    //                 AccCategoryE.FileMaintenance,
                    //             parent: 2,
                    //             index: 0,
                    //             privMask:
                    //                 FileMaintenanceE.PatientPayementPreference,
                    //             parent1: 1,
                    //             parent2: 15,
                    //             key: 1
                    //         },
                    //         {
                    //             header: PatientPayementPreferenceE[1],
                    //             CategoryId:
                    //                 AccCategoryE.PatientPayementPreference,
                    //             parent: 2,
                    //             index: 1,
                    //             privMask: 1,
                    //             parent1: 1,
                    //             parent2: 15
                    //         },
                    //         {
                    //             header: PatientPayementPreferenceE[2],
                    //             CategoryId:
                    //                 AccCategoryE.PatientPayementPreference,
                    //             parent: 2,
                    //             index: 2,
                    //             privMask: 2,
                    //             parent1: 1,
                    //             parent2: 15
                    //         },
                    //         {
                    //             header: PatientPayementPreferenceE[4],
                    //             CategoryId:
                    //                 AccCategoryE.PatientPayementPreference,
                    //             parent: 2,
                    //             index: 3,
                    //             privMask: 4,
                    //             parent1: 1,
                    //             parent2: 15
                    //         },
                    //         {
                    //             header: PatientPayementPreferenceE[8],
                    //             CategoryId:
                    //                 AccCategoryE.PatientPayementPreference,
                    //             parent: 2,
                    //             index: 4,
                    //             privMask: 8,
                    //             parent1: 1,
                    //             parent2: 15
                    //         }
                    //     ]
                    // },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[32]
                        ),
                        CategoryId: AccCategoryE.Category,
                        parent: 1,
                        index: 15,
                        privMask: FileMaintenanceE.Category,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[32]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance,
                                parent: 2,
                                index: 0,
                                privMask: FileMaintenanceE.Category,
                                parent1: 1,
                                parent2: 15,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: CategoryE[1],
                                CategoryId: AccCategoryE.Category,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 15,
                                categoryName : "Category",
                            },
                            {
                                header: CategoryE[2],
                                CategoryId: AccCategoryE.Category,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 15,
                                categoryName : "Category",
                            },
                            {
                                header: CategoryE[4],
                                CategoryId: AccCategoryE.Category,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 15,
                                categoryName : "Category",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[33]
                        ),
                        CategoryId: AccCategoryE.PatientNotesLog,
                        parent: 1,
                        index: 16,
                        privMask: FileMaintenance1E.PatientNotesLog,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[33]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance1,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenance1E.PatientNotesLog,
                                parent1: 1,
                                parent2: 16,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: PatientNotesLogE[1],
                                CategoryId:
                                    AccCategoryE.PatientNotesLog,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 16,
                                categoryName : "PatientNotesLog",
                            },
                            {
                                header: PatientNotesLogE[2],
                                CategoryId:
                                    AccCategoryE.PatientNotesLog,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 16,
                                categoryName : "PatientNotesLog",
                            },
                            {
                                header: PatientNotesLogE[4],
                                CategoryId:
                                    AccCategoryE.PatientNotesLog,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 16,
                                categoryName : "PatientNotesLog",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[34]
                        ),
                        CategoryId: AccCategoryE.PrescriberNotesLog,
                        parent: 1,
                        index: 17,
                        privMask: FileMaintenance1E.PrescriberNotesLog,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[34]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance1,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenance1E.PrescriberNotesLog,
                                parent1: 1,
                                parent2: 17,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: PrescriberNotesLogE[1],
                                CategoryId:
                                    AccCategoryE.PrescriberNotesLog,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 17,
                                categoryName : "PrescriberNotesLog",
                            },
                            {
                                header: PrescriberNotesLogE[2],
                                CategoryId:
                                    AccCategoryE.PrescriberNotesLog,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 17,
                                categoryName : "PrescriberNotesLog",
                            },
                            {
                                header: PrescriberNotesLogE[4],
                                CategoryId:
                                    AccCategoryE.PrescriberNotesLog,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 17,
                                categoryName : "PrescriberNotesLog",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[35]
                        ),
                        CategoryId: AccCategoryE.DrugNotesLog,
                        parent: 1,
                        index: 18,
                        privMask: FileMaintenance1E.DrugNotesLog,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[35]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance1,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenance1E.DrugNotesLog,
                                parent1: 1,
                                parent2: 18,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: DrugNotesLogE[1],
                                CategoryId: AccCategoryE.DrugNotesLog,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 18,
                                categoryName : "DrugNotesLog",
                            },
                            {
                                header: DrugNotesLogE[2],
                                CategoryId: AccCategoryE.DrugNotesLog,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 18,
                                categoryName : "DrugNotesLog",
                            },
                            {
                                header: DrugNotesLogE[4],
                                CategoryId: AccCategoryE.DrugNotesLog,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 18,
                                categoryName : "DrugNotesLog",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[36]
                        ),
                        CategoryId: AccCategoryE.InsuranceNotesLog,
                        parent: 1,
                        index: 19,
                        privMask: FileMaintenance1E.InsuranceNotesLog,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[36]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance1,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenance1E.InsuranceNotesLog,
                                parent1: 1,
                                parent2: 19,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: InsuranceNotesLogE[1],
                                CategoryId:
                                    AccCategoryE.InsuranceNotesLog,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 19,
                                categoryName : "InsuranceNotesLog",
                            },
                            {
                                header: InsuranceNotesLogE[2],
                                CategoryId:
                                    AccCategoryE.InsuranceNotesLog,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 19,
                                categoryName : "InsuranceNotesLog",
                            },
                            {
                                header: InsuranceNotesLogE[4],
                                CategoryId:
                                    AccCategoryE.InsuranceNotesLog,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 19,
                                categoryName : "InsuranceNotesLog",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            FileMaintenance1E[16]
                        ),
                        CategoryId: AccCategoryE.FileMaintenance1,
                        parent: 1,
                        index: 20,
                        privMask: 16,
                        parent1: 1,
                        parent2: 20,
                        key: 1,
                        categoryName : "FileMaintenance",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[40]
                        ),
                        CategoryId: AccCategoryE.DoseScheduleFile,
                        parent: 1,
                        index: 21,
                        privMask: FileMaintenance1E.DoseScheduleFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[40]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance1,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenance1E.DoseScheduleFile,
                                parent1: 1,
                                parent2: 21,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: DoseScheduleFileE[1],
                                CategoryId:
                                    AccCategoryE.DoseScheduleFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 21,
                                categoryName : "DoseScheduleFile",
                            },
                            {
                                header: DoseScheduleFileE[2],
                                CategoryId:
                                    AccCategoryE.DoseScheduleFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 21,
                                categoryName : "DoseScheduleFile",
                            },
                            {
                                header: DoseScheduleFileE[4],
                                CategoryId:
                                    AccCategoryE.DoseScheduleFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 21,
                                categoryName : "DoseScheduleFile",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[54]
                        ),
                        CategoryId: AccCategoryE.PrescriberSpecialities,
                        parent: 1,
                        index: 22,
                        privMask: FileMaintenance1E.PrescriberSpecialities,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[54]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance1,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenance1E.PrescriberSpecialities,
                                parent1: 1,
                                parent2: 22,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: PrescriberSpecialitiesE[1],
                                CategoryId:
                                    AccCategoryE.PrescriberSpecialities,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 22,
                                categoryName : "PrescriberSpecialities",
                            },
                            {
                                header: PrescriberSpecialitiesE[2],
                                CategoryId:
                                    AccCategoryE.PrescriberSpecialities,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 22,
                                categoryName : "PrescriberSpecialities",
                            },
                            {
                                header: PrescriberSpecialitiesE[4],
                                CategoryId:
                                    AccCategoryE.PrescriberSpecialities,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 22,
                                categoryName : "PrescriberSpecialities",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            FileMaintenance1E[128]
                        ),
                        CategoryId: AccCategoryE.FileMaintenance1,
                        parent: 1,
                        index: 23,
                        privMask: 128,
                        parent1: 1,
                        parent2: 23,
                        key: 1,
                        categoryName : "FileMaintenance",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[55]
                        ),
                        CategoryId: AccCategoryE.DeferredReason,
                        parent: 1,
                        index: 24,
                        privMask: FileMaintenance1E.DeferredReason,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[55]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance1,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenance1E.DeferredReason,
                                parent1: 1,
                                parent2: 24,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: DeferredReasonE[1],
                                CategoryId:
                                    AccCategoryE.DeferredReason,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 24,
                                categoryName : "DeferredReason",
                            },
                            {
                                header: DeferredReasonE[2],
                                CategoryId:
                                    AccCategoryE.DeferredReason,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 24,
                                categoryName : "DeferredReason",
                            },
                            {
                                header: DeferredReasonE[4],
                                CategoryId:
                                    AccCategoryE.DeferredReason,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 24,
                                categoryName : "DeferredReason",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[60]
                        ),
                        CategoryId: AccCategoryE.PayorFileMaintenance,
                        parent: 1,
                        index: 25,
                        privMask: FileMaintenance1E.PayorFileMaintenance,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[60]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance1,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenance1E.PayorFileMaintenance,
                                parent1: 1,
                                parent2: 25,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: PayorFileMaintenanceE[1],
                                CategoryId:
                                    AccCategoryE.PayorFileMaintenance,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 25,
                                categoryName : "PayorFileMaintenance",
                            },
                            {
                                header: PayorFileMaintenanceE[2],
                                CategoryId:
                                    AccCategoryE.PayorFileMaintenance,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 25,
                                categoryName : "PayorFileMaintenance",
                            },
                            {
                                header: PayorFileMaintenanceE[4],
                                CategoryId:
                                    AccCategoryE.PayorFileMaintenance,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 25,
                                categoryName : "PayorFileMaintenance",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[38]
                        ),
                        CategoryId: AccCategoryE.VendorFile,
                        parent: 1,
                        index: 26,
                        privMask: FileMaintenance1E.VendorFile,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[38]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance1,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenance1E.VendorFile,
                                parent1: 1,
                                parent2: 26,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    VendorFileE[1]
                                ),
                                CategoryId:
                                    AccCategoryE.VendorFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 1,
                                parent2: 26,
                                categoryName : "VendorFile",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    VendorFileE[2]
                                ),
                                CategoryId:
                                    AccCategoryE.VendorFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 26,
                                categoryName : "VendorFile",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    VendorFileE[4]
                                ),
                                CategoryId:
                                    AccCategoryE.VendorFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 26,
                                categoryName : "VendorFile",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    VendorFileE[8]
                                ),
                                CategoryId:
                                    AccCategoryE.VendorFile,
                                parent: 2,
                                index: 4,
                                privMask: 8,
                                parent1: 1,
                                parent2: 26,
                                categoryName : "VendorFile",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    VendorFileE[16]
                                ),
                                CategoryId:
                                    AccCategoryE.VendorFile,
                                parent: 2,
                                index: 5,
                                privMask: 16,
                                parent1: 1,
                                parent2: 26,
                                categoryName : "VendorFile",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[77]
                        ),
                        CategoryId: AccCategoryE.DrugCategory,
                        parent: 1,
                        index: 27,
                        privMask: FileMaintenance1E.DrugCategory,
                        categoryName : "FileMaintenance",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[77]
                                ),
                                CategoryId:
                                    AccCategoryE.FileMaintenance1,
                                parent: 2,
                                index: 0,
                                privMask:
                                    FileMaintenance1E.DrugCategory,
                                parent1: 1,
                                parent2: 27,
                                key: 1,
                                categoryName : "FileMaintenance",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DrugCategoryE[1]
                                ),
                                CategoryId:
                                    AccCategoryE.DrugCategory,
                                parent: 2,
                                index: 1,
                                privMask:1,
                                parent1: 1,
                                parent2: 27,
                                categoryName : "DrugCategory",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DrugCategoryE[2]
                                ),
                                CategoryId:
                                    AccCategoryE.DrugCategory,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 1,
                                parent2: 27,
                                categoryName : "DrugCategory",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DrugCategoryE[4]
                                ),
                                CategoryId:
                                    AccCategoryE.DrugCategory,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 1,
                                parent2: 27,
                                categoryName : "DrugCategory",
                            }
                        ]
                    }

                ]
            },
            {
                header: DocumentE[8],
                CategoryId: AccCategoryE.Reports,
                parent: 0,
                index: 3,
                privMask: DocumentE.Reports,
                categoryName : "Documents",
                items: [
                    {
                        header: DocumentE[8],
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.Reports,
                        parent1: 2,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[1]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 1,
                        privMask: 1,
                        categoryName : "Reports",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    ReportsE[1]
                                ),
                                CategoryId: AccCategoryE.Reports,
                                parent: 2,
                                index: 0,
                                privMask: ReportsE.DailyLog,
                                parent1: 2,
                                parent2: 1,
                                key: 1,
                                categoryName : "Reports",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DailyLogE[1]
                                ),
                                CategoryId: AccCategoryE.DailyLog,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 2,
                                parent2: 1,
                                categoryName : "DailyLog",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DailyLogE[2]
                                ),
                                CategoryId: AccCategoryE.DailyLog,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 2,
                                parent2: 1,
                                categoryName : "DailyLog",
                            }
                            
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[2]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 2,
                        privMask: 2,
                        categoryName : "Reports",
                        // parent1: 2,
                        // parent2: 2,
                        // key: 1
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    ReportsE[2]
                                ),
                                CategoryId: AccCategoryE.Reports,
                                parent: 2,
                                index: 0,
                                privMask: ReportsE.RefillsDue,
                                parent1: 2,
                                parent2: 2,
                                key: 1,
                                categoryName : "Reports",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillDueE[1]
                                ),
                                CategoryId: AccCategoryE.RefillDueReport,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 2,
                                parent2: 2,
                                categoryName : "RefillsDue",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillDueE[2]
                                ),
                                CategoryId: AccCategoryE.RefillDueReport,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 2,
                                parent2: 2,
                                categoryName : "RefillsDue",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RefillDueE[4]
                                ),
                                CategoryId: AccCategoryE.RefillDueReport,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 2,
                                parent2: 2,
                                categoryName : "RefillsDue",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[4]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 3,
                        privMask: 4,
                        parent1: 2,
                        parent2: 3,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[8]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 4,
                        privMask: 8,
                        parent1: 2,
                        parent2: 4,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[16]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 5,
                        privMask: 16,
                        parent1: 2,
                        parent2: 5,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[32]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 6,
                        privMask: 32,
                        parent1: 2,
                        parent2: 6,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[64]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 7,
                        privMask: 64,
                        parent1: 2,
                        parent2: 7,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[128]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 8,
                        privMask: 128,
                        parent1: 2,
                        parent2: 8,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[256]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 9,
                        privMask: 256,
                        categoryName : "Reports",
                        // parent1: 2,
                        // parent2: 9,
                        // key: 1
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    ReportsE[256]
                                ),
                                CategoryId: AccCategoryE.Reports,
                                parent: 2,
                                index: 0,
                                privMask: ReportsE.BusinessReports,
                                parent1: 2,
                                parent2: 9,
                                key: 1,
                                categoryName : "Reports",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    BusinessReportE[1]
                                ),
                                CategoryId: AccCategoryE.BusinessReports,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 2,
                                parent2: 9,
                                categoryName : "BusinessReports",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    BusinessReportE[2]
                                ),
                                CategoryId: AccCategoryE.BusinessReports,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 2,
                                parent2: 9,
                                categoryName : "BusinessReports",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    BusinessReportE[4]
                                ),
                                CategoryId: AccCategoryE.BusinessReports,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 2,
                                parent2: 9,
                                categoryName : "BusinessReports",
                            },
                        ]
                    },
                    // {
                    //     header: new StringSepWithSpace().transform(
                    //         ReportsE[512]
                    //     ),
                    //     CategoryId: AccCategoryE.Reports,
                    //     parent: 1,
                    //     index: 10,
                    //     privMask: 512,
                    //     parent1: 2,
                    //     parent2: 10,
                    //     key: 1
                    // },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[512]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 10,
                        privMask: 512,
                        parent1: 2,
                        parent2: 10,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: "ASAP Control Data",
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 11,
                        privMask: 1,
                        categoryName : "Reports",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[43]
                                ),
                                CategoryId: AccCategoryE.Reports,
                                parent: 2,
                                index: 0,
                                privMask: ReportsE.ControlFile,
                                parent1: 2,
                                parent2: 11,
                                key: 1,
                                categoryName : "Reports",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    ControlFileE[1]
                                ),
                                CategoryId: AccCategoryE.ControlFile,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 2,
                                parent2: 11,
                                categoryName : "ASAPControlData",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    ControlFileE[2]
                                ),
                                CategoryId: AccCategoryE.ControlFile,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 2,
                                parent2: 11,
                                categoryName : "ASAPControlData",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    ControlFileE[4]
                                ),
                                CategoryId: AccCategoryE.ControlFile,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 2,
                                parent2: 11,
                                categoryName : "ASAPControlData",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[2048]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 12,
                        privMask: 1,
                        categoryName : "Reports",
                        // parent1: 2,
                        // parent2: 13,
                        // key: 1
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    ReportsE[2048]
                                ),
                                CategoryId: AccCategoryE.Reports,
                                parent: 2,
                                index: 0,
                                privMask: ReportsE.AuditTrails,
                                parent1: 2,
                                parent2: 12,
                                key: 1,
                                categoryName : "Reports",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AuditTrailsE[1]
                                ),
                                CategoryId: AccCategoryE.AuditTrails,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 2,
                                parent2: 12,
                                categoryName : "AuditTrails",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AuditTrailsE[2]
                                ),
                                CategoryId: AccCategoryE.AuditTrails,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 2,
                                parent2: 12,
                                categoryName : "AuditTrails",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AuditTrailsE[4]
                                ),
                                CategoryId: AccCategoryE.AuditTrails,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 2,
                                parent2: 12,
                                categoryName : "AuditTrails",
                            },
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[4096]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 13,
                        privMask: 4096,
                        parent1: 2,
                        parent2: 13,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[8192]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 14,
                        privMask: 8192,
                        parent1: 2,
                        parent2: 14,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ReportsE[16384]
                        ),
                        CategoryId: AccCategoryE.Reports,
                        parent: 1,
                        index: 15,
                        privMask: 16384,
                        parent1: 2,
                        parent2: 15,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Reports1E[1]
                        ),
                        CategoryId: AccCategoryE.Reports1,
                        parent: 1,
                        index: 16,
                        privMask: Reports1E.TransferredRxReport,
                        parent1: 2,
                        parent2: 16,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Reports1E[2]
                        ),
                        CategoryId: AccCategoryE.Reports1,
                        parent: 1,
                        index: 17,
                        privMask: Reports1E.RxByInsurance,
                        parent1: 2,
                        parent2: 17,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Reports1E[4]
                        ),
                        CategoryId: AccCategoryE.Reports1,
                        parent: 1,
                        index: 18,
                        privMask: Reports1E.SalesTaxSummary,
                        parent1: 2,
                        parent2: 18,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Reports1E[8]
                        ),
                        CategoryId: AccCategoryE.Reports1,
                        parent: 1,
                        index: 19,
                        privMask: 1,
                        categoryName : "Reports",
                        // parent1: 2,
                        // parent2: 19,
                        // key: 
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    Reports1E[8]
                                ),
                                CategoryId: AccCategoryE.Reports1,
                                parent: 2,
                                index: 0,
                                privMask: Reports1E.PriceReport,
                                parent1: 2,
                                parent2: 19,
                                key: 1,
                                categoryName : "Reports",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    PriceReportE[1]
                                ),
                                CategoryId: AccCategoryE.PriceReport,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 2,
                                parent2: 19,
                                categoryName : "PriceReport",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    PriceReportE[2]
                                ),
                                CategoryId: AccCategoryE.PriceReport,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 2,
                                parent2: 19,
                                categoryName : "PriceReport",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Reports1E[16]
                        ),
                        CategoryId: AccCategoryE.Reports1,
                        parent: 1,
                        index: 20,
                        privMask: Reports1E.PatientMedicationAdherence,
                        parent1: 2,
                        parent2: 20,
                        key: 1,
                        categoryName : "Reports",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            Reports1E[32]
                        ),
                        CategoryId: AccCategoryE.Reports1,
                        parent: 1,
                        index: 21,
                        privMask: Reports1E.PartialFill,
                        parent1: 2,
                        parent2: 21,
                        key: 1,
                        categoryName : "Reports",
                    },

                ]
            },
            {
                header: DocumentE[16],
                CategoryId: AccCategoryE.Inventory,
                parent: 0,
                index: 4,
                privMask: DocumentE.Inventory,
                categoryName : "Documents",
                items: [
                    {
                        header: DocumentE[16],
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.Inventory,
                        parent1: 3,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            InventoryE[1]
                        ),
                        CategoryId: AccCategoryE.Inventory,
                        parent: 1,
                        index: 1,
                        privMask: 1,
                        parent1: 3,
                        parent2: 1,
                        key: 1,
                        categoryName : "Inventory",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            InventoryE[2]
                        ),
                        CategoryId: AccCategoryE.Inventory,
                        parent: 1,
                        index: 2,
                        privMask: 2,
                        parent1: 3,
                        parent2: 2,
                        key: 1,
                        categoryName : "Inventory",
                    },
                    // {
                    //     header: new StringSepWithSpace().transform(
                    //         InventoryE[2]
                    //     ),
                    //     CategoryId: AccCategoryE.VendorFile,
                    //     parent: 1,
                    //     index: 2,
                    //     privMask: 2,
                    //     items: [
                    //         {
                    //             header: new StringSepWithSpace().transform(
                    //                 InventoryE[2]
                    //             ),
                    //             CategoryId: AccCategoryE.Inventory,
                    //             parent: 2,
                    //             index: 0,
                    //             privMask: InventoryE.VendorFile,
                    //             parent1: 3,
                    //             parent2: 2,
                    //             key: 1
                    //         },
                    //         {
                    //             header: VendorFileE[1],
                    //             CategoryId: AccCategoryE.VendorFile,
                    //             parent: 2,
                    //             index: 1,
                    //             privMask: 1,
                    //             parent1: 3,
                    //             parent2: 2
                    //         },
                    //         {
                    //             header: VendorFileE[2],
                    //             CategoryId: AccCategoryE.VendorFile,
                    //             parent: 2,
                    //             index: 2,
                    //             privMask: 2,
                    //             parent1: 3,
                    //             parent2: 2
                    //         },
                    //         {
                    //             header: VendorFileE[4],
                    //             CategoryId: AccCategoryE.VendorFile,
                    //             parent: 2,
                    //             index: 3,
                    //             privMask: 4,
                    //             parent1: 3,
                    //             parent2: 2
                    //         },
                    //         {
                    //             header: new StringSepWithSpace().transform(
                    //                 VendorFileE[8]
                    //             ),
                    //             CategoryId: AccCategoryE.VendorFile,
                    //             parent: 2,
                    //             index: 4,
                    //             privMask: VendorFileE.AllowChangingInventoryBucket,
                    //             parent1: 3,
                    //             parent2: 2
                    //         }
                    //     ]
                    // },
                    
                    {
                        header: new StringSepWithSpace().transform(
                            InventoryE[4]
                        ),
                        CategoryId: AccCategoryE.Inventory,
                        parent: 1,
                        index: 3,
                        privMask: InventoryE.InventoryTransactionType,
                        categoryName : "Inventory",
                        // parent1: 3,
                        // parent2: 3,
                        // key: 1
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    InventoryE[4]
                                ),
                                CategoryId: AccCategoryE.Inventory,
                                parent: 2,
                                index: 0,
                                privMask: InventoryE.InventoryTransactionType,
                                parent1: 3,
                                parent2: 3,
                                key : 1,
                                categoryName : "Inventory",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    InventoryTransactionTypeE[1]
                                ),
                                CategoryId: AccCategoryE.InventoryTransactionType,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 3,
                                parent2: 3,
                                categoryName : "InventoryTransactionType",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    InventoryTransactionTypeE[2]
                                ),
                                CategoryId: AccCategoryE.InventoryTransactionType,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 3,
                                parent2: 3,
                                categoryName : "InventoryTransactionType",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    InventoryTransactionTypeE[4]
                                ),
                                CategoryId: AccCategoryE.InventoryTransactionType,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 3,
                                parent2: 3,
                                categoryName : "InventoryTransactionType",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            InventoryE[8]
                        ),
                        CategoryId: AccCategoryE.PhysicalInventory,
                        parent: 1,
                        index: 4,
                        privMask: InventoryE.PhysicalInventory,
                        categoryName : "Inventory",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    InventoryE[8]
                                ),
                                CategoryId: AccCategoryE.Inventory,
                                parent: 2,
                                index: 0,
                                privMask: InventoryE.PhysicalInventory,
                                parent1: 3,
                                parent2: 4,
                                key : 1,
                                categoryName : "Inventory",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    PhysicalInventoryE[1]
                                ),
                                CategoryId: AccCategoryE.PhysicalInventory,
                                parent: 2,
                                index: 1,
                                privMask: PhysicalInventoryE.AllowProcessPhyiscalInventoryBatch,
                                parent1: 3,
                                parent2: 4,
                                categoryName : "PhysicalInventory",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            InventoryE[16]
                        ),
                        CategoryId: AccCategoryE.Bucket,
                        parent: 1,
                        index: 5,
                        privMask: InventoryE.Bucket,
                        categoryName : "Inventory",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    InventoryE[16]
                                ),
                                CategoryId: AccCategoryE.Inventory,
                                parent: 2,
                                index: 0,
                                privMask: InventoryE.Bucket,
                                parent1: 3,
                                parent2: 5,
                                key : 1,
                                categoryName : "Inventory",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    BucketE[1]
                                ),
                                CategoryId: AccCategoryE.Bucket,
                                parent: 2,
                                index: 1,
                                privMask: BucketE.Add,
                                parent1: 3,
                                parent2: 5,
                                categoryName : "Bucket",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    BucketE[2]
                                ),
                                CategoryId: AccCategoryE.Bucket,
                                parent: 2,
                                index: 2,
                                privMask: BucketE.Change,
                                parent1: 3,
                                parent2: 5,
                                categoryName : "Bucket",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    BucketE[4]
                                ),
                                CategoryId: AccCategoryE.Bucket,
                                parent: 2,
                                index: 3,
                                privMask: BucketE.Delete,
                                parent1: 3,
                                parent2: 5,
                                categoryName : "Bucket",
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            InventoryE[32]
                        ),
                        CategoryId: AccCategoryE.Inventory,
                        parent: 1,
                        index: 6,
                        privMask: InventoryE.AllowChangingInventoryBucket,
                        parent1: 3,
                        parent2: 6,
                        key: 1,
                        categoryName : "Inventory",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            InventoryE[64]
                        ),
                        CategoryId: AccCategoryE.Inventory,
                        parent: 1,
                        index: 7,
                        privMask: InventoryE.AllowTransferQtyBetweenInventoryBuckets,
                        parent1: 3,
                        parent2: 7,
                        key: 1,
                        categoryName : "Inventory",
                    },
                    // {
                    //     header: new StringSepWithSpace().transform(
                    //         InventoryE[128]
                    //     ),
                    //     CategoryId: AccCategoryE.Inventory,
                    //     parent: 1,
                    //     index: 8,
                    //     privMask: InventoryE.InventoryTransactionType,
                    //     items: [
                    //         {
                    //             header: new StringSepWithSpace().transform(
                    //                 InventoryE[128]
                    //             ),
                    //             CategoryId: AccCategoryE.Inventory,
                    //             parent: 2,
                    //             index: 0,
                    //             privMask: InventoryE.InventoryTransactionType,
                    //             parent1: 3,
                    //             parent2: 8,
                    //             key : 1
                    //         },
                    //         {
                    //             header: new StringSepWithSpace().transform(
                    //                 InventoryTransactionTypeE[1]
                    //             ),
                    //             CategoryId: AccCategoryE.InventoryTransactionType,
                    //             parent: 2,
                    //             index: 1,
                    //             privMask: 1,
                    //             parent1: 3,
                    //             parent2: 8
                    //         },
                    //         {
                    //             header: new StringSepWithSpace().transform(
                    //                 InventoryTransactionTypeE[2]
                    //             ),
                    //             CategoryId: AccCategoryE.InventoryTransactionType,
                    //             parent: 2,
                    //             index: 2,
                    //             privMask: 2,
                    //             parent1: 3,
                    //             parent2: 8
                    //         },
                    //         {
                    //             header: new StringSepWithSpace().transform(
                    //                 InventoryTransactionTypeE[4]
                    //             ),
                    //             CategoryId: AccCategoryE.InventoryTransactionType,
                    //             parent: 2,
                    //             index: 3,
                    //             privMask: 4,
                    //             parent1: 3,
                    //             parent2: 8
                    //         }
                    //     ]
                    // },
                    {
                        header: "EDI 832 Price Update History",
                        CategoryId: AccCategoryE.Inventory,
                        parent: 1,
                        index: 8,
                        privMask: InventoryE.EDI832PriceUpdateHistory,
                        parent1: 3,
                        parent2: 8,
                        key: 1,
                        categoryName : "Inventory",
                    },
                    
                ]
            },
            // {
            //     header: new StringSepWithSpace().transform(
            //         AccCategoryE[8]
            //     ),
            //     CategoryId: AccCategoryE.ClaimProcessing,
            //     parent: 0,
            //     index: 5,
            //     privMask: DocumentE.ClaimProcessing,
            //     items: [
            //         {
            //             header: new StringSepWithSpace().transform(
            //                 AccCategoryE[8]
            //             ),
            //             CategoryId: AccCategoryE.Document,
            //             parent: 1,
            //             index: 0,
            //             privMask: DocumentE.ClaimProcessing,
            //             parent1: 4,
            //             parent2: 0,
            //             key: 2
            //         },
            //         {
            //             header: new StringSepWithSpace().transform(
            //                 ClaimProcessingE[1]
            //             ),
            //             CategoryId: AccCategoryE.ClaimProcessing,
            //             parent: 1,
            //             index: 1,
            //             privMask: 1,
            //             parent1: 4,
            //             parent2: 0,
            //             key: 1
            //         }
            //     ]
            // },
            {
                header: AccCategoryE[9],
                CategoryId: AccCategoryE.eRx,
                parent: 0,
                index: 5,
                privMask: DocumentE.eRx,
                categoryName : "Documents",
                items: [
                    {
                        header: AccCategoryE[9],
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.eRx,
                        parent1: 4,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents",
                    },
                    {
                        header: "eRx Action List",
                        CategoryId: AccCategoryE.ERxActionList,
                        parent: 1,
                        index: 1,
                        privMask: eRxE.eRxActionList,
                        categoryName : "eRx",
                        // parent1: 5,
                        // parent2: 1,
                        // key: 1
                        items: [
                            {
                                header: "eRx Action List",
                                CategoryId: AccCategoryE.eRx,
                                parent: 2,
                                index: 0,
                                privMask: eRxE.eRxActionList,
                                parent1: 4,
                                parent2: 1,
                                key: 1,
                                categoryName : "eRx",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    eRxActionListE[1]
                                ),
                                CategoryId: AccCategoryE.ERxActionList,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 4,
                                parent2: 1,
                                categoryName : "eRxActionList",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    eRxActionListE[2]
                                ),
                                CategoryId: AccCategoryE.ERxActionList,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 4,
                                parent2: 1,
                                categoryName : "eRxActionList",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    eRxActionListE[4]
                                ),
                                CategoryId: AccCategoryE.ERxActionList,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 4,
                                parent2: 1,
                                categoryName : "eRxActionList",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    eRxActionListE[8]
                                ),
                                CategoryId: AccCategoryE.ERxActionList,
                                parent: 2,
                                index: 4,
                                privMask: 8,
                                parent1: 4,
                                parent2: 1,
                                categoryName : "eRxActionList",
                            },
                            {
                                header: "eRx Rph Notes",
                                CategoryId: AccCategoryE.ERxActionList,
                                parent: 2,
                                index: 5,
                                privMask: 16,
                                categoryName : "eRxActionList",
                                // parent1: 3,
                                // parent2: 6
                                
                                        items: [
                                            {
                                                header: "eRx Rph Notes",
                                                CategoryId: AccCategoryE.ERxActionList,
                                                parent: 3,
                                                index: 0,
                                                privMask: eRxActionListE.eRxRphNotes,
                                                parent1: 4,
                                                parent2: 1,
                                                parent3: 5,
                                                key: 1,
                                                categoryName : "eRxActionList",
                                            },
                                            {
                                                header: new StringSepWithSpace().transform(
                                                   eRxRphNotesE[1]
                                                ),
                                                CategoryId: AccCategoryE.ERxRphNotes,
                                                parent: 3,
                                                index: 1,
                                                privMask: eRxRphNotesE.Add,
                                                parent1: 4,
                                                parent2: 1,
                                                parent3: 5,
                                                categoryName : "eRxRphNotes",
                                            },
                                            {
                                                header: new StringSepWithSpace().transform(
                                                   eRxRphNotesE[2]
                                                ),
                                                CategoryId: AccCategoryE.ERxRphNotes,
                                                parent: 3,
                                                index: 2,
                                                privMask: eRxRphNotesE.Edit,
                                                parent1: 4,
                                                parent2: 1,
                                                parent3: 5,
                                                categoryName : "eRxRphNotes",
                                            },
                                            {
                                                header: new StringSepWithSpace().transform(
                                                   eRxRphNotesE[4]
                                                ),
                                                CategoryId: AccCategoryE.ERxRphNotes,
                                                parent: 3,
                                                index: 3,
                                                privMask: eRxRphNotesE.Delete,
                                                parent1: 4,
                                                parent2: 1,
                                                parent3: 5,
                                                categoryName : "eRxRphNotes",
                                            },
                                        ]
                                    
                            },
                        ]
                    },
                  
                    {
                        header: "eRx Exception Report", // new StringSepWithSpace().transform(eRxE[2]),
                        CategoryId: AccCategoryE.eRx,
                        parent: 1,
                        index: 2,
                        privMask: 2,
                        parent1: 4,
                        parent2: 2,
                        key: 1,
                        categoryName : "eRx",
                    }
                ]
            },
            {
                header: AccCategoryE[10],
                CategoryId: AccCategoryE.Tools,
                parent: 0,
                index: 6,
                privMask: DocumentE.Tools,
                categoryName : "Documents",
                items: [
                    {
                        header: AccCategoryE[10],
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.Tools,
                        parent1: 5,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ToolsE[1]
                        ),
                        CategoryId: AccCategoryE.Tools,
                        parent: 1,
                        index: 1,
                        privMask: 1,
                        parent1: 5,
                        parent2: 1,
                        key: 1,
                        categoryName : "Tools",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ToolsE[2]
                        ),
                        CategoryId: AccCategoryE.Tools,
                        parent: 1,
                        index: 2,
                        privMask: 2,
                        parent1: 5,
                        parent2: 2,
                        key: 1,
                        categoryName : "Tools",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ToolsE[4]
                        ),
                        CategoryId: AccCategoryE.Tools,
                        parent: 1,
                        index: 3,
                        privMask: 4,
                        categoryName : "Tools",
                        // parent1: 6,
                        // parent2: 3,
                        // key: 1
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    ToolsE[4]
                                ),
                                CategoryId: AccCategoryE.Tools,
                                parent: 2,
                                index: 0,
                                privMask: ToolsE.RecallDeletedRx,
                                parent1: 5,
                                parent2: 3,
                                key: 1,
                                categoryName : "Tools",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    RecallDeletedRxE[1]
                                ),
                                CategoryId: AccCategoryE.RecallDeletedRx,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 5,
                                parent2: 3,
                                categoryName : "RecallDeletedRx",
                            },
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ToolsE[8]
                        ),
                        CategoryId: AccCategoryE.Tools,
                        parent: 1,
                        index: 4,
                        privMask: 8,
                        parent1: 5,
                        parent2: 4,
                        key: 1,
                        categoryName : "Tools",
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            ToolsE[16]
                        ),
                        CategoryId: AccCategoryE.Tools,
                        parent: 1,
                        index: 5,
                        privMask: 16,
                        parent1: 5,
                        parent2: 5,
                        key: 1,
                        categoryName : "Tools",
                    },
                    // {
                    //     header: new StringSepWithSpace().transform(
                    //         ToolsE[32]
                    //     ),
                    //     CategoryId: AccCategoryE.Tools,
                    //     parent: 1,
                    //     index: 6,
                    //     privMask: 32,
                    //     parent1: 6,
                    //     parent2: 6,
                    //     key: 1
                    // },
                    {
                        header: new StringSepWithSpace().transform(
                            ToolsE[32]
                        ),
                        CategoryId: AccCategoryE.Tools,
                        parent: 1,
                        index: 6,
                        privMask: 32,
                        categoryName : "Tools",
                        // parent1: 6,
                        // parent2: 3,
                        // key: 1
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    ToolsE[32]
                                ),
                                CategoryId: AccCategoryE.Tools,
                                parent: 2,
                                index: 0,
                                privMask: ToolsE.ScheduledTasks,
                                parent1: 5,
                                parent2: 6,
                                key: 1,
                                categoryName : "Tools",
                            },
                            {
                                header: "Add Task",
                                CategoryId: AccCategoryE.ScheduledTasks,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 5,
                                parent2: 6,
                                categoryName : "ScheduledTasks",
                            },
                            {
                                header: "Run Task",
                                CategoryId: AccCategoryE.ScheduledTasks,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 5,
                                parent2: 6,
                                categoryName : "ScheduledTasks",
                            },
                            {
                                header: "Edit Task",
                                CategoryId: AccCategoryE.ScheduledTasks,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 5,
                                parent2: 6,
                                categoryName : "ScheduledTasks",
                            },
                            {
                                header: "Delete Task",
                                CategoryId: AccCategoryE.ScheduledTasks,
                                parent: 2,
                                index: 4,
                                privMask: 8,
                                parent1: 5,
                                parent2: 6,
                                categoryName : "ScheduledTasks",
                            },
                        ]
                    },
                ]
            },
            {
                header: DocumentE[256],
                CategoryId: AccCategoryE.Label,
                parent: 0,
                index: 7,
                privMask: DocumentE.Label,
                categoryName : "Documents",
                items: [
                    {
                        header: DocumentE[256],
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.Label,
                        parent1: 6,
                        key: 2,
                        categoryName : "Documents",
                    },
                    // {
                    //     header: new StringSepWithSpace().transform(
                    //         LabelE[1]
                    //     ),
                    //     CategoryId: AccCategoryE.LabelPrintSet,
                    //     parent: 1,
                    //     index: 1,
                    //     privMask: 1,
                    //     items: [
                    //         {
                    //             header: new StringSepWithSpace().transform(
                    //                 LabelE[1]
                    //             ),
                    //             CategoryId: AccCategoryE.Label,
                    //             parent: 2,
                    //             index: 0,
                    //             privMask: 1,
                    //             parent1: 7,
                    //             parent2: 1,
                    //             key: 1
                    //         },
                    //         {
                    //             header: LabelPrintSetE[1],
                    //             CategoryId: AccCategoryE.LabelPrintSet,
                    //             parent: 2,
                    //             index: 1,
                    //             privMask: LabelPrintSetE.Add,
                    //             parent1: 7,
                    //             parent2: 1
                    //         },
                    //         {
                    //             header: LabelPrintSetE[2],
                    //             CategoryId: AccCategoryE.LabelPrintSet,
                    //             parent: 2,
                    //             index: 2,
                    //             privMask: LabelPrintSetE.Change,
                    //             parent1: 7,
                    //             parent2: 1
                    //         },
                    //         {
                    //             header: LabelPrintSetE[4],
                    //             CategoryId: AccCategoryE.LabelPrintSet,
                    //             parent: 2,
                    //             index: 3,
                    //             privMask: LabelPrintSetE.Delete,
                    //             parent1: 7,
                    //             parent2: 1
                    //         },
                    //     ]
                    // },
                    {
                        header: new StringSepWithSpace().transform(
                            LabelE[1]
                        ),
                        CategoryId: AccCategoryE.LabelDesigner,
                        parent: 1,
                        index: 1,
                        privMask: LabelE.LabelDesigner,
                        categoryName : "Label",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    LabelE[1]
                                ),
                                CategoryId: AccCategoryE.Label,
                                parent: 2,
                                index: 0,
                                privMask: LabelE.LabelDesigner,
                                parent1: 6,
                                parent2: 1,
                                key: 1,
                                categoryName : "Label",
                            },
                            {
                                header: LabelDesignerE[1],
                                CategoryId: AccCategoryE.LabelDesigner,
                                parent: 2,
                                index: 1,
                                privMask: LabelDesignerE.Add,
                                parent1: 6,
                                parent2: 1,
                                categoryName : "LabelDesigner",
                            },
                            {
                                header: LabelDesignerE[2],
                                CategoryId: AccCategoryE.LabelDesigner,
                                parent: 2,
                                index: 2,
                                privMask: LabelDesignerE.Edit,
                                parent1: 6,
                                parent2: 1,
                                categoryName : "LabelDesigner",
                            },
                            {
                                header: LabelDesignerE[4],
                                CategoryId: AccCategoryE.LabelDesigner,
                                parent: 2,
                                index: 3,
                                privMask: LabelDesignerE.Delete,
                                parent1: 6,
                                parent2: 1,
                                categoryName : "LabelDesigner",
                            },
                            {
                                header: LabelDesignerE[8],
                                CategoryId: AccCategoryE.LabelDesigner,
                                parent: 2,
                                index: 4,
                                privMask: LabelDesignerE.Preview,
                                parent1: 6,
                                parent2: 1,
                                categoryName : "LabelDesigner",
                            },
                        ]
                    }
                ]
            },
            // {
            //     header: "340B Program",
            //     CategoryId: AccCategoryE.Document,
            //     parent: 0,
            //     index: 9,
            //     privMask: DocumentE.E340BProgram,
            //     // items: [
            //     //     {
            //     //         header: "340BProgram",
            //     //         CategoryId: AccCategoryE.Document,
            //     //         parent: 1,
            //     //         index: 0,
            //     //         privMask: DocumentE.E340BProgram,
            //     //         parent1: 8,
            //     //         parent2: 0,
            //     //         key: 2
            //     //     }
            //     // ]
            // },
            {
                header: AccCategoryE[45],
                CategoryId: AccCategoryE.Settings,
                parent: 0,
                index: 8,
                privMask: DocumentE.Settings,
                categoryName : "Documents",
                items: [
                    {
                        header: AccCategoryE[45],
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.Settings,
                        parent1: 7,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents"
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            AccCategoryE[39]
                        ),
                        CategoryId: AccCategoryE.SecurityManager,
                        parent: 1,
                        index: 1,
                        privMask: SettingsE.SecurityManager,
                        categoryName : "Settings",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    AccCategoryE[39]
                                ),
                                CategoryId: AccCategoryE.Settings,
                                parent: 2,
                                index: 0,
                                privMask: SettingsE.SecurityManager,
                                parent1: 7,
                                parent2: 1,
                                key: 1,
                                categoryName : "Settings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    SecurityManagerE[1]
                                ),
                                CategoryId:
                                    AccCategoryE.SecurityManager,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                categoryName : "SecurityManager",
                                items: [
                                    {
                                        header: SecurityManagerE[1],
                                        CategoryId:
                                            AccCategoryE.SecurityManager,
                                        parent: 3,
                                        index: 0,
                                        privMask:
                                            SecurityManagerE.Users,
                                        parent1: 7,
                                        parent2: 1,
                                        parent3: 1,
                                        key: 1,
                                        categoryName : "SecurityManager"
                                    },
                                    {
                                        header: UsersE[1],
                                        CategoryId: AccCategoryE.Users,
                                        parent: 3,
                                        index: 1,
                                        privMask: UsersE.Add,
                                        parent1: 7,
                                        parent2: 1,
                                        parent3: 1,
                                        categoryName : "Users"
                                    },
                                    {
                                        header: UsersE[2],
                                        CategoryId: AccCategoryE.Users,
                                        parent: 3,
                                        index: 2,
                                        privMask: UsersE.Edit,
                                        parent1: 7,
                                        parent2: 1,
                                        parent3: 1,
                                        categoryName : "Users"
                                    },
                                    {
                                        header: UsersE[4],
                                        CategoryId: AccCategoryE.Users,
                                        parent: 3,
                                        index: 3,
                                        privMask: UsersE.Inactive,
                                        parent1: 7,
                                        parent2: 1,
                                        parent3: 1,
                                        categoryName : "Users"
                                    }
                                ]
                            },
                            {
                                header: SecurityManagerE[2],
                                CategoryId:
                                    AccCategoryE.SecurityManager,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                categoryName : "SecurityManager",
                                items: [
                                    {
                                        header: SecurityManagerE[2],
                                        CategoryId:
                                            AccCategoryE.SecurityManager,
                                        parent: 3,
                                        index: 0,
                                        privMask:
                                            SecurityManagerE.Roles,
                                        parent1: 7,
                                        parent2: 1,
                                        parent3: 2,
                                        key: 1,
                                        categoryName : "SecurityManager"
                                    },
                                    {
                                        header: RolesE[1],
                                        CategoryId: AccCategoryE.Roles,
                                        parent: 3,
                                        index: 1,
                                        privMask: RolesE.Add,
                                        parent1: 7,
                                        parent2: 1,
                                        parent3: 2,
                                        categoryName : "Roles"
                                    },
                                    {
                                        header: RolesE[2],
                                        CategoryId: AccCategoryE.Roles,
                                        parent: 3,
                                        index: 2,
                                        privMask: RolesE.Edit,
                                        parent1: 7,
                                        parent2: 1,
                                        parent3: 2,
                                        categoryName : "Roles"
                                    },
                                    {
                                        header: RolesE[4],
                                        CategoryId: AccCategoryE.Roles,
                                        parent: 3,
                                        index: 3,
                                        privMask: RolesE.Delete,
                                        parent1: 7,
                                        parent2: 1,
                                        parent3: 2,
                                        categoryName : "Roles"
                                    }
                                ]
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    SecurityManagerE[4]
                                ),
                                CategoryId:
                                    AccCategoryE.SecurityManager,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 7,
                                parent2: 1,
                                categoryName : "SecurityManager"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    SecurityManagerE[8]
                                ),
                                CategoryId:
                                    AccCategoryE.SecurityManager,
                                parent: 2,
                                index: 4,
                                privMask: 8,
                                parent1: 7,
                                parent2: 1,
                                categoryName : "SecurityManager"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    SecurityManagerE[16]
                                ),
                                CategoryId:
                                    AccCategoryE.SecurityManager,
                                parent: 2,
                                index: 5,
                                privMask: 16,
                                parent1: 7,
                                parent2: 1,
                                categoryName : "SecurityManager"
                            }
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            SettingsE[2]
                        ),
                        CategoryId: AccCategoryE.AccountSettings,
                        parent: 1,
                        index: 2,
                        privMask: SettingsE.AccountSettings,
                        categoryName : "Settings",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    SettingsE[2]
                                ),
                                CategoryId: AccCategoryE.Settings,
                                parent: 2,
                                index: 0,
                                privMask: SettingsE.AccountSettings,
                                parent1: 7,
                                parent2: 2,
                                key: 1,
                                categoryName : "Settings"
                            },
                            {
                                header: "Pharmacy Info",
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 1,
                                privMask: AccountSettingsE.PharmacyInfo,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: "Settings",
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 2,
                                privMask: AccountSettingsE.Settings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[4]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 3,
                                privMask: AccountSettingsE.RxSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            // {
                            //     header: new StringSepWithSpace().transform(
                            //         AccountSettingsE[8]
                            //     ),
                            //     CategoryId: AccCategoryE.AccountSettings,
                            //     parent: 2,
                            //     index: 4,
                            //     privMask: AccountSettingsE.PatientSettings,
                            //     parent1: 9,
                            //     parent2: 2
                            // },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[8]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 4,
                                privMask: AccountSettingsE.DrugSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[16]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 5,
                                privMask: AccountSettingsE.LabelSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[32]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 6,
                                privMask: AccountSettingsE.MessagingSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[64]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 7,
                                privMask: AccountSettingsE.ControlFileSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: "eRx Settings",
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 8,
                                privMask: AccountSettingsE.ErxSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[256]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 9,
                                privMask: AccountSettingsE.DMSSetings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[512]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 10,
                                privMask: AccountSettingsE.WorkFlowSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[1024]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 11,
                                privMask: AccountSettingsE.ReportSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[2048]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 12,
                                privMask: AccountSettingsE.PriceUpdateSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[4096]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 13,
                                privMask: AccountSettingsE.TransmissionSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: "340b Setting",
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 14,
                                privMask: AccountSettingsE.ThreeFourtyBSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettingsE[16384]
                                ),
                                CategoryId: AccCategoryE.AccountSettings,
                                parent: 2,
                                index: 15,
                                privMask: AccountSettingsE.InventorySettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    AccountSettings1E[1]
                                ),
                                CategoryId: AccCategoryE.AccountSettings1,
                                parent: 2,
                                index: 16,
                                privMask: AccountSettings1E.ClinicalSettings,
                                parent1: 7,
                                parent2: 2,
                                categoryName : "AccountSettings"
                            },
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(
                            SettingsE[4]
                        ),
                        CategoryId: AccCategoryE.Settings,
                        parent: 1,
                        index: 3,
                        privMask: SettingsE.ManageOverrideActions,
                        parent1: 7,
                        parent2: 3,
                        categoryName : "Settings"
                    },

                ]
            },
            {
                header: AccCategoryE[59],
                CategoryId: AccCategoryE.Fax,
                parent: 0,
                index: 9,
                privMask: DocumentE.Fax,
                categoryName : "Documents",
                items: [
                    {
                        header: AccCategoryE[59],
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.Fax,
                        parent1: 8,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents",
                    },
                    {
                        header: new StringSepWithSpace().transform(FAXE[1]),
                        CategoryId: AccCategoryE.Fax,
                        parent: 1,
                        index: 1,
                        privMask: 1,
                        parent1: 8,
                        parent2: 1,
                        key: 1,
                        categoryName : "Fax"
                    },
                    {
                        header: new StringSepWithSpace().transform(FAXE[2]),
                        CategoryId: AccCategoryE.Fax,
                        parent: 1,
                        index: 2,
                        privMask: 2,
                        parent1: 8,
                        parent2: 2,
                        key: 1,
                        categoryName : "Fax"
                    },
                    {
                        header: new StringSepWithSpace().transform(FAXE[4]),
                        CategoryId: AccCategoryE.Fax,
                        parent: 1,
                        index: 3,
                        privMask: 4,
                        parent1: 8,
                        parent2: 3,
                        key: 1,
                        categoryName : "Fax"
                    },
                    {
                        header: new StringSepWithSpace().transform(FAXE[8]),
                        CategoryId: AccCategoryE.Fax,
                        parent: 1,
                        index: 4,
                        privMask: 8,
                        parent1: 8,
                        parent2: 4,
                        key: 1,
                        categoryName : "Fax"
                    }
                ]
            },
            {
                header: new StringSepWithSpace().transform(AccCategoryE[57]),
                CategoryId: AccCategoryE.ElectronicSignature,
                parent: 0,
                index: 10,
                privMask: DocumentE.ElectronicSignature,
                categoryName : "Documents",
                items: [
                    {
                        header: new StringSepWithSpace().transform(AccCategoryE[57]),
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.ElectronicSignature,
                        parent1: 9,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents",
                    },
                    {
                        header: new StringSepWithSpace().transform(ElectronicSignatureE[1]),
                        CategoryId: AccCategoryE.ElectronicSignature,
                        parent: 1,
                        index: 1,
                        privMask: 1,
                        parent1: 9,
                        parent2: 1,
                        key: 1,
                        categoryName : "ElectronicSignature",
                    },
                    {
                        header: new StringSepWithSpace().transform(ElectronicSignatureE[2]),
                        CategoryId: AccCategoryE.ElectronicSignature,
                        parent: 1,
                        index: 2,
                        privMask: 2,
                        parent1: 9,
                        parent2: 2,
                        key: 1,
                        categoryName : "ElectronicSignature",
                    },
                    {
                        header: new StringSepWithSpace().transform(ElectronicSignatureE[4]),
                        CategoryId: AccCategoryE.ElectronicSignature,
                        parent: 1,
                        index: 3,
                        privMask: 4,
                        parent1: 9,
                        parent2: 3,
                        key: 1,
                        categoryName : "ElectronicSignature",
                    },
                    {
                        header: new StringSepWithSpace().transform(ElectronicSignatureE[8]),
                        CategoryId: AccCategoryE.ElectronicSignature,
                        parent: 1,
                        index: 4,
                        privMask: 8,
                        parent1: 9,
                        parent2: 4,
                        key: 1,
                        categoryName : "ElectronicSignature",
                    },
                    {
                        header: new StringSepWithSpace().transform(ElectronicSignatureE[16]),
                        CategoryId: AccCategoryE.ElectronicSignature,
                        parent: 1,
                        index: 5,
                        privMask: 16,
                        parent1: 9,
                        parent2: 5,
                        key: 1,
                        categoryName : "ElectronicSignature",
                    },
                    {
                        header: new StringSepWithSpace().transform(ElectronicSignatureE[32]),
                        CategoryId: AccCategoryE.ElectronicSignature,
                        parent: 1,
                        index: 6,
                        privMask: 32,
                        parent1: 9,
                        parent2: 6,
                        key: 1,
                        categoryName : "ElectronicSignature",
                    },
                    {
                        header: new StringSepWithSpace().transform(ElectronicSignatureE[64]),
                        CategoryId: AccCategoryE.ElectronicSignature,
                        parent: 1,
                        index: 7,
                        privMask: 64,
                        parent1: 9,
                        parent2: 7,
                        key: 1,
                        categoryName : "ElectronicSignature",
                    },
                   {
                        header: new StringSepWithSpace().transform(ElectronicSignatureE[128]),
                        CategoryId: AccCategoryE.ElectronicSignature,
                        parent: 1,
                        index: 8,
                        privMask: 128,
                        parent1: 9,
                        parent2: 8,
                        key: 1,
                        categoryName : "ElectronicSignature",
                    }
                ]
            },
            {
                header: AccCategoryE[58],
                CategoryId: AccCategoryE.DMS,
                parent: 0,
                index: 11,
                privMask: DocumentE.DMS,
                categoryName : "Documents",
                items: [
                    {
                        header: AccCategoryE[58],
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.DMS,
                        parent1: 10,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents"
                    },
                    {
                        header: new StringSepWithSpace().transform(DMSE[1]),
                        CategoryId: AccCategoryE.DMS,
                        parent: 1,
                        index: 1,
                        privMask: 1,
                        categoryName : "DMS",
                        // parent1: 12,
                        // parent2: 1,
                        // key: 1
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    DMSE[1]
                                ),
                                CategoryId: AccCategoryE.DMS,
                                parent: 2,
                                index: 0,
                                privMask: DMSE.DMSSubcategory,
                                parent1: 10,
                                parent2: 1,
                                key: 1,
                                categoryName : "DMS",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DMSSubcategoryE[1]
                                ),
                                CategoryId: AccCategoryE.DMSSubcategory,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 10,
                                parent2: 1,
                                categoryName : "DMSSubcategory",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DMSSubcategoryE[2]
                                ),
                                CategoryId: AccCategoryE.DMSSubcategory,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 10,
                                parent2: 1,
                                categoryName : "DMSSubcategory",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DMSSubcategoryE[4]
                                ),
                                CategoryId: AccCategoryE.DMSSubcategory,
                                parent: 2,
                                index: 3,
                                privMask: 4,
                                parent1: 10,
                                parent2: 1,
                                categoryName : "DMSSubcategory",
                            },
                        ]
                        
                    },
                    {
                        header: new StringSepWithSpace().transform(DMSE[2]),
                        CategoryId: AccCategoryE.DMS,
                        parent: 1,
                        index: 2,
                        privMask: 2,
                        parent1: 10,
                        parent2: 2,
                        key: 1,
                        categoryName : "DMS",
                    },
                    {
                        header: new StringSepWithSpace().transform(DMSE[4]),
                        CategoryId: AccCategoryE.DMS,
                        parent: 1,
                        index: 3,
                        privMask: 4,
                        // parent1: 12,
                        // parent2: 3,
                        // key: 1
                        categoryName : "DMS",
                        items: [
                            {
                                header: new StringSepWithSpace().transform(
                                    DMSE[4]
                                ),
                                CategoryId: AccCategoryE.DMS,
                                parent: 2,
                                index: 0,
                                privMask: DMSE.DocumentQueue,
                                parent1: 10,
                                parent2: 3,
                                key: 1,
                                categoryName : "DMS",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DocumentQueueE[1]
                                ),
                                CategoryId: AccCategoryE.DocumentQueue,
                                parent: 2,
                                index: 1,
                                privMask: 1,
                                parent1: 10,
                                parent2: 3,
                                categoryName : "DocumentQueue",
                            },
                            {
                                header: new StringSepWithSpace().transform(
                                    DocumentQueueE[2]
                                ),
                                CategoryId: AccCategoryE.DocumentQueue,
                                parent: 2,
                                index: 2,
                                privMask: 2,
                                parent1: 10,
                                parent2: 3,
                                categoryName : "DocumentQueue",
                            },
                        ]
                    },
                    {
                        header: new StringSepWithSpace().transform(DMSE[8]),
                        CategoryId: AccCategoryE.DMS,
                        parent: 1,
                        index: 4,
                        privMask: 8,
                        parent1: 10,
                        parent2: 4,
                        key: 1,
                        categoryName : "DMS",
                    },
                    {
                        header: new StringSepWithSpace().transform(DMSE[16]),
                        CategoryId: AccCategoryE.DMS,
                        parent: 1,
                        index: 5,
                        privMask: 16,
                        parent1: 10,
                        parent2: 5,
                        categoryName : "DMS",
                    }
                ]
            },
            {
                header: AccCategoryE[75],
                CategoryId: AccCategoryE.SMS,
                parent: 0,
                index: 12,
                privMask: DocumentE.SMS,
                categoryName : "Documents",
                items: [
                    {
                        header: AccCategoryE[75],
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.SMS,
                        parent1: 11,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents"
                    },
                    {
                        header: new StringSepWithSpace().transform(SMSE[1]),
                        CategoryId: AccCategoryE.SMS,
                        parent: 1,
                        index: 1,
                        privMask: 1,
                        parent1: 11,
                        parent2: 1,
                        key: 1,
                        categoryName : "SMS"
                    },
                    {
                        header: new StringSepWithSpace().transform(SMSE[2]),
                        CategoryId: AccCategoryE.SMS,
                        parent: 1,
                        index: 2,
                        privMask: 2,
                        parent1: 11,
                        parent2: 2,
                        key: 1,
                        categoryName : "SMS"
                    },  
                    
                ]
            },
            {
                header: new StringSepWithSpace().transform(AccCategoryE[79]),
                CategoryId: AccCategoryE.ReportWriter,
                parent: 0,
                index: 13,
                privMask: DocumentE.ReportWriter,
                categoryName : "Documents",
                items: [
                    {
                        header: new StringSepWithSpace().transform(AccCategoryE[79]),
                        CategoryId: AccCategoryE.Document,
                        parent: 1,
                        index: 0,
                        privMask: DocumentE.ReportWriter,
                        parent1: 12,
                        parent2: 0,
                        key: 2,
                        categoryName : "Documents",
                    },
                    {
                        header: new StringSepWithSpace().transform(ReportWriterE[1]),
                        CategoryId: AccCategoryE.ReportWriter,
                        parent: 1,
                        index: 1,
                        privMask: 1,
                        parent1: 12,
                        parent2: 1,
                        key: 1,
                        categoryName : "ReportWriter",
                    },
                    {
                        header: new StringSepWithSpace().transform(ReportWriterE[2]),
                        CategoryId: AccCategoryE.ReportWriter,
                        parent: 1,
                        index: 2,
                        privMask: 2,
                        parent1: 12,
                        parent2: 2,
                        key: 1,
                        categoryName : "ReportWriter",
                    },
                    {
                        header: new StringSepWithSpace().transform(ReportWriterE[4]),
                        CategoryId: AccCategoryE.ReportWriter,
                        parent: 1,
                        index: 3,
                        privMask: 4,
                        parent1: 12,
                        parent2: 3,
                        key: 1,
                        categoryName : "ReportWriter",
                    },
                    {
                        header: new StringSepWithSpace().transform(ReportWriterE[8]),
                        CategoryId: AccCategoryE.ReportWriter,
                        parent: 1,
                        index: 4,
                        privMask: 8,
                        parent1: 12,
                        parent2: 4,
                        key: 1,
                        categoryName : "ReportWriter",
                    },  
                    
                ]
            }                      
        ]
    }
];
}
