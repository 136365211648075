import { Pipe, PipeTransform } from "@angular/core";
import { CounsTypeEnum } from "../models";

@Pipe({ name: "getDropDown" })
export class CustmDrDw implements PipeTransform {
    SystemDatakey = [
        { DEF_RX_ORIG_CODE: "RxOrigin" },
        { DEF_DELIVERY_METHOD: "DeliveryMode" },
        { DEF_PRICE_CODE: "PriceSchedule" },
        // { PAT_LANGUAGE: "Lang" },
        // { PAT_ALLERGY: "Allergies" },
        { SLCT_SRVC_LVL: "TransClaimServiceUrl"},
        { PDC_Med_Cat_List: "MedAdherCategory"}
    ];
    DefaultKey = [
        // { PAT_TYPE: "InsucarrierDetails" },
        // { PAT_RESIDENCE: "ResidenceCat" },
        { RX_NO_GEN_SYST: "RxGenSys" },
        { TNT_PHARM_SERV_TYPE: "PharmServiceCat" },
        { Default_Subm_Clarification: "SubClarification"},
        { Default_Vendor: "VendorList"},
        { Default_340B_Vendor :"threeFourtyBvendorsList"}
    ];
    pathistView = [
        { Name: "All", Id: 0, Code: "A" },
        { Name: "Last 1 Yr", Id: 1, Code: "1" },
        { Name: "Last 2 Yr", Id: 2, Code: "2" },
        { Name: "Last 6 Mon", Id: 3 , Code: "6"},
        { Name: "Current Rxs Only", Id: 4 , Code: "C"}
    ];
    dateRanges = [
        { Name: "LAST 30 DAYS", Id: "LAST 30 DAYS" },
         { Name: "LAST 60 DAYS", Id: "LAST 60 DAYS" },
         { Name: "LAST 90 DAYS", Id: "LAST 90 DAYS"},
         { Name: "LAST 6 MONTHS", Id: "LAST 6 MONTHS" },
         { Name: "LAST 1 YEAR", Id: "LAST 1 YEAR" },
         { Name: "CUSTOM RANGE", Id: "CUSTOM RANGE" },
         { Name: "LAST 1 WEEK", Id: "LAST 1 WEEK" }
    ];
    Security = [
        { Id: 1, Name: "Severe" },
        { Id: 2, Name: "Moderate" },
        { Id: 3, Name: "Mild" }
    ];
    SubCat = [{ Id: 1, Name: "Patient" }];
    pregancyRating = [
        { Id: 1, Name: "A" },
        { Id: 2, Name: "B" },
        { Id: 3, Name: "C" },
        { Id: 4, Name: "D" },
        { Id: 5, Name: "NA" },
        { Id: 6, Name: "X" }
    ];
    laction = [
        { Id: 1, Name: "Avoid" },
        { Id: 2, Name: "caution" },
        { Id: 3, Name: "compatible" }
    ];
    compPrice = [{ Id: 1, Name: "D-Drug" }, { Id: 2, Name: "I-Ingredient" }];
    drugName = [
        { Id: 1, Name: "Default as Compound" },
        { Id: 2, Name: "Compound" },
        { Id: 3, Name: "Drug Name" }
    ];
    ErxPrintForm = [{ Id: 1, Name: "RxFormat" }, { Id: 2, Name: "Simple" }];
    controlRx = [
        { Id: 1, Name: "class 2 Drug" },
        { Id: 0, Name: "All Controls" }
    ];
    onSet = [
        { Id: 1, Name: "Rapid" },
        { Id: 2, Name: "Early" },
        { Id: 3, Name: "Delayed" }
    ];
    incidence = [
        { Id: 1, Name: "Common" },
        { Id: 2, Name: "Infrequent" },
        { Id: 3, Name: "Rare" },
        { Id: 3, Name: "Reported" }
    ];
    prfDrugDropdown = [
        { Id: "0", Name: "Do Nothing" },
        { Id: "1", Name: "Prompt" },
        { Id: "2", Name: "Auto Replace" }
    ];
    eScriptVersion = [
        // { Id: 1, Name: "10.6" },
        { Id: 2, Name: "2017071" }
    ];
    defaultMessageTypeFilters = [
        { Id: 0, Name: "All" },
        { Id: 1, Name: "New Rx" },
        { Id: 2, Name: " Rx Change Request" },
        { Id: 3, Name: "Refill Request" },
        { Id: 4, Name: "Refill Response" },
        { Id: 5, Name: "Cancel Rx" },
        { Id: 6, Name: "Rx Change Response" }
    ];
    defaultActionListSort = [
        { Id: 1, Name: "Transaction Date" },
        { Id: 2, Name: "Patient" },
        { Id: 3, Name: "Prescriber" },
        { Id: 4, Name: "Drug" }
    ];
    defaultACBAction = [
        { Id: "1", Name: ">" },
        { Id: "2", Name: ">=" },
    ];

    printCounsellingType = [
        { Id: CounsTypeEnum.YES, Name: "Yes" },
        { Id: CounsTypeEnum.NO, Name: "No" },
        { Id: CounsTypeEnum.REPORT, Name: "Report" }
    ];

    scanningIMageType = [
        // { Id: "0", Name: "B&W" },
        { Id: "1", Name: "Gray" },
        { Id: "2", Name: "RGB" },
    ];

    scanDocumentResolution = [
        { Id: "100", Name: 100 },
        { Id: "150", Name: 150 },
        { Id: "200", Name: 200 },
        { Id: "300", Name: 300 }
    ]

    transform(name: string, rxStngInfoFG, SystemData, dropSelectedId:any, notFrmSystemData?): any {

        let dropVal: any = null;
        if (name === "PREGNANCY_RATING") {
            dropVal = this.pregancyRating;
        }

        if (name === "PRF_DRUG_SLCT_ON_RX_FILL") {
            dropVal = this.prfDrugDropdown;
        }
        if (name === "E_PRESC_SCRIPT-VERSION") {
            dropVal = this.eScriptVersion;
        }
        if (name === "SEVERITY_LEVEL") {
            dropVal = this.Security;
        }
        if (name === "LACTATION_RATING") {
            dropVal = this.laction;
        }
        if (name === "PAT_PHOTO_SUB_CATG") {
            dropVal = this.SubCat;
        }
        if (name === "PAT_HISTORY_VIEW") {
            dropVal = this.pathistView;
        }
        if (name === "PDC_Date_Range_Type") {
            dropVal = this.dateRanges;
        }
        if (name === "COMP_PRICE_CALCULATION") {
            dropVal = this.compPrice;
        }
        if (name === "COMP_NAME_LABEL") {
            dropVal = this.drugName;
        }
        if (name === "ERX_PRNT_FORMAT") {
            dropVal = this.ErxPrintForm;
        }
        if (name === "RX_SERI_NO_REQ_CNT_DRUG") {
            dropVal = this.controlRx;
        }
        if (name === "ON_SET") {
            dropVal = this.onSet;
        }
        if (name === "INCIDENCE") {
            dropVal = this.incidence;
        }
        if (name === "E-PRESCRIPTION_SCRIPT_VERSION") {
            dropVal = this.eScriptVersion;
        }
        if (name === "DEFAULT_MESSAGE_TYPE_FILTERS") {
            dropVal = this.defaultMessageTypeFilters;
        }
        if (name === "DEFAULT_ERX_ACTION_LIST_SORT") {
            dropVal = this.defaultActionListSort;
        }
        if (name === "ACB_SCORE_CONDITION") {
            dropVal = this.defaultACBAction;
        }
        if (name==="Print_Drug_Monograph_on_Label") {
            dropVal = this.printCounsellingType;
        }

        if (name === "Default_Scanning_Image_Type") {
            dropVal = this.scanningIMageType;
        }

        if (name === "Scanned_Document_Resolution") {
            dropVal = this.scanDocumentResolution;
        }
        
        if( name == "START_NEW_RX_FROM"){
            dropVal = [{'Id': 1,'Name':'Drug'},{'Id': 2, 'Name':'Patient'}]
        }
        //  Based on this card commented these changes EP-6860: UI: DAW: Remove and Update DAW Settings for Rx Entry
        // if( name == "DEF_DAW"){
        //     dropVal = [{'Id': 3, 'Name':'NO DAW'}, {'Id': 4,'Name':'PHYSICIAN DAW'}, {'Id': 0, 'Name':'Blank'}]
        // }

        this.SystemDatakey.map(sdkey => {
            if (sdkey[name]) {
                dropVal = SystemData[sdkey[name]];
            }
        });
        this.DefaultKey.map(rxKey => {
            if (rxKey[name] && rxStngInfoFG.controls && rxStngInfoFG.controls[rxKey[name]] ) {
                dropVal = rxStngInfoFG.controls[rxKey[name]].value;
            }
        });
        if(notFrmSystemData){
            dropVal = notFrmSystemData;
        }
        if (dropVal) {
            var item = dropVal.find(resp=> {
                if(name == "Ins_Code_340BSettings"){
                    return resp.InsuCarrierId == dropSelectedId;
                }else if (name === "Default_Vendor" || name === "Default_340B_Vendor")  {
                    return resp.ContractId == dropSelectedId;
                } else {
                    return resp.Id == dropSelectedId
                }
                
            })
        }
        if (dropSelectedId && !item && (name === "Default_Vendor" || name === "Default_340B_Vendor")) {
            return "--";
        } else {
            return ((dropSelectedId && item) ? (name == 'Default_Vendor' || name == 'Default_340B_Vendor' ? (item["ContractName"] + " " + item["VendorName"]) : (name == "Default_Subm_Clarification" || name == "Basis_Of_Cost" ? item["Remarks"] : ( name == 'Ins_Code_340BSettings' ? item['InsuranceName'] : (name == 'Price_340BSettings' ? item['PriceCode'] : item['Name'])))) : dropVal);
        }
    }
}
