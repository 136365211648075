import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-icon-tooltip',
  templateUrl: './info-icon-tooltip.component.html',
  styleUrls: ['./info-icon-tooltip.component.scss']
})
export class InfoIconTooltipComponent implements OnInit {
@Input() InformationTool:string;
@Input() TableViewInformation:string;
  constructor() { }

  ngOnInit(): void {
  }

}
