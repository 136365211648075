<div id="loader-wrapper" *ngIf="_loader">
    <div class="spinner col-12 p-0">
        <div class="disable-select height_100px">
            <img src="assets/img/edited.png" class="loader-img">
        </div>
        <div class="disable-select">
            <!-- <h5 class="text-white">Please wait ... </h5> -->
             <div class="row" *ngIf="message">
                <h3 class="text-white col-12 p-0" [innerHTML]="(addDots ? message+ ' ...' : message)"></h3>
             </div>
             <div *ngIf="!message">
                <h3 class="text-white" >Loading data ...</h3>
             </div>
        </div>
        <!-- <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="length" fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
        </svg>
        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
        </svg>
        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
        </svg>
        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
        </svg> -->
    </div>
    <div class="loader-section section-left"></div>
    <div class="loader-section section-right"></div>
</div>
