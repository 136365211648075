
<div class="editpatient" *ngIf="!isFromTrRejct && !hideDurHeader && !VaccineForm">
    <div class="row content--heading" *ngIf="!hideforNewRx">
        <div class="col-md-8 padding-0">
            <div class="row">
                <div class="icon">
                    <img src="assets/dist/Icons/Icon_rx2.png">
                </div>
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title padding-0"> Drug Utilization Review (DUR)
                        <span class="content--name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-4 padding-0" *ngIf="!fromNewRx">
            <!-- <button  class="hotkey_success" *ngIf="newRxDur?.length > 0"  appShortcutKey [AltKey]="'s'" (click)="saveNewRxDur()" id="saveButton"><span>S</span> Save </button> -->
            <button class="hotkey_success" appShortcutKey [AltKey]="'c'" (click)="previousPage()" id="CancelButton"><span>C</span>Cancel</button>
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="editpatient--body newrx--body edit--drug edit--drug">
            <div class="exprx--common-block">
                <div class="eprx--block__header " *ngIf="!accessingFrom">
                    <div class="row">
                        <div class="col">
                            <span class="eprx--header__heading">
                                DUR Values </span>
                        </div>
                       
                        <div class="col pull-right text-right">
                            <button *ngIf="showAddForDUR" aria-hidden="true" class="hotkey_success hotkey_primary--Override"
                            ngbAutofocus (keydown.tab)="focusOutFromAdd($event)"  title="Add" (click)="addPopUp(DURADD)" appShortcutKey [AltKey]="'a'"><span>A</span>ADD DUR</button>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <wj-flex-grid #DURlist [headersVisibility]="'Column'" [itemsSource]="DURWJ" [isReadOnly]="true" [columnPicker]="(rxType === 'nr' || rxType === 'rf' || rxType === 'nrf'|| rxType === 'rnr') ? 'DURValNewRxWJ' : 'DurValWj'" [selectionMode]="'Row'" (keydown)="openPopOnWijimo(DURADD, $event)"
                        (updatedView)="init(DURlist)" (keydown.tab)="focusOnSave($event)" [ngClass]="!totalCount ? 'no-data':''">
                        <wj-flex-grid-column [header]="'Actions'" [width]="'0.5*'">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div class="text-center">
                                    <i class="far fa-edit   actions" *ngIf="rxType === 'er'" title="edit" (click)="
                                                                editPopUpModal(
                                                                    DURADD,
                                                                    item,
                                                                    item.value
                                                                )
                                                            "></i>
                                    <i class="far fa-trash-alt  actions danger-actve" *ngIf="rxType === 'er'" title="delete" (click)="
                                    openDeleteModal(delete, item.Id,item.value)"></i>
                                    <i class="far fa-edit   actions" *ngIf="rxType === 'nr' || rxType === 'rf' || rxType === 'nrf' || rxType === 'rnr'"
                                        title="edit" (click)="editNewRxPopUpModal( DURADD,
                                        item, item.value)"></i>
                                    <i class="far fa-trash-alt  actions danger-actve" *ngIf="rxType === 'nr' || rxType === 'rf' || rxType === 'nrf' || rxType === 'rnr'"
                                        title="delete" (click)="openNewRxDeleteModal(delete,item.value)"></i>
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="actvHeader['hName']" [binding]="actvHeader['hName']" [visible]="actvHeader['isVisible']" *ngFor="
                                                    let actvHeader of wjHeaders;
                                                    let i = index"
                                                 [width]="actvHeader['width']">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">

                                <div *ngIf="actvHeader['hName'] !== 'Actions'"  [title]="((item[actvHeader['hName']] + '') | uppercase)">
                                    {{ item[actvHeader['hName']] }}
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                        <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                    </wj-flex-grid>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="VaccineForm" class="edit--drug">
    <div class="row">
        <div class="col-md-10 padding-0">
            <h5 class="col-12 label_Color">DUR Values</h5>
        </div>
        <div class="col-md-2 pull-right text-right mb-2">
            <button aria-hidden="true" class="hotkey_success hotkey_primary--Override"
            ngbAutofocus title="Add" (keydown.tab)="focusOutSave($event)" (click)="addPopUp(DURADD)" appShortcutKey [AltKey]="'a'"><span>A</span>ADD DUR</button>
        </div>
    </div>
    <wj-flex-grid #DURlist [headersVisibility]="'Column'" [itemsSource]="DURWJ" [isReadOnly]="true" [columnPicker]="'DURValNewRxWJ'" [selectionMode]="'Row'" (keydown)="openPopOnWijimo(DURADD, $event)"
    (updatedView)="init(DURlist)" (keydown.tab)="focusOutSave($event)" [ngClass]="!totalCount ? 'no-data':''">
    <wj-flex-grid-column [header]="'Actions'" [width]="'1*'">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
            <div class="text-center">
                <i class="far fa-edit   actions" *ngIf="rxType === 'er' || frmVerfScrn" title="edit" (click)="
                                                                editPopUpModal(
                                                                    DURADD,
                                                                    item,
                                                                    item.value
                                                                )
                                                            "></i>
                                    <i class="far fa-trash-alt  actions danger-actve" *ngIf="rxType === 'er' || frmVerfScrn" title="delete" (click)="
                                    openDeleteModal(delete, item.Id,item.value)"></i>
                                    <i class="far fa-edit   actions" *ngIf="rxType === 'nr' || rxType === 'rf' || rxType === 'nrf' || rxType === 'rnr' || frmQukRef"
                                        title="edit" (click)="editNewRxPopUpModal( DURADD,
                                        item, item.value)"></i>
                                    <i class="far fa-trash-alt  actions danger-actve" *ngIf="rxType === 'nr' || rxType === 'rf' || rxType === 'nrf' || rxType === 'rnr' ||frmQukRef"
                                        title="delete" (click)="openNewRxDeleteModal(delete,item.value)"></i>
            </div>
        </ng-template>
    </wj-flex-grid-column>
    <wj-flex-grid-column [header]="actvHeader['hName']" [binding]="actvHeader['hName']" [visible]="actvHeader['isVisible']" *ngFor="
                                let actvHeader of wjHeaders;
                                let i = index"
                             [width]="actvHeader['width']">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">

            <div *ngIf="actvHeader['hName'] !== 'Actions'"
            [ngbTooltip]="actvHeader['hName']==='Reason of Service'?(item['ReasonOfSrvc'] | uppercase): actvHeader['hName']==='Professional Service'?(item['ProfessionalSrvc']| uppercase) :
            actvHeader['hName']==='Result of Service'?(item['ResultofSrvc']| uppercase):actvHeader['hName']==='Clinical Signif.Code'?(item['ClinicalSignfCode']| uppercase):actvHeader['hName']==='Level'?(item['level']| uppercase) : ((item[actvHeader['hName']] + '') | uppercase)" [placement] = "'bottom'" container="body" tooltipClass="tooltip-title">
                {{ item[actvHeader['hName']] }}
            </div>
        </ng-template>
    </wj-flex-grid-column>
    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
</wj-flex-grid> 

</div>
<ng-template #DURADD let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">DUR Entry</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="(rxType === 'nr' || rxType === 'rf' || rxType === 'nrf' || rxType === 'rnr'|| frmQukRef || VaccineForm) ? closeModal() : d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body box-body" [formGroup]="DurFG">
        <div class="box box-default box-solid">
            <div class="eprx--block__header">
                <span class="eprx--header__heading"></span>
            </div>
            <div class="box-body">
                <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <app-sub-dur [DurFG]="DurFG" [Defaultval]="systemData?.servReason" [InputErrors]="ServReasonId?.errors"
                            [FormGroupInvalid]="formGroupInvalid"></app-sub-dur>
                    </div>
                    <div class="col-6">
                        <eprx-select [LabelText]="'Professional Service'" [PlaceHolder]="''" [ControlName]="'ProfServId'"
                            [FormGroupName]="DurFG" [secondCntrlN]="'ProfServName'" [bindScnCntrlV]="'Name'" [HasMultiple]="false"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.profServs" [InputErrors]="ProfServId?.errors" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                        </eprx-select>
                    </div>
                </div>
                </div>
                <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <eprx-select [LabelText]="'Result of Service'" [PlaceHolder]="''" [ControlName]="'ServResultId'"
                            [FormGroupName]="DurFG" [secondCntrlN]="'ServResult'" [bindScnCntrlV]="'Name'" [HasMultiple]="false"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.servResult" [InputErrors]="ServResultId?.errors" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-6">
                        <eprx-select [LabelText]="'Level of Effort'" [PlaceHolder]="''" [ControlName]="'EffortLvlId'"
                            [FormGroupName]="DurFG" [HasMultiple]="false" [secondCntrlN]="'EffortLvlName'" [bindScnCntrlV]="'Name'"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.effortLvl" [InputErrors]="EffortLvlId?.errors" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                        </eprx-select>
                    </div>
                </div>
                </div>
                <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <eprx-input [LabelText]="'Co-Agent ID'" [PlaceHolder]="''" [ControlName]="'CoAgentNum'" [MaxLength]="19"
                            [InputType]="'ALPHANUMERIC'" [FormGroupName]="DurFG"></eprx-input>
                    </div>
                    <div class="col-6">
                        <eprx-select [LabelText]="'Co-Agent ID Type'" [PlaceHolder]="''" [ControlName]="'CoAgentIdType'"
                            [FormGroupName]="DurFG" [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'"
                            [BindValue]="'Name'" [LabelForId]="'Id'" [List]="systemData?.coAgentIdCat"
                            [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid"
                            [IsRequired]="DurFG?.value['CoAgentNum'] !== null"
                            [MarkAsTouched]="DurFG?.controls['CoAgentIdType']?.touched" [Show2Values]="true">
                        </eprx-select>
                    </div>
                </div>
                </div>
                <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <eprx-select [LabelText]="'Clinical Significance'" [PlaceHolder]="''" [ControlName]="'ClinicalSignfId'"
                            [FormGroupName]="DurFG" [secondCntrlN]="'ClinicalSignfName'" [bindScnCntrlV]="'Name'" [IsTitle]="true"
                            [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.clinicalSignf" [InputErrors]="ClinicalSignfId?.errors"
                            [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true"
                            [show2ValInInputhidden]="true">
                        </eprx-select>
                    </div>
                    <div class="col-6" [formGroup]="DurFG">
                        <label class="align-label"> Pharmacist Comment </label>
                        <textarea class="custom--text__area" rows="1" cols="73" formControlName="PhComment"
                            placeholder="Comments"></textarea>
                    </div>

                </div>
                </div>
                <div class="col-12" [formGroup]="DurFG">
                    <input class="form-check-input" type="checkbox" formControlName="IsRefillSpecific" id="IsRefillSpecific"/>
                    <label class="form-check-label">
                        Use this for current refill only.
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success"
                *ngIf="!editMode && (rxType === 'er' || rxType === 'brx' || (this.rxno && !VaccineForm) || frmVerfScrn)"
                (click)="addDUR()" (keydown.enter)="enterKeyDownEv($event, 'addDUR')" appShortcutKey [AltKey]="'s'">
                   <span>S</span> Save
                </button>
                <button class="hotkey_success"
                *ngIf="(rxType === 'nr' || rxType === 'rf' || rxType === 'nrf' || rxType === 'rnr' || frmQukRef) && !newRxEditMode"
                (click)="addNewDUR()" (keydown.enter)="enterKeyDownEv($event, 'addNewDUR')" appShortcutKey [AltKey]="'s'">
                    <span>S</span>    Save
                </button>
                <button class="hotkey_success"
                *ngIf="editMode &&(rxType === 'er' || rxType === 'brx' || frmVerfScrn)"
                (click)="editDUR()" (keydown.enter)="enterKeyDownEv($event, 'editDUR')" appShortcutKey [AltKey]="'u'">
                   <span>U</span> Update
                </button>
                <button class="hotkey_success"
                *ngIf="(rxType === 'nr' || rxType === 'rf' || rxType === 'nrf' || rxType === 'rnr' || frmQukRef) && newRxEditMode"
                (click)="editNewRxDUR()" (keydown.enter)="enterKeyDownEv($event, 'editNewRxDUR')" appShortcutKey [AltKey]="'u'">
                       <span>U</span> Update
                    </button>
                <button class="hotkey_success" (keydown.enter)="(rxType === 'nr' || rxType === 'rf' || rxType === 'nrf' || rxType === 'rnr' || frmQukRef || VaccineForm) ? closeModal() : d('Cross click')" (click)="(rxType === 'nr' || rxType === 'rf' || rxType === 'nrf' || rxType === 'rnr' || frmQukRef || VaccineForm) ? closeModal() : d('Cross click')" appShortcutKey [AltKey]="'c'">
                   <span>C</span> Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #delete let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button"  [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>{{warningText?.deleteConfrmMsg}}</span>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-12 padding-0">
            <div>
                <button autofocus class="hotkey_primary" *ngIf="(rxType === 'er' || rxType === 'brx' || frmVerfScrn)"
                (click)="d('Cross click')" (click)="deleteDUR()" (keydown.enter)="deleteDUR(); d('Cross click')" appShortcutKey InputKey="y"><b>Y</b> YES
                </button>
                <button autofocus class="hotkey_primary"  *ngIf="(rxType === 'nr' || rxType === 'rf' || rxType === 'nrf' || rxType === 'rnr' || frmQukRef)"
                (click)="d('Cross click')" (click)="deleteNewRxDur()" (keydown.enter)="deleteNewRxDur(); d('Cross click')" appShortcutKey InputKey="y"><b>Y</b> YES
                    </button>
                <button class="hotkey_primary" (click)="d('Cross click')" (keydown.enter)="d('Cross click')" appShortcutKey InputKey="n"><b>N</b>NO</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #info let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Please Correct the Information</h4>
        <button type="button"  [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>Unable to save empty record, please specify value for atleast 1 field.</span>
    </div>
    <div class="modal-footer">
        <button class="hotkey_primary" (click)="d('Cross click')" (keydown.enter)="enterKeyDownEv($event, 'Info')" appShortcutKey InputKey="o"><b>O</b>OK</button>
</div>
</ng-template>
