
import { PatientService } from "src/app/services/patient.services";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    Input,
    ChangeDetectorRef
} from "@angular/core";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrintErxService } from "src/app/services/print-erx.service";
import { AlertService, RxService, CommonService, } from "src/app/services";
import { Router, ActivatedRoute } from "@angular/router";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { MsgConfig } from "../../../app.messages";
import { UdpCurrencyMaskPipe } from "../../../pipes";
import { ValidateFormFieldsUtils, WijimoUtil } from "src/app/utils";
import { Subject, Subscription ,  forkJoin } from "rxjs";
import { CollectionView } from "@grapecity/wijmo";
import { RxChangeDUR, RxChangeDia, RxChangeDrug, ErxVersions, SystemData } from "../../../models";
import * as _ from "lodash";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-erx-change-req",
    templateUrl: "./erx-change-req.component.html"
})
export class ErxChangeReqComponent implements OnInit {
    prescRefillId: any;
    erxDetails: any;
    drugId: any;
    SearchDrug = true;
    isInputFocused = false;
    numberPipe = new UdpCurrencyMaskPipe();
    erxReq: FormGroup;
    SPIDetails: any;
    multipleSpi: boolean;
    PrescAddress: any;
    cancelPOP: boolean;
    authRefills: any = [];
    canDoReq = false;
    PrescAddress1: any;
    PrescAddress2: any;
    typedValue: boolean;
    subscriptions = new Subscription();
    chngReqTyps: { Code: string; Name: string; }[];
    RxNo: any;
    DAWList: { Code: string; Name: string; }[];
    selectedDrug: any;
    addDrugValue: any[] = [];
    drugListWJ: CollectionView;
    editMode: boolean;
    DurFG: FormGroup;
    modal: any;
    newRxDur = [];
    totalCount: number;
    formGroupInvalid: boolean;
    defaultval: any;
    editIndex: any;
    deleteIndex: any;
    Rxdurdata: any;
    rxInfo: any;
    patDiagnis: any;
    diagListType: any;
    model: NgbModalRef;
    ErxChangeReqFG: FormGroup;
    DURWJ: CollectionView;
    SigCodeFocus: boolean;
    enableDrugSearch: boolean;
    textareaFocus = false;
    patInsurList: any;
    insureList: any;
    insurncListModified: CollectionView;
    drugformGroupInvalid: boolean;
    DrugSelect: boolean;
    durType: boolean;
    frmErx: any;
    erxData: any;
    eRxVersion: any;
    dropdownData: any;
    DrugMissingForm: FormGroup;
    formSubmitted = false;
    sendButtonText = "Send Request";
    showDrugDescription: boolean;
    drugIngredientsList: any;
    processingMissingDrug: any;
    routeFrom: any;
    enableMessageSubCode = false;
    wjHeadersSelectDrug: any;
    wjHeadersDUR: any;
    unsubscribe$: Subject<void> = new Subject();
    selectedBenefitCheckType: string;
    patientSearchValue: any = "";
    requestCategoryCode: any = [];
    changedInsId: any;
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    @Input()
    set PrescRefillId(data: any) {
        this.prescRefillId = data;
    }

    @Input()
    set PrescNumber(prescNum: any) {
        this.RxNo = prescNum;
    }

    @Input()
    set DrugAddDetails(data: any) {
        if (data) {
            this.selectedDrugInfo(data);
        }
    }

    @Input()
    set DrugId(data: any) {
        this.drugId = data;
    }

    @Input()
    set RxInfo(data: any) {
        this.rxInfo = data;
    }

    @Input()
    set ExData(data: any) {
        this.erxData = data;
    }

    @Input()
    set SelectedBenefitCheckType(data: any) {
        this.selectedBenefitCheckType = data;
    }
    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @Output()
    DrugAddEmit = new EventEmitter<any>();

    @ViewChild("ChangeReq", { static: true })
    ChangeReq: any;

    @ViewChild("CancelPop")
    CancelPop: any;

    @ViewChild("DURADD", { static: true })
    DURADD: any;

    @ViewChild("diagList", { static: true })
    diagList: any;

    @ViewChild("BILLTO", { static: true })
    BILLTO: any;

    @ViewChild("MissingFields", { static: true })
    MissingFields: any;

    @ViewChild("canDoRxChange", { static: true })
    canDoRxChange: any;

    modelRef: NgbModalRef;
    modelRefForScreen: NgbModalRef;
    @Input() systemData: SystemData;

    constructor(
        private _modalService: NgbModal,
        private _erxServ: PrintErxService,
        private _alertService: AlertService,
        private _fb: FormBuilder,
        private _cdr: ChangeDetectorRef,
        private _rxService: RxService,
        private _patientSVC: PatientService,
        private _formValid: ValidateFormFieldsUtils,
        private _commonServ: CommonService,
        private _actvRoute: ActivatedRoute,
        public _formatsUtil:FormatsUtil,
        private _wijimoUtils: WijimoUtil
    ) {
        this.DurFG = this._fb.group(new RxChangeDUR());
        this.DrugMissingForm = this._fb.group({
            DoseFrmDescId: [],
            DoseFrmCodeId: [],
            DrugStrQualId: [],
            PotencyUnitCodeId: [],
            QtyQualId: [],
            DrugId: [],
            DrugName: []
        });
        this.ErxChangeReqFG = this._fb.group({
            erxId: [],
            PrescRefillId: [],
            PatientId: [],
            PrescriberId: [],
            DrugId: [],
            SPINum: [],
            RxId: [],
            currentRefillNo: [],
            Quantity: [],
            SupplyDays: [],
            PharmacistNotes: [],
            SigDesc: [],
            DispQty: [],
            OrdQty: [] ,
            Daw: [],
            PrescriptionId: [],
            UnitPriceUNC: [],
            NpiNum: [],
            InsuranceId: [],
            RxChangeReason: [],
            RxChangeCategory: [],
            RxChangeSubReason: [],
            IsTypedValue: [],
            DrugCoverageCodes: [],
            AWPPack: null,
            PriceSchId: [],
            count: [],
            Dur: this._fb.array([]),
            PrimaryDiagnosis1: this._fb.group(new RxChangeDia()),
            PrimaryDiagnosis2: this._fb.group(new RxChangeDia()),
            SecondaryDiagnosis1: this._fb.group(new RxChangeDia()),
            SecondaryDiagnosis2: this._fb.group(new RxChangeDia()),
            AlternativeDrugs: this._fb.array([]),
        });
        this.subscriptions.add(
            this._commonServ.systemData$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.defaultval = resp;
            })
        );
        this.erxReq = this._fb.group(new RxChangeDrug());
        this.chngReqTyps = [{ Code: "D", Name: "DUR" },
        { Code: "F", Name: "Formulary Compliance" }, { Code: "C", Name: "Days Supply Change" },
        { Code: "4a", Name: "Switiching from Brand To Generic" }, { Code: "G", Name: "New Generic Available" },
        { Code: "4c", Name: "Brand Not Covered" }, { Code: "T", Name: "Therapy Change" },
        { Code: "P", Name: "Prior Authorization Req" },
        { Code: "S", Name: "Script Clarification" }];
        this.DAWList = [{ Code: "Y", Name: "Yes" }, { Code: "N", Name: "No" }];

        const versionsetting = this._commonServ.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
        if (versionsetting == 2) {
            this.eRxVersion = ErxVersions.NewerVersion;
        } else {
            this.eRxVersion = ErxVersions.OldVersion;
        }
    }

    get PrimaryDiagnosis1(): FormGroup {
        return this.ErxChangeReqFG.get("PrimaryDiagnosis1") as FormGroup;
    }
    get PrimaryDiagnosis2(): FormGroup {
        return this.ErxChangeReqFG.get("PrimaryDiagnosis2") as FormGroup;
    }
    get SecondaryDiagnosis1(): FormGroup {
        return this.ErxChangeReqFG.get("SecondaryDiagnosis1") as FormGroup;
    }
    get SecondaryDiagnosis2(): FormGroup {
        return this.ErxChangeReqFG.get("SecondaryDiagnosis2") as FormGroup;
    }

    ngOnInit() {
        this.getErxDetails();
        this.authRefills.push({ Id: 100, Name: "Not SPecified" });
        this.subscriptions.add(
            this._actvRoute.queryParams
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(params => {
                this.routeFrom = params.fm;
            })
        );
        for (let i = 0; i <= 99; i++) {
            this.authRefills.push({ Id: i, Name: i });
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "erxChangeReqDURListWJ" || w["WijmoKey"] === "erxChangeReqSelectDrugListWJ")) {
                this.patchDefaultValHeaders();
            }
        });
        if (this.selectedBenefitCheckType  && this.selectedBenefitCheckType === "paReq") {
            this.sendButtonText  =   "Send PA Request";
        }
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
        this.systemData = this._commonServ.systemData$["source"]["value"];
    }
    get AuthRef(): FormControl {
        return this.erxReq.get("Refills") as FormControl;
    }

    async patchDefaultValHeaders() {
        const storedWJDUR = await this._wijimoUtils.getWJSavedData("erxChangeReqDURListWJ");
        this.wjHeadersDUR = this._wijimoUtils.patchDefHeader("erxChangeReqDURListWJ", storedWJDUR);
        const storedWJSelectDrug = await this._wijimoUtils.getWJSavedData("erxChangeReqSelectDrugListWJ");
        this.wjHeadersSelectDrug = this._wijimoUtils.patchDefHeader("erxChangeReqSelectDrugListWJ", storedWJSelectDrug);

    }
    
    ngOnDestroy() {
	    this.unsubscribe$.next();
	    this.unsubscribe$.complete();
    }

    getSigCodeDescOnChange(sigC: any) {
        const endSpace = /\s$/;
        if (sigC && endSpace.test(sigC)) {
            this.getSigCodeInfo(sigC);
        }
    }

    getSigCodeDescOnEnter(sigC: any) {
        if (sigC) {
            this.getSigCodeInfo(sigC.target.value);
        }
    }

    trimSigCodeOnBlur(sigC: any) {
        if (sigC) {
            const sigtrim = sigC.value.trim();
            this.erxReq.controls["SigCode"].setValue(sigtrim);
            this._cdr.detectChanges();
        }
    }

    async getSigCodeInfo(val) {
        if (val) {
            const sigCodeList: string[] = (val.trim()).split(" ");
            const sigData = {
                sigcodes: sigCodeList,
                langcodes: ["enhyus"]
            };
            const resp = await this._rxService
                .getSigCodeListByLangCode(sigData).toPromise();
                    if (resp) {
                        let sigCodeData = "";
                        let sigAltCodeData = "";
                        sigCodeList.map(code => {
                            for (const key in resp) {
                                if (code === key) {
                                    if (resp[key]) {
                                        if (resp[key]["en-US"]) {
                                            const sigCodeDS = resp[key][
                                                "en-US"
                                            ].split("#");
                                            sigCodeData += " " + sigCodeDS[0];
                                        } else {
                                            sigCodeData += " " + key;
                                        }
                                    } else {
                                        sigCodeData += " " + key;
                                        sigAltCodeData += " " + key;
                                    }
                                }
                            }
                        });
                        if (sigCodeData === "" || sigCodeData === null ) {
                            this.erxReq.controls["Sig"].setValue(val);
                        } else {
                            this.erxReq.controls["Sig"].setValue(sigCodeData.substring(0, 140).trim());
                        }
                    }
        }
    }
    selectPatient(patInfo: any) {
        if (patInfo) {
            this.patInsurList = patInfo["insurance"];
            this.patientSearchValue = patInfo.fullname ? ((patInfo.fullname).toUpperCase()).replace("_", ",") : "";
            this.ErxChangeReqFG.controls["PatientId"].patchValue(patInfo.patientid);
            this.openBillToPopupModal();
        } else {
            this.patientSearchValue = "";
            this.ErxChangeReqFG.controls["PatientId"].patchValue(null);
        }
}

    getErxDetails() {
        if (!this.erxData) {
        this._erxServ
            .getErxDetails(
                this.rxInfo.PrescReFill.Id,
                this.rxInfo.Drug.id,
                "Change"
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.saveErxDetails(resp);
                } else {
                    this.IsPopUpClosed.emit(null);
                }
            });
        } else {
            this._erxServ.getErxDetailsFrErx(this.erxData, this.eRxVersion)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.saveErxDetails(resp);
                } else {
                    this.IsPopUpClosed.emit(null);
                }
            });
        }
    }
    getPatientDiagnosis() {
        if(!(this.erxDetails && this.erxDetails.Patient.patientid)) {
              this.patDiagnis = { TempDiagnosis : []};
        } else {
            this._patientSVC.getPatientDiagnosis(this.erxDetails.Patient.patientid)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resps => {
                if (resps && resps.TempDiagnosis) {
                    this.patDiagnis = resps;
                } else {
                    this.patDiagnis = { TempDiagnosis : []};
                }
            });
        }
    }
    saveErxDetails(data) {
        if (data) {
            this.erxDetails = data;
            this.patInsurList = this.erxDetails["Patient"]["insurance"];
            this.requestCategoryCode =
            this.erxDetails.RxChangeRequestTypesList.filter((item: any) => (item["RequestCategoryCode"] === "P"));
           if (this.requestCategoryCode && this.requestCategoryCode.length && this.requestCategoryCode[0].RequestCategoryCode === "P" &&
           this.selectedBenefitCheckType === "paReq") {
            this.ErxChangeReqFG.controls["RxChangeReason"].patchValue(this.requestCategoryCode[0].RequestDescription);
           }
           this.getPatientDiagnosis();
            // tslint:disable-next-line: max-line-length
            this.ErxChangeReqFG.controls["PrescRefillId"].patchValue(this.erxDetails["PrescReFill"]["Id"] ? this.erxDetails["PrescReFill"]["Id"] : null);
            this.ErxChangeReqFG.controls["PatientId"].patchValue(this.erxDetails["Patient"]["patientid"]);
            this.ErxChangeReqFG.controls["PrescriberId"].patchValue(this.erxDetails["Prescriber"]["prescriberid"]);
            this.ErxChangeReqFG.controls["RxId"].patchValue(Number(this.erxDetails["Prescription"]["PrescNum"]));
            // tslint:disable-next-line: max-line-length
            this.ErxChangeReqFG.controls["PrescriptionId"].patchValue(this.erxDetails["Prescription"]["Id"] ? this.erxDetails["Prescription"]["Id"] : null);
            this.ErxChangeReqFG.controls["DrugId"].patchValue(this.erxDetails["Prescription"]["DrugId"]);
            //this.erxReq.controls["Days"].patchValue(0);
            this.erxReq.controls["Refills"].patchValue(0);
            this.erxReq.controls["Daw"].patchValue("N");
            this.erxReq.controls["Qty"].patchValue("0.000");
            if (!this.erxData) {
                if ( this.erxDetails["drugEvaluationUse"] &&  this.erxDetails["drugEvaluationUse"].length !== 0) {
                    this.newRxDur = this.erxDetails["drugEvaluationUse"];
                    this.totalCount = this.erxDetails["drugEvaluationUse"].length;
                    this.generateWJ(this.erxDetails["drugEvaluationUse"]);
                } else {
                    this.totalCount = 0;
                }
            }

            if (this.erxDetails["SpiList"] && this.erxDetails["SpiList"].length > 0) {
                this.SPIDetails = this.erxDetails["SpiList"];
                this.erxDetails["SpiList"].map(val => {
                    if (val["IsPrimary"] === true) {
                        this.PrescAddress1 = val;
                    }
                    if (val["OriginalSpiNumber"] === true) {
                        this.PrescAddress = val;
                        this.erxDetails.Prescriber.prescriberspinum = val["SPINo"];
                    }
                });
            }

            if (!this.PrescAddress && this.PrescAddress1) {
                this.PrescAddress = this.PrescAddress1;
                this.erxDetails.Prescriber.prescriberspinum = this.PrescAddress1["SPINo"];
            }

            if (this.erxDetails["Prescriber"]["PrescriberAddresses"]) {
                if (
                    this.erxDetails.Prescriber.PrescriberAddresses
                        .length > 1
                ) {
                    this.erxDetails.Prescriber.PrescriberAddresses.map(
                        value => {
                            if (
                                value["Id"] ===
                                this.erxDetails.Prescriber
                                    .primeAddressId
                            ) {
                                this.PrescAddress2 = value;
                            }
                        }
                    );
                } else {
                    this.PrescAddress2 = this.erxDetails.Prescriber.PrescriberAddresses[
                        "0"
                    ];
                }
            } else {
                this.PrescAddress2 = null;
            }

            if (this.erxDetails.Prescriber.prescriberdeanum === "###") {
                this.erxDetails.Prescriber.prescriberdeanum = null;
            }


            this.modelRefForScreen = this._modalService.open(this.ChangeReq, {
                size: "lg",
                backdrop: false,
                windowClass: "md-x-lg",
            });
        }
    }



    closeModal() {
        this.cancelPOP = false;
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.IsPopUpClosed.emit(null);
    }

    enableDrug(data) {
        this.ErxChangeReqFG.controls["RxChangeSubReason"].enable();
        if (data && data.value && data.value["RequestDescription"] && (data.value["RequestDescription"] === "PRIOR AUTHORIZATION" ||
             data.value["RequestDescription"] === "Prior Authorization Required")) {
            this.enableDrugSearch = false;
            this.sendButtonText = "Send PA Request";
        } else {
            this.enableDrugSearch = true;
            this.sendButtonText = "Send Request";
        }
        if (data && data.value && data.value["RequestDescription"] && (data.value["RequestDescription"] === "PRIOR AUTHORIZATION" ||
             data.value["RequestDescription"] === "Prior Authorization Required")) {
            this.openBillToPopupModal();

        }
        if (data && data.value && data.value["RequestDescription"] && (data.value["RequestDescription"] === "DUR(DRUG UTILIZATION REVIEW)" ||
        data.value["RequestDescription"] === "DUE(Drug Use Evaluation)")) {
            this.durType = true;
        } else {
            this.durType = false;
        }
        if (data && data.value && data.value["RequestDescription"] && data.value["RequestDescription"] === "Prescriber Authorization") {
            this.enableMessageSubCode = true;
        } else {
            this.enableMessageSubCode = false;
            // tslint:disable-next-line: no-unused-expression
            this.ErxChangeReqFG.controls["RxChangeSubReason"].disable();
        }
    }

    resetDrug() {
        this.SearchDrug = true;
        this.selectedDrug = null;
    }

    openAddDrugPopup(value) {
        this.DrugAddEmit.emit(value);
    }

    selectedDrugInfo(val) {
        this.isInputFocused = false;
        if (val && val !== " ") {
            this.SearchDrug = false;
            this.selectedDrug = val;
            this.DrugSelect = true;
            this.erxReq.controls["DrugId"].patchValue(this.selectedDrug["id"]);
            if (this.selectedDrug["DrugFullName"] && this.selectedDrug["DrugFullName"] !== " ") {
                this.erxReq.controls["DrugName"].patchValue(this.selectedDrug["DrugFullName"]);
            } else {
                this.erxReq.controls["DrugName"].patchValue(this.selectedDrug["drugname"] + " " + this.selectedDrug["drugformname"]
                    + " " + this.selectedDrug["strength"]);
            }
        } else {
            this.SearchDrug = true;
            this.DrugSelect = false;
            setTimeout(() => {
                this.isInputFocused = true;
            }, 10);
        }
    }

    focusOnTab(event, id, type) {
        if (event.which === 9) {
            event.preventDefault();
            if (type === "select") {
                const data: any = document.getElementById(id).getElementsByTagName("input")[0];
                if (id === "RxSig" && this.ErxChangeReqFG.value["RxChangeReason"]) {
                    data.focus();
                } else if (id !== "RxSig") {
                    data.focus();
                }
            } else if (type === "input") {
                if (id === "erxReqQty") {
                    this.DrugSelect = false;
                }
                const data: any = document.getElementById(id);
                data.focus();
            } else if (type === "drug") {
                if (this.enableDrugSearch) {
                    if (this.isInputFocused === true) {
                        this.isInputFocused = false;
                    }
                    setTimeout(() => {
                        this.isInputFocused = true;
                        this._cdr.detectChanges();
                    }, 20);
                } else {
                    this.isInputFocused = true;
                    setTimeout(() => {
                        this.isInputFocused = false;
                    }, 20);
                    document.getElementById(id).focus();
                }
                this.DrugSelect = true;
            } else if (type === "OutSig") {
                if (!this.selectedDrug || !this.enableDrugSearch) {
                    setTimeout(() => {
                    document.getElementById("durAdd").focus();
                    }, 20);
                } else if (this.selectedDrug && this.enableDrugSearch) {
                    setTimeout(() => {
                    document.getElementById("addtogrid").focus();
                    }, 20);
                }
            } else if (type === "textArea") {
                this.textareaFocus = true;
            } else if (type === "OutText") {
                this.textareaFocus = false;
                setTimeout(() => {
                document.getElementById("sendReq").focus();
                }, 20);
            }
        }
    }


    setNumberFormat(name: string) {
        this.erxReq.controls[name].patchValue(
            Number(this.erxReq.value[name]).toFixed(3)
        );
    }

    saveDrugFields() {
        this.formSubmitted = true;
        if (!this.DrugMissingForm.valid) {
            return;
        }
        let postData = this.DrugMissingForm.value;
        postData["IsUnitDrug"] = false;
        postData["IsMedGuide"] = false;
        // if (this.erxReq.valid) {
        //     postData["DrugId"] = this.erxReq.value["DrugId"];
        // }
        this._erxServ.saveMissingFields(postData)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                if (this.modal) {
                    this.modal.close();
                }
                // this.addDrugValueToList();
                this.formSubmitted = false;
                if (this.processingMissingDrug) {
                    this.checkEachCompMissingFields();
                } else {
                    this.checkCompoundDrugMissingFields();
                }
            }
        });
    }

    getDropdownsFormissing() {
        this._erxServ.getErxDropdowns()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.dropdownData = resp;
            }
        });
    }

    // AddDrugToGrid() {
    //     if (this.erxReq.valid && this.selectedDrug) {
    //         this._erxServ.checkDrugValues(this.erxReq.value["DrugId"]).subscribe(resp => {
    //             if (!resp) {
    //                 this.getDropdownsFormissing();
    //                 this.modal = this._modalService.open(this.MissingFields, {
    //                     keyboard: false,
    //                     centered: true
    //                 });
    //             } else {
    //                 this.addDrugValueToList();
    //             }
    //         });
    //     } else {
    //         this.drugformGroupInvalid = true;
    //     }
    // }

    async AddDrugToGrid() {
        if (this.erxReq.valid && this.selectedDrug) {
            const serviceStack = [];
            serviceStack.push(this._erxServ.checkDrugValues(this.erxReq.value["DrugId"]));
            if (this.erxReq.value["SigCode"] != null && this.erxReq.value["Sig"] == null) {
                serviceStack.push(this.getSigCodeInfo(this.erxReq.value["SigCode"]));
            }

            const resp = await forkJoin(serviceStack).toPromise();
            if (resp[0]) {
                this.getDropdownsFormissing();
                this.patchMissingFields(resp[0]);
                if (this.selectedDrug.drugtypeid === 2) {
                    this.showDrugDescription = true;
                }
                this.formSubmitted = false;
                if (this.DrugMissingForm && (!this.DrugMissingForm.value.PotencyUnitCodeId || (this.selectedDrug.drugtypeid === 2 && !this.DrugMissingForm.value.DoseFrmCodeId))) {
                    this.modal = this._modalService.open(this.MissingFields, {
                        keyboard: false,
                        centered: true
                    });
                } else {
                    this.checkCompoundDrugMissingFields();
                }

            } else {
                this.checkCompoundDrugMissingFields();
            }
        }

    }

    patchMissingFields(patchValues) {
        this.DrugMissingForm.reset();
        this.DrugMissingForm.controls.DoseFrmCodeId.clearValidators();
        this.DrugMissingForm.patchValue(patchValues);
    }


    checkCompoundDrugMissingFields() {
        if (this.selectedDrug.drugtypeid === 2) {
            this._erxServ.checkCompoundDrugValues(this.selectedDrug.id, 0)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
                if (response) {
                    if (!this.dropdownData) {
                        this.getDropdownsFormissing();
                    }
                    this.showDrugDescription = false;
                    this.drugIngredientsList = response;
                    this.checkEachCompMissingFields();
                } else {
                    this.addDrugValueToList();
                }
            });
        } else {
            this.addDrugValueToList();
        }
    }

    checkEachCompMissingFields() {
        if (this.drugIngredientsList.length > 0) {
            this.processingMissingDrug = this.drugIngredientsList.shift();
            this.patchMissingFields(this.processingMissingDrug);
            this.formSubmitted = false;
            // this.openModal(this.MissingFields);
            if (this.DrugMissingForm && !this.DrugMissingForm.value.PotencyUnitCodeId) {
                this.modal = this._modalService.open(this.MissingFields, {
                    keyboard: false,
                    centered: true
                });
            } else {
                this.addDrugValueToList();
            }
        } else {
            this.addDrugValueToList();
        }
    }

    addDrugValueToList() {
        this.addDrugValue.push(this.erxReq.value);
        this.generateList(this.addDrugValue);
        this.erxReq.reset();
        this.erxReq.markAsUntouched();
        this.SearchDrug = true;
        this.selectedDrug = null;
        this.drugformGroupInvalid = false;
        this.isInputFocused = false;
        setTimeout(() => {
            this.isInputFocused = true;
        }, 10);
        //this.erxReq.controls["Days"].patchValue(0);
        this.erxReq.controls["Refills"].patchValue(0);
        this.erxReq.controls["Daw"].patchValue("N");
        this.erxReq.controls["Qty"].patchValue("0.000");
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    init1(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    generateList(data: any) {
        // if (data.length && data.length > 0) {
            this.drugListWJ = new CollectionView(
                data.map((Drug, i) => {
                    const j = {};
                    j["RowId"] = i;
                    j["Drug Name"] = Drug.DrugName,
                    j["Days"] = Drug.Days;
                    j["Qty"] = Drug.Qty;
                    j["Refills"] = Drug.Refills;
                    j["DAW"] = Drug.Daw;
                    j["Sig"] = Drug.Sig;
                    return j;
                })
            );
        // }
    }

    deleteSelectedId(value) {
        this.addDrugValue.splice(value["RowId"], 1);
        this.generateList(this.addDrugValue);
    }

    addPopUp() {
        this.formGroupInvalid = false;
        this.editMode = false;
        this.DurFG.reset();
        this.DurFG.markAsTouched();
        this.DurFG.markAsUntouched();
        this.modal = this._modalService.open(this.DURADD, {
            centered: true,
            size: "lg"
        });
    }
    checkDurFgHasValue() {
        const cntrlData = ["ServiceReasonCode", "ProfessionalServiceCode", "ServiceResultCode", "LevelOfEffort", "ClinicalSignificanceCode", "CoAgentID", "CoAgentIdType"];
        let hasValue = false;
        cntrlData.map(cntrl => {
            if (this.DurFG.value[cntrl]) {
                hasValue = true;
            }
        });
        return hasValue;
    }
    async addNewDUR() {
        await this._formValid.blurAndDelay();
        if (this.DurFG.valid) {
            if (!this.checkDurFgHasValue()) {
                this._alertService.error(MsgConfig.ADD_ATLEAST_ONE_RECORD);
            } else {
                this.totalCount = this.newRxDur.length + 1;
                this.newRxDur.push(this.DurFG.value);
                if (this.modal) {
                    this.modal.close();
                }
                this.getNEWDUR();
            }
        } else {
            this.formGroupInvalid = true;
        }
    }

    getNEWDUR() {
        this.generateWJ(this.newRxDur);
    }

    generateWJ(info: any) {
        if (info) {
            this.DURWJ = new CollectionView(
                info.map((dur, i) => {
                    const j = {};
                    j["Clinical Signif.Code"] = dur.ClinicalSignificanceCode;
                    j["Agent Id"] = dur.CoAgentIdType;
                    j["Level"] = dur.LevelOfEffort;
                    j["Professional Service"] = dur.ProfessionalServiceCode;
                    j["Reason of Service"] = dur.ServiceReasonCode;
                    j["Result of Service"] = dur.ServiceResultCode;
                    j["Id Q"] = dur.CoAgentID;
                    j["PH-Comment"] = dur.AcknowledgementReason;
                    j["Actions"] = "";
                    j["value"] = i;
                    j["Id"] = dur.Id;
                    return j;
                })
            );
        }
    }

    editNewRxPopUpModal(content, val, nu) {
        this.editMode = true;
        this.editIndex = nu;
        for (const key in this.DurFG.value) {
            if (key) {
                this.DurFG.controls[key].patchValue(this.newRxDur[nu][key]);
            }
        }
        this.modal = this._modalService.open(content, {
            size: "lg",
            centered: true
        });
    }

    async editNewRxDUR() {
        await this._formValid.blurAndDelay();
        if (this.DurFG.valid) {
            this.totalCount = this.newRxDur.length;
            this.newRxDur[this.editIndex] = this.DurFG.value;
            if (this.modal) {
                this.modal.close();
            }
            this.getNEWDUR();
        } else {
            this.formGroupInvalid = true;
        }
    }

    deleteNewRxDur() {
        this.newRxDur.splice(this.deleteIndex, 1);
        this.totalCount = this.newRxDur.length;
        this.getNEWDUR();
    }

    openNewRxDeleteModal(content, val) {
        this.deleteIndex = val;
        this._modalService.open(content, {
            centered: true,
            windowClass: "large--content"
        });
    }

    openDiagList(type) {
        this.diagListType = type;
        this.model = this._modalService.open(this.diagList, {
            size: "lg",
            windowClass: "modal-center-to-page"
        });
    }

    closeDiagList(value) {
        if (this.diagListType === "1") {
            this.PrimaryDiagnosis1.controls["DiagnosisCode"].patchValue(value["Code"]);
            this.PrimaryDiagnosis1.controls["DiagnosisDescription"].patchValue(value["Diagnosis Description"]);
        } else if (this.diagListType === "2") {
            this.PrimaryDiagnosis2.controls["DiagnosisCode"].patchValue(value["Code"]);
            this.PrimaryDiagnosis2.controls["DiagnosisDescription"].patchValue(value["Diagnosis Description"]);
        } else if (this.diagListType === "3") {
            this.SecondaryDiagnosis1.controls["DiagnosisCode"].patchValue(value["Code"]);
            this.SecondaryDiagnosis1.controls["DiagnosisDescription"].patchValue(value["Diagnosis Description"]);
        } else if (this.diagListType === "4") {
            this.SecondaryDiagnosis2.controls["DiagnosisCode"].patchValue(value["Code"]);
            this.SecondaryDiagnosis2.controls["DiagnosisDescription"].patchValue(value["Diagnosis Description"]);
        }
        if (this.model) {
            this.model.close();
        }
    }

    changeSPIADD(value) {
        if (value && value.value) {
            if (value.value["SPINo"]) {
                this.erxDetails.Prescriber.prescriberspinum = value.value["SPINo"];
            }
            if ((value.value["AddressLine1"] || value.value["StateName"])) {
                    this.PrescAddress = value.value;
                    this.typedValue = false;
                } else {
                    this.typedValue = true;
            }
            this.checkServiceLevel(value.value);
        }
    }




    generateFA(key: string, values: any[]) {
        if (values) {
            const extraCostFGs = values.map(val => this._fb.group(val));
            const extraCostFormArray = this._fb.array(extraCostFGs);
            this.ErxChangeReqFG.setControl(key, extraCostFormArray);
        }
    }

    openBillToPopupModal() {
        this.insureList = _.clone(this.patInsurList);
        if (this.insureList && this.insureList.length > 0) {
            const index = this.insureList.findIndex(insu => insu.IsCash);
            this.insureList.splice(index, 1);
        }
        this.insurncListModified = new CollectionView(this.insureList);
        if (this.insureList && this.insureList.length > 0) {
            this.ErxChangeReqFG.controls["InsuranceId"].setValue(this.insureList[0].insuranceid);
        }
        this.modelRef = this._modalService.open(this.BILLTO, {
            size: "lg",
            centered : true
        });
        this._cdr.detectChanges();
    }

    selectedInsurence(isChecked: boolean, checkedVal: any, fromInput?: any) {
        if (isChecked) {
            if (!fromInput) {
                this.ErxChangeReqFG.controls["InsuranceId"].setValue(checkedVal);
            } else {
                this.changedInsId = checkedVal
            }
        }
    }

    closeBillModal(fromOkBtn?: any) {
        if (fromOkBtn && this.changedInsId) {
            this.ErxChangeReqFG.controls["InsuranceId"].setValue(this.changedInsId);
        }
        if (this.modelRef) {
            this.modelRef.close();
        }
    }

    checkSPIServiceLevel() {
        if (!this.patientSearchValue &&  this.selectedBenefitCheckType === "paReq") {
            this._alertService.error("For Prior Authorization request, Patient Selection Required.");
        } else if (!this.ErxChangeReqFG.valid) {
            this._formValid.validateAllFormFields(this.ErxChangeReqFG);
            this._alertService.error("Please select mandatory fields.");
        } else {
            if (!this.typedValue) {
                this.SPIDetails.map(val => {
                    if (val["SPINo"] === this.erxDetails.Prescriber.prescriberspinum) {
                        if (val["ServiceLevel"] && (val["ServiceLevel"].trim() === "1" || val["ServiceLevel"].toLowerCase().trim() === "y")) {
                            if (val["ServiceLevelName"].includes("Change")) {
                                this.canDoReq = true;
                            }
                        }
                    }
                });
                if (!this.canDoReq) {
                    this.modal = this._modalService.open(this.canDoRxChange, {
                                        centered: true,
                                        keyboard: false,
                                        windowClass: "large--content"
                                    });
                } else {
                    this.sendErxChangeReq();
                }
            } else {
                this.sendErxChangeReq();
            }
        }
    }

    sendErxChangeReq() {
        this.ErxChangeReqFG.controls["SPINum"].patchValue(this.erxDetails["Prescriber"]["prescriberspinum"]);
        if (!this.ErxChangeReqFG.controls["SPINum"].value && this.ErxChangeReqFG.controls["SPINum"].value === " ") {
            this._alertService.error(
                "SPI No is Missing",
                false,
                "long"
            );
            const data: any = document.getElementById("RxSig").getElementsByTagName("input")[0];
            data.doucs();
        } else {
            this.ErxChangeReqFG.controls["IsTypedValue"].patchValue(this.typedValue);
            if (this.newRxDur && this.newRxDur.length > 0) {
                this.generateFA("Dur", this.newRxDur);
            }
            if (this.addDrugValue && this.addDrugValue.length > 0) {
                this.generateFA("AlternativeDrugs", this.addDrugValue);
            } else {
                this.ErxChangeReqFG.controls["Daw"].patchValue(this.erxReq.value["Daw"]);
                this.ErxChangeReqFG.controls["DrugId"].patchValue(this.erxReq.value["DrugId"]);
                this.ErxChangeReqFG.controls["currentRefillNo"].patchValue(Number(this.erxReq.value["Refills"]));
                this.ErxChangeReqFG.controls["SigDesc"].patchValue(this.erxReq.value["Sig"]);
                this.ErxChangeReqFG.controls["Quantity"].patchValue(Number(this.erxReq.value["Qty"]));
                this.ErxChangeReqFG.controls["SupplyDays"].patchValue(Number(this.erxReq.value["Days"]));
            }
            if (this.PrimaryDiagnosis1.value["DiagnosisCode"]) {
                this.PrimaryDiagnosis1.controls["Qualifier"].patchValue("ABF");
            }
            if (this.PrimaryDiagnosis2.value["DiagnosisCode"]) {
                this.PrimaryDiagnosis2.controls["Qualifier"].patchValue("ABF");
            }
            if (this.SecondaryDiagnosis1.value["DiagnosisCode"]) {
                this.SecondaryDiagnosis1.controls["Qualifier"].patchValue("ABF");
            }
            if (this.SecondaryDiagnosis2.value["DiagnosisCode"]) {
                this.SecondaryDiagnosis2.controls["Qualifier"].patchValue("ABF");
            }

            if (!this.ErxChangeReqFG.value["DrugId"]) {
                this.ErxChangeReqFG.controls["DrugId"].patchValue(this.erxDetails["Prescription"]["DrugId"]);
            }
            if (this.ErxChangeReqFG.value["RxChangeReason"] === "DUR(DRUG UTILIZATION REVIEW)"
            && !(this.newRxDur && this.newRxDur.length > 0)) {
                this._alertService.error("Add DUR values.", false, "long");
            } else if (!(this.erxDetails.Prescriber.telephone) || (this.erxDetails.Prescriber.telephone &&
                this.erxDetails.Prescriber.telephone.length < 10 && this.routeFrom === "er" )) {
                this._alertService.error("Prescriber phone must be greater than or equals to 10 digits.");
            } else {
                const Reqdata = this.erxDetails.RxChangeRequestTypesList.find(val =>
                    (val["RequestDescription"] === this.ErxChangeReqFG.value["RxChangeReason"])
                 );
                this.ErxChangeReqFG.value["RxChangeCategory"] = Reqdata ? Reqdata["RequestCategoryCode"] : null;
                if (this.erxData) {
                    this.ErxChangeReqFG.value["erxId"] = this.erxData["erxId"];
                }
                this._erxServ.changeRequest(this.ErxChangeReqFG.value,this.eRxVersion)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp === "010" || resp.trim() === "" ) {
                        this._alertService.success(MsgConfig.ERX_CHANGE_REQ_SUCCESS);
                        this.mainPopUpClosed();
                    }  else if ( resp.trim() === "000") {
                        this._alertService.success(MsgConfig.ERX_CHANGE_REQ_SUBMITTED);
                        this.mainPopUpClosed();
                    }  else if (resp) {
                        this._alertService.error(resp, false, "long");
                    } else {
                        this._alertService.error("eRx Change request failed.", false, "long");
                        this.IsPopUpClosed.emit(null);
                    }
                });
            }
        }
    }

    refreshSpi() {
        this._erxServ.refreshSpi(this.erxDetails.Prescriber.prescribernpinum, this.erxDetails.Prescriber.prescriberid, this.eRxVersion)
        .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    if (this.modelRefForScreen) {
                        this.modelRefForScreen.close();
                    }
                    this.getErxDetails();
                }
        });
    }

    mainPopUpClosed() {
        if (this.modelRefForScreen) {
            this.modelRefForScreen.close();
        }
        this.IsPopUpClosed.emit({activeModal : "ErxChangeCompl" , selectedBenefitCheckType: this.selectedBenefitCheckType});
    }

    checkServiceLevel(val) {
        if (val["ServiceLevel"] && (val["ServiceLevel"].trim() === "1" || val["ServiceLevel"].toLowerCase().trim() === "y")) {
            if (val["ServiceLevelName"].includes("Change")) {
                return true;
            } else return false;
        } else return false;
    }

}
