import { constant } from "./../app.constants";
import { Observable } from "rxjs/observable";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({
    providedIn: "root"
})
export class SigFileService {
    constructor(private _http: HttpClient) {}

    sendSigFileFilters(sigInfo: any): Observable<any> {
        return this._http.post<any>(constant.POST_SigFile_Filters, sigInfo, reqOptns);
    }
    getSigCodeById(sigId: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_SigFile_ById.replace("{sigId}", "" + sigId)
        );
    }

    addSigFile(sigInfo: any): Observable<any> {
        return this._http.post<any>(constant.POST_SigFile_Add, sigInfo);
    }

    updateSigFile(sigInfo: any): Observable<any> {
        return this._http.put<any>(constant.PUT_SigFile, sigInfo);
    }

    deleteSigFile(payload): Observable<any> {
        return this._http.post<any>(
            constant.DELETE_SigFile, payload);
    }

    deleteSigResponse(data): Observable<any> {
        return this._http.post<any>(constant.POST_SigCode_Response, data);
    }
}
