import { AlertService, CommonService } from "src/app/services";
import {
    Component,
    OnInit,
    OnChanges,
    Output,
    EventEmitter,
    Input
} from "@angular/core";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { AccCategoryE, EditRxE, RefillRxE, RxEntryE } from "src/app/models";
import { PrivMaskGuard } from "src/app/guards";
import { MsgConfig } from "src/app/app.messages";

@Component({
    selector: "app-upload",
    templateUrl: "./upload.component.html"
})
export class UploadComponent implements OnInit, OnChanges {
    nameFG: any;
    nameControlName: any;
    nameRequired=false;
    enableScanner: boolean;
    @Input() set NameFG(pi: any) {
        this.nameFG = pi;
    }

    @Input() set NameControlName(pi: any) {
        this.nameControlName = pi;
    }
    @Input() set NameRequired(pi: any) {
        this.nameRequired = pi;
    }

    @Input() showScanner: any;
    @Input() rxType: any;
    @Input() alignStart: boolean;
    @Input() disableClick: boolean;
    @Output()
    UploadedFile = new EventEmitter();

    constructor(private _validateUtils:ValidateFormFieldsUtils,private _commonServ:CommonService,private _privMask: PrivMaskGuard,private _alertSvc: AlertService) {}

    ngOnInit() {
        this.enableScanner = true;
    }

    ngOnChanges() { }

    handleFileInput(files: FileList) {
        for(let i=0;i<files.length;i++) {
            let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
            if (allowedExtensions.test(files[i].name)) {
                this.UploadedFile.emit(files[i]);
            }
        }
    }

    checkForTitle(uploadDocFile) {
        console.log(this.rxType)
        if (this.rxType == "nr" && !this._privMask.canActivate(AccCategoryE.RxEntry, RxEntryE.AttachDocumentToRx)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        } else if (this.rxType == "er" && !this._privMask.canActivate(AccCategoryE.EditRx, EditRxE.AttachDocumentToRx)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        } else if (this.rxType == "rf" && !this._privMask.canActivate(AccCategoryE.RefillRx, RefillRxE.AttachDocumentToRx)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        } else if (this.nameRequired) {
            if (this.nameFG.valid) {
                uploadDocFile.click();
            } else {
                this._validateUtils.validateAllFormFields(this.nameFG);
            }
        } else {
            uploadDocFile.click();
        }
    }


}

