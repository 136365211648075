<div class="editpatient--body newrx--body edit--drug">
        <div class="exprx--common-block">
            <div class="eprx--block__header">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-10">
                                <div class="eprx--header__heading">
                                    <span>Diagnosis List</span>
                                </div>
                            </div>
                            <div class="col-2 text-right">
                                <app-user-guide-icon [ActiveTabId]= "'DiagnosisFile'"></app-user-guide-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="eprx--block__content">
                <div class="col-sm-12 padding-0 erx--body">
                    <div class="row">

                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <eprx-input [LabelText]="'Diagnosis Code'" [RxSelectId]="'DiagCode'" [AutoFocus]="true" [PlaceHolder]="'Search Diagnosis Code'" [ControlName]="'Code'" [FormGroupName]="drugDiaFG" (TriggerOnEnterValue)="filter()">
                            </eprx-input>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <eprx-input [LabelText]="'Diagnosis Description'" [PlaceHolder]="'Search Diagnosis Description'" [ControlName]="'Description'" [FormGroupName]="drugDiaFG" (TriggerOnEnterValue)="filter()">
                            </eprx-input>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 align-button allign-bottom text-right">
                            <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" *ngIf = "loadResp" (click)="filter()"  appShortcutKey [AltKey]="'f'">
                                <span>F</span>
                                Search
                            </button>
                            <button type="submit" class="inactive" *ngIf = "!loadResp" id="Preview" title="Search / Refresh" appShortcutKey [AltKey]="'f'">
                                <span>F</span>
                                Search
                          </button>
                            <button type="button" class="hotkey_success pull-right" (click)="clearSearchData()" appShortcutKey [AltKey]="'l'"><span>L</span> Clear
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="loader" class="progress-bar-container" *ngIf="!isPopup">
                <div class="progress-bar--common" [style.width]="progressValue + '%'"></div>
            </div>
            <div class="eprx--block__content" *ngIf="displayGrid" [ngClass]="loading ? 'mt-0' : ''">
                <wj-flex-grid #diagnosislist  [headersVisibility]="'Column'" [itemsSource]="disWarnWJ" [isReadOnly]="true" [columnPicker]="'diagnosisListWJ'"
                    [selectionMode]="'Row'" (updatedView)="init(diagnosislist)" (keydown.ctrl.c)="$event.preventDefault()"
                    (dblclick)="onRowDblclicked(diagnosislist, $event)" (keydown.enter)="onRowDblclicked(diagnosislist, $event)"  [ngClass]="[isPopup ? 'wjm-grd-custom-height-46' : 'wjm-grd-custom-height-66', !totalCount ? 'no-data' : '']">
                    <wj-flex-grid-column [header]="rxheader.hName" [binding]="rxheader.hName"
                    [visible]="rxheader['isVisible']" [width]="rxheader['width']" *ngFor="let rxheader of wjHeaders; let i = index">
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                </wj-flex-grid>
                <div class="row" *ngIf="totalCount">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <app-eprx-pagination [TotalCount]="totalCount" (OnFocusOut)="focusToFirst($event)" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [GridName]="'diagnosislistfile'"
                        [WijmoName]="diagnosislist" [PageNumber]="pageNu" [FromModal]="false"  [PageSize]="Size"></app-eprx-pagination>
                    </div>
                </div>
            </div>

        </div>
    </div>

