<div class="exprx--common-block erx--body drug-verify-body">
    <div class="eprx--block__header">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-11">
                        <div class="eprx--header__heading">
                            <div class="col-md-12 padding-0"><span> {{isRxVerification? "Rx Verification" : "Rph Verification"}}</span></div>
                        </div>
                    </div>
                    <div class="col-1 padding_left_7rem">
                        <app-user-guide-icon [ActiveTabId]="isRxVerification ? 'RxVerification' : 'RphVerification'"></app-user-guide-icon>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="eprx--block__content">
        <div class="col-sm-12 padding-0">
            <div class="row">
                <div class="col-lg-12 pr-0" >
                    <form [formGroup]="rphFilterFG" (ngSubmit)="getRphData()">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class="col" *ngIf="!isRxVerification">
                                    <eprx-select
                                        [LabelText]="'Verification Stage'"
                                        [PlaceHolder]="'Verification Stage'"
                                        [Title]="'Verification Stage'"
                                        [ControlName]="'VerifyStage'"
                                        [FormGroupName]="rphFilterFG"
                                        [HasMultiple]="false"
                                        [HasBindLabel2]="false"
                                        [BindLabel]="'status'"
                                        (TriggerSelectValue)="getRphData()"
                                        [BindValue]="'value'"
                                        [LabelForId]="'Name'"
                                        [List]="verifyStage"
                                    >
                                    </eprx-select>
                                </div>
                                <div class="col" >
                                    <eprx-select
                                        [LabelText]="'Verification Status'"
                                        [PlaceHolder]="'Verification Status'"
                                        [Title]="'Verification Status'"
                                        [ControlName]="'VerifyStatus'"
                                        [FormGroupName]="rphFilterFG"
                                        [HasMultiple]="false"
                                        [HasBindLabel2]="false"
                                        (TriggerSelectValue)="getRphData()"
                                        [BindLabel]="'status'"
                                        [BindValue]="'value'"
                                        [LabelForId]="'Name'"
                                        [List]="verifyStatus"
                                    >
                                    </eprx-select>
                                </div>
                                <div class="col">
                                    <eprx-date-picker
                                        [LabelText]="'Fill Date From'"
                                        [PlaceHolder]="''"
                                        [ControlName]="'FromDate'"
                                        [FormGroupName]="rphFilterFG"
                                        [MinDate]=""
                                        [MaxDate]="minDate"
                                        [IsDisabled]="false"

                                        [ErrorDefs]="{required: 'Required'}"
                                        [MarkAsTouched]="rphFilterFG?.get('FromDate')?.touched"
                                    >
                                    </eprx-date-picker>
                                </div>
                                <div class="col">
                                    <eprx-date-picker
                                        [LabelText]="'To Date'"
                                        [PlaceHolder]="''"
                                        [ControlName]="'ToDate'"
                                        [FormGroupName]="rphFilterFG"
                                        [MinDate]=""
                                        [MaxDate]="minDate"
                                        [IsDisabled]="false"
                                        [ErrorDefs]="{required: 'Required'}"
                                        [MarkAsTouched]="rphFilterFG?.get('ToDate')?.touched"
                                    >
                                    </eprx-date-picker>
                                </div>
                                <div class="col-lg-4" *ngIf="isRxVerification">
                                    <eprx-radio
                                        [LabelText]="'Filter By'"
                                        [ControlName]="'PharmacyFilter'"
                                        [FormGroupName]="rphFilterFG"
                                        [List]="[
                                            'All',
                                            'Refills Only',
                                            'Original Only',
                                            'Control Drugs Only'
                                        ]"
                                        [ValueList]="[
                                            null,
                                            'rdbRefillOnly',
                                            'rdbOriginalOnly',
                                            'rdbControlDrugOnly'
                                        ]"
                                        [IDForList]="[20, 21, 22, 23]"
                                        [LabelForId]=""
                                        (TriggerSelectValue)="getRphData()"
                                    >
                                    </eprx-radio>
                                </div>
                                <div class="col"  *ngIf="!isRxVerification">
                                    <eprx-select  [LabelText]="'Rx Received By'" [ControlName]="'UserId'" [FormGroupName]="rphFilterFG" [PlaceHolder]="''"
                                        [BindLabel]="'UserId'" [BindLabel2]="'Name'" [BindLabel3]="'Role'" [LabelTitle1]="'USER ID'"
                                        [LabelTitle2]="'NAME'" [LabelTitle3]="'ROLE'" [BindValue]="'AppUserId'" [LabelForId]="'AppUserId'"
                                        [List]="appUsrData$ | async" [hasBindLabel3]="true" [MarkAsTouched]="rphFilterFG?.get('UserId')?.touched"></eprx-select>
                                </div>

                                <!-- <div class="col">
                                    <div>
                                        <div class="padding-0">
                                            <label
                                                class="control-label col-sm-12 padding-0 insurance--body"
                                            >
                                                <span>User</span>
                                                <input
                                                    type="text"
                                                    name="searchBoxUser"
                                                    class="form-control search--input__box"
                                                    placeholder="Search User"
                                                    [ngbTypeahead]="searchUsers"
                                                    [resultTemplate]="rt"
                                                    [value]="rphVerifyFilters?.UserId ? rphVerifyFilters?.UserId : ''"
                                                    [inputFormatter]="Usersformatter"
                                                    (selectItem)="optedUserValue($event)"
                                                    KeyUpDown
                                                />
                                            </label>
                                        </div>
                                        <ng-template #rt let-r="result" let-t="term">
                                            <div class="card patient-card" *ngIf="r">
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="row">
                                                            <div class="card__subTit--head">
                                                                UserName:
                                                            </div>
                                                            <div class="card__subTit--title">
                                                                {{ r?.UserName }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="row">
                                                            <div class="card__subTit--head">
                                                                FullName :
                                                            </div>
                                                            <div class="card__subTit--title">
                                                                {{ r?.FullName }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div> -->
                                <div class="col text-right pt-3 pr-5">
                                    <!-- <button title="Search / Refresh" type="button" class="hotkey_success pull-right" (click)="getRphData()">
                                        <span>F</span> Search
                                    </button> -->
                                    <button title="Search / Refresh" type="button" class="hotkey_success pull-right" *ngIf = "patientResp" (click)="getRphData()"  appShortcutKey [AltKey]="'f'">
                                        <span>F</span>Search</button>
                                    <button class="inactive" *ngIf = "!patientResp" title="Search / Refresh" appShortcutKey [AltKey]="'f'">
                                            <span>F</span>
                                            Search
                                    </button>
                                    <button type="button" class="hotkey_success pull-right" (click)="reset()" (keydown.tab)="focusOutFrmCancel($event)">
                                        <span>L</span> Clear
                                    </button>
                                </div>
                            </div>
                            <div class="row insurance--body">
                                <div class="col-3"  *ngIf="!isRxVerification"
                                    style="bottom: 0.3rem"
                                >
                                    <label>Patient</label>
                                    <div class="seconday-search--grid">
                                        <app-searchbox-global
                                            [SearchFor]="'Patient'"
                                            [IsAddNew]="false"
                                            [DisableSerchFil]="true"
                                            class="common_global_search_max_content_display"
                                            [DisableRadioButtons]="true"
                                            (SelectedPatient)="selectPatient($event)"
                                            [InputValue]="selectedPatient"
                                            [ClearInputValue]="patientSearchInputValue"
                                        ></app-searchbox-global>
                                    </div>
                                </div>
                                <div class="col-lg-2 messsage-height" *ngIf="!isRxVerification">
                                    <label class="col-md-12 padding-0 ">
                                        Major and Severe DDI only</label
                                    >
                                    <eprx-radio
                                        [ControlName]="'DDIOnly'"
                                        [FormGroupName]="rphFilterFG"
                                        [List]="['Yes', 'No']"
                                        [ValueList]="[true, false]"
                                        [IDForList]="[5, 6]"
                                        (TriggerSelectValue)="getRphData()"
                                        [LabelForId]=""
                                    >
                                    </eprx-radio>
                                </div>
                                <div class="col-lg-4" *ngIf="!isRxVerification">
                                    <eprx-radio
                                        [LabelText]="'Filter By'"
                                        [ControlName]="'PharmacyFilter'"
                                        [FormGroupName]="rphFilterFG"
                                        [List]="[
                                            'All',
                                            'Refills Only',
                                            'Original Only',
                                            'Control Drugs Only'
                                        ]"
                                        [ValueList]="[
                                            null,
                                            'rdbRefillOnly',
                                            'rdbOriginalOnly',
                                            'rdbControlDrugOnly'
                                        ]"
                                        [IDForList]="[20, 21, 22, 23]"
                                        [LabelForId]=""
                                        (TriggerSelectValue)="getRphData()"
                                    >
                                    </eprx-radio>
                                </div>
                                <div class="col-3">
                                    <div class="row" (click)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
                                        (keydown.enter)="$event.preventDefault()">
                                        <div class="col-7">
                                            <eprx-input [RxSelectId]="'RxNumber'" [LabelText]="'Rx#'" [HasControl]="false" [AutoFocus]="true" [InputValue]="directRxNumber"
                                             (TriggerChangeValue)="optedRxNumber($event)" (TriggerOnEnterValue)="verifyWithRxNumber()" >
                                            </eprx-input>
                                        </div>
                                        <div  class="col-5" style="margin-top: 1rem">
                                            <button appShortcutKey [ngClass]="directRxNumber ? 'hotkey_success pull-right' : 'inactive pull-right'" [AltKey]="'e'" [title]="!directRxNumber ? 'Please enter Rx# to Verify' : ''"
                                                (click)="directRxNumber ? verifyWithRxNumber() : ''" (keydown.enter)="directRxNumber ? verifyWithRxNumber() : ''"><span>E</span>
                                                Verify
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                 </div>
                 <!-- <div class="col-lg-3" >
                    <div class="row">
                        <div class="col-lg-8 col-sm-3 col-xs-3 ">
                            <label class="control-label col-sm-12 padding-0">
                                <span> Rx Number# </span>
                                <input
                                    autofocus
                                    type="text"
                                    class="form-control search--input__box"
                                    (blur)="optedRxNumber($event.target.value)"
                                    [id]="'RxNumber'"
                                />
                            </label>
                        </div>
                        <div  class="col-lg-4 col-md-2 col-sm-2 col-xs-2"
                            style="margin-top: 1rem"
                        >
                            <button
                                class="hotkey_success pull-right"
                                (click)="verifyWithRxNumber()"
                            >
                                Verify
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12  text-right"
                        >
                            <button
                                type="submit"
                                class="hotkey_success pull-right"
                                (click)="getRphData()"
                            >
                                <span>S</span> Search
                            </button>
                            <button
                                type="button"
                                class="hotkey_success pull-right"
                                (click)="reset()"
                                (keydown.tab)="focusOutFrmCancel($event)"
                            >
                                <span>C</span> Reset
                            </button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div id="loader" class="progress-bar-container" >
        <div class="progress-bar--common" [style.width]="progressValue + '%'"></div>
      </div>
        <div class="eprx--block__content pt-1" id="rph_VerificatioGrid" *ngIf="displayGrid" [ngClass]="loading ? 'mt-0' : ''">
            <div class="row mt-0">
                <div class="col-12 text-right" *ngIf="totalCount > 0">
                    <button  class="submit print-med-guide" style="background: rgb(247, 148,29)" (click)="toggleColorLegend()">Color Legend</button>
                </div>
            </div>
            <wj-flex-grid #RPHVerifylist  [headersVisibility]="'Column'" [ngClass]="[isRxVerification ? 'wjm-grd-custom-height-51' : 'wjm-grd-custom-height-51', !totalCount ? 'no-data':'']"
                    [itemsSource]="rphListingWJ" [itemFormatter]="itemFormatter" [isReadOnly]="true" [columnPicker]="isRxVerification ? 'rxVerfWJ' : 'rphVerfWJ'"
                    [selectionMode]="'Row'" [allowSorting]="true" (updatedView)="init(RPHVerifylist)" (initialized)="init(RPHVerifylist)"
                    (selectionChanged)="selectRadioOnKeyEvents(RPHVerifylist)">
                    <wj-flex-grid-column [header]="'Select'" [width]="50">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <input type="radio" [id]="item.data.Prescription.PrescNum + 'i' + item['row']" (change)="
                                                                    selectedRx(
                                                                        $event?.target?.checked,
                                                                        item.data,
                                                                        item.row
                                                                    )
                                                                " value="{{ item.row }}" [checked]="
                                                                    totalCount > 1
                                                                        ? (item.data.Prescription.PrescNum ===
                                                                        val) && (item.data.PrescReFill.ReFillNum === val1) && (item.data.PrescReFill.PartialFillNo === val2)
                                                                        : 'true'
                                                                " />
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'Actions'" [width]="60">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <i class="far fa-edit actions" title="Edit" (click)="routeToEditRx(item)"></i>
                            <i class="far fa-history actions" title="Patient History" (click)="routeToPatientHistory(item)"></i>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                        [width]="rxheader['width']" *ngFor="let rxheader of wjHeaders; let i = index">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <div *ngIf="rxheader['hName'] === 'Verification Status'" [title] ="item[rxheader['hName']] | uppercase">
                                <span *ngIf="item[rxheader['hName']] === 'Re-verify'"
                                style="color: red;">{{item[rxheader['hName']]}}</span>
                                <span *ngIf="item[rxheader['hName']] !== 'Re-verify'">{{item[rxheader['hName']]}}</span>
                            </div>
                            <div *ngIf="(rxheader['hName'] === 'Patient Name' || rxheader['hName'] === 'Follow-Up Reason' ||
                            rxheader['hName'] === 'Prescriber Name')" [title] ="item[rxheader['hName']] | uppercase">
                            {{ item[rxheader['hName']] }}
                            </div>
                            <div *ngIf="!(rxheader['hName'] == 'Select' ||  rxheader['hName'] === 'Patient Name' || rxheader['hName'] === 'Follow-Up Reason' || rxheader['hName'] === 'Status' ||
                            rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Verification Status' ||
                            rxheader['hName'] === 'Prescriber Name' || rxheader['hName'] === 'Rx#')" [title] ="((item[rxheader['hName']] + '') | uppercase)">
                                {{ item[rxheader['hName']] }}
                            </div>
                            <div *ngIf = "(rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Status' || rxheader['hName'] === 'Rx#')" [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver'] : rxheader['hName'] === 'Rx#' ? item['RxHoverOver'] : item['StatusHoverOver']" [placement] = "(cell.col.index>=0 && cell.col.index < 3) ? 'right' : (cell.col.index>=3 && cell.col.index <17)?'top':'left'" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title"  container="body">
                                    {{item[rxheader['hName']]}}
                            </div>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                            <div title={{rxheader.hName}}>
                                {{ rxheader.hName }}
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
            </wj-flex-grid>
            <span>
                <app-eprx-pagination *ngIf="totalCount > 0" [TotalCount]="totalCount" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)"
                    [GridName]='isRxVerification ? "RxVerification" : "RphVerification"' (OnFocusOut)="focusToFirst($event)" [WijmoName]="flex"></app-eprx-pagination>
                <app-action-buttons *ngIf="totalCount > 0" [hidden]="!(totalCount !== 0)" [RphFilters]="rphFilterFG?.value" [RxInfo]="selectedRxInfo"  [IsRxVerification]="isRxVerification"
                    (RefreshGrid)="getGridRefresh($event)" [totalRecordsCount]="totalRecordCnt"></app-action-buttons>
            </span>
        </div>

</div>

<ng-template #AlreadyVerif let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header> Confirmation </h4>
        <span body>
            Rx#: <span class="font-weight-bold">{{rxInfo?.Prescription?.PrescNum}}</span> Refill#:
             <span class="font-weight-bold">{{rxInfo?.PrescReFill?.ReFillNum}}</span> Fill#:
             <span class="font-weight-bold">{{_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription)
                ? rxInfo?.PrescReFill?.PartialFillNo : " --"}}</span>
                <div><br></div>
                 This Rx has been {{isRxVerification ? 'Rx' : 'Rph'}} verified  by <span class="font-weight-bold">
                 {{verifiedAlready?.UserName ? (verifiedAlready?.UserName | uppercase) : '--' }}</span> on <span class="font-weight-bold">
            {{verifiedAlready.VerifDtTm ? (verifiedAlready.VerifDtTm | localTime | date:'MM/dd/yyyy'):'--'}}</span>. Would you wish to re-verify?
        </span>
        <button ngbAutofocus footer class="hotkey_primary"  (click)="c('Close click');checkIsDiscontinue();" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button footer class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b> No</button>
    </app-custom-modal>
</ng-template>

<ng-template #DiscontinuedRx let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span >Warning!!</span>
    </div>
    <div class="modal-body">
        <p>Rx is Discontinued. If you wish to continue the Rx will no more be marked as discontinued. Would you like to proceed?</p>
    </div>
    <div class="modal-footer" >
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
        (click)="setOrderStatus(); c('Close click')"><b>Y</b>Yes</button>
        <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
        (click)="c('Close click')"><b>N</b>No</button>
    </div>
</ng-template>
