import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MsgConfig } from 'src/app/app.messages';
import { PharmacyLabels } from 'src/app/models/labels.models';
import { CommonService, RxTransferService } from 'src/app/services';
import { FormatsUtil } from 'src/app/utils/formats.util';
import { PatientInfoUtil } from 'src/app/utils/patient-info.util';

@Component({
  selector: 'app-current-pharmacy',
  templateUrl: './current-pharmacy.component.html',
  styleUrls: ['./current-pharmacy.component.scss']
})
export class CurrentPharmacyComponent implements OnInit {
  requestPharmacyDetails: any  = {
  };
  pharmacistName: string = "";
  msgConfig;
  pharmacyabels: any;
  @Input() LabelName;
  @Output() RequestPharmacyDetails = new EventEmitter();
  constructor(private _commonServ:CommonService, 
              private _formatUtil:FormatsUtil,
              private _patientUtil: PatientInfoUtil,
              private _rxSer:RxTransferService) { }

  ngOnInit(): void {
    this.msgConfig = MsgConfig;
      this.getRequestPharmacy();
      this.pharmacyabels = new PharmacyLabels();
      this.pharmacistName = this._commonServ.DecryptData(localStorage.getItem("PharmacistName"));
  }
  async getRequestPharmacy() {
    const resp: any = await this._commonServ.getNewTenantinfoDetails().toPromise();
    if (resp?.PharmacyDetails) {
      resp.PharmacyDetails.TelephoneNumber = this._formatUtil.getPhoneFormat(resp.PharmacyDetails.Telephone);
      resp.PharmacyDetails.pharmAddress = this._patientUtil.getPatientAddress({
        address1: resp.PharmacyDetails.AddressLine1,
        address2: resp.PharmacyDetails.AddressLine2,
        state: resp.PharmacyDetails.StateName,
        zipcode: resp.PharmacyDetails.ZipCode,
        city: resp.PharmacyDetails.CityName,
      });
    }
      this.requestPharmacyDetails = resp.PharmacyDetails;
  }
}
