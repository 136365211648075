import { Injectable } from "@angular/core";
import { UserService, AlertService } from "../services";
import * as moment from "moment";
import * as _ from "lodash";
import { MsgConfig } from "../app.messages";
import {
    CanActivate,
    Router
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class BaseAccessChecker {
    constructor(
        private _userServ: UserService,
        private _router: Router,
        public _alertServ: AlertService
    ) {}
    sessionTimeout() {
        this._userServ._sessionTimeout$.next("Timeout");
    }
    getAccessToken() {
        return this._userServ.getAccessToken();
    }
    identifyPrivValue(category: number, catePrvis: any) {
        const authToken = this._userServ.getAccessToken();
        const userPrivs = this._userServ.getUserPrivsFromCookies();
        if (
            authToken &&
            authToken !== "undefined" &&
            authToken !== undefined &&
            userPrivs
        ) {
            if (moment().isAfter(authToken[".expires"])) {
                sessionStorage.removeItem("$1");
                this._router.navigate(["/login"]);
            }
            let hasPrivileges = false;
            const prevs = userPrivs;
            if (prevs && prevs.Roles) {
                const privRoles = _.values(prevs.Roles);
                privRoles.map(role => {
                    _.map(
                        _.filter(role.RoleCategoryPrivMask, {
                            CategoryId: category
                        }),
                        "PrivMask"
                    ).map(mask => {
                        if ((mask & catePrvis) === catePrvis) {
                            hasPrivileges = true;
                        }
                    });
                });
                if (hasPrivileges) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
        // this._alertServ.error(MsgConfig.SOMETHING_WENT_WRONG);
        // not logged in or unauthorized access, so redirect to login page
        // this._router.navigate(["/login"]);
        return false;
    }
}

// @Injectable()
// export class BaseAuthGuard extends BaseAccessChecker {
//     constructor(
//         _userServ: UserService,
//         _router: Router,
//         _alertServ: AlertService,
//         private alertServ?: AlertService
//     ) {
//         super(_userServ, _router, _alertServ);
//     }

//     validateAuth(category, privCategory) {
//         const reslt = super.identifyPrivValue(category, privCategory, true);
//         if (reslt) {
//             return reslt;
//         } else {
//             this._alertServ.error(MsgConfig.NO_PRIVS_ACCESS);
//             return reslt;
//         }
//     }
// }

@Injectable()
export class BasePrivGetter extends BaseAccessChecker {
    constructor(
        _userServ: UserService,
        _router: Router,
        _alertServ: AlertService
    ) {
        super(_userServ, _router, _alertServ);
    }

    validateAuth(category, privCategory, errorCheck) {
        const reslt = super.identifyPrivValue(category, privCategory);
        if (reslt) {
            return reslt;
        } else {
            if (errorCheck) {
                this._alertServ.error(MsgConfig.NO_PRIVS_ACCESS, false, "long");
            }
            return reslt;
        }
    }
}

@Injectable()
export class IsActivUserGuard implements CanActivate {
    constructor(private _userServ?: UserService) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        const authToken = this._userServ.getAccessToken();
        if (authToken) {
            return true;
        } else {
            return false;
        }
    }
}

// @Injectable()
// export class RxGuard extends BaseAuthGuard implements CanActivate {
//     constructor(
//         _userServ: UserService,
//         _router: Router,
//         _alertServ: AlertService
//     ) {
//         super(_userServ, _router, _alertServ);
//     }

//     canActivate() {
//         const reslt = super.validateAuth(AccCategoryE.Rx, RxE);
//         return reslt;
//     }
// }



@Injectable()
export class PrivMaskGuard extends BasePrivGetter implements CanActivate {
    constructor(
        _userServ: UserService,
        _router: Router,
        _alertServ: AlertService
    ) {
        super(_userServ, _router, _alertServ);
    }

    public canActivate(category, privCategory) {
        if(!super.getAccessToken() && !sessionStorage.getItem("Timeout")) {
            sessionStorage.setItem("Timeout", "true");
            this.sessionTimeout();
            return true;
        } else
        {
            const reslt = super.validateAuth(category, privCategory, false);
            return reslt;
        }
    }
}

// Dose Schedule AuthGuards

// @Injectable()
// export class DoseSchAddGuard extends BasePrivGetter implements CanActivate {
//     constructor(
//         _userServ: UserService,
//         _router: Router,
//         _alertServ: AlertService
//     ) {
//         super(_userServ, _router, _alertServ);
//     }

//     canActivate() {
//         const reslt = super.validateAuth(
//             AccCategoryE.DoseScheduleFile,
//             DoseScheduleFileE.Add,
//             true
//         );
//         return reslt;
//     }
// }

// @Injectable()
// export class DoseSchEditGuard extends BasePrivGetter implements CanActivate {
//     constructor(
//         _userServ: UserService,
//         _router: Router,
//         _alertServ: AlertService
//     ) {
//         super(_userServ, _router, _alertServ);
//     }

//     canActivate() {
//         const reslt = super.validateAuth(
//             AccCategoryE.DoseScheduleFile,
//             DoseScheduleFileE.Edit,
//             true
//         );
//         return reslt;
//     }
// }

// Report nav side menu authguards




