import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    HostListener
} from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
    RxService,
    AlertService,
    RphVerificationService,
    CommonService
} from "src/app/services";
import { AuditLogUtils } from "src/app/utils";

@Component({
    selector: "app-rph-followup",
    templateUrl: "./rph-followup.component.html"
})
export class RphFollowupComponent implements OnInit {
    modelRef: any;
    drugInfo: any;
    rxInfo: any;
    followUpReas = "";
    followUpId = 0;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set RxInfo(data: any) {
        this.rxInfo = data;
        // this.getRxFollowUpReason();
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @Output()
    RefreshGrid = new EventEmitter<Boolean>();

    @ViewChild("RxFollowup", { static: true })
    RxFollowup: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            this.modelRef.close();
            this.modelRef = null;
            event.preventDefault();
        }
    }


    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _alertService: AlertService,
        private _rphService: RphVerificationService,
        public _commonServ: CommonService,
        private _auditUtils: AuditLogUtils
    ) {
        config.backdrop = "static";
    }

    ngOnInit() {
        this.modelRef = this._modalService.open(this.RxFollowup, {keyboard:false , centered : true});
        this.getRxFollowUpReason();
    }

    getRxFollowUpReason() {
        this._rphService
            .GetRxFollowUpreason(
                this.rxInfo.Prescription.Id,
                this.rxInfo.PrescReFill.Id
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.followUpReas = resp.Reason;
                    this.followUpId = resp.Id;
                }
            });
    }

    saveFollowUp() {
        let DatatoPost = {
            PrescId: this.rxInfo.Prescription.Id,
            PrescRefillId: this.rxInfo.PrescReFill.Id,
            VerifDtTm: moment(Date.now()).format("MM-DD-YYYY"),
            Status: "F",
            RefillNum: this.rxInfo.PrescReFill.ReFillNum,
            PartialfillNum: this.rxInfo["PrescReFill"]["PartialFillNo"],
            PrescNum: this.rxInfo.Prescription.PrescNum,
            Reason: this.followUpReas,
            Id: this.followUpId,
            UserName: "JOHN",
            SendReadyForPickupAlerts:  this._commonServ.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts"),
            SendReadyForPickupAlertsWhen: this._commonServ.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts_When")
        };
        this._rphService.PostRphVerify(DatatoPost)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this._alertService.success("Follow-up saved successfully.");
                this._auditUtils.getChangedValues(
                    null,
                    {"Rph Verification Status": "Follow-up"},
                    "Rph Verification",
                    "Rx",
                    this.rxInfo.PrescReFill.Id
                );
                this.RefreshGrid.emit(true);
                this.closeModal(false);
            } else {
                this._alertService.error("Follow-up save unsuccessful.");
            }
        });
    }

    changeFollowup(notes: string) {
        this.followUpReas = notes;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    closeModal(isAddFollowUp) {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.IsPopUpClosed.emit(isAddFollowUp);
    }
}
