import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    HostListener
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertService, RxService, CommonService, } from "../../../services";
import { FormGroup, FormBuilder, FormControl, FormArray } from "@angular/forms";
import { RxPatientModel, OtherAmntMisc, RxModel, SystemData } from "src/app/models";
import { RxBillingUtil, PatchRXFGUtil, NRxSaveUtils, CommonUtil } from "src/app/utils";
import { MsgConfig } from "src/app";
import { RxStore } from "src/app/store"; 

@Component({
    selector: "app-other-amount",
    templateUrl: "./other-amount.component.html"
})
export class OtherAmountComponent implements OnInit {
    modelRef: any;
    fileFG: FormGroup;
    rxPatientModel: RxPatientModel;
    rxType: any;

    @Input() rxFG: FormGroup;
    @Input() insuSetng: any;
    @Input() insuDrpLst: any;
    @Input() systemData: SystemData;
    @Input() drugInsRestr: any;

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("OTHERAMOUNT", { static: true })
    OTHERAMOUNT: any;
    insPrefData: any;
    oldRxInfo: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (
            event.which === 27 &&
            this.modelRef &&
            this._modalService.hasOpenModals()
        ) {
            // esc
            this.closeModal(true);
            event.preventDefault();
        }
    }

    constructor(
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _billingUtils: RxBillingUtil, private _nrxSaveUtil: NRxSaveUtils,
        private _alrtServ: AlertService,
        private _rxService: RxService, private _rxStore: RxStore,
        private _comnSvc: CommonService, private _rxPatchUtil: PatchRXFGUtil, private _cmnUtil: CommonUtil
    ) {}

    ngOnInit() {
        this.oldRxInfo = this._rxStore.rxInfo$["source"]["value"];
        this.rxType = this.rxFG.get("rxType").value;
        if (
            this.rxFG &&
            (!this.rxFG.value.RxOtherAmount.OtherAmntMisc ||
                (this.rxFG.value.RxOtherAmount.OtherAmntMisc &&
                    this.rxFG.value.RxOtherAmount.OtherAmntMisc.length === 0))
        ) {
            this.generateOtherMiscDummyDataFA();
        }
        if (this.rxFG && this.rxFG.value.RxOtherAmount) {
            const fg = this.rxFG.controls["RxOtherAmount"] as FormGroup;
            fg.controls["FaxeSalesTax"].patchValue(this.rxFG.value.RxOtherAmount["SalesTax"]);
        }
        // Sentry.captureException([
        //     {name: "OtherAmount", id : 1},
        //     {name: "QuickVerfFlow", id : 2}
        // ]);
        this.rxOtherAmountManipulation();
        setTimeout(() => {
            this.modelRef = this._modalService.open(this.OTHERAMOUNT, {
                size: "lg",
                keyboard: false,
                backdrop: false, centered: true
            });
        }, 0);
        if(this.insuSetng) {
            this.changeInTaxValue();
        }
        if (this.drugInsRestr?.length) {
            this._cmnUtil.storedefaultDrugInsPref(this.rxFG, false, true);
        }
        if (this.rxType && (this.rxType === 'nr') && this.rxFG && this.rxFG.value.RxOtherAmount) {
            const fg = this.rxFG.controls["RxOtherAmount"] as FormGroup;
            const otherAmount = this.rxFG.value.RxOtherAmount;
            fg.controls["ProfFee"].patchValue(!otherAmount["ProfFee"] ? "0.00" : otherAmount["ProfFee"]);
            fg.controls["PaidAmnt"].patchValue(!otherAmount["PaidAmnt"] ? "0.00" : otherAmount["PaidAmnt"]);
            fg.controls["Incentive"].patchValue(!otherAmount["Incentive"] ? "0.00" : otherAmount["Incentive"]);
        }
    }

    get OtherAmntMisc(): FormArray {
        return this.rxFG.controls.RxOtherAmount.get(
            "OtherAmntMisc"
        ) as FormArray;
    }

    get UnitPriceUNC(): FormControl {
        return this.rxFG.controls["PrescReFill"].get(
            "UnitPriceUNC"
        ) as FormControl;
    }

    changeToNumber(val: string, prop: string) {
        const RxOtherAmount: any = this.rxFG.controls.RxOtherAmount;
        RxOtherAmount.controls[prop].setValue(val ? (parseFloat(val)).toFixed(2) : "0.00");
        if (prop === "SalesTaxPer") {
            this.getInsuranceManualCalc();
        }
        const rxfgV: any = this.rxFG.getRawValue();
        if ( prop === "FlatSalesTax" || prop === "SalesTax") {
            this.rxFG.controls["TaxAmt"].setValue(+rxfgV.RxOtherAmount.FlatSalesTax + +rxfgV.RxOtherAmount.SalesTax);
        }
    }

    changedUsualAndCustomary() {
        const PrescReFill: any = this.rxFG.controls["PrescReFill"];
        PrescReFill.controls["UnitPriceUNC"].setValue(PrescReFill.value.UnitPriceUNC ?
            (parseFloat(PrescReFill.value.UnitPriceUNC)).toFixed(2) : "0.00"
        );
    }

    changeMiscInfoToNumber(val: string, index: number, prop: string) {
        const RxOtherAmount: any = this.rxFG.controls.RxOtherAmount;
        RxOtherAmount.controls.OtherAmntMisc.controls.map((om: any, i) => {
            if (i === index) {
                om.controls[prop].setValue(val ? (parseFloat(val)).toFixed(2) : "0.00");
            }
        });
    }

    generateOtherMiscDummyDataFA() {
        const otherAmount: any = this.rxFG.controls.RxOtherAmount;
        let otherDetails: any[] = this.OtherAmntMisc.value;
        if (!otherDetails) {
            otherDetails = [];
            otherDetails.push(new OtherAmntMisc());
            otherDetails.push(new OtherAmntMisc());
            otherDetails.push(new OtherAmntMisc());
        }
        const otherAmountDetFGS = otherDetails.map(val => this._fb.group(val));
        const otherAmountDetFAS = this._fb.array(otherAmountDetFGS);
        otherAmount.setControl("OtherAmntMisc", otherAmountDetFAS);
        this.getInsuranceManualCalc();
    }

    addOtherAmount() {
        const otherAmount: any = this.rxFG.controls.RxOtherAmount;
        let otherDetails: any[] = this.OtherAmntMisc.value;
        if (!otherDetails) {
            otherDetails = [];
            otherDetails.push(new OtherAmntMisc());
        } else {
            otherDetails.push(new OtherAmntMisc());
        }
        const otherAmountDetFGS = otherDetails.map(val => this._fb.group(val));
        const otherAmountDetFAS = this._fb.array(otherAmountDetFGS);
        otherAmount.setControl("OtherAmntMisc", otherAmountDetFAS);
    }

    delteOtherAmount(index: any) {
        const otherAmount: any = this.rxFG.controls.RxOtherAmount;
        const otherDetails: any[] = otherAmount.value.OtherAmntMisc;
        otherDetails.splice(index, 1);
        const otherAmountDetFGS = otherDetails.map(val => this._fb.group(val));
        const otherAmountDetFAS = this._fb.array(otherAmountDetFGS);
        otherAmount.setControl("OtherAmntMisc", otherAmountDetFAS);
    }

    async saveOtherAmount() {
        const rxInfo: RxModel = this.rxFG.getRawValue();
        rxInfo["RxFile"] = null;
        if (this.rxType === "er") {
            await this._billingUtils.caculateBalDue(this.rxFG, this.rxType);
            if (rxInfo.RxOtherAmount.OtherAmntMisc && rxInfo.RxOtherAmount.OtherAmntMisc.length) {
                rxInfo.RxOtherAmount.OtherAmntMisc.map((value, i) => {
                    var data3 = rxInfo["RxOtherAmount"]['OtherAmntMisc'];
                        data3[i]['OtherAmntCatId'] = !value["OtherAmntCatId"] ? 1 : value["OtherAmntCatId"];
                });
             }
            rxInfo["PrescReFill"]["BalDue"] = this.rxFG.controls["PrescReFill"].value["BalDue"] ? this.rxFG.controls["PrescReFill"].value["BalDue"] : "0.00";
            const resp = await this._nrxSaveUtil.updateRxInfo(rxInfo);
            if (resp && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
                rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            }
            this.updateOtherValueinRxFG(rxInfo);
            this.closeModal();
        } else {
            this._billingUtils.caculateBalDue(this.rxFG, this.rxType);
            this._alrtServ.success(MsgConfig.OTHER_AMT_SAVE_TEMP);
            this.closeModal();
        }
    }

    async updateOtherValueinRxFG(rxInfo) {
        const resp = await this._comnSvc.getRxDetailsByPrescNos([rxInfo["Prescription"]["PrescNum"]],
        rxInfo["PrescReFill"]["Id"]).toPromise();
        if (resp && resp.length > 0) {
            this.rxFG.controls["PrescReFill"].patchValue({"ModifiedDtTm": new Date()});
            this._rxPatchUtil.patchOtherAmount(this.rxFG, resp[0], "RxOtherAmount");
            this._rxStore.storeRxInfo(this.rxFG.value);
        }
    }

    closeModal(type?) {
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.rxType == 'er' && type) {
            this.rxFG.controls["RxOtherAmount"].patchValue(this.oldRxInfo ? this.oldRxInfo.RxOtherAmount : "0.00");
            this.rxFG.controls["PrescReFill"].patchValue({UnitPriceUNC: (this.oldRxInfo ? this.oldRxInfo.PrescReFill.UnitPriceUNC : "0.00")});
        }
        this.IsPopUpClosed.emit(null);
    }

    getInsuranceInformation() {
        if (this.insuSetng) {
            const otherAmount = this.rxFG.controls
                .RxOtherAmount as FormGroup;
            otherAmount.controls["SalesTaxPer"].setValue(
                this.insuSetng.InsuPlanPrice.TaxRatePer
                    ? +this.insuSetng.InsuPlanPrice.TaxRatePer
                    : 0.0
            );
            if (this.insuSetng.InsuPlanPrice.IsTaxOnRx && this.insuSetng.InsuPlanPrice.SalesTaxBasisId === 2) {
                otherAmount.controls["SalesTax"].setValue(
                    (
                        ((this.rxFG.value.PrescReFill.Price
                            ? +this.rxFG.value.PrescReFill.Price
                            : 0.0) *
                            (this.insuSetng.InsuPlanPrice.TaxRatePer
                                ? +this.insuSetng.InsuPlanPrice.TaxRatePer
                                : 0.0)) /
                        100
                    ).toFixed(2)
                );
            } else if (this.insuSetng.InsuPlanPrice.IsTaxOnRx && (this.insuSetng.InsuPlanPrice.SalesTaxBasisId === 1 ||
                this.insuSetng.InsuPlanPrice.SalesTaxBasisId === 3)) {
                otherAmount.controls["SalesTax"].setValue(
                    (
                        (((this.rxFG.value.PrescReFill.Price
                            ? +this.rxFG.value.PrescReFill.Price
                            : 0.0) + (this.rxFG.value.PrescReFill.ProfFee
                                ? +this.rxFG.value.PrescReFill.ProfFee
                                : 0.0)) *
                            (this.insuSetng.InsuPlanPrice.TaxRatePer
                                ? +this.insuSetng.InsuPlanPrice.TaxRatePer
                                : 0.0)) /
                        100
                    ).toFixed(2)
                );
            } else {
                otherAmount.controls["SalesTax"].setValue(0.0);
            }
        }
    }

    getInsuranceManualCalc() {
        // const rxInfo = this.rxFG.getRawValue();
        // if (this.insuSetng) {
        //     const otherAmount: any = this.rxFG.controls
        //         .RxOtherAmount as FormGroup;
        //     if (rxInfo.Drug.drugtypeid === 4 && this.insuSetng.InsuPlanPrice.IsTaxOnOTC) {
        //         if (this.insuSetng.InsuPlanPrice && this.insuSetng.InsuPlanPrice.IsTaxOnRx &&
        //                 this.insuSetng.InsuPlanPrice.SalesTaxBasisId === 2) {
        //             otherAmount.controls["SalesTax"].setValue(
        //                 (
        //                     ((this.rxFG.value.PrescReFill.Price
        //                         ? +this.rxFG.value.PrescReFill.Price
        //                         : 0.0) *
        //                         (otherAmount.value.SalesTaxPer
        //                             ? +otherAmount.value.SalesTaxPer
        //                             : 0.0)) /
        //                     100
        //                 ).toFixed(2)
        //             );
        //         } else if (this.insuSetng.InsuPlanPrice.IsTaxOnRx && (this.insuSetng.InsuPlanPrice.SalesTaxBasisId === 1 ||
        //             this.insuSetng.InsuPlanPrice.SalesTaxBasisId === 3)) {
        //             otherAmount.controls["SalesTax"].setValue(
        //                 (
        //                     (((this.rxFG.value.PrescReFill.Price
        //                         ? +this.rxFG.value.PrescReFill.Price
        //                         : 0.0) + (this.rxFG.value.PrescReFill.ProfFee
        //                             ? +this.rxFG.value.PrescReFill.ProfFee
        //                             : 0.0)) *
        //                         (otherAmount.value.SalesTaxPer
        //                             ? +otherAmount.value.SalesTaxPer
        //                             : 0.0)) /
        //                     100
        //                 ).toFixed(2)
        //             );
        //         } else {
        //             otherAmount.controls["SalesTax"].setValue(0.0);
        //         }
        //     } else {
        //         otherAmount.controls["SalesTax"].setValue(0.0);
        //     }
        // }
    }

    checkAsManul(val: string, prop: string) {
        const fg = this.rxFG.controls["RxOtherAmount"] as FormGroup;
        fg.controls["IsManaulSaleTax"].patchValue(true);
        this.changeToNumber(val, prop);
    }

    changeInTaxValue() {
        this._billingUtils.calTaxValues(this.rxFG, this.insuSetng);
    }
    rxOtherAmountManipulation() {
        if (this.rxFG.value.RxOtherAmount.OtherAmntMisc &&
            this.rxFG.value.RxOtherAmount.OtherAmntMisc.length !== 0) {
                this.rxFG.value.RxOtherAmount.OtherAmntMisc.map((value, i) => {
                    const data: any = this.rxFG.controls["RxOtherAmount"];
                    const data1: any = data.controls["OtherAmntMisc"];
                    const data2: any = data1.controls[i];
                    if (!value["OtherAmntCatId"]) {
                        data2.controls["OtherAmntCatId"].patchValue(1);
                    }
                    if (!value["Amount"]) {
                        data2.controls["Amount"].patchValue("0.000");
                    }
                });
    }
    }
}




