import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "filterRelation" })
export class FilterRelationCat implements PipeTransform {
    transform(data: any[], type): any {
        let result = [];
        if (data && data.length > 0) {
            result = data.filter(val => val.Type === (type === "Patient" ? "PATREL" : "PICK"));
        }
        return result;
    }
}
