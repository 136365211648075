import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"; 
import { Router } from "@angular/router";
import { CommonService, RxObservableService } from "../../../services";

@Component({
    selector: "app-patient-card",
    templateUrl: "./result-card.component.html"
})
export class ResultCardComponent implements OnInit {
    @Input() index: any;
    @Input() searchTerm: any;
    @Input()
    set ResultData(rd: any) {
        if (rd) {
            this.inputData = rd;
            if (this.inputData && this.inputData.insurance && this.inputData.insurance.length > 0) {
                this.primaryInsu = this.inputData.insurance.filter(x =>
                    x.insuranceid === +this.inputData.insuranceid)[0];
            }
        }
    }

    @Input()
    set RouterURL(ru: string) {
        this.routerURL = ru;
    }

    @Input()
    set IsAllToShow(all: boolean) {
        this.isAllToShow = all;
    }

    @Input()
    set ShowOnlyPatName(patName: boolean) {
        this.showPatName = patName;
    }

    @Output() SelectedPatient = new EventEmitter();

    inputData: any;
    routerURL: string;
    isAllToShow: boolean;
    showPatName: boolean;
    primaryInsu: any;

    constructor(
        private _router: Router,
        private _commonService: CommonService,
        private _rxObserSer: RxObservableService
    ) {}

    ngOnInit() {}

    selectedPatient(patient: any): any {
        this.SelectedPatient.emit({patient});
        // localStorage.removeItem("patientId");
        // localStorage.removeItem("RxInfo");
        // localStorage.removeItem("RxID");
        // localStorage.removeItem("PatientInfo");
        // sessionStorage.removeItem("ErxId");
        // sessionStorage.removeItem("ErxOpTransactionId");
        // sessionStorage.removeItem("ErxFileId");
        // sessionStorage.removeItem("ErxOperationId");
        // sessionStorage.removeItem("ElectronicRxDoc");
        // sessionStorage.removeItem("ErxType");
        // if (this.routerURL) {
        //     sessionStorage.setItem("PatientComplete", "" + false);
        //     sessionStorage.setItem("PrescriberComplete", "" + false);
        //     sessionStorage.setItem("DrugComplete", "" + false);
        //     this._commonService.storePatientIdinBS(patient.patientid);
        //     localStorage.setItem("PatientInfo", JSON.stringify(patient));
        //     this._router.navigate([this.routerURL], {
        //         queryParams: { fm: "nr" }
        //     });
        // }
    }
}
