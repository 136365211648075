import {
    Component,
    OnInit,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    AfterViewInit,
    OnDestroy,
    HostListener
} from "@angular/core";
import { AlertService, CommonService, UserService } from "src/app/services";
import { RxOrigin, AccCategoryE, RefillRxE, PatientHistoryE } from "src/app/models";
import { CollectionView } from "@grapecity/wijmo";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrivMaskGuard } from "src/app/guards";
import { PrintErxService } from "src/app/services/print-erx.service";
import { FormBuilder } from "@angular/forms";
import { PrintService } from "src/app/services/print.service";
import { RegEx } from "src/app/app.regex";
import { ColorCode, ValidateFormFieldsUtils, WijimoUtil } from "src/app/utils";  
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import { ColorCodeRx } from "src/app/utils/rx-color.util";
import { Verificationsenum } from "src/app/models/Verifications.enum"; 
import { CommonStore } from "src/app/store";
import { FormatsUtil } from "src/app/utils/formats.util";
import { MsgConfig } from "../../../app.messages";

@Component({
    selector: "app-refill-request",
    templateUrl: "./refill-request.component.html",
    styles: []
})
export class RefillRequestComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() refillHeader: string;
    @Input() selectedRxs: any[];
    @Input() rxInfo: any;
    @Input() rxOriginList: RxOrigin[];
    @Input() openDirectly: string;
    @Input() SearchParams : any;

    @Output()
    PrintFaxView = new EventEmitter<any>();
    @Output()
    CheckHasPrivsForErxReq = new EventEmitter<any>();
    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("PRINTREFILLREQUEST", { static: true })
    PRINTREFILLREQUEST: any;
    @ViewChild("ALREADYVERIFIED", { static: true })
    ALREADYVERIFIED: any;
    @ViewChild("ISCONTROLRX",{ static: true })
    ISCONTROLRX: any;
    @ViewChild("ISNONCONTROLRX",{ static: true })
    ISNONCONTROLRX: any;
    @ViewChild("DAWVALID", { static: true })
    DAWVALID: any;
    @ViewChild("SPINOMODAL", { static: true })
    SPINOMODAL: any;
    @ViewChild("ADDSPI", { static: true })
    ADDSPI: any;
    @ViewChild("MISSINGFIELDS", { static: true })
    MISSINGFIELDS: any;

    printRefWJ: any;
    activeModal: string;
    accessPrivCate: typeof AccCategoryE;
    patientHistoryPrivs: typeof PatientHistoryE;
    refillRxPrivs: typeof RefillRxE;
    categoryId: any;
    privType: any;
    openOverride: boolean;
    overrideFor: any;
    overrideEndpoint: any;
    AlreadyVerPopUp: boolean;
    modalRef: any;
    regex = RegEx;
    faxPopUp:boolean;
    checkDawModal: boolean;
    checkSPIModal: boolean;
    missingPopUP: boolean;
    dropdownData: any;
    showDrugDescription: boolean;
    formSubmitted: boolean;
    drugIngredientsList: any;
    faxPrefix:number;
    processingMissingDrug: any;
    spiNumber: any;
    faxSubject:string;
    drugMissingForm: any;
    refReqModel: any;
    unsubscribe$: Subject<void> = new Subject();
    hasFaxNumber: boolean;
    SendRefillReqProcessingData: any[] = [];
    isControlRxPriv: boolean = false;
    isNonControlRxPriv: boolean = false;
    wjHeadersForprepareBatchedRxs: { hName: string; isVisible: boolean; width: number; }[];
    wjHeaders: any;
    batchedRxssWijmo: wjcGrid.FlexGrid;
    alreadyBatchedRxs: any;
    originalRxsForRefReq: any;
    deferredEquivalents: any;
    prepareBatchedRxsWj: CollectionView<any>;
    deferredEquivalentsWJ: CollectionView<any>;
    eqvDeferredFlex: wjcGrid.FlexGrid;
    checkedRow: boolean;
    wjHeadersForGrpRxs: any[];
    selectedEqvdWJ: wjcGrid.FlexGrid;
    _wijHeightDiv : string;
    _wijHeight: string;
    eqvDefRxs: any;
    selectedRxsForRefReq = [];
    selectedRxsToFill = [];
    filledIds = [];
    selectedPrescIds = [];
    filteredPrescData = [];
    selectedPrescrefillIds: { SelectedPrescrefillIds: any[]; };
    eqvDefRxsCopy = [];
    @HostListener('window:resize', ['$event']) onResize(event) {
        this.gridHeightDefine();
    }
    constructor(
        private _alertSvc: AlertService,
        private _modalService: NgbModal,
        private _privMaskGuard: PrivMaskGuard,
        private _erxServ: PrintErxService,
        private _commonServ: CommonService,
        private _cdr: ChangeDetectorRef,
        private _fb: FormBuilder,
        private _userService: UserService,
        private _printser: PrintService,
        private _formUtils: ValidateFormFieldsUtils,
        private _wijimoUtils: WijimoUtil,
        private _colorCode: ColorCode,
        private _colorCodeRx: ColorCodeRx,
        private _commonStore: CommonStore,
        private _formatsUtil:FormatsUtil
    ) {
        this.accessPrivCate = AccCategoryE;
        this.refillRxPrivs = RefillRxE;
        this.patientHistoryPrivs = PatientHistoryE;

        this.drugMissingForm = this._fb.group({
            DoseFrmDescId: [],
            DoseFrmCodeId: [],
            DrugStrQualId: [],
            PotencyUnitCodeId: [],
            QtyQualId: [],
            DrugId: [],
            DrugName: []
        });
    }

    ngOnInit() {
        this.faxPrefix = this._commonServ.getSetttingValue("FaxAndReportsSettings", "Fax_Prefix");
        this.faxSubject = this._commonServ.getSetttingValue("FaxAndReportsSettings", "Fax_Subject");
        this.preparePrintRef();
        if (this.openDirectly) {
            this.openDirectly === "Print" ? this.printFaxView() : this.openDirectly === "Send" ? this.assignArrayAndCheckRefill() : 
            this.openDirectly === "PrintRefillReqForm" ? this.openActionModal("PrintRefillReqForm"): this.checkHasPrivsForErxReq();
        } else {
            this.openRefillRequestModal();
        }
        const faxNumberSetting = this._commonServ.getSetttingValue("FaxAndReportsSettings", "Fax_Number");
        if (faxNumberSetting && faxNumberSetting.length) {
            this.hasFaxNumber = true;
        } else {
            this.hasFaxNumber = false;
        }
        this.getEquivalentDeferredRxs();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "printRefReqWJ" || w["WijmoKey"] === "printbatchedRxsRefReqWJ")) {
                this.patchDefaultValHeaders();
            }
        });
        this.gridHeightDefine();
    }

    openActionModal(modal?: string) {
        this.activeModal = null;
        if (modal && modal === "PrintRefillReqForm") {
            this.activeModal = modal;
        }
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("deferredEquivalentsWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("deferredEquivalentsWJ", storedWJ);
        const storedWJ1 = await this._wijimoUtils.getWJSavedData("printbatchedRxsRefReqWJ");
        this.wjHeadersForprepareBatchedRxs = this._wijimoUtils.patchDefHeader("printbatchedRxsRefReqWJ", storedWJ1);
        const storedWJ2 = await this._wijimoUtils.getWJSavedData("deferredEqvGrpWJ");
        this.wjHeadersForGrpRxs = this._wijimoUtils.patchDefHeader("deferredEqvGrpWJ", storedWJ2);
    }
    initBatchedRxss(flex: wjcGrid.FlexGrid) {
        this.batchedRxssWijmo = flex;
    }
    getEquivalentDeferredRxs(prescIds? : any){
        if((prescIds && prescIds.length > 0)){
            this.selectedPrescrefillIds.SelectedPrescrefillIds = [];
            prescIds?.map((item)=>{
                let data = this.selectedRxs?.filter((val) => val.PrescReFill.Id === item);
                this.selectedRxs = data;
            })
            this.selectedPrescrefillIds = {
                SelectedPrescrefillIds : prescIds

            }
            this.preparePrintRef("isFrmRefill");
        } 
        else if((prescIds && prescIds.length === 0) && this.selectedPrescrefillIds){
            this.selectedPrescrefillIds.SelectedPrescrefillIds = [];
            this.selectedRxs = [];
            this.closeRefillRequest();
        }
        else{
            this.selectedRxs?.map((Rxs)=>{
                if(!this.selectedPrescIds.includes(Rxs.PrescReFill.Id)){
                    this.selectedPrescIds.push(Rxs.PrescReFill.Id);
                }
            });
            this.selectedPrescrefillIds = {
                SelectedPrescrefillIds : this.selectedPrescIds
            }
        }
        this._commonServ.getEquiDeferredRxs(this.selectedPrescrefillIds).
        pipe(takeUntil(this.unsubscribe$)).subscribe((resp)=>{
            if(resp){
                this.alreadyBatchedRxs = resp?.BatchFaxes;
                if(this.alreadyBatchedRxs){
                    this.prepareBatchedRxs(this.alreadyBatchedRxs);
                }
                this.originalRxsForRefReq = resp?.OriginalRxs;
                this.deferredEquivalents = resp?.DeferredEquivalents;
                if(this.originalRxsForRefReq){
                    this.prepareDeferredEquivalents(this.originalRxsForRefReq,prescIds);
                }
                if(resp?.OriginalRxs){
                    this.selectedRxs = Object.values(resp?.OriginalRxs);                 
                    this.preparePrintRef("isFrmRefill");
                }
            }
        });
    }
    prepareBatchedRxs(batchedRxs) {
        this.prepareBatchedRxsWj = new CollectionView(
            batchedRxs?.map((patRx, i) => {
                const k = {};
                k["Id"] = patRx.Id;
                k["FROM NAME"] = patRx.FromName ? patRx.FromName : "";
                k["From Name"] = patRx.FromName ? patRx.FromName : "";
                k["TO NAME"] = patRx.ToName ? patRx.ToName : "";
                k["To Name"] = patRx.ToName ? patRx.ToName : "";
                k["TO FAX NO"] = patRx.ToFaxNo ? patRx.ToFaxNo : "";
                k["To Fax No"] = k["To Fax#"] = patRx.ToFaxNo ? patRx.ToFaxNo : "";
                k["Rx#"] = patRx.RxNo ? patRx.RxNo.toString() : "";
                k["Ref#"] = ((patRx.RefillNo) || (patRx.RefillNo === 0)) ? patRx.RefillNo : "";
                k["Fill#"] = patRx.PartialFillNo ? patRx.PartialFillNo : "";
                k["Fax Date"] = (patRx.FaxDateTime) ? moment.utc(patRx.FaxDateTime).local().format("MM/DD/YYYY") : "";
                k["Time Info"] = (patRx.FaxDateTime) ? moment.utc(patRx.FaxDateTime).local().format("hh:mm:ss a") : "";
                return k;
            })
        );
    }
    init(flex: wjcGrid.FlexGrid) {
        this.eqvDeferredFlex = flex;
        flex?.focus();
        flex?.refresh();
        flex.columnHeaders.rows[0].wordWrap = true;
        flex?.autoSizeRow(0, true);
        this?.gridHeightDefine();
    }
    loadedWJRows(flex: wjcGrid.FlexGrid) {
        flex?.collapseGroupsToLevel(0);
    }
    async updateWJView(flex: wjcGrid.FlexGrid) {
        this.selectedEqvdWJ = flex;
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    showData(item) {
        return item?.items;
    }
    prepareDeferredEquivalents(originalRxsForRefReq,isFrmFill? : any){
        this.selectedRxsForRefReq = [];
        Object.keys(originalRxsForRefReq).map((item)=>{
            let eqvDefVales;
            eqvDefVales = this.deferredEquivalents?.[item];
            if(eqvDefVales && eqvDefVales.length > 0)
                this.selectedRxsForRefReq.push(originalRxsForRefReq?.[item]);
        });
        this.eqvDefRxsCopy = [];
        this.deferredEquivalentsWJ = new CollectionView(
            this.selectedRxsForRefReq?.map((item)=>{
                    const k = {};
                    this.eqvDefRxs = this.deferredEquivalents?.[item?.PrescReFill?.Id];
                    this.eqvDefRxs?.map((item)=>{
                        this.eqvDefRxsCopy.push(item);
                    })
                    k["Rx#"] = item.Prescription.PrescNum;
                    k["Ref#"] = item.PrescReFill.ReFillNum;
                    k["Fill#"] = this._commonServ.checkIsPartialFill(item.PrescReFill, item.Prescription) ? item.PrescReFill.PartialFillNo : "";
                    k["Auth#"] = item.Prescription.ReFillNum;
                    k["Insurance"] = this._commonServ.rxInsurance(item);
                    k["Status"] = (item.PrescReFill.StatusId === 4 || item.Prescription.RxStatusId === 4) ? "T" :
                    (item.PrescReFill.StatusId === 2 || item.Prescription.RxStatusId === 2 || item.RxBill.StatusId === 6) ? "D" :
                        (item.PrescReFill.StatusId === 3
                            || item.Prescription.RxStatusId === 3) && item.RxBill.StatusId === 2 ? "BI" :
                            (item.RxBill.StatusId === 2 && item.PrescReFill.StatusId !== 4
                                && item.Prescription.RxStatusId !== 2) ? "B" :
                                ((item.PrescReFill.StatusId === 3 || item.Prescription.RxStatusId === 3) &&
                                    item.RxBill.StatusId === 1) ? "UI" : "U";
                    k["Drug Name"] = (item?.Drug?.drugname?.trim() + " " + item?.Drug?.strength?.trim() + " " + item?.Drug?.drugformname?.trim()).toUpperCase();
                    k["Qty Dis"] = item.PrescReFill.DispQty ? (item.PrescReFill.DispQty).toFixed(3) : "0.000";
                    k["Fill Date"] = moment.utc(item.PrescReFill.FillDtTm).local().format("MM/DD/YYYY");
                    k["NDC"] = this._formatsUtil.getNDCFormat(item.Drug.ndc);
                    k["PrescRefillId"] = item.PrescReFill.Id ? item.PrescReFill.Id : null;
                    k["Supply Days"] = k["Days Supply"] = Math.round(item.PrescReFill.SupplyDays);
                    k["Prescriber Name"] = item.Prescriber ? (
                        item.Prescriber.prescriberlastname + ", " + item.Prescriber.prescriberfirstname).toUpperCase() : null;
                    let result = null;
                    result = this.generateWj(this.eqvDefRxs,item.PrescReFill.Id);
                    k["items"] = result;
              return k;
            })
        )
    }
    generateWj(eqvDefRxs,orgPrescrefillId?: any){
        const mapDataSet = [];
            eqvDefRxs?.map((item)=>{
                const k = {};
                    k["Rx#"] = item.Prescription.PrescNum;
                    k["Ref#"] = item.PrescReFill.ReFillNum;
                    k["Fill#"] = this._commonServ.checkIsPartialFill(item.PrescReFill, item.Prescription) ? item.PrescReFill.PartialFillNo : "";
                    k["Auth#"] = item.Prescription.ReFillNum;
                    k["Insurance"] = this._commonServ.rxInsurance(item);
                    k["Status"] = (item.PrescReFill.StatusId === 4 || item.Prescription.RxStatusId === 4) ? "T" :
                    (item.PrescReFill.StatusId === 2 || item.Prescription.RxStatusId === 2 || item.RxBill.StatusId === 6) ? "D" :
                        (item.PrescReFill.StatusId === 3
                            || item.Prescription.RxStatusId === 3) && item.RxBill.StatusId === 2 ? "BI" :
                            (item.RxBill.StatusId === 2 && item.PrescReFill.StatusId !== 4
                                && item.Prescription.RxStatusId !== 2) ? "B" :
                                ((item.PrescReFill.StatusId === 3 || item.Prescription.RxStatusId === 3) &&
                                    item.RxBill.StatusId === 1) ? "UI" : "U";
                    k["Drug Name"] = (item?.Drug?.drugname?.trim() + " " + item?.Drug?.strength?.trim() + " " + item?.Drug?.drugformname?.trim()).toUpperCase();
                    k["Qty Dis"] = item.PrescReFill.DispQty ? (item.PrescReFill.DispQty).toFixed(3) : "0.000";
                    k["Fill Date"] = moment.utc(item.PrescReFill.FillDtTm).local().format("MM/DD/YYYY");
                    k["NDC"] = this._formatsUtil.getNDCFormat(item.Drug.ndc);
                    k["PrescRefillId"] = item.PrescReFill.Id ? item.PrescReFill.Id : null;
                    k["Supply Days"] = k["Days Supply"] = Math.round(item.PrescReFill.SupplyDays);
                    k["Prescriber Name"] = item.Prescriber ? (
                        item.Prescriber.prescriberlastname + ", " + item.Prescriber.prescriberfirstname).toUpperCase() : null;
                    k["OrgPrescrefillId"] = orgPrescrefillId;
                    k["PatientId"] = item && item.Patient ? item.Patient.patientid : null;
                mapDataSet.push(k);
        });
        return mapDataSet;
    }
    showInsideGrid(item) {
        return item.items;
    }
    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row && row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const colors = this._colorCode.showRxColorRefactoring(row.dataItem, "Rx");
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                } 
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Status") {
                const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && ["DVrf?", "PVrf?", "Rx Vrf?"].includes(panel.columns[c].binding)) {
                let key = panel.columns[c].binding;
                if ((row.dataItem[key] === "Y") || (row.dataItem[key] === Verificationsenum.verified || row.dataItem[key] === Verificationsenum.reverified)) {
                    if ((+row.dataItem["OweQty"]) && key === "DVrf?") {
                        cell.style.backgroundColor = "#ffc107";
                        cell.style.color = "#fff";
                    } else {
                        cell.style.backgroundColor = "darkgreen";
                        cell.style.color = "#fff";
                    }
                } else if (row.dataItem[key] === "Re-Verify") {
                    cell.style.backgroundColor = "orange";
                    cell.style.color = "#fff";
                }  else {
                    cell.style.backgroundColor = "#fff";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
        if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Auth#") {
            cell.style.background = "#FFF";
            cell.style.backgroundColor = "#FFF";
            cell.style.color = "#000";
            cell.style.fontWeight = "normal";
            cell.style.textAlign = "initial";
        }
    };
    gridHeightDefine() {
        const element = document.getElementById("eqvDeferredId");
        if (element) {
            const height = window.innerHeight -
            element.getBoundingClientRect().top +
            window.scrollY - window.scrollY;
            let fitHeight;
            fitHeight = (height > 400 && height < 650) ? height - 175 : (height >= 650 && height < 750) ? height - 235 : (height >= 750) ? fitHeight = height - 260 : height - 195;
            this._wijHeightDiv = (fitHeight + 30) + 'px';
            this._wijHeight = ((fitHeight + 30) - 4) + 'px';
        }
    }
    selectedRx(isChecked: boolean, checkedVal? : any) {
        this.checkedRow = isChecked;
        if(this.checkedRow && checkedVal){
            if(!this.filledIds.includes(checkedVal.PrescRefillId)){
                this.filledIds.push(checkedVal.PrescRefillId);
                this.selectedRxsToFill.push(checkedVal);
            }
        } else{
            if(this.filledIds.includes(checkedVal?.PrescRefillId)){
                const index = this.filledIds.indexOf(checkedVal?.PrescRefillId);
                if (index !== -1) {
                this.filledIds.splice(index, 1);
                }
            }
        }
   }
   
   async processRefillFlowEqvDefRxs() {
    if (this.modalRef) {
        this.modalRef.close();
    }
    if(this.checkedRow && this.selectedRxsToFill && this.selectedRxsToFill.length){
        if(this.refReqModel){
            this.refReqModel.close();
        }
        const prescNum = this.selectedRxsToFill.map(val => {
            return val["Rx#"];
        });
        const data = { selectedRxs: prescNum, frm: "RxHist", completeData: prescNum,
        patHistData: this.SearchParams.value};
        this.SearchParams?.controls?.["refillFrmHist"]?.patchValue(true);
        localStorage.setItem("previousRefillScreen", this._commonServ.encryptData("Refill"));
        this._commonStore.storeRefInfo(data);
        this._commonStore.storeRfStage("Start");
    } else{
        this._alertSvc.error("Please select atleast one Rx");
    }
}
    preparePrintRef(isFrmRefill?: any) {
        if (this.checkForRxSelect(isFrmRefill)) {
            this.printRefWJ = new CollectionView(
                this.selectedRxs.map((patRx, i) => {
                    let k = {};
                    k["RowId"] = i;
                    k["PatientName"] =
                        patRx.Patient.lastname + ", " + patRx.Patient.firstname;
                    k["PrescriberName"] =
                        patRx.Prescriber.prescriberlastname +
                        ", " +
                        patRx.Prescriber.prescriberfirstname;
                        let faxnumber : any;
                        if(patRx && patRx.Prescriber &&  patRx.Prescriber.PrescriberAddresses &&  patRx.Prescriber.PrescriberAddresses.length){
                            patRx.Prescriber.PrescriberAddresses.find(resp=>{
                                if(resp.Id ==  patRx.Prescription.PrescriberAddressId){
                                 faxnumber = resp.Fax && resp.Fax.length == 10 ? resp.Fax : (patRx.Prescriber.faxnumber && patRx.Prescriber.faxnumber.length == 10 ? patRx.Prescriber.faxnumber : null)
                                }
                             })
                        }    
                    k["PrescPhone"] = faxnumber;
                    k["RxNo"] = patRx.Prescription.PrescNum.toString();
                    k["RxOrigin"] = this.getRxOrgNameById(
                        patRx.PrescReFill.RxOriginId
                    );
                    k["RefillNo"] = patRx.PrescReFill.ReFillNum;
                    k["drugClass"] = patRx.Drug.drugclass;
                    k["PrescriberId"] = patRx.Prescriber.prescriberid;
                    k["DrugId"] = patRx.PrescReFill.DrugId;
                    k["IsControlRx"] = patRx.Drug.drugclass>=2 ? true :false;
                    k["FaxSubject"] = "Refill Request";
                    k["Notes"] = "";
                    k["FromFaxNumber"] = "18447783045";
                    // k["DrugClassID"] = patRx.Dru.drugclass;
                    k["PrescRefillId"] = patRx.PrescReFill.Id;
                    k["DRUG NAME"] = (patRx?.Drug?.drugname?.trim() + " " + patRx?.Drug?.strength?.trim() + " " + patRx?.Drug?.drugformname?.trim()).toUpperCase();
                    return k;
                })
            );
            return;
        }

    }

    checkForRxSelect(modalType?: string): boolean {
        if (modalType !== "PatientNotes") {
            if (this.rxInfo && this.rxInfo.length === 1) {
                return true;
            } else if (this.selectedRxs && this.selectedRxs.length >= 1) {
                return true;
            } else {
                modalType === "isFrmRefill" ? '' : this._alertSvc.error("Please select atleast one Rx.");
                return false;
            }
        } else {
            return true;
        }
    }

    getRxOrgNameById(id: number): string {
        let name = "";
        this.rxOriginList.forEach(element => {
            if (element.Id === id) {
                name = element.Name;
            }
        });
        return name;
    }

    openRefillRequestModal() {
        this.refReqModel = this._modalService.open(this.PRINTREFILLREQUEST, {
            size: "lg",
            backdrop: false,
            keyboard: false,
            centered: true
        });
    }

    printFaxView() {
        // this.activeModal = "";
        // this.activeModal = "printFax";
        // this._cdr.detectChanges();
        this.printRefReq();
    }


    printRefReq() {
        let multipage = this._commonServ.getSetttingValue("FaxAndReportsSettings", "Refill_Request_Fax_Print_Format") === "1" ? 2 : 1;
        let rxinfData = {
            PrescNum: this.rxInfo.Prescription.PrescNum,
            DrugId: this.rxInfo.Drug.id,
            PrescriberId: this.rxInfo.Prescriber.prescriberid,
            DrugClass: this.rxInfo.Drug.drugclass,
            RefillNum: this.rxInfo.PrescReFill.ReFillNum,
            IsControlRx: this.rxInfo.Drug.drugclass >= 2 && this.rxInfo.Drug.drugclass <= 5 ? true : false
        };
        let payload = {
            Faxes: [rxinfData],
            IncludeCoverPage: false,
            FaxFormat: multipage,
            IsBatchFax: true,
            PatientId: this.rxInfo.Prescription.PatientId
        }
        this._userService.printLocally(payload)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.size > 0 && resp.type === "application/pdf") {
                this.printReport(resp);
            }
        })
    }

    printReport(datToPrint) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printser.printPdf(base64data);
        }.bind(this);
    }

    assignArrayAndCheck() {
        this.SendRefillReqProcessingData = [];
        if (this.selectedRxs.length) {
            this.SendRefillReqProcessingData = Object.assign([], this.selectedRxs) || [];
            this.checkHasPrivsForErxReq();
        }
    }

    checkHasPrivsForErxReq() {
        if (!this.SendRefillReqProcessingData || !this.SendRefillReqProcessingData.length) {
            return;
        }
        if (this.SendRefillReqProcessingData[0]["Drug"]["drugclass"] >= 2 && this.SendRefillReqProcessingData[0]["Drug"]["drugclass"] <= 5) {
            if (this.checkHasPrivsToAccess(this.accessPrivCate.PatientHistory,this.patientHistoryPrivs.SendERefillRequestForControls)) {
                this.checkErxRequest();
            } else {
                this.isControlRxPriv = true;
                this.checkErxRequest()
            }
        } else {
            if (this.checkHasPrivsToAccess(this.accessPrivCate.PatientHistory,this.patientHistoryPrivs.SendERefillRequest)) {
                this.checkErxRequest();
            } else {
                this.isNonControlRxPriv = true;
                this.checkErxRequest()
            }
        } 
    
    }

    assignArrayAndCheckRefill() {
        this.SendRefillReqProcessingData = [];
        if (this.selectedRxs.length) {
            this.SendRefillReqProcessingData = Object.assign([], this.selectedRxs) || [];
            this.checkHasPrivsForRefillErxReq();
        }
    }

    checkHasPrivsForRefillErxReq() {
        if (!this.SendRefillReqProcessingData || !this.SendRefillReqProcessingData.length) {
            return;
        }
        if (
            this.checkHasPrivsToAccess(
                this.accessPrivCate.RefillRx,
                this.refillRxPrivs.SendERefillRequest
            )
        ) {
            if (this.SendRefillReqProcessingData[0]["Drug"]["drugclass"] >= 2 &&
                    this.SendRefillReqProcessingData[0]["Drug"]["drugclass"] <= 5) {
                if (
                    this.checkHasPrivsToAccess(
                        this.accessPrivCate.RefillRx,
                        this.refillRxPrivs.SendERefillRequestForControls
                    )
                ) {
                    this.checkErxRequest();
                } else {
                    this.openOveride(
                        "Refill",
                        "SendERefillRequestForControls"
                    );
                }
            } else {
                this.checkErxRequest();
            }
        } else {
            this.openOveride(
                "Refill",
                "SendERefillRequest",
                "POST_ERX_XMLCreation"
            );
        }
    }

    openOveride(category, type, endpoint?) {
        this.categoryId = category;
        this.privType = type;
        this.openOverride = true;
        this.overrideFor = type;
        this.overrideEndpoint = endpoint;
    }

    checkHasPrivsToAccess(category: any, privType: any) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    closeSendRefillReq() { // logic after closing the E-Refill Request.
        this.activeModal = null;
        if (this.SendRefillReqProcessingData && this.SendRefillReqProcessingData.length) {
            this.checkHasPrivsForErxReq();
        } else {
            this.resetActiveModal();
        }
    }

    checkErxRequest() {
        if (!this.SendRefillReqProcessingData || !this.SendRefillReqProcessingData.length) {
            return;
        }
        this.rxInfo = null;
        this.rxInfo = this.SendRefillReqProcessingData.shift();
        if (this.isControlRxPriv) {
            this.openModal(this.ISCONTROLRX);
            this.isControlRxPriv = false;
        }  else if (this.isNonControlRxPriv) {
            this.openModal(this.ISNONCONTROLRX);
            this.isNonControlRxPriv = false;
        } else {        
            this._erxServ.checkErxRequest(this.rxInfo.Prescription.Id).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                if (resp) {
                    this.AlreadyVerPopUp = true;
                    this.openModal(this.ALREADYVERIFIED);
                } else {
                    this.chekcCompundDrug();
                }
            });
        }
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.checkErxRequest();
        } else {
            this.resetActiveModal();
        }
    }

    openModal(content: any, val?: any) {
        this.closeModal(false);
        if (val === "addSPI") {
            this.modalRef = this._modalService.open(content, {
            backdrop: false,
            keyboard: false, windowClass: "small--content",
            centered : true
            });
        } else {
            this.modalRef = this._modalService.open(content, {
                backdrop: false, keyboard: false, windowClass: val ? "" : "large--content",centered : true
                });
        }
    }

    chekcCompundDrug() {
        this.closeModal(false);
        if (
            this._commonServ.getSetttingValue(
                "ERxSettings",
                "E-PRESCRIPTION_SCRIPT_VERSION"
            ) === "1"
        ) {
            this._erxServ
                .chekcCompundDrug(this.rxInfo.Drug.id)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this._alertSvc.error(
                            "eRx refill request for Compound drugs can not be sent electronically."
                        );
                    } else {
                        this.checkPrescriber();
                    }
                });
        } else {
            this.checkPrescriber();
        }
    }

    checkDaw() {
        this._erxServ.checkDaw(this.rxInfo.PrescReFill.Id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.checkPrescriber();
            } else {
                this.checkDawModal = true;
                this.openModal(this.DAWVALID);
            }
        });
    }
    checkPrescriber() {
        this._erxServ
            .checkPrescriber(this.rxInfo.Prescriber.prescriberid)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.checkDrugValues();
                } else {
                    this.checkSPIModal = true;
                    this.openModal(this.SPINOMODAL);
                }
            });
    }

    checkDrugValues() {
        this._erxServ.checkDrugValues(this.rxInfo.Drug.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.missingPopUP = true;
                this.getDropdownsFormissing();
                this.patchMissingFields(resp);
                if (this.rxInfo.Drug.drugtypeid === 2) {
                    this.showDrugDescription = true;
                }
                this.formSubmitted = false;
                if (this.drugMissingForm && (!this.drugMissingForm.value.PotencyUnitCodeId ||
                    (this.rxInfo.Drug.drugtypeid === 2 && !this.drugMissingForm.value.DoseFrmCodeId))) {
                    this.openModal(this.MISSINGFIELDS, "drug");
                } else if (this.rxInfo.Drug.drugtypeid === 2) {
                    this.checkCompoundDrugMissingFields();
                } else {
                    this.showErxData();
                }
            }
        });
    }

    getDropdownsFormissing() {
        this._erxServ.getErxDropdowns()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.dropdownData = resp;
            }
        });
    }

    patchMissingFields(patchValues) {
        this.drugMissingForm.reset();
        this.drugMissingForm.controls.DoseFrmCodeId.clearValidators();
        this.drugMissingForm.patchValue(patchValues);
    }

    saveSPI() {
        if (this.spiNumber) {
            const dataToPost = {
                prescriberId: this.rxInfo.Prescriber.prescriberid,
                SpiNumber: this.spiNumber,
                PrescNum: this.rxInfo.Prescription.PrescNum,
                RefillNum: this.rxInfo.PrescReFill.ReFillNum,
            };
            this._erxServ.saveSPINumber(dataToPost)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp) => {
                if (resp) {
                    this.checkDrugValues();
                }
            });
        } else {
            this._alertSvc.warning("Please enter valid SPI Number");
        }
    }

    checkCompoundDrugMissingFields() {
        if (this.rxInfo.Drug.drugtypeid === 2) {
            this._erxServ
                .checkCompoundDrugValues(this.rxInfo.Drug.id, this.rxInfo.PrescReFill.Id)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(response => {
                    if (response) {
                        if (!this.dropdownData) {
                            this.getDropdownsFormissing();
                        }
                        this.showDrugDescription = false;
                        this.drugIngredientsList = response;
                        this.checkEachCompMissingFields();
                    } else {
                        this.showErxData();
                    }
                });
        } else {
            this.showErxData();
        }
    }

    checkEachCompMissingFields() { // recursive function to check missing values for each ingredients.
        if (this.drugIngredientsList.length > 0) {
            this.processingMissingDrug = this.drugIngredientsList.shift();
            this.patchMissingFields(this.processingMissingDrug);
            this.formSubmitted = false;
            if (this.drugMissingForm && !this.drugMissingForm.value.PotencyUnitCodeId) {
                this.openModal(this.MISSINGFIELDS, "drug");
            } else {
                this.checkEachCompMissingFields();
            }
        } else {
            this.showErxData();
        }
    }

    showErxData() {
        if (this.refReqModel) {
            this.refReqModel.close();
        }
        this.activeModal = "";
        this.activeModal = "sendEfill";
        this._cdr.detectChanges();
    }

    changeSpiNumber(value: any) {
        this.spiNumber = value;
        this.rxInfo.Prescriber.prescriberspinum = value;
    }

    async saveDrugFields() {
        await this._formUtils.blurAndDelay();
        this.formSubmitted = true;
        if (!this.drugMissingForm.valid) {
            return;
        }
        this.closeModal(false);
        const postData = this.drugMissingForm.value;
        postData["IsUnitDrug"] = false;
        postData["IsMedGuide"] = false;
        this._erxServ.saveMissingFields(postData)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.showDrugDescription = false;
                this.formSubmitted = false;
                if (this.processingMissingDrug) {
                    this.checkEachCompMissingFields();
                } else {
                    this.checkCompoundDrugMissingFields();
                }
            }
        });
    }

    openFaxPopUp() {
        this.faxPopUp = true;
    }

    reset(e){
        this.faxPopUp = false;
        if (e) {
            if (this.refReqModel) {
                this.refReqModel.close();
                this.refReqModel = null;
            }
            this.IsPopUpClosed.emit(null);
        }
    }

    closeRefillRequest() {
        this.activeModal = "";
        if (this.refReqModel) {
            this.refReqModel.close();
        }
        this.IsPopUpClosed.emit(null);
        this._cdr.detectChanges();
    }

    closeModal(closeSendRefill = true) {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        if (closeSendRefill) {
            this.closeSendRefillReq();
        }
    }

    resetActiveModal() {
        this.activeModal = "";
        this.closeModal(false);
        this.closeRefillRequest();
    }
}
