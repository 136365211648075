<ng-template #PatPreDrugCheck let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal(true)">
        <h4 header>eRx to New Rx</h4>
        <span body>
            <div class="eprx--block__content erx__Doc--section">
                <span>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="exprx--common-block">
                                <div class="eprx--block__content erx__doc--body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="exprx--common-block" [ngClass]="index==1 ? 'prescrib--focus':''">
                                                <h5 class="erx--label--primary margin_bottom_5px">Electronic Rx</h5>
                                                <div class="eprx--block__content">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h5 class="text-uppercase">
                                                                <label>
                                                                    {{ detailsInfo?.PresLastName}}<span *ngIf="detailsInfo?.PresLastName && detailsInfo?.PresFirstName">, </span>{{ detailsInfo?.PresFirstName ? (detailsInfo?.PresFirstName | titlecase ) : ''}} - {{detailsInfo?.PrescSpi}}
                                                                </label>
                                                                <label>{{detailsInfo?.ClinicName ? '-' + detailsInfo?.ClinicName : ''}}</label>
                                                            </h5>
                                                            <p>{{detailsInfo?.PrescAddress}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                <label>NPI#</label>
                                                                <div class="label--data">{{detailsInfo?.PrescNpi ? detailsInfo?.PrescNpi : '--'}}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                                                                <label>Phone#</label>
                                                                <div class="label--data">{{ detailsInfo?.PrescPhone ? (detailsInfo?.PrescPhone | mask:'(000) 000-0000')
                                                                    : '--'}}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                <label>DEA#</label>
                                                                <div class="label--data"> {{detailsInfo?.PrescDea ? _formatsUtil.getDeaFormat(detailsInfo?.PrescDea) : '--'}}
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                       </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="exprx--common-block" [ngClass]="index==0 ? 'eprx-bg-focus':''">
                                                <h5 class="erx--label--primary erx-pat-presc-drug-card-spacing">PATIENT</h5>
                                                <div class="eprx--block__content">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label>Name</label>
                                                            <div class="label--data">
                                                                {{ detailsInfo?.PatLastName}}<span *ngIf="detailsInfo?.PatLastName && detailsInfo?.PatFirstName">,&nbsp;</span>{{ detailsInfo?.PatFirstName ? detailsInfo?.PatFirstName : '--'}}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                                                            <label>DOB</label>
                                                            <div class="label--data">{{ detailsInfo?.PatBirthDt ? (detailsInfo?.PatBirthDt | date:'MM/dd/yyyy')
                                                                : '--' }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label>Payer ID</label>
                                                            <div class="label--data">{{detailsInfo?.PayerId ? detailsInfo?.PayerId: '--' }}</div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label>Gender</label>
                                                            <div class="label--data">{{detailsInfo?.PatGender ? ((detailsInfo?.PatGender.toLowerCase() === "m") ?
                                                                    "MALE" : (detailsInfo?.PatGender.toLowerCase() === "f") ? "FEMALE" : "NOT SPECIFIED") :
                                                                       '--' }}</div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label>Phone#</label>
                                                            <div class="label--data">{{detailsInfo?.PatPhone ? (detailsInfo?.PatPhone | mask:'(000)
                                                                000-0000') : '--'}}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label>Address</label>
                                                            <div class="label--data"> {{detailsInfo?.PatAddress ? detailsInfo?.PatAddress : '--' }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="exprx--common-block" [ngClass]="index==2 ? 'eprx-bg-focus':''">
                                                <h5 class="erx--label--primary erx-pat-presc-drug-card-spacing">DRUG</h5>
                                                <div class="eprx--block__content">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label>Name </label>
                                                            <div class="label--data">{{detailsInfo?.DrugName ? detailsInfo?.DrugName : '--' }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label>Drug NDC </label>
                                                            <div class="label--data">{{this._formatsUtil.getNDCFormat(detailsInfo?.DrugNdc)}}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                                                            <label>Strength</label>
                                                            <div class="label--data"> {{detailsInfo?.DrugStrength ? detailsInfo?.DrugStrength : '--'
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label>Form</label>
                                                            <div class="label--data"> {{detailsInfo?.DrugForm ? detailsInfo?.DrugForm : '--' }}</div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label>Potency Unit Code</label>
                                                            <div class="label--data"> {{detailsInfo?.DrugPotency ? detailsInfo?.DrugPotency : '--'}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="exprx--common-block">
                                                <h5 class="erx--label--primary erx-pat-presc-drug-card-spacing">Rx</h5>
                                                <div class="eprx--block__content">
                                                    <div class="row">
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label>Rx# </label>
                                                            <div class="label--data">{{erxData?.RxNo ? erxData?.RxNo : '--' }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label>Ord Date </label>
                                                            <div class="label--data">{{rxOrdDt ? rxOrdDt
                                                                : '--' }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                                                            <label>Auth#</label>
                                                            <div class="label--data"> {{detailsInfo?.auth ? detailsInfo?.auth : '--' }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label>Eff Date</label>
                                                            <div class="label--data"> {{EfctDt ? EfctDt : '--' }}</div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label>Qty</label>
                                                            <div class="label--data"> {{detailsInfo?.RxQty ? (detailsInfo?.RxQty | number : '1.3-3')
                                                                : '--'}}</div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label>Days</label>
                                                            <div class="label--data"> {{detailsInfo?.RxDays ? detailsInfo?.RxDays: '--' }}</div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label>DAW</label>
                                                            <div class="label--data"> {{detailsInfo?.RxDaw ? detailsInfo?.RxDaw : '--'}}</div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 "
                                                            *ngIf="!(detailsInfo?.IsNormalRx)" title="Total Dispensings">
                                                            <label>Total Disp.</label>
                                                            <div class="label--data"> {{detailsInfo?.totalDispensings}}</div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Prs.ord.Ref</label>
                                                            <div class="label--data"> {{detailsInfo?.PrsOrdRef ? detailsInfo?.PrsOrdRef : '--'}}</div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Diagnosis Code</label>
                                                            <div class="label--data"> {{detailsInfo?.diagnosis ? detailsInfo?.diagnosis : '--'}}</div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label>Sig</label>
                                                            <div class="label--data"> {{detailsInfo?.RxSig ? detailsInfo?.RxSig : '--'}}</div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label>Message</label>
                                                            <div class="label--data"> {{detailsInfo?.RxMsg ? detailsInfo?.RxMsg : '--'}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-1">

                        </div> -->
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-2" [ngbTooltip]="PatientId ? patToolTip : null" placement="right-top" container="body" tooltipClass="tooltip-auto" [openDelay]="500">
                                    <a (click)="gotoPage(0,index)">
                                        <div class="step" [ngClass]="index==0 ? 'bg--focus': index>0 ? 'active':''">
                                            <div class="circle">
                                                <span [ngClass]="index==0 ? 'text-bold':''">1</span>
                                            </div>
                                            <div class="label" [ngClass]="index==0 ? 'text-bold color-primary line-height-2':'line-height-2'">Patient
                                                <!-- <i *ngIf="PatientId" class="far fa-check-circle" title="Exists"></i>
                                                <i *ngIf="!PatientId && processIdsObj.PatientCount === 0" class="far fa-times-circle" title="Doesnt Exist"></i>
                                                <i *ngIf="!PatientId && processIdsObj.PatientCount > 1" class="far fa-exclamation-circle" title="Multiple Match"></i> -->
                                            </div>
                                        </div>
                                    </a>
                                    <ng-template #patToolTip>
                                        <div>
                                            <div class="row text-left">
                                                <div class="col-md-12">
                                                    <label class="text-decoration-underline margin_bottom_1rem">Patient Info</label>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-4 pcard--sleek__grid">
                                                            <label>Name</label>
                                                            <div class="label--data">
                                                                {{rxPatientModel?.fullname? (rxPatientModel?.fullname.replace('_',', ') | uppercase):''}}
                                                            </div>
                                                        </div>
                                                        <div class="col-4 pcard--sleek__grid">
                                                            <label>DOB</label>
                                                            <div class="label--data">
                                                                {{ rxPatientModel?.dob | date: "MM/dd/yyyy" }}
                                                            </div>
                                                        </div>
                                                        <div class="col-4 pcard--sleek__grid">
                                                            <label>Gender</label>
                                                            <div class="label--data">
                                                                {{ rxPatientModel?.gender ? rxPatientModel?.gender  : "--" }}
                                                            </div>
                                                        </div>
                                                        <div class="col-12 pcard--sleek__grid sml-box semi-sml">
                                                            <label>Address</label>
                                                            <div class="label--data">
                                                                <span *ngIf="rxPatientModel?.address1 && rxPatientModel?.address1 !== '###'">
                                                                    {{rxPatientModel?.address1}},
                                                                </span>
                                                                <span *ngIf="rxPatientModel?.address2 && rxPatientModel?.address2 !== '###'">
                                                                    {{rxPatientModel?.address2}},
                                                                </span>
                                                                <span *ngIf="rxPatientModel?.city && rxPatientModel?.city !== '###'">
                                                                    {{rxPatientModel?.city}},
                                                                </span>
                                                                <span *ngIf="rxPatientModel?.state && rxPatientModel?.city !== '###'">
                                                                    {{rxPatientModel?.state}},
                                                                </span>
                                                                <span *ngIf="rxPatientModel?.zipcode && rxPatientModel?.zipcode !== '###'">
                                                                    {{rxPatientModel?.zipcode}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </ng-template>
                                </div>
                                <div class="col-md-3">
                                    <div class="divider"></div>
                                </div>
                                <div class="col-md-2 pr-0" [ngbTooltip]="PrescriberId ? preToolTip : null" placement="bottom" container="body" tooltipClass="tooltip-auto" [openDelay]="500">
                                    <a (click)="gotoPage(1,index)">
                                        <div class="step" [ngClass]="index==1 ? 'bg--focus': index>=1 ? 'active':''">
                                            <div class="circle">
                                                <span [ngClass]="index==1 ? 'text-bold':''">2</span>
                                            </div>
                                            <div class="label" [ngClass]="index==1 ? 'text-bold color-primary line-height-2':'line-height-2'">Prescriber
                                                <!-- <i *ngIf="PrescriberId" class="far fa-check-circle" title="Exists"></i>
                                                <i *ngIf="!PrescriberId && processIdsObj.PrescriberCount === 0" class="far fa-times-circle" title="Doesnt Exist"></i>
                                                <i *ngIf="!PrescriberId && processIdsObj.PrescriberCount > 1" class="far fa-exclamation-circle" title="Multiple Match"></i> -->
                                            </div>
                                        </div>
                                    </a>
                                    <ng-template #preToolTip>
                                        <div>
                                            <div class="row text-left">
                                                <div class="col-md-12">
                                                    <label class="text-decoration-underline margin_bottom_1rem">Prescriber Info</label>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-4 pcard--sleek__grid">
                                                            <label>Name</label>
                                                            <div class="label--data">
                                                                {{ (prescriberInfo?.prescriberlastname ? (prescriberInfo?.prescriberlastname | uppercase):'') + ", "+ (prescriberInfo?.prescriberfirstname
                                                                                                                ? (prescriberInfo?.prescriberfirstname | uppercase) :'')}}
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <label> Phone# </label>
                                                            <div class="label--data"> {{prescriberInfo?.telephone === "###"? "--" : (prescriberInfo?.telephone | mask : "(000) 000-0000")}} </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <label> DEA# </label>
                                                            <div class="label--data"> {{prescriberInfo?.prescriberdeanum === "###"? "--" :
                                                                _formatsUtil.getDeaFormat(prescriberInfo?.prescriberdeanum)}}
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <label> NPI# </label>
                                                            <div class="label--data"> {{prescriberInfo?.prescribernpinum? prescriberInfo?.prescribernpinum : "--"}}
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <label> SPI# </label>
                                                            <div class="label--data"> {{prescriberInfo?.prescriberspinum === "###"? "--" :
                                                                prescriberInfo?.prescriberspinum}}
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <label> Address </label>
                                                            <div class="label--data"> {{prescriberInfo.PrescriberAddresses["0"]?.AddressLine1}}
                                                                <span *ngIf="prescriberInfo.PrescriberAddresses['0']?.AddressLine2"> - {{prescriberInfo.PrescriberAddresses["0"]?.AddressLine2}} </span>
                                                                <span *ngIf="prescriberInfo.PrescriberAddresses['0']?.CityName"> - {{prescriberInfo.PrescriberAddresses["0"]?.CityName}} </span>
                                                                <span *ngIf="prescriberInfo.PrescriberAddresses['0']?.StateName"> - {{prescriberInfo.PrescriberAddresses["0"]?.StateName}} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </ng-template>
                                </div>
                                <div class="col-md-3">
                                    <div class="divider"></div>
                                </div>
                                <div class="col-md-2" [ngbTooltip]="DrugId ? drugToolTip : null" placement="left-top" container="body" tooltipClass="tooltip-auto" [openDelay]="500">
                                    <a (click)="gotoPage(2,index)">
                                        <div class="step" [ngClass]="index==2 ? 'bg--focus':  index>2 ? 'active':''">
                                            <div class="circle">
                                                <span [ngClass]="index==2 ? 'text-bold':''">3</span>
                                            </div>
                                            <div class="label" [ngClass]="index==2 ? 'text-bold color-primary line-height-2':'line-height-2'"> Drug
                                                <!-- <i *ngIf="DrugId" class="far fa-check-circle" title="Exists"></i>
                                                <i *ngIf="!DrugId && processIdsObj.DrugCount === 0" class="far fa-times-circle" title="Doesnt Exist"></i>
                                                <i *ngIf="!DrugId && processIdsObj.DrugCount > 1" class="far fa-exclamation-circle" title="Multiple Match"></i> -->
                                            </div>
                                        </div>
                                    </a>
                                    <ng-template #drugToolTip>
                                        <div>
                                            <div class="row text-left">
                                                <div class="col-md-12">
                                                    <label class="text-decoration-underline margin_bottom_1rem">Drug Info</label>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-lg-8 col-md-8 col-xs-12 sml-box semi-sml">
                                                            <label> Drug Name </label>
                                                            <div class="label--data">
                                                                {{drugInfo?.drugname + " "}}&nbsp;{{drugInfo?.strength}}&nbsp;{{drugInfo?.drugformname}}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                            <label> Drug NDC </label>
                                                                <div class="label--data" *ngIf="drugInfo?.drugcode">{{this._formatsUtil.getNDCFormat(drugInfo?.drugcode)}}</div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                                            <label> Size </label>
                                                            <div class="label--data"> {{drugInfo?.qtypack? drugInfo?.qtypack.toFixed(3) : "0.000"}} </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 sml-box semi-sml">
                                                            <label> AWP </label>
                                                            <div class="label--data"> {{(drugInfo?.unitpriceawp ? (drugInfo?.unitpriceawp | currency: "$":"symbol":"1.3-3") : "$0.000") + " ("+ (drugInfo?.awppack ? (drugInfo?.awppack | currency: "$":"symbol":"1.2-2") : "$0.00") +")"}} </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 sml-box semi-sml">
                                                            <label> Cost </label>
                                                            <div class="label--data"> {{(drugInfo?.unitpricecost ? (drugInfo?.unitpricecost | currency: "$":"symbol":"1.3-3") : "$0.000")  + " ("+ (drugInfo?.costpack ? (drugInfo?.costpack | currency: "$":"symbol":"1.2-2") : "$0.00") +")"}} </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                            <label> Manufacturer </label>
                                                            <div class="label--data"> {{drugInfo?.manufacturername? drugInfo?.manufacturername : "--"}} </div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                                <label> Drug Class </label>
                                                                <div class="label--data"> {{drugInfo?.drugclass? drugInfo?.drugclass : "0"}} </div>
                                                        </div>
                                                        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                            <label> Brand </label>
                                                            <div class="label--data"> {{drugInfo?.isbranded ? "Y" : "N"}} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </ng-template>
                                </div>
                                <div class="col-md-4 text-uppercase row mt-1" [ngClass]="index==0 ? 'text-bold':''">
                                    <div>
                                        ({{rxPatientModel?.fullname ? rxPatientModel?.fullname.replace('_',', ') : processIdsObj.PatientCount > 1 ? 'MULTIPLE MATCH':(erxData && erxData["Patient Name"]) ? (erxData["Patient Name"] |  uppercase) :  'NOT MATCHED'}})
                                    </div>
                                    <div class="ml-1">
                                        <i *ngIf="PatientId && isPatientMatched" class="far fa-check-circle" title="Exists"></i>
                                        <i *ngIf="(!PatientId && processIdsObj.PatientCount === 0) || !isPatientMatched" class="far fa-times-circle" title="Doesnt Exist"></i>
                                        <i *ngIf="!PatientId && processIdsObj.PatientCount > 1 && !rxPatientModel?.fullname" class="far fa-exclamation-circle" title="Multiple Match"></i>
                                    </div>
                                </div>
                                <div class="col-md-4 row mt-1 justify-content-center text-uppercase" [ngClass]="index==1 ? 'text-bold':''">
                                    <div>
                                        ({{(prescriberInfo?.prescriberlastname && prescriberInfo?.prescriberfirstname) ? (prescriberInfo?.prescriberlastname) + ',' + (prescriberInfo?.prescriberfirstname) : processIdsObj.PrescriberCount > 1 ? 'MULTIPLE MATCH':(erxData && erxData["Prescriber Name"]) ?  erxData["Prescriber Name"] : 'NOT MATCHED'}})
                                    </div>
                                    <div class="ml-1">
                                        <i *ngIf="PrescriberId && isPrescriberMatched" class="far fa-check-circle" title="Exists"></i>
                                        <i *ngIf="(!PrescriberId && processIdsObj.PrescriberCount === 0) || !isPrescriberMatched" class="far far fa-times-circle" title="Doesnt Exist"></i>
                                        <i *ngIf="!PrescriberId && processIdsObj.PrescriberCount > 1 && !prescriberInfo?.prescriberlastname" class="far fa-exclamation-circle" title="Multiple Match"></i>
                                    </div>
                                </div>
                                <div class="col-md-4 text-right text-uppercase" [ngClass]="index==2 ? 'text-bold':''" *ngIf="!isDrugInactive">
                                    ({{detailsInfo?.isCompoundDrug ? (selectedCompoundDrug ? selectedCompoundDrug.Name :
                                        (erxData && erxData["Drug Name"])? erxData["Drug Name"] : 'NOT MATCHED' ) : drugInfo?.drugname ? drugInfo?.drugname : processIdsObj.DrugCount > 1 ? 'MULTIPLE MATCH':processIdsObj.DrugCount === 1 ? '' : (erxData && erxData["Drug Name"])? erxData["Drug Name"] : 'NOT MATCHED'}})
                                    <i *ngIf="DrugId && processIdsObj.DrugCount === 1" class="far fa-check-circle" title="Exists"></i>
                                    <i *ngIf="!DrugId && processIdsObj.DrugCount === 0" class="far fa-times-circle" title="Doesnt Exist"></i>
                                    <i *ngIf="!DrugId && processIdsObj.DrugCount > 1" class="far fa-exclamation-circle" title="Multiple Match"></i>
                                </div>
                                <div class="col-md-4 text-right text-uppercase" [ngClass]="index==2 ? 'text-bold':''" *ngIf="isDrugInactive">
                                    ({{ detailsInfo?.isCompoundDrug ? (selectedCompoundDrug ? selectedCompoundDrug.Name : (erxData && erxData["Drug Name"])? erxData["Drug Name"] : 'NOT MATCHED') : detailsInfo?.DrugName ? detailsInfo?.DrugName : processIdsObj.DrugCount > 1 ? 'MULTIPLE MATCH':processIdsObj.DrugCount === 1 ? '' : (erxData && erxData["Drug Name"])? erxData["Drug Name"] : 'NOT MATCHED'}})
                                    <i *ngIf="!DrugId && processIdsObj.DrugCount === 0" class="far fa-times-circle" title="Doesnt Exist"></i>
                                    <i *ngIf="!DrugId && processIdsObj.DrugCount > 1" class="far fa-exclamation-circle" title="Multiple Match"></i>
                                </div>
                            </div>
                            <div class="row margin_top_2_5rem min-height_450px">
                                <div class="col-md-12 seconday-search--grid" *ngIf="index===0">
                                    <label >Please select Patient
                                        <span *ngIf="!PatientId && processIdsObj.PatientCount === 0"class="color_red">(Patient details received are not in the database)</span>
                                        <span *ngIf="!PatientId && inactivePat" class="color_red">( Patient received is inactive)</span>
                                    </label>
                                    <div class="row" >
                                        <div class="col-md-10">
                                            <app-searchbox-global [SearchFor]="'Patient'" [DisableSerchFil]="true" [DisableRadioButtons]="true" [IsAddNew]="false" (SelectedPatient)="selectedPatientInfo($event)"
                                                class="rx-presc--search" [InputValue]="patientInputValue" [IsFocused]="isInputFocused"
                                                [IsFromERX]="true" (OptedFormAddNew)="addNewPatPresDrug($event)">
                                            </app-searchbox-global>
                                        </div>
                                        <div class="col-md-2 add-new--icon pl-0 margin_top_0_7rem" >
                                            <button *ngIf="!PatientId" class="hotkey_success hotkey_primary--Override" title="Add Patient" (click)="addNewPatPresDrug('Patient')"  appShortcutKey [AltKey]="'a'"><span>A</span>ADD</button>
                                        </div>
                                    </div>
                                    <div class="exprx--common-block" *ngIf="PatientId && !inactivePat">
                                        <div class="eprx--block__header">
                                            <div class="eprx--header__heading">
                                                Patient - {{rxPatientModel?.fullname? (rxPatientModel?.fullname.replace('_',', ') | uppercase):''}}
                                            </div>
                                        </div>
                                        <app-rx-patient-card [RxPatientInfo]="rxPatientModel" [IsFromERx]="'true'"></app-rx-patient-card>
                                    </div>
                                </div>
                                <div class="col-md-12 seconday-search--grid" *ngIf="index===1">
                                    <label >Please select Prescriber
                                        <span *ngIf="!PrescriberId && processIdsObj.PrescriberCount === 0" class="color_red">(Prescriber details received are not in the database)</span>
                                        <span *ngIf="!PrescriberId && inactivePresc" class="color_red">(Prescriber received is inactive)</span>
                                    </label>
                                    <div class="row" >
                                        <div class="col-md-10">
                                            <app-searchbox-global [SearchFor]="'Prescriber'" [DisableSerchFil]="true" [DisableRadioButtons]="true" [IsAddNew]="false"
                                                (SelectedPrescriber)="selectedPresInfo($event)" class="rx-presc--search" [ClearInputValue]="prescriberInputValue"
                                                [IsFocused]="isInputFocused" [IsFromERX]="true" (OptedFormAddNew)="addNewPatPresDrug($event)">
                                            </app-searchbox-global>
                                        </div>
                                        <div class="col-md-2 pl-0 margin_top_0_7rem" (click)="addNewPatPresDrug('Prescriber')">
                                            <button *ngIf="!PrescriberId" class="hotkey_success  hotkey_primary--Override" title="Add Prescriber" appShortcutKey [AltKey]="'a'"><span>A</span>ADD</button>
                                        </div>
                                    </div>
                                    <div class="exprx--common-block" *ngIf="PrescriberId && !inactivePresc">
                                        <div class="eprx--block__header">
                                            <div class="eprx--header__heading">
                                                Prescriber - {{ (prescriberInfo?.prescriberlastname ? (prescriberInfo?.prescriberlastname | uppercase):'') + ", "+ (prescriberInfo?.prescriberfirstname
                                                ? (prescriberInfo?.prescriberfirstname | uppercase) :'')}}
                                            </div>
                                        </div>
                                        <app-rx-prescriber-card [PrescriberInfo]="prescriberInfo" [IsFromERx]="'true'"></app-rx-prescriber-card>
                                    </div>
                                </div>
                                <!-- <div class="col-12" *ngIf="index===2 && DrugId && detailsInfo?.RxDaw === 'N'">
                                    Please select drug from the list below, by using the Radio button OR by Enter key on the highlighted row OR by double clicking on the row that you wish to select and proceed to 'Next' button
                                </div> -->
                                <div class="col-md-12 seconday-search--grid" *ngIf="index===2 && (detailsInfo?.isCompoundDrug)">
                                    <span *ngIf="!(detailsInfo?.isCompoundDrug) else compoundDrugSelection">
                                        <div class="exprx--common-block" *ngIf="DrugId && equivalentList && equivalentList.length">
                                            <div class="eprx--block__header">
                                                <div class="eprx--header__heading">
                                                    Equivalent drug list
                                                </div>
                                            </div>
                                            <div class="eprx--block__content">
                                                <div class="row">
                                                    <div class="col">
                                                        <wj-flex-grid #equiDrugs [isReadOnly]="true" [itemsSource]="equivalentDrugWJSource"
                                                            [headersVisibility]="'Column'"
                                                             (initialized)="init(equiDrugs)" (dblclick)="onRowDblclicked(equiDrugs, $event)"
                                                             [selectionMode]="'Row'" (keydown.enter)="onRowDblclicked(equiDrugs,$event)"
                                                            (keydown)="CloseWindowWhenFocusOnWijmo($event)"  [columnPicker]="'equivalentDrugWj'" class="equivalent-drugWJm">
                                                            <wj-flex-grid-column [header]="''" [width]="30">
                                                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                                                                    <div>
                                                                        <input type="radio" id="item['id']" name="item['id']"
                                                                            (change)=" selectedDrug($event?.target?.checked,item,true ) " value="{{ row.index }}"
                                                                            [checked]="(item['id'] === DrugId)" />
                                                                    </div>
                                                                </ng-template>
                                                            </wj-flex-grid-column>
                                                            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                                                [width]="rxheader['width']" *ngFor="let rxheader of wjHeaders; let i = index" [align]="'center'">
                                                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                                                                    <div *ngIf="(rxheader['hName'] !== 'Preferred' || (rxheader['hName'] === 'Preferred' && item[rxheader['hName']] !== 'Yes'))" [ngClass]="rxheader['hName'] === 'Drug Name' ? item['DrugClassDyn'] : ''"> {{ item[rxheader['hName']]}} </div>
                                                                    <div *ngIf="rxheader['hName'] === 'Preferred' && item[rxheader['hName']] === 'Yes'" class="drug-card-green"> {{item[rxheader['hName']]}} </div>
                                                                    <div *ngIf="(rxheader['hName'] == 'Check Qty')" class="text-center font_size_1_2rem">
                                                                        <i class="far fa-check " title="Active" (click)="openInventoryDetails(item)"></i>
                                                                    </div>
                                                                </ng-template>
                                                            </wj-flex-grid-column>
                                                        </wj-flex-grid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12" *ngIf="DrugId && !(detailsInfo?.RxDaw === 'Y') ">
                                                <label  class ="margin_top_1rem font-weight-normal color_black" >OR</label>
                                            </div>
                                            <div class="col-12">
                                                <label *ngIf="DrugId && !(detailsInfo?.RxDaw === 'Y')" class ="margin_top_1rem font-weight-normal color_black">If you do not wish to
                                                    select from the above list, please search for a different drug from the search box below</label><br>
                                                <span *ngIf="(!DrugId && processIdsObj.DrugCount === 0)" class="color_red">(Drug NDC received is not in the
                                                    database)</span>
                                                <span *ngIf="DrugId && eRxDurgId === DrugId && isDrugInactive" class="erx-inactive-drug">(Received Drug is Inactive)</span>
                                                <span *ngIf="DrugId && eRxDurgId !== DrugId && isDrugInactive" class="erx-inactive-drug">(Selected Drug is Inactive)</span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-10">
                                                <div class="row" *ngIf="!(detailsInfo?.RxDaw === 'Y')">
                                                    <div class="col-4 color_cyan">
                                                    </div>
                                                    <div class="col-8">
                                                        <!-- <div class="text-right">
                                                            <div><i class="far fa-lg fa-long-arrow-alt-right color_orange"></i><span> for Equv ;
                                                                </span><b class="color_orange">Shift + <i class="far fa-lg fa-long-arrow-alt-right color_orange"
                                                                    ></i></b><span> for Gen Equv</span></div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            
                                                <app-searchbox-global *ngIf="detailsInfo?.isCompoundDrug"
                                                    [IsHideDrugFilters]="detailsInfo?.RxDaw === 'Y' " 
                                                    [inputdisabled]=" detailsInfo?.RxDaw === 'Y'" 
                                                    [isEqAvail]="isEquiAval"
                                                    [DAWFromeRx]="(!DrugId && processIdsObj.DrugCount === 0) && detailsInfo?.RxDaw == 'Y'"
                                                    [SearchFor]="'Drug'" [DisableSerchFil]="true" [DisableRadioButtons]="true" [IsAddNew]="false"
                                                    (SelectedDrug)="selectDrugAndCheckEquiv($event)" (SelectedDrugFormBarcode)="drugFormBarcode($event)" class="rx-presc--search" [InputValue]="drugInputValue"
                                                    [IsFocused]="isInputFocused" [IsFromERX]="true" (OptedFormAddNew)="addNewPatPresDrug($event)"
                                                    (emitDrugSugstFilterFrERx)="saveDrugSuggestVal($event)" [SearchFrm]="'commonDrugSearch'">
                                                </app-searchbox-global>
                                            </div>
                                            <div *ngIf="!DrugId || detailsInfo?.RxDaw === 'Y'" class="col-md-2 add-new--icon pl-0 margin_top_1rem" (click)="addNewPatPresDrug('Drug')">
                                                <button class="hotkey_success  hotkey_primary--Override" title="Add Drug" appShortcutKey [AltKey]="'a'"><span>A</span>ADD</button>
                                            </div>
                                        </div>
                                        <div class="exprx--common-block margin_top_1_3rem" *ngIf="(DrugId && !isDrugInactive && detailsInfo?.RxDaw == 'N') || (DrugId &&
                                                processIdsObj.DrugCount === 0 && detailsInfo?.RxDaw == 'N' ) || dawYaddingDrug || (detailsInfo?.RxDaw == 'Y' && DrugId)">
                                            <div class="eprx--block__header">
                                                <div class="eprx--header__heading">
                                                    Selected Drug Details -
                                                    <span [title]="drugInfo?.Name + ' - '+ drugInfo?.Strength + ' - '+ drugInfo?.drugForm">
                                                        {{drugInfo?.drugname | uppercase}}</span><span *ngIf="processIdsObj.DrugCount === 1 && processIdsObj.DrugId === drugInfo?.id">&nbsp;(Prescribed Drug)</span>
                                                </div>
                                            </div>
                                            <app-rx-drug-card [DrugInfo]="drugInfo" [isFromErx]="true" [DrugFormBarcode]="isDrugFormBarcode"></app-rx-drug-card>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="row bottom-0 position-relative w-100">
                                <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <button *ngIf="index > 0" class="hotkey_success" (click)="previous()" appShortcutKey [AltKey]="'p'"
                                        ngbTooltip="Previous">
                                        <span>P</span> Previous</button>
                                    <button class="inactive"  [title]="IsNextButtonDisableforFurtherActions(0,'title')"
                                        *ngIf="IsNextButtonDisableforFurtherActions(index)"
                                        footer><span>N</span>Next</button>
                                    <button id="nxtBtn" class="hotkey_success"
                                        *ngIf="IsNextButtonEnableforFurtherActions(index)"
                                        footer (click)="next()" appShortcutKey [AltKey]="'n'" ngbTooltip="Next">
                                        <span>N</span> Next</button>
                                        
                                    <button id="histBtn" *ngIf="PatientId" class="hotkey_success" (click)="openPatientHist(PATHISOTRY)"
                                        appShortcutKey [AltKey]="'h'" ngbTooltip="Patient History">
                                        <span>H</span> Pat Hist.</button>
                                    <button class="hotkey_success" (click)="closeModal(true)" appShortcutKey [AltKey]="'c'" ngbTooltip="Cancel">
                                    <span>C</span> Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        </span>
    </app-custom-modal>
</ng-template>


<ng-template #compoundDrugSelection>
    <div class="col-12" *ngIf="index===2 && (detailsInfo?.isCompoundDrug)">
        <div class="row">
            <div class="col-4">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Prescribed Drug</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12">
                                <label>Name</label>
                                <div class="label--data"> {{detailsInfo?.DrugName ? detailsInfo?.DrugName : '--'}}</div>
                            </div>
                            <div class="col-12">
                                <label>NDC</label>
                                <div class="label--data"> --</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Prescribed Compound Ingredients</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12" *ngIf="detailsInfo?.compoundIngredients && detailsInfo?.compoundIngredients.length>0 else noRecords">
                                <wj-flex-grid #previewControl [headersVisibility]="'Column'" [itemsSource]="detailsInfo?.compoundIngredients" [isReadOnly]="true"
                                    [selectionMode]="'None'">
                                    <wj-flex-grid-column [header]="'DRUG NAME'" [width]="'*'" [binding]="'DrugName'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'NDC'" [width]="'*'" [binding]="'DrugNdc'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'STRENGTH'" [width]="'*'" [binding]="'DrugStrength'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'FORM'" [width]="'*'" [binding]="'StrengthForm'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'QUANTITY'" [width]="'*'" >
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                            {{ item['Quantity'] | number : '1.3-3'}}
                                        </ng-template>
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'CLASS'" [width]="'*'" [binding]="'DrugClass'">
                                    </wj-flex-grid-column>
                                </wj-flex-grid>
                            </div>

                            <ng-template #noRecords>
                                <div class="eprx--block__footer">
                                    <div class="col-12 no-data-found">
                                        <span></span>
                                        <i class="fa fa-file"></i><br/>
                                        No Data Found!
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-right">
                <button class="hotkey_success" (click)="printErxCompoundIngredients()" appShortcutKey [AltKey]="'i'"><span>I</span> Print Prescribed Drug Info </button>
                <button autofocus class="hotkey_success" (click)="addNewPatPresDrug('Drug')"  appShortcutKey [AltKey]="'a'"><span>A</span> Add New Compound Drug</button>
            </div>
            <div class="col-12 margin_top_1rem">
                <div class="row">
                    <div class="col-4">
                        <eprx-radio [LabelText]="'Please Select a compound Drug'" [FormGroupName]="CompDrugFG" [ControlName]="'compDrugType'" [IDForList]="[123,124]" [ValueList]="['Similar','All']" [List]="['Similar','All']" (TriggerSelectValue)="optedCompDrug($event)"></eprx-radio>
                    </div>
                    <div class="col-4 margin_top_1rem">
                        <eprx-input  [LabelText]="''" [PlaceHolder]="'Search drug here'" [FormGroupName]="CompDrugFG" [ControlName]="'compSearchText'"></eprx-input>
                    </div>
                    <div class="col-2 margin_top_1rem">
                        <button title="Search / Refresh" class="hotkey_primary" (click)="getCompoundDrugsByIngredients()">Search</button>
                    </div>
                </div>
            </div>
            <div class="col-4" *ngIf="!compAddedNew">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Search Results</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12">
                            <wj-flex-grid #compList [headersVisibility]="'Column'" [itemsSource]="compSelctionList"
                                [isReadOnly]="true" [selectionMode]="'Row'" (dblclick)="showIngreds(compList,$event)"
                                (click)="showIngreds(compList,$event)" (keydown.enter)="showIngreds(compList,$event)" (initialized)="initCompDrugs(compList)">
                                <wj-flex-grid-column [header]="'DRUG NAME'" width="*">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell" >
                                        <span  class="text-center" [style.background]="addedCompDrug?.DrugCode === item['DrugCode'] ? 'lightgreen':''"
                                        [title]="addedCompDrug?.DrugCode === item['DrugCode'] ? 'Added Drug' : ''" >
                                            {{item["Name"]}}
                                        </span>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'NDC'" width="*">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell" >
                                        <span class="text-center" [style.background]="addedCompDrug?.DrugCode === item['DrugCode'] ? 'lightgreen':''"
                                        [title]="addedCompDrug?.DrugCode === item['DrugCode'] ? 'Added Drug' : ''">
                                            {{item["DrugCode"]}}
                                        </span>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            </div>
                            <div class="col-12" *ngIf="compTotalCount > 15">
                                <ngb-pagination class="d-flex justify-content-center" [collectionSize]="compTotalCount"
                                    [(page)]="compDrugPageNumber" [maxSize]="5" [rotate]="true" [pageSize]="15" [ellipses]="false"
                                    [boundaryLinks]="true" (pageChange)="setCompPage($event)">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4" *ngIf="compAddedNew">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Added Drug</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12">
                                <label>Name</label>
                                <div class="label--data"> {{selectedCompoundDrug?.Name ? selectedCompoundDrug?.Name : '--'}}</div>
                            </div>
                            <div class="col-12">
                                <label>NDC</label>
                                <div class="label--data"> {{selectedCompoundDrug?.DrugCode ? selectedCompoundDrug?.DrugCode : '--'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Selected Compound Drug</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12">
                            <wj-flex-grid #ingredList [headersVisibility]="'Column'" [itemsSource]="selectedCompoundDrug?.compoundIngredients" [isReadOnly]="true"
                                [selectionMode]="'None'">
                                <wj-flex-grid-column [header]="'DRUG NAME'" [width]="'*'" [binding]="'Name'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'NDC'" [width]="'*'" >
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        {{ item['DrugCode'] | mask:'AAAA0-0000-00'}}
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'QUANTITY'" [width]="'*'" [binding]="'DefaultQty'">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        {{ item['DefaultQty'] | number : '1.3-3'}}
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'LOT NO'" [width]="'*'" [binding]="'LotNo'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'INGR. PROP.'" [width]="'*'" [binding]="'IngPropPer'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'COST'" [width]="'*'" [binding]="'CostPack'">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        {{ item['CostPack'] ? (item['CostPack'] | currency: "$":"symbol":"1.2-2")   : "$0.00"}}
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'IS OPTIONAL'" [width]="'*'" [binding]="'IsOptional'">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        {{ item['IsOptional'] == "False"? "No": "Yes"}}
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-template>
<ng-template #PatNotMatch let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePatPresPopups('Patient')">
        <h4 header>Warning!!</h4>
        <span body>
            Selected patient details are not matching with the eRx details. Do you wish to continue?
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button id="patientNotMatch" ngbAutofocus class="hotkey_primary"  (click)="gotoNext()" appShortcutKey
                    InputKey="y"><b>Y</b> YES</button>
                <button class="hotkey_primary" (click)="closePatPresPopups('Patient')" appShortcutKey InputKey="n">
                    <b>N</b> NO</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>

<ng-template #PatPhoneAddMatch let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalRef2()">
        <h4 header>Patient Information Changed</h4>
        <span body>
            <div class="row margin_bottom_2rem">
                <div class="col-md-12">
                    Following information for patient: <strong> {{rxPatientModel?.fullname? (rxPatientModel?.fullname.replace('_',', ') | uppercase):''}}<br> (DOB: {{rxPatientModel?.dob ? (rxPatientModel?.dob | date:'MM/dd/yyyy') : ''}})</strong> is different on the file as compared to what is received through this
                    eRx.
                </div>
            </div>
            <div class="row" *ngIf="detailsInfo?.PatPhone && rxPatientModel?.telephone != detailsInfo?.PatPhone" >
                <div class="col-md-6">
                    <label>Phone# on File</label>
                    <div class="label--data"> {{rxPatientModel?.telephone ? rxPatientModel?.telephone : ''}}</div>
                </div>
                <div class="col-md-6">
                    <label>Received Phone# Info</label>
                    <div class="label--data"> {{detailsInfo?.PatPhone ? detailsInfo?.PatPhone : ''}}</div>
                </div>
            </div>
            <div class="row" *ngIf="(detailsInfo?.PatAddrL1 && ( rxPatientModel?.address1 != detailsInfo?.PatAddrL1)) ||
                                    (detailsInfo?.PatAddrL2 &&  (rxPatientModel?.address2 != detailsInfo?.PatAddrL2)) ||
                                    (detailsInfo?.PatZip &&  (rxPatientModel?.zipcode !== detailsInfo?.PatZip))">
                <div class="col-md-6">
                    <label>Address on File</label>
                    <div class="label--data">
                {{rxPatientModel?.address1?(rxPatientModel?.address1).trim():''}}{{rxPatientModel?.address2?', '+(rxPatientModel?.address2.trim()):''}}{{rxPatientModel?.city?', '+(rxPatientModel?.city.trim()):''}}{{rxPatientModel?.state?', '+(rxPatientModel?.state.trim()):''}}{{rxPatientModel?.zipcode?', '+(rxPatientModel?.zipcode.trim())+'.':''}}
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Received Address Info</label>
                    <div class="label--data">
                    {{detailsInfo?.PatAddrL1? detailsInfo?.PatAddrL1.trim():''}}{{detailsInfo?.PatAddrL2? ', '+detailsInfo?.PatAddrL2.trim():''}}{{detailsInfo?.PatCity? ', '+detailsInfo?.PatCity.trim():''}}{{detailsInfo?.PatState? ', '+detailsInfo?.PatState.trim():''}}{{detailsInfo?.PatZip? ', '+detailsInfo?.PatZip.trim()+'.':''}}
                    </div>
                </div>
            </div>
            <div class="row margin_top_2rem">
                <div class="col-md-12">
                    The file data is different from the received data. Would you like to update?
                </div>
            </div>
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button id="updatePatienInfo" class="hotkey_primary" appShortcutKey (click)="updatePatientDetails()"
                    InputKey="y"><b>Y</b> YES </button>
                <button id="cancelPatienInfo" class="hotkey_primary" appShortcutKey InputKey="n" (click)="closeModalRef2()">
                    <b>N</b> NO</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>


<ng-template #PrescribDeaMatch let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="actModal2 = null;closeModalRef2()">
        <h4 header>Prescriber Information Changed</h4>
        <span body>
            <div class="row margin_bottom_2rem">
                <div class="col-md-12">
                    Following information for prescriber: <strong> {{ (prescriberInfo?.prescriberlastname ? (prescriberInfo?.prescriberlastname | uppercase):'') + ", "+ (prescriberInfo?.prescriberfirstname
                        ? (prescriberInfo?.prescriberfirstname | uppercase) :'')}}</strong> is different on the application file as compared to what is received through this
                    eRx.
                </div>
            </div>
            <div class="row" *ngIf="!isPrescrNameNotMatched">
                <div class="col-md-6">
                    <label>Name on File</label>
                    <div class="label--data"> {{ (prescriberInfo?.prescriberlastname ? (prescriberInfo?.prescriberlastname | uppercase):'') + ", "+ (prescriberInfo?.prescriberfirstname
                        ? (prescriberInfo?.prescriberfirstname | uppercase) :'')}}
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Received Name </label>
                    <div class="label--data">
                        {{ (detailsInfo?.PresLastName ? (detailsInfo?.PresLastName | uppercase):'') + ", "+ (detailsInfo?.PresFirstName
                        ? (detailsInfo?.PresFirstName | uppercase) :'')}}
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!isPrescriberDEANotMatched">
                <div class="col-md-6">
                    <label>DEA# on File</label>
                    <div class="label--data">  
                        {{ prescriberInfo?.prescriberdeanum ? _formatsUtil.getDeaFormat(prescriberInfo?.prescriberdeanum) : "--" }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Received DEA# </label>
                    <div class="label--data">
                        {{ (detailsInfo?.PrescDea ? (detailsInfo?.PrescDea | uppercase):'') }}
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="prescAddressDetails">
                <div class="col-md-6">
                    <label>Address on File</label>
                    <div class="label--data">
                        {{prescAddressDetails?.AddressLine1 ? (prescAddressDetails?.AddressLine1).trim():''}}
                        {{prescAddressDetails?.AddressLine2 ? ', '+(prescAddressDetails?.AddressLine2.trim()):''}}
                        {{prescAddressDetails?.CityName ? ', '+(prescAddressDetails?.CityName.trim()):''}}
                        {{prescAddressDetails?.StateName ? ', '+(prescAddressDetails?.StateName.trim()):''}}
                        {{prescAddressDetails?.ZipCode ? ', '+(prescAddressDetails?.ZipCode.trim())+'.':''}}
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Received Address Info</label>
                    <div class="label--data">
                    {{detailsInfo?.PresAddrL1 ? detailsInfo?.PresAddrL1.trim():''}}
                    {{detailsInfo?.PresAddrL2 ? ', '+detailsInfo?.PresAddrL2.trim():''}}
                    {{detailsInfo?.PresCity ? ', '+detailsInfo?.PresCity.trim():''}}
                    {{detailsInfo?.PresState ? ', '+detailsInfo?.PresState.trim():''}}
                    {{detailsInfo?.PresZip ? ', '+detailsInfo?.PresZip.trim()+'.':''}}
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="prescPhoneNumCheck">
                <div class="col-md-6">
                    <label>Phone# on File</label>
                    <div class="label--data"> {{prescPhoneNumCheck?.Telephone ? prescPhoneNumCheck?.Telephone : ''}}</div>
                </div>
                <div class="col-md-6">
                    <label>Received Phone# Info</label>
                    <div class="label--data"> {{detailsInfo?.PrescPhone ? detailsInfo?.PrescPhone : ''}}</div>
                </div>
            </div>
            <div class="row margin_top_2rem">
                <div class="col-md-12">
                    The file data is different from the received data. Would you like to update?
                </div>
            </div>
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button id="updatePresInfo" class="hotkey_primary" appShortcutKey (click)="updatePrescriberDetails()"
                    InputKey="y"><b>Y</b> YES</button>
                <button id="cancelPresInfo" class="hotkey_primary" appShortcutKey InputKey="n" (click)="actModal2 = null;closeModalRef2()" autofocus>
                    <b>N</b> NO</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>

<ng-template #SPInotFound let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modalRef2?.close();">
        <h4 header>Confirmation</h4>
        <span body>
            The SPI <b>{{ this.detailsInfo?.PrescSpi }}</b> from the XML is not currently associated with this prescriber. Would you like to add this SPI to the prescriber profile?
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button class="hotkey_primary" (click)="SPIUpdation()" appShortcutKey InputKey="y"><b>Y</b> YES</button>
                <button id="SPINotMatch" class="hotkey_primary" (click)="modalRef2?.close();" appShortcutKey InputKey="n">
                    <b>N</b> NO</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>

<ng-template #PreNotMatch let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePatPresPopups('Prescriber')">
        <h4 header>Warning!!</h4>
        <span body>
            Selected prescriber details are not matching with the eRx details. Do you wish to continue?
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button id="prescriberNotMatch" ngbAutofocus class="hotkey_primary" (click)="gotoNext()" appShortcutKey InputKey="y"><b>Y</b> YES</button>
                <button class="hotkey_primary" (click)="closePatPresPopups('Prescriber')" appShortcutKey InputKey="n">
                    <b>N</b> NO</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>


<ng-template #DrugNdcNotMatch let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modalRef2.close();actModal2=null">
        <h4 header>Warning!!</h4>
        <span body>
            Selected Drug details are not matching with the eRx details. Do you wish to continue?
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button autofocus class="hotkey_primary" (click)="modalRef2.close();actModal2=null"
                    (click)="generateRxToProcess()" appShortcutKey InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" (click)="modalRef2.close();actModal2=null" appShortcutKey InputKey="n">
                    <b>N</b> NO</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>

<ng-template #DrugNotPrefered let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modalRef2.close();actModal2=null">
        <h4 header>Warning!!</h4>
        <span body>
            Please select a preferred drug to continue.
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button class="hotkey_primary" (click)="modalRef2.close();actModal2=null"  appShortcutKey InputKey="o">
                    <b>O</b> Ok </button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>

<ng-template #MaxRefillsForDrugClass let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modalRef2.close();actModal2=null;checkIsPrn()">
        <h4 header>Warning!!</h4>
        <span body>
            Received number of refills are {{erxData["Refill"] ? erxData["Refill"] : 0}}. Max allowed Refills for the selected drug class is
            {{refillSettingLimit["MaxRefillAllow"] ? refillSettingLimit["MaxRefillAllow"] : 0}}.
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button class="hotkey_primary" (click)="modalRef2.close();actModal2=null" (click)="checkIsPrn()"  appShortcutKey InputKey="o">
                    <b>O</b> Ok </button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>

<ng-template #SelectDefaultSig let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modalRef2.close();actModal2=null;checkMaxRefillsForDrug(2)">
        <h4 header>Select SIG</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    <h3>Please select SIG to use for this eRx.</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label>Name </label>
                    <div class="label--data">{{drugInfo?.drugname}}
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <label>Qty</label>
                    <div class="label--data"> {{detailsInfo?.RxQty ? (detailsInfo?.RxQty | number : '1.3-3')
                            : '--'}}</div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <label>Days</label>
                    <div class="label--data"> {{detailsInfo?.RxDays ? detailsInfo?.RxDays: '--' }}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <eprx-text-area [LabelText]="'Rx SIG'" [FormGroupName]="SigFG" [ControlName]="'RxSig'" [PlaceHolder]="'Rx Sig'" [MaxLength]="210" [Rows]="3"></eprx-text-area>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <eprx-input [LabelText]="'Drug Default SIG Code'" [PlaceHolder]="'Drug Default SIG Code'" [FormGroupName]="SigFG" [ControlName]="'DefaultSigCode'"
                        (TriggerChangeValue)="getSigCodeDescOnChange($event)" (TriggerOnEnterValue)="getSigCodeDescOnChange($event)" (TriggerOnBlur)="getSigCodeInfo($event?.target?.value)"></eprx-input>
                </div>
                <div class="col-md-6">
                    <eprx-text-area [LabelText]="'English'" [FormGroupName]="SigFG" [ControlName]="'DefaultSig'"  [PlaceHolder]="'English Sig'"  [MaxLength]="210"></eprx-text-area>
                </div>
                <div class="col-md-6">
                    <eprx-text-area [LabelText]="'Alternate Language'" [FormGroupName]="SigFG" [ControlName]="'AlternateSig'" [PlaceHolder]="'Alternate Sig'" [MaxLength]="210"></eprx-text-area>
                </div>
            </div>
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button autofocus class="hotkey_success" (click)="modalRef2.close();actModal2=null" (click)="checkMaxRefillsForDrug(1)" appShortcutKey [AltKey]="'r'">
                    <span>R</span> Rx SIG</button>
                <button  class="hotkey_success" (click)="modalRef2.close();actModal2=null" (click)="checkMaxRefillsForDrug(2)" appShortcutKey [AltKey]="'d'">
                    <span>D</span>Drug Default SIG</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>

<app-override-log
    *ngIf="openOverride && !isFrmPatientChange"
    [PrivMask]="privType"
    [CatId]="categoryId"
    [OverrideEndpoint]="overrideEndpoint"
    (OverrideLoginDet)="OverrideRes($event)"
></app-override-log>



<ng-template #IsPrn let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="modalRef2.close();actModal2=null;generateRxtoProcess()">
            <h4 header>Authorized Refill Required</h4>
            <span body>
                <div class="row">
                    <div class="col-md-12">
                        <p>
                            The eRx you are processing is marked as PRN by prescriber. Please add the number of Authorized refills for this Rx to continue.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 padding_top_1_5rem" [formGroup]="SigFG">
                            <input class="form-check-input" type="checkbox" [id]="'IsPrnActive'" [formControlName]="'IsPrn'">
                            <label class="form-check-label" [for]="'IsPrnActive'">Is Prn?</label>
                    </div>
                    <div class="col-md-4">
                            <eprx-select
                                [RxSelectId]="'rxReFillNum'"
                                [LabelText]="'Auth Refills'"
                                [PlaceHolder]="''"
                                [ControlName]="'AuthRefills'"
                                [FormGroupName]="SigFG"
                                [List]="authRefills"
                                [HasMultiple]="false"
                                [BindLabel]="'Name'"
                                [BindValue]="'Id'"
                                [LabelForId]="'Name'"
                                [IsSearchable]="false"
                                [AutoFocus]="true"
                            >
                            </eprx-select>
                            <!-- <eprx-input [LabelText]="'Default SIG Code'" [PlaceHolder]="'Default SIG Code'" [FormGroupName]="SigFG" [ControlName]="'DefaultSigCode'" [InputValue]="drugInfo?.drugusesigcode"
                                (TriggerChangeValue)="getSigCodeDescOnChange($event)" (TriggerOnEnterValue)="getSigCodeDescOnChange($event)"></eprx-input> -->
                        </div>
                </div>
            </span>
            <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
                <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <button  class="hotkey_success" (click)="modalRef2.close();actModal2=null" (click)="generateRxtoProcess()" appShortcutKey [AltKey]="'o'">
                        <span>o</span> Ok</button>
                </div>
            </div>
        </app-custom-modal>
    </ng-template>



<ng-template #DrugQuantity let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="selectDrugQuantityAndProceed()">
        <h4 header>Drug Quantity Selection</h4>
        <span body>
            <div class="row">
                <div class="col-md-12 font_size_1_3rem">
                    <p>
                        The Drug Quantity specified by the doctor (eRx) is different from the Default Quantity defined in your drug file.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 custom-radio-eRx">
                    <eprx-radio [LabelText]="'Please select Drug Quantity'" [FormGroupName]="defaultDrugEditQty"  [List]="['eRx Drug Quantity (' + (detailsInfo.RxQty ? detailsInfo.RxQty : '0') + ')', 'Default Drug Quantity (' + (detailsInfo.isCompoundDrug ? (selectedCompoundDrug.DefaultQty ? selectedCompoundDrug.DefaultQty : '0') : drugInfo.defaultqty ? drugInfo.defaultqty : '0') + ')']" [ValueList]="[(detailsInfo.RxQty ? +detailsInfo.RxQty : 0) , (detailsInfo.isCompoundDrug ? (selectedCompoundDrug.DefaultQty ? +selectedCompoundDrug.DefaultQty : 0) : drugInfo.defaultqty ? +drugInfo.defaultqty : 0 ) ]"
                        [IDForList]="['eRx Drug Quantity', 'Default Drug Quantity']" [ControlName]="'selectedDrugQuantity'"
                        [IsRequired]="true" [InputErrors]="defaultDrugEditQty?.controls?.selectedDrugQuantity?.errors" [AutoFocus]="true"
                        [ErrorDefs]="{required: 'Required'}" (TriggerOnEnterValue)="selectDrugQuantityAndProceed()">
                    </eprx-radio>
                </div>
            </div>
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button ngbAutofocus class="hotkey_primary" (keydown.enter)="selectDrugQuantityAndProceed()"  (click)="selectDrugQuantityAndProceed()" appShortcutKey InputKey="o">
                    <b>O</b> Ok</button>
                    <!-- <button ngbAutofocus class="hotkey_primary" (click)="modalRef2.close();actModal2=null" >
                            <span>C</span> Cancel</button> -->
            </div>
        </div>
    </app-custom-modal>
</ng-template>

<ng-template #PATHISOTRY let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close');actModal2=null">
        <h4 header>Patient Rx History</h4>
        <span body>
            <div class="eprx--block__content">
                <div class="grp-tran-pat-hist">
                    <app-rx-history [PatientId]="PatientId" [ShowDateRange]="true" [MultiCheck]="false"
                        [ActiveScreen]="'GrpTransPatHis'" [PageSize]="'60'" [SearchParams]="rxPatientModel"
                        [IsFromModal]="true" (hasRxHistory)="hasRxHistory=$event"></app-rx-history>
                </div>
            </div>
        </span>
        <button footer class="hotkey_success" (click)="c('close');actModal2=null" id="PatHistCloseBtn" appShortcutKey [AltKey]="'c'">
                <span>C</span> Close
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #CancelReqTemp let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal(true)">
        <h4 header>{{eMessageType == 'CancelRx' ? 'Cancel Rx' : 'Rx Change Response'}} </h4>
        <span body>
            <div class="row">
                <div class="col-md-5">
                    <app-electronic-rx-doc *ngIf="detailsInfo" [ErxDocData]="detailsInfo" [IsNotesDisabled]="true"></app-electronic-rx-doc>
                </div>
                <div class="col-md-7">
                    <div class="exprx--common-block erx--body insurance--body">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Transaction Information</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Response Status</label>
                                    <div class="label--data">{{ responseState ? responseState : "--" }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Message Type</label>
                                    <div class="label--data">{{detailsInfo?.MessageDescription ? detailsInfo?.MessageDescription : '--'}}</div>
                                </div>
                                <div class="col-md-12">
                                    <label>Transaction</label>
                                    <div class="label--data">{{detailsInfo?.Transaction ? detailsInfo?.Transaction : '--'}}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Date</label>
                                    <div class="label--data">{{eRxWJDetails["Date & Time"] ? (eRxWJDetails["Date & Time"] | localTime | date: "MM/dd/yyyy hh:mm:ss a") : '--'}}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Document Ids</label>
                                    <div class="label--data">{{detailsInfo?.DocIDs ? detailsInfo?.DocIDs : '--'}}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label>Message Ref</label>
                                    <div class="label--data">{{detailsInfo?.MsgRef ? detailsInfo?.MsgRef : '--'}}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label>Other Information</label>
                                    <textarea class="custom--text__area form-control" [rows]="3" [disabled]="true" [value]="detailsInfo?.Message"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <label>Messages</label>
                                    <textarea class="custom--text__area form-control" [rows]="3" [disabled]="true" [value]="detailsInfo?.OtherInfo"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="exprx--common-block erx--body insurance--body">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Original RX Information</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Prescriber</label>
                                    <div class="label--data">{{cancelOrgRxInfo && cancelOrgRxInfo[0]?.Prescriber?.fullname ? cancelOrgRxInfo[0]?.Prescriber?.fullname.replace('_',', ') : '--'}}</div>
                                </div>
                                <div class="col-md-6">
                                    <label>Last Filled Date</label>
                                    <div class="label--data">{{cancelOrgRxInfo && cancelOrgRxInfo[0]?.PrescReFill?.FillDtTm ? (cancelOrgRxInfo[0]?.PrescReFill?.FillDtTm !== "0001-01-01T00:00:00" ? (cancelOrgRxInfo[0]?.PrescReFill?.FillDtTm | localTime | date: 'MM/dd/yyyy') : '--') : '--'}}</div>
                                </div>
                                <div class="col-md-6">
                                    <label>Patient</label>
                                    <div class="label--data">{{cancelOrgRxInfo && cancelOrgRxInfo[0]?.Patient?.fullname ? cancelOrgRxInfo[0]?.Patient?.fullname.replace('_',', ') : '--'}}</div>
                                </div>
                                <div class="col-md-6">
                                    <label>Filled/Auth Refills:</label>
                                    <div class="label--data"> {{(cancelOrgRxInfo[0]) ? cancelOrgRxInfo[0]?.filledRefills : '--'}}/{{cancelOrgRxInfo && (cancelOrgRxInfo[0]?.Prescription?.ReFillNum || cancelOrgRxInfo[0]?.Prescription?.ReFillNum === 0) ? cancelOrgRxInfo[0]?.Prescription?.ReFillNum : '--'}}</div>
                                </div>
                                <div class="col-md-6">
                                    <label>Drug:</label>
                                    <div class="label--data">
                                        {{(cancelOrgRxInfo[0] && cancelOrgRxInfo[0].Drug && cancelOrgRxInfo[0].Drug.drugfullname ? cancelOrgRxInfo[0].Drug.drugfullname : '--')}}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>NDC:</label>
                                    <div class="label--data">{{cancelOrgRxInfo && cancelOrgRxInfo[0]?.Drug?.ndc ? this._formatsUtil.getNDCFormat(cancelOrgRxInfo[0]?.Drug?.ndc) : '--'}}</div>
                                </div>
                                <div class="col-md-6">
                                    <label>Rx#</label>
                                    <div class="label--data">{{cancelOrgRxInfo && cancelOrgRxInfo[0]?.Prescription?.PrescNum ? cancelOrgRxInfo[0]?.Prescription?.PrescNum : '--'}}</div>
                                </div>
                                <div class="col-md-6">
                                    <label>Days Supplied</label>
                                    <div class="label--data">{{cancelOrgRxInfo && cancelOrgRxInfo[0]?.PrescReFill?.SupplyDays ? cancelOrgRxInfo[0]?.PrescReFill?.SupplyDays : '--'}}</div>
                                </div>
                                <div class="col-md-8">
                                    <label>Sig</label>
                                    <div class="label--data">{{cancelOrgRxInfo && cancelOrgRxInfo[0]?.SigCodes?.Description ? cancelOrgRxInfo[0]?.SigCodes?.Description : '--'}}</div>
                                </div>
                                <div class="col-md-4" *ngIf="!(detailsInfo.OriginalRxDetails && detailsInfo.OriginalRxDetails.length > 0) && !(detailsInfo.rxInformtion && detailsInfo.rxInformtion.length > 0)">
                                    <button footer class="hotkey_primary float-right margin_top_4rem" (click)="openCancelPatHist()" appShortcutKey InputKey="s">
                                        <b>S</b> Search Rx
                                    </button>
                                </div>
                                <div class="col-md-8" *ngIf="(detailsInfo.OriginalRxDetails && detailsInfo.OriginalRxDetails.length > 0)">
                                    <span class="font-weight-bold">The Original eRx being cancelled, found and has not been processed</span>
                                </div>
                                <div class="col-md-8"
                                    *ngIf="(!(detailsInfo.OriginalRxDetails && detailsInfo.OriginalRxDetails.length > 0) && !(detailsInfo.rxInformtion && detailsInfo.rxInformtion.length > 0)) && !cancelOrgRxInfo">
                                    <span>Could not find original Rx. Please select manually</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="rejectCancelRx()" appShortcutKey InputKey="r" *ngIf="eMessageType == 'CancelRx' && cancelOrgRxInfo?.length">
            <b>R</b> Reject
        </button>
        <button footer class="inactive" appShortcutKey InputKey="r" *ngIf="eMessageType == 'CancelRx' && !cancelOrgRxInfo?.length"
        title="Please select the Rx before sending Cancel Response">
            <b>R</b> Reject
        </button>
        <button footer class="hotkey_primary" (click)="acceptCancelRx()" appShortcutKey InputKey="a" *ngIf="eMessageType == 'CancelRx' && cancelOrgRxInfo?.length">
            <b>A</b> Accept
        </button>
        <button footer class="inactive" appShortcutKey InputKey="a" *ngIf="eMessageType == 'CancelRx' && !cancelOrgRxInfo?.length"
        title="Please select the Rx before sending Cancel Response">
            <b>A</b> Accept
        </button>
        <button footer class="hotkey_primary" (click)="discontinueAndCreateNew()" appShortcutKey InputKey="d" *ngIf="eMessageType == 'RxChangeResponse'">
            <b>D</b> Discontinue & Create New
        </button>
        <button footer class="hotkey_primary" (click)="closeModal(true)" appShortcutKey InputKey="c">
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #SelectCancelRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal2();cancelRxInfo = []">
        <h4 header>Patient Rx History</h4>
        <span body>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding--right__7 ">
                    <div class="exprx--common-block erx--body insurance--body" *ngIf="!cancelPatient?.patientid">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Select Patient</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content seconday-search--grid">
                            <app-searchbox-global  class="w-100" [SearchFor]="'Patient'" [DisableSerchFil]="true"
                                [DisableRadioButtons]="true" [IsAddNew]="false"
                                (SelectedPatient)="selectdCancelPat($event)" class="rx-presc--search"
                                [IsFocused]="isInputFocused">
                            </app-searchbox-global>
                        </div>
                    </div>
                    <div class="exprx--common-block" *ngIf="cancelPatient?.patientid">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Patient - {{cancelPatient?.fullname? (cancelPatient?.fullname.replace('_',', ') | uppercase):''}}
                                <ul class="pull-right eprx--header__icons margin_right_1rem">
                                    <li>
                                        <a (click)="resetCancelPatient()">
                                            <i class="far fa-redo-alt fa-lg" title="Reset Patient"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <app-rx-patient-card [RxPatientInfo]="cancelPatient"></app-rx-patient-card>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="cancelPatient?.patientid">
                <div class="col-md-12">
                    <app-rx-history [PatientId]="cancelPatient?.patientid" [ShowDateRange]="true" [MultiCheck]="true"
                        (emitRxInfo)="getCancelRxInfo($event)" [ActiveScreen]="'PatHis'"></app-rx-history>
                </div>
            </div>
        </span>
        <button footer class="hotkey_success" (click)="pickCancelRx()" appShortcutKey [AltKey]="'p'">
            <span>P</span> Pick
        </button>
        <button footer class="hotkey_success" (click)="closeModal2();cancelRxInfo = [];" appShortcutKey [AltKey]="'c'">
            <span>C</span> Close
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #RevDelRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeReverseListModal()">
        <h4 header>Reverse / Delete Rx(s)</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th scope="col">
                                    Select
                                </th>
                                <th scope="col">
                                    Rx#
                                </th>
                                <th scope="col">
                                    Refill#
                                </th>
                                <th scope="col">
                                    Fill#
                                </th>
                                <th scope="col">
                                    Date Filled
                                </th>
                                <th scope="col">
                                    Picked Up
                                </th>
                                <th scope="col">
                                    Status
                                </th>
                                <th scope="col">
                                    Eligibility to Delete
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cancelRxSing of sortedOrgInfo;let i=index">
                                <td>
                                    <fieldset>
                                        <span  class="text-center">
                                            <input class="form-check-input" type="checkbox" [id]="'CanceRx'+i" name="" [(ngModel)]="cancelRxSing.IsSelected"
                                                [checked]="cancelRxSing.IsSelected" disabled="true"/>
                                            <label class="form-check-label" [for]="'CanceRx'+i">
                                            </label>
                                        </span>
                                    </fieldset>
                                </td>
                                <td>{{cancelRxSing?.Prescription?.PrescNum ? cancelRxSing?.Prescription?.PrescNum : '--'}}</td>
                                <td>{{cancelRxSing?.PrescReFill?.ReFillNum}}</td>
                                <td>{{this._commonServ.checkIsPartialFill(cancelRxSing?.PrescReFill, cancelRxSing?.Prescription)
                                    ? cancelRxSing?.PrescReFill?.PartialFillNo : " "}}</td>
                                <td>{{cancelRxSing?.PrescReFill?.FillDtTm ? (cancelRxSing?.PrescReFill?.FillDtTm | localTime |  date: 'MM/dd/yyyy'):'--'}}</td>
                                <td>{{(cancelRxSing?.pickupRx?.IsPickedUp ? "Picked Up" : (cancelRxSing?.rxDelivery?.isShipped ? "Delivered" : 'Not picked up'))}}</td>
                                <td>{{cancelRxSing?.RxBill?.StatusId == 1 ? 'UnBilled' : (cancelRxSing?.RxBill?.StatusId == 2 ? 'Billed': (cancelRxSing?.RxBill?.StatusId == 6 ? 'Filed' : (cancelRxSing?.RxBill?.StatusId == 3 ? 'TransferIn' : 'TransferOut')))}}</td>
                                <td>{{(cancelRxSing?.pickupRx?.IsPickedUp || cancelRxSing?.rxDelivery?.isShipped) ? "No" : "Yes"}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="revAndDelSelectedRxs()" appShortcutKey [InputKey]="'r'">
            <b>R</b> Reverse / Delete
        </button>
        <button footer class="hotkey_primary" (click)="ContinueAcceptCancelRx()" appShortcutKey [InputKey]="'d'">
            <b>D</b> Discontinue
        </button>
        <button footer class="hotkey_primary" (click)="closeReverseListModal()" appShortcutKey [InputKey]="'c'">
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #ReverseCancelRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeReverseModal()">
        <h4 header>Would you like to continue?</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    <label>Are you sure you want to reverse the Rx(s) :</label>
                    <label *ngFor="let i of paidPayRecords; last as isLast">&nbsp;{{ i?.Prescription?.PrescNum }}-{{ i?.PrescReFill?.ReFillNum }}<span *ngIf="!isLast">,&nbsp;</span></label>
                </div>
                <span class ="col-md-12">Continuing will reverse the prescription.</span>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="isQtyDetected()" appShortcutKey [InputKey]="'y'">
            <b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="closeReverseModal()" appShortcutKey [InputKey]="'n'">
            <b>N</b> NO
        </button>

    </app-custom-modal>
</ng-template>

<ng-template #DeleteCancelRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeDeleteModal()">
        <h4 header>Delete Rx</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    <label *ngIf="paidPayRecords.length">Do you wish to Delete Rx which are successfully Reversed?</label>
                </div>
                <span class="col-md-12" *ngIf="paidPayRecords.length">If you select YES, Rx(s) will be deleted after Successful Reversal, otherwise Rx(s) will be left in Unbilled Status.</span>
                <span class="col-md-12" *ngIf="!paidPayRecords.length">Are you sure want to delete the Rx(s)?</span>
                <div class="col-md-12">
                    Rx#:<span *ngFor="let i of currentProcessingRx; last as isLast"><label>{{ i?.Prescription?.PrescNum }}-{{ i?.PrescReFill?.ReFillNum }}<span *ngIf="!isLast">,&nbsp;</span></label></span>
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary"(click)="checkExpiration(currentProcessingRx,false)" autofocus appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="checkExpiration(currentProcessingRx,true)" appShortcutKey [InputKey]="'n'">
            <b>N</b> NO
        </button>
        <button footer class="hotkey_primary" (click)="closeDeleteModal()" appShortcutKey [InputKey]="'c'">
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #AcceptRejectRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeAccRejPopup()">
        <h4 header>{{accRejPopUpType}} Cancel Rx</h4>
        <span body>
              <div class="row" *ngIf="cancelOrgRxInfo && cancelOrgRxInfo[0]">
                <div class="col-12 inline-flex">
                    <label class="control-label width_100px"> Rx#: </label>
                    {{cancelOrgRxInfo[0].Prescription.PrescNum ? cancelOrgRxInfo[0].Prescription.PrescNum : "--"}}
                </div>
                <div class="col-12 inline-flex">
                    <label class="control-label width_100px"> Patient Name: </label>
                     {{cancelOrgRxInfo && cancelOrgRxInfo[0]?.Patient?.fullname ? (cancelOrgRxInfo[0]?.Patient?.fullname.replace('_',', ') | uppercase) : '--'}}
                </div>
            </div>
            <div class="row" *ngIf="cancelOrgRxInfo && cancelOrgRxInfo[0]">
                <div class="col-12 inline-flex">
                    <label class="control-label width_100px"> Drug Name: </label>
                    {{(cancelOrgRxInfo[0].Drug.drugfullname ? (cancelOrgRxInfo[0].Drug.drugfullname | uppercase) : '--')}}
                </div>
            </div>  
            <div class="row">
                <div class="col-md-12">
                    <eprx-select [LabelText]="'Reason'" [PlaceHolder]="''" [List]="detailsInfo?.cancelRxResponseCodes" [HasMultiple]="false" [BindLabel]="'ValDescr'"
                        [BindValue]="'FieldVal'" [LabelForId]="'ValDescr'" [HasControl]="false" (TriggerSelectValue)="cancelRxReasonSelected($event?.value)" [AutoFocus]="accRejPopUpType === 'Reject'"></eprx-select>
                </div>
                <div class="col-md-12">
                    <label>Pharmacist Notes</label>
                    <textarea class="custom--text__area form-control" maxlength="70" [rows]="3" [disabled]="accRejPopUpType === 'Reject' && (!cancelReason ||  (cancelReason.ValDescr && cancelReason.ValDescr.trim() !== 'N/A'))" [(ngModel)]="cancelPharmNotes" (change)="cancelRxNotesChanged($event?.target?.value)"></textarea>
                </div>
            </div>
        </span>
        <button footer class="hotkey_success" (click)="AcceptOrRejectCancelRx()" appShortcutKey [AltKey]="'o'">
            <span>O</span> Ok
        </button>
        <button footer class="hotkey_success" (click)="closeAccRejPopup()" appShortcutKey [AltKey]="'c'">
            <span>C</span> Cancel
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #expiredToReverse let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close')">
        <h4 header>Reversal Restriction</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    <p>Claim older than {{revAllowDays}} days is not allowed for reversal. This claim is {{differenceDays}} days old.</p>
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="reverseNextRx()" (click)="c('close')" appShortcutKey [InputKey]="'o'">
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #selcDrugNotEqui let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close');actModal2=null">
        <h4 header>Warning!!</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    <p>The Drug you have selected is not matching with the Prescribed drug. <br>Do you wish to continue with the selected Drug?</p>
                </div>
            </div>
        </span>
        <button footer autofocus class="hotkey_primary" (click)="getDrugById(tempEqDrug.id, null, null, true, true)" (click)="c('close')" appShortcutKey [InputKey]="'y'">
            <b>Y</b> Yes
        </button>
        <button footer class="hotkey_primary" (click)="c('close');actModal2=null" appShortcutKey [InputKey]="'n'">
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #addDrug1 let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closemodal1()">
        <h4 header>Add Drug</h4>
        <div body class="word_break"><b>Drug </b> not found. Would you like to add?
        </div>
        <button footer ngbAutofocus id="addOk" type="submit" class="hotkey_primary" appShortcutKey InputKey="y"
            (click)="addNewPatPresDrug('Drug')" (keydown.enter)="addNewPatPresDrug('Drug')">
            <b>Y</b> YES
        </button>
        <button footer id="addcDefActn" type="submit" class="hotkey_primary" appShortcutKey InputKey="c"
            (click)="closemodal1()" (keydown.enter)="closemodal1()">
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>







