import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    HostListener,
    OnDestroy
} from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms"; 
import * as moment from "moment";
import {
    RxService,
    AlertService,
    CommonService,
    RphDashboardService
} from "src/app/services";
import { PaginationModel, RxModel } from "src/app/models";
import { AuditLogUtils, CommonUtil, NRxSaveUtils, NRxUtils, PatchRXFGUtil, ValidateFormFieldsUtils } from "src/app/utils";
import { RxStore } from "src/app/store";
import { MsgConfig } from "src/app";
import { CommonWarnorconfirmPopupComponent } from "../common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DateCheckUtil } from "src/app/utils/date-checks.util";


@Component({
    selector: "app-rx-file",
    templateUrl: "./rx-file.component.html"
})
export class RxFileComponent implements OnInit, OnDestroy {
    paginationModel: PaginationModel = new PaginationModel();
    modelRef: any;
    fileFG: FormGroup;
    activeModal = "";
    isFiledNew: boolean;
    minDate: any;
    filedInfo: any;
    isNewRxFromFile: any;
    messageAfterSave: string;
    unsubscribe$: Subject<void> = new Subject();
    modalRefSM: any;
    noneReason = [
        {
            Id: null,
            Name: "None",
            TenantId: null,
            IsActive: true,
            CreatedDtTm: null,
            ModifiedDtTm: null
        }
    ];
    deferredReasonList = [];
    rxExpiredObj: any;

    @Input() rxInfo: RxModel;
    @Input() rxFG: FormGroup;
    @Input() rxType: string;
    @Input() FiledRx: any;

    @ViewChild("FILECONFIRMATION", { static: true })
    FILECONFIRMATION: any;

    @ViewChild("FILE", { static: true })
    FILE: any;

    @ViewChild("UnbilledRxError", { static: true })
    UnbilledRxError: any;

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @Output()
    ReCalcPriceAfterFill = new EventEmitter<any>();

    @Output()
    SaveFileInfo = new EventEmitter<any>();

    @Output()
    CancelPopUpClosed = new EventEmitter<any>();
    oldInfo: RxModel;
    msgForVerf: string;
    formGrpInvld: boolean;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (
            event.which === 27 &&
            this.modelRef &&
            this._modalService.hasOpenModals()
        ) {
            // esc
            if (this.activeModal === "file" || this.activeModal === "confirm") {
                event.preventDefault();
                this.closeModal();
                this.activeModal = null;
            }
        }
    }

    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _rxService: RxService,
        private _alertService: AlertService,
        private _DfrdReasonServ: RphDashboardService,
        private _nrxSaveUtil: NRxSaveUtils,
        private _commUtil: CommonUtil,
        private _auditUtils: AuditLogUtils,
        private _commonService: CommonService,
        private _nrxUtils: NRxUtils,
        private _rxStore: RxStore, private _pRxFGUtls: PatchRXFGUtil, private _formUtils: ValidateFormFieldsUtils, private _dateChecks: DateCheckUtil
    ) {
        this.createFileFG();
        config.backdrop = "static";

        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
    }

    get DueDtTm(): FormControl {
        return this.fileFG.get("DueDtTm") as FormControl;
    }

    createFileFG() {
        this.fileFG = this._fb.group({
            Id: null,
            TenantId: null,
            FileDtTm: [],
            DueDtTm: [],
            PrescId: [],
            PrescRefillId: [],
            DeferredReason: [],
            DeferredReasonName: [],
            IsDeleted: null,
            Remarks: null
        });

        this.fileFG.controls["DueDtTm"].setValue(
            moment(new Date()).format("MM/DD/YYYY")
        );
        this.fileFG.controls["FileDtTm"].setValue(
            moment(new Date()).format("MM/DD/YYYY")
        );
    }

    ngOnInit() {
        if ((this.rxInfo.PrescReFill.StatusId === 2 || this.rxInfo.Prescription.RxStatusId === 2)) {
            this.confirmToFile();
        } else {
            this.checkRxStatus();
        }
        const dfrdReasonFG = { SearchText: null, PageNumber: this.paginationModel.pageNumber, PageSize: this.paginationModel.pageSize };
        this.getDeferredReasonList(dfrdReasonFG);
    }

    async getDeferredReasonList(dfrdReasonFG) {
        const resp: any = await this._DfrdReasonServ
        .getDeferredReasonList(dfrdReasonFG).toPromise();
        if (resp && resp.length > 0) {
            if (resp[0].TotalCount > 0) {
                dfrdReasonFG.PageSize = resp[0].TotalCount;
                const data: any = await this._DfrdReasonServ
                    .getDeferredReasonList(dfrdReasonFG).toPromise();
                if (data && data.length > 0) {
                    this.deferredReasonList = resp;
                    this.deferredReasonList.splice(0, 0, this.noneReason[0]);
                } else {
                    this.deferredReasonList = this.noneReason;
                }
            }
        } else {
            this.deferredReasonList = this.noneReason;
        }
    }

    checkRxStatus() {
        const paidRecords =
            this.rxInfo && this.rxInfo.RxPay
                ? this.rxInfo.RxPay.filter(v => v.RecType === "P")
                : null;
        if (
            this.rxInfo &&
            paidRecords &&
            (this.rxInfo.RxBill.StatusId === 2 ||
                paidRecords.length > 0)
        ) {
            this.modalRefSM = this._modalService.open(
                this.UnbilledRxError,
                {
                    centered: true,
                    backdrop: false,
                    keyboard: false,
                    windowClass:"large--content"
                }
            );
        } else {
            this.modelRef = this._modalService.open(
                this.FILECONFIRMATION,
                {
                    centered: true,
                    keyboard: false,
                    backdrop: false,
                    windowClass:"large--content"
                }
            );
            this.activeModal = "confirm";
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    confirmToFile(e?: any) {
        if (e) {
            e.preventDefault();
        }
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.isFiledNew = false;
        setTimeout(() => {
            this.modelRef = this._modalService.open(this.FILE, {centered: true,
                keyboard: false,
                backdrop: false
            });
            this.activeModal = "file";
        }, 100);
    }

    async checkForReverification() {
        await this._formUtils.blurAndDelay();
        const dateChecks = [{
            control: "DueDtTm",
            minDate: "",
            maxDate: "",
            isRequired: true
        }];
        const DueDatesFG = this._fb.group({
            DueDtTm: this.fileFG.controls["DueDtTm"].value,
        }); 
        let isDateValidationChecks: boolean = this._dateChecks.checkDateValidationAtTimeofSave(DueDatesFG, dateChecks);
        if (isDateValidationChecks) {
            this.formGrpInvld = true;
            return;
        } else {
            if (this.fileFG.valid) {
                this.formGrpInvld = false;
                if (this.modelRef) {
                    this.modelRef.close();
                }
                if ((this.rxInfo["drugPickVerif"] && this.rxInfo["drugPickVerif"].length) ||
                this.rxInfo["rPHVerifs"] && this.rxInfo["rPHVerifs"].length && this.rxInfo.PrescReFill.StatusId !== 2
                && this.rxInfo.Prescription.RxStatusId !== 2) {
            const rxNum = this.rxInfo.Prescription.PrescNum + "-" + this.rxInfo.PrescReFill.ReFillNum +
             (this._commonService.checkIsPartialFill(this.rxInfo.PrescReFill, this.rxInfo.Prescription) ? "-" + this.rxInfo.PrescReFill.PartialFillNo : " ");
                this.msgForVerf = "";
            const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {backdrop: false,
                keyboard: false, windowClass: "large--content"});
                modelRef.componentInstance.IsHeaderText = "Confirmation";
                modelRef.componentInstance.warnMsg = "<b>" + rxNum + "</b> has Pharmacist or Drug Pick or Rx Verification records." + "<br/>Would you like to Re-Verify?",
                modelRef.componentInstance.yesButton = true;
                modelRef.componentInstance.noButton = true;
                modelRef.componentInstance.IsPopUpClosed
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(async (resp: any) => {
                    modelRef.close();
                    if (resp) {
                        if (this.rxInfo["drugPickVerif"] && this.rxInfo["drugPickVerif"].length && this.rxInfo["drugPickVerificationStatus"] &&
                         this.rxInfo["drugPickVerificationStatus"].trim().toLowerCase()  === "p") {
                                this._auditUtils.getChangedValues(
                                    null,
                                    {"DP Verification Status": "Re-verify"},
                                    "DP Verification",
                                    "Rx",
                                   this.rxInfo.PrescReFill.Id
                                );
                                this.msgForVerf="D";
                                // this._nrxUtils.updateVerfStatus(this.rxInfo, "D");
                                // this.rxInfo["drugPickVerificationStatus"] = "RV";
                                // this.rxInfo.drugPickVerif.map(item => {
                                //     item["Result"] = "RV";
                                // });
                        }
                        if (this.rxInfo["rPHVerifs"] && this.rxInfo["rPHVerifs"].length) {
                            if (this.rxInfo["rph1VerificationStatus"] && this.rxInfo["rph1VerificationStatus"].trim().toUpperCase()  === "R") {
                                this.msgForVerf= this.msgForVerf ? "DX" : "X";
                                this._auditUtils.getChangedValues(
                                    null,
                                    {"Rx Verification Status": "Re-verify"},
                                    "Rx Verification",
                                    "Rx",
                                    this.rxInfo.PrescReFill.Id
                                );
                                // this._nrxUtils.updateVerfStatus(this.rxInfo, "X");
                                // this.rxInfo["rph1VerificationStatus"] = "RV";
                                // this.rxInfo.rPHVerifs.map(item => {
                                //     if (item["Status"].trim() === "R") {
                                //     item["IsForRxVerification"] = true;
                                //     item["Status"] = "RV";
                                //     }
                                // });
                            }
                            if (this.rxInfo["rph2VerificationStatus"] && this.rxInfo["rph2VerificationStatus"].trim().toUpperCase()  === "V") {
                                this.msgForVerf= this.msgForVerf ? this.msgForVerf+"R": "R";
                                this._auditUtils.getChangedValues(
                                    null,
                                    {"Rph Verification Status": "Re-verify"},
                                    "Rph Verification",
                                    "Rx",
                                    this.rxInfo.PrescReFill.Id
                                );
                                // this._nrxUtils.updateVerfStatus(this.rxInfo, "R");
                                // this.rxInfo["rph2VerificationStatus"] = "RV";
                                // this.rxInfo.rPHVerifs.map(item => {
                                //     if (item["Status"].trim() === "V") {
                                //         item["IsForRxVerification"] = false;
                                //         item["Status"] = "RV";
                                //     }
                                // });
                            }
                        }
                        const respnse = await this._nrxUtils.updateVerfStatus(this.rxInfo, this.msgForVerf, null, false, true);
                        if (this.rxFG && respnse) {
                            let rxInfoD: any;
                            const resp = await this._commonService.getRxDetailsByPrescNos([this.rxInfo["Prescription"]["PrescNum"]],
                            this.rxInfo["PrescReFill"]["Id"]).toPromise();
                            if (resp && resp.length > 0) {
                                rxInfoD = resp[0];
                                this.rxInfo = rxInfoD;
                                this._pRxFGUtls.patchVerifications(this.rxFG, rxInfoD, "both")
                            }
                        }
                    }
                    this.saveFileToInfo();
                });
                } else {
                    this.saveFileToInfo();
                }
            } else {
                this.formGrpInvld = true;
            }
        }
    }

    createNewFiledRx(val) {
        this.isFiledNew = val;
    }

    closeModal(e?: any) {
        if (e) {
            e.preventDefault();
        }
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.CancelPopUpClosed.emit(null);
    }

    saveFileToInfo() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        const rxInfo = this.rxInfo;
        this.oldInfo = Object.assign({}, rxInfo);
        const prescription: any = this.rxInfo.Prescription;
        const prescRefill: any = this.rxInfo.PrescReFill;
        const rxBill: any = this.rxInfo.RxBill;

        this.fileFG.controls["PrescId"].setValue(rxInfo.Prescription.Id);
        this.fileFG.controls["PrescRefillId"].setValue(rxInfo.PrescReFill.Id);

        if (
            (+prescRefill.StatusId === 2 || +prescription.RxStatusId === 2) &&
            !this.isFiledNew
        ) {
            prescription.RxStatusId = 1;
            prescRefill.StatusId = 1;
            if (rxBill.StatusId && +rxBill.StatusId === 6) {
                rxBill.StatusId = 1;
            }
        } else {
            prescription.RxStatusId = 2;
            prescRefill.StatusId = 2;
        }
        this.rxInfo.DueDtTm = this.fileFG.value.DueDtTm;
        this.rxInfo["RxFile"] = this.fileFG.value;

        if (this.rxType === "nr") {
            this.saveFileInNewRx();
        } else {
            this.saveFileRxInfo({
                file: this.fileFG.value,
                isnew: this.isFiledNew
            });
        }
    }

    saveFileInNewRx() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        const rxInfo = this.rxFG.getRawValue();
        const prescription: FormGroup = this.rxFG.controls.Prescription as FormGroup;
        const prescRefill: FormGroup = this.rxFG.controls.PrescReFill as FormGroup;
        const rxBill: FormGroup = this.rxFG.controls.RxBill as FormGroup;

        this.fileFG.controls["PrescId"].setValue(rxInfo.Prescription.Id);
        this.fileFG.controls["PrescRefillId"].setValue(rxInfo.PrescReFill.Id);

        if ((+prescRefill.value.StatusId === 2 || +prescription.value.RxStatusId === 2) && !this.isFiledNew) {
            prescription.controls.RxStatusId.setValue(1);
            prescRefill.controls.StatusId.setValue(1);
            if (rxBill.value.StatusId && +rxBill.value.StatusId === 6) {
                rxBill.controls["StatusId"].setValue(1);
            }
        } else {
            prescription.controls.RxStatusId.setValue(2);
            prescRefill.controls.StatusId.setValue(2);
        }
        this.IsPopUpClosed.emit();
    }

    saveFileRxInfo(val) {
        this.activeModal = null;
        this.filedInfo = val.file;

        if (val.isnew) {
            this.isNewRxFromFile = val.isnew;
            sessionStorage.setItem(
                "CreatingNewFile",
                "" + this.rxInfo["Prescription"]["PrescNum"]
            );
            // if (
            //     +this.rxInfo.Prescription.RxStatusId !== 2 ||
            //     +this.rxInfo.PrescReFill.StatusId !== 2
            // ) {
            //     this.ReCalcPriceAfterFill.emit("NewRx");
            // } else {
                this.createNewRxFromFile();
            // }
        } else {
            this.isNewRxFromFile = false;
            // if (
            //     +this.rxInfo.Prescription.RxStatusId !== 2 ||
            //     +this.rxInfo.PrescReFill.StatusId !== 2
            // ) {
            //     this.ReCalcPriceAfterFill.emit("NotNewRx");
            // } else {
                if (this.rxType !== "er") {
                    this.messageAfterSave =
                        "Rx is filed and saved successfully.";
                }
                this.updateRx();
            // }
        }
    }

    async createNewRxFromFile() {
        const resp: any = this._rxService.saveRxInfo(this.rxInfo).toPromise();
        if (resp !== "Refill Already Created" && resp && !isNaN(+resp) && +resp > 0) {
            this._commUtil.updateDashbordStatus();
            this._alertService.success("Rx saved successfully.");
            this.IsPopUpClosed.emit();
        } else if (resp === "Refill Already Created") {
           let errorMessage = "Refill is being created for the selected Rx#:" + this.rxInfo.Prescription.PrescNum + " by a different user on the same pharmacy.";
            this._alertService.error(errorMessage);
        } else if (isNaN(+resp)) {
            this._alertService.error(resp);
        } else {
            this._alertService.error("Unable to save Rx.");
        }
    }

    async updateRx() {
        const resp = await this._nrxSaveUtil.updateRxInfo(this.rxInfo, this.oldInfo);
        if (resp && !isNaN(+resp.RxNum) && +(resp.RxNum) > 0) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this._alertService.success(
                "Rx Filed and updated successfully."
            );
        } else if (resp && isNaN(+resp.Message)) {
            if (resp.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                this._alertService.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
            } else if (typeof resp.Message === "string") {
            this._alertService.error(resp.Message);
            }
        } else {
            this._alertService.error( "Save Unsuccessful.");
        }
        this.IsPopUpClosed.emit(true);
    }

    closeModalSM(e?: any) {
        if (e) {
            e.preventDefault();
        }
        if (this.modalRefSM) {
            this.modalRefSM.close();
            this.modalRefSM = null;
        }
        this.IsPopUpClosed.emit(null);
    }
}
