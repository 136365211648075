import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    Input,
    HostListener,
    AfterViewInit,
    ChangeDetectorRef
} from "@angular/core";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrintErxService } from "src/app/services/print-erx.service";
import { AlertService, CommonService, ErxService } from "src/app/services";
import { MsgConfig } from "../../../../app.messages";
import { UdpCurrencyMaskPipe } from "../../../../pipes";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { ErxVersions, SystemData } from "src/app/models";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-send-efill-request",
    templateUrl: "./send-efill-request.component.html"
})
export class SendEfillRequestComponent implements OnInit, AfterViewInit {
    prescRefillId: any;
    erxDetails: any;
    faxInfo: any;
    drugId: any;
    SearchPres: boolean;
    faxPopUp: boolean;
    isInputFocused = false;
    numberPipe = new UdpCurrencyMaskPipe();
    erxReq: FormGroup;
    SPIDetails: any;
    multipleSpi: boolean;
    PrescAddress: any;
    cancelPOP: boolean;
    authRefills: any = [];
    canDoReq: boolean;
    PrescAddress1: any;
    PrescAddress2: any;
    typedValue: boolean;
    showCanDoReq: boolean;
    selectedRxs: any[];
    spiModel: any;
    eRxVersion: string;
    showFaxRef = true;
    hasFaxNumber: boolean;
    resetPrescriber: boolean;
    systemData: SystemData;
    unsubscribe$: Subject<void> = new Subject();
    checkSpiServiceLevel: boolean = false;
    phone: any;
    prescAddresses: any;

    @Input()
    set PrescRefillId(data: any) {
        this.prescRefillId = data;
    }

    @Input()
    set DrugId(data: any) {
        this.drugId = data;
    }

    @Input()
    set RxInfo(data: any) {
        this.erxDetails = data;
        // this.SPIDetails = this.erxDetails["SpiList"];
    }

    @Input()
    set FaxInfo(data: any) {
        this.faxInfo = data;
    }

    @Input()
    set ShowFaxRef(data: any) {
        this.showFaxRef = data;
    }

    @Input()
    set selectedRx(data: any) {
        this.selectedRxs = data;
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("SendEfill", { static: true })
    SendEfill: any;

    @ViewChild("CancelPop", { static: true })
    CancelPop: any;

    @ViewChild("canDoReq", { static: true })
    canDoReqTemplate: any;


    modelRef: NgbModalRef;

    constructor(
        private _modalService: NgbModal,
        private _erxServ: PrintErxService,
        private _alertService: AlertService,
        private _fb: FormBuilder,
        private _formValid: ValidateFormFieldsUtils,
        private _commonServ: CommonService,
        private _erxService: ErxService,
        private _cdr: ChangeDetectorRef
    ) {
        this.erxReq = this._fb.group({
            DAW: [null],
            Qty: [null],
            days: [null],
            AuthRef: [null],
            PharmNotes: [null]
        });
    }

    ngOnInit() {
        this._commonServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.systemData = resp;
        });
        this.getErxDetails();
        this.authRefills.push({ Id: 100, Name: "Not SPecified" });
        for (let i = 0; i <= 99; i++) {
            this.authRefills.push({ Id: i, Name: i });
        }
        const versionsetting = this._commonServ.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
        if (versionsetting == 2) {
            this.eRxVersion = ErxVersions.NewerVersion;
        } else {
            this.eRxVersion = ErxVersions.OldVersion;
        }
        let faxNumberSetting = this._commonServ.getSetttingValue("FaxAndReportsSettings", "Fax_Number");
        if (faxNumberSetting && faxNumberSetting.length) {
            this.hasFaxNumber = true;
        } else {
            this.hasFaxNumber = false;
        }
    }

    get AuthRef(): FormControl {
        return this.erxReq.get("AuthRef") as FormControl;
    }

    ngAfterViewInit() {}
    
    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    getErxDetails() {
        this._erxServ
            .getErxDetails(
                this.erxDetails.PrescReFill.Id,
                this.erxDetails.Drug.id,
                "Refill"
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.erxDetails = resp;
                    if (this.erxDetails["SpiList"] && this.erxDetails["SpiList"].length > 0) {
                        this.SPIDetails = this.erxDetails["SpiList"];
                        this.erxDetails["SpiList"].map(val => {
                            if (val["IsPrimary"] === true) {
                                this.PrescAddress1 = val;
                            }
                            if (val["OriginalSpiNumber"] === true) {
                                this.PrescAddress = val;
                                this.phone  = this.erxDetails?.Prescriber?.PrescriberAddresses?.find(val => val["SPINum"] == this.PrescAddress?.SPINo)?.Telephone;
                                this.erxDetails.Prescriber.prescriberspinum = val["SPINo"];
                            }
                        });
                    }
                    if (!this.PrescAddress && this.PrescAddress1) {
                        this.PrescAddress = this.PrescAddress1;
                        this.phone  = this.erxDetails?.Prescriber?.PrescriberAddresses?.find(val => val["SPINum"] == this.PrescAddress?.SPINo)?.Telephone;
                        this.erxDetails.Prescriber.prescriberspinum = this.PrescAddress1["SPINo"];
                    }
                    if (this.erxDetails["Prescriber"]["PrescriberAddresses"]) {
                        if (
                            this.erxDetails.Prescriber.PrescriberAddresses
                                .length > 1
                        ) {
                            this.erxDetails.Prescriber.PrescriberAddresses.map(
                                value => {
                                    if (
                                        value["Id"] ===
                                        this.erxDetails.Prescriber
                                            .primeAddressId
                                    ) {
                                        this.PrescAddress2 = value;
                                    }
                                }
                            );
                        } else {
                            this.PrescAddress2 = this.erxDetails.Prescriber.PrescriberAddresses[
                                "0"
                            ];
                        }
                    } else {
                        this.PrescAddress2 = null;
                    }

                    this.erxReq.controls["Qty"].patchValue(
                        this.erxDetails.Prescription.Qty !== null
                            ? this.erxDetails.Prescription.Qty.toFixed(3)
                            : null
                    );
                    this.erxReq.controls["DAW"].patchValue(
                        this.erxDetails.PrescReFill.DAWId !== null
                            ? this.erxDetails.PrescReFill.DAWId
                            : null
                    );
                    this.erxReq.controls["days"].patchValue(
                        this.erxDetails.Prescription.Days !== null
                            ? this.erxDetails.Prescription.Days
                            : null
                    );
                    this.erxReq.controls["AuthRef"].patchValue(
                        this.erxDetails.Prescription.ReFillNum !== null
                            ? this.erxDetails.Prescription.ReFillNum
                            : null
                    );
                    this.erxReq.controls["PharmNotes"].patchValue(
                        this.erxDetails.PharmacistNotes
                    );
                    if (this.erxDetails.Prescriber.prescriberdeanum === "###") {
                        this.erxDetails.Prescriber.prescriberdeanum = null;
                    }


                    this.modelRef = this._modalService.open(this.SendEfill, {
                        centered: true,
                        size: "lg",
                        backdrop: false
                    });
                }
            });
    }

    focusOnFaxReq() {
        document.getElementById("faxReq").focus();
            
    }
    changeSPI(value) {
        this.erxDetails.Prescriber.prescriberspinum = value.value["SPINum"];
    }

    changeSPIADD(value) {
        if (value && value.value) {
            if (value.value["SPINo"]) {
                this.erxDetails.Prescriber.prescriberspinum = value.value["SPINo"];
            }
            if ((value.value["AddressLine1"] || value.value["StateName"])) {
                this.PrescAddress = value.value;
                this.typedValue = false;
                } else {
                    this.typedValue = true;
            }
            this.checkServiceLevel(value.value);
        }
    }

    closeModal() {
        this.cancelPOP = false;
        if (this.modelRef) {
        this.modelRef.close();
        }
        this.IsPopUpClosed.emit(null);
    }

    setTabIndex(value) {
        if (value.which !== 9) {
            const data: any = document.getElementsByClassName("ng-input");
            data[0].tabIndex = 2;
            data[0]["firstChild"].tabIndex = 2;
        }
    }

    resetTabIndex() {
        const data: any = document.getElementsByClassName("ng-input");
        data[0].tabIndex = -1;
        data[0]["firstChild"].tabIndex = 0;
    }

    sendErxRequestCheck() {
            const dataToPost = {
                PrescRefillId: this.erxDetails.PrescReFill.Id,
                PatientId: this.erxDetails.Patient.patientid,
                PrescriberId: this.erxDetails.Prescriber.prescriberid,
                DrugId: this.erxDetails.Drug.id,
                SPINum: this.erxDetails.Prescriber.prescriberspinum,
                RxId: Number(this.erxDetails.Prescription.PrescNum),
                currentRefillNo: this.erxReq.value["AuthRef"],
                PrescriptionId: this.erxDetails.Prescription.Id,
                Quantity: this.erxReq.value["Qty"],
                SupplyDays: this.erxReq.value["days"],
                typedValue: this.typedValue,
                PharmacistNotes: this.erxReq.value["PharmNotes"]
                    ? this.erxReq.value["PharmNotes"]
                    : null
            };
            this._erxServ.xmlCreation(dataToPost, this.eRxVersion)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp === "010" || resp.trim() === "") {
                    this._alertService.success(MsgConfig.ERX_REQUEST_SUCCESS);
                    this.closeModal();
                } else if (resp === "000") {
                    this.closeModal();
                    this._alertService.success(MsgConfig.ERX_REQUEST_SUBMITTED);
                } else if (
                    resp ===
                    "Prescriber does not support controlled substance type message."
                ) {
                    this._alertService.error(
                        "Prescriber does not support controlled substance type message.",
                        false,
                        "long"
                    );
                    this.closeModal();
                } else if (resp === "xml is invalid") {
                    this._alertService.error(
                        MsgConfig.ERX_REQUEST_ERROR,
                        false,
                        "long"
                    );
                }  else {
                    const errResp = resp.replace(/#/g, "<br>");
                    this._alertService.error(errResp, false, "long");
                    this.SyncPrescriberSPI(this.erxDetails.Prescriber.prescribernpinum, this.erxDetails.Prescriber.prescriberspinum,  this.erxDetails.Prescriber.prescriberid, this.eRxVersion);
                }
            });
    }

    SyncPrescriberSPI(npinumber,SPI,prescriberId, Version) {
        this._erxServ.SyncPrescriberSPI(npinumber,SPI,prescriberId, Version).pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                if (this.modelRef) {
                    this.modelRef.close();
                }
                this.getErxDetails();
            }
    });

    }

    async sendErxRequest(content) {
        if (this.erxReq.valid) {
            await this._formValid.blurAndDelay();
            if (this.erxDetails && this.erxDetails.Patient && this.erxDetails.Prescriber && (!this.erxDetails.Patient.telephone || (this.erxDetails.Patient.telephone &&
                this.erxDetails.Patient.telephone.length >= 10)) &&
                (this.erxDetails.Prescriber.telephone && this.erxDetails.Prescriber.telephone.length >= 10)
                // ((this.PrescAddress.Phone && this.PrescAddress.Phone.length >= 10) ||
                //     (this.erxDetails.Prescriber.telephone && this.erxDetails.Prescriber.telephone.length >= 10))
            ) {
                this.canDoReq = false;
                this.setNumberFormat("Qty", 3);
                this.setNumberFormat("days", 0);
                if (!this.typedValue) {
                    this.SPIDetails.map(val => {
                        if (val["SPINo"] === this.erxDetails.Prescriber.prescriberspinum) {
                            if (val["ServiceLevel"] && (val["ServiceLevel"].trim() === "1" || val["ServiceLevel"].toLowerCase().trim() === "y")) {
                                if (val["ServiceLevelName"].includes("Refill")) {
                                    this.canDoReq = true;
                                }
                            }
                        }
                    });
                } else {
                    this.canDoReq = true;
                }
                if (this.canDoReq) {
                    this.sendErxReqAfterCheck();
                } else {
                    this.spiModel = this._modalService.open(content, { centered: true, windowClass: "large--content"});
                    this.showCanDoReq = true;
                }
            } else if (this.erxDetails && this.erxDetails.Patient && !(!this.erxDetails.Patient.telephone || (this.erxDetails.Patient.telephone &&
                this.erxDetails.Patient.telephone.length >= 10))) {
                this._alertService.error("Patient phone must be greater than or equals to 10 digits.");
            } else {
                this._alertService.error("Prescriber phone must be greater than or equals to 10 digits.");
            }
        } else {
            this._formValid.validateAllFormFields(this.erxReq);
        }
    }


    sendErxReqAfterCheck() {
        const dataToPost = {
            PrescRefillId: this.erxDetails.PrescReFill.Id,
            PatientId: this.erxDetails.Patient.patientid,
            PrescriberId: this.erxDetails.Prescriber.prescriberid,
            DrugId: this.erxDetails.Drug.id,
            SPINum: this.erxDetails.Prescriber.prescriberspinum,
            RxId: Number(this.erxDetails.Prescription.PrescNum),
            currentRefillNo: this.erxReq.value["AuthRef"],
            Daw: this.erxReq.value["DAW"],
            PrescriptionId: this.erxDetails.Prescription.Id,
            Quantity: this.erxReq.value["Qty"],
            SupplyDays: this.erxReq.value["days"],
            typedValue: this.typedValue,
            PharmacistNotes: this.erxReq.value["PharmNotes"]
                ? this.erxReq.value["PharmNotes"]
                : null,
            AlternativeDrugs: []
        };
        this._erxServ.xmlCreation(dataToPost, this.eRxVersion)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp === "010" || resp.trim() === "") {
                this.closeModal();
                this._alertService.success(MsgConfig.ERX_REQUEST_SUCCESS);
            } else if ( resp === "000") {
                this.closeModal();
                this._alertService.success(MsgConfig.ERX_REQUEST_SUBMITTED);
            } else if (
                resp ===
                "Prescriber does not support Controlled Substance type message."
            ) {
                this._alertService.error(
                    "Prescriber does not support Controlled Substance type message.",
                    false,
                    "long"
                );
                this.closeModal();
            } else if (resp === "xml is invalid") {
                this._alertService.error(
                    MsgConfig.ERX_REQUEST_ERROR,
                    false,
                    "long"
                );
            }  else {
                const errResp = resp.replace(/#/g, "<br>");
                this._alertService.error(errResp, false, "long");
            } 
            if(resp === "Receiving partner does not support this type of message") {
                this.SyncPrescriberSPI(this.erxDetails.Prescriber.prescribernpinum, this.erxDetails.Prescriber.prescriberspinum, this.erxDetails.Prescriber.prescriberid, this.eRxVersion);
            }
        });
    }

    changeSpiNumber(val) {
        this.erxDetails.Prescriber.prescriberspinum = val;
    }

    changeAuth(val) {
        this.erxDetails.Prescription.ReFillNum = val;
    }

    resetPresc() {
        this.resetPrescriber = true
        this.SearchPres = true;
        this.erxDetails.Prescriber = null;
        this.isInputFocused = false;
        this._cdr.detectChanges();
        this.isInputFocused = true;
    }

    selectedPresInfo(val) {
        this.isInputFocused = false;
        if (val) {
            this.erxDetails.Prescriber = val;
            this._erxService.getSPIInformation(val.prescriberid)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if(resp){
                    this.SPIDetails = resp;
                    this.prescAddresses = val.PrescriberAddresses;
                    this.phone = this.prescAddresses.find(val => val["SPINum"] == this.SPIDetails[0]?.SPINo)?.Telephone;
                    this.SPIDetails.map(val => {
                        if (val["IsPrimary"] === true) {
                            this.checkServiceLevel(val)                               
                        }
                    });
                    
                }
            })
            this.SearchPres = false;
        } else {
            this.SearchPres = true;
            this._cdr.detectChanges();
            this.isInputFocused = true;
        }
    }

    setNumberFormat(name: string, deciVal) {
        this.erxReq.controls[name].patchValue(
            this.numberPipe.transform(this.erxReq.controls[name].value, deciVal)
        );
        // this.erxDetails.Prescription["Qty"] =  this.numberPipe.transform(this.erxDetails.Prescription["Qty"], 3);
    }

    valueChange(value) {}

    async openFaxPopUp() {
        await this._formValid.blurAndDelay();
        this.faxPopUp = true;
    }

    resetActiveModal(e) {
        this.faxPopUp = false;
        if (e) {
            if (this.modelRef) {
                this.modelRef.close();
                }
            this.IsPopUpClosed.emit(null);
        }
    }


    openCancelPopUP() {
        this.cancelPOP = true;
        if (this.modelRef) {
            this.modelRef.close();
            }
        this.modelRef = this._modalService.open(this.CancelPop, {
            centered: true,
            backdrop: false,
            windowClass: "large--content"
        });
    }

    refreshSpi() {
        this._erxServ.refreshSpi(this.erxDetails.Prescriber.prescribernpinum, this.erxDetails.Prescriber.prescriberid, this.eRxVersion)
        .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    if (this.modelRef) {
                        this.modelRef.close();
                    }
                    this.PrescAddress = '';
                    this.phone = '';
                    this.resetPrescriber = false;
                    this.getErxDetails();
                    
                }
        });
    }

    checkServiceLevel(val) {
        if (val["ServiceLevel"] && (val["ServiceLevel"].trim() === "1" || val["ServiceLevel"].toLowerCase().trim() === "y")) {
            if (val["ServiceLevelName"].includes("Refill")) {
                return true;
            } else return false;
        } else return false;
    }
}
