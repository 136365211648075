<div class="row">
    <div class=" col-11 input-group">
        <input 
          id="pharmacy-search" 
          type="text" 
          class="form-control height_325"
          [(ngModel)]="searchTerm"
          (ngModelChange)="getPharmacyChange($event)"
          appChangeDebounce
          [ngbTypeahead]="search"
          (selectItem)="selectedPharmacy($event.item)"
          [inputFormatter]="inputFormatter"
          placeholder="Search Pharmacy" />
          <div class="search--icon">
            <i class="far fa-search" (click)="inputClearedBySearchIcon()"></i>
        </div>
      </div>
      <div class="col-1 pl-0 patient-edit--transfer-in">
        <i class="fa-kit fa-pharmacy-icon" (click)="editPopUp()"></i> 
      </div>
</div>
<div *ngIf="searchTerm && PharmacyNames?.pharmNames?.length > 0" class="results bg-white">
    <div class="row pharmacy_suggest" *ngFor="let x of PharmacyNames.pharmNames" (click)="selectedPharmacyDetails(x)">
    <div class="col-8 px-0">
        <label>Name</label>&nbsp;
        <span>{{ x?.PharmacyName ? (x?.PharmacyName | uppercase) :  msgConfig.EMPTY_Data}}</span>
    </div>
    <div class="col-4 px-0 ">
        <label>Fax#</label>&nbsp;
        <span>{{(x?.Fax) ? this._commonUtil.getPhoneFormatForWrongFax(x?.Fax): msgConfig.EMPTY_Data}}</span>
    </div> 
    <div class="col-4 px-0 mt-2">
        <label>NPI#</label>&nbsp;
        <span>{{x?.NPINum ? x?.NPINum : msgConfig.EMPTY_Data}}</span>
    </div>
    <div class="col-4 px-0 mt-2">
        <label>Phone1#</label>&nbsp;
        <span>{{x?.Phone1 ? this._commonUtil.getPhoneFormat(x?.Phone1) : msgConfig.EMPTY_Data}}</span>
    </div>
    <div class="col-4 px-0 mt-2">
        <label>Phone2#</label>&nbsp;
        <span>{{x?.Phone2 ? this._commonUtil.getPhoneFormat(x?.Phone2) : msgConfig.EMPTY_Data}}</span>
    </div>
    <div class="col-6 pl-0 mt-2">
        <label>Address</label>&nbsp;
        <span>{{ x?.addresses ? (x?.addresses | uppercase) :  msgConfig.EMPTY_Data}}</span>
    </div>
    <div class="col-6 px-0 mt-2">
        <label>Rx Transfer service</label>&nbsp;
        <span [ngClass]="x.IsService ? '' : ''">{{x.IsService? 'Available': 'UnAvailable'}}</span>
    </div>
</div>
</div>