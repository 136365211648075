import { Injectable } from "@angular/core";
import * as moment from "moment";
import {
    FormGroup,
    FormArray,
    FormControl,
    FormBuilder, 
} from "@angular/forms";
import * as _ from "lodash";
import { 
    Prescription,
    PrescReFill,
    RxBill,
    RefillInsu,
    SigCodes,
    RxOtherAmount,
    EPrescriber,
    EPatient,
    EDrug,
    PrescRefill_Observtions,
    RxDelivery,
    RPHVerifs,
    PrescRefill_RxTags,
    PickupRx,
    ERxAdmin,
    IOrganization,
    InsuCarrier,
    InsuPlan,
    InsuTransOther,
    ERxDetailsViewModel,
    ERxFile,
    ECodeInfo,
    ErxDetailsData,
    PartialCummulativeDetails,
    Insurances,
    PrimaryInsurance,
    IHoaDoseSchedule} from "src/app/models";
import { UserService } from "../services/user.service";

@Injectable({
    providedIn: "root",
})
export class PatchRXFGUtil {
    constructor(private _fb: FormBuilder, private _userService: UserService) {}

    createNewRxFG() {
        let eRxFG = this._fb.group({
            Prescription: this._fb.group(new Prescription()),
            RxNotes: [],
            PrescReFill: this._fb.group(new PrescReFill()),
            partialCummulativeDetails: this._fb.group(new PartialCummulativeDetails()),
            RxBill: this._fb.group(new RxBill()),
            RefillInsu: this._fb.group(new RefillInsu()),
            SigCodes: this._fb.group(new SigCodes()),
            RxOtherAmount: this._fb.group(new RxOtherAmount()),
            Prescriber: this._fb.group(new EPrescriber()),
            Patient: this._fb.group(new EPatient()),
            Drug: this._fb.group(new EDrug()),
            RxFile: this._fb.group(new ERxFile()),
            HOADetails: this._fb.group(new IHoaDoseSchedule()),
            HoaFrmBilling: false,
            RxAdminDoseSchedule: this._fb.group({
                PrescriptionId: null,
                RxAdmin: this._fb.group(new ERxAdmin()),
                RxDoseSchs: this._fb.array([]),
                dosePerDay: null,
            }),
            insuranceInfo: this._fb.group({
                insurance: this._fb.group(new Insurances()),
                organization: this._fb.group(new IOrganization()),
                insuCarrier: this._fb.group(new InsuCarrier()),
                insuPlan: this._fb.group(new InsuPlan()),
                InsuTransOther: this._fb.group(new InsuTransOther()),
            }),
            drugPickVerif: this._fb.array([]),
            prescRefill_Observtions: this._fb.group(
                new PrescRefill_Observtions()
            ),
            rxDelivery: this._fb.group(new RxDelivery()),
            rPHVerifs: this._fb.group(new RPHVerifs()),
            PrescRefill_RxTags: this._fb.group(new PrescRefill_RxTags()),
            drugPickVerificationStatus: [],
            erxDetailsInRx: this._fb.group(new ErxDetailsData()),
            rph1VerificationStatus: [],
            rph2VerificationStatus: [],
            IsDocumentExists: "",
            FollowUpStatus: [],
            RphAppUserId: [],
            Price: [],
            isFromDocuementQueue: [],
            isFromDocuementQueueForRxHistory: [],
            isNavigateFromDocumentQueue: [],
            PickupRx: this._fb.group(new PickupRx()),
            pickupRx: this._fb.group(new PickupRx()),
            RxPay: [],
            RxExpirationDate: [],
            rxExpirationDate: [],
            islatestrefill: [],
            eRxDetailsViewModel: this._fb.group(new ERxDetailsViewModel()),
            trackRx: [],
            ResponseStatus: [],
            compounddrugs: [],
            DueDtTm: [],
            FileDtTm: [],
            isnewpatient: [],
            isOriginalRx: [],
            dme: [],
            workmancomp: [],
            pharmacy: [],
            refillNumberasPerSettings: [],
            OtherFee: [0],
            OtherAmount: [0],
            TaxAmt: [0],
            AmountDue: [0],
            rxType: [],
            rxRefillPrevoiusScreenType: [],
            rxFileType: [],
            routeFrom: [],
            isPatHisEnbl: [false],
            patPopCompl: [],
            prescPopCompl: [],
            drugPopCompl: [],
            patCompl: [],
            prescCompl: [],
            drugCompl: [],
            isAfterBillToCash: [],
            dailyDose: [null],
            rxActnType: [null],
            isQuickVerf: [false],
            isNewRxFile: [false],
            isDetOldRx: null,
            fileToFill: null,
            fileToFillNewRx : null,
            isNewFrmExisting: null,
            Documents: this._fb.array([]),
            refillFrmHist: false,
            focusOnSearch: null,
            MaxDailyDose: null,
            intialAuth: 0,
            closeBillDet: false,
            scldQty: 0,
            FaxRefillId: [0],
            pricingValues: null,
            insuChanges: false,
            refreshPat: false,
            rxRelatedCodeInfo: this._fb.group(new ECodeInfo()),
            frmInprog: false,
            calQtyRemFrmIn: false,
            IsPrescnumExists: false,
            isRxDocAttached: false,
            isLabelPrinted: false,
            IsMedGuideExists : false,
            patRest: false,
            isPatIndicator: true,
            equChange: false,
            transmissionStatus: null,
            rphverifDtTm: null,
            profitAmnt: null,
            ProfitAmnt: null,
            isDetOldRxRf: null,
            isDetOldRxRfFill: null,
            islatestpartialrefill: null,
            check340BP: false,
            check340BPr: false,
            drugEquSearchValFrmSuggest: false,
            Is340NotByDrug: false,
            IsFromTransmissionRejectScreen: null,
            Is340BBy: null,
            bucketChanged: false,
            notOpenAuthWarn: false,
            rxExpiryDateByUser : null,
            rxThreshold : null,
            isFromErxOrDmsType : null,
            FromNavigationForBenfitCheck:  null,
            TotalRemainingQty : null,
            LeftOverQty: false,
            NavigationType: null,
            isRxTransferred: false,
            alreadyChckd: false,
            alreadyCheckedLowInventory: false,
            primaryInsurance: this._fb.group(new PrimaryInsurance()),
            noteLogPopupDisplay: false,
            IsOverFillRx: false,
            isLowInventoryCheck: false,
            isLowInventoryAtTimeofSave: null,
            statusIdFlag: false,
            IsPatientPrimaryInsuranceChanged: false,
            isLowInventoryCheckForAltkeys: false,
            isDisQtyOldValue: null,
            isDisQtyNewValue: null,
            afterDrugInactv: false,
            authRefillsCheckAtSave: false,
            priceCalculationsCheckAtSave: false,
            selectedRxFromRefill: null,
            isInprogressRx: false,
            inProgressRxPriceVals: null,
            IsFromFile: false,
            refillRxPriceValsForInprogress: null,
            TreatmentTypeId: null,
            TreatmentTypeName: null,
            OrigProFee: 0,
            activeElementID: 0,
            totalPartialQty: null,
            totalPartialQtyForPar: null,
            isPreviousRefillPartialCreatedOrNot: false,
            erxCount: 0,
            dmsCount: 0,
            Precendence : '',
            equilantSelectTypeForPrefDrug: '',
            IsIntialQtyDispGivining: 0,
            isOverrideCompl: false,
          //  patientDrugHistory: false
            refillinsu: [],
            annotationNotes: null,
            IsPatientDrugDispensedHistory: false,
            primeRxAuthRefillCount: null,
            SubClarifIdComb: null,
            compoundIngredientsPopup: true,
            totalRxRemQty: 0,
            TotalRefillsQty: 0,
            NotePopupscreenType: null,
            isCostRefreshed : null,
            IsPrescriberChanged: false,
            isFrmDpvVerified : null,
            IouDetails: null,
            IsDSCSAExists : false,
            NotesAvailability: false,
            RefillInsuList:null,
            DrugRest:  null,
            rxExtraInfo: {},
            IsElectornicRx:  false,
            IsHOAPreviewed: false,
            IsHoAReGenerate: false,
            isAltKeyPress: false,
            isAltKeyPressFrm: null,
            isSupplyDaysChanged: false,
            keyCode: null,
            InputFieldFocusId: null,
            isSigFocusedSave: null,
            stopSave: false,
            SaveNotesForRefill: null,
            isSeqChanged: false,
            prevSigCodes : null
        });

        const DrugFG: any = eRxFG.controls["Drug"];
        // DrugFG.setControl("drugdescription", this._fb.array([]));
        // DrugFG.setControl("dosageinformation", this._fb.array([]));
        return eRxFG;
    }

    patchRxInfo(rxInfo: any, eRxFG: FormGroup, routeFrom?: string  , type? : string) {
        let self = this;
        const techLogkey = this._userService.getToken("TechUserName");
        Object.keys(rxInfo).map(function (key, index) {
            if (key && rxInfo[key] && eRxFG.controls[key]) {
                if (
                    [
                        "insuranceInfo",
                        "RxAdminDoseSchedule",
                        "RxOtherAmount",
                        "drugPickVerif",
                        "RxPay",
                        "Documents",
                        "rPHVerifs"
                    ].indexOf(key) === -1
                ) {
                    if (rxInfo[key] != undefined && rxInfo[key] != null) {
                        if (
                            eRxFG.controls[key] instanceof FormGroup ||
                            eRxFG.controls[key] instanceof FormArray
                        ) {
                            const comFG: any = eRxFG.controls[key] as FormGroup;
                            for (const rfkey in rxInfo[key]) {
                                if(key === "PrescReFill" && (rfkey === "LotNum" || rfkey === "DrugLotId" || rfkey === "ExpDt" || rfkey === "LotExpiryDate")) {
                                    continue;
                                }
                                else if (key === "SigCodes" || key === "IouDetails") {
                                    if (rxInfo[key].hasOwnProperty(rfkey)) {
                                        if (comFG.get(rfkey)) {
                                            comFG.controls[rfkey].setValue(
                                                rxInfo[key][rfkey]
                                            );
                                        } else {
                                            comFG.addControl(
                                                rfkey,
                                                new FormControl(
                                                    rxInfo[key][rfkey]
                                                )
                                            );
                                        }
                                    }
                                } else if (rfkey && rxInfo[key].hasOwnProperty(rfkey)) {
                                    if (comFG.get(rfkey)) {
                                        if (
                                            rfkey === "DispQty" ||
                                            rfkey === "OrdQty"
                                        ) {
                                            comFG.controls[rfkey].setValue(
                                                parseFloat(
                                                    rxInfo[key][rfkey]
                                                ).toFixed(3)
                                            );
                                        } else if ( (rxInfo["rxType"] && rxInfo["rxType"] === 'er' || type)  && rfkey === "FillDtTm") {
                                                comFG.controls["FillDtTmCopy"].setValue(rxInfo[key][rfkey] ?
                                                    rxInfo[key][rfkey] : null);
                                                comFG.controls[rfkey].setValue(
                                                rxInfo[key][rfkey] ?
                                                moment.utc(rxInfo[key][rfkey]).local() : null
                                                );
                                        } else if (rfkey === "OrderDtTm" && (!type || type === "rf")) {
                                                comFG.controls["OrderDtTmCopy"].setValue(rxInfo[key][rfkey] ?
                                                rxInfo[key][rfkey] : null);
                                                comFG.controls[rfkey].setValue(
                                                    rxInfo[key][rfkey] ?
                                                    moment.utc(rxInfo[key][rfkey]).local() : null
                                                );
                                        } else if (
                                            rfkey === "UnitPriceUNC" ||
                                            rfkey === "UnitPriceAWP" ||
                                            rfkey === "TotalRxCost" ||
                                            rfkey === "OtherAmnt" ||
                                            rfkey === "ProfFee" ||
                                            rfkey === "Price" ||
                                            rfkey === "Discount" ||
                                            rfkey === "TotalAmt" ||
                                            rfkey === "BalDue" ||
                                            rfkey === "OtherFee" ||
                                            rfkey === "OtherAmount" ||
                                            rfkey === "TaxAmt"
                                        ) {
                                            comFG.controls[rfkey].setValue(
                                                parseFloat(
                                                    rxInfo[key][rfkey] ? rxInfo[key][rfkey] : 0
                                                ).toFixed(2)
                                            );
                                        } else if (rfkey === "TechInitials" && type === "rf") {
                                            if (!techLogkey) {
                                                rxInfo[key][rfkey] = "";
                                                comFG.controls[rfkey].patchValue("");
                                            } else {
                                                comFG.controls[rfkey].patchValue(rxInfo[key][rfkey]);
                                            }
                                        } else if (
                                            !Array.isArray(rxInfo[key].hasOwnProperty(rfkey))
                                        ) {
                                            comFG.controls[rfkey].setValue(
                                                rxInfo[key][rfkey]
                                            );
                                        }
                                    } else {
                                        // TODO: Need to fix this
                                        // if (!Array.isArray(rxInfo[key][rfkey])) {
                                        //     comFG.addControl(
                                        //         rfkey,
                                        //         new FormControl(rxInfo[key][rfkey])
                                        //     );
                                        // }
                                    }
                                } else if (rfkey && !rxInfo[key][rfkey]) {
                                    if (rfkey === "ReFillNum") {
                                        comFG.controls[rfkey].setValue(0);
                                    }
                                }
                            }
                        } else {
                            eRxFG.controls[key].setValue(rxInfo[key]);
                        }
                    }
                } else if (key === "RxAdminDoseSchedule") {
                    if (routeFrom !== "rf") {
                        const adminRxDoseSchdl: FormGroup = eRxFG.controls[
                            key
                        ] as FormGroup;
                        if (rxInfo[key]["RxAdmin"]) {
                            adminRxDoseSchdl.controls["RxAdmin"].patchValue(
                                rxInfo[key]["RxAdmin"]
                            );
                            if (rxInfo[key] && rxInfo[key]["PrescriptionId"]) {
                                adminRxDoseSchdl.controls[
                                    "PrescriptionId"
                                ].patchValue(rxInfo[key]["PrescriptionId"]);
                            }
                        }
                        const rxDoseSchsFGs = rxInfo[key][
                            "RxDoseSchs"
                        ].map((val) => self._fb.group(val));
                        const rxDoseSchsFAs = self._fb.array(rxDoseSchsFGs);
                        adminRxDoseSchdl.setControl(
                            "RxDoseSchs",
                            rxDoseSchsFAs
                        );
                    }
                } else if (key === "RxOtherAmount") {
                    self.patchOtherAmount(eRxFG, rxInfo, key);
                } else if (key === "drugPickVerif" || key === "rPHVerifs") {
                    self.patchVerifications(eRxFG, rxInfo, key);
                } else if (key === "RxPay") {
                    const rxPay = rxInfo.RxPay ? rxInfo.RxPay : [];
                    const rxPayFG = rxPay.map((val) => self._fb.group(val));
                    const rxPayFA = self._fb.array(rxPayFG);
                    eRxFG.setControl("RxPay", rxPayFA);
                } else if (key === "insuranceInfo") {
                    const insuInfoFG: FormGroup = eRxFG.controls[
                        key
                    ] as FormGroup;
                    Object.keys(rxInfo[key]).map((inkey) => {
                        if (rxInfo[key][inkey]) {
                            insuInfoFG.controls[inkey].patchValue(
                                rxInfo[key][inkey]
                            );
                        }
                    });
                } else if (key === "Documents") {
                    const documents = rxInfo.Documents ? rxInfo.Documents : [];
                    const documentsFG = documents.map((val) => self._fb.group(val));
                    const documentsFA = self._fb.array(documentsFG);
                    eRxFG.setControl("Documents", documentsFA);
                } else if (key) {
                    if (rxInfo[key]) {
                        if (key === "ProfitAmnt") {
                            eRxFG.controls[key].patchValue(parseFloat(rxInfo[key] ? rxInfo[key] : 0).toFixed(2));
                        } else {
                            eRxFG.controls[key].patchValue(rxInfo[key]);
                        }
                    }
                }
            } else if (rxInfo[key] === 0 && (key === "islatestrefill" || key === "islatestpartialrefill" || key === "ProfitAmnt")) {
                eRxFG.controls[key].patchValue(key === "ProfitAmnt" ? "0.00" : 0);
            }
        });
        eRxFG.controls["intialAuth"].patchValue(eRxFG.value["Prescription"]["ReFillNum"]);
        return eRxFG;
    }

    patchVerifications(eRxFG, rxInfo, key) {
        const self = this;
        if (key && (key === "drugPickVerif" || key === "both")) {
            const drugPickVerif = rxInfo?.drugPickVerif
                ? rxInfo.drugPickVerif
                : [];
            let drugPickVerifFGS = [];
            if (drugPickVerif && drugPickVerif.length) {
                drugPickVerifFGS = drugPickVerif.map((val) => self._fb.group(val));
            }
            const drugPickVerifFAS = self._fb.array(drugPickVerifFGS);
            eRxFG.setControl("drugPickVerif", drugPickVerifFAS);
            eRxFG.controls["drugPickVerificationStatus"].patchValue(rxInfo?.drugPickVerificationStatus);
            eRxFG.controls["PrescReFill"].patchValue({
                OweQty: rxInfo?.PrescReFill.OweQty,
                IsIou: rxInfo?.PrescReFill.IsIou
            });
        }
        if (key === "rPHVerifs" || key === "both") {
            const rPHVerifs = rxInfo?.rPHVerifs
                ? rxInfo.rPHVerifs
                : [];
            let rphVerifFGS = [];
            if (rPHVerifs && rPHVerifs.length) {
                rphVerifFGS = rPHVerifs.map((val) => self._fb.group(val));
            }
            const rphVerifFAS = self._fb.array(rphVerifFGS);
            eRxFG.setControl("rPHVerifs", rphVerifFAS);
            eRxFG.patchValue({
                rph1VerificationStatus: rxInfo?.rph1VerificationStatus,
                rph2VerificationStatus: rxInfo?.rph2VerificationStatus
            });
        }
    }

    patchOtherAmount(eRxFG, rxInfo, key) {
        const self = this;
        eRxFG.controls["RxOtherAmount"].patchValue(rxInfo[key]);
        const rxOtherAmountFG: FormGroup = eRxFG.controls[
            "RxOtherAmount"
        ] as FormGroup;
        const rxOtherAmount: any = rxInfo[key];

        Object.keys(rxInfo[key]).map((inkey) => {
            if (rxInfo[key][inkey] && rxOtherAmountFG.controls[inkey]) {
                rxOtherAmountFG.controls[inkey].patchValue(
                   rxInfo[key][inkey]
                );
            } else if(inkey === "Incentive" ||inkey  === 'PaidAmnt' || inkey === 'ProfFee') {
                rxOtherAmountFG.controls[inkey].patchValue(
                    "0.00"
                 );
            }
        });
        if (
            rxInfo.RxOtherAmount &&
            rxInfo.RxOtherAmount.OtherAmntMisc
        ) {
            const rxOtherAmountMisc = rxOtherAmount.OtherAmntMisc
                ? rxOtherAmount.OtherAmntMisc
                : [];
            const otherAmountDetFGS = rxOtherAmount.OtherAmntMisc.map(
                (val) => self._fb.group(val)
            );
            const otherAmountDetFAS = self._fb.array(
                otherAmountDetFGS
            );
            rxOtherAmountFG.setControl(
                "OtherAmntMisc",
                otherAmountDetFAS
            );
        }
    }

    createAndPatchFGInfo(data) {
        return this.createMainForm(data);
    }

    createMainForm(fgData) {
        let mainFormGroup = null;
        if (Array.isArray(fgData)) {
            return this.generateFA(fgData);
        } else if (typeof fgData === "object" && fgData !== null) {
            mainFormGroup = this._fb.group(fgData);
            Object.keys(fgData).filter((d) => {
                if (
                    Array.isArray(fgData[d]) ||
                    (typeof fgData[d] === "object" && fgData[d] !== null)
                ) {
                    mainFormGroup.setControl(d, this.createMainForm(fgData[d]));
                }
            });
        }
        return mainFormGroup;
    }

    generateFA(datFA) {
        const gFA = datFA.map((val) => this.createMainForm(val));
        return this._fb.array(gFA);
    }
}
