import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { constant } from "..";

@Injectable({
    providedIn: "root"
})
export class DrugPickVerificationService {
    constructor(private _http: HttpClient) {}

    getRxDrugStatus(
        rxId: number,
        RefillNum: number,
        PartialFillNum : number,
        NDC: any
    ): Observable<any> {
        return this._http.get<any>(
            constant.GET_DrugPickVerification_Status.replace(
                "{PrescNum}",
                "" + rxId
            ).replace("{PartialFillNum}", "" + PartialFillNum)
                .replace("{RefillNum}", "" + RefillNum)
                .replace("{NDC}", "" + NDC)
        );
    }

    sendNotVerifiedFilters(data: any): Observable<any> {
        let headers = new HttpHeaders();
        if (sessionStorage.getItem("drug-pick")) {
            headers = headers.set("dph-token", sessionStorage.getItem("drug-pick")).set("isLoadNotReq", "true");
        }
        return this._http.post<any>(
            constant.POST_DrugPickVerification_NotVerified,
            data,{ headers }
        );
    }

    sendVerifiedFilters(data: any): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("isLoadNotReq", "true")
        };
        return this._http.post<any>(
            constant.POST_DrugPickVerification_Verified,
            data, reqOptns
        );
    }

    getRxDetails(PrescNum: number, refillNum: number, partialFillNum: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_DrugPickVerification_RxDetails.replace(
                "{PrescNum}",
                "" + PrescNum
            ).replace("{RefillNum}", "" + refillNum).replace("{PartialfillNum}", "" + partialFillNum)
        );
    }

    sendDrugVerifyDetails(data: any) {
        return this._http.post<any>(
            constant.POST_DrugPickVerification_VerifyDetails,
            data
        );
    }

    SendBatchDrugVerificationDetails(data: any) {
        return this._http.post<any>(
            constant.POST_BatchDrugVerification,
            data
        );
    }

    getRxImage(PrescNum: number): any {
        return this._http.get<boolean>(
            constant.GET_DrugPickVerification_Rximage.replace( "{PrescNum}" , "" + PrescNum)
        );
    }

    getDphVerifHistory(payload: any): Observable<any> {
        let headers = new HttpHeaders();
        if (sessionStorage.getItem("drug-pick")) {
            headers = headers.set("dph-token", sessionStorage.getItem("drug-pick"));
        }
        return this._http.post<any>( constant.POST_RX_DRUG_PICK_HISTORY, payload, { headers } );
    }

    SendDrugReVerifyDetails(data: any) {
        return this._http.post<any>(constant.POST_DrugPickVerification_Reverify, data);
    }

}
