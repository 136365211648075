import { CommonService } from "./../../../services/common.service";
import { MsgConfig } from "./../../../app.messages";
import { FormGroup, FormBuilder, FormArray, FormControl } from "@angular/forms";
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    EventEmitter,
    Output,
    ElementRef,
    HostListener
} from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RxTransferService, AlertService } from "../../../services";
import { Pharmphone, City, State } from "../../../models";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RegEx } from "../../../app.regex";

@Component({
    selector: "app-transfer-pharmacy",
    templateUrl: "./transfer-pharmacy.component.html"
})
export class TransferPharmacyComponent implements OnInit {
    scndpharmValid = false;
    addPharFG: FormGroup;
    isEdit: any;
    hasState = true;
    hasCity = true;
    states: any;
    cities: any;
    modelRef: any;
    TransferPharmacies: any;
    pharmFGInvalid: boolean;
    public citySelected: any;
    public stateSelected: any;
    PhnRegex: any;
    unsubscribe$: Subject<void> = new Subject();

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @Output()
    Result = new EventEmitter<any>();

    @Output()
    EditResult = new EventEmitter<any>();

    @ViewChild("AddPopUp")
    AddPopUp: ElementRef;
    warningpopup: boolean;

    @Input()
    set PharmacyFG(ed: FormGroup) {
        this.addPharFG = ed;
        this.TransferPharmacies = ed.controls["TransferPharmacies"];
    }
    @Input()
    set IsEditMode(ed: any) {
        this.isEdit = ed;
        if (this.isEdit) {
            this.citySelected = new City();
            if (this.TransferPharmacies) {
                if (this.TransferPharmacies.value.CityId) {
                    this.citySelected.Name = this.TransferPharmacies.value[
                        "CityName"
                    ];
                }
                this.stateSelected = new State();
                if (this.TransferPharmacies.value.StateId) {
                    this.stateSelected.Id = this.TransferPharmacies.value[
                        "StateId"
                    ];
                    this.stateSelected.Name = this.TransferPharmacies.value[
                        "StateName"
                    ];
                }
                if (this.TransferPharmacies.value.NPINum) {
                    this.TransferPharmacies.value.NPINum = this.TransferPharmacies.value.NPINum;
                   // this.TransferPharmacies.value.NPINum = this.TransferPharmacies.value.NPINum.toString();
                }
            }
        }
    }


    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event && event.which === 27 && this._modalService.hasOpenModals()) {
            if (this.warningpopup === true) {
                event.preventDefault();
                if (this.modelRef) {
                this.modelRef.close();
                }
                this.warningpopup = false;
            } else {
            event.preventDefault();
            this.closeModal("cancel", null);
            }
        }
    }


    constructor(
        private _modalService: NgbModal,
        private _rxSer: RxTransferService,
        private _fb: FormBuilder,
        public activeModal: NgbActiveModal,
        private _alertService: AlertService,
        private _commonServ: CommonService
    ) {}

    ngOnInit() {
        this.PhnRegex = RegEx;
        // this.modelRef = this._modalService.open(this.AddPopUp, {
        //     centered: true
        // });
    }
    get Phones(): FormArray {
        return this.addPharFG.get("Phones") as FormArray;
    }
    get phone1(): FormControl {
        return this.addPharFG.get("phone1") as FormControl;
    }
    get phone2(): FormControl {
        return this.addPharFG.get("phone2") as FormControl;
    }
    get Fax(): FormControl {
        return this.addPharFG.get("Fax") as FormControl;
    }
    setDefaultValues() {
        const msgAry = new Array<any>();
        for (let i = 1; i < 4; i++) {
            const message = new Pharmphone();
            if (i === 1 || i === 2) {
                message.PhoneCatId = 3;
            } else if (i === 3) {
                message.PhoneCatId = 4;
            }
            msgAry.push(message);
        }
        this.generateFA("Phone", msgAry);
    }
    generateFA(key: string, values: any[]) {
        if (values) {
            const extraCostFGs = values.map(val => this._fb.group(val));
            const extraCostFormArray = this._fb.array(extraCostFGs);
            this.addPharFG.setControl(key, extraCostFormArray);
        }
    }
    closeModal(type, value) {
       // this.modelRef.close();
       const dataSend = {
        IsPopUpClosed: false,
        Editvalue: false,
        AddValue: false
       };
       if (type === "Edit") {
        dataSend.Editvalue = value;
        dataSend.AddValue = false;
       } else if (type === "Add") {
        dataSend.Editvalue = false;
        dataSend.AddValue = value;
       } else if (type === "cancel") {
        dataSend.Editvalue = false;
        dataSend.AddValue = false;
       }
       this.activeModal.close(dataSend);
       // this.IsPopUpClosed.emit(dataSend);
    }
    prescZipSelected(zipMaster: any) {
        if (zipMaster) {
            this.citySelected = new City();
            this.citySelected.Id = zipMaster.CityId;
            this.citySelected.StateId = zipMaster.StateId;
            this.citySelected.Name = zipMaster.CityName;

            this.stateSelected = new State();
            this.stateSelected.Id = zipMaster.StateId;
            this.stateSelected.Name = zipMaster.StateName;

            const dataInfo: any = this.addPharFG.controls["TransferPharmacies"];
            dataInfo.controls["StateId"].setValue(zipMaster.StateId);
            dataInfo.controls["CityId"].setValue(zipMaster.CityId);
        }
    }

    checkDuplicatePharm(content) {
        const data = this.addPharFG.value;
        if (this.addPharFG.valid) {
        this._rxSer.checkDuplicatePharmacy(data["TransferPharmacies"])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.warningpopup = false;
                if (resp && resp === 1) {
                    this._alertService.error("NPI number already exist. Please enter a different one,", false, "long");
                    this.TransferPharmacies.controls["NPINum"].patchValue(null);
                    document.getElementById("NPINumber").focus();
                } else if (resp && resp === 2) {
                    this._alertService.error("DEA# alredy exist. Please enter a different one.", false, "long");
                    this.TransferPharmacies.controls["DEANum"].patchValue(null);
                    document.getElementById("DEANumber").focus();
                } else if (resp && resp === 3) {
                    this.warningpopup = true;
                   this.modelRef = this._modalService.open(content, {centered: true, backdrop: false, keyboard: false, windowClass:"large--content"});
                } else if (resp && resp === 4) {
                    if (this.isEdit) {
                        this.editPharmacy();
                    } else {
                        this.addNewPhar();
                    }
                }
        });
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    addNewPhar() {
        this.setDefaultValues();
        const data = this.addPharFG.value;
        const dataInfo = this.addPharFG;
        const phoneData: any = this.addPharFG.controls["Phone"];
        for (const key in this.addPharFG.value) {
            if (key === "Phone") {
                if (dataInfo.controls["phone1"].value) {
                    phoneData.controls[0].value["Phone"] =
                        dataInfo.controls["phone1"].value;
                } else {
                    phoneData.controls[0].value["Phone"] = "";
                }
                if (dataInfo.controls["phone2"].value) {
                    phoneData.controls[1].value["Phone"] =
                        dataInfo.controls["phone2"].value;
                } else {
                    phoneData.controls[1].value["Phone"] = "";
                }
                if (dataInfo.controls["Fax"].value) {
                    phoneData.controls[2].value["Phone"] =
                        dataInfo.controls["Fax"].value;
                } else {
                    phoneData.controls[2].value["Phone"] = "";
                }
            }
        }
        if (this.addPharFG.valid) {
            this._rxSer.addPharmacy(data)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this._alertService.success(MsgConfig.SAVE_SUCCESS);
                    this.closeModal("Add", resp);
                   // this.Result.emit(resp);
                } else {
                    this._alertService.error(MsgConfig.SAVE_FAIL);
                }
            });
        } else {
            this.pharmFGInvalid = true;
           // this._alertService.error(MsgConfig.ENTER_REQ_FIELD);
        }
    }

    editPharmacy() {
        const data = this.addPharFG.value;
        const dataInfo = this.addPharFG;
        const transfer: any = this.addPharFG.controls["TransferPharmacies"];
        const phoneData: any = this.addPharFG.controls["Phone"];
        for (const key in this.addPharFG.value) {
            if (key) {
                if (key === "Phone") {
                if (phoneData.length) {
                    if (dataInfo && dataInfo.controls && dataInfo.controls["phone1"] && dataInfo.controls["phone1"].value && phoneData?.controls?.length && phoneData?.controls[0]?.value && !(phoneData.controls[0].value["Phone"] === " ")) {
                        phoneData.controls[0].value["Phone"] =
                            (dataInfo.controls["phone1"].value).trim();
                    } else {
                        phoneData.controls[0].value["Phone"] = "";
                    }
                    if (dataInfo && dataInfo.controls && dataInfo.controls["phone2"] && dataInfo.controls["phone2"].value && phoneData?.controls?.length && phoneData?.controls[0]?.value && !(phoneData.controls[1].value["Phone"] === " ")) {
                        phoneData.controls[1].value["Phone"] =
                            (dataInfo.controls["phone2"].value).trim();
                    } else {
                        phoneData.controls[1].value["Phone"] = "";
                    }
                    if (dataInfo && dataInfo.controls && dataInfo.controls["Fax"] && dataInfo.controls["Fax"].value && phoneData?.controls?.length && phoneData?.controls[0]?.value && !(phoneData.controls[2].value["Phone"] === " ")) {
                        phoneData.controls[2].value["Phone"] =
                            (dataInfo.controls["Fax"].value).trim();
                    } else {
                        phoneData.controls[2].value["Phone"] = "";
                    }
                }
                }
            }
        }
        //  delete data["phone1"];
        //  delete data["phone2"];
        //  delete data["Fax"];
        if (
            data["TransferPharmacies"].ZipCode &&
            data["TransferPharmacies"].AddressLine1 &&
            data["TransferPharmacies"].PharmName &&
            data["TransferPharmacies"].CityId &&
            data["TransferPharmacies"].StateId
        ) {
            if (this.addPharFG.valid) {
                this._rxSer.editPharmacy(data)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this._alertService.success(MsgConfig.UPDATE_SUCCESS);
                        this.closeModal("Edit", resp);
                      //  this.EditResult.emit(resp);
                    } else {
                        this._alertService.error(MsgConfig.UPDATE_FAIL);
                    }
                });
            } else {
                this.pharmFGInvalid = true;
                this._alertService.error("Enter valid values.");
            }
        } else {
            this.pharmFGInvalid = true;
            // this.scndpharmValid = true;
            // this._alertService.error(MsgConfig.ENTER_REQ_FIELD);
        }
    }

    optedStateValue(val) {
        if (val) {
            // this.stateSelected = new State();
            //this.stateSelected.Id = val.Id;
            // this.stateSelected.Name = val.StateName;
            const dataInfo: any = this.addPharFG.controls["TransferPharmacies"];
            dataInfo.controls["StateId"].setValue(val.Id);
            dataInfo.controls["StateName"].setValue(val.Name);
            this.citySelected = new City();
            dataInfo.controls["CityId"].setValue(null);
        }
    }

    optedCityValue(val) {
        const transfer: any = this.addPharFG.controls["TransferPharmacies"];
        transfer.controls["CityName"].setValue(val.Name);
        transfer.controls["CityId"].setValue(val.Id);
    }
    formatter = (x: any) => {
        return x.Name;
    };
    formatter1 = (x: any) => {
        return x.Name;
    };
    onAddressChange(address) {
        const result = address.replace(/^\s+/g, '');
        this.TransferPharmacies.controls["AddressLine1"].patchValue(result);
    }
}
