import { CommonService, RxService, AlertService } from "src/app/services";
import { MsgConfig } from "src/app/app.messages";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    HostListener
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { AuditLogUtils, NewAuditUtils, ValidateFormFieldsUtils, WijimoUtil } from "src/app/utils";
import { DUR, RxAuditLog, SystemData } from "src/app/models";
import { RxStore } from "src/app/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as _ from "lodash";

@Component({
    selector: "app-dur",
    templateUrl: "./dur.component.html"
})
export class DurComponent implements OnInit {
    editMode = false;
    DurFG: FormGroup;
    actvHeaders: string[];
    DURWJ: any;
    defDurVal: any[];
    deleteId: any;
    Id: any;
    originalData: any;
    formGroupInvalid = false;
    modal: any;
    unsubscribe$: Subject<void> = new Subject();
    editIndex: any;
    deleteIndex: any;
    accessingFrom: string;
    coAgentNum: any = null;
    @Input() rxType: string;
    @Input() systemData: SystemData;
    @Input() isFromTrRx: any;
    @Input() showAddForDUR = true;
    @Input() isFromTrRejct: any;
    @Input() rxno: any;
    @Input() newRxDur: any[];
    @Input() refId: any;
    @Input() IsFromRxHistory:any;
    @Input() VaccineForm: any;
    @Input() frmQukRef: any;
    @Input() fromNewRx : boolean
    @Input() openRxDur: any;
    @Input() frmVerfScrn: boolean;
    @Output()  CloseEditMode = new EventEmitter<any>();
    @Output() IsPopUpClosed = new EventEmitter<any>();
    @Output() IsPopUpClosedDUR = new EventEmitter<any>();
    @Output() totalDurRecords = new EventEmitter<any>();
    @Output() emitFocus = new EventEmitter<any>();
    @ViewChild("info", { static: true }) info: any;
    @ViewChild("DURADD", { static: true }) DURADD: any;
    @ViewChild("DUR") DUR: any;
    totalCount: number;
    modelRef: any;
    message: string;
    wjHeaders: {hName:string, isVisible:boolean, width:number}[];
    durDataNewRxFlow: any;
    hideTabData = false;
    hideforNewRx: boolean;
    hideDurHeader: boolean;
    item: any;
    newRxEditMode: boolean;
    noRecords: boolean;
    PrescrirefillId : any;
    isDataExists = true;
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }
    
    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27 && this.modal && this._modalService.hasOpenModals()) { // esc
            this.modal.close();
            this.modal = null;
            event.preventDefault();
        }
    }

    constructor(
        private _rxService: RxService,
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _auditUtils: AuditLogUtils,
        private _alrtServ: AlertService,
        private _cmmnServ: CommonService,
        private _rxStore: RxStore,
        private _validUtils: ValidateFormFieldsUtils,
        private _nwAudtUtl: NewAuditUtils,
        private _wijimoUtils:WijimoUtil
    ) {
        this.DurFG = this._fb.group(new DUR());        
    }

    ngOnInit() {
        this.durDataNewRxFlow = this._rxStore.addDur$["source"]["value"];

        if (!this.VaccineForm && (!this.durDataNewRxFlow || !this.durDataNewRxFlow.length) && (this.rxType === "nr" || this.rxType === "nrf" || this.rxType === "rf" || this.rxType === "rnr")) {
            this.hideDurHeader = true;
            this.openNewDURPopup(this.DURADD);
        } else if (!this.VaccineForm &&(this.rxType === "er" ||
              this.rxType === "brx" || this.isFromTrRx) && this.rxno && !this.openRxDur) {
            this.getDURData();
        } else if (!this.VaccineForm && this.durDataNewRxFlow && this.durDataNewRxFlow.length &&
            (this.rxType === "nr" || this.rxType === "nrf" || this.rxType === "rf" || this.rxType === "rnr")) {
            this.hideforNewRx = false;
            this.totalCount = this.durDataNewRxFlow.length;
            this.getNEWDUR(true);
        } else if (this.VaccineForm) {
            this.totalCount = this.durDataNewRxFlow?.length ? this.durDataNewRxFlow.length : 0;
            this.isDataExists = this.totalCount ? true : false;
            this.defDurVal = this.durDataNewRxFlow;
            this.getNEWDUR(true);
        } else if (this.openRxDur && this.rxno && this.refId) {
            this.fromNewRx = true;
            this.getDURData()
        } else {
            this.newRxDur = [];
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "DurValWj"  || w["WijmoKey"] === "DURValNewRxWJ")) {
                this.patchDefaultValHeaders();
            }
        });
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }


    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData(this.VaccineForm ? "DURValNewRxWJ" : (this.rxType === "nr" || this.rxType === "rf"
        || this.rxType === "nrf" || this.rxType === "rnr" ? "DURValNewRxWJ" : "DurValWj"));
        this.wjHeaders = this._wijimoUtils.patchDefHeader(this.VaccineForm ? "DURValNewRxWJ" : (this.rxType === "nr" || this.rxType === "rf" ||
         this.rxType === "nrf" || this.rxType === "rnr"  ? "DURValNewRxWJ" : "DurValWj"), storedWJ);
    }

    openNewDURPopup(content) {
        this.modal = this._modalService.open(content, {
            centered: true,
            backdrop: false,
            size: "lg"
        });
    }

    getNEWDUR(isFromOninit: boolean = false) {
        this.generateWJ(this.durDataNewRxFlow);
        if(!isFromOninit) {
            this._rxStore.storeDurNewRxFlow(this.durDataNewRxFlow);
        }
    }
    openPopOnWijimo(content, event) {
        if (event.ctrlKey) {
            event.preventDefault();
            if (event.which === 65) {
                this.openNewDURPopup(content);
            } else if (event.which === 67) {
                this.IsPopUpClosedDUR.emit();
            }
    }
}
    async getDURData() {
        this.totalCount = 0;
        const resp: any = await this._rxService.getDurValues(this.rxno,this.refId).toPromise();
        if (resp && resp.length) {
            this.isDataExists = true;
            this.totalCount = resp.length > 0 ? 1 : 0;
            this.defDurVal = resp;
            this.generateWJ(resp);
            this._rxStore.storeDURInfo(resp);
        } else {
            this.totalCount = 0;
            this.isDataExists = false;
            this.DURWJ = new CollectionView(null)
        }
        this.totalDurRecords.emit((resp && resp.length) ? resp.length : 0 );
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    generateWJ(info: any) {
        if (info) {
            this.totalCount = info.length;
            this.DURWJ = new CollectionView(
                info.map((dur, i) => {
                    const j = {};
                    j["Clinical Signif.Code"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "clinicalSignf", "Id", dur.ClinicalSignfId, (this.VaccineForm ? "Name" : "Remarks") );
                    j["Agent Id"] = dur && dur.CoAgentIdType ? dur.CoAgentIdType : null;
                    j["Level"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "effortLvl", "Id", dur.EffortLvlId, (this.VaccineForm ? "Name" : "Remarks") );
                    j["Professional Service"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "profServs", "Id", dur.ProfServId, (this.VaccineForm ? "Name" : "Remarks") );
                    j["Reason of Service"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "servReason", "Id", dur.ServReasonId, (this.VaccineForm ? "Name" : "Remarks") );
                    j["Result of Service"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "servResult", "Id", dur.ServResultId, (this.VaccineForm ? "Name" : "Remarks") );
                    j["Id Q"] = dur && dur.CoAgentNum ? dur.CoAgentNum : null;
                    j["PH-Comment"] = dur && dur.PhComment ? dur.PhComment : null;
                    j["Actions"] = "";
                    j["value"] = i;
                    j["Id"] = dur && dur.Id ? dur.Id : null;
                    j["ClinicalSignfId"] = dur && dur.ClinicalSignfId ? dur.ClinicalSignfId : null;
                    j["EffortLvlId"] = dur && dur.EffortLvlId ? dur.EffortLvlId : null;
                    j["ProfServId"] = dur && dur.ProfServId ? dur.ProfServId : null;
                    j["ServReasonId"] = dur && dur.ServReasonId ? dur.ServReasonId : null;
                    j["ServResultId"] = dur && dur.ServResultId ? dur.ServResultId : null;
                    j["IsRefillSpecific"] = dur && dur.IsRefillSpecific ? dur.IsRefillSpecific : null;
                    j["CreatedDtTm"] =  dur && dur.CreatedDtTm ? dur.CreatedDtTm : null;
                    j["ModifiedDtTm"] = dur && dur.ModifiedDtTm ? dur.ModifiedDtTm : null;
                    j["EffortLvlName"] = dur && dur.EffortLvlName ? dur.EffortLvlName : null;
                    j["PrescrefillId"] = dur && dur.PrescrefillId ? dur.PrescrefillId : null;
                    j["ServReasonName"] = dur && dur.ServReasonName ? dur.ServReasonName : null;
                    j["CoAgentIdType"] = dur && dur.CoAgentIdType ? dur.CoAgentIdType : null;
                    j["CoAgentNum"] = dur && dur.CoAgentNum ? dur.CoAgentNum : null;
                    j["ClinicalSignfName"] = dur && dur.ClinicalSignfName ? dur.ClinicalSignfName : null;
                    j["IsActive"] = dur && dur.IsActive ? dur.IsActive : null;
                    j["RxId"] = dur && dur.RxId ? dur.RxId : null;
                    j["TenantId"] = dur && dur.TenantId ? dur.TenantId : null;
                    j["ProfessionalSrvc"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "profServs", "Id", dur.ProfServId, "Remarks" );
                    j["ReasonOfSrvc"]= this._cmmnServ.getSystmDatVal(this.systemData,
                        "servReason", "Id", dur.ServReasonId, "Remarks");
                    j["ResultofSrvc"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "servResult", "Id", dur.ServResultId, "Remarks");
                    j["ClinicalSignfCode"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "clinicalSignf", "Id", dur.ClinicalSignfId, "Remarks" );
                    j["level"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "effortLvl", "Id", dur.EffortLvlId, "Remarks");
                    return j;
                })
            );
            this.actvHeaders = [
                "Reason of Service",
                "Professional Service",
                "Result of Service",
                "Level",
                "Id Q",
                "Agent Id",
                "Clinical Signif.Code",
                "PH-Comment"
            ];

            if (!this.accessingFrom && !this.isFromTrRx && !this.VaccineForm) {
                this.actvHeaders.splice(0, 0, "Actions");
            }
        } else {
            this.isDataExists = false;
        }
    }

    addPopUp() {
        this.editMode = false;
        if (this.rxType === "nr" || this.rxType === "nrf" || this.rxType === "rf" || this.rxType === "rnr" || this.frmQukRef) {
            this.newRxEditMode = false;
        }
        this.DurFG.reset();
        this.DurFG.controls["CoAgentIdType"].setValidators(Validators.nullValidator);
        this.DurFG.controls["CoAgentIdType"].markAsTouched();
        this.DurFG.markAsUntouched();
        this.modal = this._modalService.open(this.DURADD, {
            centered: true,
            size: "lg"
        });
    }

    editPopUpModal(content, val, nu) {
        this.editMode = true;
        for (const key in this.DurFG.getRawValue()) {
            if (key && this.defDurVal && this.defDurVal.length) {
                this.DurFG.controls[key].patchValue(this.defDurVal[nu][key]);
                if (key === "Id") {
                    this.DurFG.controls[key].patchValue(this.defDurVal[nu]["Id"]);
                    this.Id = this.defDurVal[nu]["Id"];
                }
            }
        }
        this.originalData = Object.assign({}, this.DurFG.getRawValue());
        this.modal = this._modalService.open(content, {size: "lg", centered: true, backdrop: false});
    }
patchingDur(val){
    this.DurFG.patchValue({
        ServReasonId: val && val["ServReasonId"] ? val["ServReasonId"] : null,
        ProfServId: val&&  val["ProfServId"] ? val["ProfServId"] : null,
        ServResultId: val && val["ServResultId"] ? val["ServResultId"] : null,
        EffortLvlId: val && val["EffortLvlId"] ? val["EffortLvlId"] : null,
        CoAgentIdType : val && val["CoAgentIdType"] ? val["CoAgentIdType"] : null,
        CoAgentNum: val && val["CoAgentNum"] ? val["CoAgentNum"] : null,
        ClinicalSignfId: val &&  val["ClinicalSignfId"] ? val["ClinicalSignfId"] : null,
        PhComment: val && val["PH-Comment"] ? val["PH-Comment"] : null,
        IsRefillSpecific : val && val["IsRefillSpecific"] ? val["IsRefillSpecific"] : null,
        Id : val && val["Id"] ? val["Id"] : null,
        CreatedDtTm : val && val["CreatedDtTm"] ? val["CreatedDtTm"] : null,
        ModifiedDtTm : val && val["ModifiedDtTm"] ? val["ModifiedDtTm"] : null,
        ClinicalSignfName : val && val["ClinicalSignfName"] ? val["ClinicalSignfName"] : null,
        EffortLvlName : val && val["EffortLvlName"] ? val["EffortLvlName"] : null,
        IsActive : val && val["IsActive"] ? val["IsActive"] : null,
        PrescrefillId : val && val["PrescrefillId"] ? val["PrescrefillId"] : null,
        ProfServName : val && val["ProfServName"] ? val["ProfServName"] : null,
        RxId : val && val["RxId"] ? val["RxId"] : null,
        ServReasonName : val && val["ServReasonName"] ? val["ServReasonName"] : null,
        ServResult : val && val["ServResult"] ? val["ServResult"] : null,
        TenantId : val && val["TenantId"] ? val["TenantId"] : null,


    });
}
    editNewRxPopUpModal(content, val, index) {
        this.newRxEditMode = true;
        this.editIndex = index;
     
       this.patchingDur(val);
        this.modal = this._modalService.open(content, {
            centered: true,
            backdrop: false,
            size: "lg"
        });
    }

    closeModal() {
        if (this.modal) {
            this.modal.close();
            this.modal = null;
        }
        if (!this.VaccineForm)
            this.IsPopUpClosed.emit(false);
        else
            this.emitFocus.emit(null);
    }

    addNewDUR() {
        const length = this.durDataNewRxFlow && this.durDataNewRxFlow.length > 0 ? this.durDataNewRxFlow.length : 0;
        this.DurFG.controls["IsActive"].setValue(true);
        if (this.DurFG.valid) {
            if (!this.checkDurFgHasValue()) {
                this._alrtServ.error(MsgConfig.ADD_ATLEAST_ONE_RECORD);
            } else if (this.DurFG.value.CoAgentNum && !this.DurFG.value.CoAgentIdType) {
                this.formGroupInvalid = true;
                this._validUtils.validateAllFormFields(this.DurFG);
            } else {
            if (length) {
                this.durDataNewRxFlow.push(this.DurFG.value);
                this._rxStore.storeDurNewRxFlow(this.durDataNewRxFlow);
            } else {
                this.durDataNewRxFlow = [this.DurFG.value];
                this._rxStore.storeDurNewRxFlow(this.durDataNewRxFlow);
            }
            this.totalCount = length + 1;
                if (this.modal) {
                    this.modal.close();
                }
            this._alrtServ.success(MsgConfig.SAVE_SUCCESS);
            this.isDataExists = true;
            if (!this.VaccineForm)
               this.IsPopUpClosed.emit(this.totalCount === 1 && !this.noRecords ? "addDUR" : null);
            this.getNEWDUR(true);
            if (this.VaccineForm) this.emitFocus.emit(null);
            }
        } else {
            this.formGroupInvalid = true;
        }
    }

    editNewRxDUR() {
        const length = this.durDataNewRxFlow && this.durDataNewRxFlow.length > 0 ? this.durDataNewRxFlow.length : 0;
        if (this.DurFG.valid) {
            if (!this.checkDurFgHasValue()) {
                this._alrtServ.error(MsgConfig.ADD_ATLEAST_ONE_RECORD);
            } else if (this.DurFG.value.CoAgentNum && !this.DurFG.value.CoAgentIdType) {
                this.formGroupInvalid = true;
                this._validUtils.validateAllFormFields(this.DurFG);
            } else {
                this.totalCount = length;
                this.durDataNewRxFlow[this.editIndex] = this.DurFG.getRawValue();
                if (this.modal) {
                    this.modal.close();
                }
                this._alrtServ.success(MsgConfig.UPDATE_SUCCESS);
                this.getNEWDUR();
                if (this.VaccineForm) this.emitFocus.emit(null);
            }
        } else {
            this.formGroupInvalid = true;
        }
    }
    deleteNewRxDur() {
        // if(this.rxType === 'nr'){
        //     this.deleteDurforMultiple();
        // } else{
            if(this.durDataNewRxFlow && this.durDataNewRxFlow[this.deleteIndex] && this.durDataNewRxFlow[this.deleteIndex].Id){
                this._rxService.deleteDUR(this.durDataNewRxFlow[this.deleteIndex].Id, this.refId, this.rxno)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res)=>{
                    if(res){
                        this.deleteDurforMultiple();
                    }
                });
            } else{
                this.deleteDurforMultiple();
            }
            if (this.VaccineForm) this.emitFocus.emit(null);
        // }
    }
    deleteDurforMultiple(){
        this.durDataNewRxFlow.splice(this.deleteIndex, 1);
            this.totalCount = this.durDataNewRxFlow.length;
            this.noRecords = true;
            this._alrtServ.success(MsgConfig.DELETE_SUCCESS);
            this.getNEWDUR();
    }
    enterKeyDownEv(event, type) {
        if (event) {
            event.preventDefault();
            setTimeout(() => {
                if (type === "addDUR") {
                    this.addDUR();
                } else if (type === "addNewDUR") {
                    this.addNewDUR();
                } else if (type === "editDUR") {
                    this.editDUR();
                } else if (type === "editNewRxDUR") {
                    this.editNewRxDUR();
                } else if (type === "Info") {
                    if (this.modelRef) {
                        this.modelRef.close();
                        this.modelRef = null;
                    }
                }
            }, 100);
        }
    }

    checkDurFgHasValue() {
        const cntrlData = ["ServReasonId", "ProfServId", "ServResultId", "EffortLvlId", "ClinicalSignfId", "CoAgentNum", "CoAgentIdType"];
        let hasValue = false;
        cntrlData.map(cntrl => {
            if (this.DurFG.value[cntrl]) {
                hasValue = true;
            }
        });
        return hasValue;
    }
    async addDUR() {
        this.DurFG.controls["IsActive"].setValue(true);
        if (this.DurFG.valid) {
            if (!this.checkDurFgHasValue()) {
                this._alrtServ.error(MsgConfig.ADD_ATLEAST_ONE_RECORD);
            } else if (this.DurFG.value.CoAgentNum && !this.DurFG.value.CoAgentIdType) {
                this.formGroupInvalid = true;
                this._validUtils.validateAllFormFields(this.DurFG);
            } else {
                if (this.modal) {
                    this.modal.close();
                }
                const data: any[] = [];
               
                const durData = this.DurFG.getRawValue();
                durData.RxId = this.rxno;
                data.push(durData);
                this.PrescrirefillId = this.DurFG.controls.IsRefillSpecific && this.DurFG.controls.IsRefillSpecific.value ? this.refId : null;
                const  payload = {
                    prescriptionNo : this.rxno,
                    prescrefillId : this.refId,
                    durInfoList : data
                }
                this.formGroupInvalid = false;
                const resp = await this._rxService.addDURValues(payload).toPromise();
                if (resp) {
                    const durAudtMdl = new RxAuditLog().durAuditModel;
                    this._nwAudtUtl.saveAuditChange(
                        null,
                        this.DurFG.getRawValue(),
                        "DUR",
                        "Rx",
                        this.refId,
                        durAudtMdl
                    );
                    this.refreshDURGrid();
                    this._alrtServ.success(MsgConfig.SAVE_SUCCESS);
                } else {
                    this._alrtServ.error("DUR Not Saved.");
                }
                if (this.VaccineForm) this.emitFocus.emit(null);
            }
        } else {
            this.formGroupInvalid = true;
        }
    }

    async editDUR() {
        if (this.DurFG.valid) {
            if (!this.checkDurFgHasValue()) {
                this._alrtServ.error(MsgConfig.ADD_ATLEAST_ONE_RECORD);
            } else if (this.DurFG.value.CoAgentNum && !this.DurFG.value.CoAgentIdType) {
                this.formGroupInvalid = true;
                this._validUtils.validateAllFormFields(this.DurFG);
            } else {
                if (this.modal) {
                    this.modal.close();
                }
                this.formGroupInvalid = false;
                const data: any[] = [];
                this.DurFG.controls.PrescrefillId.patchValue(this.DurFG.controls.IsRefillSpecific && this.DurFG.controls.IsRefillSpecific.value ? this.refId : null)
                data.push(this.DurFG.getRawValue());
                const resp = await this._rxService.editDURValues(data, this.Id, this.refId).toPromise();
                if (resp) {
                    const durAudtMdl = new RxAuditLog().durAuditModel;
                    this._nwAudtUtl.saveAuditChange(
                        this.originalData,
                        this.DurFG.getRawValue(),
                        "DUR",
                        "Rx",
                        this.refId,
                        durAudtMdl
                    );
                    this.refreshDURGrid();
                    this._alrtServ.success(MsgConfig.UPDATE_SUCCESS);
                } else {
                    this._alrtServ.error(MsgConfig.UPDATE_FAIL);
                }
                if (this.VaccineForm) this.emitFocus.emit(null);
            }
        } else {
            this.formGroupInvalid = true;
        }
    }
    deleteDURAudit(){
        const durAudtMdl = new RxAuditLog().durAuditModel;
        this._nwAudtUtl.saveAuditChange(
            this.originalData,
            null,
            "DUR",
            "Rx",
            this.refId,
            durAudtMdl
        );
    }
    async deleteDUR() {
        const resp = await this._rxService.deleteDUR(this.deleteId, this.refId, this.rxno).toPromise();
        if (resp) {
            this.deleteDURAudit();
            this.getDURData();
            this._alrtServ.success(MsgConfig.DELETE_SUCCESS);
        } else {
            this._alrtServ.error(MsgConfig.DELETE_FAIL);
        }
        if (this.VaccineForm) this.emitFocus.emit(null);
    }
 patchDurValues(nu) {
    for (const key in this.DurFG.getRawValue()) {
        if (key && this.defDurVal && this.defDurVal.length) {
            this.DurFG.controls[key].patchValue(this.defDurVal[nu][key]);
            if (key === "Id") {
                this.DurFG.controls[key].patchValue(this.defDurVal[nu]["Id"]);
            }
        }
    }
    this.originalData = Object.assign({}, this.DurFG.getRawValue());
 }
   async openDeleteModal(content,val,index) {
        this.deleteId = val;
       await this.patchDurValues(index);
        this.modal = this._modalService.open(content, {
            centered: true,
            backdrop: false,
            windowClass: "large--content"
        });
        this.coAgentNum = null;
    }

    openNewRxDeleteModal(content, val) {
        this.deleteIndex = val;
        this.modal = this._modalService.open(content, {
            windowClass: "large--content",
            centered: true,
            backdrop: false
        });
    }

    previousPage() {
        if(this.IsFromRxHistory){
            this.CloseEditMode.emit("cancel")
        }else{
            this.IsPopUpClosed.emit("Patient1");
        }
    }

    closeDUR() {
        this.previousPage();
    }

    saveNewRxDur() {
        this._rxStore.storeDURInfo(this.newRxDur);
        this._alrtServ.success(MsgConfig.SAVE_SUCCESS);
        this.previousPage();
    }

    focusOutFromAdd(event) {
        if (!this.totalCount) {
            event.preventDefault();
            this.focusOnSave(event);
        }
    }

    focusOnSave(event) {
        if (this.newRxDur.length > 0) {
        if (document.getElementById("saveButton")) {
            event.preventDefault();
            document.getElementById("saveButton").focus();
        }
    } else {
        if (document.getElementById("CancelButton")) {
            event.preventDefault();
            document.getElementById("CancelButton").focus();
        }
    }
    }

    validateCoAgent() {
        this.coAgentNum = this.DurFG.controls["CoAgentNum"];
        if (this.coAgentNum.value) {
            this.DurFG.controls["CoAgentIdType"].setValidators(Validators.required);
            this.DurFG.controls["CoAgentIdType"].markAsTouched();
        } else  {
            this.DurFG.controls["CoAgentIdType"].setValidators(Validators.nullValidator);
            this.DurFG.controls["CoAgentIdType"].markAsTouched();
        }
    }

    get ServReasonId(): FormControl {
        return this.DurFG.controls["ServReasonId"].get(
            "ServReasonId"
        ) as FormControl;
    }

    get ServResultId(): FormControl {
        return this.DurFG.controls["ServResultId"].get(
            "ServResultId"
        ) as FormControl;
    }

    get ProfServId(): FormControl {
        return this.DurFG.controls["ProfServId"].get(
            "ProfServId"
        ) as FormControl;
    }

    get EffortLvlId(): FormControl {
        return this.DurFG.controls["EffortLvlId"].get(
            "EffortLvlId"
        ) as FormControl;
    }

    get CoAgentIdType(): FormControl {
        return this.DurFG.controls["CoAgentIdType"].get(
            "CoAgentIdType"
        ) as FormControl;
    }

    get ClinicalSignfId(): FormControl {
        return this.DurFG.controls["ClinicalSignfId"].get(
            "ClinicalSignfId"
        ) as FormControl;
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
         this.unsubscribe$.complete();
       }

    async refreshDURGrid() {
        if (this.VaccineForm) {
            const resp: any = await this._rxService.getDurValues(this.rxno,this.refId).toPromise();
            if (resp && resp.length) {
                this.durDataNewRxFlow = resp;
                this.isDataExists = true;
            }
            this.defDurVal = this.durDataNewRxFlow;
            this.getNEWDUR();
        } else {
            this.getDURData();
        }
    }

    focusOutSave(event) {
        this.emitFocus.emit(event);
    }
}
