

import * as wjcGrid from "@grapecity/wijmo.grid"
import { ColumnPickerRfComponent } from "../components/column-picker-rf/column-picker-rf.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";


// import * as wjcSelf from './flex-grid-column-picker';
// window['FlexGridColumnPicker'] = wjcSelf;


"use strict";

/**
 * Provides column picker for FlexGrid.
 */
export class FlexGridColumnPicker {
    _flex: wjcGrid.FlexGrid;
    columnPicker: string;

    private _frame: HTMLElement;
    private _img: HTMLElement;
    private _modalSvc: NgbModal;
    modelRef: any;
    fromDrugGrid;
    fromPaGrid;

    /**
     * Initializes a new instance of the @see:FlexGridColumnPicker class.
     *
     * @param flex @see:FlexGrid that will receive the column picker.
     */
    constructor(flex: wjcGrid.FlexGrid, columnPicker: string, modalSvc: NgbModal) {

        this._flex = flex;
        this._modalSvc = modalSvc;
        this.columnPicker = columnPicker;
        this.fromDrugGrid = (sessionStorage.getItem('fromDrugGrid') && sessionStorage.getItem('fromDrugGrid') === "true") ? true : false;
        this.fromPaGrid = (sessionStorage.getItem('fromPaGrid') && sessionStorage.getItem('fromPaGrid') === "true") ? true : false;
        // add picker icon to column header element
        this._createPickerImg();

        // create frame element for column list
       // this._createPopIframe();

        // close frame when user clicks anywhere else
      //  this._initMouseDownOnBody();
    }

    // create picker icon
    private _createPickerImg() {

        var host = this._flex.hostElement,
            colHdr = host.querySelector("[wj-part=\"ch\"]"),
            img = document.createElement("i");

        this._img = img;
        img.className = "far fa-cog";
        img.style.position = "absolute";
        img.style.top = "0px";
        img.style.right = "0px";
        img.style.fontSize = "20px";
        img.style.background = "#EAEAEA";
        img.style.borderLeft = "1px solid #ccc";
        img.style.padding = "6px 12px";
        colHdr.appendChild(img);

        img.addEventListener("mousedown", (e: Event) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            if (!this.modelRef) {
                this.modelRef = this._modalSvc.open(ColumnPickerRfComponent, {
                    centered: true,
                    backdrop: false,
                    keyboard: false,
                    windowClass: this.fromDrugGrid ? 'max-modal-39' : this.fromPaGrid ? 'max-modal-51' : ''
                });
                this.modelRef.componentInstance.flex = this._flex;
                this.modelRef.componentInstance.WijmoKey = this.columnPicker;
                this.modelRef.componentInstance.FromDrugGrid = this.fromDrugGrid;
                this.modelRef.componentInstance.FromPaGrid = this.fromPaGrid;
                this.modelRef.componentInstance.EmitColumnPickerClose
                    .subscribe(resp => {
                        if (this.modelRef) {
                            this.modelRef.close();
                            this.modelRef = null;
                        }
                });
            }
        });

        // img.addEventListener("mousedown", (e: Event) => {
        //     var fs = this._frame.style;
        //     // we're done with this event
        //     e.preventDefault();
        //     e.stopImmediatePropagation();

        //     // if frame is visible, hide it
        //     if (fs.display != "none") {
        //         fs.display = "none";
        //         return;
        //     }

        //     // populate frame with checkboxes for the columns
        //     this._populateFrame();
        //     var root = <HTMLElement>host.querySelector("[wj-part=\"root\"]");
        //     // show frame
        //    // fs.top = 0 + 'px';
        //    fs.top = this._flex.rows.defaultSize + "px";
        //     fs.right = (root.offsetWidth - root.clientWidth) + "px";
        //  fs.maxHeight = (root.clientHeight - this._flex.rows.defaultSize) * 0.65 + "px";
        //  //    fs.height = 'auto !important';
        //     fs.display = "";
        //     this._frame.focus();
        // });
    }

    // create pop iframe
    private _createPopIframe() {

        var host = this._flex.hostElement,
            frame = document.createElement("div");

        this._frame = frame;
        frame.className = "wj-col-picker-dropdown";
        frame.style.position = "absolute";
        frame.style.outline = "none";
        frame.style.display = "none";
        frame.tabIndex = 0;
        var root = <HTMLElement>host.querySelector("[wj-part=\"root\"]");
        root.parentElement.appendChild(frame);

        // handle checkbox changes to show/hide columns
        frame.addEventListener("change", (e: Event) => {
            var sender = e.target;
            if (sender instanceof HTMLInputElement &&
                sender.type == "checkbox" &&
                sender.value != null) {
                var col = this._flex.columns[parseInt(sender.value)];
                col.visible = sender.checked;
            }
        });
    }

    // populate the frame with checkboxes for each column
    private _populateFrame() {
        this._frame.innerHTML = "";

        // create two divs to hold the checkboxes
        for (let j = 0; j < 2; j++) {
            const d = document.createElement("div");
            d.style.cssFloat = "left";
            this._frame.appendChild(d);
        }

        // add one checkbox for each grid column
        for (let i = 0; i < this._flex.columns.length; i++) {

            // create checkbox
            const col = this._flex.columns[i];
            if (!(col.header === "" || col.header === null)) {
                const  hdr = col.header ? col.header : col.binding,
                checked = col.visible ? "checked" : "",
                chk = document.createElement("label");

            chk.className = "wj-col-picker-item";
            chk.innerHTML = "<input type=\"checkbox\" value= \"" + i + "\" " + checked + ">" + hdr;
            // append to first or second div
            const fc = i <= this._flex.columns.length / 2 ? 0 : 1;
            this._frame.children[fc].appendChild(chk);
            }
        }
    }

    private _initMouseDownOnBody() {
        document.body.addEventListener("mousedown", (e: any) => {
            var mde = document.elementFromPoint(e.clientX, e.clientY);
            if (mde != this._img) {
                if (this._contains(this._frame, mde)) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    this._frame.focus();
                } else {
                    this._frame.style.display = "none";
                }
            }
        }, true);
    }

    private _contains(parent: HTMLElement, child: Element) {
        for (var e = child; e; e = e.parentElement) {
            if (e == parent) return true;
        }
        return false;
    }
}

