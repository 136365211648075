import { CommonService } from "./../../../services/common.service";
import { SystemData } from "./../../../models/system-data.model";
import { FormControl } from "@angular/forms";
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    ViewChild,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ErxFilter } from "../../../models";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { MsgConfig } from "src/app";
import { AlertService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { CommonStore } from "src/app/store";
import { ErxNewRequestComponent } from "../erx-new-request/erx-new-request.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { StorageService } from "src/app/services/storage.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-erx-filter",
    templateUrl: "./erx-filter.component.html"
})
export class ErxFilterComponent implements OnInit, OnChanges, AfterViewInit {
    @Output()
    processMultiRecords = new EventEmitter<any>();
    @Output()
    processBenfitCheck = new EventEmitter<any>();

    @Output()
    FormModel = new EventEmitter<any>();
    @Output()
    CloseErxMessageLinkModal = new EventEmitter<any>();
    @Input() isPopErxModel: boolean;
    @Input() IsFromRxHistoryDisplay: any;
    @Input() RxInfo: any;
    fromIntakeQueue: boolean;
    reviewXDays : any;
    IsSelectedRecordsList: boolean;
    enableBtn = false;
    @Input()
    set FromRph(bl: boolean) {
        this.fromRph =  bl;
    }
    @Input()
    set FromIntakeQueue(bl: boolean) {
        this.fromIntakeQueue =  bl;
    }
    @Input() RxFGInfo: any;
    @Input() PatientId: any;
    @Input()
    set ReviewXDays(data : any){
        this.reviewXDays = data;
    }

    @Input() set IsSelectedRecords(val) {
        this.IsSelectedRecordsList = val;
    }
    erxFormGroup: FormGroup;
    TotalCount: number;
    awaitingCount: number;
    patDiagnis: any;
    errorCount: number;
    readyCount: number;
    processCount: number;
    fromRph: boolean = false;
    filterObj: ErxFilter = new ErxFilter();
    warningPop: any;
    filterStatus: any;
    systemData: SystemData;
    hideFilterForm = false;
    appliedFilters: ErxFilter = new ErxFilter();
    priorDate: any;
    selectedValue: any;
    today: any;
    fromdate: string;
    todate: string;
    value: any;
    catIds: any;

    status: any;
    minDate: any;
    routeFrom: string;
    startdate: string;
    placholderText: string;
    filterFG: FormGroup;
    erxOpCat: any;

    erxOpCatOptions = [];
    iseRxFilters: any = [];

    @ViewChild("searchBox", { static: true })
    SearchBox: ElementRef;
    @ViewChild("newrxRequest", { static: true })
    newrxRequest: any;
    catgID: any;
    hasFaxNumber: boolean;
    unsubscribe$: Subject<void> = new Subject();
    IseRxTRxREQ: boolean = false;
    IseRxTRxREQEnable:boolean = false;
    IseRxTransferRequest: boolean;
    constructor(
        private _fb: FormBuilder,
        private _commonServ: CommonService,
        private translate: TranslateService,
        private _actvRoute: ActivatedRoute,
        private _alertSer: AlertService,
        private _modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _commonStore: CommonStore,
        private _router: Router,
        private _formUtils: ValidateFormFieldsUtils,
        private _sessionStorage: StorageService
    ) {
        this._actvRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            if (params && params.fromm) {
                const dates = this._commonStore.dashFilters$["source"]["value"];
                if (dates) {
                    this.filterFG.patchValue({FilledFrom: dates.StartDate ? dates.StartDate : this.filterFG.value["FilledFrom"],
                    FilledTo: dates.EndDate ? dates.EndDate : this.filterFG.value["FilledTo"],
                    BillStatus: "A"});
                }
            }
        })
    }

    ngOnInit() {
        this.IseRxTransferRequest = environment.IseRxTransferRequest;
        this.IseRxTRxREQEnable =  this._sessionStorage.getSessionStorage("totalSelectedeRxCount") ? JSON.parse(this._sessionStorage.getSessionStorage("totalSelectedeRxCount")) : null;
        this.filterStatus = this.getFilterOptions();
        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this._commonServ.systemData$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            this.systemData = resp;
            this.erxOpCat = resp["ERxOpCat"].filter(val => (val.Name !== "RxChangeResponse") && (val.Name !== "RXCHG"));
            this.erxOpCatOptions = resp["ERxOpCat"].filter(val => (val.Name !== "CANCELRXRESPONSE"));
            if (this.isPopErxModel) {
                this.erxOpCatOptions = this.erxOpCatOptions.filter(function(ele) {
                    return !(ele.Name === "CancelRx" || ele.Name === "REFREQ" || ele.Name === "RXCHG" || ele.Name === "All");
                });
            } else if (this.fromRph) {
                this.erxOpCatOptions = this.erxOpCatOptions.filter(function(ele) {
                    return !(ele.Name === "CancelRx" || ele.Name === "REFREQ" || ele.Name === "All"
                    || ele.Name === "RxChangeResponse");
                });
            }
        });
        this.createFG();
        this._actvRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            if (params && params.from) {
                this.catgID = params.cat ? parseInt(params.cat, 0) : 0;
                const dates = this._commonStore.dashFilters$["source"]["value"];
                if (dates) {
                   this.startdate = dates.StartDate;
                   this.today = dates.EndDate;
                }
            } else {
                const displayDays = +this._commonServ.getSetttingValue("ERxSettings", "DEFAULT_ERX_SEARCH_DAYS");
                this.startdate = this.startdate = moment(this.today).subtract(displayDays, "days").format("MM/DD/YYYY");
            }
        });
        if (sessionStorage.getItem("eRxFilterData")) {
            let filterOb = JSON.parse(sessionStorage.getItem("eRxFilterData"));
            this.filterObj = filterOb.filterOb;
            this.erxFormGroup.patchValue(filterOb.erxFormGroup);
            sessionStorage.removeItem("eRxFilterData");
        } else {
            this.setDefaultValues();
        }
        this.getErxData();
      //  document.getElementById("iSearchBox").focus();
      let faxNumberSetting = this._commonServ.getSetttingValue("FaxAndReportsSettings", "Fax_Number");
      if (faxNumberSetting && faxNumberSetting.length) {
          this.hasFaxNumber = true;
      } else {
          this.hasFaxNumber = false;
      }
    }
    navigateToBenfitCheck() {
        this.processBenfitCheck.emit("benfitCheck");
    }
    ngAfterViewInit() {
        if(this.SearchBox && this.SearchBox.nativeElement)
        {
            this.SearchBox.nativeElement.focus();
        }
    }
    createFG() {
        this.erxFormGroup = this._fb.group({
            SearchBy: ["Patient"],
            SearchText: [],
            FromDate: [this.priorDate],
            ToDate: [this.today],
            StatusIds: 1,
            CategoryIds: [[0]]
        });
        this.selectedValue = "Patient";
        this.placholderText = "Search patient";
    }
    goToNewRxReq() {
        this.warningPop = this._modalService.open(ErxNewRequestComponent, { keyboard: false, backdrop: false,
            size: "lg",
            windowClass: "max-modal-74 modal-newrx-req modal-center"}); // modal-md
            this.warningPop.componentInstance.RxFGInfo = this.RxFGInfo;
            this.warningPop.componentInstance.CloseErxRequestModal.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                this.warningPop.close();
          });
    }
    setDefaultValues() {
        if(this.fromRph && this.reviewXDays){
            this.startdate = moment().subtract(this.reviewXDays, "days").format("YYYY-MM-DD");
            this.erxFormGroup.controls["FromDate"].patchValue(this.startdate);
        } else{
        this.erxFormGroup.controls["FromDate"].patchValue(this.startdate);
        this.erxFormGroup.controls["SearchBy"].setValue("Patient");
        this.erxFormGroup.controls["SearchText"].patchValue(null);
        this.erxFormGroup.controls["ToDate"].patchValue(this.today);
        this.erxFormGroup.controls["StatusIds"].patchValue(1);
        const defaultMessageType = +this._commonServ.getSetttingValue("ERxSettings", "DEFAULT_MESSAGE_TYPE_FILTERS");
        if (this.catgID || this.catgID === 0) {
            this.erxFormGroup.controls["CategoryIds"].patchValue([this.catgID]);
        } else {
            this.erxFormGroup.controls["CategoryIds"].patchValue([defaultMessageType]);
        }
        if (this.isPopErxModel) {
            this.erxOpCatOptions.map((ele: any) => {
                this.iseRxFilters.push(parseInt(ele.Id, 0));
            });
            this.erxFormGroup.controls["CategoryIds"].patchValue(this.iseRxFilters);
        } else if (this.fromRph) {
            this.erxOpCatOptions.map((ele: any) => {
                this.iseRxFilters.push(parseInt(ele.Id, 0));
            });
            this.erxFormGroup.controls["CategoryIds"].patchValue(this.iseRxFilters);
        }
        }
    }

    @Input()
    set AwaitingCount(val: number) {
        this.awaitingCount = val ? val : 0;
    }
    @Input()
    set ErrorCount(val: number) {
        this.errorCount = val ? val : 0;
    }
    @Input()
    set ReadyCount(val: number) {
        this.readyCount = val ? val : 0;
    }
    @Input()
    set ProcessCount(val: number) {
        this.processCount = val ? val : 0;
    }
    @Input()
    set SystemData(sd: any) {
        this.systemData = sd;
    }

    @Input()
    set StoreFilters(sd: boolean) {
        if (sd) {
            this.storeFiltersToStorage();
        }
    }

    ngOnChanges(changes: any): void {
        if (
            (changes && changes["AwaitingCount"]) ||
            changes["ErrorCount"] ||
            changes["ReadyCount"] ||
            changes["ProcessCount"]
        ) {
            this.TotalCount =
                this.errorCount +
                this.awaitingCount +
                this.readyCount +
                this.processCount;
            this.filterStatus = this.getFilterOptions();
            if (this.isPopErxModel) {
                this.filterStatus = this.filterStatus.filter(val => (val.status === "Ready To Process"));
            }
        }
    }

    get SearchText(): FormControl {
        return this.erxFormGroup.get("SearchText") as FormControl;
    }

    get ToDate() {
        return this.erxFormGroup.get("ToDate");
    }

    get FromDate() {
        return this.erxFormGroup.get("FromDate");
    }

    storeFiltersToStorage() {
        sessionStorage.setItem("eRxFilterData", JSON.stringify({ filterobj: this.filterObj, erxFormGroup: this.erxFormGroup.value }));
    }

    getErxData() {
        if (moment(this.erxFormGroup.value.FromDate,'MM/DD/YYYY') > moment(this.erxFormGroup.value.ToDate,'MM/DD/YYYY')) {
            this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
            return;
        }
        this.show();
        this.filterObj = { ...this.filterObj, ...this.erxFormGroup.value };
        this.FormModel.emit(this.filterObj);
        this.setAppliedFilters();
    }
    setAppliedFilters() {
        const values: ErxFilter = this.erxFormGroup.value;
        if (this.systemData && this.systemData.ERxOpCat && this.systemData.ERxOpStatus && this.erxOpCatOptions) {
            if (values) {
                if (this.erxOpCatOptions && this.erxOpCatOptions.length) {
                    this.catIds = values?.CategoryIds?.length ? values.CategoryIds.map( id => (this.erxOpCatOptions.find(elm => elm.Id === id) || {}).Name) : [];
                }
                this.appliedFilters.CategoryIds = this.catIds;
                this.status = this.systemData.ERxOpStatus[
                    values.StatusIds
                ] ?  this.systemData.ERxOpStatus[
                    values.StatusIds].Name : "";
                this.appliedFilters.StatusIds = this.status;
                this.appliedFilters.SearchBy = values.SearchBy;
                this.appliedFilters.SearchText = values.SearchText;
                this.appliedFilters.FromDate = values.FromDate;
                this.appliedFilters.ToDate = values.ToDate;
                this.selectedValue = this.appliedFilters.SearchBy;
            }
        }
    }

    getFilterOptions() {
        return [
            {
                status: `All`,
                value: 0
            },
            {
                status: `Ready To Process`,
                value: 1
            },
            // {
            //     status: `Already Processed`,
            //     value: 2
            // },
            {
                status: `Awaiting Response`,
                value: 3
            },
            {
                status: `Error in Transmission`,
                value: 4
            },
            {
                status: `Removed`,
                value: 5
            }
        ];
        // return [
        //     {
        //         status: `All (${this.TotalCount})`,
        //         value: 0
        //     },
        //     {
        //         status: `Ready To Process (${this.readyCount})`,
        //         value: 1
        //     },
        //     // {
        //     //     status: `Already Processed (${this.processCount})`,
        //     //     value: 2
        //     // },
        //     {
        //         status: `Awaiting Response (${this.awaitingCount})`,
        //         value: 3
        //     },
        //     {
        //         status: `Error In Transmission (${this.errorCount})`,
        //         value: 4
        //     }
        // ];
    }

    addMessageType(val) {
        let messageTypes = this.erxFormGroup.value.CategoryIds
            ? this.erxFormGroup.value.CategoryIds
            : [];
        if (val.Id === 0) {
            messageTypes = [0];
        } else {
            if (messageTypes.includes(0)) {
                messageTypes.shift();
            }
            messageTypes.push(val.Id);
        }
        this.erxFormGroup.patchValue({ CategoryIds: messageTypes });
    }

    getValueFromDropdown(selectedValue: any) {
        if (selectedValue === "1") {
            this.placholderText = "Search patient";
            this.erxFormGroup.controls["SearchBy"].patchValue("Patient");
        } else if (selectedValue === "2") {
            this.placholderText = "Search prescriber";
            this.erxFormGroup.controls["SearchBy"].patchValue("Prescriber");
        } else {
            this.placholderText = "Search drug";
            this.erxFormGroup.controls["SearchBy"].patchValue("Drug");
        }
    }

    show() {
        this.hideFilterForm = !this.hideFilterForm;
    }

    reset() {
        this.erxFormGroup.reset();
        this.erxFormGroup.markAsUntouched();
       this.setDefaultValues();
       this.searchErxData();
    }

    searchByKeyWord(val: any) {
        this.erxFormGroup.controls["SearchText"].patchValue(val);
    }

    getAlreadyProcessed() {
        this.erxFormGroup.controls["StatusIds"].setValue(2);
        // this.getErxData();
        this.searchErxData();
    }

    getReadyToProcess() {
        this.erxFormGroup.controls["StatusIds"].setValue(1);
        // this.getErxData();
        this.searchErxData();
    }

   async searchErxData(Frm?: any) {
        await this._formUtils.blurAndDelay();
        const resp = this._formUtils.checkDateErrors(this.erxFormGroup, "FromDate", "ToDate", 0);
       if (resp === "DATEISSUE") {
            this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
        } else {
            this.filterObj.PageNumber = 1;
            const ele = document.getElementById(Frm) as HTMLInputElement;
            if (Frm && ele) {
               const e = ele.getElementsByTagName("input")[0];
               e.focus();
            }
            this.getErxData();
        }
    }

    routeToErxReport() {
        localStorage.setItem("ReportNavigation" ,
        this._commonServ.encryptData(JSON.stringify({IsFromRxHistoryDisplay :  this.IsFromRxHistoryDisplay ,
            patientId: this.PatientId})));
            localStorage.setItem("patientidForpatientHistory" ,
            (this.IsFromRxHistoryDisplay === "patHistory") ? this._commonServ.encryptData(JSON.stringify(this.PatientId)) : "");
           this._router.navigate(["/eprx/Reports/erx"]);
    }
    processRecords(type) {
        this.processMultiRecords.emit(type);
    }
    closePopupModal() {
        if (this.isPopErxModel) {
            this.CloseErxMessageLinkModal.emit("cancelWithoutSelect");
        } else {
            this.CloseErxMessageLinkModal.emit("cancel");
        }
    }
    closeTRxREQPopUpModal() {
        this.IseRxTRxREQ = true;
        this._cdr.detectChanges();
        this.IseRxTRxREQ = false;
    }
    enableOrDisableSearch(event) {
        this.enableBtn = event;
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
