import { Drug } from "./patient-Info.model";
import { Organization } from "./elastc-rx.model";

export class DrugData {
    Drug: Drug;
    DrugDesc: DrugDesc;
    DrugForm: DropDown[] = null;
    DrugMiscPrice: DrugMiscPrice;
    DrugPO: DrugPO;
    DrugTrans: DrugTrans[] = null;
    ImageName: string = null;
    Organization: Organization[] = null;
    QtyInHand: number = null;
    QuantityInHandEqui: number = null;
    LatestVendorName: string = null;
    Sig: Sig[] = null;
    UnitDoseIndication: DropDown[] = null;
    Vendor: DrugVendor[] = null;
    InventoryHistory: InvenHist = null;
    PriceHistory: PriceHis = null;
    PreferredDrugsCount: number = null;
    IsDocumentExists: boolean = null;
    diagnosisInfo: DrugDiagnosis;
}
export class InvenHist {
    LastDispensed: any = null;
    LastReceived: any = null;
}
export class PriceHis {
    NewPackAWPPrice: number = null;
    OldPackAWPPrice: number = null;
    NewUnitAWPPrice: number = null;
    OldUnitAWPPrice: number = null;
    NewUnitCost: number = null;
    OldUnitCost: number = null;
    NewCostPack: number = null;
    NewUnitDIR: number = null;
    OldCostPack: number = null;
    OldUnitDIR: number = null;
}
export class DrugDefaultVal {
    Organization: Organization[] = null;
    Sig: Sig[] = null;
    DrugForm: DropDown[] = null;
}

export class DrugDesc {
    CoatingName: string = null;
    Color_Back: string = null;
    Color_Frnt: string = null;
    Descrip: string = null;
    DrugItemId: number = null;
    FlavorName: string = null;
    ID_BACK: number = null;
    ID_FRONT: number = null;
    NDC: number = null;
    ScoringName: string = null;
    Shape: string = null;
    Version: number = null;
}
export class DrugVendor {
    Code: string = null;
    DefaultBucketId: number = null;
    Id: number = null;
    VendorName: string = null;
}
export class MiscInfo {
    DrugMisc: DrugMisc;
    AdmnstrnRoute: DropDown[] = null;
    AdmnstrnRoute2: DropDown[] = null;
    AutoMc: DropDown[] = [];
    CompoundTyp: DropDown[];
    DispUnitForm: DropDown[];
    DoseFrmCode: DropDown[];
    DoseFrmDesc: DropDown[];
    DrugLot: DrugLot[];
    DrugStrQual: DropDown[];
    // Drug_PkgMc: DrugPkgMc[];
    InvLoc: DropDown[];
    PkgMc: DropDown[];
    PotencyUnitCode: DropDown[];
    QtyQual: DropDown[];
}
export class DrugPkgMc {
    DrugMiscId: number = null;
    Id: number = null;
    IsDeleted: boolean = null;
    PkgMcId: number = null;
    TenantId: number = null;
}
export class DrugLot {
    Action: string = null;
    DrugId: number = null;
    DrugTransId: number = null;
    Id: number = null;
    LotNum: number = null;
    ExpDt: Date = null;
    TenantId: number = null;
    IsActive: boolean = null;
    BucketId: number = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    IsDeleted:boolean = null;
}
export class DropDown {
    Action: string = null;
    Code: number = null;
    Id: number = null;
    IsActive: boolean = null;
    Name: string = null;
    Remarks: string = null;
}
export class Sig {
    CreatedDtTm: Date = null;
    Id: number = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    Name: string = null;
    Remarks: string = null;
    DailyDose: string = null;
    DoseSchId: number = null;
    Priority: any;
    SigCode: string = null;
    TenantId: number = null;
}
export class DrugPriceHist {
    CreatedDtTm: Date = null;
    DrugId: number = null;
    Id: number = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    NewPrice: number = null;
    OldPrice: number = null;
    PriceCatId: number = null;
    TenantId: number = null;
    UpdateDtTm: Date = null;
    UpdatedById: number = null;
}

export class DrugPO {
    DrugId: number = null;
    Id: number = null;
    IsAutoPO: boolean = null;
    MinOrderQty: number = null;
    OrderQty: number = null;
    PrefVendId: number = null;
    ReorderQty: number = null;
    TargetQty: number = null;
    TenantId: number = null;
    BucketId: number = null;
    ContractId: number = null;
}
export class DrugTrans {
    AqCostPerPkg: number = null;
    AqCostPerUnit: number = null;
    BucketId: number = null;
    CreatedDtTm: Date = null;
    DrugId: number = null;
    ExtCost: number = null;
    Id: number = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    PONum: number = null;
    Packs: number = null;
    Qty: number = null;
    RecdById: number = null;
    ReceiptDtTm: number = null;
    Ref: string = null;
    Remarks: string = null;
    TenantId: number = null;
    TransCatId: number = null;
    VendorId: number = null;
    ExcludeRecvCount: boolean = null;
}

export class DrugMiscPrice {
    Id: number = null;
    TenantId: number = null;
    DrugId: number = null;
    DrugMiscPriceCatId: number = null;
    Price: any = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class DrugMisc {
    AdmnstrnRoute2Id: number = null;
    AdmnstrnRouteId: number = null;
    AutoMcId: number = null;
    CompoundTypId: number = null;
    DefaultDrugLotId: number = null;
    DispUnitFormId: number = null;
    DoseFrmCodeId: number = null;
    DoseFrmDescId: number = null;
    DrugId: number = null;
    DrugStrQualId: number = null;
    Id: number = null;
    InvLocId: number = null;
    IsMedGuide: boolean = null;
    IsUnitDrug: boolean = null;
    PotencyUnitCodeId: number = null;
    QtyQualId: number = null;
    TenantId: number = null;
}
export class DrugInformation {
    NDC: string = null;
    DrugItemId: number = null;
    Version: number = null;
    Descrip: string = null;
    Shape: string = null;
    Color_Frnt: string = null;
    Color_Back: string = null;
    ID_FRONT: string = null;
    ID_BACK: string = null;
    FlavorName: string = null;
    CoatingName: string = null;
    ScoringName: string = null;
}
export class DrugCategory {
    DrugCatToBucket: any = null;
    DrugId: number = null;
}

export class DrugRefreshMMS {
    AWPPrice: string = null;
    BillNDC: string = null;
    Brand: string = null;
    BrandName: string = null;
    CostPrice: string = null;
    DirPrice: string = null;
    DiscontinueDate: Date = null;
    DispensUnit: string = null;
    DosageForm: string = null;
    DrugCategory: string = null;
    DrugClass: string = null;
    DrugForm: string = null;
    DrugNDC: string = null;
    DrugName: string = null;
    DrugType: string = null;
    Expirationdate: Date = null;
    GenericName: string = null;
    LastDispensed: string = null;
    Madeby: string = null;
    Pack: string = null;
    QckCode: string = null;
    Quanitypack: string = null;
    RouteOfAdministration: string = null;
    Size: string = null;
    StockQty: string = null;
    Strength: string = null;
    TxrCode: string = null;
    TxrExtension: string = null;
    UnitPrice: string = null;
    Warning: string = null;
    discontDate: null;
    unitdoesofInd: string = null;
    unitofMeasure: string = null;
    upc: string = null;
    Therapeutic: string = null;
    TherapeuticCode: string = null;
    ObsDate: string = null;
}

export class DrugInsuRest {
    Id: number = null;
    // IsAllowed = false;
    // BillingNDC: any = null;
    DrugId: number = null;
    InsurId: number = null;
    IsAllInsu = false;
    MaxQtyAllowed: any = null;
    MaxRefillsAllowed: any = null;
    // NDCQualiId: number = null;
    // IsPriorApprReqd = false;
    // QtyConCal = 0;
    IsActive = true;
    IsDeleted = false;
    // Result: any = null;
    Incentive: number=null;
    VaccineGrp: any = null;
    SubClarifId: any = null;
    SubClarification: string = null;
    SubClarificationRem: string = null;
    CostBasisId: any = null;
    CostBasis: string = null;
    ServReasonId: any = null;
    ServReasonName: string = null;
    ProfServId: any = null;
    ProfServName: string = null;
    ServResultId: any = null;
    ServResult: string = null;
    EffortLvlId: any = null;
    EffortLvlName: string = null;
    CoAgentNum: string = null;
    CoAgentIdType: string = null;
    ClinicalSignfId: any = null;
    ClinicalSignfName: string = null;
    PhComment: string  = null;
    ImageUrl: any = null; 
}

export class DrugBucketPrice {
    Id: number = null;
    DrugBucketId: number = null;
    TenantId: any =  null;
    UnitPrice: any = null;
    DrugId: number = null;
    IsActive: boolean = null;
}
export class DrugDiagnosis {
    DIAGCODE: any= null;
    DIAGDESCR: any = null;
}

