import { RxHistoryParams } from "./patient-history.model";
import { FileGeneratorList } from "./rx.model";

export class WjGridSaveModel {
    ColumnOrder: string = null;
    UserId: number = null;
    TenantId: number = null;
    WijmoId: number = null;
    Id: number = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    WijmoKey: string = null;
}

export const wijimoIdsList = {
    patientRxHistWJ: 1,
    browseRxWj: 2,
    filedQueueWj: 3,
    refQueueWJ: 4,
    erxListingWJ: 5,
    rphVerfWJ: 6,
    rxVerfWJ: 7,
    drugVerfWJ1: 8,
    drugVerfWJ2: 9,
    reviewdrugVerfWJ: 10,
    reviewRxVerfWJ: 11,
    rphDashWj: 12,
    returnToStockWJ: 13,
    reviewFollowupWJ: 14,
    gtTransHisWJ: 15,
    patListWJ: 16,
    prescListWJ: 17,
    drugListWJ: 18,
    insuListWJ: 19,
    sigListWJ: 20,
    facilityListWJ: 21,
    priceSchdListWJ: 22,
    priceListWJ: 23,
    discountListWJ: 24,
    drugWarnListWJ: 25,
    diagnosisListWJ: 26,
    rxFollowUpListWJ: 27,
    doseScheduleListWJ: 28,
    patCategoryListWJ: 29,
    presSpecalityListWJ: 30,
    payorFileListWJ: 31,
    ncpdpListWJ: 32,
    vendorListWJ: 33,
    pharmacyListWJ: 34,
    drugSearchListWJ: 35,
    usersListWJ: 36,
    rolesListWJ: 37,
    loginHistoryListWJ: 38,
    auditListWJ: 39,
    overrideLogListWJ: 40,
    phyInvListWJ: 41,
    enterInvListWJ: 42,
    viewInvListWJ: 43,
    bucketListwj: 44,
    invTransListWJ: 45,
    faxRefillListWJ: 46,
    faxLogListWJ1: 47,
    faxLogListWJ2: 48,
    batchedFaxListWJ: 49,
    incomingFaxListWJ: 50,
    erxExceptionListWJ: 51,
    unBilledRxListWJ: 52,
    patAdditionalInsuListWJ: 53,
    patDiagnosisListWJ: 54,
    patDeliveryAddListWJ: 55,
    DocumentsListWJ: 56,
    patClinicalInfoListWJ: 57,
    prescClinicAddListWJ: 58,
    prescHistoryListWJ: 59,
    drguDispHistoryListWJ: 60,
    drugMiscInfoListWJ: 61,
    drugCounsellingListWJ: 62,
    drugInsuRestListWJ: 63,
    drugInvVendorListWJ: 64,
    drugInvHistoryListWJ: 65,
    mergePatientListWJ: 66,
    profitRevDataSetListWJ: 67,
    easyRefillWizardListWJ: 68,
    rxByInsuPopupListWJ: 69,
    rxByInsuPayorPopupListWJ: 70,
    scheduledTaskListWJ: 71,
    recallDeletedRxListWJ: 72,
    drugPriceUpdateListWJ: 73,
    erxChangeReqDURListWJ: 74,
    erxChangeReqSelectDrugListWJ: 75,
    primeescPatAckListWJ: 76,
    primeescPatHistListWJ: 77,
    primeescSignByDateListWJ: 78,
    primeescSignByRxAcknListWJ: 79,
    primeescSignByRxHistListWJ: 80,
    primeescRxPickUpDetInsuWJ: 81,
    printRxWithAttDocsListWJ: 82,
    drugCounsellingWarningListWJ: 83,
    drugPriceCheckHistListWJ: 84,
    drugPriceCheckSimilarDrugListWJ: 85,
    drugPriceCheckTotalListWJ: 86,
    scheduledTaskExecHistListWJ: 87,
    revHistoryListWj: 88,
    otherInsuListWj: 89,
    documentQueueListWJ: 90,
    batchScanListWJ: 91,
    vendorInvHistListWJ: 92,
    bucketLogListWJ: 93,
    eRxExtraInfoListWJ: 94,
    rphVeryHistListWJ: 95,
    drugMMSSearchList: 96,
    viewDatSetWj: 97,
    bucketListDisplaywj: 98,
    printRxWithAttDocsListWJFill: 99,
    equivalentDrugs: 100,
    InventoryTransactionAudit: 101,
    refillQueueWJ: 102,
    insuranceListWJ: 103,
    reviewRxHistwj: 104,
    primeEDIPriceUpdateHistListWJ: 105,
    refillFlowComponent: 106,
    drugOtherPriceswj: 107,
    BenefitCheckCoverageInfoQueueWj: 108,
    rxGroupViewWJ: 109,
    equivalentBrandDrugs : 110,
    equivalentGenDrugs : 111,
    fileRecordsWJ: 112,
    cntrlSettingsWj: 113,
    insuranceToBillWj: 114,
    patientSpecificWj: 115,
    patientMedAdherenceWj: 116,
    equivalentDrugWj: 117,
    rxTransmissionLogWJ: 118,
    printRefReqWJ: 119,
    printbatchedRxsRefReqWJ: 120,
    quickRefillWJ: 121,
    faxbatchedRxsRefReqWJ: 122,
    controlResubmitListWJ: 123,
    controlgenerateListWJ: 124,
    rxBasicDisplayListWJ: 125,
    faxRefReqWJ: "faxRefSelectedRxs",
    auditListWJ2: "auditListWJ2",
    auditListWJ3: "auditListWJ3",
    auditListWJ4: "auditListWJ4",
    auditListWJ5: "auditListWJ5",
    auditListWJ6: "auditListWJ6",
    labelQueueDisplayListWJ: "LabelQueueWj",
    equivalentGenDrugsInNewRxFlow: "equivalentGenDrugsInNewRxFlow",
    topPerfomersPatientListWj:"topPerfomersPatientListWj",
    topPerfomersInsideListWj: "topPerfomersInsideListWj",
    topPerfomersPrescriberListWj: "topPerfomersPrescriberListWj",
    topPerfomersDrugListWj: "topPerfomersDrugListWj",
    topPerfomersInsListWj: "topPerfomersInsListWj",
    topPerfomersFacListWj: "topPerfomersFacListWj",
    forGroupViewWj: "forGroupViewWj",
    automatSubWj: "automatSubWj",
    CntrlRxAuditTrailWj: "CntrlRxAuditTrailWj",
    viewErrorCntrlRxWj: "viewErrorCntrlRxWj",
    logicalAccessCtrlWj: "logicalAccessCtrlWj",
    messageLogWj: "messageLogWj",
    messageQueueWj: "messageQueueWj",
    rxGroupViewDetailsWJ: "rxGroupViewDetailsWJ",
    controlFileErrorForNY:"controlFileErrorForNY",
    controlFileError:"controlFileError",
    refillDueMsgOuterWj: "refillDueMsgOuterWj",
    refillDueMsgInnerWj: "refillDueMsgInnerWj",
    refillDueMsgIndividualWj: "refillDueMsgIndividualWj",
    geographyDistributionWj: "geographyDistributionWj",
    drugThearpauticWj: "drugThearpauticWj",
    reverseClaim: "reverseClaimData",
    inventoryListwj:"inventoryListwj",
    insuaranceListeRxListwj:"insuaranceListeRxListwj",
    insuExceptionListWJ:"insuExceptionListWJ",
    physicalInvStatusRecWJ: "physicalInvStatusRecWJ",
    physicalInvRecWJ: "physicalInvRecWJ",
    labelDesignListWJ: "labelDesignListWJ",
    compoundIngListWJ: "compoundIngListWJ",
    prescriberSearchWJ :"prescriberSearchWJ",
    InsuFileSearchWJ: "InsuFileSearchWJ",
    DurValWj: "DurValWj",
    manageInvwj:"manageInvWj",
    physicalInvWj:"physicalInvWj",
    receiveInvWj: "receiveInvWj",
    dispensedInvWj:"dispensedInvWj",
    DURValNewRxWJ: "DURValNewRxWJ",
    NoteHistory: "NoteHistory",
    prescClinicAddListPopupWJ:"prescClinicAddListPopupWJ",
    PrescTypeFileWJ : "PrescTypeFileWJ",
    edi810SuccessfulRecords: "edi810SuccessfulRecords",
    edi810UnSuccessfulRecords: "edi810UnSuccessfulRecords",
    refReqPrescriberWj: "refReqPrescriberWj",
    drugCatoriesList: "drugCatoriesList",
    intakeQueueWjList:  "intakeQueueWjList",
    fileGeneratorList:  "fileGeneratorList",
    iouWjList: "iouWjList",
    DPVVerfListWJ: "DPVVerfListWJ",
    NoteCatoriesList: "NoteCatoriesList",
    InvReconRepWj: "InvReconRepWj",
    vaccnImmunGeneratorListWJ: "vaccnImmunGeneratorListWJ",
    InvReconDrugWj: "InvReconDrugWj",
    customReportListWJ: "customReportListWJ",
    deferredEquivalentsWJ : "deferredEquivalentsWJ",
    prevoiusRefillsWJ: "prevoiusRefillsWJ",
    prevoiusFillsWJ: "prevoiusFillsWJ",
    dscsaListWJ : "dscsaListWJ",
    RxHistoryCurrentView : "RxHistoryCurrentView",
    pillCounterListWJ: "pillCounterListWJ",
    remittanceDataSetListWJ: "remittanceDataSetListWJ",
    drugSuggestListWj : "drugSuggestListWj",
    drugEquListWj : "drugEquListWj",
    paQueueWj : "paQueueWj",
    drugRequesteDetails : "drugRequesteDetails"
};

export class DefaultHeaders {
    equivalentBrandDrugs = [
        {header: "Drug Name", isVisible: true, width: "2.6*"},
        {header: "Drug Class", isVisible: true,  width: "1.2*"},
        {header: "NDC", isVisible: true, width: "1.6*"},
        {header: "Size", isVisible: true, width: "0.8*"},
        {header: "Pck/Unit Cost", isVisible: true, width: "1.5*"},
        {header: "Pck/Unit AWP", isVisible: true, width: "1.6*" },
        {header: "Made By", isVisible: true,  width: "1.2*"},
        {header: "Qty in Hand", isVisible: true, width: "1.2*"},
        {header: "Last Disp", isVisible: true,  width: "1.2*"},
        {header: "D/C Date", isVisible: true, width: "1.2*"},
        {header: "Drug Type", isVisible: true,  width: "1.2*"},
        {header: "Brand Name", isVisible: true, width: "1.3*"},
        {header: "Generic Name", isVisible: true, width: "1.4*"},        
        {header: "Brand?", isVisible: true, width: "0.7*"},
        {header: "Equ.Avl?", isVisible: true, width: "0.9*"},
        {header: "Gen.Avl?", isVisible: true, width: "0.9*"},
        {header: "Is Pref?", isVisible: true, width: "0.9*"},        
        {header: "Is 340B", isVisible: true, width: "0.9*"},
        {header: "Inv?", isVisible: true, width: "*"},
    ]
    equivalentGenDrugs = [
        {header: "Drug Name", isVisible: true, width: "2.6*"},
        {header: "Drug Class", isVisible: true,  width: "1.2*"},
        {header: "NDC", isVisible: true, width: "1.6*"},
        {header: "Size", isVisible: true, width: "0.8*"},
        {header: "Pck/Unit Cost", isVisible: true, width: "1.5*"},
        {header: "Pck/Unit AWP", isVisible: true, width: "1.6*" },
        {header: "Made By", isVisible: true,  width: "1.2*"},
        {header: "Qty in Hand", isVisible: true, width: "1.2*"},
        {header: "Last Disp", isVisible: true,  width: "1.2*"},
        {header: "D/C Date", isVisible: true, width: "1.2*"},
        {header: "Drug Type", isVisible: true,  width: "1.2*"},
        {header: "Brand Name", isVisible: true, width: "1.3*"},
        {header: "Generic Name", isVisible: true, width: "1.4*"},        
        {header: "Brand?", isVisible: true, width: "0.7*"},
        {header: "Equ.Avl?", isVisible: true, width: "0.9*"},
        {header: "Gen.Avl?", isVisible: true, width: "0.9*"},
        {header: "Is Pref?", isVisible: true, width: "0.9*"},
        {header: "Is 340B", isVisible: true, width: "0.9*"},
        {header: "Inv?", isVisible: true, width: "*"},
    ]
    rxGroupViewWJ = [
        {header: "Rx#", isVisible: true, width: 142},
        {header: "Auth#", isVisible: true, width: 90},
        {header: "Total Fills", isVisible: true, width: 200},
        {header: "Total Qty Disp", isVisible: true, width: 200},
        {header: "Total Qty Remaining", isVisible: true, width: 200},
        {header: "Total CoPay", isVisible: true, width: 200},
        {header: "Total Price", isVisible: true, width: 200},
        {header: "Total Days", isVisible: true, width: 200},
        {header: "Total Profit", isVisible: true, width: 200},
    ];

    rxGroupViewDetailsWJ = [
        {header: "Rx#", isVisible: true, width: 75},
        {header: "Auth#", isVisible: true, width: 45},
        {header: "Ref#", isVisible: true, width: 45},
        {header: "Fill#", isVisible: true, width: 45},
        {header: "Insurance", isVisible: true, width: 75},
        {header: "Status", isVisible: true, width: 55},
        {header: "Drug Name", isVisible: true, width: 200},
        {header: "Qty Dis", isVisible: true, width: 55},
        {header: "Days Supply", isVisible: true, width: 75},
        {header: "Fill Date", isVisible: true, width: 150},
        {header: "Prescriber Name", isVisible: true, width: 200},
        {header: "NDC", isVisible: true, width: 150},
        {header: "Due in Days", isVisible: true, width: 75},
        {header: "DVrf?", isVisible: true, width: 55},
        {header: "PVrf?", isVisible: true, width: 55},
        {header: "Rx Vrf?", isVisible: true, width: 55},
        {header: "Copay", isVisible: true, width: 75},
        {header: "Profit Amount", isVisible: true, width: 75},
        {header: "Label Printed?", isVisible: true, width: 75},
        {header: "Rx Origin", isVisible: true, width: 75},
    ];

   patientRxHistWJ = [
        {header: "Rx#", isVisible: true,  width: 87},
        {header: "Auth#", isVisible: true,  width: 45},
        {header: "Ref#", isVisible: true, width: 37},
        {header: "Fill#", isVisible: true, width: 34},
        {header: "Insurance", isVisible: true,  width: 69},
        {header: "Status", isVisible: true, width: 50},
        {header: "Drug Name", isVisible: true,  width: 259},
        {header: "Qty Dis", isVisible: true ,  width: 87},
        {header: "T.Rx Qty Rem", isVisible: true, width: 65},
        {header: "Qty trans. Out", isVisible: true, width: 80},
        {header: "Days Supply", isVisible: true, width: 51},
        {header: "Fill Date", isVisible: true, width: 163},
        {header: "Prescriber Name", isVisible: true, width: 259},
        {header: "NDC", isVisible: true,  width: 100},
        {header: "Price", isVisible: true,  width: 59},
        {header: "Due in Days", isVisible: true, width: 53},
        {header: "DVrf?", isVisible: true, width: 43},
        {header: "PVrf?", isVisible: true, width: 42},
        {header: "Rx Vrf?", isVisible: true, width: 40},
        {header: "Copay", isVisible: true, width: 49},
        {header: "Profit Amount", isVisible: true, width: 64},
        {header: "Label Printed?", isVisible: true, width: 59},
        {header: "Rx Origin", isVisible: false, width: 87},
        {header: "IsDrugDis", isVisible: false, width: 69},
        {header: "Total Amount", isVisible: false, width: 60},
        {header: "Insurance Paid", isVisible: false, width: 69},
        {header: "Sig Lines", isVisible: false, width: 184},
        {header: "Tag Name", isVisible: false, width: 46},
        {header: "Delivery Method", isVisible: false, width: 62},
        {header: "Refills Left", isVisible: false, width: 49},
        {header: "Rf and Def Due Date", isVisible: false, width: 85},
        {header: "HasDoc", isVisible: false, width: 57},
        {header: "TQty Disp.", isVisible: false, width: 50},
        {header: "Patient Pay", isVisible: false, width: 53},
    ];

    browseRxWj = [
        {header: "Rx#", isVisible: true, width: 75},
        {header: "Status", isVisible: true, width: 50},
        {header: "Ref#", isVisible: true, width: 35},
        {header: "Fill#", isVisible: true, width: 35},
        {header: "Auth#", isVisible: true, width: 50},
        {header: "Insu Code", isVisible: true, width: 80},
        {header: "Patient Name", isVisible: true, width: 150},
        {header: "Drug Name", isVisible: true, width: 150},
        {header: "Qty-Dis", isVisible: true, width: 105},
        {header: "Due Days", isVisible: true, width: 50},
        {header: "Prescriber Name", isVisible: true, width: 150},
        {header: "Fill Date & Time", isVisible: true, width: 170},
        {header: "Ord-Date", isVisible: true, width: 80},
        {header: "DAW", isVisible: true, width: 50},
        {header: "R.P.H", isVisible: true, width: 80},
        {header: "Tech.", isVisible: true, width: 80},
        {header: "RxNotes", isVisible: true, width: 117},
        {header: "Paid", isVisible: false, width: 105},
        {header: "Billed", isVisible: false, width: 105},
        {header: "NDC", isVisible: false, width: 107},
        {header: "Co-Pay", isVisible: false, width: 105},
        {header: "Drug Category", isVisible: false, width: 150},
        {header: "Class", isVisible: false, width: 50},
        {header: "Delivery Method", isVisible: false, width: 117},
        {header: "DVrf?", isVisible: false, width: 50},
        {header: "PVrf?", isVisible: false, width: 50},
        {header: "Sig Lines", isVisible: false, width: 150},
        {header: "Tag Name", isVisible: false, width: 150},
        {header: "Patient Address", isVisible: false, width: 150},
        {header: "Label Printed?", isVisible: false, width: 50},
        {header: "Profit Amount", isVisible: true, width: 73},
        {header: "Day's Supply", isVisible: false, width: 73},
        {header: "Prescriber Phone#", isVisible: false, width: 120},
        {header: "Prescriber Address", isVisible: false, width: 166},
        {header: "Picked Up Date & Time", isVisible: false, width: 173},
        {header: "Facility", isVisible: false, width: 120},
        {header: "Is340B", isVisible: false, width: 73},
        {header: "Inv Bucket Name", isVisible: false, width: 73},
    ];
   documentQueueListWJ = [
        {header: "Document ID", isVisible: true, width: 142},
        {header: "Attach", isVisible: true, width: 100},
        {header: "Tag Patient", isVisible: true, width: 190},
        {header: "Delivery Priority", isVisible: true, width: 154},
        {header: "Fill Rx", isVisible: true, width: 110},
        {header: "Scan Date", isVisible: true, width: 170},
        {header: "Title", isVisible: true, scheduledTaskListWJwidth: 158},
        {header: "Marked By", isVisible: true, width: 200},
        {header: "Comments", isVisible: true, width: 90}
    ];
    batchScanListWJ = [
        {header: "Document Id", isVisible: true, width: "2*"},
        {header: "Rx Reference No", isVisible: true, width: "2.7*"},
        {header: "Scan Date", isVisible: true, width: "3*"},
        {header: "Doc Type Text", isVisible: true, width: "1.8*"},
        {header: "Patient Name", isVisible: true, width: "3*"}
    ];

    filedQueueWj = [
        {header: "Rx#", isVisible: true, width: 57},
        {header: "Auth#", isVisible: true, width: 49},
        {header: "Ref#", isVisible: true, width: 46},
        {header: "Fill#", isVisible: true, width: 40},
        {header: "Fill Date", isVisible: true, width: 90},
        {header: "Defer Due Date", isVisible: true, width: 90},
        {header: "Patient Name", isVisible: true, width: 200},
        {header: "Prescriber Name", isVisible: true, width: 200},
        {header: "Drug Name", isVisible: true, width: 168},
        {header: "Qty Dis", isVisible: true, width: 56},
        {header: "Supply Days", isVisible: true, width: 53},
        {header: "T.Price", isVisible: true, width: 100},
        {header: "Insurance", isVisible: true, width: 66},
        {header: "Facility Code", isVisible: true, width: 96},
        {header: "Facility Name", isVisible: true, width: 76},
        {header: "Tag Name", isVisible: true, width: 95},
        {header: "Sig Code", isVisible: true, width: 150},
        {header: "Sig Desc.", isVisible: true, width: 95},
        {header: "Deferred Reason", isVisible: true, width: 150},
        {header: "Rx Notes", isVisible: true, width: 77}
    ];

    refQueueWJ = [
        {header: "Rx#", isVisible: true, width: 79},
        {header: "Status", isVisible: true, width: 79},
        {header: "Date-Time Queued", isVisible: true, width: 150},
        {header: "Source", isVisible: true, width: 100},
        {header: "Patient Name", isVisible: true, width: 267},
        {header: "Auth#", isVisible: true, width: 70},
        {header: "Ref#", isVisible: true, width: 70},
        {header: "Fill#", isVisible: true, width: 70},
        {header: "T.Rx Qty Rem", isVisible: true, width: 90},
        {header: "Drug Name", isVisible: true, width: 267},
        {header: "Prescriber Name", isVisible: true, width: 267},
        {header: "Added By", isVisible: true, width: 135},
        {header: "S.Days", isVisible: true, width: 70},
        {header: "Due Date", isVisible: true, width: 86},
        {header: "Last Refill Date", isVisible: true, width: 111},
        {header: "Ins", isVisible: true, width: 97},
        {header: "Ref. DD Threshold", isVisible: true, width: 98},
        {header: "Pickup Due Date", isVisible: true, width: 98},
        {header: "Picked Up", isVisible: true, width: 86},
        {header: "Pickup Date", isVisible: true, width: 111},
        {header: "Pickup Time", isVisible: true, width: 97},
        {header: "Refill Expiration Date", isVisible: true, width: 98},
        {header: "Is Maintenance Drug", isVisible: true, width: 98},

    ];

    erxListingWJ = [
        {header: "Message Type", isVisible: true, width: 90},
        {header: "Status", isVisible: true, width: 178},
        {header: "Date & Time", isVisible: true, width: 121},
        {header: "Patient Name", isVisible: true, width: 192},
        {header: "Prescriber Name", isVisible: true, width: 183},
        {header: "Drug Name", isVisible: true, width: 275},
        {header: "Last Filled", isVisible: true, width: 70},
        {header: "Processed?", isVisible: true, width: 82},
        {header: "Auth#", isVisible: true, width: 40},
        {header: "Rx#", isVisible: true, width: 58},
        {header: "Rph Notes", isVisible: true, width: 51},
        {header: "Presc Notes", isVisible: true, width: 51},
        {header: "Printed", isVisible: false, width: 56},
        {header: "Drug Class", isVisible: false, width: 56},
        {header: "Nabp", isVisible: false, width: 64},
        {header: "Speciality type", isVisible: false, width: 91},
        {header: "SPI#", isVisible: false, width: 111},
        {header: "Patient Phone", isVisible: false, width: 102},
        {header: "message Id", isVisible: false, width: 166},
        {header: "Version", isVisible: false, width: 79},
        {header: "Patient Type", isVisible: false, width: 57},
        {header: "Last Removed by", isVisible: false, width: 78},
        {header: "eRx Facility", isVisible: false, width: 69},
        {header: "Facility", isVisible: false, width: 70},
        {header: "Refill Response Status", isVisible: false, width: 79},
    ];

    rphVerfWJ = [
        {header: "Rx#", isVisible: true, width: 67},
        {header: "Ref#", isVisible: true, width: 38},
        {header: "Fill#", isVisible: true, width: 36},
        {header: "Patient Name", isVisible: true, width: 180},
        {header: "Drug Name", isVisible: true, width: 171},
        {header: "Filled Date", isVisible: true, width: 85},
        {header: "Days", isVisible: true, width: 38},
        {header: "Verification Status", isVisible: true, width: 94},
        {header: "Verified DtTm", isVisible: true, width: 150},
        {header: "Follow-Up Reason", isVisible: true, width: 102},
        {header: "Ins Name", isVisible: true, width: 74},
        {header: "Status", isVisible: true, width: 55},
        {header: "Billed", isVisible: true, width: 51},
        {header: "Paid", isVisible: true, width: 50},
        {header: "Prescriber Name", isVisible: true, width: 165},
        {header: "Class", isVisible: true, width: 50},
        {header: "Picked up", isVisible: true, width: 50},
        {header: "Facility Name", isVisible: true, width: 105},
        {header: "DEA#", isVisible: true, width: 79},
        {header: "Qty", isVisible: true, width: 42},
        {header: "Delivery Mode", isVisible: true, width: 67},
        {header: "Filled By", isVisible: true, width: 68},
        {header: "Verified By R.Ph", isVisible: true, width: 105}
    ];

    rxVerfWJ = [
        {header: "Rx#", isVisible: true, width: 67},
        {header: "Ref#", isVisible: true, width: 38},
        {header: "Fill#", isVisible: true, width: 36},
        {header: "Patient Name", isVisible: true, width: 180},
        {header: "Drug Name", isVisible: true, width: 171},
        {header: "Filled Date", isVisible: true, width: 85},
        {header: "Days", isVisible: true, width: 38},
        {header: "Verification Status", isVisible: true, width: 94},
        {header: "Verified DtTm", isVisible: true, width: 150},
        {header: "Ins Name", isVisible: true, width: 74},
        {header: "Status", isVisible: true, width: 55},
        {header: "Billed", isVisible: true, width: 51},
        {header: "Paid", isVisible: true, width: 50},
        {header: "Prescriber Name", isVisible: true, width: 165},
        {header: "Class", isVisible: true, width: 50},
        {header: "Picked up", isVisible: true, width: 50},
        {header: "Facility Name", isVisible: true, width: 105},
        {header: "DEA#", isVisible: true, width: 79},
        {header: "Qty", isVisible: true, width: 42},
        {header: "Delivery Mode", isVisible: true, width: 67},
        {header: "Filled By", isVisible: true, width: 68},
        {header: "Verified By R.Ph", isVisible: true, width: 105}
    ];

    drugVerfWJ1 = [
        {header: "Rx#", isVisible: true, width: 136},
        {header: "Status", isVisible: true, width: 120},
        {header: "Ref#", isVisible: true, width: 104},
        {header: "Fill#", isVisible: true, width: 102},
        {header: "Patient", isVisible: true, width: 280},
        {header: "Prescriber", isVisible: true, width: 280},
        {header: "Drug", isVisible: true, width: 280},
        {header: "NDC", isVisible: true, width: 170},
        {header: "Qty", isVisible: true, width: 129},
        {header: "Fill Date", isVisible: true, width: 256},
        {header: "Qty in hand", isVisible: true, width: 228}
    ];

    drugVerfWJ2 = [
        {header: "Rx#", isVisible: true, width: 140},
        {header: "Patient Name", isVisible: true, width: 160},
        {header: "Pharmacist", isVisible: true, width: 120},
        {header: "Ref#", isVisible: true, width: 60},
        {header: "Fill#", isVisible: true, width: 60},
        {header: "Tech", isVisible: true, width: 120},
        {header: "Drug NDC", isVisible: true, width: 120},
        {header: "Date Time Verified", isVisible: true, width: 150},
        {header: "Result", isVisible: true, width: 120},
        {header: "Drug", isVisible: true, width: 359},
        {header: "Qty In Hand", isVisible: true, width: 120},
        {header: "Qty Dispensed", isVisible: true, width: 116},
        {header: "Lot#", isVisible: true, width: 116},
        {header: "Lot Expiration Date", isVisible: true, width: 116},
        {header: "GTIN#", isVisible: true, width: 116},
        {header: "Serial#", isVisible: true, width: 116}
    ];

    reviewdrugVerfWJ = [
        {header: "User", isVisible: true, width: "0.7*"},
        {header: "Tech", isVisible: true, width: "0.7*"},
        {header: "Verified", isVisible: true, width: "0.9*"},
        {header: "Bottle NDC", isVisible: true, width: "1*"},
        {header: "Result", isVisible: true, width: "2.1*"},
        {header: "Rx#", isVisible: true, width: "1.4*"},
        {header: "Ref#", isVisible: true, width: "0.4*"},
        {header: "Fill#", isVisible: true, width: "0.5*"},
        {header: "Drug Name", isVisible: true, width: "3.5*"},
        {header: "Filled", isVisible: true, width: "0.9*"},
        {header: "Tr Count", isVisible: true, width: "0.7*"},
        {header: "Qty", isVisible: true, width: "0.5*"},
        {header: "Verif Qty", isVisible: true, width: "1.1*"}
    ];

    reviewRxVerfWJ = [
        {header: "Rx#", isVisible: true, width: 67},
        {header: "Ref#", isVisible: true, width: 39},
        {header: "Fill#", isVisible: true, width: 33},
        {header: "Patient Name", isVisible: true, width: 137},
        {header: "Drug Name", isVisible: true, width: 143},
        {header: "Drug Class", isVisible: true, width: 43},
        {header: "User", isVisible: true, width: 51},
        {header: "Verified", isVisible: true, width: 84},
        {header: "Billed", isVisible: true, width: 137},
        {header: "Paid", isVisible: true, width: 131},
        {header: "Qty", isVisible: true, width: 41},
        {header: "Days", isVisible: true, width: 38},
        {header: "Filled", isVisible: true, width: 79},
        {header: "Status", isVisible: true, width: 55},
        {header: "Pharmacist Verification Status", isVisible: true, width: 212},
        {header: "Rx Verification Status", isVisible: true, width: 106},
        {header: "Prescriber Name", isVisible: true, width: 121},
        {header: "Tr Count", isVisible: true, width: 48}
    ];

    rphDashWj = [
        {header: "Rx#", isVisible: true, width: 69},
        {header: "Auth#", isVisible: true, width: 51},
        {header: "Ref#", isVisible: true, width: 39},
        {header: "Fill#", isVisible: true, width: 38},
        {header: "Status", isVisible: true, width: 53},
        {header: "Insurance", isVisible: true, width: 91},
        {header: "Patient Name", isVisible: true, width: 168},
        {header: "Drug Name", isVisible: true, width: 282},
        {header: "Qty Disp", isVisible: true, width: 50},
        {header: "Supply Days", isVisible: true, width: 56},
        {header: "Fill Date", isVisible: true, width: 86},
        {header: "Pat.Pay", isVisible: true, width: 58},
        {header: "Ins.Paid Amt", isVisible: true, width: 116},
        {header: "Drug PV", isVisible: true, width: 91},
        {header: "Rph Verf.", isVisible: true, width: 98},
        {header: "Prescriber Name", isVisible: true, width: 137},
        {header: "Order Date", isVisible: true, width: 80},
        {header: "Under paid", isVisible: true, width: 49},
        {header: "Defer Due Date", isVisible: true, width: 83},
        {header: "Rx Notes", isVisible: true, width: 150},
        {header: "Rx Verification", isVisible: true, width: 93},
    ];

    returnToStockWJ = [
        {header: "Patient Name", isVisible: true, width: 166},
        {header: "Patient Ph#", isVisible: true, width: 104},
        {header: "Rx#", isVisible: true, width: 80},
        {header: "Ref#", isVisible: true, width: 38},
        {header: "Fill#", isVisible: true, width: 39},
        {header: "Unpicked For", isVisible: true, width: 66},
        {header: "Date Filled", isVisible: true, width: 88},
        {header: "Drug Name", isVisible: true, width: 230},
        {header: "Qty", isVisible: true, width: 56},
        {header: "Supply Days", isVisible: true, width: 55},
        {header: "Prescriber", isVisible: true, width: 230},
        {header: "Prescriber Ph#", isVisible: false, width: 47},
        {header: "Ins", isVisible: true, width: 54},
        {header: "Copay", isVisible: true, width: 73},
        {header: "Price", isVisible: true, width: 145},
        {header: "Ins.Amt.Paid", isVisible: true, width: 90},
        {header: "Status", isVisible: true, width: 60},
        {header: "PH", isVisible: true, width: 52},
        {header: "Group#", isVisible: false, width: 140},
        {header: "BIN", isVisible: true, width: 60},
        {header: "Tag Name", isVisible: true, width: 82},
        {header: "Facility", isVisible: true, width: 82},
        {header: "Rx Notes", isVisible: true, width: 74},
    ];

    reviewFollowupWJ = [
        {header: "Rx#", isVisible: true, width: 90},
        {header: "Ref#", isVisible: true, width: 48},
        {header: "Fill#", isVisible: true, width: 48},
        {header: "Patient Name", isVisible: true, width: 166},
        {header: "DOB", isVisible: true, width: 97},
        {header: "Insurance", isVisible: true, width: 113},
        {header: "Status", isVisible: true, width: 58},
        {header: "Prescriber", isVisible: true, width: 166},
        {header: "Drug Name", isVisible: true, width: 166},
        {header: "Fill Date", isVisible: true, width: 97},
        {header: "Qty Dis", isVisible: true, width: 75},
        {header: "Supply Days", isVisible: true, width: 50},
        {header: "Tag Name", isVisible: true, width: 194},
        {header: "Price", isVisible: true, width: 54},
        {header: "Pickedup Rx", isVisible: true, width: 69},
        {header: "Facility", isVisible: true, width: 54},
        {header: "Notes", isVisible: true, width: 54},
    ];

    gtTransHisWJ = [
        {header: "Rx#", isVisible: true, width: 76},
        {header: "GroupID", isVisible: true, width: 48},
        {header: "BatchID", isVisible: true, width: 48},
        {header: "Ref#", isVisible: true, width: 34},
        {header: "Fill#", isVisible: true, width: 33},
        {header: "PatientName", isVisible: true, width: 113},
        {header: "QtyDis", isVisible: true, width: 76},
        {header: "Day", isVisible: true, width: 70},
        {header: "Drug Name", isVisible: true, width: 189},
        {header: "Fill Date Time", isVisible: true, width: 189},
        {header: "Insurance Code", isVisible: true, width: 76},
        {header: "IngredientCostPaid", isVisible: true, width: 76},
        {header: "Copay", isVisible: true, width: 76},
        {header: "TotalAmountPaid", isVisible: true, width: 76},
        {header: "ProfFeePaid", isVisible: true, width: 76},
        {header: "RejectedReason", isVisible: true, width: 113},
        {header: "TransResult", isVisible: true, width: 69},
        {header: "TransmissionStatus", isVisible: true, width: 110},
    ];

    patListWJ = [
        {header: "Last Name", isVisible: true, width: "2*"},
        {header: "First Name", isVisible: true, width: "2*"},
        {header: "Date Of Birth", isVisible: true, width: "0.9*"},
        {header: "Gender", isVisible: true, width: "0.52*"},
        {header: "IsActive", isVisible: true, width: "0.62*"},
        {header: "Ins.Type", isVisible: true, width: "1.75*"},
        {header: "Policy#", isVisible: true, width: "0.98*"},
        {header: "Address", isVisible: true, width: "2*"},
        {header: "Telephone#", isVisible: true, width: "0.98*"},
        {header: "Pat.Chart#", isVisible: true, width: "0.87*"},
        {header: "Facility Code", isVisible: true, width: "1.2*"},
        {header: "Created By", isVisible: true, width: "1.2*"},
        {header: "Created Date", isVisible: true, width: "0.92*"},
        {header: "Ethnicity", isVisible: false, width: "1*"},
        {header: "Pat. Race", isVisible: false, width: "1*"},
    ];

    prescListWJ = [
        {header: "Last Name", isVisible: true, width: "1*"},
        {header: "First Name", isVisible: true, width: "1*"},
        {header: "Quick Code", isVisible: true, width: "0.5*"},
        {header: "License#", isVisible: true, width: "0.5*"},
        {header: "DEA#", isVisible: true, width: "0.5*"},
        {header: "NPI#", isVisible: true, width: "0.5*"},
        {header: "Address", isVisible: true, width: "2*"},
        {header: "Mobile#", isVisible: true, width: "0.9*"},
        {header: "Telephone#", isVisible: true, width: "0.9*"},
        {header: "Work#", isVisible: true, width: "0.9*"},
    ];

    drugListWJ = [
        {header: "Drug Name", isVisible: true, width: 250},
        {header: "Strength", isVisible: true, width: 95},
        {header: "Units", isVisible: true, width: 50},
        {header: "Form", isVisible: true, width: 50},
        {header: "Size", isVisible: true, width: 95},
        {header: "Drug NDC", isVisible: true, width: 105},
        {header: "Made By", isVisible: true, width: 105},
        {header: "AWP Price", isVisible: true, width: 95},
        {header: "Last Dispensed", isVisible: true, width: 113},
        {header: "Gen. Avail?", isVisible: true, width: 85},
        {header: "Quick Code", isVisible: true, width: 105},
        {header: "IsBrand", isVisible: true, width: 65},
        {header: "Cost Price", isVisible: true, width: 95},
        {header: "Drug Class", isVisible: true, width: 85},
        {header: "Drug Type", isVisible: true, width: 85},
        {header: "D/C Date", isVisible: true, width: 105},
        {header: "Stock Qty", isVisible: true, width: 95},
        {header: "Brand Name", isVisible: true, width: 113},
        {header: "Generic Name", isVisible: true, width: 113},
        {header: "Exp. Date", isVisible: true, width: 105}
      //  {header: "Billing NDC", isVisible: true, width: 105},
    ];

    insuListWJ = [
        {header: "Insurance Code", isVisible: true, width: "2*"},
        {header: "Insurance Name", isVisible: true, width: "2*"},
        {header: "Address", isVisible: true, width: "2*"},
        {header: "Phone#", isVisible: true, width: "1*"},
        {header: "Pharmacy Id", isVisible: true, width: "1*"},
        {header: "BIN", isVisible: true, width: "0.7*"},
        {header: "Processor Ctrl", isVisible: true, width: "1*"},
        {header: "Version", isVisible: true, width: "0.5*"},
       // {header: "Communication", isVisible: true, width: 116},
        {header: "Remarks", isVisible: true, width: "2*"},
    ];

    sigListWJ = [
        {header: "Sig Code", isVisible: true, width: "2*"},
        {header: "Language", isVisible: true,  width: "2*"},
        {header: "Sig Description", isVisible: true,  width: "3*"},
        {header: "Daily Dose", isVisible: true,  width: "2*"},
    ];

    facilityListWJ = [
        {header: "Facility Code", isVisible: true, width: "1*"},
        {header: "Address 1", isVisible: true,width: "1*"},
        {header: "Address 2", isVisible: true, width: "1*"},
        {header: "City", isVisible: true, width: "0.7*"},
        {header: "State", isVisible: true, width: "0.5*"},
        {header: "Zip", isVisible: true, width: "0.5*"},
        {header: "Phone# 1", isVisible: true, width: "0.7*"},
        {header: "Phone# 2", isVisible: true, width: "0.7*"},
        {header: "PO Form", isVisible: true, width: "1*"},
        {header: "PO Text", isVisible: true, width: "1*"},
        {header: "Comments", isVisible: true, width: "1*"},
    ];

    priceSchdListWJ = [
        {header: "Price Code", isVisible: true, width: "1*"},
        {header: "Name", isVisible: true, width: "3*"},
        {header: "Base", isVisible: true,  width: "1*"},
        {header: "SType", isVisible: true,  width: "1*"},
        {header: "Mark Up", isVisible: true, width: "1*"},
        {header: "Pro Fee", isVisible: true,  width: "1*"},
        {header: "RoundTo", isVisible: true,  width: "1*"},
        {header: "Up Charge", isVisible: true, width: "1*"},
        {header: "Min Charge", isVisible: true,  width: "1*"}
    ];

    priceListWJ = [
        {header: "NDC", isVisible: true, width: "1.5*"},
        {header: "Drug Name", isVisible: true, width: "2.1*"},
        {header: "Strength", isVisible: true, width: "1.3*"},
        {header: "Form", isVisible: true, width: "0.7*"},
        {header: "Price Code", isVisible: true, width: "1.3*"},
        {header: "Qty1", isVisible: true, width: "1.3*"},
        {header: "Price1", isVisible: true, width: "1.5*"},
        {header: "Qty2", isVisible: true, width: "1.3*"},
        {header: "Price2", isVisible: true, width: "1.5*"},
        {header: "Qty3", isVisible: true, width: "1.3*"},
        {header: "Price3", isVisible: true, width: "1.5*"},
        {header: "Qty4", isVisible: true, width: "1.3*"},
        {header: "Price4", isVisible: true, width: "1.5*"},
        {header: "Qty5", isVisible: true, width: "1.3*"},
        {header: "Price5", isVisible: true, width: "1.2*"},
    ];

    discountListWJ = [
        {header: "Discount Code", isVisible: true, width: "1*"},
        {header: "Discount Name", isVisible: true,width: "1*"},
        {header: "Base Amount(or)Percentage", isVisible: true, width: "3*"},
        {header: "Discount", isVisible: true, width: "1*"}
    ];

    drugWarnListWJ = [
        {header: "Warning", isVisible: true, width: "*"},
        {header: "English Description", isVisible: true, width: "3*"},
        {header: "Spanish Description", isVisible: true, width: "3*"}
    ];

    diagnosisListWJ = [
        {header: "Code", isVisible: true, width: "1*"},
        {header: "Diagnosis Description", isVisible: true, width: "4*"},
        {header: "Type", isVisible: true, width: "1*"}
    ];

    rxFollowUpListWJ = [
        {header: "Tag Name", isVisible: true, width: "2*"},
        {header: "Tag Status", isVisible: true, width: "2*"},
    ];

    doseScheduleListWJ = [
        {header: "Dose Schedule Name", isVisible: true, width: "3.9*"},
        {header: "Dose Time Type", isVisible: true, width: "3.9*"},
        {header: "Repeat Days", isVisible: true, width: "3.9*"},
        {header: "Dose Per Day", isVisible: true, width: "3.9*"},
    ];

    patCategoryListWJ = [
        {header: "Category Code", isVisible: true, width: "2*"},
        {header: "Category Name", isVisible: true, width: "2*"},
    ];

    presSpecalityListWJ = [
        {header: "Speciality Code", isVisible: true, width: "*"},
        {header: "Description", isVisible: true, width: "3*"},
    ];

    payorFileListWJ = [
        {header: "Payor Ins ID", isVisible: true, width: "1*"},
        {header: "Payor Name", isVisible: true, width: "1*"},
        {header: "Contact Name", isVisible: true, width: "1*"},
        {header: "Address", isVisible: true,width: "1*"},
        {header: "City", isVisible: true, width: "1*"},
        {header: "State", isVisible: true, width: "0.5*"},
        {header: "Zip", isVisible: true, width: "0.5*"},
        {header: "Phone#", isVisible: true, width: "1*"},
        {header: "Fax#", isVisible: true, width: "1*"},
        {header: "Notes", isVisible: true, width: "3*"}
    ];

    ncpdpListWJ = [
        {header: "Set ID", isVisible: true, width: 150},
        {header: "Set Name", isVisible: true, width: 204},
        {header: "Transaction Type", isVisible: true, width: 150},
        {header: "Patient", isVisible: true, width: 150},
        {header: "Insurance", isVisible: true, width: 150},
        {header: "Claims", isVisible: true, width: 150},
        {header: "Pharmacy Provider", isVisible: true, width: 150},
        {header: "Prescriber", isVisible: true, width: 150},
        {header: "COB", isVisible: true, width: 150},
        {header: "WCOM", isVisible: true, width: 150},
        {header: "DUR", isVisible: true, width: 150},
        {header: "Pricing", isVisible: true, width: 150},
        {header: "Coupon", isVisible: true, width: 150},
        {header: "Compound", isVisible: true, width: 150},
        {header: "Prior App", isVisible: true, width: 150},
        {header: "Clinical", isVisible: true, width: 150},
        {header: "Additional Doc", isVisible: true, width: 150},
        {header: "Facility", isVisible: true, width: 150},
        {header: "Narrative", isVisible: true, width: 150}
    ];

    vendorListWJ = [
        {header: "Vendor Id", isVisible: true, width: "1.4*"},
        {header: "Vendor Name", isVisible: true, width: "1.5*"},
        {header: "City", isVisible: true, width: "1.5*"},
        {header: "State", isVisible: true, width: "1.4*"},
        {header: "Zip", isVisible: true, width: "1.5*"},
        {header: "Phone#", isVisible: true, width: "1.5*"},
        {header: "Fax#", isVisible: true, width: "1.5*"},
        {header: "Website", isVisible: true, width: "1.5*"},
        {header: "Contact Person", isVisible: true, width: "1.5*"},
        {header: "Default Bucket", isVisible: true, width: "1.5*"},
        {header: "Comments", isVisible: true, width: "1.5*"},
        {header: "Status", isVisible: false, width: "1.1*"},
        {header: "Auto Close", isVisible: false, width: "1.1*"},
        {header: "Last Order Date", isVisible: false, width: "1.1*"},
        {header: "Last Received Date", isvisible: false, width: "1.1*"},
        {header: "Supports EDI", isVisible: false, width: "1.1*"},
        {header: "Send Vendo Cost Price", isVisible: false, width: "1.1*"},
        {header: "Use Qnt Pack For Price Call For 832", isVisible: false, width: "1.1*"},
        {header: "EDI Vendor Code", isVisible: false, width: "1.1*"},
        {header: "Use Qnt Pack For Price Call For 810", isVisible: false, width: "1.1*"},
        {header: "Price Qualifier", isVisible: false, width: "1.1*"},
        {header: "Update Price", isVisible: false, width: "1.1*"},
        {header: "Cost Qualifier", isVisible: false, width: "1.1*"},
        {header: "Update Cost", isVisible: false, width: "1.1*"}
    ];

    pharmacyListWJ = [
        {header: "Pharmacy Name", isVisible: true, width: "2*"},
        {header: "Address", isVisible: true, width: "2*"},
        {header: "Phone#", isVisible: true, width: "1*"},
        {header: "City", isVisible: true, width: "1*"},
        {header: "State", isVisible: true, width: "0.5*"},
        {header: "Zip", isVisible: true, width: "0.5*"},
        {header: "DEA#", isVisible: true, width: "0.6*"},
        {header: "NPI#", isVisible: true, width: "0.6*"},
        {header: "Fax#", isVisible: true, width: "1*"}
    ];

    drugSearchListWJ = [
        {header: "NDC", isVisible: true, width: "0.4*"},
        {header: "Shape", isVisible: true, width: "0.4*"},
        {header: "Color Front", isVisible: true, width: "0.4*"},
        {header: "Color Back", isVisible: true, width: "0.4*"},
        {header: "ID Front", isVisible: true, width: "0.4*"},
        {header: "ID Back", isVisible: true, width: "0.4*"},
        {header: "Description", isVisible: true, width: "*"}
    ];

    usersListWJ = [
        {header: "Initials", isVisible: true, width: "0.7*"},
        {header: "Name", isVisible: true, width: "2*"},
        {header: "Roles", isVisible: true, width: "0.8*"},
        {header: "Address", isVisible: true, width: "1.8*"},
        {header: "State", isVisible: true, width: "5*"},
        {header: "City", isVisible: true, width: "1*"},
        {header: "Zip", isVisible: true, width: "0.8*"},
        {header: "Phone# 1", isVisible: true, width: "1.1*"},
        {header: "Phone# 2", isVisible: true, width: "0.9*"},
        {header: "Active", isVisible: true, width: "0.5*"},
        {header: "Locked Status", isVisible: true, width: "1*"},
        {header: "Provider ID", isVisible: true, width: "1*"},
        {header: "Provider ID Type", isVisible: true, width: "1.4*"},
        {header: "Remarks", isVisible: true, width: "1.7*"}
    ];

    rolesListWJ = [
        {header: "Role Name", isVisible: true, width: "5*"},
        {header: "Role Type", isVisible: true, width: "4*"},
        {header: "Description", isVisible: true, width: "5*"}
    ];

    loginHistoryListWJ = [
        {header: "Login ID", isVisible: true, width: "1.4*"},
        {header: "IP Address", isVisible: true, width: "1.9*"},
        {header: "Login Date", isVisible: true, width: "2*"},
        {header: "Login Time", isVisible: true, width: "1.6*"},
        {header: "Logout Date", isVisible: true, width: "2.5*"},
        {header: "Logout Time", isVisible: true, width: '1.7*'},
        {header: "Session Time", isVisible: true, width: "2*"},
        {header: "User Roles", isVisible: true, width: "3*"}
    ];

    auditListWJ = [
        {header: "Item", isVisible: true, width: 326},
        {header: "Related To", isVisible: true, width: 139},
        {header: "Action Performed", isVisible: true, width: 177},
        {header: "Entity Data", isVisible: true, width: 167},
        {header: "User", isVisible: true, width: 140},
        {header: "Old Value", isVisible: true, width: 334},
        {header: "New Value", isVisible: true, width: 326},
        {header: "DateTime", isVisible: true, width: 275}
    ];

    auditListWJ2 = [
        {header: "Item", isVisible: true, width: 237},
        {header: "Related To", isVisible: true, width: 96},
        {header: "Action Performed", isVisible: true, width: 135},
        {header: "Rx#-Ref#-Fill#", isVisible: true, width: 117},
        {header: "User", isVisible: true, width: 72},
        {header: "Old Value", isVisible: true, width: 420},
        {header: "New Value", isVisible: true, width: 424},
        {header: "DateTime", isVisible: true, width: 170}
    ];

    auditListWJ3 = [
        {header: "Item", isVisible: true, width: 237},
        {header: "Related To", isVisible: true, width: 96},
        {header: "Action Performed", isVisible: true, width: 135},
        {header: "Patient Name", isVisible: true, width: 161},
        {header: "User", isVisible: true, width: 72},
        {header: "Old Value", isVisible: true, width: 400},
        {header: "New Value", isVisible: true, width: 400},
        {header: "DateTime", isVisible: true, width: 170}
    ];

    auditListWJ4 = [
        {header: "Item", isVisible: true, width: 230},
        {header: "Related To", isVisible: true, width: 96},
        {header: "Action Performed", isVisible: true, width: 135},
        {header: "Prescriber Name", isVisible: true, width: 168},
        {header: "User", isVisible: true, width: 72},
        {header: "Old Value", isVisible: true, width: 400},
        {header: "New Value", isVisible: true, width: 400},
        {header: "DateTime", isVisible: true, width: 170}
    ];

    auditListWJ5 = [
        {header: "Item", isVisible: true, width: 237},
        {header: "Related To", isVisible: true, width: 96},
        {header: "Action Performed", isVisible: true, width: 135},
        {header: "Drug Name", isVisible: true, width: 161},
        {header: "User", isVisible: true, width: 72},
        {header: "Old Value", isVisible: true, width: 400},
        {header: "New Value", isVisible: true, width: 400},
        {header: "DateTime", isVisible: true, width: 170}
    ];

    auditListWJ6 = [
        {header: "Item", isVisible: true, width: 237},
        {header: "Related To", isVisible: true, width: 96},
        {header: "Action Performed", isVisible: true, width: 135},
        {header: "Insurance Name", isVisible: true, width: 161},
        {header: "User", isVisible: true, width: 72},
        {header: "Old Value", isVisible: true, width: 400},
        {header: "New Value", isVisible: true, width: 400},
        {header: "DateTime", isVisible: true, width: 170}
    ];


    overrideLogListWJ = [
        {header: "Initials", isVisible: true, width: "1.5*"},
        {header: "Override Initials", isVisible: true, width: "2*"},
        {header: "Document Name", isVisible: true, width: "2*"},
        {header: "Function Name", isVisible: true, width: "3*"},
        {header: "Override Date", isVisible: true, width: "1.5**"},
        {header: "Comments", isVisible: true, width: "2*"},
        {header: "Result", isVisible: true, width: "2*"}
    ];

    phyInvListWJ = [
        {header: "NDC", isVisible: true, width: "1.7*"},
        {header: "Drug Name", isVisible: true, width: "3*"},
        {header: "Manufacturer", isVisible: true, width: "1.5*"},
        {header: "New Qty", isVisible: true, width: "1.2*"},
        {header: "Old Qty", isVisible: true, width: "1.2*"},
        {header: "Date", isVisible: true, width: "1.2*"},
        {header: "User ID", isVisible: true, width: "1.3*"},
        {header: "Bucket Name", isVisible: true, width: "1.7*"},
        {header: "Comments", isVisible: true, width: "1.2*"}
    ];

    enterInvListWJ = [
        {header: "NDC", isVisible: true, width: "1.5*"},
        {header: "Drug Name", isVisible: true, width: "2.3*"},
        {header: "Manufacturer", isVisible: true, width: "2*"},
        {header: "Date Received", isVisible: true, width: "1.5*"},
        {header: "Comment", isVisible: true, width: "2*"},
        {header: "Cost", isVisible: true, width: "1.5*"},
        {header: "Unit Cost", isVisible: true, width: "1.5*"},
        {header: "Qty Received", isVisible: true, width: "1.5*"},
        {header: "Bucket Name", isVisible: true, width: "2*"}
    ];

    viewInvListWJ = [
        {header: "Date Received", isVisible: true, width: 104},
        {header: "Drug Name", isVisible: true, width: 250},
        {header: "NDC", isVisible: true, width: 168},
        {header: "Made by", isVisible: true, width: 109},
        {header: "Bucket Name", isVisible: true, width: 109},
        {header:  "VendorName", isVisible: true, width: 130},
        {header:  "Account", isVisible: true, width: 150},
        {header: "Qty.Received", isVisible: true, width: 104},
        {header: "Unit Price", isVisible: true, width: 95},
        {header: "Tot.Amt", isVisible: true, width: 95},
        {header: "Pkgs.Recv", isVisible: true, width: 95},
        {header: "Type Name", isVisible: true, width: 109},
        {header: "Reference", isVisible: true, width: 109},
        {header: "PO#", isVisible: true, width: 111},
        {header: "Lot#", isVisible: true, width: 120},
        {header: "Therapeutic Code", isVisible: false, width: 112}
    ];

    bucketListwj = [
        {header: "Bucket Code", isVisible: true, width: "2*"},
        {header: "Bucket Name", isVisible: true, width: "4*"},
        {header: "Bucket Context Type", isVisible: true, width: "4*"},
        {header: "Default", isVisible: true, width: "3*"}
    ];
    bucketListDisplaywj = [
        {header: "Bucket Name", isVisible: true, width: "1.5*"},
        {header: "Qty", isVisible: true, width: "1.2*"},
        {header: "Bucket Context Type", isVisible: true, width: "2*"}
    ];

    invTransListWJ = [
        {header: "Type Name", isVisible: true, width: "7*"},
        {header: "Trans Type", isVisible: true, width: "8*"}
    ];

    faxRefillListWJ = [
        {header: "Rx#", isVisible: true, width: 80},
        {header: "Drug Name", isVisible: true, width: 250},
        {header: "Patient Name", isVisible: true, width: 190},
        {header: "Prescriber Name", isVisible: true, width: 200},
        {header: "Req. Sent Date", isVisible: true, width: 90},
        {header: "Mark Date", isVisible: true, width: 90},
        {header: "Comments", isVisible: true, width: 200},
        {header: "Reply Received", isVisible: true, width: 70},
        {header: "Ref. Req. Type", isVisible: true, width: 90},
        {header: "Already Processed", isVisible: true, width: 142},
        {header: "Refills Left", isVisible: true, width: 65},
        {header: "Qty Remaining", isVisible: true, width: 79},
        {header: "Available EQ Rxs", isVisible: true, width: 89},
        {header: "Failed Reason", isVisible: true, width: 200}
    ];

    faxLogListWJ1 = [
        {header: "Rx#", isVisible: true, width: "1.8*"},
        {header: "Subject", isVisible: true, width: "1.8*"},
        {header: "Cancel Status", isVisible: true, width: "1.8*"},
        {header: "Fax To", isVisible: true, width: "1.8*"},
        {header: "Fax From", isVisible: true, width: "1.8*"},
        {header: "Fax#", isVisible: true, width: "1.8*"},
        {header: "Reply Date", isVisible: true, width: "1.8*"},
        {header: "Status", isVisible: true, width: "1.8*"},
        {header: "Notes", isVisible: true, width: "1.8*"}
    ];

    faxLogListWJ2 = [
        {header: "Rx#", isVisible: true, width: "0.7*"},
        {header: "Subject", isVisible: true, width: "1.6*"},
        {header: "Fax To", isVisible: true, width: "1.7*"},
        {header: "Fax From", isVisible: true, width: "1.7*"},
        {header: "Fax#", isVisible: true, width: "1*"},
        {header: "Drug Name", isVisible: true, width: "1.5*"},
        {header: "Prescriber Phone", isVisible: true, width: "1.4*"},
        {header: "Reply Date", isVisible: true, width: "1.6*"},
        {header: "Status", isVisible: true, width: "1*"},
        {header: "Notes", isVisible: true, width: "1.5*"},
        {header: "Failed Reason", isVisible: true, width: "2.5*"}
    ];

    batchedFaxListWJ = [
        {header: "From Name", isVisible: true, width: "2*"},
        {header: "To Name", isVisible: true, width: "2*"},
        {header: "To Fax#", isVisible: true, width: "2*"},
        {header: "Rx#", isVisible: true, width: "1.5*"},
        {header: "Ref#", isVisible: true, width: "1.5*"},
        {header: "Fill#", isVisible: true, width: "2*"},
        {header: "Fax Date", isVisible: true, width: "2.1*"},
        {header: "Time Info", isVisible: true, width: "2.1*"}
    ];

    incomingFaxListWJ = [
        {header: "Date Received", isVisible: true, width: 140},
        {header: "From Fax#", isVisible: true, width: 140},
        {header: "Comments", isVisible: true, width: 180},
        {header: "Sender", isVisible: true, width: 140},
        {header: "Receiving Fax#", isVisible: true, width: 139}
    ];

    erxExceptionListWJ = [
        {header: "Rx#", isVisible: true, width: 60},
        {header: "Ref#", isVisible: true, width: 40},
        {header: "Fill#", isVisible: true, width: 40},
        {header: "Auth#", isVisible: true, width: 40},
        {header: "Patient", isVisible: true, width: 150},
        {header: "Prescriber", isVisible: true, width: 150},
        {header: "Drug", isVisible: true, width: 200},
        {header: "Ord Qty", isVisible: true, width: 80},
        {header: "Date Ord", isVisible: true, width: 80},
        {header: "Date Filled", isVisible: true, width: 80},
        {header: "Rph/Tech", isVisible: true, width: 90},
        {header: "Rph Notes", isVisible: true, width: 40},
        {header: "Rph Vrf", isVisible: true, width: 40},
        {header: "Presc Notes", isVisible: true, width: 40},
        {header: "Sig", isVisible: true, width: 326},
        {header: "Days", isVisible: true, width: 40},
        {header: "DAW", isVisible: true, width: 100},
        {header: "Tag Name", isVisible: true, width: 90},
    ];

    unBilledRxListWJ = [
        {header: "Rx#", isVisible: true, width: 88},
        {header: "Ref#", isVisible: true, width: 42},
        {header: "Fill#", isVisible: true, width: 42},
        {header: "Auth Refs", isVisible: true, width: 42},
        {header: "PatientName", isVisible: true, width: 240},
        {header: "QtyDisp.", isVisible: true, width: 87},
        {header: "Days", isVisible: true, width: 46},
        {header: "DrugName", isVisible: true, width: 240},
        {header: "Fill Date", isVisible: true, width: 86},
        {header: "Ins. Code", isVisible: true, width: 97},
        {header: "RejectCode", isVisible: true, width: 87},
        {header: "RejectReason", isVisible: true, width: 260},
        {header: "TransmissionStatus", isVisible: true, width: 195}
    ];

    patAdditionalInsuListWJ = [
        {header: "Ins Code", isVisible: true, width: "1*"},
        {header: "Insurer Name", isVisible: true, width: "1.9*"},
        {header: "Is 340B?", isVisible: true, width: "1*"},
        {header: "Ins ID", isVisible: true, width: "1.3*"},
        {header: "PC", isVisible: true, width: "0.7*"},
        {header: "Priority", isVisible: true, width: "1*"},
        {header: "Group#", isVisible: true, width: "1*"},
        {header: "C.H.Last Name", isVisible: true, width: "1.5*"},
        {header: "C.H.First Name", isVisible: true, width: "1.5*"},
        {header: "Active for Patient", isVisible: true, width: "1.3*"},
        {header: "BIN", isVisible: true, width: "1*"},
        {header: "Comments", isVisible: true, width: "2.5*"},
        {header: "Assig", isVisible: true, width: "0.7*"},
        {header: "Rel.", isVisible: true, width: "0.7*"}
    ];

    patDiagnosisListWJ = [
        {header: "Code", isVisible: true, width: "2*"},
        {header: "Diagnosis Description", isVisible: true, width: "5*"},
        {header: "Type", isVisible: true, width: "1*"}
    ];

    patDeliveryAddListWJ = [
        {header: "Address1", isVisible: true, width: "5*"},
        {header: "Address2", isVisible: true, width: "5*"},
        {header: "City", isVisible: true, width: "1.4*"},
        {header: "State", isVisible: true, width: "1.4*"},
        {header: "Zip", isVisible: true, width: "1.4*"}
    ];

    DocumentsListWJ = [
        {header: "Date", isVisible: true, width: "1*"},
        {header: "Document Name", isVisible: true, width: "1.2*"},
        {header: "Print With Label", isVisible: true, width: "1.2*"},
        {header: "Title", isVisible: true, width: "1*"},
        {header: "Category", isVisible: true, width: "1*"},
        {header: "Subcategory", isVisible: true, width: "1.2*"},
        {header: "Comment", isVisible: true, width: "1.2*"}
    ];

    patClinicalInfoListWJ = [
        {header: "Date and Time", isVisible: true, width: "3*"},
        {header: "Dimension", isVisible: true, width: "4*"},
        {header: "Measurement Unit", isVisible: true, width: "4*"},
        {header: "Measurement Value", isVisible: true, width: "3.5*"}
    ];

    prescClinicAddListWJ = [
        {header: "SPI#", isVisible: true, width: "1*"},
        {header: "Address1", isVisible: true, width: "1*"},
        {header: "Address2", isVisible: true, width: "1*"},
        {header: "State", isVisible: true, width: "0.8*"},
        {header: "City", isVisible: true, width: "0.8*"},
        {header: "ZipCode", isVisible: true, width: "0.5*"},
        {header: "Active", isVisible: true, width: "0.5*"},
        {header: "Fax#", isVisible: true, width: "1*"},
        {header: "Telephone#", isVisible: true, width: "1*"},

    ];

    prescHistoryListWJ = [
        {header: "Patient Name", isVisible: true, width: "2*"},
        {header: "Ins. Type", isVisible: true, width: "1*"},
        {header: "Drug Name", isVisible: true, width: "2*"},
        {header: "Strength", isVisible: true, width: "1*"},
        {header: "Form", isVisible: true, width: "1*"},
        {header: "Tot. Amt", isVisible: true, width: "1*"},
        {header: "Address", isVisible: true, width: "3.5*"},
        {header: "Rx#", isVisible: true, width: "1*"},
        {header: "Ref#", isVisible: true, width: "1*"},
        {header: "Quantity", isVisible: true, width: "1*"},
        {header: "Date Filled", isVisible: true, width: "1.7*"}
    ];

    drguDispHistoryListWJ = [
        {header: "Patient Name", isVisible: true, width: "3.5*"},
        {header: "Date Filled", isVisible: true, width: "0.9*"},
        {header: "Rx#", isVisible: true, width: "0.8*"},
        {header: "Ref#", isVisible: true, width: "0.6*"},
        {header: "Fill#", isVisible: true, width: "0.9*"},
        {header: "Status", isVisible: true, width: "0.6*"},
        {header: "TotalAmount", isVisible: true, width: "1*"},
        {header: "Patient Copay", isVisible: true, width: "1.2*"},
        {header: "QtyDisp", isVisible: true, width: "0.8*"},
        {header: "Days", isVisible: true, width: "0.6*"},
        {header: "Ins.Type", isVisible: true, width: "1.2*"},
        {header: "Prescriber Name", isVisible: true, width: "3.5*"}
    ];

    drugMiscInfoListWJ = [
        {header: "Lot#", isVisible: true, width: "2*"},
        {header: "Expiration Date", isVisible: true, width: "2.5*"},
        {header: "IsActive", isVisible: true, width: "1*"}
    ];

    drugCounsellingListWJ = [
        {header: "Age Category", isVisible: true, width: 131},
        {header: "From Age Days", isVisible: true, width: 93},
        {header: "To Age Days", isVisible: true, width: 88},
        {header: "Min Daily Units", isVisible: true, width: 84},
        {header: "Max Daily Units", isVisible: true, width: 79},
        {header: "Usual Daily Units", isVisible: true, width: 86},
        {header: "Ingredient Name", isVisible: true, width: 121},
        {header: "Min Daily Dose", isVisible: true, width: 97},
        {header: "Max Daily Dose", isVisible: true, width: 101},
        {header: "Usual Daily Dose", isVisible: true, width: 79},
        {header: "Frequency", isVisible: true, width: 89},
        {header: "Frequency Days", isVisible: true, width: 95},
        {header: "LifeTime Max", isVisible: true, width: 98},
        {header: "Patient Experience", isVisible: true, width: 123},
        {header: "Dosing Type", isVisible: true, width: 120},
        {header: "Hepatic Impairement May Require Dose Adjustment", isVisible: true, width: 112},
        {header: "Renal Impairement May Require Dose Adjustment", isVisible: true, width: 112}
    ];

    drugInsuRestListWJ = [
        {header: "Ins.Code", isVisible: true, width: 112},
        {header: "Ins.Name", isVisible: true, width: 126},
       // {header: "Billing NDC", isVisible: true, width: 162},
        // {header: "NDC Qualifier", isVisible: true, width: "3*"},
        // {header: "Allowed", isVisible: true, width: "1*"},
        // {header: "PA.?", isVisible: true, width: "1*"},
        {header: "Max.Refills", isVisible: true, width: 112},
        {header: "MaxQty", isVisible: true, width: 112},
        {header: "Apply to All Ins", isVisible: true, width: 112},
        {header: "Incentive Amount", isVisible: true, width: 181},
        {header: "Submission Clarification", isVisible: true, width: 210},
        {header: "Basis of Cost", isVisible: true, width: 138},
        {header: "DUR", isVisible: true, width: 246},
        {header: "Pharmacist Comment", isVisible: true, width: 227}

    ];

    drugInvVendorListWJ = [
        {header: "Vendor Name", isVisible: true, width: "1.4*"},
        {header: "Account", isVisible: true, width: "1.4*"},
        {header: "Vendor Item Code", isVisible: true, width: "1.4*"},
        {header: "Unit Price Cost", isVisible: true, width: "1.4*"},
        {header: "Cost Pack", isVisible: true, width: "1.3*"},
        {header: "AWP Pack", isVisible: true, width: "1.3*"},
        {header: "Unit Price AWP", isVisible: true, width: "1.4*"},
        {header: "Is Discountable?", isVisible: true, width: "1.4*"},
        {header: "Last Updated", isVisible: true, width: "1.4*"}
    ];

    drugInvHistoryListWJ = [
        {header: "Date Received", isVisible: true, width: "1*"},
        {header: "Vendor Name", isVisible: true, width: "1.2*"},
        {header: "Account", isVisible: true, width: "1.3*"},
        {header: "Bucket", isVisible: true, width: "1.1*"},
        {header: "Qty.Received", isVisible: true, width: "1*"},
        {header: "Price", isVisible: true, width: "0.8*"},
        {header: "Drug Lot", isVisible: true, width: "1*"},
        {header: "Reference", isVisible: true, width: "1*"},
        {header: "PO#", isVisible: true, width: "0.9*"},
        {header: "Received By", isVisible: true, width: "1.2*"},
       // {header: "NDC", isVisible: true, width: 125}
    ];

    mergePatientListWJ = [
        {header: "Rx#", isVisible: true, width: 95},
        {header: "Ref#", isVisible: true, width: 38},
        {header: "Fill#", isVisible: true, width: 38},
        {header: "Insurance", isVisible: true, width: 85},
        {header: "Drug Name", isVisible: true, width: 316},
        {header: "Qty", isVisible: true, width: 95},
        {header: "SDays", isVisible: true, width: 50},
        {header: "Fill Date", isVisible: true, width: 97},
        {header: "Auth#", isVisible: false, width: 112},
        {header: "Status", isVisible: false, width: 112},
        {header: "Patient Name", isVisible: false, width: 112},
        {header: "Picked Up", isVisible: false, width: 112},
        {header: "Copay", isVisible: false, width: 112},
        {header: "Price", isVisible: false, width: 112},
        {header: "Prescriber Name", isVisible: false, width: 112},
        {header: "NDC", isVisible: false, width: 112},
        {header: "Due Days", isVisible: false, width: 112}
    ];

    profitRevDataSetListWJ = [
        {header: "Rx#", isVisible: true, width: 95},
        {header: "Ref#", isVisible: true, width: 95},
        {header: "Fill#", isVisible: true, width: 95},
        {header: "Date Filled", isVisible: true, width: 112},
        {header: "Ins", isVisible: true, width: 112},
        {header: "Patient Name", isVisible: true, width: 220},
        {header: "Drug Name", isVisible: true, width: 220},
        {header: "NDC", isVisible: true, width: 112},
        {header: "Qty.", isVisible: true, width: 112},
        {header: "Days", isVisible: true, width: 95},
        {header: "Total Rx Amount", isVisible: true, width: 112},
        {header: "Profit", isVisible: true, width: 112},
        {header: "Cost Applied", isVisible: true, width: 112},
        {header: "Drug Cost", isVisible: true, width: 112},
        {header: "Rx Cost", isVisible: true, width: 112},
        {header: "Prescriber", isVisible: true, width: 220},
    ];

    easyRefillWizardListWJ = [
        {header: "Rx#", isVisible: true, width: "1*"},
        {header: "Refill Status", isVisible: true, width: "1.2*"},
        {header: "Fill Date", isVisible: true, width: "1*"},
        {header: "Auth#", isVisible: true, width: "0.6*"},
        {header: "Ref#", isVisible: true, width: "0.6*"},
        {header: "Fill#", isVisible: true, width: "0.6*"},
        {header: "T.Rx Qty Rem", isVisible: true, width: "1.1*"},
        {header: "Insurance", isVisible: true, width: "1.4*"},
        {header: "Qty Disp", isVisible: true, width: "1.1*"},
        {header: "S.Days", isVisible: true, width: "0.6*"},
        {header: "Ord Date", isVisible: true, width: "1*"},
        {header: "Patient Name", isVisible: true, width: "1.8*"},
        {header: "Drug Name", isVisible: true, width: "2*"},
        {header: "Prescriber Name", isVisible: true, width: "2*"}
    ];

    rxByInsuPopupListWJ = [
        {header: "Ins.Code", isVisible: true, width: 148},
        {header: "Ins.Name", isVisible: true, width: 220},
        {header: "BIN", isVisible: true, width: 148},
        {header: "Processor Ctrl#", isVisible: true, width: 148},
        {header: "Pharmacy Id", isVisible: true, width: 148},
        {header: "Address", isVisible: true, width: 148},
        {header: "Phone#", isVisible: true, width: 145}
    ];

    rxByInsuPayorPopupListWJ = [
        {header: "Payor Ins ID", isVisible: true, width: "*"},
        {header: "Payor Name", isVisible: true, width: "*"},
        {header: "Contact Name", isVisible: true, width: "*"},
        {header: "Address", isVisible: true, width: "*"},
        {header: "City", isVisible: true, width: "*"},
        {header: "State", isVisible: true, width: "*"},
        {header: "Zip", isVisible: true, width: "*"},
        {header: "Phone#", isVisible: true, width: "*"},
        {header: "Fax#", isVisible: true, width: "*"},
        {header: "Notes", isVisible: true, width: "*"}
    ];

    scheduledTaskListWJ = [
        {header: "Task", isVisible: true, width: "5*"},
        {header: "Task Name", isVisible: true, width: "1.4*"},
        {header: "Task Description", isVisible: true, width: "2.5*"},
        {header: "Task Perform", isVisible: true, width: "1.1*"},
        {header: "Start Date", isVisible: true, width: "0.8*"},
        {header: "Start Time", isVisible: true, width: "0.8*"},
        // {header: "Email", isVisible: true, width: 50},
        {header: "Save File", isVisible: true, width: "0.7*"},
        {header: "Enable", isVisible: true, width: "1*"},
        // {header: "Email Address", isVisible: true, width: 290},
        {header: "Last Executed", isVisible: true, width: "1.9*"}
    ];

    recallDeletedRxListWJ = [
        {header: "Rx#", isVisible: true, width: "0.8*"},
        {header: "Ref#", isVisible: true, width: "0.6*"},
        {header: "Fill#", isVisible: true, width: "0.6*"},
        {header: "Date Filled", isVisible: true, width: "1*"},
        {header: "Deleted Date", isVisible: true, width: "1.5*"},
        {header: "Deleted By", isVisible: true, width: "1.5*"},
        {header: "Patient Name", isVisible: true, width: "1.7*"},
        {header: "Ins. type", isVisible: true, width: "0.9*"},
        {header: "Drug Name", isVisible: true, width: "2.3*"},
        {header: "Quantity", isVisible: true, width: "0.8*"},
        {header: "Days", isVisible: true, width: "0.7*"},
        {header: "Total Rx Amount", isVisible: true, width: "1.5*"},
        {header: "RPH", isVisible: true, width: "1.5*"}
    ];

    drugPriceUpdateListWJ = [
        {header: "Date Updated", isVisible: true, width: "1.2*"},
        {header: "New Price", isVisibile: true, width: "1.2*"},
        {header: "Old Price", isVisibile: true, width: "1.2*"},
        {header: "Type", isVisibile: true, width: "1.2*"},
        {header: "Manuf. Update Date", isVisibile: true, width: "1.2*"},
        {header: "Updated By", isVisibile: true, width: "1.2*"}
    ];

    erxChangeReqDURListWJ = [
        {header: "Reason of Service", isVisible: true, width: 68},
        {header: "Professional Service", isVisible: true, width: 150},
        {header: "Result of Service", isVisible: true, width: 68},
        {header: "Level", isVisible: true, width: 68},
        {header: "Id Q", isVisible: true, width: 68},
        {header: "Agent Id", isVisible: true, width: 68},
        {header: "Clinical Signif.Code", isVisible: true, width: 68},
        {header:  "PH-Comment", isVisible: true, width: 67}
    ];

    erxChangeReqSelectDrugListWJ = [
        {header: "Drug Name", isVisible: true, width: 191},
        {header: "Days", isVisible: true, width: 64},
        {header: "Qty", isVisible: true, width: 64},
        {header: "Refills", isVisible: true, width: 64},
        {header: "DAW", isVisible: true, width: 64},
        {header: "Sig", isVisible: true, width: 190},
    ];

    primeescPatAckListWJ = [
        {header: "Trans#", isVisible: true, width: 228},
        {header: "Date", isVisible: true, width: 228},
        {header: "Accept", isVisible: true, width: 228},
        {header: "Sig Type", isVisible: true, width: 228},
        {header: "Binary Sign", isVisible: true, width: 228},
        {header: "Event Type", isVisible: true, width: 228},
        {header: "Sign", isVisible: true, width: 231}
    ];

    primeescPatHistListWJ = [
        {header: "Date", isVisible: true, width: 114},
        {header: "Insu", isVisible: true, width: 114},
        {header: "Rxs Signed For", isVisible: true, width: 114},
        {header: "Counseling", isVisible: true, width: 114},
        {header: "Sig Type", isVisible: true, width: 114},
        {header: "Manual Group no", isVisible: true, width: 114},
        {header: "PHARM", isVisible: true, width: 114},
        {header: "INSMSGACCEPTANCE", isVisible: true, width: 114},
        {header: "PHARMSIGDATA", isVisible: true, width: 114},
        {header: "INSSIGDATA", isVisible: true, width: 114},
        {header: "PHARMSIGBIN", isVisible: true, width: 114},
        {header: "INSSIGBIN", isVisible: true, width: 114},
        {header: "DelType", isVisible: true, width: 114},
        {header: "UserInitial", isVisible: true, width: 117},
    ];

    primeescSignByDateListWJ = [
        {header: "Patient Name", isVisible: true, width: "4*"},
        {header: "Pickup Date", isVisible: true, width: "2*"},
        {header: "Rxs Signed For", isVisible: true, width: "4*"},
        {header: "Couns?", isVisible: true, width: "2*"}
    ];

    primeescSignByRxAcknListWJ = [
        {header: "Trans#", isVisible: true, width: 400},
        {header: "Date", isVisible: true, width: 400},
        {header: "Accept", isVisible: true, width: 400},
        {header: "Event Type", isVisible: true, width: 399}
    ];

    primeescSignByRxHistListWJ = [
        {header: "Rx#", isVisible: true, width: 267},
        {header: "Ref#", isVisible: true, width: 267},
        {header: "Fill#", isVisible: true, width: 267},
        {header: "Pickup Date", isVisible: true, width: 267},
        {header: "Ins", isVisible: true, width: 267},
        {header: "Couns?", isVisible: true, width: 264}
    ];

    primeescRxPickUpDetInsuWJ = [
        {header: "Ins.Code", isVisible: true, width: 112},
        {header: "Ins.Name", isVisible: true, width: 435},
        {header: "BIN", isVisible: true, width: 112},
        {header: "Processor Ctrl#", isVisible: true, width: 112},
        {header: "Pharmacy Id", isVisible: true, width: 112},
        {header: "Address", isVisible: true, width: 112},
        {header: "Phone#", isVisible: true, width: 112}
    ];

    printRxWithAttDocsListWJ = [
        {header: "Rx#", isVisible: true, width: "1*"},
        {header: "Ins Code", isVisible: true, width: "1*"},
        {header: "Patient Name", isVisible: true, width: "2*"},
        {header: "Prescriber Name", isVisible: true, width: "2*"},
        {header: "Drug Name", isVisible: true, width: "2.9*"},
        {header: "NDC", isVisible: true, width: "1.2*"},
        {header: "Document ID", isVisible: true, width: "1.2*"},
        {header: "Subcategory", isVisible: true, width: "1.8*"},
        {header: "Title", isVisible: true, width: "1.8*"},
        {header: "Scan Date", isVisible: true, width: "1*"}
    ];

    printRxWithAttDocsListWJFill = [
        {header: "Rx#", isVisible: true, width: 105},
        {header: "Ref#", isVisible: true, width: 105},
        { header: "Fill#", isVisible: true, width: 167 },
        {header: "Ins Code", isVisible: true, width: 105},
        {header: "Patient Name", isVisible: true, width: 200},
        {header: "Prescriber Name", isVisible: true, width: 200},
        {header: "Drug Name", isVisible: true, width: 290},
        {header: "NDC", isVisible: true, width: 120},
        {header: "Subcategory", isVisible: true, width: 181},
        {header: "Title", isVisible: true, width: 180},
        {header: "Scan Date", isVisible: true, width: 105}
    ];

    drugCounsellingWarningListWJ = [
        {header: "Warning", isVisible: true, width: "2.9*"},
        {header: "Gender", isVisible: true, width: "2.4*"},
        {header: "Age Start", isVisible: true, width: "2.9*"},
        {header: "Age End", isVisible: true, width: "2.9*"},
        {header: "Warn_Desc1", isVisible: true, width: "2.9*"},
        {header: "Warn_Desc2", isVisible: true, width: "2.9*"}
    ];

    drugPriceCheckHistListWJ = [
        {header: "Date", isVisible: true, width: "0.9*"},
        {header: "Rx#", isVisible: true, width: "0.7*"},
        {header: "Drug Name", isVisible: true, width: "3*"},
        {header: "NDC", isVisible: true, width: "1.1*"},
        {header: "Qty", isVisibe: true, width: "1*"},
        {header: "Price", isVisible: true, width: "1*"}
    ];

    drugPriceCheckSimilarDrugListWJ = [
        {header: "Drug Name", isVisible: true, width: "3*"},
        {header: "NDC", isVisible: true, width: "1*"},
        {header: "Dg.Mfg", isVisible: true, width: "1*"},
        {header: "Pack Size", isVisible: true, width: "1*"},
        {header: "Total Amount", isVisible: true, width: "1.5*"}
    ];

    drugPriceCheckTotalListWJ = [
        {header: "Drug Name", isVisible: true, width: "2.5*"},
        // {header: "Strength", isVisible: true, width: 232},
        // {header: "Form", isVisible: true, width: 175},
        {header: "Tot.Amt", isVisible: true, width: "1.5*"},
        {header: "Qty", isVisible: true, width: "1.2*"},
        {header: "PriceSchedule Name", isVisible: true, width: "1.7*"}
    ];

    scheduledTaskExecHistListWJ = [
        {header: "Task Name", isVisible: true, width: "3.6*"},
        {header: "Task Status", isVisible: true, width: "1.1*"},
        {header: "Start Date", isVisible: true, width: "1.1*"},
        {header: "Start Time", isVisible: true, width: "1.1*"},
        {header: "End Time", isVisible: true, width: "1.1*"},
        {header: "Job Run Type", isVisible: true, width: "1.2*"},
        {header: "Log Description", isVisible: true, width: "5*"}
    ];
    revHistoryListWj = [
        { header: "Rx#", isVisible: true, width: "0.2*" },
        { header: "Ref#", isVisible: true, width: "0.1*" },
        { header: "Fill#", isVisible: true, width: "0.1*" },
        { header: "User Initial", isVisible: true, width: "0.3*" },
        { header: "Date Time Verified", isVisible: true, width: "0.4*" },
        { header: "Bottle NDC", isVisible: true, width: "0.3*" },
        { header: "Result", isVisible: true, width: "0.3*" },
        { header: "Lot#", isVisible: true, width: "0.2*" },
        { header: "Expiration Date", isVisible: true, width: "0.3*" },
        { header: "GTIN#", isVisible: true, width: "0.3*" },
        { header: "Serial#", isVisible: true, width: "0.3*" },
        { header: "Run Date Time", isVisible: true, width: "0.3*" },
        { header: "Verified Quantity", isVisible: true, width: "0.3*" }
    ];
    otherInsuListWj = [
        {header: "Other Insurance", isVisible: true, width: 496},
        {header: "Payer Id", isVisible: true, width: 563},
        {header: "Qualifier", isVisible: true, width: 523},
    ];

    vendorInvHistListWJ = [
        {header: "Date Received", isVisible: true, width: 118},
        {header: "Drug Name", isVisible: true, width: 118},
        {header: "NDC", isVisible: true, width: 118},
        {header: "Made by", isVisible: true, width: 118},
        {header: "Vendor", isVisible: true, width: 118},
        {header: "Account", isVisible: true, width: 118},
        {header: "Qty. Received", isVisible: true, width: 118},
        {header: "Unit price", isVisible: true, width: 118},
        {header: "Pkgs. Received", isVisible: true, width: 118},
        {header: "Reference", isVisible: true, width: 118},
        {header: "PO#", isVisible: true, width: 116}
    ];
    bucketLogListWJ = [
        {header: "Bucket Code", isVisible: true, width: 97},
        {header: "Bucket Name", isVisible: true, width: 186},
        {header: "Drug NDC", isVisible: true, width: 114},
        {header: "Drug Name", isVisible: true, width: 300},
        {header: "Transaction Quantity", isVisible: true, width: 186},
        {header: "Transaction Date", isVisible: true, width: 160},
        {header: "Transaction Type", isVisible: true, width: 186},
        {header: "Transaction Name", isVisible: true, width: 186},
        {header: "Quantity In Hand", isVisible: true, width: 186},
    ];

    eRxExtraInfoListWJ = [
        {header: "Ins. Code", isVisible: true, width: 127},
        {header: "Payer Identification", isVisible: true, width: 200},
        {header: "Payer Name", isVisible: true, width: 100},
        {header: "PCN", isVisible: false, width: 112},
        {header: "IIN/BIN", isVisible: false, width: 112},
        {header: "Ins. ID", isVisible: true, width: 83},
        {header: "Card Holder Id", isVisible: true, width: 200},
        {header: "Card Holder First Name", isVisible: true, width: 200},
        {header: "Card Holder Last Name", isVisible: true, width: 200},
        {header: "Card Holder Suffix", isVisible: true, width: 200},
        {header: "Card Holder Prefix", isVisible: true, width: 200},
        {header: "Group Id", isVisible: false, width: 100},
        {header: "Payer Type", isVisible: false, width: 112},
        {header: "Group Name", isVisible: false, width: 112},

    ];

    rphVeryHistListWJ = [
        {header: "Rx#", isVisible: true, width: 200},
        {header: "Ref#", isVisible: true, width: 100},
        {header: "Fill#", isVisible: true, width: 100},
        {header: "Verified By", isVisible: true, width: 300},
        {header: "Date Time Verified", isVisible: true, width: 200},
        {header: "Status", isVisible: true, width: 269}
    ];

    drugMMSSearchList = [
        {header: "Drug Name", isVisible: true, width: 112},
        {header: "Drug NDC", isVisible: true, width: 112},
      //  {header: "Bill NDC", isVisible: true, width: 112},
        {header: "Brand Name", isVisible: true, width: 112},
        {header: "Generic Name", isVisible: true, width: 112},
        {header: "Made By", isVisible: true, width: 112},
        {header: "Drug Type", isVisible: true, width: 112},
        {header: "Strength", isVisible: true, width: 112},
        {header: "Drug Form", isVisible: true, width: 112},
        {header: "Units", isVisible: true, width: 112},
        {header: "Brand", isVisible: true, width: 112},
        {header: "Size", isVisible: true, width: 112},
        {header: "AWP Price", isVisible: true, width: 112},
        {header: "DIR Price", isVisible: true, width: 112},
        {header: "Cost Price", isVisible: true, width: 112},
    ];
    viewDatSetWj = [
        {header: "Drug Name", isVisible: true, width: 247},
        {header: "NDC", isVisible: true, width: 126},
        {header: "Pkg Size", isVisible: true, width: 105},
        {header: "Mfg", isVisible: true, width: 227},
        {header: "Class", isVisible: true, width: 73},
        {header: "AWP", isVisible: true, width: 118},
        {header: "Cost", isVisible: true, width: 143},
        {header: "Bucket Name", isVisible: true, width: 141},
        {header: "Value(AWP)", isVisible: true, width: 141},
        {header: "Value(Cost)", isVisible: true, width: 141},
        {header: "Target Qty", isVisible: false, width: 112},
        {header: "Recorder Level", isVisible: false, width: 112},
        {header: "Qty in Hand", isVisible: false, width: 112}
    ];
    InventoryTransactionAudit = [
        {header: "Trans. Type", isVisible: true, width: 180},
        {header: "Transaction Date", isVisible: true, width: 219 },
        {header: "Referenece", isVisible: true, width: 205},
        {header: "Bucket", isVisible: true, width: 280},
        {header: "Trans Qty", isVisible: true, width: 138},
    ];
    refillQueueWJ  = [
        {header: "Rx#", isVisible: true, width: 75},
        {header: "Date Filled", isVisible: true, width: 100 },
        {header: "Ref#", isVisible: true, width: 75},
        {header: "Fill#", isVisible: true, width: 75},
        {header: "Auth#", isVisible: true, width: 75},
        {header: "Insurance", isVisible: true, width: 170},
        {header: "Qty", isVisible: true, width: 80},
        {header: "S.Days", isVisible: true, width: 80},
        {header: "Date Ord", isVisible: true, width: 100},
        {header: "Patient Name", isVisible: true, width: 164},
        {header: "Refill Status", isVisible: true, width: 107},
        {header: "Drug Name", isVisible: true, width: 211},
        {header: "Prescriber Name", isVisible: true, width: 162},
    ];
    insuranceListWJ  = [
        {header: "Ins Code", isVisible: true, width: 120},
        {header: "Insurance Name", isVisible: true, width: 220},
        {header: "Insurance Id#", isVisible: true, width: 220},
        {header: "Bill Order", isVisible: true, width: 97},
        {header: "BIN", isVisible: true, width: 200},
        {header: "PCN", isVisible: true, width: 200},
    ];
    reviewRxHistwj = [
        {header: "Rx#", isVisible: true, width: 168},
        {header: "Ref#", isVisible: true, width: 184},
        {header: "Fill#", isVisible: true, width: 172},
        {header: "Old Tag Name", isVisible: true, width: 166},
        {header: "New Tag Name", isVisible: true, width: 162},
        {header: "Modified By", isVisible: true, width: 152},
        {header: "Modified Date Time", isVisible: true, width: 165}
    ];
    primeEDIPriceUpdateHistListWJ = [
        {header: "File ID", isVisible: true, width: "2.1*"},
        {header: "Last Updated Date", isVisible: true, width: "2.5*"},
        {header: "Records", isVisible: true, width: "2*"},
        {header: "Drugs Updated", isVisible: true, width: "3*"},
        {header: "Vendor Name", isVisible: true, width: "2.5*"},
        {header: "Account", isVisible: true, width: "2.5*"},
    ];
    BenefitCheckCoverageInfoQueueWj = [
        {header: "Drug Type", isVisible: true, width: 230},
        {header: "Drug Name", isVisible: true, width: 277},
        {header: "30 Days", isVisible: true, width: 250},
        {header: "45 Days", isVisible: true, width: 210},
        {header: "90 Days", isVisible: true, width: 210},
        {header: "PA Req?", isVisible: true, width: 100}
    ];
    refillFlowComponent = [
        {header: "Rx#", isVisible: true, width: 75},
        {header: "Ref#", isVisible: true, width: 50 },
        {header: "Auth#", isVisible: true, width: 50},
        {header: "Fill#", isVisible: true, width: 50},
        {header: "Fill Date", isVisible: true, width: 100},
        {header: "Status", isVisible: true, width: 70},
        {header: "NDC",isVisible : true, width: 100},
        {header: "Drug Name", isVisible: true, width: 220},
        {header: "Qty", isVisible: true, width: 80},
        {header: "Days", isVisible: true, width: 65},
        {header: "Prescriber", isVisible: true, width: 200},
        {header: "T.Rx Qty Rem", isVisible: true, width: 50},
    ];
    drugOtherPriceswj = [
        {header: "Price Type", isVisible: true, width: "*"},
        {header: "Unit Price", isVisible: true, width: "0.5*"},
        {header: "Updated By", isVisible: true, width: "*"},
        {header: "Last Updated Date Time", isVisible: true, width: "*"}
    ];
    fileRecordsWJ = [
        {header: "Fill#", isVisible: true, width: "0.9*"},
        {header: "Ref#", isVisible: true, width: "0.9*"},
        {header: "Fill Date", isVisible: true, width: "1.7*"},
        {header: "Total Qty Disp.", isVisible: true, width: "1.6*"},
        {header: "Qty Disp.", isVisible: true, width: "1.5*"},
        {header: "Total Rx Qty Rem", isVisible: true, width: "1.7*"},
        {header: "Days", isVisible: true, width: "1*"},
        {header: "Total Ingr. Cost", isVisible: true, width: "2*"},
        {header: "Ingredient Cost", isVisible: true, width: "1.7*"},
        {header: "Dispensing Fee", isVisible: true, width: "1.7*"},
        {header: "Usual and Custom", isVisible: true, width: "2.2*"}
    ]
    cntrlSettingsWj = [
        {header: "State", isVisible: true, width: "1*"},
        {header: "Version", isVisible: true, width: "1*"},
        {header: "File Type", isVisible: true, width: "1.5*"},
        {header: "Gen. Control File By", isVisible: true, width: "2*"},
        {header: "IsActive?", isVisible: true, width: "1*"}
    ]
    insuranceToBillWj = [
        {header: "Ins Code", isVisible: true, width: 170},
        {header: "Insurance Name", isVisible: true, width: 480},
        {header: "Insurance#", isVisible: true, width: 155},
        {header: "Bill Order", isVisible: true, width: 150},
        {header: "BIN", isVisible: true, width: 90},
        {header: "PCN", isVisible: true, width: 90}
    ]
    patientSpecificWj = [
        {header: "Last Name", isVisible: true, width: 230},
        {header: "First Name", isVisible: true, width: 230},
        {header: "License#", isVisible: true, width: 110},
        {header: "DEA#", isVisible: true, width: 110},
        {header: "Phone#", isVisible: true, width: 150},
        {header: "Address", isVisible: true, width: 335}
    ]
    patientMedAdherenceWj = [
        {header: "Patient Name", isVisible: true, width: 180},
        {header: "Age", isVisible: true, width: 110},
        {header: "Insurance", isVisible: true, width: 140},
        {header: "Category Code", isVisible: true, width: 140},
        {header: "Facility", isVisible: true, width: 140}
    ]
    equivalentDrugWj = [
        {header: "Drug Name", isVisible: true, width: 214},
        {header: "NDC", isVisible: true, width: 98},
        {header: "Dg. Mfg", isVisible: true, width: 104},
        {header: "Branded", isVisible: true, width: 60},
        {header: "Pkg. Size", isVisible: true, width: 70},
        {header: "AWP", isVisible: true, width: 52},
        {header: "Inventory", isVisible: true, width: 81},
        {header: "Inv. In Hand", isVisible: true, width: 70},
        {header: "Check Qty", isVisible: true, width: 68},
        {header: "Last Dispensed Date", isVisible: true, width: 85},
        {header: "Preferred", isVisible: true, width: 70},
        {header: "Brand Name", isVisible: true, width: 80},
        {header: "Generic Name", isVisible: true, width: 160},
        {header: "Specfic Product Id", isVisible: true, width: 70},
        {header: "Therapeutic", isVisible: true, width: 82},
        {header: "Unit Price Cost", isVisible: true, width: 62},
        {header: "Last Vendor", isVisible: true, width: 70},
    ]
    rxTransmissionLogWJ = [
        {header: "Rx#", isVisible: true, width: 70},
        {header: "Ref#", isVisible: true, width: 50},
        {header: "Fill#", isVisible: true, width: 50},
        {header: "Result", isVisible: true, width: 75},
        {header: "Transm. Date", isVisible: true, width: 190},
        {header: "Ins. Code", isVisible: true, width: 130},
        {header: "Trans.", isVisible: true, width: 70},
        {header: "User", isVisible: true, width: 75},
        {header: "Drug NDC", isVisible: true, width: 120}
    ]
    printRefReqWJ = [
        {header: "From Name", isVisible: true, width: 275},
        {header: "To Name", isVisible: true, width: 275},
        {header: "To Fax#", isVisible: true, width: 185},
        {header: "Rx#", isVisible: true, width: 115},
        {header: "Ref#", isVisible: true, width: 70},
        {header: "Fill#", isVisible: true, width: 70},
        {header: "Rx Origin", isVisible: true, width: 150}
    ]
    printbatchedRxsRefReqWJ = [
        {header: "From Name", isVisible: true, width: 275},
        {header: "To Name", isVisible: true, width: 275},
        {header: "To Fax#", isVisible: true, width: 150},
        {header: "Rx#", isVisible: true, width: 110},
        {header: "Ref#", isVisible: true, width: 75},
        {header: "Fill#", isVisible: true, width: 65},
        {header: "Fax Date", isVisible: true, width: 100},
        {header: "Time Info", isVisible: true, width: 90}
    ]
    quickRefillWJ = [
        {header: "Refill Status", isVisible: true, width: "1.2*"},
        {header: "Patient Name", isVisible: true, width: "1.4*"},
        {header: "Rx#", isVisible: true, width: "1*"},
        {header: "Auth#", isVisible: true, width: "0.7*"},
        {header: "Ref#", isVisible: true, width: "0.7*"},
        {header: "Fill#", isVisible: true, width: "0.7*"},
        {header: "T.Rx Qty Rem", isVisible: true, width: "1.1*"},
        {header: "Insurance Name", isVisible: true, width: "1.4*"},
        {header: "Drug Name", isVisible: true, width: "2*"},
        {header: "Qty Disp", isVisible: true, width: "1.1*"},
        {header: "Fill Date", isVisible: true, width: "0.8*"},
        {header: "Price", isVisible: true, width: "0.6*"},
        {header: "Copay", isVisible: true, width: "0.6*"},
        {header: "Prescriber Name", isVisible: true, width: "2*"},

    ]
    faxbatchedRxsRefReqWJ = [
        {header: "From Name", isVisible: true, width: "1.7*"},
        {header: "To Name", isVisible: true, width: "1.7*"},
        {header: "To Fax#", isVisible: true, width: "1*"},
        {header: "Rx#", isVisible: true, width: "0.7*"},
        {header: "Ref#", isVisible: true, width: "0.4*"},
        {header: "Fax Date", isVisible: true, width: "0.8*"},
        {header: "Time Info", isVisible: true, width: "0.9*"}
    ]
    faxRefReqWJ = [
        {header: "From Name", isVisible: true, width: "2*"},
        {header: "To Name", isVisible: true, width: "2*"},
        {header: "To Fax#", isVisible: true, width: "1.5*"},
        {header: "Rx#", isVisible: true, width: "1*"},
        {header: "Rx Origin", isVisible: true, width: "1*"}
    ]

    controlResubmitListWJ = [
        {header: "Rx#", isVisible: true, width: 60},
        {header: "Date Filled", isVisible: true, width: 100},
        {header: "Ref#", isVisible: true, width: 40},
        {header: "Fill#", isVisible: true, width: 40},
        {header: "Insurance", isVisible: true, width: 87},
        {header: "Qty", isVisible: true, width: 40},
        {header: "Supply Days", isVisible: true, width: 60},
        {header: "Date Ordered", isVisible: true, width: 100},
        {header: "Auth#", isVisible: true, width: 40},
        {header: "Patient Name", isVisible: true, width: 145},
        {header: "Drug Name", isVisible: true, width: 120},
        {header: "Prescriber Name", isVisible: true, width: 120},
        {header: "Pickup Date", isVisible: true, width: 100}
    ];

    controlgenerateListWJ = [
        {header: "Rx#", isVisible: true, width: 60},
        {header: "Date Filled", isVisible: true, width: 100},
        {header: "Ref#", isVisible: true, width: 40},
        {header: "Fill#", isVisible: true, width: 40},
        {header: "Insurance", isVisible: true, width: 87},
        {header: "Qty", isVisible: true, width: 59},
        {header: "Supply Days", isVisible: true, width: 74},
        {header: "Date Ordered", isVisible: true, width: 100},
        {header: "Auth#", isVisible: true, width: 56},
        {header: "Patient Name", isVisible: true, width: 174},
        {header: "Drug Name", isVisible: true, width: 120},
        {header: "Prescriber Name", isVisible: true, width: 125},
        {header: "Status", isVisible: true, width: 100},
        {header: "Pickup Date", isVisible: true, width: 110}
    ];

    rxBasicDisplayListWJ = [
        {header: "Rx#", isVisible: true, width: 60},
        {header: "Date Filled", isVisible: true, width: 100},
        {header: "Ref#", isVisible: true, width: 40},
        {header: "Fill#", isVisible: true, width: 40},
        {header: "Insurance", isVisible: true, width: 87},
        {header: "Qty", isVisible: true, width: 40},
        {header: "Supply Days", isVisible: true, width: 101},
        {header: "Date Ordered", isVisible: true, width: 100},
        {header: "Auth#", isVisible: true, width: 64},
        {header: "Patient Name", isVisible: true, width: 174},
        {header: "Drug Name", isVisible: true, width: 120},
        {header: "Prescriber Name", isVisible: true, width: 127},
        {header: "Pickup Date", isVisible: true, width: 100}
    ];
    labelQueueDisplayListWJ = [
        {header: "Rx#", isVisible: true, width: 60},
        {header: "Ref#", isVisible: true, width: 40},
        {header: "Fill#", isVisible: true, width: 40},
        {header: "Patient Name", isVisible: true, width: 150},
        {header: "Date Queued", isVisible: true, width: 95},
        {header: "Queued By", isVisible: true, width: 95},
        {header: "Drug Name", isVisible: true, width: 120},
        {header: "NDC", isVisible: true, width: 131},
        {header: "Qty", isVisible: true, width: 68},
        {header: "Days", isVisible: true, width: 55},
        {header: "Prescriber Name", isVisible: true, width: 150},
        {header: "Copay", isVisible: true, width: 96},
        {header: "Status", isVisible: true, width: 50},
        {header: "Print Count from Queue", isVisible: true, width: 101},
        {header: "Printed Previously?", isVisible: true, width: 101},
        {header: "Sig", isVisible: true, width: 64},
        {header: "Notes", isVisible: true, width: 100},
        {header: "Delivery", isVisible: true, width: 64},
        {header: "Facility CD", isVisible: true, width: 86},
        {header: "Patient Address", isVisible: true, width: 180},
        {header: "Patient Phone", isVisible: true, width: 150},
        {header: "Patient Email", isVisible: true, width: 150},
        {header: "Fill Date", isVisible: true, width: 95},
        {header: "Order Date", isVisible: true, width: 95},
        {header: "Patient Mobile", isVisible: true, width: 131},
        {header: "Patient Insurance", isVisible: true, width: 130},
        {header: "Del Status", isVisible: true, width: 92},
        {header: "Ins", isVisible: false, width: 120},
        {header: "Full Address", isVisible: false, width: 120},
        {header: "Time In Queue", isVisible: false, width: 113},
        {header: "Printed From Queue?", isVisible: false, width: 148},
        {header: "Nrefill", isVisible: false, width: 64},
        {header: "Drug Lot#", isVisible: false, width: 140},
        {header: "Drug Expiration Date", isVisible: false, width: 166}
    ];
    topPerfomersPatientListWj = [
        {header: "Patient Name", isVisible: true, width: "1.6*"},
        {header: "Address", isVisible: true, width: "2*"},
        {header: "Rx Count", isVisible: true, width: "1.2*"},
        {header: "Refill Count", isVisible: true, width: "1.3*"},
        {header: "Fill Count", isVisible: true, width: "1.2*"},
        {header: "Total Rx Amount", isVisible: true, width: "2.2*"},
        {header: "Total Copay", isVisible: true, width: "2.1*"},
        {header: "Total Cost", isVisible: true, width: "1.8*"},
        {header: "Total Profit", isVisible: true, width: "1.7*"},
    ];
    topPerfomersInsideListWj = [
        {header: "RX#", isVisible: true, width: "1.2*"},
        {header: "Ref#", isVisible: true, width: "0.7*"},
        {header: "Fill#", isVisible: true, width: "1.1*"},
        {header: "NDC", isVisible: true, width: "1.2*"},
        {header: "Qty", isVisible: true, width: "1*"},
        {header: "Supply Days", isVisible: true, width: "1.1*"},
        {header: "Patient Name", isVisible: true, width: "1.7*"},
        {header: "Prescriber Name", isVisible: true, width: "1.8*"},
        {header: "Insurance Name", isVisible: true, width: "2*"},
        {header: "Drug Name", isVisible: true, width: "2.1*"},
        {header: "Total Rx Amount", isVisible: true, width: "1.4*"},
    ];
    topPerfomersPrescriberListWj = [
        {header: "Prescriber Name", isVisible: true, width: "1.3*"},
        {header: "PresLNm", isVisible: true, width: "1.1*"},
        {header: "PresFNm", isVisible: true, width: "1.1*"},
        {header: "PresType", isVisible: true, width: "1.1*"},
        {header: "Rx Count", isVisible: true, width: "1.3*"},
        {header: "Refill Count", isVisible: true, width: "1.4*"},
        {header: "Fill Count", isVisible: true, width: "1.3*"},
        {header: "Total Rx Amount", isVisible: true, width: "1.9*"},
        {header: "Total Copay", isVisible: true, width: "1.8*"},
        {header: "Total Cost", isVisible: true, width: "1.8**"},
        {header: "Total Profit", isVisible: true, width: "1.7*"},
    ];
    topPerfomersDrugListWj = [
        {header: "Drug Name", isVisible: true, width: "1.8*"},
        {header: "Form", isVisible: true, width: "0.5*"},
        {header: "Strength", isVisible: true, width: "0.9*"},
        {header: "NDC", isVisible: true, width: "1.1*"},
        {header: "Total Qty", isVisible: true, width: "0.8*"},
        {header: "Qty In Stock", isVisible: true, width: "0.9*"},
        {header: "Avg/Month", isVisible: true, width: "0.9*"},
        {header: "Rx Count", isVisible: true, width: "1.2*"},
        {header: "Refill Count", isVisible: true, width: "0.9*"},
        {header: "Fill Count", isVisible: true, width: "1*"},
        {header: "Total Rx Amount", isVisible: true, width: "1.2*"},
        {header: "Total Copay", isVisible: true, width: "1.2*"},
        {header: "Total Cost", isVisible: true, width: "1.1*"},
        {header: "Total Profit", isVisible: true, width: "1.7*"},
    ];
    topPerfomersInsListWj = [
        {header: "Insurance Code", isVisible: true, width: "1.3*"},
        {header: "Insurance Name", isVisible: true, width: "2*"},
        {header: "Rx Count", isVisible: true, width: "1.3*"},
        {header: "Refill Count", isVisible: true, width: "1.6*"},
        {header: "Fill Count", isVisible: true, width: "1.2*"},
        {header: "Total Rx Amount", isVisible: true, width: "2.1*"},
        {header: "Total Copay", isVisible: true, width: "2.1*"},
        {header: "Total Cost", isVisible: true, width: "2*"},
        {header: "Total Profit", isVisible: true, width: "1.7*"},
    ];
    topPerfomersFacListWj = [
        {header: "Facility Code", isVisible: true, width: "2.4*"},
        {header: "Facility Name", isVisible: true, width: "1.2*"},
        {header: "Rx Count", isVisible: true, width: "1.4*"},
        {header: "Refill Count", isVisible: true, width: "1.4*"},
        {header: "Fill Count", isVisible: true, width: "1.4*"},
        {header: "Total Rx Amount", isVisible: true, width: "2.2*"},
        {header: "Total Copay", isVisible: true, width: "1.8*"},
        {header: "Total Cost", isVisible: true, width: "2.1*"},
        {header: "Total Profit", isVisible: true, width: "1.7*"},
    ];
    forGroupViewWj = [
        {header: "Patient Name", isVisible: true, width: 388},
        {header: "Patient Phone", isVisible: true, width: 339},
        {header: "Patient Mobile", isVisible: true, width: 387},
        {header: "Delivery", isVisible: true, width: 481},

    ];
    CntrlRxAuditTrailWj = [
        {header: "Rx#", isVisible: true, width: "0.7*"},
        {header: "Ref#", isVisible: true, width: "0.6*"},
        {header: "Fill#", isVisible: true, width: "0.4*"},
        {header: "Date Filled", isVisible: true, width: "1.5*"},
        {header: "Entered By", isVisible: true, width: "1.2*"},
        {header: "Changed By", isVisible: true, width: "1*"},
        {header: "Field Change", isVisible: true, width: "1.4*"},
        {header: "Original Value", isVisible: true, width: "1.3*"},
        {header: "New Value", isVisible: true, width: "1.2*"},
        {header: "Changed Date", isVisible: true, width: "1.4*"},
    ];
    automatSubWj = [
        {header: "State", isVisible: true, width: 324},
        {header: "File WebSite", isVisible: true, width: 934},
        {header: "Upload file to FTP/SFTP?", isVisible: true, width: 304},
        // {header: "Send Email?", isVisible: true, width: 281},
    ];
    viewErrorCntrlRxWj = [
        {header: "Rx#", isVisible: true, width: 60},
        {header: "Ref#", isVisible: true, width: 50},
        {header: "Fill#", isVisible: true, width: 50},
        {header: "Date Filled", isVisible: true, width: 90},
        {header: "Patient Name", isVisible: true, width: 170},
        {header: "Drug Name", isVisible: true, width: 180},
        {header: "Control Error", isVisible: true, width: 250},
        {header: "Amount", isVisible: true, width: 70},
        {header: "Billed", isVisible: true, width: 70},
        {header: "Insu Paid", isVisible: true, width: 70},
        {header: "Copay", isVisible: true, width: 70},
        {header: "Prescriber Name", isVisible: true, width: 170},
        {header: "NDC", isVisible: true, width: 126},
        {header: "Date Ordered", isVisible: true, width: 90},
        {header: "Status", isVisible: true, width: 50},
        {header: "Ins.", isVisible: true, width: 70},
        {header: "Quantity", isVisible: true, width: 60},
        {header: "Days", isVisible: true, width: 70},
    ];
    logicalAccessCtrlWj = [
        {header: "Changed By", isVisible: true, width: "1*"},
        {header: "Changed For", isVisible: true, width: "1.1*"},
        {header: "Document", isVisible: true, width: "5.3*"},
        {header: "Function", isVisible: true, width: "3.9*"},
        {header: "Change Date", isVisible: true, width: "1.5*"},
        {header: "Old Permissions", isVisible: true, width: "1.2*"},
        {header: "New Permissions", isVisible: true, width: "1.3*"},
        {header: "Operation", isVisible: true, width: "1.1*"},
    ];
    messageLogWj = [
        {header: "Patient Name", isVisible: true, width: "2.3*"},
        {header: "Mobile#", isVisible: true, width: "1*"},
        {header: "Date of Birth", isVisible: true, width: "1*"},
        {header: "Message Type", isVisible: true, width: "1.5*"},
        {header: "Message", isVisible: true, width: "3.2*"},
        {header: "Sent/Received Date Time", isVisible: true, width: "1.9*"},
        {header: "Message Status", isVisible: true, width: "1.5*"},
        {header: "Exception Details", isVisible: true, width: "2.2*"},
        {header: "Sent By", isVisible: true, width: "0.7*"},
        {header: "Notification Type", isVisible: true, width: "1.2*"},
    ];
    messageQueueWj = [
        {header: "Patient Name", isVisible: true, width: "3.9*"},
        {header: "Mobile#", isVisible: true, width: "2.2*"},
        {header: "Message Type", isVisible: true, width: "2.8*"},
        {header: "Message", isVisible: true, width: "3.6*"},
        {header: "Message Queued Date Time", isVisible: true, width: "2.8*"},
        {header: "Sent By", isVisible: true, width: "2.3*"},
    ];
    controlFileError = [
        {header: "Rx#", isVisible: true, width: 85},
        {header: "Ref#", isVisible: true, width: 50},
        {header: "Fill#", isVisible: true, width: 50},
        {header: "Date Filled", isVisible: true, width: 90},
        {header: "Patient Name", isVisible: true, width: 170},
        {header: "Drug Name", isVisible: true, width: 180},
        {header: "Resolved Date", isVisible: true, width: 100},
        {header: "Control Error", isVisible: true, width: 250},
        {header: "Amount", isVisible: true, width: 70},
        {header: "Billed", isVisible: true, width: 70},
        {header: "Insu Paid", isVisible: true, width: 70},
        {header: "Copay", isVisible: true, width: 70},
        {header: "Prescriber Name", isVisible: true, width: 170},
        {header: "NDC", isVisible: true, width: 126},
        {header: "Date Ordered", isVisible: true, width: 90},
        {header: "Status", isVisible: true, width: 50},
        {header: "Ins.", isVisible: true, width: 70},
        {header: "Qty", isVisible: true, width: 60},
        {header: "Days", isVisible: true, width: 70},
    ];
    controlFileErrorForNY = [
        {header: "Rx#", isVisible: true, width: 60},
        {header: "Ref#", isVisible: true, width: 50},
        {header: "Fill#", isVisible: true, width: 50},
        {header: "Patient Name", isVisible: true, width: 170},
        {header: "Drug Name", isVisible: true, width: 180},
        {header: "NDC", isVisible: true, width: 126},
        {header: "Prescriber Name", isVisible: true, width: 170},
        {header: "Ins.", isVisible: true, width: 70},
        {header: "Error Code", isVisible: true, width: 170},
        {header: "Error Description", isVisible: true, width: 180},
        {header: "Value Provided", isVisible: true, width: 126},
        {header: "Comment", isVisible: true, width: 170},
        {header: "Submitted Date", isVisible: true, width: 70},
        {header: "Fill Date", isVisible: true, width: 70},
    ]

    refillDueMsgOuterWj = [
        // {header: "patientNo", isVisible: true, width: 141},
        {header: "Patient Name", isVisible: true, width: 217},
        {header: "Language", isVisible: true, width: 85},
        {header: "Email", isVisible: true, width: 228},
        {header: "Date of Birth", isVisible: true, width: 98},
        // {header: "Phone#", isVisible: true, width: 141},
        {header: "Mobile#", isVisible: true, width: 115},
        // {header: "Sms Carrier", isVisible: true, width: 141},
         {header: "Gender", isVisible: true, width: 66},
        {header: "Insurance", isVisible: true, width: 132},
        {header: "Patient Address", isVisible: true, width: 324},
        {header: "Is Not Pickup Remainder", isVisible: true, width: 166},
        // {header: "Is Not Pickup SMS", isVisible: true, width: 50},
        // {header: "Is send Del Ship Batch Message", isVisible: true, width: 50},
        // {header: "Is send Del Ship Batch Email", isVisible: true, width: 50},
        // {header: "Is send Del Ship Batch Phone call", isVisible: true, width: 50},
        // {header: "Is send Del Ship Batch SMS", isVisible: true, width: 50},
        // {header: "Send Pickup Remainder when Billed", isVisible: true, width: 50},
        // {header: "Allowed for Facility", isVisible: true, width: 50},
    ];
    refillDueMsgInnerWj = [
        // {header: "patientNo", isVisible: true, width: 141},
        {header: "Rx#", isVisible: true, width: 74},
        {header: "Ref#", isVisible: true, width: 64},
        {header: "Fill#", isVisible: true, width: 53},
        {header: "Auth#", isVisible: true, width: 74},
        {header: "Date Filled", isVisible: true, width: 97},
        {header: "Quantity", isVisible: true, width: 89},
        {header: "Drug Name", isVisible: true, width: 243},
        {header: "Patient Name", isVisible: true, width: 129},
        {header: "IsActive?", isVisible: true, width: 74},
        {header: "Prescriber Name", isVisible: true, width: 167},
        {header: "Primary Insurance", isVisible: true, width: 140},
        {header: "Total Cost", isVisible: true, width: 97},
        {header: "Total Rx Amount", isVisible: true, width: 150},
        {header: "Pickup Due Date", isVisible: false, width: 74},
        {header: "Total Ins Paid", isVisible: false, width: 74},
        {header: "Profit", isVisible: false, width: 74},
        {header: "Rx Type", isVisible: false, width: 74},
        {header: "Delivery Method", isVisible: false, width: 74},
    ];
    refillDueMsgIndividualWj = [
        {header: "Rx#", isVisible: true, width: 74},
        {header: "Ref#", isVisible: true, width: 64},
        {header: "Fill#", isVisible: true, width: 53},
        {header: "Auth#", isVisible: true, width: 74},
        {header: "Date Filled", isVisible: true, width: 97},
        {header: "Quantity", isVisible: true, width: 89},
        {header: "Drug Name", isVisible: true, width: 243},
        {header: "Patient Name", isVisible: true, width: 129},
        {header: "IsActive?", isVisible: true, width: 74},
        {header: "Prescriber Name", isVisible: true, width: 167},
        {header: "Primary Insurance", isVisible: true, width: 140},
        {header: "Total Cost", isVisible: true, width: 97},
        {header: "Total Rx Amount", isVisible: true, width: 150},
        {header: "Date of Birth", isVisible: true, width: 74},
        {header: "Mobile#", isVisible: true, width: 74},
        {header: "Pickup Due Date", isVisible: false, width: 74},
        {header: "Total Ins Paid", isVisible: false, width: 74},
        {header: "Profit", isVisible: false, width: 74},
        {header: "Rx Type", isVisible: false, width: 74},
        {header: "Delivery Method", isVisible: false, width: 74},
    ];
    geographyDistributionWj = [
        {header:  "Zip", isVisible: true, width: "0.7*"},
        {header: "City", isVisible: true, width: "1.5*"},
        {header: "Patient#", isVisible: true, width: "0.8*"},
        {header: "%Patients", isVisible: true, width: "0.8*"},
    ];
    drugThearpauticWj = [
        {header:  "Concept", isVisible: true, width: "2.5*"},
        {header: "Drug#", isVisible: true, width: "0.6*"},
        {header: "Rx#", isVisible: true, width: "0.6*"}
    ];
    reverseClaim = [
        {header:  "Rx#", isVisible: true, width: 75},
        {header: "Ref#", isVisible: true, width: 45},
        {header: "Fill#", isVisible: true, width: 45},
        {header: "Rec Type", isVisible: true, width: 100},
        {header: "Cov", isVisible: true, width: 50},
        {header: "Ins.Code", isVisible: true, width: 100},
        {header: "Date", isVisible: true, width: 170},
        {header: "OTHPYRPDQ", isVisible: true, width: 175},
        {header: "Ingr.Cost Paid", isVisible: true, width: 100},
        {header: "Fee Paid", isVisible: true, width: 100},
        {header: "Pat Pay", isVisible: true, width: 100},
        {header: "FSTAXAMT", isVisible: true, width: 100},
        {header: "PSTAXAMT", isVisible: false, width: 100},
        {header: "PSTAXRATE", isVisible: false, width: 100},
        {header: "INCAMTPAID", isVisible: false, width: 100},
        {header: "PSFEEPAID", isVisible: false, width: 100},
        {header: "OTHAMTPAID", isVisible: false, width: 100},
        {header: "OthPayRecog", isVisible: false, width: 100},
        {header: "NetPaid", isVisible: false, width: 100},
        {header: "OTHPAYQ1", isVisible: false, width: 100},
        {header: "OTHPAYAMT1", isVisible: false, width: 100},
        {header: "OTHPAYQ2", isVisible: false, width: 100},
        {header: "OTHPAYAMT2", isVisible: false, width: 100},
        {header: "OTHPAYQ3", isVisible: false, width: 100},
        {header: "OTHPAYAMT3", isVisible: false, width: 100},
        {header: "TAXEXEMPT", isVisible: false, width: 100},
        {header: "BASISOFREI", isVisible: false, width: 100},
        {header: "BOC_DFEE", isVisible: false, width: 100},
        {header: "BOC_COPAY", isVisible: false, width: 100},
        {header: "BOC_FSTAX", isVisible: false, width: 100},
        {header: "BOC_PSTAX", isVisible: false, width: 100},
        {header: "REJCODES", isVisible: false, width: 100},
        {header: "AMT_PREDED", isVisible: false, width: 100},
        {header: "AMT_SALETX", isVisible: false, width: 100},
        {header: "AMT_COPINS", isVisible: false, width: 100},
        {header: "AMT_PRODSL", isVisible: false, width: 100},
        {header: "AMT_EXCMAX", isVisible: false, width: 100},
        {header: "INSNETPAID", isVisible: false, width: 100},
        {header: "PSTaxBasisPaid", isVisible: false, width: 100},
        {header: "AccumDedAmount", isVisible: false, width: 100},
        {header: "RemDedAmount", isVisible: false, width: 100},
        {header: "RemBenifitAmount", isVisible: false, width: 100},
        {header: "AmtProcFee", isVisible: false, width: 100},
        {header: "PatSTaxAmt", isVisible: false, width: 100},
        {header: "AmtCoIns", isVisible: false, width: 100},
        {header: "BOC_CoIns", isVisible: false, width: 100},
        {header: "EstGen", isVisible: false, width: 100},
        {header: "SpendAccAmtRem", isVisible: false, width: 100},
        {header: "HPIanFundAmt", isVisible: false, width: 100},
        {header: "SpendAccAmtRem", isVisible: false, width: 100},
        {header: "AmtProdSelBrand", isVisible: false, width: 100},
        {header: "AmtProvNetSel", isVisible: false, width: 100},
        {header: "AmtProdSelNPForm", isVisible: false, width: 100},
        {header: "AmtProdSelNPFormB", isVisible: false, width: 100},
        {header: "AmtCovGap", isVisible: false, width: 100},
        {header: "IngCostContRAmt", isVisible: false, width: 100},
        {header: "DFeeContRAmt", isVisible: false, width: 100},
        {header: "COBBillSeq", isVisible: false, width: 100},
        {header: "InsNetPaid", isVisible: false, width: 100}
    ];
    inventoryListwj = [
        {header: "Bucket Name", isVisible: false, width: 144},
        {header: "Qty", isVisible: false, width: 127},
        {header: "Bucket Type", isVisible: false, width: 203},
        {header: "Last Vendor", isVisible: false, width: 100},
        {header: "Cost", isVisible: false, width: 83},
        {header: "Last Received Date", isVisible: false, width: 173},
        {header: "Qty On Order", isVisible: false, width: 100}
    ];
    insuaranceListeRxListwj = [
        {header: "IsPrimary", isVisible: true, width: 144},
        {header: "Ins. Code", isVisible: true, width: 127},
        {header: "PCN", isVisible: true, width: 203},
        {header: "BIN", isVisible: true, width: 100},
        {header: "Ins. ID", isVisible: true, width: 83},
        {header: "Group#", isVisible: true, width: 173},
        {header: "C.H. First Name", isVisible: true, width: 100},
        {header: "C.H. Last Name", isVisible: true, width: 100},
        {header: "C.H. DOB", isVisible: true, width: 100},
        {header: "Relation", isVisible: true, width: 100}
    ];
    insuExceptionListWJ=[
        {header: "Rx#", isVisible: true, width: 92},
        {header: "Ref#", isVisible: true, width: 47},
        {header: "Fill#", isVisible: true, width: 47},
        {header: "Date Filled", isVisible: true, width: 200},
        {header: "Ins Type", isVisible: true, width: 90},
        {header: "Patient", isVisible: true, width: 144},
        {header: "Drug", isVisible: true, width: 240},
        {header: "Drug NDC", isVisible: true, width: 114},
        {header: "Qty", isVisible: true, width: 90},
        {header: "Status", isVisible: true, width: 90},
        {header: "Cost", isVisible: true, width: 85},
        {header: "Amt Billed", isVisible: false, width: 85},
        {header: "Ins Paid", isVisible: false, width: 90},
        {header: "Pat Paid", isVisible: false, width: 90},
        {header: "Total Paid", isVisible: false, width: 90},
        {header: "Profit", isVisible: false, width: 90},
        {header: "Bin#", isVisible: false, width: 90},
        {header: "P.Ctrl#", isVisible: false, width: 90},
     ];
     physicalInvStatusRecWJ = [
        {header: "Bucket", isVisible: true, width: "1.6*"},
        {header: "Manage Inventory", isVisible: true, width: "1.5*"},
        {header: "View Records", isVisible: true, width: "1.6*"},
     ];
     physicalInvRecWJ = [
        {header: "Date of Entry", isVisible: true, width: 234},
        {header: "Bucket Name", isVisible: true, width: 229},
        {header: "Total Qty", isVisible: true, width: 154},
        {header: "Packs", isVisible: true, width: 247},
        {header: "Loose Qty", isVisible: true, width: 306},
     ];
     labelDesignListWJ = [
        {header: "System Template", isVisible: true, width: "1.2*"},
        {header: "Name", isVisible: true, width: "4.5*"},
        {header: "Page Size", isVisible: true, width: "3.8*"},
        {header: "Created", isVisible: true, width: "2.8*"},
        {header: "Modified", isVisible: true, width: "2.8*"},
     ];
     compoundIngListWJ = [
        {header: "DRUG NAME", isVisible: true, width: 409},
        {header: "NDC", isVisible: true, width: 138},
        {header: "QUANTITY", isVisible: true, width: 88},
        {header: "AWP", isVisible: true, width: 88},
        {header: "Price", isVisible: true, width: 100},
        {header: "LOT NO", isVisible: true, width: 97},
        {header: "EXP DATE", isVisible: true, width: 88},
        {header: "INGR. PROP%", isVisible: true, width: 105},
        {header: "COST", isVisible: true, width: 100},
        {header: "ACTIVE", isVisible: true, width: 88},
        {header: "Qty Sufficient", isVisible: true, width: 105},
        {header: "Optional", isVisible: true, width: 100},
        {header: "MOD.1", isVisible: false, width: 56},
        {header: "MOD.2", isVisible: false, width: 56},
        {header: "MOD.3", isVisible: false, width: 56},
        {header: "BASIS", isVisible: false, width: 100}
     ];
     prescriberSearchWJ=
     [
        {header: "LAST NAME",isVisible: true, width:190},
        {header:"FIRST NAME",isVisible: true, width:190},
        {header: "NPI#",isVisible: true, width:50},
        {header:"PHONE#",isVisible: true, width:50},
        {header:"FAX#",isVisible: true, width:50},
        {header: "STREET",isVisible: true, width:220},
        {header:"STATE",isVisible: true, width:50},
        {header:"CITY",isVisible: true, width:50},
        {header:"ZIP",isVisible: true, width:50},
        {header:"LICENSE#",isVisible: true, width:50},
        {header:"DEA#",isVisible: true, width:50},
        {header:"SPI#",isVisible: true, width:50},
        {header:"ACTIVE",isVisible: true, width:190},
        {header:"SERVICE LEVEL NAME",isVisible: true, width:220},
        {header:"SERVICE LEVEL",isVisible: true, width:190},
     ];
     InsuFileSearchWJ =
     [
        {header:"Ins.Code",isVisible:true, width:50},
        {header:"Ins.Name",isVisible:true, width:50},
        {header:"Address",isVisible:true, width:50},
        {header:"Phone#",isVisible:true, width:50},
        {header:"Pharmacy Id#",isVisible:true, width:50},
        {header:"Processor Ctrl",isVisible:true, width:50},
        {header:"Bin#",isVisible:true, width:50},
        {header:"Ver",isVisible:true, width:85},
        {header:"Comm.Method",isVisible:true, width:85},
        {header:"Notes",isVisible:true, width:50}
     ];
     DurValWj = [
        {header:"Reason of Service",isVisible:true, width:185},
        {header:"Professional Service",isVisible:true, width:412},
        {header:"Result of Service",isVisible:true, width:230},
        {header:"Level",isVisible:true, width:226},
        {header:"Id Q",isVisible:true, width:80},
        {header:"Agent Id",isVisible:true, width:80},
        {header:"Clinical Signif.Code",isVisible:true, width:203},
        {header:"PH-Comment",isVisible:true, width:211},
     ]
     manageInvwj = [
        {header:"Bucket",isVisible:true, width:"1.5*"},
        {header:"Transaction Date",isVisible:true, width:"2*"},
        {header:"User",isVisible:true, width:"1.5*"},
        {header:"Status",isVisible:true, width:"1*"},
     ];
     physicalInvWj =[
        {header:"Bucket",isVisible:true, width:"1.5*"},
        {header:"Transaction Date",isVisible:true, width:"1.7*"},
        {header:"Transaction Qty",isVisible:true, width:"1.6*"},
        {header:"User",isVisible:true, width:"1*"},
        {header:"Is Deleted?",isVisible:true, width:"1.6*"},

     ];
     receiveInvWj = [
        {header:"Bucket",isVisible:true, width:"1.5*"},
        {header:"Transaction Date",isVisible:true, width:"1.8*"},
        {header:"User",isVisible:true, width:"1.5*"},
        {header:"Transaction Qty",isVisible:true, width:"1.6*"},
        {header:"Is Deleted?",isVisible:true, width:"1.6*"},
     ];
     dispensedInvWj = [
        {header:"Bucket",isVisible:true, width:"1.4*"},
        {header:"Transaction Date",isVisible:true, width:"1.8*"},
        {header:"Reference Number",isVisible:true, width:"1.8*"},
        {header:"User",isVisible:true, width:"1.2*"},
        {header:"Transaction Qty",isVisible:true, width:"1.7*"},
        {header:"Type",isVisible:true, width:"1.1*"},
     ];

     DURValNewRxWJ = [
        {header: "Reason of Service", isVisible: true, width: "2*"},
        {header: "Professional Service", isVisible: true, width: "2*"},
        {header: "Result of Service", isVisible: true, width: "2*"},
        {header: "Level", isVisible: true, width: "1.2*"},
        {header: "Id Q", isVisible: true, width: "0.8*"},
        {header: "Agent Id", isVisible: true, width: "1*"},
        {header: "Clinical Signif.Code", isVisible: true, width: "2*"},
        {header: "PH-Comment", isVisible: true, width: "2*"},
     ];
     NoteHistory = [
        {header: "Created Date & Time", isVisible: true, width: 162},
        {header: "Created By", isVisible: true, width: 115},
        {header: "Notes", isVisible: true, width: 367},
     ];
     prescClinicAddListPopupWJ = [
        {header: "SPI#", isVisible: true, width: "*"},
        {header: "Address1", isVisible: true, width: "*"},
        {header: "Address2", isVisible: true, width: "*"},
        {header: "State", isVisible: true, width: "0.8*"},
        {header: "City", isVisible: true, width: "0.8*"},
        {header: "ZipCode", isVisible: true, width: "0.5*"},
        {header: "Active", isVisible: true, width: "0.5*"},
        {header: "Fax#", isVisible: true, width: "1*"},
        {header: "Telephone#", isVisible: true, width: "1*"},
    ];
    PrescTypeFileWJ = [
        {header: "Code", isVisible:true, width :'*'},
        {header: "Description", isVisible:true, width :'2*'}
    ];
    edi810SuccessfulRecords = [
        {header: "Invoice#", isVisible: true, width: 118},
        {header: "NDC#", isVisible: true, width: 152},
        {header: "Drug Name", isVisible: true, width: 253},
        {header: "Account", isVisible: true, width: 180},
        {header: "Quantity", isVisible: true, width: 79},
        {header: "Pack Size", isVisible: true, width: 81},
        {header: "Actual Qty", isVisible: true, width: 103},
        {header: "Units", isVisible: true, width: 82},
        {header: "Cost Per Pack", isVisible: true, width: 82},
        {header: "Unit Cost", isVisible: true, width: 95},
        {header: "Total Cost", isVisible: true, width: 82},
        {header: "Transaction Name", isVisible: true, width: 145},
        {header: "Item#", isVisible: true, width: 100},
        // {header: "Process Status", isVisible: true, width: 192},
        {header: "Lot#", isVisible: true, width: 90},
        {header: "Expiration Date", isVisible: true, width: 154},
        
    ];
    edi810UnSuccessfulRecords = [
        {header: "Invoice#", isVisible: true, width: 94 },
        {header: "NDC#", isVisible: true, width: 110},
        {header: "Drug Name", isVisible: true, width: 222},
        {header: "Account", isVisible: true, width: 180},
        {header: "Quantity", isVisible: true, width: 79},
        {header: "Pack Size", isVisible: true, width: 81},
        {header: "Actual Qty", isVisible: true, width: 103},
        {header: "Physical Inv", isVisible: true, width: 80},
        {header: "Units", isVisible: true, width: 82},
        {header: "Cost Per Pack", isVisible: true, width: 82},
        {header: "Unit Cost", isVisible: true, width: 82},
        {header: "Pack AWP", isVisible: true, width: 82},
        {header: "Unit AWP", isVisible: true, width: 82},
        {header: "Total Cost", isVisible: true, width: 82},
        {header: "Drug Exists?", isVisible: true, width: 80},
        {header: "Item#", isVisible: true, width: 49},
        {header: "Process Status", isVisible: true, width: 192},
        {header: "Lot#", isVisible: true, width: 79},
        {header: "Expiration Date", isVisible: true, width: 135}
    ];

    refReqPrescriberWj = [
        {header: "Last Name", isVisible: true, width: 192},
        {header: "First Name", isVisible: true, width: 192},
        {header: "Address", isVisible: true, width: 383},
        {header: "Fax#", isVisible: true, width: 105},
        {header: "Telephone#", isVisible: true, width: 105},
        {header: "License#", isVisible: true, width: 124},
        {header: "DEA#", isVisible: true, width: 89},
    ];
    drugCatoriesList = [
        {header: "Category ID", isVisible: true, width: "*"},
        {header: "Category Name", isVisible: true, width: "2*"}
    ];
    intakeQueueWjList = [
        {header: "Type", isVisible: true, width: "0.7*"},
        {header: "Rx#", isVisible: true, width: "0.5*"},
        {header: "Auth#", isVisible: true, width: "0.4*"},
        {header: "Ref#", isVisible: true, width: "0.4*"},
        {header: "Fill#", isVisible: true, width: "0.3*"},
        {header: "Status", isVisible: true, width: "0.5*"},
        {header: "Patient Name", isVisible: true, width: "1.7*"},
        {header: "Prescriber Name", isVisible: true, width: "1.7*"},
        {header: "Drug Name", isVisible: true, width: "1.7*"},
        {header: "Date", isVisible: true, width: "0.6*"},
        {header: "Insurance", isVisible: true, width: "0.7*"},
        {header: "Qty", isVisible: true, width: "0.4*"},
        {header: "Supply Days", isVisible: true, width: "0.5*"},
        // {header: "Date Ordered", isVisible: true, width: 130},
        {header: "Facility", isVisible: true, width: "0.7*"},
    ];
    fileGeneratorList = [
        {header: "Patient Name", isVisible: true, width: "1.2*"},
        {header: "Date Of Birth", isVisible: true, width: "0.5*"},
        {header: "Gender", isVisible: true, width: "0.4*"},
        {header: "Address", isVisible: true, width: "2*"}
    ];
    fileGeneratorRxList = [
        {header: "Rx#", isVisible: true, width: 125},
        {header: "Ref#", isVisible: true, width: 75},
        {header: "Fill#", isVisible: true, width: 75},
        {header: "Qty Dis", isVisible: true ,  width: 75},
        {header: "Fill Date", isVisible: true, width: 163},
        {header: "Drug Name", isVisible: true,  width: 250},
        {header: "Drug Class", isVisible: true,  width: 100},
        {header: "Sig Code", isVisible: true, width: 150},
        {header: "Prescriber Name", isVisible: true, width: 250},
        {header: "HOA", isVisible: true, width: 100},
        {header: "Sent To Packaging?", isVisible: true, width: 175},
    ];
    iouWjList = [
        {header : "NDC#", isVisible: true, width: 160},
        {header: "Drug Name", isVisible: true, width: 376},
        {header: "Actual Qty. Dispensed", isVisible: true, width: 158},
        {header: "Qty. Owed", isVisible: true, width: 142},
        {header: "Date & Time", isVisible: true, width: 256},
        // {header: "Notes/Comments", isVisible: true, width: 299},
        // {header: "Source", isVisible: true, width: 131}
    ];
    DPVVerfListWJ = [
        {header: "Drug NDC", isVisible: true, width: "0.35*"},
        {header: "Drug Name", isVisible: true, width: "0.5*"},
        // {header: "Manufacturer", isVisible: true, width: 165},
        {header: "Qty in Hand", isVisible: true, width: "0.3*"},
        {header: "Actual Qty. Dispensed", isVisible: true, width: "0.4*"},
        {header: "Lot", isVisible: true, width: "0.3*"},
        {header: "Expiration Date", isVisible: true, width: "0.4*"}
    ];
    NoteCatoriesList = [
        {header: "Category Name", isVisible: true, width: "1*"},
        {header: "System Default Subcategory", isVisible: true, width: "0.4*"},
        {header: "Subcategory Name", isVisible: true, width: "1.3*"}
        
    ];

    InvReconRepWj = [
        {header: "Drug Name", isVisible: true, width: 131},
        {header: "NDC", isVisible: true, width: 100},
        {header: "BucketName", isVisible: true, width: 80},
        {header: "Date of Last Inventory", isVisible: true, width: 85},
        {header: "#Starting Quantity of Last Inventory", isVisible: true, width: 85},
        {header: "+ Total Purchases (since last inventory)", isVisible: true, width: 85},
        {header: "- Total Dispensed (since last inventory)", isVisible: true, width: 85},
        {header: "= Calculated on hand Inventory", isVisible: true, width: 85},
        {header: "Current Actual on Hand Inventory", isVisible: true, width: 200},
        {header: "Discrepancy", isVisible: true, width: 100},
        {header: "Reason & Corrective Action", isVisible: true, width: 450}

    ];

    vaccnImmunGeneratorListWJ = [
        {header: "Rx#", isVisible: true, width: "0.4*"},
        {header: "Ref#", isVisible: true, width: "0.5*"},
        {header: "Fill#", isVisible: true, width: "0.5*"},
        {header: "Patient Name", isVisible: true, width: "2*"},
        {header: "Fill Date & Time", isVisible: true, width: "1.8*"},
        {header: "Completion Status", isVisible: true, width: "2*"},
        {header: "Vaccine Group", isVisible: true, width: "1.5*"},
        {header: "File Generated Date & Time", isVisible: true, width: "1.3*"},
    ];

    InvReconDrugWj = [
        {header: "Drug Name", isVisible: true, width: "2*"},
        {header: "NDC", isVisible: true, width: "*"},
        {header: "Drug Class", isVisible: true, width: "*"},
    ];

    customReportListWJ = [
        {header: "Report Name", isVisible: true, width: "2*"},
        {header: "Created By", isVisible: true, width: "1*"},
        {header: "Created Dt Tm", isVisible: true, width: "1*"},
        {header: "Last Modified By", isVisible: true, width: "2*"},
        {header: "Last Modified Dt Tm", isVisible: true, width: "2*"},
    ];
    deferredEquivalentsWJ = [
        {header: "Rx#", isVisible: true,  width: 87},
        {header: "Auth#", isVisible: true,  width: 45},
        {header: "Ref#", isVisible: true, width: 37},
        {header: "Fill#", isVisible: true, width: 34},
        {header: "Insurance", isVisible: true,  width: 72},
        {header: "Status", isVisible: true, width: 50},
        {header: "Drug Name", isVisible: true,  width: 260},
        {header: "Qty Dis", isVisible: true ,  width: 87},
        {header: "Days Supply", isVisible: true, width: 51},
        {header: "Fill Date", isVisible: true, width: 150},
        {header: "NDC", isVisible: true,  width: 100},
        {header: "Prescriber Name", isVisible: true, width: 259},
        {header: "Refills Left", isVisible: false, width: 49},
    ];
    deferredEqvGrpWJ = [
        {header: "Rx#", isVisible: true,  width: 87},
        {header: "Auth#", isVisible: true,  width: 45},
        {header: "Ref#", isVisible: true, width: 37},
        {header: "Fill#", isVisible: true, width: 34},
        {header: "Insurance", isVisible: true,  width: 72},
        {header: "Status", isVisible: true, width: 50},
        {header: "Drug Name", isVisible: true,  width: 260},
        {header: "Qty Dis", isVisible: true ,  width: 87},
        {header: "Days Supply", isVisible: true, width: 51},
        {header: "Fill Date", isVisible: true, width: 150},
        {header: "NDC", isVisible: true,  width: 100},
        {header: "Prescriber Name", isVisible: true, width: 259},
        {header: "Refills Left", isVisible: false, width: 49},
    ]
    prevoiusRefillsWJ = [
        {header: "Rx#", isVisible: true,  width: "1*"},
        {header:  "Ref#", isVisible: true,  width: "1*"},
        {header: "Fill#", isVisible: true, width: "1*"},
        {header: "Fill Date", isVisible: true,  width: "2*"},
        {header: "Order Qty", isVisible: true, width: "2*"},
        {header: "Disp Qty", isVisible: true,  width: "2*"},
        {header: "Days", isVisible: true ,  width: "1.5*"},
    ]
    prevoiusFillsWJ = [
        {header: "Fill#", isVisible: true, width: "1*"},
        {header: "Disp Qty", isVisible: true,  width: "2*"},
        {header: "Days", isVisible: true ,  width: "1*"},
        {header: "Ing. Cost", isVisible: true,  width: "2*"},
        {header: "U&C", isVisible: true,  width: "1*"},
        {header: "Copay", isVisible: true,  width: "1*"},
    ]
    dscsaListWJ = [
        {header: "Name", isVisible: true, width: "1*"},
        {header: "API URL", isVisible: true,  width: "1*"},
        {header: "Pharmacy Access Key", isVisible: true ,  width: "2*"},
    ]
    RxHistoryCurrentView  = [
        {header: "Rx#", isVisible: true,  width: "1*"},
        {header: "Auth#", isVisible: true,  width: "0.7*"},
        {header: "Ref#", isVisible: true, width: "0.7*"},
        {header: "Fill#", isVisible: true, width: "0.7*"},
        {header: "T.Rx Qty Rem", isVisible: true, width: "1.3*"},
        {header: "Refills Left", isVisible: true, width: "1*"},
        {header: "Insurance", isVisible: true,  width: "1*"},
        {header: "Status", isVisible: true, width: "0.7*"},
        {header: "NDC", isVisible: true,  width: "1.3*"},
        {header: "Prescriber Name", isVisible: true, width: "2*"},
        {header: "Drug Name", isVisible: true,  width: "2*"},
        {header: "Fill Date", isVisible: true, width: "1*"},
        {header: "Days Supply", isVisible: true, width: "1*"},
        {header: "Rf and Def Due Date", isVisible: true, width: "1.5*"},
        {header: "Qty Disp", isVisible: true, width: "1.3*"}
    ];
    pillCounterListWJ = [
        {header: "Interface Name", isVisible: true, width: 145},
        {header: "Machine Name", isVisible: true,  width: 176},
        {header: "IP Address", isVisible: true ,  width: 105},
        {header: "Port No.", isVisible: true,  width: 90},
        {header: "Image Port No.", isVisible: true ,  width: 90},
        {header: "Printer", isVisible: true,  width: 198},
        {header: "Active", isVisible: true ,  width: 58},
    ];
    remittanceDataSetListWJ = [
        {header: "Rx#", isVisible: true, width: 62},
        {header: "Ref#", isVisible: true, width: 45},
        {header: "Fill#", isVisible: true, width: 45},
        {header: "Ins. Code", isVisible: true, width: 84},
        {header: "Patient Name", isVisible: true, width: 180},
        {header: "Drug Name", isVisible: true, width: 200},
        {header: "Amount Billed", isVisible: true, width: 67},
        {header: "Amount Approved", isVisible: true, width: 82},
        {header: "Reimbursements", isVisible: true, width: 121},
        {header: "Diff. Amount", isVisible: true, width: 97},
        {header: "Remittance Status", isVisible: true, width: 112},
        {header: "Authorization#", isVisible: true, width: 118},
        {header: "Cheque#", isVisible: true, width: 100},
        {header: "Cheque Date", isVisible: true, width: 112},
        {header: "CARC", isVisible: true, width: 68},
        {header: "CAMA", isVisible: true, width: 68},
        {header: "Payor Name", isVisible: true, width: 220},
        {header: "Date Applied", isVisible: true, width: 125},
        {header: "Auth#", isVisible: false, width: 45},
        {header: "Bin", isVisible: false, width: 66},
        {header: "Priority", isVisible: false, width: 112},
        {header: "CAGC", isVisible: false, width: 125},
    ]

    drugSuggestListWj = [
        {header: "Drug Name", isVisible: true,  width: "3*"},
        {header: "Drug Class", isVisible: true,  width: "1.2*"},
        {header: "NDC", isVisible: true, width: "1.5*"},
        {header: "Size", isVisible: true, width: "0.8*"},
        {header: "Pck/Unit Cost", isVisible: true, width: "1.5*"},
        {header: "Pck/Unit AWP", isVisible: true, width: "1.6*"},
        {header: "Made By", isVisible: true,  width: "1.2*"},
        {header: "Qty in Hand", isVisible: true, width: "1.2*"},
        {header: "Last Disp", isVisible: true,  width: "1.2*"},
        {header: "D/C Date", isVisible: true, width: "1.2*"},
        {header: "Drug Type", isVisible: true,  width: "1.5*"},
        {header: "Brand Name", isVisible: true, width: "1.3*"},
        {header: "Generic Name", isVisible: true, width: "1.4*"},
        {header: "Brand?", isVisible: true, width: "0.7*"},
        {header: "Equ.Avl?", isVisible: true, width: "0.9*"},
        {header: "Gen.Avl?", isVisible: true, width: "0.9*"}
    ];

    drugEquListWj = [
        {header: "Drug Name", isVisible: true, width: "2.6*"},
        {header: "Drug Class", isVisible: true,  width: "1.2*"},
        {header: "NDC", isVisible: true, width: "1.6*"},
        {header: "Size", isVisible: true, width: "0.8*"},
        {header: "Pck/Unit Cost", isVisible: true, width: "1.5*"},
        {header: "Pck/Unit AWP", isVisible: true, width: "1.6*" },
        {header: "Made By", isVisible: true,  width: "1.2*"},
        {header: "Qty in Hand", isVisible: true, width: "1.2*"},
        {header: "Last Disp", isVisible: true,  width: "1.2*"},
        {header: "D/C Date", isVisible: true, width: "1.2*"},
        {header: "Drug Type", isVisible: true,  width: "1.7*"},
        {header: "Brand Name", isVisible: true, width: "1.3*"},
        {header: "Generic Name", isVisible: true, width: "1.4*"},        
        {header: "Brand?", isVisible: true, width: "0.7*"},
        {header: "Equ.Avl?", isVisible: true, width: "0.9*"},
        {header: "Gen.Avl?", isVisible: true, width: "0.9*"},
        {header: "Is Pref?", isVisible: true, width: "0.9*"},
        {header: "Is 340B", isVisible: true, width: "0.9*"},
        {header: "Inv?", isVisible: true, width: "*"},
    ];
        
    drugRequesteDetails = [
        {header: "Drug Name", isVisible: true,  width: "3*"},
        {header: "NDC", isVisible: true, width: "1.5*"},
        {header: "Brand", isVisible: true, width: "0.7*"},
        {header: "AWP", isVisible: true, width: "1.6*"},
        {header: "Cost", isVisible: true, width: "1.5*"},
        {header: "Size", isVisible: true, width: "0.8*"},
        {header: "Manufacuture", isVisible: true,  width: "1.2*"},
        {header: "Drug Class", isVisible: true,  width: "1.2*"},
        {header: "Drug Notes", isVisible: true,  width: "1.2*"},
    ];

    paQueueWj = [
        {header: "Drug Name", isVisible: true, width: "1.6*"},
        {header: "Fill Date", isVisible: true,  width: "1.2*"},
        {header: "Patient Name", isVisible: true, width: "1.6*"},
        {header: "Drug NDC", isVisible: true, width: "0.8*"},
        {header: "Qty", isVisible: true, width: "1.5*"},
        {header: "Prescriber Name", isVisible: true, width: "1.6*" },
        {header: "Ins Code (Bin/PCN)", isVisible: true,  width: "1.6*"},
        {header: "Total Amount", isVisible: true, width: "1.2*"},
        {header: "Rx#", isVisible: true,  width: "1.2*"},
        {header: "PA Vendor", isVisible: true, width: "1.5*"},
        {header: "PA#", isVisible: true,  width: "1.7*"},
        {header: "Ref#", isVisible: true, width: "1.3*"},
        {header: "Request Received On", isVisible: true, width: "1.4*"},        
        {header: "PA# Issue Date", isVisible: true, width: "0.7*"},
        {header: "Fill#", isVisible: true, width: "0.9*"},
        {header: "Rx Status", isVisible: true, width: "1.2*"},
        {header: "PA# Validity Expiry", isVisible: true, width: "1.5*"}
    ]
}
