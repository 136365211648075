<ng-template #EasyRefill let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Refill Wizard</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="eprx--block__content erx--body editpatient">
            <div class="col easy-ref--block">
                <div class="col-xs-12 col-md-12 padding-0">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <eprx-input [LabelText]="'Rx#'" [PlaceHolder]="'Search Rx'"
                                        [FormGroupName]="dummyFG" [ControlName]="'rxno'" [RxSelectId]="'searchRx'"
                                        (TriggerOnEnterValue)="getRx($event['target']?.value,'fromInput')"
                                        [InputType]="'number'" [DecimalValues]="0">
                                    </eprx-input>
                                </div>
                                <div class="col-md-4 pull-right padding_top_1rem">
                                    <button class="hotkey_success height_2_6rem"
                                        (click)="getRx(dummyFG.value['rxno'],'fromInput')" appShortcutKey
                                        [AltKey]="'a'"><span>A</span>Add</button>
                                    <button class="hotkey_success height_2_6rem" *ngIf="rxData.length"
                                        (click)="addToRefillQueue()" appShortcutKey [AltKey]="'r'"><span>R</span>Add to
                                        Refill Queue</button>
                                    <button class="inactive height_2_6rem" *ngIf="!rxData.length"><span>R</span>Add to Refill
                                        Queue</button>

                                </div>
                                <div class="seconday-search--grid col-5 text-right mt-3">
                                    <app-searchbox-global [IsFocused]="isInputFocused" [SearchFor]="'Patient'"
                                        [DisableSerchFil]="true" [DisableRadioButtons]="true" [ClearInputValue]="clearpatName"
                                        (SelectedPatient)="selectedPatientInfo($event)" [IsAddNew]="false"
                                        [NotToClearSesion]="true" (keydown.tab)="focusToFirst($event)"></app-searchbox-global>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 wizard-color-code">
                                    <span *ngIf="rxData.length > 0">
                                        <div class="row mt-0">
                                            <div class="col-12 text-right">
                                                <button class="submit print-med-guide background_orange_RGB"
                                                    (click)="toggleColorLegend()">Color Legend</button>
                                            </div>
                                        </div>
                                        <div>
                                            <wj-flex-grid  [headersVisibility]="'Column'" [selectionMode]="'None'"
                                                [itemsSource]="rxRefWizardWJ" [isReadOnly]="true"
                                                [columnPicker]="'easyRefillWizardListWJ'" id="easyRefWzd"
                                                [itemFormatter]="itemFormatter">
                                                <wj-flex-grid-column header="" [width]="80">
                                                    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
                                                        <span class="position-relative right_3px">
                                                            <input class="form-check-input" type="checkbox" id="i" name="" [(ngModel)]="allSelect"
                                                                (click)="checkAll($event?.target?.checked,item)" [checked]="allSelect" />
                                                            <label class="form-check-label" for="i"></label>
                                                        </span>
                                                    </ng-template>
                                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'"
                                                        let-item="item" let-cell="cell">
                                                        <span class="text-center"
                                                            *ngIf="['READYTOREFILL', 'Early To Refill', 'Regular', 'Discontinued',
                                                            'No More Refills Left With P.Qty',
                                                                         'Deferred Rx', 'Transferred','Deferred and Discontinued Rx', 'No More Refills Left With R.Qty'].indexOf(item['Refill Status']) > -1">
                                                            <input class="form-check-input" type="checkbox"
                                                                [id]="'easy'+item.Rx+item.RowId" name=""
                                                                [(ngModel)]="item.IsSelected"
                                                                (change)="optedRxFrRefill($event?.target?.checked, item)"
                                                                [checked]="item.IsSelected" />
                                                            <label class="form-check-label"
                                                                [for]="'easy'+item.Rx+item.RowId">
                                                            </label>

                                                            <i class="far fa-history actions" title="Patient History" (click)="PatientHist(item)"></i>

                                                        </span>
                                                        <span class="text-center"
                                                            *ngIf="['No Quantity', 'Refill Expired',
                                                                         'Expired Rx' , 'Expired and Discontinued Rx', 'Deferred and Expired Rx','Expired(User) and Discontinued Rx','Deferred and Expired(User) Rx','Expired(User)'].indexOf(item['Refill Status']) > -1">
                                                            <input class="form-check-input" type="checkbox" name=""
                                                                disabled="disabled" />
                                                            <label class="form-check-label">
                                                            </label>
                                                            <i class="far fa-history actions" title="Patient History" (click)="PatientHist(item)"></i>

                                                        </span>
                                                        <span class="text-center">
                                                            <i class="far fa-trash-alt actions danger-actve" title="Delete"
                                                            (click)="delRow2(item)"></i>
                                                        </span>
                                                    </ng-template>
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="rxheader['hName']"
                                                    [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                                    *ngFor="let rxheader of wjHeaders; let i = index;"
                                                    [width]="rxheader['width']">
                                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'"
                                                        let-item="item" let-cell="cell">
                                                        <div *ngIf="(['Qty Disp'].indexOf(rxheader['hName']) === -1) && (rxheader['hName'] !== 'T.Rx Qty Rem' && rxheader['hName'] !== 'Rx#' && rxheader['hName'] !== 'Drug Name' && rxheader['hName'] !== 'Refill Status')" [title]="((item[rxheader.hName]+'')|uppercase)">
                                                            {{item[rxheader['hName']]}}
                                                        </div>
                                                        <div *ngIf="(['Qty Disp'].indexOf(rxheader['hName']) > -1) && (rxheader['hName'] !== 'T.Rx Qty Rem' && rxheader['hName'] !== 'Rx#' && rxheader['hName'] !== 'Drug Name' && rxheader['hName'] !== 'Refill Status')" [title]="((item[rxheader.hName]+'')|uppercase)">
                                                            {{item[rxheader['hName']] | number: '1.3-3'}}
                                                        </div>
                                                        <div  *ngIf = "(rxheader['hName']=== 'T.Rx Qty Rem')">
                                                            {{item[rxheader['hName']]}}
                                                            <!--
                                                                As per EP-4459 Handling Overfill Scenarios    
                                                                 <span  (click)="openRxTotalQtyComponent(item)" [ngClass]="(item[rxheader['hName']] && (item[rxheader['hName']] !== '0.000') && (item['PartialQtyRemaining'] > 0)) ? 'total_remaining_traingle_icon' : ''">

                                                            </span> -->
                                                        </div>
                                                        <div *ngIf="(rxheader['hName'] === 'Rx#' || rxheader['hName'] === 'Drug Name')" [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver'] : rxheader['hName'] === 'Rx#' ? item['RxHoverOver'] : item['StatusHoverOver']" [placement] = "(cell.col.index>=0 && cell.col.index < 2) ? 'right' : (cell.col.index>=2 && cell.col.index <12)?'top':'left'" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title">
                                                                {{item[rxheader['hName']] }}
                                                        </div>
                                                        <div *ngIf="rxheader['hName'] === 'Refill Status'" [ngbTooltip]="item[rxheader['hName']] === 'Transferred' ? item['TransferredRxStatus'] : ''" [placement] = "(cell.col.index>=0 && cell.col.index < 2) ? 'right' : (cell.col.index>=2 && cell.col.index <12)?'top':'left'" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title">
                                    {{(item[rxheader['hName']]  == 'No More Refills Left With P.Qty' ) ? "No More Refills Left" : item[rxheader['hName']] }}
                                </div>
                                                    </ng-template>

                                                    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                                        <div [title]="((rxheader['hName'] && rxheader['hName'] === 'T.Rx Qty Rem')) ? 'Total Remaining Qty on Rx' : (rxheader['hName'] && rxheader['hName'] === 'Qty Rem P.fill') ? 'Quantity remaining on Refill' : rxheader['hName'] ">
                                                            {{ rxheader.hName }}
                                                        </div>
                                                    </ng-template>
                                                </wj-flex-grid-column>

                                            </wj-flex-grid>
                                        </div>
                                    </span>
                                </div>
                                <div class="col-12">
                                    <div class="row height_1rem margin_top_2rem">
                                        <div class="col">
                                            <div class="col-xs-12 inline-flex">
                                                <label> Total Claim : </label>
                                                <div class="label--data">
                                                    {{rxData?.length? rxData?.length : 0}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col pull-right text-right">
                                            <eprx-radio [LabelText]="''" [FormGroupName]="refTypeFG"
                                                [ControlName]="'type'"
                                                [List]="['Reg Refill(Ctrl+G)', 'Quick Refill(Ctrl+Q)']"
                                                [ValueList]="['rr', 'qr']" [IDForList]="['rr', 'qr']" [LabelForId]=""
                                                (TriggerSelectValue)="getRefillType()">
                                            </eprx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer eprx--block__content erx--body">
        <div class="text-right pull-right col-12 padding-0 editpatient">
            <div>
                <app-quick-refill *ngIf="initiateQuickRef && (isSelected && selectedRxs.length > 0)" [FrWz]="true" (IsPopUpClosed)="IsClosePopClosed()"
                    [RxInfo]="selectedRxs" [FrmScrn]="'RefWzrd'" (IsPopUpClosedFromEasyRefill)="IsClosePopClosed($event)" (CloseParent)="initiateQuickRef = false;">
                </app-quick-refill>
                <button class="hotkey_success" *ngIf="(selectedRxsFrReg?.length > 0)" (click)="initiateProcess()"
                    appShortcutKey [AltKey]="'e'"><span>E</span> Continue</button>
                <button class="inactive" *ngIf="!(selectedRxsFrReg?.length)"><span>E</span> Continue</button>
                <!---<button class="hotkey_primary" *ngIf="(isSelected && selectedRxs.length > 0) && refType !== 'quick'" (click)="initiateProcess()"  appShortcutKey [CtrlKey]="'o'"><span>O</span>
                    OK</button>-->
                <button class="hotkey_success" id="cancelButton" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>c</span>
                    Cancel</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #quickRefill let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <app-quick-refill *ngIf="initiateQuickRef && (isSelected && selectedRxs.length > 0)" [FrWz]="true" (IsPopUpClosed)="IsClosePopClosed()"
            [RxInfo]="selectedRxs" [FrmScrn]="'RefWzrd'" (IsPopUpClosedFromEasyRefill)="IsClosePopClosed($event)" (CloseParent)="initiateQuickRef = false;">
        </app-quick-refill>
    </app-custom-modal>
</ng-template>


<!-- warning only one modal -->
<ng-template #warnOnlyOne let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header> Warning!!</h4>
        <span body>
            <p>Select only one Record.</p>
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="o" (click)="c('Close click')"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<!-- warning only one modal -->
<ng-template #warnAtleastOne let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header> Warning!!</h4>
        <span body>
            <p>Select atleast one Record.</p>
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="o" (click)="c('Close click')"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #RxnotFound let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="clearValueAndFocus('RxnotFound')">
        <h4 header> Warning!!</h4>
        <span body >
            <p *ngIf="rxNotFound">Rx not found.</p>
            <p *ngIf="!rxNotFound">No refills due for the patient.</p>
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="o" (click)="clearValueAndFocus('RxnotFound')">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>



<ng-template #Rxnotoadd let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="clearValueAndFocus('Rxtoadd')">
        <h4 header> Warning!!</h4>
        <span body>
            <p>Enter Rx# to add.</p>
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="o" (click)="clearValueAndFocus('Rxtoadd')">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #Rxexists let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="clearValueAndFocus('Rxexists')">
        <h4 header> Warning!!</h4>
        <span body>
            <p>Rx's
                <span *ngFor="let showRx of showRxNos;index as i">
                    <b>{{showRx}}</b>
                    <span *ngIf="i < showRxNos.length-1">
                        <b>, </b>
                    </span>
                </span>
                already exists on the Wizard list. Total {{count}} Rx's are added.
            </p>
        </span>
        <button footer class="hotkey_primary" id="focusonRx" appShortcutKey InputKey="o"
            (click)="clearValueAndFocus('Rxexists')"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>
