import { Injectable } from "@angular/core";
import { constant } from "..";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({
    providedIn: "root"
})
export class ErxExceptionReportService {

    _erxExcpFilter$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    erxExcpFilter$: Observable<any> = this._erxExcpFilter$.asObservable();

    constructor(private _http: HttpClient) { }

    getDropDowndata() {
        return this._http.get(constant.GET_Reports_RxByPrescriber_DropDown);
    }

    erxData(data: any): any {
        return this._http.post<any[]>(
            constant.POST_ErxExceptionReport,
            data
        );
    }

    getRxFollowUpDropDown(addHdrs?) {
        if (addHdrs) {
            return this._http.get(constant.GET_Rx_Follow_Up_Tag_DropDownData,reqOptns);
        } else {
            return this._http.get(constant.GET_Rx_Follow_Up_Tag_DropDownData);
        }
    }

    saveRxFollowUpValues(rxTagInfo: any) {
        return this._http.put<any>(constant.PUT_Rx_Follow_Up_Tag, rxTagInfo);
    }

    saveExceptionReportFG(data) {
        this._erxExcpFilter$.next(data);
    }
}
