import { Injectable } from "@angular/core";
import { CommonService } from "../services";
import { City, State } from "../models";

@Injectable({
    providedIn: "root"
})

export class SysSettingsUtil {
    stateId: any;
    tenantStngPair = {
        TNT_NAME: "PharmacyName", TNT_NABP: "NABPNum", TNT_REGI: "RegistrationNum",
        TNT_DEA_REGI: "DEARegistration", TNT_STATE_SPECIFIC_ID: "StateSpecificCode", TNT_NPI_ID: "NCPDPId",
        TNT_NPI_NO: "NPINum", TNT_CONTACT_NAME: "ContPerson", TNT_PHARM_SERV_TYPE: "PharmServiceCatId",
        TNT_PHARM_EMAIL: "EmailAddress", TNT_STATE: "StateName", TNT_CITY: "CityName", TNT_ADDRESS: "AddressLine1",
        TNT_ZIP: "ZipCode", TNT_TELEPHONE: "Telephone", TNT_FAX: "Fax"
    };
    constructor(private _commonServ: CommonService) { }

    changeDropValToNumber(data) {
        const selectedCntrl = {
            // PatientSettings: ["PAT_TYPE", "PAT_LANGUAGE", "PAT_RESIDENCE"],
            RxSettings: ["DEF_DELIVERY_METHOD", "DEF_RX_ORIG_CODE", "START_NEW_RX_FROM", "DEF_GENERAL_BUCKET"],
            PharmacySettings: ["RX_NO_GEN_SYST"], DrugSettings: ["COMP_NAME_LABEL", "COMP_PRICE_CALCULATION"],
            LabelSettings: ["DEF_PRNT_LBL_SET"], TenantSettings: ["TNT_PHARM_SERV_TYPE"],
            ERxSettings: ["E-PRESCRIPTION_SCRIPT_VERSION", "DEFAULT_MESSAGE_TYPE_FILTERS", "ERX_PRINT_FORMAT",
             "DEFAULT_ERX_ACTION_LIST_SORT"],
            DmsSettings: ["ERX_PRINT_FORMAT"],
            TransmissionSettings: ["SLCT_SRVC_LVL"],
            InventorySettings: ["Default_Vendor", "Default_340B_Vendor"],
            ThreeFourtyBSettings: ["Ins_Code_340BSettings", "Price_340BSettings", "Facility_340BSettings",
            "Inv_Recv_Trans", "Inv_Ret_Trans", "Default_Subm_Clarification", "Basis_Of_Cost"],
            WorkFlowSettings:["PA_Default_Vendor"]
        };
        if (data) {
            this.formatTenantValues(data);
            Object.keys(selectedCntrl).map(stngName => {
                selectedCntrl[stngName].map(cntrl => {
                    if (data[stngName] && data[stngName].length > 0) {
                        const cntrlValue = data[stngName].find(val => val["Key"] === cntrl);
                        if (cntrlValue) {
                            if (cntrlValue.Value && !isNaN(+cntrlValue.Value)) {
                                cntrlValue.Value = +cntrlValue.Value;
                            }
                            if (cntrlValue.DefaultValues && !isNaN(+cntrlValue.DefaultValues)) {
                                cntrlValue.DefaultValues = +cntrlValue.DefaultValues;
                            }
                        }
                    }
                });
            });
        }
        return data;
    }

    formatTenantValues(stngData) {
        const tenantDetails = this._commonServ.newTenatInfo$["source"]["value"];
        if (stngData && stngData.AccountSettingCount === "0" && tenantDetails) {
            Object.keys(this.tenantStngPair).map(tntKey => {
                if (stngData["TenantSettings"] && stngData["TenantSettings"].length > 0 && tenantDetails["PharmacyDetails"]) {
                    const tntKeyValue = stngData["TenantSettings"].find(val => val["Key"] === tntKey);
                    if (tntKeyValue) {
                        tntKeyValue["Value"] = tenantDetails[tntKey === "TNT_FAX" ? "Faxdetails"
                            : "PharmacyDetails"][this.tenantStngPair[tntKey]];
                    }
                }
            });
        }
        if (stngData["TenantSettings"] && stngData["TenantSettings"].length > 0) {
            ["TNT_TELEPHONE", "TNT_FAX", "TNT_MAILING_TELEPHONE", "TNT_MAILING_FAX", "TNT_MAILING_ADD_SAME_TNT"].map(cntrl => {
                const tntKeyValue = stngData["TenantSettings"].find(val => val["Key"] === cntrl);
                if (tntKeyValue && tntKeyValue["Value"] === " " && cntrl !== "TNT_MAILING_ADD_SAME_TNT") {
                    tntKeyValue["Value"] = null;
                } else if (cntrl === "TNT_MAILING_ADD_SAME_TNT" && tntKeyValue && (tntKeyValue["Value"] === " " || !tntKeyValue["Value"])) {
                    tntKeyValue["Value"] = "1";
                }
            });
        }
    }

    async patchStateCityValues(stngFG, type, PrevValue?: any, zipValue?: any) {
        let cntrlValue: any;
        const result = PrevValue ? PrevValue : {StateSelected: new State(), citySelected: new City()};
        const tntFG: any = (stngFG && stngFG.controls ? stngFG.controls["TenantSettings"] : null);
        if (type === "zip" && zipValue) {
            result["StateSelected"]["Id"] = zipValue["StateId"];
            result["StateSelected"]["Name"] = zipValue["StateName"];
            result["citySelected"] = new City();
            result["citySelected"]["StateId"] = zipValue["StateId"];
            result["citySelected"]["Id"] = zipValue["CityId"];
            result["citySelected"]["Name"] = zipValue["CityName"];
            result["StateSelected"]["CityId"] = zipValue["CityId"];
        } else if (type === "zip" && !zipValue) {
            result["citySelected"] = new City();
            result["StateSelected"] = new State();
        }
        if (type === "state" || type === "state1") {
            const cntrlName = type === "state" ? "TNT_STATE" : "TNT_MAILING_STATE";
            if (tntFG && tntFG.controls) {
                cntrlValue = tntFG.controls.find(val => val["value"]["Key"] === cntrlName);
            }
            if (cntrlValue && cntrlValue.value ) {
                const stateValue = await this._commonServ.getStatesData(cntrlValue.value["Value"]).toPromise();
                if (stateValue && stateValue[0]) {
                    result["StateSelected"]["Id"] = stateValue[0].Id;
                    result["StateSelected"]["Name"] = stateValue[0].Name;
                    result["citySelected"] = new City();
                    result["citySelected"]["StateId"] = stateValue[0].Id;
                    this.stateId =  stateValue[0].Id;
                } else {
                    result["StateSelected"] = new State();
                }
            }
        }
        if (type === "city" || type === "city1") {
            const cntrlName = type === "city" ? "TNT_CITY" : "TNT_MAILING_CITY";
            if (tntFG && tntFG.controls) {
                cntrlValue = tntFG.controls.find(val => val["value"]["Key"] === cntrlName);
            }
            if (cntrlValue && cntrlValue.value) {
                const cityValue = await this._commonServ.getCitiesData(cntrlValue.value["Value"],
                result["StateSelected"]["Id"]).toPromise();
                if (cityValue && cityValue[0]) {
                    result["citySelected"]["Id"] = cityValue[0].Id;
                    result["citySelected"]["Name"] = cityValue[0].Name;
                    result["StateSelected"]["CityId"] = cityValue[0].Id;
                    result["citySelected"]["StateId"] = cityValue[0].StateId;
                } else {
                    result["citySelected"] = new City();
                }
            }
        }
        return result;
    }
}

