export enum NotesMainCat {
    RxAnnonation = "RxAnnotation",
    RxAnnonations = "Rx Annotation",
    Patient = "Patient",
    Prescriber = "Prescriber",
    Drug = "Drug",
    Insurance = "Insurance",
}

export enum NotesPopUpScreenCode {
    er = "Edit Rx",
    nr = "New Rx",
    dpv = "DPV",
    rph = "RPh Vrf",
    cn = "Create New",
    rf = "Refill",
    all = "All",
}
