import { Component, OnInit, HostListener } from "@angular/core";
import { KeyboardShortcuts, Unlisten, AlertService } from "../../services";
import * as _ from "lodash";

@Component({
    selector: "app-alert",
    templateUrl: "./alert.component.html"
})
export class AlertComponent implements OnInit {

    keyboardShortcuts: KeyboardShortcuts;
    unlisten: Unlisten;
    toasterMsg: any[] = [];
    stopGetUpdate = false;

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27) {
            if (this.toasterMsg && this.toasterMsg.length > 0) {
                this.removeFromArrayByForce(this.toasterMsg.length - 1);
            }
        }
    }

    constructor(private alertService: AlertService, keyboardShortcuts: KeyboardShortcuts) {
        this.keyboardShortcuts = keyboardShortcuts;
    }

    ngOnInit() {
        this.toasterMsg = [];
        this.alertService.getMessage().subscribe(message => {
            if (!_.isEmpty(message)) {
                this.toasterMsg.push(message);
                const self = this;
                let timeout;
                if (message) {
                    if (message.type === "success" || message.type === "unsuccess") {
                        timeout = 3500;
                    } else if (message.type === "error" || message.type === "warning") {
                        timeout = 5000;
                    } else if (message.type === "update") {
                        timeout = 200000000;
                    }

                    const index = self.toasterMsg.indexOf(message);
                        setTimeout(
                            function () {
                                self.removeFromArray(index, message.type, timeout);
                            },
                            timeout
                        );
                    }
                }
        });
    }

    dismissTostar() {
        this.toasterMsg.push("");
    }

    removeFromArray(i, type, time) {
        // const tstLength = tstmsg.length;
        // const tostMsgL = this.toasterMsg.length;
        // const updIndex = i - tstLength - tostMsgL;
        if (type === "update") {
            this.stopGetUpdate = true;
        } else {
            if (!(type === "error" || type === "warning")) {
                this.toasterMsg.splice(i, 1, time);
            }
        }
    }

    removeFromArrayByForce(i) {

        this.toasterMsg.splice(i, 1);
         setTimeout(() => {
            const ele = this.alertService.inputFocus ? document.getElementById(this.alertService.inputFocus) as HTMLInputElement  : "";
            if (ele) {
                if(this.alertService.inputFocus === "insSearchInp") {
                    ele.value = "";
                }
                document.getElementById(this.alertService.inputFocus).focus();
                this.alertService.inputFocus = "";
            }
         }, 100);
    }

    hardRefreshBrowser() {
        location.reload(true);
    }

    myFunction() {
        const copyText = document.getElementById("tokenText").innerText;
        document.execCommand("copy");
        const tooltip = document.getElementById("myTooltip");
        tooltip.style.visibility = "visible";
        tooltip.innerHTML = "Copied: " + copyText;
    }
}
