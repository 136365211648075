import { FormGroup, FormBuilder } from "@angular/forms";
import { Component, OnInit, Output, EventEmitter, AfterViewInit, Renderer2 } from "@angular/core";
import { CommonService, InsuranceService } from "src/app/services";

@Component({
    selector: "app-insucopy",
    templateUrl: "./insucopy.component.html"
})
export class InsucopyComponent implements OnInit, AfterViewInit {

    @Output()
    SelectedCopy = new EventEmitter<any>();
    insuList$: any;
    insuCopyFG: FormGroup;

    constructor(private _comnSvc: CommonService, private _renderer: Renderer2, private _insService: InsuranceService,
        private _fb: FormBuilder) {
        this.insuList$ = this._comnSvc.insuranceData$;
        this.insuCopyFG = this._fb.group({insuId: []});
    }

    ngOnInit() {}


    ngAfterViewInit() {
        if (this._renderer.selectRootElement("#CopyList")) {
            this._renderer.selectRootElement("#CopyList").focus();
        }
    }

    selectedValue(value) {
        this.SelectedCopy.emit(value["value"]["Id"]);
    }
}
