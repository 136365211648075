import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ElementRef, ViewChild, HostListener, Input, AfterViewInit, ViewEncapsulation, EventEmitter, Output } from "@angular/core";
import {
    PatientInfo,
    PatientPickAlert,
    PatientMsgAlert
} from "../../../models";
import { ActivatedRoute, Router } from "@angular/router";
import {
    CommonService,
    PatientService,
    AlertService,
    UserService
} from "../../../services";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
import { MsgConfig } from "../../..";
import { NewAuditUtils, RxUtils } from "src/app/utils";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HubService } from "src/app/services/Hub.service";
import { MessageService } from "src/app/services/message.service";
import { environment } from "../../../../environments/environment";
@Component({
    selector: "app-messaging",
    templateUrl: "./messaging.component.html",
    encapsulation: ViewEncapsulation.None, 
})
export class PMessagingComponent implements OnInit {
    @Input() patientInfoFG: FormGroup;

    patientID: number;
    patientInfo: PatientInfo;
    patientAlertFG: FormGroup;
    patientMsgAlertFG: FormGroup;
    messageInfo: any;
    showRefill = false;
    showDose = false;
    showGeneral = false;
    showPickup = false;
    alertmsgInfo: any;
    refillInfo: any;
    modalRef: any;
    @ViewChild("warningData", { static: true })
    warningData: ElementRef;
    @ViewChild("warningEmailData", { static: true })
    warningEmailData: ElementRef;
    @ViewChild("warningPhnData", { static: true })
    warningPhnData: ElementRef;
    emailInfo: any;
    phoneInfo: any;
    originalData: any;
    sharedCancel = false;
    routeFrom: string;
    canDeactivateRoute = true;
    accGeneralSettings: number;
    accBirthdayStng: number;
    accRefRemStng: number;
    accNewRxStng: number;
    accPickUpStng: number;
    time: { hour: any; minute: any; };
    tempFG: FormGroup;
    dateTime: any;
    unsubscribe$: Subject<void> = new Subject();
    messages: any[] = []; // chat history
    auth0_token:any;
    npi:any;
    chatObject = {};
    selectedPatient:any;
    isSecurePatientComm:boolean;
    get PatientMessageSettings(): FormGroup {
        return this.patientInfoFG.get("PatientMessageSettings") as FormGroup;
    }

    constructor(
        private _actvRoute: ActivatedRoute,
        private _commonServ: CommonService,
        private _editPatient: PatientService,
        private _fb: FormBuilder,
        private modalService: NgbModal,
        private _router: Router,
        private _alertService: AlertService,
        private _auditUtils: AuditLogUtils,
        private _rxUtils: RxUtils,
        private _newAuditUtil: NewAuditUtils,
        private hubService: HubService,
        private _userServ: UserService,
        private _msgServ: MessageService,
    ) {
        this.createFG();
        this.getAccountSettings();
        this.tempFG = this._fb.group({
            SubscriptionResponseDate: null
    });
    this.hubService.setMessagingComponent(this)
}

    ngOnInit() {
        this.patchDefaultValues();
        const patientInfo = this.patientInfoFG.controls['Person']?.value;
        if(patientInfo && patientInfo?.['Id']){
            this.chatObject = {
                identifier: ""+patientInfo?.['Id'],
                userName: patientInfo?.['LastName']+' '+ patientInfo?.['FirstName'],
                gender: (patientInfo?.['GenderId'] === 2 ) ? 'male' : 'female'
            }
            this.selectedPatient = this.chatObject;
        }
        this.isSecurePatientComm = environment.isSecurePatientComm;     
    }
    
    getAccountSettings() {
        this.accGeneralSettings = parseInt(this._commonServ.getSetttingValue("MessageSettings",
        "Send_General_Alerts"), 10);
        this.accBirthdayStng = parseInt(this._commonServ.getSetttingValue("MessageSettings",
        "Send_Birthday_Greeting_Alerts"), 10);
        this.accRefRemStng = parseInt(this._commonServ.getSetttingValue("MessageSettings",
        "Send_Refill_Reminder_Alerts"), 10);
        this.accNewRxStng = parseInt(this._commonServ.getSetttingValue("MessageSettings",
        "Send_New_Rx_Message_Alerts"), 10);
        this.accPickUpStng = parseInt(this._commonServ.getSetttingValue("MessageSettings",
        "Send_Ready_For_Pickup_Alerts"), 10);
    }
    patchDefaultValues() {
        if (this.PatientMessageSettings) {
        this.PatientMessageSettings.patchValue({
            SubscriptionResponse: this.PatientMessageSettings.controls["SubscriptionResponse"] &&
            this.PatientMessageSettings.controls["SubscriptionResponse"].value !== null &&
            (
            (typeof this.PatientMessageSettings.controls["SubscriptionResponse"].value === 'string' &&
            this.PatientMessageSettings.controls["SubscriptionResponse"].value.toLowerCase() === "true") ||
            this.PatientMessageSettings.controls["SubscriptionResponse"].value === true
            ) ? true : false,
            SendRefillReminderAlerts: (this.PatientMessageSettings.controls["SendRefillReminderAlerts"].value !== null
             && this.accRefRemStng) ?
             this.PatientMessageSettings.controls["SendRefillReminderAlerts"].value :  false,
            SendGeneralAlerts:  (this.PatientMessageSettings.controls["SendGeneralAlerts"].value !== null && this.accGeneralSettings) ?
            this.PatientMessageSettings.controls["SendGeneralAlerts"].value : false,
            SendReadyForPickupAlerts:  this.PatientMessageSettings.controls["SendReadyForPickupAlerts"].value !== null ?
            this.PatientMessageSettings.controls["SendReadyForPickupAlerts"].value : false,
            SendBirthdayGreetingAlerts: this.PatientMessageSettings.controls["SendBirthdayGreetingAlerts"].value !== null
            && this.accBirthdayStng ?
            this.PatientMessageSettings.controls["SendBirthdayGreetingAlerts"].value : false,
            SendNewRxMessageAlerts:   this.PatientMessageSettings.controls["SendNewRxMessageAlerts"].value !== null && this.accNewRxStng ?
            this.PatientMessageSettings.controls["SendNewRxMessageAlerts"].value : false
        });
        if ( this.PatientMessageSettings.controls["SubscriptionResponseDtTm"] && this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].value &&
        this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].value !== "0001-01-01T00:00:00") {
        const DateTime = moment.utc(this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].value).local().format("MM/DD/YYYY HH:mm:ss");
        this.tempFG.controls["SubscriptionResponseDate"].patchValue(
        moment(DateTime).format("MM/DD/YYYY"));
        const time1 =  moment(DateTime).format("HH:mm:ss");
        const time2 = time1.split(":");
        this.time = {
            hour: +time2[0],
            minute: +time2[1]
        };
        }
        this.dateTime = this.PatientMessageSettings.controls["SubscriptionResponse"].value ?
         moment.utc(this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].value).local().format("MM/DD/YYYY HH:mm:ss") : null;
        }
    }

    getMessagingInfo() {
        this._editPatient.getMessagingInfo(this.patientID).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                this.messageInfo = resp;
                if (this.messageInfo["Patient_MsgAlert"] === null) {
                    this.setDefaultValues();
                }
                this.patchValue();
                this.originalData = this.patientMsgAlertFG.value;
            }
        });
    }

    patchValue() {
        for (const key in this.messageInfo) {
            if (key === "Patient_MsgAlert" && this.messageInfo[key]) {
                this.generateFA(key, this.messageInfo[key]);
            } else if (key === "PatientAlert_PickAlert" && this.messageInfo[key]) {
                if (this.messageInfo[key]) {
                    this.patientMsgAlertFG.controls["PatientAlert_PickAlert"].patchValue(this.messageInfo[key]);
                } else {
                    this.patientMsgAlertFG.controls["PatientAlert_PickAlert"].patchValue({PickAlertCatId: 1})
                }
            }
        }
    }

    setDefaultValues() {
        const msgAry = new Array<PatientMsgAlert>();
        for (let i = 1; i < 5; i++) {
            const message = new PatientMsgAlert();
            message.PatientId = this.patientID;
            if (i === 3) {
                message.MsgAlertCatId = 4;
            } else if (i === 4) {
                message.MsgAlertCatId = 3;
            } else {
                message.MsgAlertCatId = i;
            }
            message.IsEmailAllowed = false;
            message.IsSMSAllowed = false;
            message.IsPhoneAllowed = false;
            message.IsActive = false;
            msgAry.push(message);
        }
        this.messageInfo.Patient_MsgAlert = msgAry;
    }

    generateFA(key: string, values: any[]) {
        if (values) {
            const extraCostFGs = values.map(val => this._fb.group(val));
            const extraCostFormArray = this._fb.array(extraCostFGs);
            this.patientMsgAlertFG.setControl(key, extraCostFormArray);
        }
    }

    createFG() {
        this.patientMsgAlertFG = this._fb.group({
            Patient_MsgAlert: this._fb.array([]),
            PatientAlert_PickAlert: this._fb.group(new PatientPickAlert())
        });
    }
    async updateMessagingInfo() {
        this.patientMsgAlertFG.controls["PatientAlert_PickAlert"].patchValue({
            PatMsgAlertId: this.patientMsgAlertFG.value.Patient_MsgAlert[3].Id,
            IsActive: true
        });
        const resp = this._editPatient.updateMessagingInfo(this.patientMsgAlertFG.value).toPromise();
        if (resp) {
            this._auditUtils.getChangedValues(
                this.originalData,
                this.patientMsgAlertFG.value,
                "MessagingInfo",
                "Patient",
                this.patientID
            );

            this._alertService.success(MsgConfig.UPDATE_SUCCESS);
        } else {
            this._alertService.error(MsgConfig.UPDATE_FAIL);
        }
    }

    changeMessageValues(val?: any, i?: any, popup?: any, check?: any) {
        if (i === 0) {
            if (this.refillInfo) {
                if (this.emailInfo === null && popup === "email") {
                    this.showEmailPopUp(i);
                } else if (check) {
                    if (check.target.id === "RefillEmail") {
                        this.changeEmailValue(i, check);
                    }
                }
                if (this.phoneInfo === null && popup === "phn") {
                    this.showMobilePopup(i);
                } else if (check) {
                    if (check.target.id === "RefillPhone") {
                        this.changeMobileValue(i, check);
                    }
                    if (check.target.id === "RefillSms") {
                        this.changeSmsValue(i, check);
                    }
                }
                if (val) {
                    if (val.target.id === "0") {
                        this.messageInfo.Patient_MsgAlert[0].IsActive = true;
                        this.patchValue();
                        this.showRefill = true;
                    } else {
                        this.showRefill = false;
                        this.radio(i);
                    }
                }
            } else {
                this.messageInfo.Patient_MsgAlert[0].IsActive = false;
                this.patchValue();
                this.modalRef = this.modalService.open(this.warningData, {
                    centered: true,
                    keyboard: false,
                    windowClass: "large--content"
                });
            }
        } else if (i === 1) {
            if (val) {
                if (val.target.id === "2") {
                    this.showDose = true;
                    this.messageInfo.Patient_MsgAlert[i].IsActive = true;
                    this.patchValue();
                } else {
                    this.showDose = false;
                    this.radio(i);
                }
            }
            if (this.emailInfo === null && popup === "email") {
                this.showEmailPopUp(i);
            } else if (check) {
                if (check.target.id === "DoseEmail") {
                    this.changeEmailValue(i, check);
                }
            }
            if (this.phoneInfo === null && popup === "phn") {
                this.showMobilePopup(i);
            } else if (check) {
                if (check.target.id === "DosePhone") {
                    this.changeMobileValue(i, check);
                }
                if (check.target.id === "DoseSms") {
                    this.changeSmsValue(i, check);
                }
            }
        } else if (i === 2) {
            if (val) {
                if (val.target.id === "4") {
                    this.showGeneral = true;
                    this.messageInfo.Patient_MsgAlert[i].IsActive = true;
                    this.patchValue();
                } else {
                    this.showGeneral = false;
                    this.radio(i);
                }
            }
            if (this.emailInfo === null && popup === "email") {
                this.showEmailPopUp(i);
            } else if (check) {
                if (check.target.id === "GeneralEmail") {
                    this.changeEmailValue(i, check);
                }
            }
            if (this.phoneInfo === null && popup === "phn") {
                this.showMobilePopup(i);
            } else if (check) {
                if (check.target.id === "GeneralPhone") {
                    this.changeMobileValue(i, check);
                }
                if (check.target.id === "GeneralSms") {
                    this.changeSmsValue(i, check);
                }
            }
        } else if (i === 3) {
            if (val) {
                if (val.target.id === "6") {
                    this.showPickup = true;
                    this.messageInfo.Patient_MsgAlert[i].IsActive = true;
                    this.patchValue();
                } else {
                    this.showPickup = false;
                    this.radio(i);
                }
            }
            if (this.emailInfo === null && popup === "email") {
                this.showEmailPopUp(i);
            } else if (check) {
                if (check.target.id === "PickupEmail") {
                    this.changeEmailValue(i, check);
                }
            }
            if (this.phoneInfo === null && popup === "phn") {
                this.showMobilePopup(i);
            } else if (check) {
                if (check.target.id === "PickupPhone") {
                    this.changeMobileValue(i, check);
                }
                if (check.target.id === "PickupSms") {
                    this.changeSmsValue(i, check);
                }
            }
        }
    }

    radio(i: any) {
        this.messageInfo.Patient_MsgAlert[i].IsActive = false;
        this.patchValue();
        this.patientMsgAlertFG.value.Patient_MsgAlert[i].IsEmailAllowed = false;
        this.patientMsgAlertFG.value.Patient_MsgAlert[i].IsPhoneAllowed = false;
        this.patientMsgAlertFG.value.Patient_MsgAlert[i].IsSMSAllowed = false;
    }

    showEmailPopUp(i: any) {
        this.messageInfo.Patient_MsgAlert[i].IsEmailAllowed = false;
        this.patchValue();
        this.modalRef = this.modalService.open(this.warningEmailData, {
            centered: true,
            keyboard: false,
            windowClass: "large--content"
        });
    }

    showMobilePopup(i: any) {
        this.messageInfo.Patient_MsgAlert[i].IsSMSAllowed = false;
        this.messageInfo.Patient_MsgAlert[i].IsPhoneAllowed = false;
        this.patchValue();
        this.modalRef = this.modalService.open(this.warningPhnData, {
            centered: true,
            keyboard: false,
            windowClass: "large--content"
        });
    }

    changeMobileValue(i: any, check: any) {
        this.messageInfo.Patient_MsgAlert[i].IsPhoneAllowed =
            check.target.checked;
        this.patchValue();
    }

    changeSmsValue(i: any, check: any) {
        this.messageInfo.Patient_MsgAlert[i].IsSMSAllowed =
            check.target.checked;
        this.patchValue();
    }

    changeEmailValue(i: any, check: any) {
        this.messageInfo.Patient_MsgAlert[i].IsEmailAllowed =
            check.target.checked;
        this.patchValue();
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }
    patchDateTime() {
      if (this.PatientMessageSettings && this.PatientMessageSettings.controls["SubscriptionResponse"])  {
         if (this.PatientMessageSettings.controls["SubscriptionResponse"].value) {
            if (this.PatientMessageSettings.controls["SubscriptionResponseDtTm"]) {
                if (!this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].value) {
                    const todayDate = moment().format("MM/DD/YYYY");
                    const time = moment().format("HH:mm:ss");
                    const time2 = time.split(":");
                    this.tempFG.controls["SubscriptionResponseDate"].patchValue(todayDate);
                    this.time = {
                        hour: +time2[0],
                        minute: +time2[1]
                    };
                    this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].patchValue(
                        this.tempFG.controls["SubscriptionResponseDate"].value + " " + time);
                  } else {
                    this.tempFG.controls["SubscriptionResponseDate"].patchValue(
                    this.dateTime ? moment(this.dateTime).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"));
                    const time1 = this.dateTime ? moment(this.dateTime).format("HH:mm:ss") : moment().format("HH:mm:ss");
                    const time2 = time1.split(":");
                    this.time = {
                        hour: +time2[0],
                        minute: +time2[1]
                    };
                    this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].patchValue(
                        this.tempFG.controls["SubscriptionResponseDate"].value + " " + this.time);
                  }
              }
         } else {
            if (this.dateTime) {
            const todayDate = moment().format("MM/DD/YYYY");
            const time = moment().format("HH:mm:ss");
            const time2 = time.split(":");
            this.tempFG.controls["SubscriptionResponseDate"].patchValue(todayDate);
            this.time = {
                hour: +time2[0],
                minute: +time2[1]
            };
            this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].patchValue(
                this.tempFG.controls["SubscriptionResponseDate"].value + " " + time);
         } else {
            this.tempFG.controls["SubscriptionResponseDate"].patchValue(null);
            this.time = {
                hour: null,
                minute: null
            };
            this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].patchValue(null);
         }

        }
    }
}
    saveDateTime() {
        if (this.tempFG.controls["SubscriptionResponseDate"].value && this.time) {
            const time = this.time.hour + ":" + this.time.minute;
            this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].patchValue(
                this.tempFG.controls["SubscriptionResponseDate"].value + " " + time);
         }
    }
    ChangeToDefaultValues(frmSignalR?) {
        if (frmSignalR) {
            this._commonServ.getEditPatientInformation(this.patientInfoFG.controls.Person.value.Id).subscribe((res)=> { 
                if(res?.PatientMessageSettings){
                    this.PatientMessageSettings.patchValue({
                        SubscriptionResponse : res.PatientMessageSettings?.SubscriptionResponse?.toLowerCase() === "true" ? true : false,
                        SendRefillReminderAlerts: res.PatientMessageSettings?.SendRefillReminderAlerts ? true : false,
                        SendGeneralAlerts: res.PatientMessageSettings?.SendGeneralAlerts ? true : false,
                        SendReadyForPickupAlerts: res.PatientMessageSettings?.SendReadyForPickupAlerts ? true : false,
                        SendBirthdayGreetingAlerts:  res.PatientMessageSettings?.SendBirthdayGreetingAlerts ? true : false,
                        SendNewRxMessageAlerts: res.PatientMessageSettings?.SendNewRxMessageAlerts ? true : false
                    });
                    const subscriptionDateTime = res?.PatientMessageSettings?.SubscriptionResponseDtTm; 
                    const formattedDate = subscriptionDateTime
                    ? moment.utc(subscriptionDateTime).local().format(MsgConfig.DATE_FORMAT+" HH:mm:ss") 
                    : "";
                    this.dateTime = formattedDate;
                    this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].patchValue(null);
                    this.tempFG.controls["SubscriptionResponseDate"].patchValue(
                    this.dateTime ? moment(this.dateTime).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"));
                    const time1 = this.dateTime ? moment(this.dateTime).format("HH:mm:ss") : moment().format("HH:mm:ss");
                    const time2 = time1.split(":");
                    this.time = {
                        hour: +time2[0],
                        minute: +time2[1]
                    };
                    this.PatientMessageSettings.controls["SubscriptionResponseDtTm"].patchValue(
                    this.tempFG.controls["SubscriptionResponseDate"].value + " " + this.time);
                } 
            });
        }
        if (this.PatientMessageSettings) {
            if (!this.PatientMessageSettings.value["SubscriptionResponse"])  {
                this.PatientMessageSettings.patchValue({
                    SendRefillReminderAlerts: false,
                    SendGeneralAlerts: false,
                    SendReadyForPickupAlerts: false,
                    SendBirthdayGreetingAlerts: false,
                    SendNewRxMessageAlerts: false
                });
            } else {
                this.PatientMessageSettings.patchValue({
                    SendRefillReminderAlerts: this.accRefRemStng ? true : false,
                    SendGeneralAlerts: this.accGeneralSettings ? true : false,
                    SendReadyForPickupAlerts: this.accPickUpStng ? true : false,
                    SendBirthdayGreetingAlerts: this.accBirthdayStng ? true : false,
                    SendNewRxMessageAlerts: this.accNewRxStng ? true : false
                });
            }
        }
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
