import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { AlertService, ErxService, RxTransferService, UserService } from 'src/app/services';
import { CommonUtil } from 'src/app/utils';

@Component({
  selector: 'app-transfer-in',
  templateUrl: './transfer-in.component.html',
  styleUrls: ['./transfer-in.component.scss']
})
export class TransferInComponent implements OnInit {
  eRxModelFG: FormGroup;
  @Output() ClosePopUpModal = new EventEmitter();
  @ViewChild("eRxTRxIn", { static: true })
  eRxTRxIn: any;
  modelRef: any;
  selectedPatientDetails: any;
  selectedTransferTypeDetails: any;
  selectedDruginformation: any;
  unsubscribe$: Subject<void> = new Subject();
  @Input() IsfrmPatHis;
  @Input() RxTransferType;
  
  @Input()
  set PatientDetails(patient : any){
     this.selectedPatientDetails = patient;
     console.log(this.selectedPatientDetails  , "this.selectedPatientDetails ");
  }
  
  constructor(private _fb:FormBuilder, 
              private _cdr: ChangeDetectorRef,
              private _eRxService:ErxService,
              private _alrtSev: AlertService,
              private _userService: UserService,
              private _commonUtil: CommonUtil,
              private _rxSer: RxTransferService,
              private _modalService:NgbModal) {
    this.eRxModelFG = this._fb.group({
      patientID: new FormControl(null, [Validators.required]),
      drugID: null,
      IsTransferIn : false,
      sourcePharmacy: {
        NCPDPID : null,
        BusinessName: null,
        Specialty : null,
        Address : {
          AddressLine1: null,
          AddressLine2: null,
          City: null,
          State: null,
          ZipCode: null,
          CountryCode: null,
          PhoneNumbers: []
        }
      },
      destinationPharmacyID: new FormControl(null, [Validators.required]),
      sourceNPI: null,
      drugNDC: null,
      drugName: null,
      drugDesc: null,
  });
   }

  ngOnInit(): void {
    this.eRxModelFG.controls['destinationPharmacyID'].patchValue(
      this._userService.getToken("UniquePharmacyId")
    )
    this.modelRef = this._modalService.open(this.eRxTRxIn, {centered: true,
      backdrop: false, keyboard: false ,windowClass: "max-modal-lg-size transfer-in"});
  }
  IsPopUpClosed(event) {
    console.log(event, "event");
  }
  selectedDrugDetails(drug: any) {
    this.selectedDruginformation = drug ? drug: null;
    console.log(this.selectedDruginformation, "this.selectedDruginformation");
      if(drug) {
        console.log(drug.id , "drug.id");
          this.eRxModelFG.patchValue({
            drugID: drug.id,
            drugName: drug.drugname,
            drugNDC: drug.drugcode,
            drugDesc: drug.drugfullname
          });
      } else {
        this.eRxModelFG.patchValue({
          drugID: null,
          drugName: null,
          drugNDC: null,
          drugDesc: null
        });
      }
      console.log(this.eRxModelFG , "this.eRxModelFG.");
  }
  closePopup() {
      this.ClosePopUpModal.emit(false);
  }
  selectedTransTypeDetails(details: any) {
      this._cdr.detectChanges();
      this.selectedTransferTypeDetails = details;
      if(details && details !== "All")
      {
        this.eRxModelFG.controls['drugID'].patchValue(details);
        this.eRxModelFG.controls['IsTransferIn'].patchValue(false);
      }
      else if(details == "All")
      {
        this.eRxModelFG.controls['IsTransferIn'].patchValue(true);
      }
  }
  selectedPatientID(patientId) {
      this.eRxModelFG.controls['patientID'].patchValue(patientId);
  }
  sendTransferInReq() {
    console.log(this.eRxModelFG, "this.eRxModelFG")
    if(this.eRxModelFG.valid)
    {
      this._eRxService.sendTransferInReq(this.eRxModelFG.value, this._commonUtil.getErxVersion()).subscribe((resp: any) => {
        if(resp) {
          this.closePopup();
          this._alrtSev.success(MsgConfig.Transfer_In_Req);
        }
    });
    }
  }

  
 


  selectedToPharmacy(selectedPharmacy) {
    console.log(selectedPharmacy , "selectedPharmacy");
    if(selectedPharmacy) {
      this.eRxModelFG.controls['sourceNPI'].patchValue(selectedPharmacy?.NPINum);
      let phones: any = [];
      if(selectedPharmacy.Phone2) {
        phones.push(selectedPharmacy.Phone2);
      }
      if(selectedPharmacy.Phone1) {
        phones.push(selectedPharmacy.Phone1);
      }
      this.eRxModelFG.controls['sourcePharmacy'].patchValue({
        NCPDPID : null,
        BusinessName: null,
        Specialty : null,
        Address : {
          AddressLine1: selectedPharmacy.AddressLine1,
          AddressLine2: selectedPharmacy.AddressLine1,
          City: selectedPharmacy.city,
          State:selectedPharmacy.state,
          ZipCode: selectedPharmacy.zipcode,
          CountryCode: selectedPharmacy.CountryId,
          PhoneNumbers: phones.length ? phones : []
        }
      })
    }
  }
}
