import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DefaultHeaders, wijimoIdsList, WjGridSaveModel } from "../models";
import { CommonService, UserService } from "../services";
import { CommonUtil } from "./common.util";

@Injectable({
    providedIn: "root"
})

export class WijimoUtil {

    _wjSavedData$: BehaviorSubject<any> = new BehaviorSubject(null);
    wjSavedData$ = this._wjSavedData$.asObservable();

    constructor(private _commonSer: CommonService, private _userService: UserService, private _comnUtil: CommonUtil) {
    }

    async storeWjSavedData(data) {
        this._wjSavedData$.next(data);
    }

    patchDefWidth(flex, wijimoKey, storedWJData?: any) {
        const storedWJ = storedWJData ? storedWJData : this.getWJSavedData(wijimoKey);
        if (flex && flex.columns && flex.columns.length && storedWJ && wijimoKey !== "intakeQueueWjList" &&  wijimoKey !== "DURValNewRxWJ") {
            flex.columns.map((pCol) => {
                const col = storedWJ.find(v => v["header"] === pCol["header"]);
                if (col) {
                    pCol["width"] = col["width"];
                }
            });
        }
    }

    patchDefHeader(wijimoKey, storedWJ?: any, getActionCols: boolean = false , gridWidth?: number) {
        const storedWJData = (storedWJ && storedWJ.length) ? storedWJ : this.getWJSavedData(wijimoKey);
        var headers = [];
        let widthData = 0;
        let avgWidth = 0;
        if(storedWJData && storedWJData.length) {
             headers = storedWJData.map(v => {
                const k = {};
                k["hName"] = v.header;
                k["isVisible"] = v.isVisible;
                k["width"] = v.width;
                if(k["isVisible"]){
                    widthData += v.width;
                }
                return k;
            });
            if(gridWidth && widthData && gridWidth > widthData){
                let diffWidth = (gridWidth-widthData)-8;
                avgWidth = diffWidth/storedWJData.length;
                headers = storedWJData.map(v => {
                    const k = {};
                    k["hName"] = v.header;
                    k["isVisible"] = v.isVisible;
                    k["width"] = v.width + avgWidth;
                    return k;
                });
            } 
        }
        if(!getActionCols) {
            const excludeheader = this.setExcludeCols(wijimoKey);
            excludeheader.map(v => {
                const index = headers.findIndex(i => i["hName"] === v);
                if (index > -1) {
                    headers.splice(index, 1);
                }
            });
        }
        return headers;
    }

    setExcludeCols(wijimoKey) {
        const excludeheader =  [" ", "",  null, "Action", "Actions", "Select","SELECT", "View Docs", "Tag", "ACTIONS", "View Trans Result", "IsDeleted?", "PA Status"];
        if (wijimoKey === "erxListingWJ") {
            excludeheader.push("Process");
        } else if (wijimoKey === "reviewRxVerfWJ" || wijimoKey === "reviewdrugVerfWJ") {
            excludeheader.push("Edit Rx");
            excludeheader.push("History");
        } else if (wijimoKey === "reviewFollowupWJ") {
            excludeheader.push("Tag History");
        } else if (wijimoKey === "controlResubmitListWJ" || wijimoKey === "rxBasicDisplayListWJ") {
            excludeheader.push("Status");
        }
        return excludeheader;
    }

   async getWJSavedData(wijimoKey, type?: boolean, frmRxHist?: boolean) {
        const savedData = this._commonSer.wjSavedList$["source"]["value"];
        let result = null;
        if(frmRxHist && wijimoKey && (wijimoKey === "labelQueueDisplayListWJ" || wijimoKey === "forGroupViewWj")) {
            result = await this.getDefaultHead(wijimoKey);
        }
         else if (savedData && savedData.length &&  wijimoKey != 'enterInvListWJ') {
            const data = savedData.find(v => v["WijmoKey"] === wijimoKey && v["UserId"] === +this._userService.getToken("UserId"));
            if (data) {
                result = type ? data : JSON.parse(data["ColumnOrder"]);
                const defaultHeaders = this.getDefaultHead(wijimoKey);
                const defaultHeadersForReturn: any  = [];
                result.map((item: any) => {
                    const existHeader = defaultHeaders ? defaultHeaders.filter((item1: any)=> {   return (item1["header"] === item["header"])  }) : [];
                            if(existHeader && existHeader.length) {
                                defaultHeadersForReturn.push(item);
                            }
                });
                result = defaultHeadersForReturn;
                const defHead = this.getDefaultHead(wijimoKey);
                if (defHead && result && defHead.length !== result.length) {
                    const missedData = defHead.filter(h => result.findIndex(r => r["header"] === h["header"]) === -1);
                    if (missedData && missedData.length) {
                        missedData.map(v => {
                            const index = defHead.findIndex(h => h["header"] === v["header"]);
                            if (index > -1) {
                                result.splice(index, 0, v);
                            }
                        });
                    }
                }
            } else if (!frmRxHist) {
                result = this.getDefaultHead(wijimoKey);
            }
        } else if (!frmRxHist) {
            result = this.getDefaultHead(wijimoKey);
        }
        return result;
    }

    getDefaultHead(wijimoKey) {
        let result = null;
        const defHeaders = new DefaultHeaders();
        if (defHeaders && defHeaders[wijimoKey]) {
            result = defHeaders[wijimoKey];
        }
        return result;
    }
    async storeWijimoStatus1(WijmoKey, result) {
         const d = this.getWJSavedData(true);
        let data: WjGridSaveModel = new WjGridSaveModel();
        data["WijmoKey"] = WijmoKey;
        data["WijmoId"] = wijimoIdsList[WijmoKey];
        let wjColData = [];
        if (result) {
            wjColData = result.map(v => {
                const k = {};
                k["header"] = v["header"];
                k["width"] = v["width"];
                k["isVisible"] = v["isVisible"];
                return k;
            });
        }
        data["ColumnOrder"] = JSON.stringify(wjColData);
        await this._comnUtil.saveWjGridDet(data);

    }
}
