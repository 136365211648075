export class PrescriberInfo {
    Mobil = null;
    Telephone1 = null;
    Telephone2 = null;
    Fax = null;
    Address = null;
    Person = null;
    Prescriber: Prescriber = null;
    EMail = null;
    PrimarySpeciality: PrescSpeciality = null;
    SecondarySpeciality: PrescSpeciality = null;
    TertiarySpeciality: PrescSpeciality = null;
    PrescAdds: PrescAdds = null;
    InsuBlackList = null;
    InsuCarrier = null;
    PrescriberSalesPerson: PrescriberSalesPerson = null;
    SpecialProgram: PrescriberSplProg = null;
    PrescFacility: any = null;
    FacilityIds: any = null;
}

export class PrescAddress {
    Id: number = null;
    TenantId: number = null;
    AddressLine1: string = null;
    AddressLine2: string = null;
    ZipCode: string = null;
    CountryId: number = null;
    StateId: number = null;
    CityId: number = null;
    CityName: string = null;
    StateName: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    SPINum: string = null;
    Extension: string = null;
    Fax: string = null
    Telephone: string = null
}

export class PrescClinic {
    Id: number = null;
    Address = null;
    PrescAdds: PrescAdds = null;
}

export class ClinicalAddress {
    PrescriberId: number = null;
    PageNumber: number = null;
    PageSize: number = null;
}

export class PresClinicalAddress {
    Id: number = null;
    TenantId: number = null;
    AddressLine1: string = null;
    AddressLine2: string = null;
    ZipCode: string = null;
    CountryId: number = null;
    StateId: number = null;
    CityId: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    CityName: string = null;
    StateName: string = null;
    SPINum: any = null;
    Extension: string = null;
    Fax: string = null;
    Telephone: string = null
}

export class Prescriber {
    Id: number = null;
    PrescbNum: string = null;
    MergedId: number = null;
    PrescCatId: number = null;
    DEANum: string = null;
    MedAidNum: string = null;
    Remarks: string = null;
    QckCode: string = null;
    PrescIdCatId: number = null;
    OtherIdNum: string = null;
    PrescId: string = null;
    StateSpId: string = null;
    XDEANum: string = null;
    FacilityId: number = null;
    FacilityIds: any = [];
    DEASuffix: string = null;
}

export class PrescSpeciality {
    Id: number = null;
    TenantId: number = null;
    PrescriberId: number = null;
    SpecialityId: number = null;
    SpecPrioId: number = null;
}

export class PrescAdds {
    Id: number = null;
    SPINum: string = null;
    ServiceLevel: string = null;
    ServiceLevelName: string = null;
}

export class PrescriberSalesPerson {
    Id: number = null;
    TenantId: number = null;
    PrescriberId: number = null;
    SalesPersonId: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class PrescriberSplProg {
    Id: number = null;
    TenantId: number = null;
    PrescriberId: number = null;
    SplProgramId: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class PrescMMsFilters {
    PrescLastName: string = null;
    PrescFirstName: string = null;
    License: string = null;
    Zip: string = null;
    Dea: string = null;
    Phone: string = null;
    PrescNpi: string = null;
}

// export class PrescriberCat {
//     Id: number = null;
//     IsActive: boolean = null;
//     CreatedDtTm: Date = null;
//     ModifiedDtTm: Date = null;
//     Name: string = null;
//     PrescCatCode: string = null;
//     Remarks: string = null;
// }

export class PrescTypeFile{
    PrescCatCode : number = null;
    Name :string = null;
}
