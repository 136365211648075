import { IdName } from "./../../../models/system-data.model";
import { OverrideLogComponent } from "./../../shared/override-log/override-log.component";
import { PrivMaskGuard } from "./../../../guards/access-privs.guard";
import { AccCategoryE, RxE } from "./../../../models/access-privs.enum";
import { EdRxComponent } from "./../../ed-rx/ed-rx.component";
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    HostListener,
    OnDestroy
} from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    RphVerificationService,
    AlertService,
    CommonService,
    DocumentsService,
    PatPreDrugModalService,
    RxService,
    TransmissionService,
    NotesLogService
} from "src/app/services";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import {
    DrugInteraction,
    DrugToDrugIntractn,
    DiseaseContradication,
    PregnancyLactationInfo,
    SystemData,
    DocumentInputParams,
    SharedDocuments,
    RphFilters,
    DrugInteractionData,
    AlertTypes
} from "src/app/models";
import { RxUtils, NRxUtils, PatchRXFGUtil, RxBillingUtil, CommonUtil } from "src/app/utils";
import { AdminRxComponent } from "../../shared";
import { AuditLogUtils } from "src/app/utils/audit-log.util";
import { environment } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { CollectionView } from "@grapecity/wijmo";
import { ErxVersions } from "src/app/models";
import { CommonStore, RxStore } from "src/app/store";
import { PrescriberComponent } from "../../prescriber/prescriber.component";
import { CommonPrintService } from "src/app/services/common-print.service";
import { PatientComponent } from "../../patient/patient.component";
import { ErxComponent } from "../../erx/erx.component";
import { CommonLogicRxInfoUtil } from "src/app/utils/commonLogic-rxInfo.utils";
import { CommonWarnorconfirmPopupComponent } from "../../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { Observable, Subject, Subscription } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonDrugpickVerfHistComponent } from "../../shared/common-drugpick-verf-hist/common-drugpick-verf-hist.component";
const drugImage = environment.DrugImage;
import * as Sentry from "@sentry/browser";
import { LabelQueueService } from "src/app/services/label-queue.service";
import { NDrugComponent } from "../../rx-shared";
import { CommonNotesLogComponent } from "../../shared/common-notes-log/common-notes-log.component";
import { HoaComponent } from "../../shared/hoa/hoa.component";
import { MsgConfig } from "src/app/app.messages";
import { Verificationsenum } from "src/app/models/Verifications.enum";
import { VaccineImmunizationComponent } from "../../shared/vaccine-immunization/vaccine-immunization.component";
import { takeUntil } from "rxjs/operators";
import { LogicoyService } from "src/app/services/logicoy.service";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-rph-verify-modal",
    templateUrl: "./rph-verify.component.html"
})
export class RphVerifyComponent implements OnInit, OnDestroy {
    patientName = "--";
    prescriberName = "--";
    patientAlle = [];
    IsTransResult = false;
    currentDrugPopIndex = 0;
    drugToDrugInteractions1: DrugToDrugIntractn;
    rphFmDph: any;
    imgURL: any;
    postDoc: any;
    documentsList: CollectionView;
    eRxVersion: any;
    hasDocuments: boolean;
    drugAllergyData: any;
    warningMsg: string;
    IsHistory = false;
    geriatricAge: number;
    isRxVerification: boolean = false;
    years: number;
    youthUnderAge: number;
    isPrmptHighRsk = false;
    hasOvrRidPrv: boolean;
    hasOvrRidPrvP: boolean;
    rxPrivs: any;
    adverseDataMonograph: string;
    currentLifeStyleIndex = 0;
    refillsDueCnt: any;
    drugLifeStyle: any;
    isPatientHistory: boolean = false;
    rxid: any;
    switchValue: any;
    openPopUp: boolean = false;
    refilId: any;
    selectedFillId: any;
    totalDocs: any ;
    verifiedAlready: any;
    result: any;
    rxInfoList: any;
    TotalRxInfoSelected: any;
    selectedRxsList: any;
    PrescRefillId: any;
    index: number;
    selectedPrescRefId: any = "";
    prevClicked = false;
    nextClicked = false;
    fillNumberDisc: any;
    fillNoAlreVerf: any;
    rxData: any;
    dphVerifHistory: any;
    rphInfoData: any;
    isRxVerificationHist: boolean;
    drugpickData: any;
    searchFG: FormGroup;
    settingpostFG: FormGroup;
    StatusId: any;
    RphAndRxVerificationForPatientReviewOverXdays: any;
    notesLogForRphfromRx: any;
    modelRefRph : any;
    isHOADefined : boolean = false;
    isPaidRecordsAv: any;
    grpVerfRxs: any;
    differentNDc: any =[];
    diffNDCCnt: any;
    acbScore:any;
    rejectedTrans: boolean;
    drugInfoDet: any;
    controlDrug: boolean;
    diseaseHeading: any;
    formEntDetails: any;
    displayFormBtn :boolean = false;
    openAssessmentForm: boolean;
    rxNodisplay: string;
    patientFullName: any;
    drugFullName: string;
    patientInfo: any;
    displayLotNum: boolean;
    IOUClk: boolean = false;
    dispensedNDC: any;
    modelRefAuth: any;
    LoginVerifyFG: FormGroup;
    warnMessage: string;
    frmRRR: any;
    notEquivalentDisp: boolean;
    notEqvList: any;
    dispensedDrgNm: any;
    subscription1: Subscription;
    subscription2: Subscription;
    @Input()
    set SystemData(sd: any) {
        this.systemData = sd;
    }

    @Input()
    set IsPatientHistory(p: any) {
        this.isPatientHistory = p;
    }

    @Input()
    set IsRxVerification(bl: any) {
        this.isRxVerification = bl;
    }


    @Input()
    set RxID(rxid: any) {
        this.rxid = rxid;
    }
    @Input()
    set RefilID(refilId: any) {
        this.refilId = refilId;
    }

    @Input() FillID: any;

    @Input()
    set RxInfo(data: any) {
        this.rxInfoList = data;
    }
    @Input()
    set RefillNumId(PrescId: any) {
        this.PrescRefillId = PrescId;
    }
    @Input()
    set totalRxInfo(totalInfo: any) {
        this.TotalRxInfoSelected = totalInfo;
    }
    @Input() totalRecordsCount: any;
    @Output()
    CloseRphModal = new EventEmitter();
    @Output() emitRxInfo = new EventEmitter<any>();
    @Output() emitRxDet = new EventEmitter();
    // @Output() emitprescId = new EventEmitter<any>();
    @ViewChild("DRUGALLERGIES", { static: true })
    DRUGALLERGIES: any;

    @ViewChild("DRUGDISEASECONTRIND", { static: true })
    DRUGDISEASECONTRIND: any;

    @ViewChild("DRUGTODRUGINTERACTION", { static: true })
    DRUGTODRUGINTERACTION: any;

    @ViewChild("DUPLICATEDRUG", { static: true })
    DUPLICATEDRUG: any;

    @ViewChild("DRUGPREGNENCY", { static: true })
    DRUGPREGNENCY: any;

    @ViewChild("FILEDORTOUT", { static: true })
    FILEDORTOUT: any;

    @ViewChild("ADVERSEDRUG", { static: true })
    ADVERSEDRUG: any;

    @ViewChild("LIFESTYLE", { static: true })
    LIFESTYLE: any;

    @ViewChild("AlreadyVerif", { static: true })
    AlreadyVerif: any;

    @ViewChild("ACBSCOREWARNING", { static: true })
    ACBSCOREWARNING: any;

    @ViewChild("RxDiscontinued", { static: true })
    RxDiscontinued: any;
    @ViewChild("RxNotesLogforRph", { static : true })
    RxNotesLogforRph : any;
    @ViewChild("AuthReqPopup", {static: true})
    AuthReqPopup: any;
    modelRef: any;
    detailsInfo: any;
    effectiveDate: any;
    responseState: any;
    verifyData: any;
    insurDetails: any;
    rxInfo: any;
    selectedRxId: any;
    selectedRefilId: any;
    rphFilters: any;
    IsFollowup = false;
    IsNotes = false;
    drugInteractions: DrugInteraction;
    drugToDrugInteractions: DrugToDrugIntractn;
    drugDeseContrd: DiseaseContradication;
    drugPregInfo: PregnancyLactationInfo;
    systemData: SystemData;
    dawValue: string;
    Rx_origin: string;
    activeModal: any;
    adminRx = false;
    exprdReflsCnt: any;
    pdfToShow: any;
    actvHeaders: string[];
    imageToShow: any;
    documentAvailable: boolean;
    documentId: any;
    fileTypeToShow: string = null;
    expandHist = true;
    notesType: any;
    filedRxsCnt: any;
    awaitingPickUpCnt: any;
    neweRxsCnt: any;
    accessPrivCate: any;
    awtngPhrmVerftcnCnt: any;
    currentDocIndex: any;
    modalAlreadyVerf: any;
    modelDiscont: any;
    prevIndex: any;
    nextIndex: any;
    totalRecordsVerf: any;
    previousbtnIndex: any;
    nextbtnIndex: any;
    selectlist: any;
    payRecords$: Observable<any>;
    isCash$: Observable<any>;
    rxFG: FormGroup;
    notes: any;
    isCashRx: boolean;
    drugStatus: any;
    fillNumber: any;
    fillNumberDisplay: any;
    selectedVerfHist: any;
    drugPickVerfStatus: any;
    alrtTyp:AlertTypes = new AlertTypes();
    isHoa:boolean;
    privCheckForNDCChange: any;
    isLogicoyReg: any;
    PrescAddress: any;
    unsubscribe$: Subject<void> = new Subject();
    emptyImage: boolean;
    systemSetng$: Observable<any>;
    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey) {
        if (event.which === 77) { // m
                event.preventDefault();
                this.verifyRph("VerifyNext");
            }
            // else if (event.which === 69) { // e
            //     event.preventDefault();
            //     this.goToEditRx();
            // } else if (event.which === 84) { // t
            //     event.preventDefault();
            //     this.goToPatHist();
            //     if (!this.rphFmDph) {
            //     event.preventDefault();
            //     }
            //     this.getPrev();
            // }
             else if (event.which === 78) { // n
                event.preventDefault();
                this.getNext();
            } else if (event.which === 73) { // i
                event.preventDefault();
                this.openTrans();
            }
            else if (event.which === 72) { // h
                event.preventDefault();
                this.openHistoryPopUp();
            }
        } else  if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            this.modelRef.close();
            this.modelRef = null;
            event.preventDefault();
        }

    }

    constructor(
        private _modalService: NgbModal,
        private _docService: DocumentsService,
        private _cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private _rphService: RphVerificationService,
        private _alertService: AlertService,
        private _activeRoute: ActivatedRoute,
        public _commonService: CommonService,
        private _router: Router,
        private _cmnStore: CommonStore,
        private _patPreDrugService: PatPreDrugModalService,
        private _nrxUtils: NRxUtils,
        private _auditUtils: AuditLogUtils,
        private _nrxUtls: NRxUtils,
        private _commonPrintSer: CommonPrintService,
        private _privMaskGuard: PrivMaskGuard,
        private _rxStore: RxStore,
        private _cmnlgRxInfo: CommonLogicRxInfoUtil,
        private _rxService:RxService, private _transServ: TransmissionService,
        private _patchRx: PatchRXFGUtil, private _rxBilling: RxBillingUtil,
        private _labelQueueService: LabelQueueService,
        private _fb: FormBuilder,
        private _commonUtil: CommonUtil,
        private _notesLog: NotesLogService,
        private _alrtSev: AlertService,
        public _formatsUtil:FormatsUtil,
        private _logicoy: LogicoyService, public _buckUtils: DrugBucketUtil
    ) {
        this.getSystemData();
        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;
        this.postDoc = new DocumentInputParams();
        this.postDoc.DocCatId = SharedDocuments.Prescription;
        this.settingpostFG = this._fb.group({
            SearchText: "",
            SearchBy: "",
            OrderByColumn: "",
            SortDirection: "",
            PageNumber: 0,
            PageSize: 0
        });
        this.searchFG = this._fb.group({
            RphAndRxVerificationForPatientReviewOverXdays: null
        });
        this.drugInfoDet = this._rxStore.edtDrugInfo$["source"]["value"];
        this.LoginVerifyFG = this._fb.group({
            UserName: null,
            Password: null
        });
    }

    ngOnInit() {
        this.isLogicoyReg = this._commonService.PMPNow$["source"]["value"];
        this.isHoa = environment.isHoa;
        this.getSettings();
        // if (!this.isRxVerification) {
        //     this.getPresentUrl();
        // }
        this.actvHeaders = ["Actions" , "FileName"];
        this.privCheckForNDCChange  = this._nrxUtls.getNDCChangeOverRidePriv();
        this.hasOvrRidPrv  = this._nrxUtls.getPrscrbrOverRidePriv();
        this.hasOvrRidPrvP  = this._nrxUtls.getPatientOverRidePriv();
        if (!this.isPatientHistory) {
            // this._activeRoute.params.subscribe(params => {
                // if (params["rxid"]) {
                    this.selectedRxId = this.rxid;
                    this.selectedRefilId = this.refilId;
                    this.selectedFillId = this.FillID;
                    this.totalRecordsVerf = parseInt(this.totalRecordsCount, 0);
                    this.previousbtnIndex = 0;
                    this.nextbtnIndex = this.totalRecordsVerf - 1;
                    this.getVerifyDetailsById(false, true);
                // }
            // });
        } else {
            this.selectedRxId = this.rxid;
            this.selectedRefilId = this.refilId;
            this.selectedFillId = this.FillID;
            this.index = 0;
            this.prevIndex = 0;
            this.nextIndex = this.rxInfoList && this.rxInfoList.length ? (this.rxInfoList.length - 1) : 0;
            this.getVerifyDetailsById(false, true);
        }
        if (sessionStorage.getItem("RphFmDPV") && sessionStorage.getItem("RphFmDPV") === "true") {
            this.rphFmDph = true;
        } else if (sessionStorage.getItem("RphFmDPV") && sessionStorage.getItem("RphFmDPV") !== "true") {
            this.rphFmDph = sessionStorage.getItem("RphFmDPV");
        } else {
            this.rphFmDph = false;
        }
        this.rphFilters = this._cmnStore.rphFilters$["source"]["value"];
        this.afterLabelPrintCompleted();
    }

    get DRUG(): FormGroup {
        return this.rxFG.get("Drug") as FormGroup;
    }

   async getSettings() {
           //To get latest pharmacy settings data to patch for patient review field.
        // await this._commonService.getSystemSettingsWithBS(this.settingpostFG.value);
        this.searchFG.controls["RphAndRxVerificationForPatientReviewOverXdays"].patchValue(this._commonService.getSetttingValue("PharmacySettings", "DEF_DAYS_RPH_VERIF_PAT_REVIEW", this.systemSetng$["source"]["value"]));
    }
    toggleAdminRx() {
        this.adminRx = !this.adminRx;
    }
  navigateToNextImage(next?) {

        let index = 0;
        if (next) {
            if (this.currentDocIndex === (this.totalDocs.length - 1)) {
                index = 0;
            } else {
                index = this.currentDocIndex + 1;
            }
        } else {
            if (this.currentDocIndex === 0) {
                index = (this.totalDocs.length) - 1;
            } else {
                index = (this.currentDocIndex) - 1;
            }
        }
        this.previewSelectedFile(this.totalDocs[index]);
    }
     showActionButtons() {
        let data = {
            PatientId: this.verifyData && this.verifyData["Patient"] && this.verifyData["Patient"].patientid
             ? this.verifyData["Patient"].patientid : null,
            Fromdate: moment().format("MM/DD/YYYY"),
            FirstName: this.verifyData && this.verifyData["Patient"] && this.verifyData["Patient"].firstname
             ? this.verifyData["Patient"].firstname : null,
            LastName: this.verifyData && this.verifyData["Patient"] && this.verifyData["Patient"].lastname
             ? this.verifyData["Patient"].lastname : null,
            BirthDt: this.verifyData && this.verifyData["Patient"] && this.verifyData["Patient"].dob ?
            this.verifyData["Patient"].dob : null,
            RphAndRxVerificationForPatientReviewOverXdays: this.searchFG &&
            this.searchFG.value.RphAndRxVerificationForPatientReviewOverXdays ?
            this.searchFG.value.RphAndRxVerificationForPatientReviewOverXdays : "30",
            fromRph: true
        }
        this.RphAndRxVerificationForPatientReviewOverXdays = this.searchFG &&
        this.searchFG.value.RphAndRxVerificationForPatientReviewOverXdays ?
        this.searchFG.value.RphAndRxVerificationForPatientReviewOverXdays : "30"
        this._rphService.getActioButtonsCount(data)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.refillsDueCnt = resp.RefillDue;
                this.exprdReflsCnt = resp.ExpiredRxs;
                this.neweRxsCnt = resp.NeweRxs;
                this.filedRxsCnt = resp.FiledRxs;
                this.awaitingPickUpCnt = resp.AwaitingPickUp;
                this.awtngPhrmVerftcnCnt = resp.AwaitingPharmacistVerifictation;
            }
        })
    }

    openModalForAll(switchVal,modalType,val) {
        if (switchVal === 6 && val >0) {
            this.getNewErxs();
        } else if (val > 0){
            this.openPopUp = true;
            this.activeModal = modalType;
            this.switchValue = switchVal;
        } else {
            this._alertService.error("Since the Count is zero, Cannot perform this action");
        }
    }

    getNewErxs() {
        const modelRef = this._modalService.open(ErxComponent,
            { size: "lg", keyboard: false, backdrop: "static", windowClass: "md-x1-lg" });
        modelRef.componentInstance.IsPopErxModel = false;
        modelRef.componentInstance.FromRph = true;
        modelRef.componentInstance.RxInfo = this.verifyData;
        modelRef.componentInstance.ReviewXDays = this.searchFG && this.searchFG.value.RphAndRxVerificationForPatientReviewOverXdays ?
        this.searchFG.value.RphAndRxVerificationForPatientReviewOverXdays : "30"
        modelRef.componentInstance.CloseErxMessageLinkModal
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                modelRef.close();
            }
        });
    }

    closeActnHstPop(eve) {
        this.openPopUp = eve;
    }

    openRxAdmin() {
        const modalRef: any = this._modalService.open(AdminRxComponent, {
            backdrop: false,
            size: "lg",
            keyboard: false
        });
        modalRef.componentInstance.OpenFrom = "RphVerification";
        modalRef.componentInstance.RxInfo = this.verifyData;
        modalRef.componentInstance.CloseAdminRxPopUp
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(v => {});
    }

    getSystemData() {
        this._commonService.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.systemData = resp;
        });
        this.systemSetng$ = this._commonService.systemSettings$;
    }

    async getNext() {
        sessionStorage.removeItem("RxAdmDosSchInf");
        sessionStorage.removeItem("RxAdmDosSchFilt");
        if (this.rphFilters) {
            this.rphFilters.selectedIndex = this.rphFilters.selectedIndex + 1;
        } else {
            this.rphFilters = new RphFilters();
            this.rphFilters.PageNumber = 1;
        }
        const data = Object.assign({}, this.rphFilters);
        data["PageNumber"] = this.rphFilters.selectedIndex ? ((this.rphFilters.PageNumber + this.rphFilters.selectedIndex)) : this.rphFilters.PageNumber;
        data["PageSize"] = 1;
        if (this.isPatientHistory && this.rxInfoList && this.rxInfoList.length && this.rxInfoList[this.index + 1]) {
            this.selectedRxId = this.rxInfoList[this.index + 1].Prescription.PrescNum;
            this.selectedRefilId = this.rxInfoList[this.index + 1].PrescReFill.ReFillNum;
            this.selectedFillId = this.rxInfoList[this.index + 1].PrescReFill.PartialFillNo;
            this.selectedPrescRefId = (this.selectedPrescRefId ? (this.selectedPrescRefId + "," + (this.rxInfoList[this.index + 1].PrescReFill.Id)) :
             (this.rxInfoList[this.index + 1].PrescReFill.Id)).toString();
            this.selectlist = this.selectedPrescRefId.split(",");
            this.nextClicked = true;
            const warning: any = await this._cmnlgRxInfo.checkotherVerifications(this.isRxVerification, this.rxInfoList[this.index + 1], false, true);
            this.index = this.index + 1;
            this.nextIndex = this.nextIndex - 1;
            this.prevIndex = this.prevIndex + 1;
            await this.checkVerifications(warning);
        } else {
            this.previousbtnIndex = this.previousbtnIndex + 1;
            this.nextbtnIndex = this.nextbtnIndex - 1;
            this._rphService
            .getRphVerifyDetails(data, this.isRxVerification)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async resp => {
                if (resp && resp.data && resp.data.length > 0) {
                    this.result = resp.data[0];
                    this.emitRxDet.emit(this.result);
                    this.emitRxInfo.emit(this.result);
                    this.selectedRxId = resp.data[0].Prescription.PrescNum;
                    this.selectedRefilId = resp.data[0].PrescReFill.ReFillNum;
                    this.selectedFillId = resp.data[0].PrescReFill.PartialFillNo;
                    // this.getVerifyDetailsById();
                    const warning: any = await this._cmnlgRxInfo.checkotherVerifications(this.isRxVerification, resp.data[0], false, false);
                    await this.checkVerifications(warning);
                }
            });
        }
    }

    checkVerifications(warningMsg) {
    if (warningMsg) {
        if (
            warningMsg &&
            warningMsg !== "DiscontinuedRx" &&
            warningMsg["Status"] &&
            (warningMsg["Status"].trim() === "V" ||
                warningMsg["Status"].trim() === "R")
        ) {
            this.verifiedAlready = warningMsg;
            this.fillNoAlreVerf = this._commonService.checkIsPartialFill(this.rxInfo.PrescReFill, this.rxInfo.Prescription)? this.rxInfo.PrescReFill.PartialFillNo : " --"
            this.modalAlreadyVerf = this._modalService.open(this.AlreadyVerif, {
                keyboard: false,
                backdrop: false,
                windowClass: "large--content",
                centered : true
            });
        } else if (warningMsg === "DiscontinuedRx") {
            this.fillNumberDisc = this._commonService.checkIsPartialFill(this.result.PrescReFill, this.result.Prescription)?"-"+(this.result.PrescReFill.PartialFillNo) : "";
            this.modelDiscont = this._modalService.open(this.RxDiscontinued, {
                centered: true,
                backdrop: false,
                keyboard: false,
                windowClass: "large--content",
            });
        } else {
            const modelRef = this._modalService.open(
                CommonWarnorconfirmPopupComponent,
                {
                    backdrop: false,
                    keyboard: false,
                    windowClass: "large--content",
                }
            );
            modelRef.componentInstance.warnMsg = warningMsg;
            modelRef.componentInstance.okButton = true;
            modelRef.componentInstance.IsPopUpClosed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp: any) => {
                modelRef.close();
                this.getVerifyDetailsById();
            });
        }
    } else {
        this.getVerifyDetailsById();
    }
    }
    checkIsDiscontinue() {
        if (this.modalAlreadyVerf) {
            this.modalAlreadyVerf.close();
            this.modalAlreadyVerf = null;
        }
        if (this.result && this.result.Prescription &&  this.result.Prescription.IsDiscontinued) {
            this.fillNumberDisc = this._commonService.checkIsPartialFill(this.result.PrescReFill, this.result.Prescription)?"-"+(this.result.PrescReFill.PartialFillNo) : "";
            this.modelDiscont = this._modalService.open(this.RxDiscontinued,
                 { centered: true, backdrop: false, keyboard: false, windowClass: "large--content"});
        } else {
            this.getVerifyDetailsById();
        }
    }

    async setOrderStatus() {
        if (this.modelDiscont) {
            this.modelDiscont.close();
            this.modelDiscont = null;
        }
        await this._rxService.putRxDiscontinuedOrderStatus(this.result.Prescription.Id).toPromise();
        this.getVerifyDetailsById();
    }

    async getPrev() {
        if ((this.rphFilters && this.rphFilters.selectedIndex) || this.isPatientHistory) {
            sessionStorage.removeItem("RxAdmDosSchInf");
            sessionStorage.removeItem("RxAdmDosSchFilt");
            if (this.rphFilters) {
                this.rphFilters.selectedIndex = this.rphFilters.selectedIndex - 1;
            } else {
                this.rphFilters = new RphFilters();
                this.rphFilters.PageNumber = 1;
            }
            const data = Object.assign({}, this.rphFilters);
            data["PageNumber"] = this.rphFilters.selectedIndex ? ((this.rphFilters.PageNumber + this.rphFilters.selectedIndex)) : this.rphFilters.PageNumber;
            data["PageSize"] = 1;
            if (this.isPatientHistory) {
                this.selectedRxId = this.rxInfoList[this.index - 1].Prescription.PrescNum;
                this.selectedRefilId = this.rxInfoList[this.index - 1].PrescReFill.ReFillNum;
                this.selectedFillId = this.rxInfoList[this.index - 1].PrescReFill.PartialFillNo;
                // this.selectedPrescRefId = this.rxInfoList[this.index - 1].PrescReFill.Id;
                // this.selectedPrescRefId = (this.selectedPrescRefId ? (this.selectedPrescRefId + "," + (this.rxInfoList[this.index - 1].PrescReFill.Id)) :
                // (this.rxInfoList[this.index - 1].PrescReFill.Id)).toString();
                // this.selectlist = this.selectedPrescRefId.split(",");
                this.prevClicked = true;
                const warning: any = await this._cmnlgRxInfo.checkotherVerifications(this.isRxVerification, this.rxInfoList[this.index - 1], false, true);
                this.index = this.index - 1;
                this.nextIndex = this.nextIndex + 1;
                this.prevIndex = this.prevIndex - 1;
                // this.emitprescId.emit(this.rxInfoList[this.index - 1].PrescReFill.Id);
                await this.checkVerifications(warning);
            } else {
                this.previousbtnIndex = this.previousbtnIndex - 1;
                this.nextbtnIndex = (this.totalRecordsVerf - 1 === this.nextbtnIndex) ? this.nextbtnIndex : this.nextbtnIndex + 1;
                this._rphService
                .getRphVerifyDetails(data, this.isRxVerification)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(async resp => {
                    if (resp && resp.data && resp.data.length > 0) {
                        this.result = resp.data[0];
                        this.emitRxDet.emit(this.result);
                        this.emitRxInfo.emit(this.result);
                        this.selectedRxId = resp.data[0].Prescription.PrescNum;
                        this.selectedRefilId = resp.data[0].PrescReFill.ReFillNum;
                        this.selectedFillId = resp.data[0].PrescReFill.PartialFillNo;
                        const warning: any = await this._cmnlgRxInfo.checkotherVerifications(this.isRxVerification, resp.data[0], false, false);
                        await this.checkVerifications(warning);
                    }
                });
            }
        }
    }

    openWarningMsg() {
        this._modalService.open(this.FILEDORTOUT, {centered: true, backdrop: false, keyboard: false, windowClass: "large--content"});
    }

    PMPNow() {
        this._logicoy.PMPNow(this.verifyData.Patient)
    }

    getVerifyDetailsById(refresh?: boolean, popup?, type?, emitInfo?: boolean) {
        this._rphService
            .getRphVerifyDetails({
                PageNumber: 1,
                PageSize: 1,
                RxId: this.selectedRxId,
                RefillNum: this.selectedRefilId,
                PartialfillNum: this.selectedFillId
            }, this.isRxVerification)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async resp => {
                if (resp && resp.data && resp.data[0]) {
                    // if (resp.data[0]["PrescReFill"]["StatusId"] === 2 || resp.data[0]["Prescription"]["RxStatusId"] === 2) {
                    //     this.warningMsg = "Rx is Filed, Cannot be verified.";
                    //     this.openWarningMsg();
                    // } else if (resp.data[0]["PrescReFill"]["StatusId"] === 4 || resp.data[0]["Prescription"]["RxStatusId"] === 4) {
                    //     this.warningMsg = "Rx is Transfered, Cannot be verified.";
                    //     this.openWarningMsg();
                    // } else {
                        this.verifyData = resp.data[0];
                        const data = {
                            rxInfo: this.verifyData,
                            isRRR: type
                        }
                        if (this.verifyData?.Prescriber?.PrescriberAddresses.length > 1) {
                            this.verifyData?.Prescriber?.PrescriberAddresses.map(value => {
                                if(value["Id"] == this.verifyData?.Prescription?.PrescriberAddressId){
                                    this.PrescAddress = value;
                                }
                            });
                            if(!this.PrescAddress){
                                this.verifyData?.Prescriber?.PrescriberAddresses.find(value=>{
                                    if(value["Id"] == this.verifyData?.Prescriber?.primeAddressId){
                                        this.PrescAddress = value;
                                    }
                                })
                            }
                        } else {
                            this.PrescAddress =  this.verifyData?.Prescriber?.PrescriberAddresses["0"];
                        }
                        this.emitRxDet.emit(this.verifyData);
                        if (emitInfo && !this.isRxVerification)
                            this.emitRxInfo.emit(type ? data : this.verifyData);
                        this._rxStore.storeRxDetailsFrVerf(null);
                        this.notes = this.verifyData.Prescription.Remarks;
                        this.fillNumber = this._commonService.checkIsPartialFill(this.verifyData.PrescReFill, this.verifyData.Prescription)?"-"+(this.verifyData.PrescReFill.PartialFillNo) : "";
                        this.fillNumberDisplay = this._commonService.checkIsPartialFill(this.verifyData.PrescReFill, this.verifyData.Prescription)?(this.verifyData.PrescReFill.PartialFillNo) : "-";
                        if (this.verifyData && this.verifyData.DrugStatus) {
                            this.drugStatus = this.verifyData.DrugStatus.trim().toUpperCase() === "P" ? "Pass" :
                            this.verifyData.DrugStatus.trim().toUpperCase() === "F" ? "Fail" :
                            this.verifyData.DrugStatus.trim().toUpperCase() === "RV" ? "Re-Verified" :
                            this.verifyData.DrugStatus.trim().toUpperCase() === "R" ? "Rx Verification Done" :
                            this.verifyData.DrugStatus.trim().toUpperCase() === "N" ? "Not Verified" :
                            this.verifyData.DrugStatus.trim().toUpperCase() === "V" ? "Pharmacist verification Done" : "";
                        }
                        if (this.verifyData && this.verifyData.drugPickVerif && this.verifyData.drugPickVerif.length) {
                            this.drugpickData = this.verifyData["drugPickVerif"];
                                        if (this.drugpickData && this.drugpickData.length > 0) {
                                            this.drugPickVerfStatus = (((["P", "p", "p ", "P "].indexOf(this.drugpickData[this.drugpickData.length - 1].Result) > -1) && !this.drugpickData[this.drugpickData.length - 1].IgnoreInventoryCalculations) ||
                                            (((this.drugpickData.drugPickVerificationStatus)&&["p", "P", "p ", "P "].indexOf(this.drugpickData.drugPickVerificationStatus) > -1) && this.drugpickData.PrescReFill.IsIou))
                                             ? !this.drugpickData[this.drugpickData.length - 1].IsReverified ?  Verificationsenum.verified : Verificationsenum.reverified:
                                             this.drugpickData[this.drugpickData.length - 1].Result && this.drugpickData[this.drugpickData.length - 1].Result.trim().toLowerCase() === "rv" ? "Re-verify" : "Not Verified";
                                            }
                        }
                        if (this.verifyData && this.verifyData.rPHVerifs && this.verifyData.rPHVerifs.length) {
                            this.rxData = this.verifyData.rPHVerifs.filter(v => ((v["Status"]).trim().toLowerCase() === "r" || v["IsForRxVerification"]));
                            this.rphInfoData = this.verifyData.rPHVerifs.filter(v => !((v["Status"]).trim().toLowerCase() === "r" || v["IsForRxVerification"]));
                        }
                        const resp1 = await this._transServ.getPayRecord(this.verifyData.PrescReFill.Id).toPromise();
                        this._transServ.storePayRecords(resp1);
                        this.payRecords$ = this._transServ.payRecords$;
                        this.rxFG = this._patchRx.createNewRxFG();
                        if (this.verifyData) {
                            this._patchRx.patchRxInfo(this.verifyData, this.rxFG);
                        }
                        this.isCashRx = this._rxBilling.checkIsCahsRx(this.rxFG.getRawValue(), this.rxFG.controls["Patient"].value,
                        this.systemData);
                        this.verifyData["Patient"] = await this._commonService.
                        getRxPatientDetailsById(this.verifyData["Patient"]["patientid"]).toPromise();
                        if (this.verifyData && this.verifyData["Patient"] && this.verifyData["Patient"].dob) {
                            const dob = moment(this.verifyData["Patient"].dob);
                            this.years = moment().diff(dob, "year");
                        }
                        this.isPrmptHighRsk = this._nrxUtls.getSetngKeyVal("PatientSettings", "HIGH_RISK_PATIENT");
                        this.geriatricAge = +this._nrxUtls.getSettingValue("PatientSettings", "GERIATRIC_OVER_AGE");
                        this.youthUnderAge = +this._nrxUtls.getSettingValue("PatientSettings", "YOUTH_UNDER_AGE");
                        this.patientAlle = [];
                        if (this.verifyData?.Patient?.patallergies
                            && this.verifyData.Patient.patallergies.length > 0) {
                            this.verifyData.Patient.patallergies.forEach(element => {
                                        this.patientAlle.push(element["Name"]);
                            });
                        }
                        this.rxInfo = resp.data[0];
                        if (resp.data[0].XmlFileId && !refresh) {
                            this.getRxVerifyDetails(resp.data[0].XmlFileId, resp.data[0].XmlVersion);
                        }
                        this.getDocuments();
                        this.getDrugImage();
                        this.getDrugInteractionInfo();
                        this.getInsDetails();
                        this.showActionButtons();
                        const dawObj = this.systemData.DAW.find(daw => daw.Id === this.verifyData.PrescReFill.DAWId);
                        if (dawObj) {
                            this.dawValue = dawObj.Code;
                        }
                        const rxObj = this.systemData.RxOrigin.find(rxOrigin => rxOrigin.Id === this.verifyData.PrescReFill.RxOriginId);
                        if (rxObj) {
                            this.Rx_origin = rxObj.Name;
                        }
                        this.generateLabelData();
                        this.StatusId = this._commonUtil.getRxStatusForDisplay( this.verifyData);
                        if(popup){
                            this.getNotesLogFromRxtoRph();
                        }
                        if (this.verifyData && this.verifyData.Iou?.length) {
                            this.diffNDCCnt = 0;
                            this.differentNDc = [];
                            this.verifyData.Iou.map((item: any) => {
                                if(item.DispQty==this.verifyData.PrescReFill.DispQty){
                                    if(this.verifyData.PrescReFill.IsEquivalentDisp){
                                        this.diffNDCCnt++;
                                        this.dispensedDrgNm = item.DrugName
                                        this.dispensedNDC = item.DrugNDC;
                                        this.differentNDc.push(item.DrugNDC);
                                    }
                                }
                                else{
                                    if(item.DrugNDC !==  this.verifyData.Drug.drugcode) {
                                        this.diffNDCCnt++;
                                            this.differentNDc.push(item.DrugNDC);
                                       }
                                }
                            })
                            this.notEqvList = this.verifyData.Iou.find((item) => item.NotEquivalent)
                            this.controlDrug = (this.verifyData.Iou[this.verifyData.Iou.length-1]["DrugClass"] >= 2 && this.verifyData.Iou[this.verifyData.Iou.length-1]["DrugClass"] <= 5) ? true : false;
                        } else {
                            this.diffNDCCnt = 0;
                        }
                        this.isPaidRecordsAv  = this._commonUtil.checkPayRecExistOrNotForSelcRx(this.rxFG.value["RxPay"] );
                        if (this.TotalRxInfoSelected && this.TotalRxInfoSelected.length > 0) {
                            this.grpVerfRxs = this.TotalRxInfoSelected.map(x => {
                                        return (x.Prescription.PrescNum + "-" + x.PrescReFill.ReFillNum + 
                                        (this._commonService.checkIsPartialFill(x.PrescReFill, x.Prescription) ? "-"+ x.PrescReFill.PartialFillNo : ""));
                            });
                        }
                    // }
                    const microSvcUrlToken = sessionStorage.getItem("CmnMicroSvcToken") ? JSON.parse(this._commonService.DecryptData(sessionStorage.getItem("CmnMicroSvcToken"))) : "";
                    if (environment.isClinicalForm && microSvcUrlToken) {
                        const dataForm = {
                            prescrefillId: this.verifyData.PrescReFill.Id,
                            patientId: this.verifyData["Patient"].patientid
                        }
                        this.formEntDetails = await this._commonService.getFormsWithEntity(dataForm).toPromise();
                        console.log(this.formEntDetails, "FormDet")
                        if (this.formEntDetails?.length) {
                            this.displayFormBtn = this.verifyData.DrugCategoryId?.length ? this.verifyData.DrugCategoryId.includes(this.formEntDetails[0].identifier)
                            : false;
                        }
                    }
                } else {
                    this.warningMsg = "Rx not Found / You can't do RPH verification for the given Rx.";
                    this.openWarningMsg();
                }
            });
    }
    async recallFormEntity() {
        this.openAssessmentForm = false;
        const dataForm = {
            prescrefillId: this.verifyData.PrescReFill.Id,
            patientId: this.verifyData["Patient"].patientid
        }
        this.formEntDetails = await this._commonService.getFormsWithEntity(dataForm).toPromise();
    }
    getInsDetails() {
        const ins = this.verifyData.Patient.insurance;
        this.insurDetails = ins.find(list => list.insuranceid == this.verifyData.Patient.insuranceid );
    }

    openPrescriberModal() {
        const modalRef = this._modalService.open(PrescriberComponent, {
            backdrop: false,
            size: "lg",
            windowClass: "md-xx-lg comp--modal",
            keyboard: false
        });
        modalRef.componentInstance.prescriberID = +this.verifyData.Prescriber.prescriberid;
        modalRef.componentInstance.ClosePrescriberModal
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                modalRef.close();
                if (+this.verifyData.Prescriber.prescriberid) {
                   this.getVerifyDetailsById(true);
                }
            }
        });
    }
    openNotesModel(notesType) {
        this.notesType = notesType;
        if (!notesType) {
            this.getVerifyDetailsById(true);
        }
    }
    async getNotesLogFromRxtoRph(){
        const PrescReFill = this.verifyData.PrescReFill;
        const Prescription = this.verifyData.Prescription;
        this.notesLogForRphfromRx = await this._nrxUtils.getNotesLog("RxAnnotation",this.verifyData.Prescription.Id, {
            RxType: 'rph',
            PrescReFill : PrescReFill?.["Id"], 
            PrescNum : Prescription?.["PrescNum"]
        }) as any;
        if( this.notesLogForRphfromRx && this.notesLogForRphfromRx?.Note)
        this.notesLogForRphfromRx.Note = this.notesLogForRphfromRx.Note.concat(this.notesLogForRphfromRx.AnnotationNotes);
        if(this.notesLogForRphfromRx?.Note?.filter(ele => ele.IsShownAsPopup).length){
            this.modelRefRph = this._modalService.open(CommonNotesLogComponent,{keyboard : false, backdrop : false, centered : true , windowClass: "max-modal-970"});
            this.modelRefRph.componentInstance.NotesListFrmPage = {
                Note: this.notesLogForRphfromRx .Note.filter((item: any) => {
                    return item.IsShownAsPopup;
                })
            };
            this.modelRefRph.componentInstance.RxType =  "rph";
            this.modelRefRph.componentInstance.IsNotesLogPopupClosed.subscribe((res : any)=>{
            this.modelRefRph.close();
            })
        }
    }
    closeModal(){
        if(this.modelRefRph){
            this.modelRefRph.close();
            this.modelRefRph = null;
        }
        if (this.modelRef) {
            this.modelRef.close();
        }
    }
    openOveride(CategoryType, type) {
        const modelRef = this._modalService.open(OverrideLogComponent, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
        modelRef.componentInstance.PrivMask = type;
        modelRef.componentInstance.CatId = CategoryType;
        modelRef.componentInstance.Comp = true;
        modelRef.componentInstance.isConsolidated = true;
        modelRef.result.then(res => {
            modelRef.close();
            if (res) {
                if (CategoryType === "PrescriberFile") {
                    this.openPrescriberModal();
                } else if (CategoryType === "PatientFile") {
                    this.editPatient();
                } else if (CategoryType === "PharmacistVerification") {
                    this.verifyRph('verify')
                }
            }
        });
    }
    editRxScreen(type?: any) {
        if (this.checkHasPrivsToAccess(this.accessPrivCate.Rx, this.rxPrivs.EditRx )) {
            if (this.verifyData.Iou?.length && this.verifyData.Iou[0].NotEquivalent && this.dispensedNDC && type) {
                const warningMsg = "Drug " + "<b>" + this.dispensedDrgNm + "</b>, NDC# " + "<b>" + this._formatsUtil.getNDCFormat(this.dispensedNDC) + "</b>" + " is not equivalent to this Drug " + "<b>" + this.verifyData?.Drug?.drugfullname + "</b>, NDC# <br><b>" + this._formatsUtil.getNDCFormat(this.verifyData?.Drug?.drugcode) + "</b>." +"<br>"+"Do you wish to proceed with the changes?"
                const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                    backdrop: false,
                    keyboard: false, windowClass: "large--content"
                    });
                    modelRef.componentInstance.warnMsg = warningMsg;
                    modelRef.componentInstance.IsHeaderText = "Confirmation";
                    modelRef.componentInstance.yesButton = true;
                    modelRef.componentInstance.noButton = true;
                    modelRef.componentInstance.IsPopUpClosed
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((response: any) => {
                        modelRef.close();
                        if (response) {
                            this.frmRRR = type;
                            this.modelRefAuth = this._modalService.open(this.AuthReqPopup, {centered: true, backdrop: false});
                        }
                    });
            } else {
                if (this.verifyData && this.verifyData.Prescription.PrescNum && this.verifyData.PrescReFill.Id) {
                    this.routeToRx(+this.verifyData.Prescription.PrescNum, +this.verifyData.PrescReFill.Id, null, type);
                }
            }
        } else {
            this._alertService.error(MsgConfig.NO_PRIVS_ACCESS, false, "long");
        }
        
    }

    async routeToEditRx(rxInfo, isNPPatientValue?: string, type?:any) {
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
            const modalRef = this._modalService.open(
                EdRxComponent,
                {
                    backdrop: false,
                    size: "lg",
                    windowClass: "md-xx-lg comp--modal",
                    keyboard: false
                }
            );
            modalRef.componentInstance.SelectedValue = (isNPPatientValue && isNPPatientValue === "false") ? isNPPatientValue : "true";
            // modalRef.componentInstance.OpenReverse = type;
            modalRef.componentInstance.rejectedTransRevsInfo  = this.rejectedTrans;
            modalRef.componentInstance.CloseEditMode
            .pipe(takeUntil(this.unsubscribe$))
                .subscribe(x => {
                    if (x) {
                        modalRef.close();
                        const chngdRxInfo = this._rxStore.rxDetFrVerf$["source"]["value"] ? this._rxStore.rxDetFrVerf$["source"]["value"] : (localStorage.getItem("RxInfoFrmRRR") ? JSON.parse(this._commonService.DecryptData(localStorage.getItem("RxInfoFrmRRR"))) : null);
                        this.selectedRxId = chngdRxInfo ? chngdRxInfo?.Prescription.PrescNum : this.selectedRxId;
                        this.selectedRefilId = chngdRxInfo ? chngdRxInfo?.PrescReFill.ReFillNum : this.selectedRefilId;
                        this.selectedFillId = chngdRxInfo ? chngdRxInfo?.PrescReFill.PartialFillNo : this.selectedFillId;
                        this.getVerifyDetailsById(true, false,type, true);
                        localStorage.removeItem("RRRScenario")
                    }
                });
            modalRef.componentInstance.CloseAndReopen
            .pipe(takeUntil(this.unsubscribe$))
                .subscribe(d => {
                    modalRef.close();
                    if (d) {
                        this.routeToRx(d["rxNo"], d["rfId"], d["isNPPatientValue"]);
                    } else {
                        this.getVerifyDetailsById(true, false, type, true);
                    }
                });
        }
    }

    async routeToRx(rxNo?: any, rfId?: any, val?: any, type?: any) {
        let rxInfo: any;
        let response: any;
        const selectedrxNo = rxNo ? rxNo : null;
        const selectedrxId = rfId ? rfId : null;
        if (type) {
          const data = {
            RxId: this.verifyData.Prescription.Id,
            PrescRefillId: this.verifyData.PrescReFill.Id,
            PatientId: this.verifyData.Patient.patientid,
            PrescNum: this.verifyData.Prescription.PrescNum,
            Refillnum: this.verifyData.PrescReFill.ReFillNum,
            Partialfillno:this.verifyData.PrescReFill.PartialFillNo,
            BillStatus:this._commonUtil.getRxStatus(this.verifyData.Prescription.RxStatusId, this.verifyData.PrescReFill.StatusId, this.verifyData.RxBill.StatusId),
            RxBillId: this.verifyData.PrescReFill.RxBillId,
            IsCash: (this.verifyData.insuranceInfo && this.verifyData.insuranceInfo.insurance && this.verifyData.insuranceInfo.insurance.IsCash) ? this.verifyData.insuranceInfo.insurance.IsCash : false
          };
          response = await this._commonService.getrxDataRRR(data).toPromise();
        //  let  nwRxInfo = this._nrxUtls.editRxPatchValues(response?.rxInfo);
        //  this._nrxUtls.createPatchStoreRxFG(nwRxInfo);
        localStorage.setItem("RRRScenario", this._commonService.encryptData("true"));
          this._rxStore.storeRxInfo(response?.rxInfo);
        //   let rxFG = await this._nrxUtils.createPatchStoreRxFG();
        //   rxFG = this._patchRx.patchRxInfo(response?.rxInfo, rxFG);
        //   this._rxStore.StoreRxFG(rxFG);
          this.rejectedTrans = response?.transmitViewModel ? response?.transmitViewModel : null;
          this.selectedRxId = response?.rxInfo ? response?.rxInfo?.Prescription.PrescNum : this.selectedRxId;
          this.selectedRefilId = response?.rxInfo ? response?.rxInfo?.PrescReFill.ReFillNum : this.selectedRefilId;
          this.selectedFillId = response?.rxInfo ? response?.rxInfo?.PrescReFill.PartialFillNo : this.selectedFillId;
        } else {
             rxInfo = await this._nrxUtils.getandStoreRxInfo(selectedrxNo, selectedrxId, false, null, null, null, true,null, null, null,true);
        }
        this.routeToEditRx(response ? response.rxInfo : rxInfo, val, type);
    }
    printLabel() {
        // this.rxInfo.PrescReFill.PartialFillNo  =  this._commonService.checkIsPartialFill(this.rxInfo.PrescReFill.PartialFillNo,
        //     this.rxInfo.PrescReFill.PartialIndStatus) ? this.rxInfo.PrescReFill.PartialFillNo : " - ";
        this._commonPrintSer.printLabelToRx(this.rxInfo, false, false, false, null, "rphrxVerf");
       /* this._commonPrintSer.isLabelPrintCompleted()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
            if (res && (this.rxInfo && this.rxInfo.PrescReFill && this.rxInfo.Prescription &&
                (res.PrescNum === this.rxInfo.Prescription.PrescNum) && (res.PrescReFill === this.rxInfo.PrescReFill.Id))) {
                if (res && (!res.type || res.type === "SaveAsPdf") && res.fromScreen === "rphrxVerf") {
                    if (this.rxInfo || (res.PrescNum === +this.rxInfo["Prescription"]["PrescNum"])) {
                       this._labelQueueService.IsExistInLabelQueue(res.PrescReFill)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(resp => {
                            if (resp) {
                                this.removeFromLabelQueue(res);
                                
                            } else {
                                
                            }
                        });
                    }
                }
            }
        });*/
    }
    afterLabelPrintCompleted() {
        this._commonPrintSer.isLabelPrintCompleted()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
            if (res && (this.rxInfo && this.rxInfo.PrescReFill && this.rxInfo.Prescription &&
                (res.PrescNum === this.rxInfo.Prescription.PrescNum) && (res.PrescReFill === this.rxInfo.PrescReFill.Id))) {
                if (res && (!res.type || res.type === "SaveAsPdf") && res.fromScreen === "rphrxVerf") {
                    if (this.rxInfo || (res.PrescNum === +this.rxInfo["Prescription"]["PrescNum"])) {
                       this._labelQueueService.IsExistInLabelQueue(res.PrescReFill)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(resp => {
                            if (resp) {
                                this.removeFromLabelQueue(res);
                                
                            } else {
                                
                            }
                        });
                    }
                }
            }
          });
      }

    removeFromLabelQueue(data) {
        if (data.type === "SaveAsPdf") {
        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
            });
            modelRef.componentInstance.warnMsg = " Do you want to remove the selected label from the label queue?";
            modelRef.componentInstance.IsHeaderText = "Confirmation";
            modelRef.componentInstance.yesButton = true;
            modelRef.componentInstance.noButton = true;
            modelRef.componentInstance.IsPopUpClosed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((response: any) => {
                modelRef.close();
                if (response) {
                    this._labelQueueService.deleteRxsFromLabelQueue([{PrescRefillId : data.PrescReFill }]).toPromise();
                    this.getLabelCount();
                }
               
            });
        } else if (!data.type) {
            this._labelQueueService.deleteRxsFromLabelQueue([{PrescRefillId : data.PrescReFill }]).toPromise();
            this.getLabelCount();
        }
    }

    getLabelCount() {
        const dateParams = {
            StartDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
            EndDate: moment().subtract(0, "days").format("YYYY-MM-DD")
        };
        this._commonService.getSideNavCounts(dateParams);
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    editPatient() {
        const modalRef = this._modalService.open(PatientComponent, {
            backdrop: false,
            size: "lg",
            windowClass: "md-xx-lg comp--modal modal-center-to-page modal-patient",
            keyboard: false
        });
        modalRef.componentInstance.patientID = +this.verifyData.Patient.patientid;
        modalRef.componentInstance.ClosePatientModal
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                modalRef.close();
               if (+this.verifyData.Patient.patientid) {
                this.getVerifyDetailsById(true);
             }
            }
        });
    }

   async getDocuments() {
        this.postDoc.PrescNum = this.verifyData.Prescription.PrescNum;
        this.postDoc.RefillNum=this.verifyData.PrescReFill.ReFillNum;
        this.postDoc.FillNum=this.verifyData.PrescReFill.PartialFillNo;
        this._docService.getDocList(this.postDoc)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.Documents && resp.Documents.length > 0) {
                this.totalDocs = resp.Documents;
              if (resp.Documents && resp.Documents.length) {
                  if (this.verifyData) {
                    resp.Documents.push({
                        Priority: !this.verifyData.XmlFileId ? 0 : resp.ErxDocumentPriority,
                        data: resp.Documents[0],
                        row: resp.Documents.length,
                         Id: 123456,
                        "Title":resp.Documents[0]["Name"],
                        "Comment" : "",
                          "Remarks" :"",
                          "Extn" : "",
                          "TenantId" : "",
                          "FileName" : !this.verifyData.XmlFileId ? "rxDetails": "erxDetails",
                          "CreatedDtTm" : "",
                          "ModifiedDtTm" : ""
                    });
                  }
              }
                  let document1: any = [], document2: any = [];
                  document1 = resp.Documents.filter((ele: any) => {
                   return (ele.Priority === 0);
                });
                document2 = resp.Documents.filter((ele: any) => {
                    return (ele.Priority !== 0);
                 });
                 document2.sort((a, b) => {
                    return a.Priority  - b.Priority;
                 });
                 resp.Documents = [...document2, ...document1];
                this.previewSelectedFile(resp.Documents[0]);
                this.generateList(resp.Documents);
                this.hasDocuments = true;
            } else {
                this.hasDocuments = false;
                this.totalDocs = [];
                if (this.verifyData ) {
                    this.currentDocIndex = 0;
                    this.totalDocs.push({
                        Priority: !this.verifyData.XmlFileId ? 0 : resp.ErxDocumentPriority,
                        data: "erxDetails",
                        row:0,
                         Id: 123456,
                        "Title": "erxDetails",
                        "Comment" : "",
                          "Remarks" :"",
                          "Extn" : "",
                          "TenantId" : "",
                          "FileName" : !this.verifyData.XmlFileId ? "rxDetails": "erxDetails",
                          "CreatedDtTm" : "",
                          "ModifiedDtTm" : ""
                    });
                    this.fileTypeToShow = this.verifyData.XmlFileId ? "erxDetails" : 'rxDetails';
                  }
            }
        });
    }
    basedonPriority() {
                  let document1: any = [], document2: any = [];
                  document1 = this.totalDocs.filter((ele: any) => {
                   return (ele.Priority === 0);
                });
                document2 = this.totalDocs.filter((ele: any) => {
                    return (ele.Priority !== 0);
                 });
                 document2.sort((a, b) => {
                    return a.Priority  - b.Priority;
                 });
                 this.totalDocs = [...document2, ...document1];
                 console.log(this.totalDocs  , "this.totalDocs this.totalDocs ");
              }
   async previewSelectedFile(val: any) {
         await  this.basedonPriority();
            this.currentDocIndex = this.totalDocs.findIndex((ele: any) => {
                return (ele.Id === val.Id);
            });
        this.currentDocIndex = (this.currentDocIndex === -1) ? 0 : this.currentDocIndex;
        if (val && (val.FileName === "erxDetails" || val.FileName === "rxDetails")) {
            this.fileTypeToShow = val.FileName;
        } else if (val &&  val.FileName !== "erxDetails") {
            this.pdfToShow = null;
            this.imageToShow = null;
            this.documentAvailable = false;
            this.documentId = val.Id;
            this._docService
                .getDocumentByName(val)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        if (resp.type === "application/pdf") {
                            this.createPdfFromBlob(resp);
                        } else {
                            this.createImageFromBlob(resp);
                        }
                    }
            });
        }
    }

    createPdfFromBlob(image: Blob) {
        this.fileTypeToShow = "pdf";
        const urlCreator = window.URL;
        this.pdfToShow = this.sanitizer.bypassSecurityTrustResourceUrl(
            urlCreator.createObjectURL(image) + "#toolbar=0"
        );
        this.documentAvailable = true;
    }

    createImageFromBlob(image: Blob) {
        this.fileTypeToShow = "image";
        const urlCreator = window.URL;
        this.imageToShow = this.sanitizer.bypassSecurityTrustResourceUrl(
            urlCreator.createObjectURL(image)
        );
        this.documentAvailable = true;
    }

    generateList(data: any) {
        // this.documentAvailable = false;
        if (data && data.length > 0) {
            this.documentsList = new CollectionView(
                data.map((document, i) => {
                    const j = {};
                    j["data"] = document;
                    j["row"] = i;
                    j["Id"] = document.Id;
                    j["Document ID"] = document.Id;
                    j["Title"] = document.Name;
                    j["Comment"] =
                        document.Remarks === "null" ? "" : document.Remarks;
                    j["Extn"] = document.Extn;
                    j["FileName"] = document.FileName;
                    j["TenantId"] = document.TenantId;
                    j["CreatedDtTm"] = document.CreatedDtTm;
                    j["ModifiedDtTm"] = document.ModifiedDtTm;
                    // if (this.documentId === document.Id) {
                    //     this.documentAvailable = true;
                    // }
                    return j;
                })
            );
            this.previewSelectedFile(0);
        } else {
            this.documentsList = new CollectionView([]);
        }
    }

    generateLabelData() {
        if (this.verifyData) {
            if (this.verifyData.Prescriber) {
                this.prescriberName =
                    this.verifyData.Prescriber.fullname
                        ? (this.verifyData.Prescriber.fullname).replace("_", " ")
                        : null;
            }
            if (this.verifyData.Patient) {
                this.patientName =
                    this.verifyData.Patient.fullname
                        ? (this.verifyData.Patient.fullname).replace("_", " ")
                        :  null;
            }
        }
    }

    getRxVerifyDetails(XmlFileId: number, XmlVersion: string) {
        if (!XmlVersion) {
            const versionsetting = this._commonService.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
            if (versionsetting === "2") {
                this.eRxVersion = ErxVersions.NewerVersion;
            } else {
                this.eRxVersion = ErxVersions.OldVersion;
            }
        } else {
            this.eRxVersion = XmlVersion;
        }
        this._rphService.getRxVerifyDetails(XmlFileId, this.eRxVersion)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.detailsInfo = resp;
                this.effectiveDate = this.detailsInfo.EffectiveDate1;
                if (this.effectiveDate === "0001-01-01T00:00:00") {
                    this.effectiveDate = null;
                }
                this.responseState = this.detailsInfo.PrsOrdRef;
            } else {
                this.detailsInfo = null;
            }
        });
    }

    getDrugImage() {
        this._commonService
        .getDrugInfo(this.rxInfo.Drug.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.drugInfoDet = resp;
                if (this.drugInfoDet?.ImageName) {
                    const drugImageFileSplit = this.drugInfoDet.ImageName.split(".");

                    this._commonService
                        .isFileExist(
                            drugImageFileSplit[0],
                            drugImageFileSplit[1]
                        )
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(isExist => {
                            if (isExist) {
                                this.emptyImage =false;
                                this.imgURL = drugImage.replace(
                                    "{imagefile}",
                                    "" +
                                    drugImageFileSplit[0] +
                                    "." +
                                    drugImageFileSplit[1]
                                );
                            } else {
                                this.emptyImage =true;
                                this.imgURL = drugImage.replace(
                                    "{imagefile}",
                                    "empty.jpg"
                                );
                            }
                        });
                } else {
                    this.emptyImage =true;
                    this.imgURL = drugImage.replace("{imagefile}", "empty.jpg");
                }
            } else {
                this.emptyImage =true;
                this.imgURL = drugImage.replace(
                    "{imagefile}",
                    "empty.jpg"
                );
            }
        });
    }

    getDrugInteractionInfo() {
        if(this.rxInfo){
        const dob = moment(this.rxInfo?.Patient?.dob ? this.rxInfo.Patient.dob : null);
        const days = moment().diff(dob, "days");
        const drugIntData: DrugInteractionData = {
            drugId: this.rxInfo.Drug.id,
            drugTypeId: this.rxInfo.Drug.drugtypeid,
            patientId: this.rxInfo.Patient?.patientid,
            gender: this.rxInfo.Patient.gender,
            islong: true,
            languageCulture: "es-US",
            calledFrom: "RxEntry",
            patLanguage: this.rxInfo.Patient?.langname,
            isFromEditRx: this.rxInfo.rxType == "er" ? true : false,
            isForCount: true,
            ageInDays: days,
            therapeuticCode: this.rxInfo.Drug.therapeuticcode,
            drugClass: this.rxInfo.Drug.drugclass,
            CHECK_DRU_AND_DELIVERY: this._nrxUtils.isSafeForLabrDelivrySet(),
            CHECK_DRU_BEARING_AGE: this._nrxUtils.isChildBearingAgeSet(),
            USE_ACB_SCORING: this._nrxUtils.getSetngKeyVal("PatientSettings", "USE_ACB_SCORING"),
            drugNdc: this.rxInfo.Drug.drugcode,
            diagnosisList: this.rxInfo.Patient?.Diagnosis,
            ddiScanDays: this._nrxUtils.getSettingValue("PatientSettings", "DDI_SCAN_DAYS"),
            INCIDENCE: this._nrxUtils.getSettingValue("PatientSettings", "INCIDENCE"),
            ON_SET: this._nrxUtils.getSettingValue("PatientSettings", "ON_SET"),
            SEVERITY_LEVEL: this._nrxUtils.getSettingValue("PatientSettings", "SEVERITY_LEVEL"),
            USE_ADV_DRUG_REACTION: this._nrxUtils.getSettingValue("PatientSettings", "USE_ADV_DRUG_REACTION"),
            DRUGS_WIT_WARN_ONLY: this._nrxUtils.getSettingValue("PatientSettings", "DRUGS_WIT_WARN_ONLY")
        }
        this._rphService
            .getDrugInteractions(
               drugIntData
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.drugInteractions = resp;
                    if (this.drugInteractions &&
                        this.drugInteractions.DiseaseContradication &&
                        this.drugInteractions.DiseaseContradicationCount
                    ) {
                        this.diseaseHeading = Object.keys(this.drugInteractions.DiseaseContradication.DrillDownData).reverse();
                        this.drugDeseContrd = this.drugInteractions.DiseaseContradication;
                        this.alrtTyp.isDDC = true;
                    }
                    if (this.drugInteractions &&
                        this.drugInteractions.DrugToDrugInteraction &&
                        this.drugInteractions.DrugToDrugInteraction.length > 0 && this.drugInteractions.DrugToDrugInteractionCount
                    ) {
                        this.alrtTyp.isDDI = true;
                        this.drugToDrugInteractions1 = this.drugInteractions.DrugToDrugInteraction[0];
                    }
                    if (this.drugInteractions &&
                        this.drugInteractions.PregnancyLactationInfo &&
                        this.rxInfo.Patient.Gender === "Female"
                    ) {
                        this.alrtTyp.isPRG = true;
                        this.drugPregInfo = this.drugInteractions.PregnancyLactationInfo;
                    }

                    if (this.drugInteractions && this.drugInteractions.Allergies && this.drugInteractions.AllergiesCount) {
                        this.alrtTyp.isAllergy = true;
                        //const res = this.drugInteractions.Allergies.AllergyInfo.replace(/<br\s*[\/]?>/gi, "");
                        this.drugAllergyData=this.drugInteractions.Allergies.AllergyInfo;
                        // this.drugAllergyData = res.replace(/The patient is allergic to drug/gi, "The patient is allergic to drug");
                        // this.drugAllergyData = res.replace(/The selected drug /gi, "<br/>The selected drug ");
                        // const allergyName = this.drugAllergyData.match(/'([^']+)'/g);
                        // if (allergyName && allergyName.length > 0) {
                        //     allergyName.map((name) => {
                        //         this.drugAllergyData = this.drugAllergyData.replaceAll(name, "<span>" + "<b>" + name + "</b>" +  "</span>");
                        //         this.drugAllergyData = this.drugAllergyData.replaceAll(",", ", ");
                        //     });
                        // }
                    }
                    if(this.drugInteractions && this.drugInteractions.HasAcbScore){
                        const rxInfo = this.rxFG.getRawValue();
                        const isUseACBScore = this._nrxUtls.getSetngKeyVal("PatientSettings", "USE_ACB_SCORING");
                        const acbScore = +this._nrxUtls.getSettingValue("PatientSettings", "ACB_SCORE_VALUE");
                        const abcCond = +this._nrxUtls.getSettingValue("PatientSettings", "ACB_SCORE_CONDITION");
                        const geriatricAge = +this._nrxUtls.getSettingValue("PatientSettings", "GERIATRIC_OVER_AGE");
                        const isPrmptHighRsk = this._nrxUtls.getSetngKeyVal("PatientSettings", "HIGH_RISK_PATIENT");
                        const youthUnderAge = +this._nrxUtls.getSettingValue("PatientSettings", "YOUTH_UNDER_AGE");
                        const pregantPatient = +this._nrxUtls.getSettingValue("PatientSettings", "PREGNANT_PATIENT");
                        if(isUseACBScore){
                            let showAcbScore = this._commonUtil.showACBScore({patientData : rxInfo.Patient, isPrmptHighRsk: isPrmptHighRsk , youthUnderAge: youthUnderAge, pregantPatient : pregantPatient, geriatricAge : geriatricAge})
                            if(abcCond === 1){
                                if (showAcbScore && (this.drugInteractions.AcbScore > acbScore)) {
                                    this.alrtTyp.isACB = true;
                                }
                            }else if(showAcbScore && (this.drugInteractions.AcbScore >= acbScore)){
                                this.alrtTyp.isACB = true;
                            }
                        }
                    }
                }
            });
        }
    }

    getDrugAllergiesStatus() {
        if (this.drugInteractions &&
            this.drugInteractions.Allergies != null
            // this.drugInteractions.Allergies.AllergyInfo
        ) {
            this.modelRef = this._modalService.open(this.DRUGALLERGIES, {keyboard: false, backdrop: false, windowClass: "large--content", centered : true});
        }
    }

    getDrugDeceaseContradictionInfo() {
        if (this.drugInteractions &&
            this.drugInteractions.DiseaseContradication &&
            this.drugInteractions.DiseaseContradicationCount
        ) {
            this.drugDeseContrd = this.drugInteractions.DiseaseContradication;
            this.modelRef = this._modalService.open(this.DRUGDISEASECONTRIND, {
                size: "lg",
                keyboard: false
            });
        }
    }

    checkAllDDIPopClosed() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.currentDrugPopIndex += 1;
        if (this.drugInteractions && this.drugInteractions.DrugToDrugInteraction &&
             (this.drugInteractions.DrugToDrugInteraction.length === this.currentDrugPopIndex)) {
            this.currentDrugPopIndex = 0;
           // this.getDuplicateDrugTherapyInfo();
        } else {
            this.getDrugToDrugInterationInfo();
        }
    }

    getDrugToDrugInterationInfo() {
        if (this.drugInteractions &&
            this.drugInteractions.DrugToDrugInteraction &&
            this.drugInteractions.DrugToDrugInteraction.length > 0
        ) {
            this.drugToDrugInteractions = this.drugInteractions.DrugToDrugInteraction[this.currentDrugPopIndex];
            this.modelRef = this._modalService.open(
                this.DRUGTODRUGINTERACTION,
                {
                    size: "lg",
                    keyboard: false,
                    windowClass: "modal-center-to-page"
                }
            );
           // this.actvModal2 = "DRUGTODRUGINTERACTION";
        }
    }

    // getDrugToDrugInterationInfo() {
    //     if (
    //         this.drugInteractions.DrugToDrugInteraction &&
    //         this.drugInteractions.DrugToDrugInteraction.length > 0
    //     ) {
    //         this.drugToDrugInteractions = this.drugInteractions.DrugToDrugInteraction[0];
    //     }
    //     this.modelRef = this._modalService.open(
    //         this.DRUGTODRUGINTERACTION,
    //         {
    //             size: "lg"
    //         }
    //     );
    // }

    getDuplicateDrugTherapyInfo() {
        // this.modelRef = this._modalService.open(this.DUPLICATEDRUG);

        if (this.drugInteractions && this.drugInteractions.DuplicateDrugs && this.drugInteractions.DuplicateDrugs.length > 0) {
            this.drugInteractions.DuplicateDrugs.map((item: any) => {
                if (item && item["Filldate"]) {
                    item["Filldate"] = moment.utc(item["Filldate"]).local().format("MM/DD/YYYY");
                }
            });
            this.modelRef = this._modalService.open(this.DUPLICATEDRUG, {keyboard: false, backdrop: false, windowClass: "large--content medium-sm-modal duplicate-drug-modal"});
        }
    }

    getDrugPregnancyInfo() {
        if (this.drugInteractions &&
            this.drugInteractions.PregnancyLactationInfo &&
            this.rxInfo.Patient.Gender === "Female"
        ) {
            this.drugPregInfo = this.drugInteractions.PregnancyLactationInfo;
            this.modelRef = this._modalService.open(this.DRUGPREGNENCY, {
                size: "lg",
                keyboard: false
            });
        }
    }

    getAdverseReaction() {
        if (this.drugInteractions &&
            this.drugInteractions.AdverseReaction &&
            this.drugInteractions.AdverseReaction.length > 0) {
            this.adverseDataMonograph = this.drugInteractions.AdverseReaction[0]["ADVERSEREACTIONS"];
            this.modelRef = this._modalService.open(this.ADVERSEDRUG, {
                size: "lg", keyboard: false, backdrop: false,
                windowClass: "modal-center-to-page"
            });
        }
    }

    checkLifeStyleComplete() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.currentLifeStyleIndex += 1;
        if (this.drugInteractions.LifeStyle.length !== this.currentLifeStyleIndex) {
            this.getLifeStyle();
        } else {
            this.currentLifeStyleIndex = 0;
        }
    }

    getLifeStyle() {
        if (this.drugInteractions &&
            this.drugInteractions.LifeStyle &&
            this.drugInteractions.LifeStyle.length > 0) {
            this.drugLifeStyle = this.drugInteractions.LifeStyle[this.currentLifeStyleIndex];
           /* Sentry.captureException([
                {name: this.drugLifeStyle},
                {name: this.drugInteractions.LifeStyle.length}
            ]);*/
            this.modelRef = this._modalService.open(this.LIFESTYLE, {
                size: "lg", keyboard: false, backdrop: false, centered : true
            });
        }
    }

    openFollowup() {
        this.IsFollowup = true;
    }

    resetAdminData() {
        if (this.rphFilters) {
            this.rphFilters.alreadyInfoChckd = true;
        }
        sessionStorage.removeItem("RxAdmDosSchInf");
        sessionStorage.removeItem("RxAdmDosSchFilt");
        // if (this.isPatientHistory) {
        //     this.CloseRphModal.emit(true);
        // }
    }
    getPresentUrl() {
        let presentUrl: any = location.href.split("/");
        if (presentUrl && presentUrl.length && (presentUrl[5] === "RxVerification")) {
            this.isRxVerification = true;
        } else {
            this.isRxVerification = false;
        }
    }
    async verifyRph(verifyType: string) {
        sessionStorage.removeItem("RxAdmDosSchInf");
        sessionStorage.removeItem("RxAdmDosSchFilt");
        const DatatoPost = {
            PrescId: (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.Id) ? this.rxInfo.Prescription.Id : 0,
            PrescRefillId: (this.rxInfo && this.rxInfo.PrescReFill && this.rxInfo.PrescReFill.Id) ? this.rxInfo.PrescReFill.Id : 0,
            VerifDtTm: moment(Date.now()).format("MM-DD-YYYY"),
            RefillNum: (this.rxInfo && this.rxInfo.PrescReFill) ? this.rxInfo.PrescReFill.ReFillNum : 0,
            PrescNum: (this.rxInfo && this.rxInfo.Prescription) ? this.rxInfo.Prescription.PrescNum : 0 ,
            PartialfillNum: (this.rxInfo && this.rxInfo.Prescription) ? this.rxInfo["PrescReFill"]["PartialFillNo"] : 0,
            Status: this.isRxVerification ? "R" : "V",
            SendReadyForPickupAlerts:  this._commonService.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts"),
            SendReadyForPickupAlertsWhen: this._commonService.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts_When")
        };
        if (DatatoPost && DatatoPost.PrescId && DatatoPost.PrescNum) {
            this.result = this.rxInfo;
            const warning: any = await this._cmnlgRxInfo.checkotherVerifications(this.isRxVerification, this.rxInfo, true, this.isPatientHistory);
            if (warning) {
                    // if (warning && (warning !== "DiscontinuedRx") && warning["Status"] &&
                    //  (warning["Status"].trim() === "V" || warning["Status"].trim() === "R")) {
                    //     this.verifiedAlready = warning;
                    //     this.modalAlreadyVerf = this._modalService.open(this.AlreadyVerif, { keyboard: false, backdrop: false,
                    //         windowClass: "large--content" });
                    // } else
                     if (warning === "DiscontinuedRx") {
                        this.fillNumberDisc = this._commonService.checkIsPartialFill(this.result.PrescReFill, this.result.Prescription)?"-"+(this.result.PrescReFill.PartialFillNo) : "";
                        this.modelDiscont = this._modalService.open(this.RxDiscontinued, { centered: true, backdrop: false,
                            keyboard: false, windowClass: "large--content" });
                    } else {
                        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {backdrop: false,
                                            keyboard: false, windowClass: "large--content"});
                        modelRef.componentInstance.warnMsg = warning;
                        modelRef.componentInstance.okButton = true;
                        modelRef.componentInstance.IsPopUpClosed
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe((resp: any) => {
                             modelRef.close();
                            this.getVerifyDetailsById();
                        });
                    }
            } else {
            this._rphService.PostRphVerify(DatatoPost)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    if (DatatoPost.Status === "R") {
                        this._alertService.success("Rx verification completed successfully.");
                        this._auditUtils.getChangedValues(
                            null,
                            {"Rx Verified": "Verified"},
                            "Rx Verification",
                            "Rx",
                            this.rxInfo.PrescReFill.Id
                        );
                        this._auditUtils.getChangedValues(
                            null,
                            {"Rx Verification Status": "Pass"},
                            "Rx Verification",
                            "Rx",
                            this.rxInfo.PrescReFill.Id
                        );
                    } else {
                        this._alertService.success("Rph verification completed successfully.");
                        this._auditUtils.getChangedValues(
                            null,
                            {"Rph Verified": "verified"},
                            "Rph Verification",
                            "Rx",
                            this.rxInfo.PrescReFill.Id
                        );
                        this._auditUtils.getChangedValues(
                            null,
                            {"Rph Verification Status": "Pass"},
                            "Rph Verification",
                            "Rx",
                            this.rxInfo.PrescReFill.Id
                        );
                    }
                    if (verifyType === "verify") {
                        sessionStorage.removeItem("RphFmDPV");
                        if (this.rphFmDph && this.rphFmDph !== "TodaysRx" && this.rphFmDph !== "erxException" && !this.isPatientHistory) {
                            this.CloseRphModal.emit(true);
                            this._router.navigate(["/eprx/DrugPickVerification"]);
                        } else if (this.rphFmDph === "TodaysRx" && !this.isPatientHistory) {
                            this.CloseRphModal.emit(true);
                            this._router.navigate(["/eprx/RphDash/TodayRx"]);
                        } else if (this.rphFmDph === "erxException" && !this.isPatientHistory) {
                            this.CloseRphModal.emit(true);
                            this._router.navigate(["/eprx/ErxExceptionReport"]);
                        }  else if (!this.isPatientHistory && !this.isRxVerification) {
                            this.CloseRphModal.emit(true);
                            this._router.navigate(["/eprx/RphVerification"]);
                        }   else if (this.isRxVerification && !this.isPatientHistory) {
                            this.CloseRphModal.emit(true);
                            this._router.navigate(["/eprx/RxVerification"]);
                        }
                        if (this.isPatientHistory) {
                            // this.CloseRphModal.emit(true);
                            if (!(this.prevClicked || this.nextClicked)) {
                                this.CloseRphModal.emit(true);
                            } else {
                                this.CloseRphModal.emit(this.selectlist);
                            }
                            return;
                        }
                    } else if (verifyType === "VerifyNext") {
                        this.getNext();
                    }
                } else {
                    this._alertService.error("Rph verification unsuccessful.");
                    this._auditUtils.getChangedValues(
                        null,
                        {"Rph Verified": false},
                        "Rph Verification",
                        "Rx",
                        this.rxInfo.PrescReFill.Id
                    );
                }
            });
        }
        }
    }

    loadRxInfo() {
        this.getVerifyDetailsById();
    }
    openNotes() {
        this.IsNotes = true;
    }
    closeFollowupModal(IsFollowup) {
        // this.IsFollowup = false;
        // if(!IsFollowup) {
        //     if (!this.isRxVerification) {
        //         this._router.navigate(["/eprx/RphVerification"]);
        //     }   else if (this.isRxVerification) {
        //         this._router.navigate(["/eprx/RxVerification"]);
        //     }
        // }
        sessionStorage.removeItem("RphFmDPV");
        this.IsFollowup = false;
        if(!IsFollowup) {
             if (this.rphFmDph === "TodaysRx" && !this.isPatientHistory) {
                this.CloseRphModal.emit(true);
                this._router.navigate(["/eprx/RphDash/TodayRx"]);
            } else if (this.rphFmDph === "erxException" && !this.isPatientHistory) {
                this.CloseRphModal.emit(true);
                this._router.navigate(["/eprx/ErxExceptionReport"]);
            }  else if (!this.isPatientHistory && !this.isRxVerification) {
                this.CloseRphModal.emit(true);
                this._router.navigate(["/eprx/RphVerification"]);
            }   else if (this.isRxVerification && !this.isPatientHistory) {
                this.CloseRphModal.emit(true);
                this._router.navigate(["/eprx/RxVerification"]);
            }
            if (this.isPatientHistory) {
                this.CloseRphModal.emit(true);
            }
    }
    }

    async CloseNotesModal() {
        this.IsNotes = false;
        this.getVerifyDetailsById(true);
    }

    openTrans() {
        this._rxStore.storeRxInfo(this.rxInfo);
        this.IsTransResult = true;
    }

    CloseTransModal() {
        this.IsTransResult = false;
    }

    openHistoryPopUp(val?: any) {
        if (val) {
            this.selectedVerfHist = val;
            this.isRxVerificationHist = (val === "rx" ? true : false);
        }
        this.IsHistory = true;
    }
    closeHist() {
        this.isRxVerificationHist = this.selectedVerfHist === "rx" ? true : false;
        this.IsHistory = false;
    }

    goToPatHist() {
        this._patPreDrugService._patPreDrugModal$.next("patHist");
        const modalRef = this._patPreDrugService.modalInstanceRef;
        modalRef.componentInstance.patientID = this.rxInfo && this.rxInfo.Patient ? this.rxInfo.Patient.patientid : null;
        modalRef.componentInstance.popUpOpenFr = "rph";
        modalRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(closed => {
            modalRef.close();
            this._patPreDrugService._patPreDrugModal$.next(null);
        });
    }

    async goToEditRx() {
        this.routeToRx(+this.rxInfo.Prescription.PrescNum, this.rxInfo.PrescReFill.Id);
    }

    cancelRPH() {
        sessionStorage.removeItem("RphFmDPV");
        if (this.isPatientHistory) {
            if (!(this.prevClicked || this.nextClicked)) {
                this.CloseRphModal.emit(true);
            } else {
                this.CloseRphModal.emit(this.selectlist);
            }
            return;
        }
        if (this.rphFmDph && this.rphFmDph !== "TodaysRx" && this.rphFmDph !== "erxException") {
            this._router.navigate(["/eprx/DrugPickVerification"]);
        } else if (this.rphFmDph === "TodaysRx") {
            this._router.navigate(["/eprx/RphDash/TodayRx"]);
        } else if (this.rphFmDph === "erxException") {
            this._router.navigate(["/eprx/ErxExceptionReport"]);
        } else if(this.isRxVerification){
            this._router.navigate(["/eprx/RxVerification"]);
        } else  {
            if (this.rphFilters) {
                this.rphFilters.PageNumber = 0;
                this.rphFilters.PageSize = 15;
            }
            this._router.navigate(["/eprx/RphVerification"]);
        }
    }

    openDPVVerfHist() {
        if (this.rxInfo) {
            this.dphVerifHistory =this.verifyData["drugPickVerif"] ?  [...this.verifyData["drugPickVerif"]]: [];
            if (this.dphVerifHistory &&  this.dphVerifHistory.length)  {
                this.dphVerifHistory = this.dphVerifHistory.sort((a, b) => {
                    return <any>new Date(b.VerifDtTm) - <any>new Date(a.VerifDtTm);
                });
                this.dphVerifHistory.map(elm => {
                    elm["PrescNum"] = this.rxInfo.Prescription.PrescNum;
                    elm["RefillNum"] = this.rxInfo.PrescReFill.ReFillNum;
                    elm["PartialfillNum"] = this._commonService.checkIsPartialFill(this.rxInfo.PrescReFill, this.rxInfo.Prescription) ? this.rxInfo.PrescReFill.PartialFillNo : " ";
                });
            }
            const modelRef = this._modalService.open(CommonDrugpickVerfHistComponent,
                { backdrop: "static", size: "lg", centered: true });
            modelRef.componentInstance.DPVVerfHist = this.dphVerifHistory;
            modelRef.componentInstance.IsPopUpClosed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                    modelRef.close();
            });
        }
    }

    displayHoa(){
       // const prescRefill: FormGroup = this.rxFG.controls.PrescReFill as FormGroup;
       // let isPrn = prescRefill.controls["IsPRN"].value
       // if(!isPrn){
            const modalRef: any = this._modalService.open(HoaComponent, {
                backdrop: false,
                size: "lg",
                windowClass: "max-modal-78 modal-center-to-page",
                keyboard: false,
            });
            modalRef.componentInstance.OpenFrom = 'rph';
            modalRef.componentInstance.rxfg = this.rxFG;
            modalRef.componentInstance.CloseAdminRxPopUp
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (resp) => {
                    if(resp){
                        this._alrtSev.success(MsgConfig.HOA_SAVE);
                        this.isHOADefined = true;
                        this.rxFG.controls["PrescReFill"].patchValue({
                            IsHOADefined : this.isHOADefined
                        });
                    }
                }
            );
       // } 
        // else if(isPrn){
        //     const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
        //         backdrop: false,
        //         keyboard: false, windowClass: "large--content"
        //     });
        //     modelRef.componentInstance.isPopSize = "large--content_medium_sm";
        //     modelRef.componentInstance.warnMsg = `HOA is not applicable for PRN Rx(s)`
        //     modelRef.componentInstance.IsHeaderText = "Warning!!";
        //     modelRef.componentInstance.okButton = true;
        //     modelRef.componentInstance.hideCross = true;
        //     modelRef.componentInstance.IsPopUpClosed.subscribe((resp: any) => {
        //         modelRef.close();
        //         this.closeModal();            
        //     });
        // }
    }

    getAcbScore(){
        this.acbScore = this.drugInteractions.AcbScore;
        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
        });
        modelRef.componentInstance.isPopSize = "large--content_medium_sm";
        modelRef.componentInstance.IsHeaderText = `Severe Anticholinergic effects associated with
        ${this.DRUG.get('drugname').value.toUpperCase()} ${this.DRUG.get('strength').value}  ${this.DRUG.get('drugformname').value.toUpperCase()} `;
        modelRef.componentInstance.warnMsg = `The prescribed drug <b> ${this.DRUG.get('drugname').value.toUpperCase()} ${this.DRUG.get('strength').value.toUpperCase()} 
        ${this.DRUG.get('drugformname').value.toUpperCase()}</b> is assigned ACB score of <b>${this.acbScore}</b>
        <div>&nbsp;</div>
        The Anticholinergic Cognitive Burden (ACB) Score (or ACB Scale) is a tool used to
        identify the severity of anticholinergic effects of prescribed or over-the-counter medications on
        cognition in older adults. Durgs with anticholinergic effects are associated with adverse events
        such as delirium and falls as well as cognitive decline, loss of independence, and even death.
        An alternate medication with lower ACB scores may be considered to lower the overall risk of cognitive impairment.`
        modelRef.componentInstance.okButton = true;
        modelRef.componentInstance.hideCross = true;
        modelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp: any) => {
            modelRef.close();
            this.closeModal();            
        });
        // this.modelRef = this._modalService.open(this.ACBSCOREWARNING, {
        //     backdrop: false,
        //     keyboard: false,
        //     centered: true,

        // });
    }

    openImmuneForm() {
        const vacnImuneModelRef = this._modalService.open(VaccineImmunizationComponent, {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "lg"
        });
        vacnImuneModelRef.componentInstance.rxFG = this.rxFG;
        vacnImuneModelRef.componentInstance.rxInfo = this.rxFG.getRawValue();
        vacnImuneModelRef.componentInstance.frmVerf = true;
        vacnImuneModelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async response => {
                vacnImuneModelRef.close();
        });
    }
    openForm() {
        this.rxNodisplay = this.verifyData?.Prescription?.PrescNum+"-"+(+this.verifyData?.PrescReFill?.ReFillNum)+""+this.fillNumber;
        this.patientFullName = this.verifyData?.Patient?.fullname ? (this.verifyData?.Patient?.fullname.replace('_', ', ').toUpperCase()) : "";
        this.drugFullName = (this.verifyData?.Drug?.drugname ? this.verifyData?.Drug?.drugname.toUpperCase() : '')+(this.verifyData?.Drug?.strength ? ' ' + this.verifyData?.Drug?.strength: '')+
        (this.verifyData?.Drug?.drugformname ? ' ' + this.verifyData?.Drug?.drugformname: '');
        this.patientInfo = this.verifyData?.Patient;
        this.openAssessmentForm = true;
    }

    displayLot(event) {
        this.displayLotNum = event ? false : true;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    openActionModal() {
        this.IOUClk = true;
    }
    
    async verifyAuthPassword() {
        this.warnMessage= "";
        if (this.LoginVerifyFG.valid) {
            const resp = await this._rxService.checkTechicianPassword(
                    this.LoginVerifyFG.value.Password,
                    this.LoginVerifyFG.value.UserName).toPromise();
            if (resp && resp.Status === 1) {
                if (this.modelRefAuth) {
                    this.modelRefAuth.close();
                    this.modelRefAuth = null;
                }
                if (this.verifyData && this.verifyData.Prescription.PrescNum && this.verifyData.PrescReFill.Id && this.checkHasPrivsToAccess(
                    this.accessPrivCate.Rx, this.rxPrivs.EditRx )) {
                    this.routeToRx(+this.verifyData.Prescription.PrescNum, +this.verifyData.PrescReFill.Id, null, this.frmRRR);
                } else {
                    this._alertService.error(MsgConfig.NO_PRIVS_ACCESS, false, "long");
                }
            } else {
                if (resp && resp.Status ===  2) {
                    this.warnMessage = resp.IsAdminUser ?
                    "Admin user account is locked due to multiple attempts with wrong password. Please contact support team to unlock your account."  :
                    "Pharmacist/Technician user account is locked due to multiple attempts with wrong password. Please contact your Admin to unlock your account.";
                } else {
                    this.warnMessage = "Please enter correct password.";
                }
                this.LoginVerifyFG.reset();
                if(document.getElementById("EnterInitials"))
                      document.getElementById("EnterInitials").focus();

           }
        }

    }

    closePopUp() {
        if (this.modelRefAuth) {
            this.modelRefAuth.close();
            this.modelRefAuth = null;
        }
    }
}
