import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RxStore } from "../store";
import { CommonUtil } from "./common.util";

@Injectable({
    providedIn: "root"
})
export class RxChecksForCommonLoigc {
    actionsTypes =  ["TransmitNow","HoldRx", "TrnsmtPatent","FileTo"];
    constructor(private _rxStore: RxStore,private _commonUtil: CommonUtil) {
    }
    checkBucketIdInManageinventory(rxFG: any) {
        const drug = rxFG["Drug"];
        if(drug["manageinventory"] && drug["manageinventory"].length) {
            return drug["manageinventory"].findIndex(v => v["BucketId"] === rxFG.PrescReFill.BucketId && v["IsInvManaged"]) > -1
        } else {
            return false;
        }
    }
    getQntyinHand(rxFG: any) {
        const drug = rxFG["Drug"];
        let qtyInHand: any = "";
        if(drug["manageinventory"] && drug["manageinventory"].length)
             qtyInHand = drug["manageinventory"].find(v => v["BucketId"] === rxFG.PrescReFill.BucketId);
        return qtyInHand;
    }
    getQntyinHandForDrug(rxFG: any) {
        const drug = rxFG["Drug"];
        let qtyInHand: any = "";
        if(drug["manageinventory"] && drug["manageinventory"].length)
             qtyInHand = drug["manageinventory"].find(v => v["BucketId"] === rxFG.PrescReFill.BucketId);
        return (qtyInHand && qtyInHand.QtyInHand) ? qtyInHand.QtyInHand : 0;
    }
    clearControlPatching(rxFG: FormGroup, focusKey, isYesClick : boolean = false) {
        const prescReFill: FormGroup = rxFG.controls[
            "PrescReFill"
        ] as FormGroup;
        const oldInfo = this._rxStore.rxInfo$["source"]["value"];
        if(focusKey === "rxOrdrQty")
                prescReFill.controls["OrdQty"].setValue(null);
         else if(focusKey === "rxDispQty")
              prescReFill.controls["DispQty"].setValue(null);
        else if(focusKey === "rxSupplyDays")
             rxFG.controls["PrescReFill"].patchValue({SupplyDays: 0});
        else if(focusKey === "rxOrderDays")
             prescReFill.controls["OrderDays"].setValue(null);
        else if(focusKey === "rxDispQtyLeftOver") {
            rxFG.controls["PrescReFill"].patchValue({DispQty: isYesClick ? rxFG.value["PrescReFill"]["DispQty"] :
            oldInfo["PrescReFill"]["DispQty"].toFixed(3)});
        }
    }
    getInsuranceActiveList(rxInfo) {
        let insureList: any = [];
        if(rxInfo && rxInfo.Patient &&  rxInfo.Patient.insurance && rxInfo.Patient.insurance.length) {
            insureList =    rxInfo.Patient.insurance.filter((item: any) => {
                return ((item.insuranceid === rxInfo.RefillInsu.InsuId) && !item.insurerActiveStatus);
            });
        }
       return insureList;
    }
    checkRefillsCreatedOrNotForORgRx(rxInfo) {
        let isRxCreated: Boolean = false;
        if(rxInfo.islatestrefill &&  rxInfo.PrescReFill.ReFillNum) {
            isRxCreated =  true;
        }
        return isRxCreated;
    }
    IsRxFieldDisabled(type, rxFG) {
            if(type === 'rxOrdrQty' && this._commonUtil.checkPayRecExistOrNotForSelcRx(rxFG?.value?.RxPay)) {
                    return true;
            } else if(type === 'rxDispQty' && this._commonUtil.checkPayRecExistOrNotForSelcRx(rxFG?.value?.RxPay)) {
                return true;
            } {
                return false;
            }
    }
    IsNotOrginalRefill(rxFG: any) {
        if (!(rxFG["PrescReFill"]["ReFillNum"] === 0 && rxFG?.["PrescReFill"]["PartialFillNo"] === 1)) {
           return true;
        } else {
            return false;
        }
    }
}
