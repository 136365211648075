<div class="eprx-card">
    <div>
        <input class="radio1" type="radio" name="radio-button" id="radio-1">
        <label for="radio-1">Search from History</label>
      </div>
      
      <div>
        <input class="radio2" type="radio" id="radio-2">
        <label for="checkbox-1">Search NDc/Drug Name</label>
      </div>
    
      <div>
        <input class="radio3" type="radio" id="radio-3">
        <label for="checkbox-1">All</label>
      </div>
</div>