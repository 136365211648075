
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { constant } from "..";
const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({
    providedIn: "root"
})
export class FilesService {
    constructor(private _http: HttpClient) {}
    getDrugWarning(data: any) {
        return this._http.post(constant.POST_Drug_Warning, data, reqOptns);
    }

    getDrugDiagnosis(data: any, noLoader?) {
        if (noLoader) {
            return this._http.post(constant.POST_Drug_Diagnosis, data, reqOptns);
        } else {
            return this._http.post(constant.POST_Drug_Diagnosis, data);
        }
    }
    getRxFollowTag(data: any) {
        return this._http.post(constant.POST_Search_RxTag, data, reqOptns);
    }
    getTagNameSuggest(data: any) {
        return this._http.get(
            constant.GET_Rx_Follow_Up_Suggest.replace("{TagName}", "" + data)
        );
    }
    getEmptyObservable(): Observable<any> {
        return observableOf([]);
    }
    updateRxTag(data: any) {
        return this._http.post(constant.POST_RxTagInformation, data);
    }

    getPriceList(data: any) {
        return this._http.post(constant.POST_PriceList_Filters, data, reqOptns);
    }
    addPriceList(data: any) {
        return this._http.post(constant.POST_PriceList_Add, data);
    }
    editPriceList(data: any) {
        return this._http.put(constant.PUT_PriceList_Edit, data);
    }
    deletePriceList(Id: any) {
        return this._http.delete(
            constant.DELETE_PriceList.replace("{BulkPriceId}", "" + Id)
        );
    }
    getPriceSchedule(data: any) {
        return this._http.post(constant.POST_PriceSchedule_Filters, data, reqOptns);
    }
    addPriceSchedule(data: any) {
        return this._http.post(constant.POST_PriceSchedule_Add, data);
    }
    editPriceSchedule(data: any) {
        return this._http.put(constant.PUT_PriceSchedule_Edit, data);
    }
    deletePriceSchedule(Id: any) {
        return this._http.delete(
            constant.DELETE_PriceSchedule.replace("{PriceScheduleId}", "" + Id)
        );
    }
    getTransferPharmacy(data: any) {
        return this._http.post(constant.GET_TransferPharmacy_List, data, reqOptns);
    }

    checkDrugDuplicate(Id: any) {
        return this._http.get(constant.GET_Check_duplicate_Drug.replace("{DrugId}", "" + Id));
    }

    PriceListCalculation(data: any) {
        return this._http.post<any>(constant.POST_PriceList_Calculation, data);
    }

    getPriceScheduleDeleteResponse(priceScheduleId: any) {
        return this._http.get(constant.GET_PriceSchedule_DeleteResponse.replace("{PriceScheduleId}", "" + priceScheduleId));
    }

    getRxFollowUpTagDeleteResponse(data: any) {
        return this._http.post(constant.POST_RxTagFollowUp_DeleteResp, data);
    }
    deleteTag(Id: any) {
        return this._http.delete(
            constant.DELETE_RxTag.replace("{deleteId}", "" + Id)
        );
    }

    getVaccnFileDropdowns() {
        return this._http.get(constant.GET_VaccinesDropDownData);
    }

    getVaccnFileGeneratorList(payLoad: any) {
        return this._http.post(constant.POST_VaccineFileData, payLoad);
    }

    getImmunizationFileGeneration(payLoad: any) {
        return this._http.post(constant.POST_ImmunizationFileGeneration, payLoad);
    }
    updateCompletionStatus(payload:any) {
        return this._http.put(constant.UPDATE_COMPLETION_STATUS, payload);
    }
}
