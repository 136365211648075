import { Injectable } from "@angular/core";
import { CommonService } from "../services";
import * as moment from "moment";
import { DateCheckUtil } from "./date-checks.util";



@Injectable({
    providedIn: "root"
})
export class ColorCode {
    constructor(private _commonServ: CommonService, private _dateCheck: DateCheckUtil) {}

    showRxColorRefactoring(item, type) {
        const aref = item["Auth#"];
        const ref = item["Ref#"];
        const fillNo = (item["Fill#"] && item["Fill#"] !== " ") ? item["Fill#"] : 1;
        let remQty = +item["Qty Rem P.fill"];
        remQty = (remQty) ? remQty : 0;
        let totRemQty = +item["T.Rx Qty Rem"];
        totRemQty = totRemQty ? totRemQty : 0;
        const daysRemaingForDue = item["DueDays"];
        let rxExpired = false;
        let rxExpiredByInsuranceOrAccountSettings = false;
        // const rxExpiryDateByInsuranceOrControlSettings = item["rxExpiryDateByInsuranceOrControlSettings"];
        let colorForText = ""; // "his-bg--white__text--black";
        let MaxRefillsAllowed: any;
        let OrderToFillDays: any;
        const cntrlRefllOptns = this._commonServ.controlFileSettings$["source"]["value"];
        if (item["DrugClass"] === 0 || item["DrugClass"] === 1) {
           MaxRefillsAllowed = item.primaryInsurance && item.primaryInsurance.MaxRefills >= 0 ? item.primaryInsurance.MaxRefills : "";
           OrderToFillDays =  item.primaryInsurance && item.primaryInsurance.OrderToFillDays >= 0 ? item.primaryInsurance.OrderToFillDays : null;
           if (item["MaxRefillsAllowed"]) {
            MaxRefillsAllowed = item["MaxRefillsAllowed"];
           } if (item["OrderToFillDays"]) {
            OrderToFillDays = item["OrderToFillDays"];
           }
        } else {
            if (cntrlRefllOptns && cntrlRefllOptns.ControlRefillOptions && cntrlRefllOptns.ControlRefillOptions.length > 0) {
               const val =  cntrlRefllOptns.ControlRefillOptions.find(ele => +(ele.DrugClass) === item["DrugClass"]);
                   if (val) {
                       MaxRefillsAllowed =   val.MaxRefillAllow >= 0  ? val.MaxRefillAllow : 99;
                        OrderToFillDays  =  val.OrderFillDays >= 0  ? val.OrderFillDays : 180;
                   }
                    }
            }
        const isRxExpireDateAfterDateCheck = item["RxExpDate"]  ? this._dateCheck.checkExpiryDate(item["RxExpDate"])  :  false;
        const rxExpiryDateByInsuranceAfterDateCheck = item["rxExpiryDateByInsuranceOrControlSettings"]  ? this._dateCheck.checkExpiryDate(item["rxExpiryDateByInsuranceOrControlSettings"])  :  false;
        const RxExpirationDateCheck = item["RxExpirationDate"]  ? this._dateCheck.checkExpiryDate(item["RxExpirationDate"])  :  false;
        const rxThresholdCheck = item["rxThreshold"]  ? this._dateCheck.checkExpiryDate(item["rxThreshold"])  :  false;
        if (item["RxExpDate"] && isRxExpireDateAfterDateCheck) {
            rxExpired = true;
            if (item["RxExpDateByUser"]) {
                let d1 = new Date(moment(item["RxExpDate"]).format("MM/DD/YYYY"));
                let d2 = new Date(moment(item["RxExpDateByUser"]).format("MM/DD/YYYY"));
                if (item["rxExpiryDateByInsuranceOrControlSettings"] && rxExpiryDateByInsuranceAfterDateCheck) {
                    let d3 =  new Date(moment(item["rxExpiryDateByInsuranceOrControlSettings"]).format("MM/DD/YYYY"));
                    if (((d1.getTime() === d2.getTime()) && (d2.getTime() === d3.getTime())) || (d3 < d2 && !(d1 < d3))) {
                        rxExpiredByInsuranceOrAccountSettings =  (aref >= 0) ? true : false;
                    } else {
                        rxExpiredByInsuranceOrAccountSettings =  false;
                    }
                } else {
                    rxExpiredByInsuranceOrAccountSettings =  false;
                }
            } else {
                rxExpiredByInsuranceOrAccountSettings = true;
            }
        }
        const latestRefCompleted = item.PrescCount;
        const drugThresholdValue = this._commonServ.getSetttingValue("RxSettings", "REFILL_DUE_CONSUMPTION_THERSHOLD");
        const InsRefillDueConsumptionThreshold = item["InsRefillDueConsumptionThreshold"] ? item["InsRefillDueConsumptionThreshold"] : 0;
        let supplydaysCheck = 0;
        if (InsRefillDueConsumptionThreshold) {
            supplydaysCheck = Math.round(+item["Supply Days"] - ((+item["Supply Days"] * (+InsRefillDueConsumptionThreshold ? +InsRefillDueConsumptionThreshold : 0)) / 100));
        } else {
            supplydaysCheck = Math.round(+item["Supply Days"] - ((+item["Supply Days"] * (+drugThresholdValue ? +drugThresholdValue : 0)) / 100));
        }
        if (
            item.Status !== "D" &&(
            (item.IsDiscountinued === "YES" || item["Order Status"] === "D" || item["IsDiscontinued"] === true)) &&
           ( !rxExpired && !((item.RxExpirationDue && item.RxExpirationDue > 0)))
        ) {
            colorForText = item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx) ? "his-bg--grey__text--white" : "his-bg--red__text--white";
        } else if (item.Status === "D" && (!rxExpired && !(item.RxExpirationDue && item.RxExpirationDue > 0)) && !(item.IsDiscountinued === "YES" || item["Order Status"] === "D" || item["IsDiscontinued"] === true)) {
            colorForText = "his-bg--blue__text--white";
        } else if (item.Status === "D" && (item["Ref#"] === 0 && fillNo === 1 && (item.RxExpirationDue && item.RxExpirationDue > 0)) && !(item.IsDiscountinued === "YES" || item["Order Status"] === "D" || item["IsDiscontinued"] === true)) {
                colorForText = "his-bg--blue_peach__text--black";
        } else if(item.Status === "D" && (rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0))) {
            if (rxExpiredByInsuranceOrAccountSettings) {
                colorForText = "his-bg--blue_peach__text--black";
            } else {
                colorForText = "his-bg--blue_white__text--black";
            }
        } else if(item.Status === "D" && ( (item.IsDiscountinued === "YES" || item["Order Status"] === "D" || item["IsDiscontinued"] === true))) {
            colorForText = item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx) ? "his-bg--blue_grey__text--white" : "his-bg--blue_red__text--white";
        }else if(item.Status === "T" && +(this._commonServ.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT"))) {
            colorForText = "his-bg--white__text--black";
        }
        else if((rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0))  && ( (item.IsDiscountinued === "YES" ||
        item["Order Status"] === "D" || item["IsDiscontinued"] === true))) {
            if (rxExpiredByInsuranceOrAccountSettings) {
                colorForText = item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx) ? "his-bg--peach_grey__text--black" : "his-bg--peach_red__text--black";
            } else {
                colorForText = item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx) ? "his-bg--white_grey__text--black" : "his-bg--white_red__text--black";
            }
        }
        if ( item.Status !== "D" && ((rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0)) && !totRemQty && !colorForText)) {
            colorForText = "his-bg--white__text--black";
        } else if ((rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0)) && !colorForText
        ) { 
            colorForText = rxExpiredByInsuranceOrAccountSettings ? "his-bg--peach__color--white" : "his-bg--white__text--black";
            // if (rxExpiredByInsuranceOrAccountSettings && !remQty) {
            //     colorForText = "his-bg--peach__color--white";
            // } else if(rxExpiredByInsuranceOrAccountSettings && remQty){
            //     colorForText = "his-bg--white__text--black"
            // } 
            // else {
            //     colorForText = "his-bg--white__text--black";
            // }
        } else if (daysRemaingForDue < supplydaysCheck && !colorForText ) {
            if (type === "Rx") {
                if (aref !== ref && aref >= ref) {
                    if (
                        (item.RxExpirationDue && item.RxExpirationDue <= 0) ||
                        (!item.RxExpirationDue && daysRemaingForDue < 365)
                    ) {
                        if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                            colorForText = "his-bg--peach__color--white";
                        } else if (!totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                            colorForText = "his-bg--white__text--black";
                        } else if (latestRefCompleted && latestRefCompleted["DocCount"] > 0) {
                            if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                                if (rxThresholdCheck) {
                                    colorForText = "his-bg--darkgreen__text--white";
                                } else {
                                    colorForText = "his-bg--lightgreen__text--white";
                                }
                            } else {
                                colorForText = "his-bg--white__text--black";
                            }
                        } else {
                            if (rxThresholdCheck) {
                                colorForText = "his-bg--darkgreen__text--white";
                            } else {
                                colorForText = "his-bg--lightgreen__text--white";
                            }
                        }
                    } else {
                        colorForText = "his-bg--white__text--black";
                    }
                } else if (aref !== 0 && totRemQty === 0 && daysRemaingForDue <= 0) {
                    colorForText = "his-bg--white__text--black";
                } else if (totRemQty !== 0) {
                    if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                        colorForText = "his-bg--peach__color--white";
                    } else if (latestRefCompleted && latestRefCompleted["DocCount"] > 0) {
                        if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                            if (totRemQty !== 0 && rxThresholdCheck) {
                                colorForText = "his-bg--darkgreen__text--white";
                            } else {
                                colorForText = "his-bg--lightgreen__text--white";
                            }
                        } else {
                            colorForText = "his-bg--white__text--black";
                        }
                    } else {
                        if (rxThresholdCheck) {
                            colorForText = "his-bg--darkgreen__text--white";
                        } else {
                            colorForText = "his-bg--lightgreen__text--white";
                        }
                    }
                } else {
                    colorForText = "his-bg--white__text--black";
                }
            } else {
                if (aref !== ref &&  aref >= ref) {
                    if (
                        (item.RxExpirationDue && item.RxExpirationDue <= 0) ||
                        (!item.RxExpirationDue && daysRemaingForDue < 365)
                    ) {
                        if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                            colorForText = "his-bg--peach__color--white";
                        } else if (!totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                            colorForText = "his-bg--white__text--black";
                        } else {
                            if (rxThresholdCheck) {
                                colorForText = "his-bg--darkgreen__text--white";
                            } else {
                                colorForText = "his-bg--lightgreen__text--white";
                            }
                        }
                    } else {
                        colorForText = "his-bg--white__text--black";
                    }
                }  else if (totRemQty !== 0) {
                    if (totRemQty !== 0 && rxThresholdCheck) {
                        colorForText = "his-bg--darkgreen__text--white";
                    } else {
                        colorForText = "his-bg--lightgreen__text--white";
                    }
                } else {
                    colorForText = "his-bg--white__text--black";
                }
            }
        } else if (daysRemaingForDue >= supplydaysCheck && !colorForText ) {
            if (aref !== ref && aref >= ref) {
                if (type === "Rx") {
                    if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                        colorForText = "his-bg--peach__color--white";
                    } else if (!totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                        colorForText = "his-bg--white__text--black";
                    } else if (latestRefCompleted && latestRefCompleted["DocCount"] > 0) {
                        if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                            if (rxThresholdCheck) {
                                colorForText = "his-bg--darkgreen__text--white";
                            } else {
                                colorForText = "his-bg--lightgreen__text--white";
                            }
                        } else {
                            colorForText = "his-bg--white__text--black";
                        }
                    } else {
                        if ( rxThresholdCheck) {
                            colorForText = "his-bg--darkgreen__text--white";
                        } else {
                            colorForText = "his-bg--lightgreen__text--white";
                        }
                    }
                } else {
                    if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                        colorForText = "his-bg--peach__color--white";
                    } else if ( rxThresholdCheck) {
                        colorForText = "his-bg--darkgreen__text--white";
                    } else {
                        colorForText = "his-bg--lightgreen__text--white";
                    }
                }
            } else if (totRemQty !== 0) {
                if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                    colorForText = "his-bg--peach__color--white";
                } else if (latestRefCompleted && latestRefCompleted["DocCount"] > 0) {
                    if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                        if (totRemQty !== 0 && rxThresholdCheck) {
                            colorForText = "his-bg--darkgreen__text--white";
                        } else {
                            colorForText = "his-bg--lightgreen__text--white";
                        }
                    } else {
                        colorForText = "his-bg--white__text--black";
                        // if (totRemQty !== 0 && rxThresholdCheck) {
                        //     colorForText = "his-bg--darkgreen__text--white";
                        // } else {
                        //     colorForText = "his-bg--lightgreen__text--white";
                        // }
                    }
                } else {
                    if (totRemQty !== 0 && rxThresholdCheck) {
                        colorForText = "his-bg--darkgreen__text--white";
                    } else {
                        colorForText = "his-bg--lightgreen__text--white";
                    }
                }
            } else {
                colorForText = "his-bg--white__text--black";
            }
        } else if (!colorForText ) {
            colorForText = "his-bg--white__text--black";
        }
        return this.getBackgroundForClass(colorForText);
    }

    checkRefillStatus(data) {
        const rxInfo = data && data["Refdetail"] && data["Refdetail"]["Refilldetails"]
        ? data["Refdetail"]["Refilldetails"][0] : null;
        if (rxInfo) {
            let warnCodeStatus = null;
            const orderDate = moment.utc(rxInfo.PrescReFill.OrderDtTm).local().format("MM/DD/YYYY");
            const today = moment();
            const daysBeforeCreated = today.diff(orderDate, "days");
            const rxThreshold = moment.utc(rxInfo.rxThreshold).local().format("MM/DD/YYYY");
            const today2 = moment().format("MM/DD/YYYY");
            const nextFillDt = moment(today2, "MM/DD/YYYY").isAfter(rxThreshold);
            const rxStatus =   (rxInfo.Prescription.RxStatusId === 2 || rxInfo.PrescReFill.StatusId === 2|| rxInfo.RxBill.StatusId === 6) ? "F" : "";
            data.FinalExpdate = today.diff(rxThreshold, "days") * -1; // if the value is greater than 0 then reverse the result from positive value to negative vice-versa.
            data["MAxRefillAllowed"] = data["MAxRefillAllowed"] || data["MAxRefillAllowed"] === 0
            ? data["MAxRefillAllowed"] : 99;

            data.MaxOrderToLastrefilldays = data.MaxOrderToLastrefilldays
            || data.MaxOrderToLastrefilldays === 0
                ? data.MaxOrderToLastrefilldays : 365;
            let rxExpiredByInsuranceOrAccountSettings = false;
            rxInfo["rxExpiryDateByInsuranceOrControlSettings"] = rxInfo.rxExpiryDateByInsuranceOrControlSettings ? rxInfo.rxExpiryDateByInsuranceOrControlSettings === "0001-01-01T00:00:00" ? null : rxInfo.rxExpiryDateByInsuranceOrControlSettings :  null;
            const rxExpiryDateByInsuranceAfterDateCheck = rxInfo["rxExpiryDateByInsuranceOrControlSettings"]  ? this._dateCheck.checkExpiryDate(rxInfo["rxExpiryDateByInsuranceOrControlSettings"])  :  false;
            const RxExpirationDateCheck = rxInfo["RxExpirationDate"]  ? this._dateCheck.checkExpiryDate(rxInfo["RxExpirationDate"])  :  false;
            if (rxInfo["RxExpirationDate"] && RxExpirationDateCheck) {
                if (rxInfo["rxExpiryDateByUser"]) {
                    let d1 = new Date(moment(rxInfo["RxExpirationDate"]).format("MM/DD/YYYY"));
                    let d2 = new Date(moment(rxInfo["rxExpiryDateByUser"]).format("MM/DD/YYYY"));
                    if (rxInfo["rxExpiryDateByInsuranceOrControlSettings"] && rxExpiryDateByInsuranceAfterDateCheck) {
                        let d3 =  new Date(moment(rxInfo["rxExpiryDateByInsuranceOrControlSettings"]).format("MM/DD/YYYY"));
                        if ((d1.getTime() === d2.getTime()) && (d1.getTime() === d3.getTime())) {
                            rxExpiredByInsuranceOrAccountSettings =  (rxInfo.Prescription.ReFillNum > 0) ? true : false;
                        } else {
                            rxExpiredByInsuranceOrAccountSettings =  false;
                        }
                    } else {
                        rxExpiredByInsuranceOrAccountSettings =  false;
                    }
                } else {
                    rxExpiredByInsuranceOrAccountSettings = true;
                }
            }
            if(data.TotalRemainingQty <= 0){
                warnCodeStatus = "No Quantity";
            }else if ((rxInfo.Prescription.ReFillNum <= rxInfo.PrescReFill.ReFillNum &&
                !(data.TotalRemainingQty) && rxStatus !== "F") && !(rxInfo.RxExpirationDate && RxExpirationDateCheck)) {
                warnCodeStatus = "No Quantity";
            }   
            // else if ((rxInfo.Prescription.ReFillNum == rxInfo.PrescReFill.ReFillNum &&
            //     (rxStatus !== "F")) && !(rxInfo.RxExpirationDate && moment().isAfter(rxInfo.RxExpirationDate)) && (data.TotalRemainingQty)) {
            //     warnCodeStatus = "No More Refills Left With R.Qty";
            // } 
            else if ((rxInfo.Prescription.ReFillNum == rxInfo.PrescReFill.ReFillNum &&
                (rxStatus !== "F")) && !(rxInfo.RxExpirationDate && moment().isAfter(rxInfo.RxExpirationDate)) && !(data.TotalRemainingQty)) {
                warnCodeStatus = "No More Refills Left";
            }  else if (rxStatus === "F" && ((rxInfo["Prescription"]["IsDiscountinued"] === "YES" || rxInfo["PrescReFill"]["OrderStatus"] === "D" || rxInfo["Prescription"]["IsDiscountinued"])) && !(rxInfo.RxExpirationDate && RxExpirationDateCheck)) {
                warnCodeStatus = "Filed and Discontinued Rx";
            } else if (rxStatus !== "F" && (rxInfo["Prescription"]["IsDiscountinued"] === "YES" || rxInfo["PrescReFill"]["OrderStatus"] === "D" || rxInfo["Prescription"]["IsDiscountinued"]) && !(rxInfo.RxExpirationDate && RxExpirationDateCheck)) {
                warnCodeStatus = "Discontinued";
            }
            else if (rxStatus === "F" && !(rxInfo["Prescription"]["IsDiscountinued"] === "YES" || rxInfo["PrescReFill"]["OrderStatus"] === "D" || rxInfo["Prescription"]["IsDiscountinued"]) && !(rxInfo.RxExpirationDate && RxExpirationDateCheck)) {
                warnCodeStatus = "Deferred Rx";
            } else if ((rxInfo.PrescReFill.StatusId === 4 || rxInfo.Prescription.RxStatusId === 4) && !(rxInfo.RxExpirationDate && RxExpirationDateCheck)) {
                warnCodeStatus = "Transferred";
            } else if ((rxInfo.PrescReFill.ReFillNum >= data["MAxRefillAllowed"]) && 
            !(data.TotalRemainingQty) && !(rxInfo.RxExpirationDate && RxExpirationDateCheck)) {
                warnCodeStatus = "Refill Expired";
            } else if ((data.FinalExpdate >= 0 && daysBeforeCreated <= data.MaxOrderToLastrefilldays) && !(rxInfo.RxExpirationDate && RxExpirationDateCheck) && !nextFillDt) {
                warnCodeStatus = "Early To Refill";
            } else if (((daysBeforeCreated <= data.MaxOrderToLastrefilldays)) &&  (nextFillDt)  && !(rxInfo.RxExpirationDate && RxExpirationDateCheck)) {
                warnCodeStatus = "Regular";
            } else  if (data.TotalRemainingQty  && ((daysBeforeCreated > data.MaxOrderToLastrefilldays) ||
                (data.FinalExpdate < -data.MaxOrderToLastrefilldays) || (rxInfo.RxExpirationDate && RxExpirationDateCheck ))) {
                    if ((rxInfo["Prescription"]["IsDiscountinued"] === "YES" || rxInfo["PrescReFill"]["OrderStatus"] === "D" || rxInfo["Prescription"]["IsDiscountinued"])) {
                        warnCodeStatus = rxExpiredByInsuranceOrAccountSettings ? "Expired and Discontinued Rx" : "Expired(User) and Discontinued Rx";
                    } else if(rxStatus === "F")  {
                        warnCodeStatus = rxExpiredByInsuranceOrAccountSettings ? "Deferred and Expired Rx" : "Deferred and Expired(User) Rx";
                    } else {
                        warnCodeStatus = rxExpiredByInsuranceOrAccountSettings ? "Expired Rx" : "Expired(User)";
                    }
            } else if (rxInfo.Prescription.ReFillNum <= rxInfo.PrescReFill.ReFillNum &&
                !(rxInfo.TotalRemainingQty)) {
                warnCodeStatus = "No Quantity";
            }
            return warnCodeStatus;
        }

    }
    colorCodingForRefillStatus(item) {
        if(item && item['Refill Status']){
                let status = item['Refill Status']
                let colorForText: any = "";
                if(status && (status === "Early To Refill"))
                      colorForText = "his-bg--lightgreen__text--white";
                else if(status && (status === "Regular"))
                      colorForText = "his-bg--darkgreen__text--white";
                else if(status && (status === "Deferred Rx"))
                      colorForText = "his-bg--blue__text--white";
                else if(status && (status === "Deferred and Discontinued Rx"))
                      colorForText = "his-bg--blue_red__text--white";
                else if(status && (status === "Expired Rx"))
                      colorForText = "his-bg--peach__color--white";
                else if(status && (status === "Expired(User)"))
                      colorForText = "his-bg--white__text--black";
                else if(status && (status === "Deferred and Expired Rx"))
                      colorForText = "his-bg--blue_peach__text--black";
                else if(status && (status === "Deferred and Expired(User) Rx"))
                      colorForText = "his-bg--blue_white__text--black";
                else if(status && (status === "Expired and Discontinued Rx"))
                      colorForText = "his-bg--peach_red__text--black";
                else if(status && (status === "Expired(User) and Discontinued Rx"))
                      colorForText = "his-bg--white_red__text--black";
                else if(status && (status === "No Quantity"))
                      colorForText = "his-bg--white__text--red";
                else if(status && (status === "Discontinued"))
                      colorForText = "his-bg--red__text--white";
                else
                     colorForText = "";
        return this.getBackgroundForClass(colorForText);
        }
    }

    showRxStatusColorRefactoring(item) {
        let statusColourText = "";
        if (item.Status === "T") {
            statusColourText = "his-bg--disabled__text--white";
        } else if (item.Status === "U") {
            statusColourText = "his-bg--red__text--white";
        } else if (item.Status === "B") {
            if (item.IsBilled) {
                statusColourText = "his-bg--green_text--white";
            } else {
                statusColourText = "his-bg--red__text--white";
            }
        } else if (item.Status === "D") {
            statusColourText = "his-bg--blue_text--white";
        } else {
            statusColourText = "his-bg--white__text--black";
        }
        return this.getRxSatusColourText(statusColourText);
    }

    getRxSatusColourText(statusColourText) {
        let colors: any = {};
        if (statusColourText === "his-bg--white__text--black") {
            colors.bg = "#FFF";
            colors.color = "#000";
        } else if (statusColourText === "his-bg--red__text--white") {
            colors.bg = "#bd362f";
            colors.color = "#FFF";
        } else if (statusColourText === "his-bg--green_text--white") {
            colors.bg = "darkgreen";
            colors.color = "#FFF";
        } else if (statusColourText === "his-bg--blue_text--white") {
            colors.bg = "#0000FF";
            colors.color = "#ECF0F5";
        } else if(statusColourText === "his-bg--disabled__text--white"){
            colors.bg = "#928f8f";
            colors.color = "#fff";
        }
        return colors;
    }

    showRfDueDtColorRefactoring(item) {
        let statusColourText = "";
        if (item.Status === "D") {
            statusColourText = "his-bg--blue_text--white";
        } else {
            statusColourText = "his-bg--white__text--black";
        }
        return this.getRxSatusColourText(statusColourText);
    }
    getRxStatusColor(item) {
        let rxStatusBackgroundColor = "";
        if (item.Status === "U" || item.statusForRx === "U") {
            rxStatusBackgroundColor = "#bd362f";
        } else if (item.Status === "B" || item.statusForRx === "B") {
            if (item.IsBilled) {
                rxStatusBackgroundColor = "darkgreen";
            } else {
                rxStatusBackgroundColor = "#bd362f";
            }
        } else if (item.Status === "D" || item.statusForRx === "D"  || item.Status === "F" || item.statusForRx === "F") {
            rxStatusBackgroundColor = "#0000FF";
        } else {
            rxStatusBackgroundColor = "#FFF";
        }
        return rxStatusBackgroundColor;
    }
    applyControlDrugColor(item: any) {
            if (!item ["IsPicked"]) {
                if (item.DrugClass >= 2 && item.DrugClass <= 5) {
                    return {
                        "his-bg--white__text--red": false,
                        "his-bg--white__text--black": false,
                        "his-bg--orange__text--black": false,
                        "his-bg--orange__text--red": true,
                        "his-bg--orange__text--blue": false,
                        "his-bg--white__text--blue": false
                    };
                } else {
                    return {
                        "his-bg--white__text--red": true,
                        "his-bg--white__text--black": false,
                        "his-bg--orange__text--black": false,
                        "his-bg--orange__text--red": false,
                        "his-bg--orange__text--blue": false,
                        "his-bg--white__text--blue": false
                    };
                }
            } else {
                if (item.DrugClass >= 2 && item.DrugClass <= 5) {
                return {
                "his-bg--white__text--red": false,
                "his-bg--white__text--black": false,
                "his-bg--orange__text--black": true,
                "his-bg--orange__text--red": false,
                "his-bg--orange__text--blue": false,
                "his-bg--white__text--blue": false
                };
            } else {
                return {
                "his-bg--white__text--red": false,
                "his-bg--white__text--black": true,
                "his-bg--orange__text--black": false,
                "his-bg--orange__text--red": false,
                "his-bg--orange__text--blue": false,
                "his-bg--white__text--blue": false
                };
            }
        }
    }

    showDrugColorRefactoring(item: any) {
        let colorForText = "";
        if (item.frmErx) {
            if (item.DrugClass >= 2 && item.DrugClass <= 5) {
                colorForText = "his-bg--orange__text--black";
            } else {
                colorForText = "his-bg--white__text--black";
            }
        } else {
            if (item.Status === "D") {
                if (item.DrugClass >= 2 && item.DrugClass <= 5) {
                    colorForText = "his-bg--orange__text--blue";
                } else {
                    colorForText = "his-bg--white__text--blue";
                }
            } else if (!item["IsPicked"]) {
                    if (item.DrugClass >= 2 && item.DrugClass <= 5) {
                        colorForText = "his-bg--orange__text--red";
                    } else {
                        colorForText = "his-bg--white__text--red";
                    }
                } else {
                    if (item.DrugClass >= 2 && item.DrugClass <= 5) {
                        colorForText = "his-bg--orange__text--black";
                    } else {
                        colorForText = "his-bg--white__text--black";
                    }
                }
        }
        
        return this.getDrugColourText(colorForText);
    }
    getDrugColourText(colorForText) {
        let colors: any = {};
        if (colorForText === "his-bg--white__text--black") {
            colors.bg = "#FFF";
            colors.color = "#000";
        } else if (colorForText === "his-bg--orange__text--black") {
            colors.bg = "orange";
            colors.color = "#000";
        } else if (colorForText === "his-bg--white__text--red") {
            colors.bg = "#FFF";
            colors.color = "#bd362f";
        } else if (colorForText === "his-bg--orange__text--red") {
            colors.bg = "orange";
            colors.color = "#FF0000";
        } else if (colorForText === "his-bg--orange__text--blue") {
            colors.bg = "orange";
            colors.color = "#0000FF";
        } else if (colorForText === "his-bg--white__text--blue") {
            colors.bg = "#FFF";
            colors.color = "#0000FF";
        }
        return colors;
    }
    showDrugColorForCommon(item: any) {
        let drugBackColor = {};
        if (item.Status === "F") {
            if (item.DrugClass >= 2 && item.DrugClass <= 5) {
                drugBackColor = {bgColor: "orange" , color: "#FF0000"};
            } else {
                drugBackColor = {bgColor: "#FFF" , color: "#0000FF"};
            }
        } else if (
            this._commonServ.getSetttingValue(
                "PharmacySettings",
                "HIGLT_NOT_PICKED_RXS"
            ) === "1"
        ) {
            if (!item["IsPicked"]) {
                if (item.DrugClass >= 2 && item.DrugClass <= 5) {
                    drugBackColor = {bgColor: "orange" , color: "#0000FF"};
                } else {
                    drugBackColor = {bgColor: "#FFF" , color: "#FF0000"};
                }
            } else {
                if (item.DrugClass >= 2 && item.DrugClass <= 5) {
                    return {
                        "his-bg--white__text--red": false,
                        "his-bg--white__text--black": false,
                        "his-bg--orange__text--black": true,
                        "his-bg--orange__text--red": false,
                        "his-bg--orange__text--blue": false,
                        "his-bg--white__text--blue": false
                    };
                } else {
                    return {
                        "his-bg--white__text--red": false,
                        "his-bg--white__text--black": true,
                        "his-bg--orange__text--black": false,
                        "his-bg--orange__text--red": false,
                        "his-bg--orange__text--blue": false,
                        "his-bg--white__text--blue": false
                    };
                }
            }
        } else if (item.DrugClass >= 2 && item.DrugClass <= 5) {
            return {
                "his-bg--white__text--red": false,
                "his-bg--white__text--black": false,
                "his-bg--orange__text--black": true,
                "his-bg--orange__text--red": false,
                "his-bg--orange__text--blue": false,
                "his-bg--white__text--blue": false
            };
        } else {
            return {
                "his-bg--white__text--red": false,
                "his-bg--white__text--black": true,
                "his-bg--orange__text--black": false,
                "his-bg--orange__text--red": false,
                "his-bg--orange__text--blue": false,
                "his-bg--white__text--blue": false
            };
        }
    }
    showDrugSearchColor(drugInfo: any) {
        const days = +this._commonServ.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
        const isDrugDiscontinued = drugInfo["discontdt"] &&
        moment().startOf("day").isAfter(moment.utc(drugInfo["discontdt"]).local().add(days, "days"));
        if (isDrugDiscontinued && drugInfo.ispreferred) {
            return {
                "bg-red_text-brown": true, "bg-green_text-brown": false, "bg-white_text-black": false,
                "bg-red_text-white": false, "bg-green_text-black": false, "bg-white_color-brown": false
            };
        } else if ((drugInfo["lastDispensedDate"] || drugInfo["Last Dispensed"]) && drugInfo.ispreferred) {
            return {
                "bg-red_text-brown": false, "bg-green_text-brown": true, "bg-white_text-black": false,
                "bg-red_text-white": false, "bg-green_text-black": false, "bg-white_color-brown": false
            };
        } else if (isDrugDiscontinued) {
            return {
                "bg-red_text-brown": false, "bg-green_text-brown": false, "bg-white_text-black": false,
                "bg-red_text-white": true, "bg-green_text-black": false, "bg-white_color-brown": false
            };
        } else if (drugInfo["lastDispensedDate"] || drugInfo["Last Dispensed"]) {
            return {
                "bg-red_text-brown": false, "bg-green_text-brown": false, "bg-white_text-black": false,
                "bg-red_text-white": false, "bg-green_text-black": true, "bg-white_color-brown": false
            };
        } else if (drugInfo.ispreferred) {
            return {
                "bg-red_text-brown": false, "bg-green_text-brown": false, "bg-white_text-black": false,
                "bg-red_text-white": false, "bg-green_text-black": false, "bg-white_color-brown": true
            };
        } else {
            return {
                "bg-red_text-brown": false, "bg-green_text-brown": false, "bg-white_text-black": true,
                "bg-red_text-white": false, "bg-green_text-black": false, "bg-white_color-brown": false
            };
        }
    }

    responseStatus(item) {
        if (item["Refill Status"] !== "Deferred Rx") {
            if (item["Refill Status"] === "Early To Refill") {
                return {
                    "his-bg--white__text--lightGreen": true,
                    "his-bg--white__text--red": false,
                    "his-bg--white__text--Darkgreen": false,
                    "his-bg--white_text--blue": false
                };
            } else if (item["Refill Status"] === "RefillExpired") {
                return {
                    "his-bg--white__text--lightGreen": false,
                    "his-bg--white__text--red": true,
                    "his-bg--white__text--Darkgreen": false,
                    "his-bg--white_text--blue": false
                };
            } else if (item["Refill Status"] === "Regular") {
                return {
                    "his-bg--white__text--lightGreen": false,
                    "his-bg--white__text--red": false,
                    "his-bg--white__text--Darkgreen": true,
                    "his-bg--white_text--blue": false
                };
            } else if (item["Refill Status"] === "No Quantity") {
                return {
                    "his-bg--white__text--lightGreen": false,
                    "his-bg--white__text--red": true,
                    "his-bg--white__text--Darkgreen": false,
                    "his-bg--white_text--blue": false
                };
            }
        } else {
            return {
                "his-bg--white__text--lightGreen": false,
                "his-bg--white__text--red": false,
                "his-bg--white__text--Darkgreen": false,
                "his-bg--white_text--blue": true
            };
        }
    }

    getBackgroundForClass(classForBackground:string) {
        let colorBgObj: any = {};
            if(classForBackground === 'his-bg--white__text--red')
                    colorBgObj = {color: "#bd362f", bg: '#FFF', classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--lightgreen__text--white')
                    colorBgObj = {color: "#000", bg: 'lightgreen', classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--darkgreen__text--white')
                     colorBgObj = {color: "#ECF0F5", bg: 'darkgreen', classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--blue__text--white')
                colorBgObj = {color: "#ECF0F5", bg: '#0000FF', classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--blue_red__text--white')
                colorBgObj = {color: "#FFF", bg: 'linear-gradient(to left, red  20%, blue 80%)', classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--peach__color--white')
                colorBgObj = {color: "#ECF0F5", bg: '#FF9999', classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--peach__color--white')
                colorBgObj = {color: "#ECF0F5", bg: '#FF9999', classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--white__text--black')
                colorBgObj = {color: "#000", bg: '#FFF' , classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--blue_peach__text--black')
                colorBgObj = {color: "#ECF0F5", bg: 'linear-gradient(to left, rgb(255, 153, 153) 20%, blue 80%)', classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--blue_white__text--black')
                colorBgObj = {color: "black", bg: 'linear-gradient(to left, white 20%, blue 80%)' , classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--peach_red__text--black')
                colorBgObj = {color: "#black", bg: 'linear-gradient(to left, red  20%, rgb(255, 153, 153) 80%)' , classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--peach_grey__text--black')
                colorBgObj = {color: "black", bg: 'linear-gradient(to left, grey  20%, rgb(255, 153, 153) 80%)' , classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--white_red__text--black')
                colorBgObj = {color: "#000", bg: 'linear-gradient(to left, red  20%, white 80%)', classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--white_grey__text--black')
                colorBgObj = {color: "black", bg: 'linear-gradient(to left, grey  20%, white 80%)', classForBackground : classForBackground};
            else if(classForBackground === 'his-bg--red__text--white')
                colorBgObj = {color: "#FFF", bg: '#bd362f' , classForBackground : classForBackground}; // #f6251a
            else if(classForBackground === 'his-bg--grey__text--white')
                colorBgObj = {color: "#FFF", bg: 'grey' , classForBackground : classForBackground};
                else if(classForBackground === 'his-bg--blue_grey__text--white')
                colorBgObj = {color: "#FFF", bg: 'linear-gradient(to left, grey  20%, blue 80%)', classForBackground : classForBackground};
            return colorBgObj;
    }
    showRxStatusColorCoding(item) {
        let colorForText = "";
        if (item.Status === "U") {
            colorForText = "his-bg--red__text--white";
        } else if (item.Status === "B") {
            colorForText = (item.IsBilled) ? "his-bg--darkgreen__text--white" : "his-bg--red__text--white";
        } else if (item.Status === "D") {
            colorForText = "his-bg--blue__text--white";
        } else {
            colorForText =  "his-bg--white__text--black";
        }
        return this.getBackgroundForClass(colorForText);
    }
    getPartialOrFullPayment(payRecords: any) {
        if(payRecords?.length) {
            payRecords =  payRecords?.filter((item: any) => { return  (item['Rectype'] !== 'R' && item?.IsDeleted == false && item['PaidDate'] !== null); })
            if(payRecords?.length) {
                let TotalAmountPaid: any = 0;
                let TotalICostPaid: any = 0;
                payRecords.map((item: any,index: any) => {
                    item['PaidDate'] =  item['PaidDate'] ? moment(item['PaidDate']).format("MM/DD/YYYY") : null; 
                    TotalAmountPaid  =  (item.AmountPaid) + TotalAmountPaid;
                    TotalICostPaid  =  (item.ICostPaid) + TotalICostPaid;
                });
                return  {IsPartiallyPaidComp : payRecords.every((item: any) => { return (item?.ICostPaid == item?.AmountPaid); }) , Records: payRecords,
                TotalAmountPaid : TotalAmountPaid , PendingAmount : (TotalICostPaid -  TotalAmountPaid) };
            } else {
                return  {IsPartiallyPaidComp : null , Records: []};
            }
            
        } else {
            return  {IsPartiallyPaidComp : null , Records: []};
        }
         
  }

}
