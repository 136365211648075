import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: "[appHostListen]"
})
export class ShortcutTabDirective {
    @Output() ShortcutEmit = new EventEmitter<any>();

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        this.clickEvent(event);
    }

    constructor(private _el: ElementRef) { }

    clickEvent(event) {
        const targetElem = document.elementFromPoint(
            Math.ceil(this._el.nativeElement.getBoundingClientRect().left + 2),
            Math.ceil(this._el.nativeElement.getBoundingClientRect().top) + 2);
        if (targetElem && targetElem.isSameNode(this._el.nativeElement)) {
            this.ShortcutEmit.emit(event);
        }
    }
}

