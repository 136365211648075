import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class StaticVaribles {
  constructor() { }

  getRxExpiredClasses() {
    return ["his-bg--peach__color--white", "his-bg--blue_peach__text--black", "his-bg--peach_grey__text--black", "his-bg--peach_grey__text--black", "his-bg--peach_red__text--black", "his-bg--blue_peach__text--black"];
  }

}