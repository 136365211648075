import { CommonService, AlertService, ReportsService } from "src/app/services";
import {
    Component,
    OnInit,
    OnChanges,
    ElementRef,
    ViewChild,
    HostListener,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Output
} from "@angular/core";
import { EditPrescriberService } from "../../../services";
import { FormGroup } from "@angular/forms";
import { SystemData } from "../../../models";
import * as _ from "lodash";
import { RegEx } from "../../../app.regex";
import { PrescriberUtil, CommonUtil } from "src/app/utils";
import { Observable, Subject } from "rxjs";
import { PrescriberStore } from "src/app/store";
import { MsgConfig } from "src/app";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
@Component({
    selector: "app-prescriber-info",
    templateUrl: "./prescriber-info.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrescInfoComponent
    implements OnInit, OnChanges {
    prescdropValues: any;
    @Input() systemData: SystemData;
    @Input() prescriberInfo: any;
    @Input() prescriberFG: FormGroup;
    @Input() formGroupInvalid: boolean;
    @Input() focusOnDea: boolean;
    npiRequired = true;
    PrescNPIRequired = true;
    originlFacilityList: any[];
    @Input()
    set PrescDropValues(pdp) {
        if (pdp) {
            this.prescdropValues = pdp;
        }
    }
    @Input()
    set DropDownValues(dp){
        if (dp) {
            this.facilitiesList = dp;
        }
    }

    @ViewChild("warningInfo", { static: true })
    warningInfo: ElementRef;

    regex = RegEx;
    modalRef: any;
    UpdateSwitch: boolean;
    btnSwitch: boolean;
    newPrescID: any;
    warningMsg: string;
    sharedCancel = false;
    canDeactivateRoute = true;
    dataFmMMS: boolean;
    isFromErxToAdd: boolean;
    eRxVersion: any;
    prescriberCity$: Observable<any>;
    prescriberState$: Observable<any>;
    facilitiesList: any[] = [];
    unsubscribe$ : Subject<void> = new Subject();
    @Output() TextNPI = "Refresh NPI";
    @Output() TextSPI = "Refresh SPI";
    @Input() prescriberId: number;
    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27 && this.modalRef) { // esc
            this.modalRef.close();
            this.modalRef = null;
            event.preventDefault();
        }

    }

    constructor(
        private _prescService: EditPrescriberService,
        private _commonServ: CommonService,
        private _commonUtil: CommonUtil,
        private _prescriberUtil: PrescriberUtil,
        private _prescStore: PrescriberStore,
        private _alertSvc: AlertService,
        private _mdlServ: NgbModal,
        private _reportsServ: ReportsService,
        private _cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.prescriberCity$ = this._prescStore.prescriberCity$;
        this.prescriberState$ = this._prescStore.prescriberState$;
        // this._prescService.getPrescriberDropDown().subscribe(resp => {
        //     if (resp) {
        //         this.prescdropValues = resp;
        //     }
        // });
        // this._commonServ.getCommonDropDownValues({IsFacility : true , IsPatientCategory: false , IsPayor: false , IsInsuranceList: false}).subscribe(resp => {
        //     if (resp && resp["Facilities"] && resp["Facilities"].length) {
        //         this.facilitiesList = resp["Facilities"];
        //     }
        //   });
        this.eRxVersion = this._commonServ.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
    }


    get PrimarySpeciality(): FormGroup {
        return this.prescriberFG.get("PrimarySpeciality") as FormGroup;
    }

    get SecondarySpeciality(): FormGroup {
        return this.prescriberFG.get("SecondarySpeciality") as FormGroup;
    }

    get TertiarySpeciality(): FormGroup {
        return this.prescriberFG.get("TertiarySpeciality") as FormGroup;
    }

    get PrescriberSalesPerson(): FormGroup {
        return this.prescriberFG.get("PrescriberSalesPerson") as FormGroup;
    }

    get SpecialProgram(): FormGroup {
        return this.prescriberFG.get("SpecialProgram") as FormGroup;
    }

    get Prescriber(): FormGroup {
        return this.prescriberFG.get("Prescriber") as FormGroup;
    }

    patchStateAndCity(data, type?: any) {
        this._prescriberUtil.patchCityAndStateValue(data, this.prescriberFG, (type ? type : null));
    }

    ngOnChanges(changes: any) {
        this.prescriberFG.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (this.prescriberFG.dirty) {
                this.canDeactivateRoute = false;
            } else {
                this.canDeactivateRoute = true;
            }
        });
    if (this.prescdropValues && this.prescdropValues.Speciality && this.prescdropValues.Speciality.length && this.PrimarySpeciality &&
            this.PrimarySpeciality.value.SpecialityId) {
       const specialityCode = this.prescdropValues.Speciality.filter(ele => {
            return ele.Id === this.PrimarySpeciality.value.SpecialityId;
       });
       this.npiRequired = (specialityCode && specialityCode.length &&
           (specialityCode[0].Remarks === "Veterinarian" || specialityCode[0].Remarks === "Medical Research Veterinarian")) ? false : true;
    }
    if(this.prescdropValues && this.prescdropValues.PrescriberCat && this.prescdropValues.PrescriberCat.length && this.prescriberFG && this.prescriberFG.value.Prescriber && this.prescriberFG.value.Prescriber.PrescCatId){
        const prescdata=  this.prescdropValues.PrescriberCat.find(ele =>{
            return ele.Id === this.prescriberFG.value.Prescriber.PrescCatId;
        });
        this.PrescNPIRequired = (prescdata && !prescdata.IsDefault)? false :true;
    }
    const newArr = [];
    if (this.prescriberFG.controls["PrescFacility"].value) {
        this.prescriberFG.controls["PrescFacility"].value.map(item => {
            newArr.push(item.FacilityId);
        });
        this.prescriberFG.controls["Prescriber"].patchValue({FacilityIds : newArr});
    }
    }

    checkIsSpecilaityExist(e, cntrl) {
        if (e.value && e.value.Id) {
            const cntrlName = ["PrimarySpeciality", "SecondarySpeciality", "TertiarySpeciality"];
            const checkCntrlName = cntrlName.filter(c => c !== cntrl);
            const checkCntrlData = [];
            checkCntrlName.map(val => {
                checkCntrlData.push(this.prescriberFG.value[val]["SpecialityId"]);
            });
            if (checkCntrlData.indexOf(e.value.Id) > -1) {
                const fg: any = this.prescriberFG.controls[cntrl];
                fg.controls["SpecialityId"].setValue(null);
                this.prescriberFG.controls[cntrl].setValue(null);
                this._alertSvc.warning("Speciality exist.");
            }
        }
    }

    focusToSave(event) {
        this._commonUtil.focusById("SaveButton", event);
    }


    selectedSpecilaity(type: any, event?) {
        if (this.prescriberFG.value[type]["SpecialityId"]) {
            const values = [this.prescriberFG.value["PrimarySpeciality"]["SpecialityId"],
            this.prescriberFG.value["SecondarySpeciality"]["SpecialityId"],
            this.prescriberFG.value["TertiarySpeciality"]["SpecialityId"]];
            const seletedData = values.filter(data => data === this.prescriberFG.value[type]["SpecialityId"]);
            if (seletedData && seletedData.length > 1) {
                this.modalRef = this._mdlServ.open(this.warningInfo, {
                    centered: true,
                    keyboard: false,
                    backdrop: false,
                    windowClass: "large--content"
                });
                this.warningMsg = MsgConfig.PRESCRIBER_SPECIALITY_EXISTS;
                this[type].controls["SpecialityId"].patchValue(null);
            }
        }
        this.npiRequired = event && event.value && (event.value.Remarks === "Veterinarian" ||
         event.value.Remarks === "Medical Research Veterinarian") ? false : true;

    }
    CheckselectedPresciberType(event?){
        this.PrescNPIRequired = event && event.value && (event.value.IsDefault === false )? false :true;
    }
    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }
    onAddressChange(address,type){
        const result = address.replace(/^\s+/g, '');
        if(type === "Address1"){
            this.prescriberFG.controls["Address"].patchValue({
                AddressLine1: result
             });
        }else{
            this.prescriberFG.controls["Address"].patchValue({
                AddressLine2: result
             });
        }
    }
    patchFacilityValues(event) {
        const list = [];
        if (event && event.value) {
            event.value.map(item => {
                list.push({FacilityId : item.Id});
            });
            this.prescriberFG.controls["PrescFacility"].patchValue(list);
        }
    }
    handleLicenseBlur(event: any): void {
        let inputElement = event?.target?.value;
        if(inputElement){
            this.prescriberFG.controls["Prescriber"].patchValue({PrescbNum : inputElement});
        }
    }
    ngOnDestroy(): void {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
