import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { BehaviorSubject, Observable  ,  Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class LoaderService {
    subject = new Subject<any>();
    keepAfterNavigationChange = false;

    loaderMessage = new Subject<any>();;
    _enableSignIn$:BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    enableSignIn$: Observable<any> = this._enableSignIn$.asObservable();
    constructor(private router: Router) {
        // clear alert message on route change
        router?.events?.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next(false);
                }
            }
        });
    }

    display(display: boolean, keepAfterNavigationChange?: any) {
        this.keepAfterNavigationChange = (keepAfterNavigationChange) ? keepAfterNavigationChange : false;
        this.subject.next(display);
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    addMessage(message) {
        this.loaderMessage.next(message);
    }

    getLoaderMessage() {
        return this.loaderMessage.asObservable();
    }

    enableDisableSignIn(enableOrDisable) {
        this._enableSignIn$.next(enableOrDisable)
    }

}
