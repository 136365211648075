import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { eRxTRxREQModel, Pharmacy, PharmacyDatas, Pharmphone, SystemData } from 'src/app/models';
import { DrugLabels, Labels, PatientLabels, PharmacyLabels } from 'src/app/models/labels.models';
import { CommonService, RxTransferService, UserService } from 'src/app/services';
import { CommonUtil, DrugUtils } from 'src/app/utils';
import { PatientInfoUtil } from 'src/app/utils/patient-info.util';
import * as _ from "lodash";
import { FormatsUtil } from 'src/app/utils/formats.util';
import { TransferPharmacyComponent } from '../transfer-pharmacy/transfer-pharmacy.component';
import { CommonWarnorconfirmPopupComponent } from '../common-warnorconfirm-popup/common-warnorconfirm-popup.component';
import { DrugBucketUtil } from 'src/app/utils/drug-bucket.util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-e-rx-trx-req',
  templateUrl: './e-rx-trx-req.component.html',
  styleUrls: ['./e-rx-trx-req.component.scss']
})
export class ERxTRxREQComponent implements OnInit {
  @ViewChild("eRxTRxREQ", { static: true })
  eRxTRxREQ: any;
  
  modelRef: any;
  commonLabels: Labels;
  selectedDrug: any ;
  drugInputValue: any = false;
  drugSelectedDetails: any ;
  dispDrugSearch:boolean = false;
  clearInputValue:any = false;
  selectedPatientDetails: any;
  selectedPharmacyDetails: any;
  patientSearchValue: string = "";
  drugSearchValue: string;
  eRxTRxREQFormGroup: FormGroup;
  patientLabels: any;
  drugLabels: any;
  pharmacyabels: any;
  msgConfig;
  pharmacyList: any  = [];
  requestPharmacyDetails: any  = {
  }
  eRxModelFG: FormGroup;
  getPharmNanes: any;
  newRxPhone: any;
  SearchPatient: boolean = false;
  SearchDrug: boolean = false;
  IsFocusField: string = "";
  primaryInsu: any;
  @Output() ClosePopUpModal = new EventEmitter();
  unsubscribe$: Subject<void> = new Subject();
  systemData: SystemData;
  PharmacyNames: PharmacyDatas = new PharmacyDatas();
  logPhaId: any;
  modalRef: any;
  addPharFG: FormGroup;
  isfromPatientHistory: boolean = false;
  pharmacistName: string = "";
  @Input()
  set SelectedPatientDetailsfrmRx(patient : any){
      if(patient) {
        this.isfromPatientHistory = true;
        this.checkIsActivePatient(patient);
      }
  }

  constructor(private _modalService:NgbModal,
    private _cdr: ChangeDetectorRef,
    private _rxTransferDet: RxTransferService,
    private _fb: FormBuilder,
    private _commUtil: CommonUtil,
    private _patientUtil: PatientInfoUtil,
    private _userService:UserService,
    private _commonServ:CommonService,
    private _drugUtil:DrugUtils,
    private _formatUtil:FormatsUtil,
    private _rxSer:RxTransferService,
    public _buckUtils: DrugBucketUtil,
    private router:Router
  ) { 
    this.createFG();
    this.eRxTRxREQFormGroup = this._fb.group(new eRxTRxREQModel());
    this._commonServ.systemData$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
        if (resp) {
            this.systemData = resp;
        }
    });
    const routerState = this.router.routerState;
    console.log(routerState)
  }
  createFG() {
    this.eRxModelFG = this._fb.group({
      PatientId: new FormControl(null, [Validators.required]),
      DrugId: new FormControl(null, [Validators.required]),
      PharmacyId: new FormControl(null, [Validators.required]),
  });
    this.addPharFG = this._fb.group({
      TransferPharmacies: this._fb.group(new Pharmacy()),
      phone1: null,
      phone2: null,
      Fax: null,
      Phone: this._fb.array([new Pharmphone()])
  });
  }

  ngOnInit(): void {
    if (this.router?.routerState?.root) {
        const rootState = this.router.routerState.root;
        console.log(rootState);  
    } else {
        console.error('Router state is undefined');
    }
    this.logPhaId = this._userService.getToken("UserId") ? +this._userService.getToken("UserId") : null;
    this.pharmacistName = this._commonServ.DecryptData(localStorage.getItem("PharmacistName"));
    this.getPharmachyNames();
    this.getRequestPharmacy();
    this.msgConfig = MsgConfig;
    this.patientLabels = new PatientLabels();
    this.drugLabels = new DrugLabels();
    this.pharmacyabels = new PharmacyLabels();
    this.modelRef = this._modalService.open(this.eRxTRxREQ, {centered: true,
      backdrop: false, keyboard: false ,windowClass: "max-modal-lg-size"});
  }

getPharmachyNames(type?: any, event?) {
  this._rxSer.getPharmacyNames()
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe(resp => {
    if (resp) {
        this.getPharmNanes = resp;
        this.PharmacyNames.pharmNames = this.arrangePharmDet(resp["pharmNames"]);
        this.PharmacyNames.pharmacistNames = resp["pharmacistNames"];
        if (this._userService.getToken("TechUserName") && type) {
            this.logPhaId = null;
            if (resp["pharmacistNames"] && resp["pharmacistNames"].length) {
               const d = resp["pharmacistNames"].find(v => v["PharmacistName"] === this._userService.getToken("PhUserName"));
               this.logPhaId = d ? d["PharmacistId"] : null;
            }
        } else {
            this.logPhaId = this._userService.getToken("UserId") ? +this._userService.getToken("UserId") : null;
        }
        if(event)
          this.getPharmcyDetails(event, true);
    }
  });
}

arrangePharmDet(data) {
    let pharmaDetails = [];
    if (data && data["pharmNamesViewModels"] && data["pharmNamesViewModels"].length) {
        pharmaDetails = data["pharmNamesViewModels"].map(v => {
            const k = {};
            k["Id"] = v["Id"];
            k["PharmacyName"] = v["PharmacyName"];
            let phoneDet = null;
            if (data["Phones"] && data["Phones"].length) {
                phoneDet = data["Phones"].filter(d => d["Id"] === v["Id"]);
            }
            if(data["addresses"] && data["addresses"].length){
                const addrs = data["addresses"].filter(a=> a["id"] === v["Id"]);
                k["addresses"] = ( addrs[0].add1 ?  addrs[0].add1 + "," + " "
                + (addrs[0].city ?  addrs[0].city + "," + " " : "")  +
                (addrs[0].state ? addrs[0].state + "," + " " : "") +
                 (addrs[0].zipcode ? addrs[0].zipcode : "") : "" );
                  
            }
            if (phoneDet && phoneDet.length) {
                const ph12 = phoneDet.filter(pd => pd["PhoneCatId"] === 3);
                k["Phone1"] = ph12 && ph12.length ? ph12[0]["Phone"] : null;
                k["Phone2"] = ph12 && ph12.length > 1 ? ph12[1]["Phone"] : null;
                const fax = phoneDet.filter(pd => pd["PhoneCatId"] === 4);
                k["Fax"] = fax && fax.length ? fax[0]["Phone"] : null;
            } else {
                k["Phone1"] = null;
                k["Phone2"] = null;
                k["Fax"] = null;
            }
            k["NPINum"] = v.NPINum ? v.NPINum : null;
            return k;
        });
    }
    return pharmaDetails;
}
  async getRequestPharmacy() {
    const resp: any = await this._commonServ.getNewTenantinfoDetails().toPromise();
    if (resp?.PharmacyDetails) {
      resp.PharmacyDetails.TelephoneNumber = this._formatUtil.getPhoneFormat(resp.PharmacyDetails.Telephone);
      resp.PharmacyDetails.pharmAddress = this._patientUtil.getPatientAddress({
        address1: resp.PharmacyDetails.AddressLine1,
        address2: resp.PharmacyDetails.AddressLine2,
        state: resp.PharmacyDetails.StateName,
        zipcode: resp.PharmacyDetails.ZipCode,
        city: resp.PharmacyDetails.CityName,
      });
    }
      this.requestPharmacyDetails = resp.PharmacyDetails;
  }
  
  openTransferPharmacy() {
    this.addPharFG.reset();
    this.createFG();
    this.modalRef = this._modalService.open(TransferPharmacyComponent, {
        centered: true
    });
    this.modalRef.componentInstance.PharmacyFG = this.addPharFG;
    this.modalRef.componentInstance.IsEditMode = false;
    this.modalRef.result
    .then(res => {
      if(res?.AddValue)
      {
        this.getPharmachyNames("", res?.AddValue);
      }
    })
    .catch(error => error);
}
  selectDrug(drug) {
    this.drugInputValue = null;
    this.SearchDrug = false;
    this._cdr.detectChanges();
    this.drugInputValue = this._drugUtil.getDrugFullName(drug);
    this.drugSelectedDetails = drug;
    if(drug) {
      const drugName = this._drugUtil.getDrugFullName(drug);
      if(!drug?.isactive) {   
        const warnMsg = "Selected Drug <label><span class='font-weight-bold'>" + drugName  +"</span></label> is not an Active Drug.";
        this.commonWarnorconfirmPopup({warnMsg: warnMsg, IsHeaderText : MsgConfig.Inactive_Drug, IsFocusField : "drug"});;
      }else if(this._drugUtil.discontinuedDrug(drug)) {
        this.commonWarnorconfirmPopup({warnMsg: "Selected Drug is discontinued.", IsHeaderText : MsgConfig.Information_msg, IsFocusField : "pharmacy"});;
      } else if(drug.drugtypeid == 2 ) {
        this.commonWarnorconfirmPopup({warnMsg: "eRx transfer requests are not supported for compound drugs. Please select a different drug.", IsHeaderText : MsgConfig.Warn_Text_Header, IsFocusField : "drug"});;
      }   else {
        this.IsFocusField = "";
        this._cdr.detectChanges();
        this.eRxModelFG.controls["DrugId"].patchValue(drug?.id);
        this.drugSelectedDetails.fullName = this._drugUtil.getDrugFullName(drug);
        this.drugSelectedDetails.drugcode = this._formatUtil.getNDCFormat(drug.drugcode);
        this.IsFocusField = "pharmacy";
        this.SearchDrug = true;
      }
     
    } else {
      this.eRxModelFG.controls["DrugId"].patchValue(null);
    }
  }
  commonWarnorconfirmPopup(object) {
    const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
      backdrop: false,
      keyboard: false, windowClass: "large--content"
    });
    modelRef.componentInstance.warnMsg = object.warnMsg;
    modelRef.componentInstance.okButton = true;
    modelRef.componentInstance.IsHeaderText = object.IsHeaderText;
    modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
      if(resp)
      {
        if(object.IsFocusField === "drug") {
          this.drugSelectedDetails = null;
          setTimeout(() => {
            this.drugInputValue = null;
          }, 10);
        } else if(object.IsFocusField === "patient") {  
          setTimeout(() => {
            this.patientSearchValue = null;
          }, 10);
        }
        modelRef.close();
        this.IsFocusField = "";
        this._cdr.detectChanges();
        this.IsFocusField = object.IsFocusField;
      }
    });
  }
  checkIsActivePatient(patient) {
    this.selectedPatientDetails = null;
    this.patientSearchValue = "";
    if (patient) {
      const patientName = patient.fullname ? ((patient.fullname).toUpperCase()).replace("_", ", ") : "";
      if (patient && patient["fullname"] && (patient["actstatus"] !== 1)) {
        const warnMsg = "Selected Patient <label><span class='font-weight-bold'>" + patientName  +"</span></label> is not an Active Patient.";
        this.commonWarnorconfirmPopup({warnMsg: warnMsg, IsHeaderText : MsgConfig.Inactive_Patient, IsFocusField : "patient"});
      } else {
        this.eRxModelFG.controls["PatientId"].patchValue(patient?.patientid);
        this._cdr.detectChanges();
        this.selectedPatientDetails = patient;  
        this.patientSearchValue = patientName;
        patient.dob = patient.dob ? this._commUtil.getPatientAge(patient.dob) : "";
        patient.fullname = patient.fullname ? this._patientUtil.getPatientFullName(patient) : "";
        patient.address = patient ? this._patientUtil.getPatientAddress(patient) : "";
        patient.AllergiesList = (patient?.patallergies?.length) ? this._patientUtil.getPatientAllergies(patient) : "";
        this.IsFocusField = "";
        this._cdr.detectChanges();
        this.IsFocusField = "drug";
        this.primaryInsu = _.filter(this.selectedPatientDetails?.insurance, {
          insuranceid: +this.selectedPatientDetails.insuranceid
        })[0];
          if (!this.primaryInsu) {
            this.primaryInsu = _.filter(this.selectedPatientDetails.insurance, {
                IsCash: true
            })[0];
        }
        if (!this.primaryInsu) {
            this.primaryInsu = _.filter(this.selectedPatientDetails.insurance, {
                insurerid: this.systemData["DefaultValuese"][0]["CashInsurerId"]
            })[0];
        }
        if (!this.primaryInsu) {
            this.primaryInsu = _.filter(this.selectedPatientDetails.insurance, {
                insuranceid: 10189
            })[0];
          } 
      }
    this.SearchPatient = false;
    this._cdr.detectChanges();
    this.SearchPatient = true;
  
} else {
  this.eRxModelFG.controls["PatientId"].patchValue(null);
  this.SearchPatient = true;
    this._cdr.detectChanges();
    this.SearchPatient = false;
    this.primaryInsu = null;
    this.IsFocusField = "patient";

}
  }
  closePopup() {
    if(this.modelRef) {
      this.modelRef.close();
      this.modelRef = null;
    }
    this.ClosePopUpModal.emit(false);
  }
  radioSelection() {

  }
  getPharmcyDetails(event, IsFromInit:boolean = false) {
    if(event?.value?.Id || (event && IsFromInit))
    {
      this._rxSer.getPharmacyDetails((event?.value?.Id) ? event?.value?.Id : event)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp: any) => {
        resp["TransferPharmacies"].Telephone =  this._formatUtil.getPhoneFormat(resp.TransferPharmacies.Phone);
        resp.TransferPharmacies.selectPharmAddress = this._patientUtil.getPatientAddress({
          address1: resp.TransferPharmacies.AddressLine1,
          address2: resp.TransferPharmacies.AddressLine2,
          state: resp.TransferPharmacies.StateName,
          zipcode: resp.TransferPharmacies.ZipCode,
          city: resp.TransferPharmacies.CityName,
        });
        this.eRxModelFG.controls["PharmacyId"].patchValue(resp?.TransferPharmacies?.PharmId);
        this.selectedPharmacyDetails =  resp["TransferPharmacies"];
      });
    } else {
      this.eRxModelFG.controls["PharmacyId"].patchValue(null);
      this.selectedPharmacyDetails  = null;
    }
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}
}
