import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { AlertService, CommonService, ControlFileService, RphDashboardService, RxService } from "src/app/services";
import { RecallDeleteRxService } from "src/app/services/recall-delete-rx.service";
import { AuditLogUtils, CommonUtil, ValidateFormFieldsUtils } from "src/app/utils"; 
import { PaginationModel } from "src/app/models/elastc-rx.model";

@Component({
    selector: "app-file-due-date",
    templateUrl: "./file-due-date.component.html"
})
export class FileDueDateComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    @Output()
    CloseFileDueDate = new EventEmitter<any>();
    @Input() selectedRxs: any;
    deferredReasonList = [];
    NotUnbilledRxsListForDisplay = [];
    UnbilledRxsListForDisplay = [];
    NotUnbilledRxs = [];
    UnbilledRxs = [];
    noneReason = [
        {
            Id: null,
            Name: "None",
            TenantId: null,
            IsActive: true,
            CreatedDtTm: null,
            ModifiedDtTm: null
        }
    ];
    warningMsg: string;
    popUpOpenedFor: string;
    warningModelRef: any;

    @ViewChild("WARNFILEMULTIRX", {static: true})
    WARNFILEMULTIRX: any;

    @ViewChild("FILEDUEDATE", {static: true})
    FILEDUEDATE: any;

    @ViewChild("VERFCONFIRMATION", {static: true})
    VERFCONFIRMATION: any;

    @ViewChild("ResubmitConfirm", {static: true})
    ResubmitConfirm: any;

    confirmModel: any;
    fileFG: FormGroup;
    DEAProcessingData: any[];
    DEARxInfo: any;

    constructor(private _fb: FormBuilder,
        private _formUtils: ValidateFormFieldsUtils,
        private _alertSvc: AlertService,
        private _recallSer: RecallDeleteRxService,
        private _contrlSer: ControlFileService,
        private _rxSvc: RxService, private _modalService: NgbModal,
        private _DfrdReasonServ: RphDashboardService, private _commUtil: CommonUtil,
        private _commonServ: CommonService,
        private _auditUtils: AuditLogUtils) { }

    ngOnInit() {
        if (this.selectedRxs && this.selectedRxs.length) {
            this.createAndPatchFileDets();
            this.checkforPaidRec();
        }
    }

    createAndPatchFileDets() {
        this.fileFG = this._fb.group({
            dueDateTime: [],
            deferredReason: [],
            dpvNRpvFlag: [],
            DrugPickVerify: [],
            RphVerify: [],
            DeferredReasonName: []
        });
        this.fileFG.patchValue({
            dueDateTime: moment().format("MM/DD/YYYY"),
            deferredReason: null,
            dpvNRpvFlag: false,
            DrugPickVerify: [],
            RphVerify: []
        });
        const dfrdReasonFG = { SearchText: null, PageNumber: this.paginationModel.pageNumber, PageSize: this.paginationModel.pageSize };
        this.getDeferredReasonList(dfrdReasonFG);
    }

    checkforPaidRec() {
        this.selectedRxs.map((item: any) => {
            const isPaidRecords = (item && item.RxPay) ? item.RxPay.filter(v => v.RecType === "P") : null;
            if ((isPaidRecords && isPaidRecords.length) || (item && item.RxBill && item.RxBill.StatusId !== 1) ||
                (item.PrescReFill && item.PrescReFill.StatusId !== 1)) {
                this.NotUnbilledRxsListForDisplay.push(this.getRxStatus(item, isPaidRecords));
                this.NotUnbilledRxs.push(item);
            } else {
                this.UnbilledRxsListForDisplay.push
                    (" <label>" + item["Prescription"]["PrescNum"] + "-" + item["PrescReFill"]["ReFillNum"] +
                     (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"]) ? "-" + item["PrescReFill"]["PartialFillNo"] : " ") + "</label>");
                this.UnbilledRxs.push(item);
            }
           });
        if (this.NotUnbilledRxs && this.NotUnbilledRxs.length) {
            this.selectedRxsOtherThanUnbilled();
            if (this.NotUnbilledRxs.length === this.selectedRxs.length) {
                this.warningMsg += "<br/>Please select only unbilled rx(s) without paid pay records to Defer.";
                this.popUpOpenedFor = "ALLNOTUNBILLED";
            } else if (this.NotUnbilledRxs.length < this.selectedRxs.length) {
                this.warningMsg += "<br/><br/>Following Rx(s) can be Deferred:";
                this.UnbilledRxsListForDisplay.forEach(item => {
                    this.warningMsg += "<br/>" + item;
                });
                this.warningMsg += "<br/>Would you like to proceed and Defer?";
                this.popUpOpenedFor = "FEWUNBILLED";
            }
            this.warningModelRef = this._modalService.open(this.WARNFILEMULTIRX, {centered: true, keyboard: false, backdrop: false,
                 windowClass: "large--content" });
        } else {
            this.checkLatestFill();
        }
    }

    getRxStatus(item, ispaidRecords?) {
        let rxStatus: any = "";
        if ((item.RxBill && item.RxBill.StatusId !== 1) ||
        (item.PrescReFill && item.PrescReFill.StatusId !== 1)) {
            rxStatus = (item.PrescReFill.StatusId === 4 || item.Prescription.RxStatusId === 4) ? " : Transferred out " :
                    (item.PrescReFill.StatusId === 2 || item.Prescription.RxStatusId === 2 ||
                        item.RxBill.StatusId === 6) ? " : Already Deferred " :
                    (item.PrescReFill.StatusId === 3
                        || item.Prescription.RxStatusId === 3) && item.RxBill.StatusId === 2 ? " : Billed Rx " :
                    (item.RxBill.StatusId === 2 && item.PrescReFill.StatusId !== 4
                    && item.Prescription.RxStatusId !== 2) ? " : Billed Rx" : "";
        }
        if (!rxStatus && (ispaidRecords && ispaidRecords.length)) {
            rxStatus += " : Have paid pay records";
        }
        // const  partialIndStatus = (item["PrescReFill"]["PartialIndStatus"] !== "C" ?  "-" +
        //         item["PrescReFill"]["PartialFillNo"] : "");
        return  ("<label>" +
        item["Prescription"]["PrescNum"] + "-" + item["PrescReFill"]["ReFillNum"] +
        (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"]) ? "-" + item["PrescReFill"]["PartialFillNo"] : " ") + " </label>" + rxStatus);
    }

    selectedRxsOtherThanUnbilled() {
        this.warningMsg = "Following Rx(s) cannot be Deferred for the following reasons:";
        this.NotUnbilledRxsListForDisplay.forEach(item => {
            this.warningMsg += "<br/>" + item;
        });
    }

    async checkLatestFill() {
        if (this.warningModelRef) {
            this.warningModelRef.close();
            this.warningModelRef = null;
        }
        const rxIdsList = [];
        const refilIdsList = [];
        this.UnbilledRxs.map(item => {
            rxIdsList.push(item["Prescription"]["Id"]);
            refilIdsList.push(item["PrescReFill"]["Id"]);
        });
        const params = {
            FileDueDate: null,
            DeferredReasonId: null,
            PrescRefillId: refilIdsList,
            PrescId: rxIdsList,
            FileDate: null,
            DeferredReasonName: null,
            DrugPickVerify: null,
            RphVerify: null,
            flag: false
        };
        const resp = await this._rxSvc.checkMultipleRxWithLatestFill(params).toPromise();
        if (resp && resp !== "") {
            this.getNotLatestFillsList(resp);
        } else {
            this.checkForDpvNRpvVerificationExistOrNot();
        }
    }

    getNotLatestFillsList(resp) {
        let latestUnbilledRxs = "";
        const responseData: string = resp.trim();
        let notLatestFills = responseData.split(",");
        let notLatestFillsListForDisplay = "";
        let newRxData = "";
        notLatestFills = notLatestFills.map(item => {
            notLatestFillsListForDisplay += "<br/><label>" + item.trim() + "</label>";
            return item.trim();
        });
        this.UnbilledRxs = this.UnbilledRxs.filter((item: any) => {
            const partialFillNo = (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"])  ?  "-" + item["PrescReFill"]["PartialFillNo"] : "");
           newRxData = (item["Prescription"]["PrescNum"] + "-" + item["PrescReFill"]["ReFillNum"] + (partialFillNo ? partialFillNo : ""));
            if (notLatestFills.indexOf(newRxData) === -1) {
                latestUnbilledRxs += "<br/><label>" + item["Prescription"]["PrescNum"] +
                "-" + item["PrescReFill"]["ReFillNum"] + 
                (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"]) ? "-" + item["PrescReFill"]["PartialFillNo"] : " ") + "</label>";
            }
            return (notLatestFills.indexOf(item["Prescription"]["PrescNum"] + "-"
            + item["PrescReFill"]["ReFillNum"] + (partialFillNo ? partialFillNo : "")) === -1);
        });
        this.popUpOpenedFor = this.UnbilledRxs && this.UnbilledRxs.length ? "FEWLATESTFILLS" : "NOLATESTFILLS";
        this.warningMsg = "Following Rx(s) are not latest fill(s) they cannot be Deferred:" + notLatestFillsListForDisplay;

        this.warningMsg += latestUnbilledRxs && latestUnbilledRxs.length ?
            "<br/>Following Rx(s) can be Deferred:" + latestUnbilledRxs.toString() + "<br/>Would you like to proceed and Defer?" :
                "<br/>Please select Rx(s) with latest fill.";
         this.warningModelRef = this._modalService.open(this.WARNFILEMULTIRX, {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"});
    }

    checkForDpvNRpvVerificationExistOrNot() {
        const DPHRPH = [];
        let rphdph = "";
        this.fileFG.patchValue({
            DrugPickVerify: [],
            RphVerify: []
        });
        this.UnbilledRxs.forEach(item => {
            let verfData = "";
            const data = {
                BottleNdc: item["Drug"]["drugcode"], PrescId: item["Prescription"]["Id"], IsForRxVerification: false,
                PrescNum: item["Prescription"]["PrescNum"], PrescRefillId: item["PrescReFill"]["Id"],
                RefillNum: item["PrescReFill"]["ReFillNum"], PartialfillNum: item["PrescReFill"]["PartialFillNo"],
                Result: "RV", UserName: item["PrescReFill"]["RegPharmacist"],
                VerifiedById: item["PrescReFill"]["UpdatedById"], Status: "RV"
            };
            // if (item["drugPickVerificationStatus"] &&
            //         (item["drugPickVerificationStatus"]).trim().toLowerCase() === "p") {
            //         verfData = verfData + "D";
            //         const prevData: any[] = this.fileFG.value["DrugPickVerify"];
            //         const d = JSON.parse(JSON.stringify(data));
            //         d["IsForRxVerification"] = false;
            //         prevData.push(JSON.parse(JSON.stringify(d)));
            //         this.fileFG.patchValue({
            //             DrugPickVerify: prevData
            //         });
            // }
            // if (item["rph2VerificationStatus"] &&
            //         (item["rph2VerificationStatus"]).trim().toLowerCase() === "v") {
            //         verfData = verfData + "R";
            //         const prevData: any[] = this.fileFG.value["RphVerify"];
            //         const d = JSON.parse(JSON.stringify(data));
            //         d["IsForRxVerification"] = false;
            //         prevData.push(JSON.parse(JSON.stringify(d)));
            //         this.fileFG.patchValue({
            //             RphVerify: prevData
            //         });
            // }
            if (item["rph1VerificationStatus"] &&
                    (item["rph1VerificationStatus"]).trim().toLowerCase() === "r") {
                    verfData = verfData + "X";
                    const prevData: any[] = this.fileFG.value["RphVerify"];
                    const d = JSON.parse(JSON.stringify(data));
                    d["IsForRxVerification"] = true;
                    prevData.push(JSON.parse(JSON.stringify(d)));
                    this.fileFG.patchValue({
                        RphVerify: prevData
                    });
            }
            if (verfData && verfData.length > 0) {
                // const  partialIndStatus = (item["PrescReFill"]["PartialIndStatus"] !== "C") ?
                //   "-" + item["PrescReFill"]["PartialFillNo"] : "";
                DPHRPH.push(item["Prescription"]["PrescNum"] + "-" + item["PrescReFill"]["ReFillNum"] +
                (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"]) ? "-" + item["PrescReFill"]["PartialFillNo"] : " "));
            }
        });
        DPHRPH.forEach(item => {
            rphdph += "<br/><label>" + item + "</label>";
        });
        if (this.fileFG.value["RphVerify"] && this.fileFG.value["RphVerify"].length) {
            this.warningMsg = DPHRPH.length ?
                ("Following Rx(s) have Rx Verification records:" + rphdph) : "";
            this.warningMsg += "<br/>Would you like to Re-Verify these records?";
            this.popUpOpenedFor = "DPVANDRPHVPOPUP";
            this.confirmModel = this._modalService.open(this.VERFCONFIRMATION, {centered: true, keyboard: false, backdrop: false, windowClass: "large--content" });
        } else {
            this.flagDpvNRpv(false);
        }
    }

    flagDpvNRpv(flag: boolean, event?: any) {
        if (this.confirmModel) {
            this.confirmModel.close();
        }
        this.confirmModel = null;
        this.fileFG.patchValue({
            dpvNRpvFlag: flag
        });
        if (event) {
            event.preventDefault();
        }
        this.confirmDEASubmission(true);
    }

    async confirmDEASubmission(assign = false) {
        if (assign) {
            this.DEAProcessingData = Object.assign([], this.UnbilledRxs) || []; // asign unbilled rx list.
        }
        if (this.DEAProcessingData && this.DEAProcessingData.length) {
            this.DEARxInfo = null;
            this.DEARxInfo = this.DEAProcessingData.shift();
            if (
                (
                    (this.DEARxInfo.Drug.drugclass > 1 && this.DEARxInfo.Drug.drugclass < 6) ||
                    (await this._recallSer.CheckIOCDrugorNot(this.DEARxInfo.Drug.ndc, this.DEARxInfo.Patient.state).toPromise())
                )
                && this.DEARxInfo.PrescReFill.IsControlSubmitted
            ) {
                this._modalService.open(this.ResubmitConfirm, {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"});
            } else {
                this.confirmDEASubmission();
            }
        } else {
            this.openFileDueDatepopup();
        }
    }

    async submitResubmission(isSubmit?: boolean, event?: any) {
        if (event) {
            event.preventDefault();
        }
        if (isSubmit) {
            const resp = await
                this._contrlSer.addPrescToControlResubList(
                    { prescRefillIds: [this.DEARxInfo.PrescReFill.Id], isforFiledRx: true }).toPromise();
            if (resp) {
                this.confirmDEASubmission();
            }
        } else {
            this.confirmDEASubmission();
        }
    }

    openFileDueDatepopup() {
        this.popUpOpenedFor = "FILEDUENDEFERRED";
        this.confirmModel = this._modalService.open(this.FILEDUEDATE, {centered: true, keyboard: false, backdrop: false });
        setTimeout(() => {
            if (document.getElementById("fileFGdueDateTime")) {
                document.getElementById("fileFGdueDateTime").focus();
            }
        }, 300);
    }

    async getDeferredReasonList(dfrdReasonFG) {
        const resp: any = await this._DfrdReasonServ.getDeferredReasonList(dfrdReasonFG).toPromise();
        if (resp && resp.length > 0) {
                this.deferredReasonList = resp;
                this.deferredReasonList.splice(0, 0, this.noneReason[0]);
        } else {
                this.deferredReasonList = this.noneReason;
        }
    }

    async saveOrCancelFile(type?: any) {
        await this._formUtils.blurAndDelay();
        if (this.fileFG.valid) {
            const rxIds = [];
            const refilIds = [];
            const rxbillIds = [];
            this.UnbilledRxs.map(item => {
                rxIds.push(item["Prescription"]["Id"]);
                refilIds.push(item["PrescReFill"]["Id"]);
                rxbillIds.push(item["RxBill"]["Id"]);
            });
            const DeferredReasonName = this.deferredReasonList.find(item => item["Id"] === this.fileFG.value["deferredReason"]) || null;
            const payload = {
                FileDueDate: this.fileFG.value["dueDateTime"],
                DeferredReasonId: this.fileFG.value["deferredReason"],
                PrescRefillId: refilIds,
                PrescId: rxIds,
                FileDate: moment(new Date()),
                DeferredReasonName: DeferredReasonName ? DeferredReasonName["Name"] : null,
                DrugPickVerify: this.fileFG.value["DrugPickVerify"],
                RphVerify: this.fileFG.value["RphVerify"],
                flag: this.fileFG.value["dpvNRpvFlag"],
                RxBillId: rxbillIds
            };
            const resp = await this._rxSvc.fileMultipleRxs(payload).toPromise();
            if (resp) {
                this._commUtil.updateDashbordStatus();
                this.closeFileRelatedModals(true, payload.DeferredReasonName);
                    if (this.fileFG.value["DrugPickVerify"] && this.fileFG.value["DrugPickVerify"].length) {
                        this.fileFG.value["DrugPickVerify"].map(item => {
                            this._auditUtils.getChangedValues(
                                null,
                                {"DP Verification Status": "Re-verify"},
                                "DP Verification",
                                "Rx",
                               item.PrescRefillId
                            );
                        });
                    } if (this.fileFG.value["RphVerify"] && this.fileFG.value["RphVerify"].length) {
                        this.fileFG.value["RphVerify"].map(item => {
                            this._auditUtils.getChangedValues(
                                null,
                                item["IsForRxVerification"] ? {"Rx Verification Status": "Re-verify"} : {"Rph Verification Status": "Re-verify"},
                                item["IsForRxVerification"] ? "Rx" : "Rph" + " Verification",
                                "Rx",
                                item.PrescRefillId
                            );
                        });
                    }
                this._alertSvc.success("Deferred successfully");
            } else {
                // Sentry.captureException([
                //     {name: resp},
                //     {name:  payload.DeferredReasonName}
                // ]);
                this.closeFileRelatedModals(false, payload.DeferredReasonName);
                this._alertSvc.error("Unable to File.");
            }
        }
    }

    closeFileRelatedModals(isSucess?: boolean, defReasonName?: string) {
        if (this.warningModelRef) {
            this.warningModelRef.close();
        }
        if (this.confirmModel) {
            this.confirmModel.close();
        }
        this.confirmModel = null;
        this.CloseFileDueDate.emit(isSucess ? {unbilledRxs: this.UnbilledRxs, fileFG: this.fileFG, defferedReasonName: defReasonName} : null);
    }
}
