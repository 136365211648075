import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PatientService, AlertService, CommonService, AdditionalInsuranceService, PatPreDrugModalService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { LinkFamily, FamilyAddress, FamilyInsuCard, FamilyInsuCarrier,
     FamilyInsuGroup, FamilyInsurance, FamilyPhone, PatFamily, ExistngFamily, State, City, AccCategoryE, PatientFileE, 
     PaginationModel} from "src/app/models";
import { Observable, Subject } from "rxjs";
import { FamilyUtil } from "src/app/utils";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { PatientStore } from "src/app/store";
import { PrivMaskGuard } from "src/app/guards";

@Component({
    selector: "app-family-info-ref",
    templateUrl: "./family-info-ref.component.html"
})
export class FamilyInfoRefComponent implements OnInit, OnChanges {
    paginationModel: PaginationModel = new PaginationModel();
    @Input() familyInfoFrom: any;
    @Input() patientId: number;
    patientInfo: any;
    accessPrivCate: any;
    patientPrivs: any;
    categoryId: any;
    privType: any;
    openOverride: boolean;
    overrideFor: any;
    overrideEndpoint: any;
    isDataExists = true;
    alreadyPrivCheck: boolean;
    @Input()
    set PatientInfo(fg: any) {
        if(fg){
            this.patientInfo = fg;
            this.formGroupValuesPatch();
        }
    }
    @Input() patFG: FormGroup;
    @Output() familyUpdated = new EventEmitter<any>();
    @Output() emitPatSelectId = new EventEmitter<any>();
    @ViewChild("importFamily", { static: true }) importFamilyPopUp: any;
    @ViewChild("FamilyFirst", { static: true }) FamilyFirst: any;
    @ViewChild("addOrEditPopUp", { static: true }) addOrEditPopUp: any;
    @ViewChild("FamilyExistPopUp", { static: true }) FamilyExistPopUp: any;
    patFamilyInfo: any;
    familyInfo: any;
    hasFamily: boolean;
    modelRef: any;
    searchFamilyFG: FormGroup;
    familyList: any;
    totalCount: any;
    activeHeader = [ "Actions",
    "Last Name",
    "AddressLine 1",
    "AddressLine 2",
    "City",
    "State",
    "ZipCode",
    "Telephone",
    "Primary Insurance",
    "Policy Number"];
    searchFamilyInfoWJ: CollectionView;
    editFrom: string;
    linkFG: FormGroup;
    linkedData: any;
    linkModal: any;
    linkFromList: boolean;
    formGroupInvalid: boolean;
    editFamilyInfo: any;
    familyInfoFG: FormGroup;
    insuranceData$: any;
    insuranceCode: any;
    existingMembers = [];
    editMode: boolean;
    citySelected = null;
    stateSelected = null;
    filteredGroups = [];
    familyHeader: string[];
    familyMembersWJ: CollectionView;
    selectedMembId: any;
    patmodelRef: any;
    addOrEditFromOut: any;
    modelRef2: any;
    modelRef3:any;
    addFamMemFrRx: boolean;
    patSelectFrAddFamily: boolean;
    memberSelected: { PatientId: any; LastName: any; FirstName: any; Phone: any; };
    resetAddFamilyData: boolean;
    policyRequired = false;
    unsubscribe$: Subject<void> = new Subject();
    isOnChanges = false;
    patientEditData: any;
    ht:any;
    spaceClick:boolean = false;
    patDetail:any;
    constructor(private _patService: PatientService, private _modalSvc: NgbModal,
        private _additionalInsu: AdditionalInsuranceService, private _familyUtil: FamilyUtil,
        private _patUtil: PatientInfoUtil, private _patPreDrugModalServ: PatPreDrugModalService,
        private _fb: FormBuilder, private _alertService: AlertService, private _commonServ: CommonService, private _patientStore: PatientStore, private _privMaskGuard :PrivMaskGuard) {
            this._patientStore.patientEditInfo$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.patientEditData = resp;
            });
            this.accessPrivCate = AccCategoryE;
            this.patientPrivs = PatientFileE;
         }

    ngOnInit() {
    }
    formGroupValuesPatch(){
        this.insuranceData$ = this._commonServ.insuranceData$;
        if (this.familyInfoFrom === "Patient") {
            this.patFamilyInfo = this.patientInfo["PatFamily"];
        } else if (!this.isOnChanges){
            this.getEditPatientInfo();
            this.isOnChanges = true;
        }
    }
        ngOnChanges(changes?: any) {
        if (changes && changes.patFG && changes.patFG.currentValue) {
            this.isOnChanges = true;
            this.getEditPatientInfo();
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

   async getEditPatientInfo() {
        // const resp = await this._commonServ.getEditPatientInformation(this.patientId).toPromise();
        if (this.patientEditData) {
            this.patientInfo = this.patientEditData;
            this.patFamilyInfo = this.patientInfo["PatFamily"];
        }
    }

    async getFamilyDetails() {
        if (this.patientInfo && this.patientInfo.PatFamily && this.patientInfo.PatFamily["Id"]) {
            this.familyInfo = await this._patService.getPatientPatDetails(this.patientInfo.PatFamily["Id"]).toPromise();
            if (this.familyInfo) {
                this.hasFamily = true;
            } else {
                this.hasFamily = false;
            }
        } else {
            this.hasFamily = false;
        }
        this.existingMembers = [];
        this.modelRef3 = this._modalSvc.open(this.FamilyFirst, {
            centered: true, backdrop: false, keyboard: false, windowClass: "large--content"
        });
    }

    openFamilyListModal(content) {
        this.createFamilyListFG();
        this.searchFamilyFG.controls["Name"].patchValue(
            this.patientInfo && this.patientInfo["Person"] && this.patientInfo["Person"]["LastName"]
                ? this.patientInfo["Person"]["LastName"].trim()
                : null
        );
        this.searchFamilyFG.controls["ZipCode"].patchValue(
            this.patientInfo && this.patientInfo["Addresses"] && this.patientInfo["Addresses"]["ZipCode"]
                ? this.patientInfo["Addresses"]["ZipCode"].trim()
                : null
        );
        this.getFamilies();
        setTimeout(() => {
            this.modelRef2 = this._modalSvc.open(content, { size: "lg", backdrop: false, keyboard: false, centered: true });
        }, 800);
    }

    async getFamilies() {
        if (!this.searchFamilyFG) {
            this.createFamilyListFG();
        }
        this.familyList = await this._patService.getFamilyInfo(this.searchFamilyFG.value).toPromise();
        if (this.familyList && this.familyList["length"] > 0) {
            this.totalCount = this.familyList[0].TotalCount;
            this.familyInofWJ(this.familyList);
            this.isDataExists = true;
        } else {
            this.totalCount = 0;
            this.isDataExists = false;
            this.searchFamilyInfoWJ = new CollectionView(null)
        }
    }

    // To Open Add Family Popup
    async openPopUpModalAdd(type?: any) {
        this.editMode = false;
        this.addOrEditFromOut = type ? true : false;
        this.formGroupInvalid = false;
        if (type && type === "StartNew") {
            const selectedPat = {
                PatientId: this.patientInfo["Patient"]["Id"],
                LastName: this.patientInfo["Person"]["LastName"],
                FirstName: this.patientInfo["Person"]["FirstName"],
                Phone: (this.patientInfo["Phones"] && this.patientInfo["Phones"][0])
                    ? this.patientInfo["Phones"][0]["Id"] : null
            };
            this.existingMembers = [];
            this.existingMembers.push(selectedPat);
            this.generateFamilyMemberWJ(this.existingMembers);
            await this.patchInfoForFamilyAdd();
        }
        this.modelRef = this._modalSvc.open(this.addOrEditPopUp, { size: "lg", windowClass: "modal-center-to-page", backdrop: false, keyboard: false});
    }

    // Open Edit Family Popup
    async openEditPopUpModal(item, type?: any) {
        this.addOrEditFromOut = type ? true : false;
        this.formGroupInvalid = false;
        if (this.familyInfo && this.familyInfo["PatFamily"] && this.familyInfo["PatFamily"][0] &&
         this.familyInfo["PatFamily"][0]["Id"] === item["Id"]) {
            this.editFamilyInfo = this.familyInfo;
        } else if (item && item["Id"]){
            this.editFamilyInfo = await this._patService.getPatientPatDetails(item["Id"]).toPromise();
        }
        this.patchEditFamilyValues();
    }

    // Patch Edit Family details
    patchEditFamilyValues() {
        this.createFamilyFG();
        this.editMode = true;
        Object.keys(this.editFamilyInfo).map(control => {
            if (this.editFamilyInfo[control] && this.editFamilyInfo[control][0] &&
                ["ExistngFamilyMembers"].indexOf(control) === -1) {
                this.familyInfoFG.controls[control].patchValue(this.editFamilyInfo[control][0]);
                if (control === "FamilyInsurance") {
                    const insuranceList = this.insuranceData$ && this.insuranceData$["source"]["value"] ?
                     Object.assign([], this.insuranceData$["source"]["value"]) : [];
                    const insurance = (insuranceList && insuranceList.length > 0) ? insuranceList.filter(
                        val => val.InsuCarrierId === this.editFamilyInfo[control][0]["InsurerId"]
                    ) : [];
                    this.insuranceCode = (insurance && insurance.length > 0) ? insurance[0].PlanCode : null;
                    this.filterGroupsBasedOnInsurerId(this.editFamilyInfo[control][0]["InsurerId"]);
                }
            }
        });
        this.existingMembers = Object.assign([], this.editFamilyInfo["ExistngFamilyMembers"]);
        // if (this.addOrEditFromOut) {
        //     if (this.existingMembers && this.existingMembers.length > 0) {
        //         const index = this.existingMembers.findIndex(val => val.PatientId === this.patientInfo["Patient"]["Id"]);
        //         if (index > -1) {
        //             this.existingMembers.splice(index, 1);
        //         }
        //         this.generateFamilyMemberWJ(this.existingMembers);
        //     }
        // }
        this.generateFamilyMemberWJ(this.existingMembers);
        this.setStateCityValues(this.editFamilyInfo.FamilyAddress);
        this.modelRef = this._modalSvc.open(this.addOrEditPopUp, { size: "lg", windowClass: "modal-center-to-page", backdrop: false, keyboard: false });
    }

    // Patch default values while Add Family
    patchInfoForFamilyAdd(data?: any) {
        this.createFamilyFG();
        this.stateSelected = new State();
        this.citySelected = new City();
        const patchInfoData = data ? data : this.patientInfo;
        this.patchCityAndState(patchInfoData["Addresses"]);
        if (patchInfoData["PatFamily"] && (patchInfoData["PatFamily"]["Id"] || patchInfoData["PatFamily"]["Name"])) {
            this.familyInfoFG.controls["PatFamily"].patchValue(patchInfoData["PatFamily"]);
        } else if (patchInfoData["Person"] && patchInfoData["Person"]["LastName"]) {
            this.familyInfoFG.controls["PatFamily"].patchValue({Name: patchInfoData["Person"]["LastName"]});
        }
        const controlNames = [
            { patCntrl: "Insurances", familyCntrl: "FamilyInsurance" },
            { patCntrl: "InsuCards", familyCntrl: "FamilyInsuCard" },
            { patCntrl: "InsuCarrier", familyCntrl: "FamilyInsuCarrier" },
            { patCntrl: "InsuGroups", familyCntrl: "FamilyInsuGrp" },
            { patCntrl: "Addresses", familyCntrl: "FamilyAddress" }
        ];
        controlNames.map(cntrlName => {
            if (patchInfoData[cntrlName["patCntrl"]]) {
                if (cntrlName["patCntrl"] === "Insurances") {
                    const insuranceList = this.insuranceData$ && this.insuranceData$["source"]["value"] ?
                     Object.assign([], this.insuranceData$["source"]["value"]) : [];
                    const insurance = insuranceList.filter(
                        val => val.InsuCarrierId === patchInfoData.Insurances.InsurerId);
                        if (insurance) {
                            this.insuranceCode = insurance[0].PlanCode;
                            this.filterGroupsBasedOnInsurerId(patchInfoData.Insurances.InsurerId);
                        }
                }
                const fg: any = this.familyInfoFG.controls[cntrlName["familyCntrl"]];
                this.familyInfoFG.controls[cntrlName["familyCntrl"]].patchValue(patchInfoData[cntrlName["patCntrl"]]);
                fg.controls["Id"].patchValue(null);
            }
        });
    }

    // Patient Family Save Or Update
    async addOrEditFamily() {
        this._familyUtil.setDefaultValues(this.familyInfoFG);
        const familyFGValue = this.familyInfoFG.value;
        if (!this.familyInfoFG.valid) {
            this.formGroupInvalid = true;
            this._alertService.error("Enter Required Fields.");
        } else {
            this.formGroupInvalid = false;
            const familyDataModel = {
                PatFamily: [], FamilyAddress: [], FamilyPhone: [], FamilyInsuCard: [],
                FamilyInsurance: [], FamilyInsuCarrier: [], FamilyInsuGrp: [], ExistngFamilyMembers: [], IsFamilyNameUpdated: false
            };
            Object.keys(familyFGValue).map(cntrlName => {
                if (cntrlName !== "ExistngFamilyMembers") {
                    familyDataModel[cntrlName].push(familyFGValue[cntrlName]);
                }
            });
            familyDataModel.ExistngFamilyMembers = Object.assign([], this.existingMembers);
            let addOrUpdateResult = null;
            if (this.editMode) {
                addOrUpdateResult = await this._patService.updateFamily(familyDataModel).toPromise();
            } else {
                addOrUpdateResult = await this._patService.addFamilyInfo(familyDataModel).toPromise();
            }
            if (addOrUpdateResult) {
                if (this.modelRef) {
                    this.modelRef.close();
                    this.modelRef = null;
                }
                this._alertService.success(this.editMode ? "Family info updated successfully." : "Family info added successfully.");
                if (!this.editMode) {
                    this.familyInfoFG.controls["PatFamily"].patchValue({Id: addOrUpdateResult});
                    familyFGValue.PatFamily["Id"] = addOrUpdateResult;
                    await this._familyUtil.addMultipleMembers(familyDataModel.ExistngFamilyMembers, addOrUpdateResult);
                }
                if (this.addOrEditFromOut) {
                    this.patFamilyInfo = familyFGValue.PatFamily;
                    this.patientInfo["PatFamily"] = familyFGValue.PatFamily;
                }
                if (this.existingMembers.length > 0) {
                    this.linkInfo(familyFGValue, this.importFamilyPopUp);
                } else {
                    this.changeFamilyInfo();
                }
            }
        }
    }

    // After Add, Update, Link, OnCancel Link

    async changeFamilyInfo() {
        let patDet = this.patientInfo;
        if (this.addOrEditFromOut || (this.patientInfo && this.patientInfo["Patient"])) {
            patDet = await this._patService.getEditPatientInformation(this.patientInfo["Patient"]["Id"]).toPromise();
            if (patDet) {
                this.patFamilyInfo = patDet["PatFamily"];
                this.patientInfo["PatFamily"] = patDet["PatFamily"];
            }
        }
        if (!this.addOrEditFromOut) {
            if (!this.searchFamilyFG) {
                this.createFamilyListFG();
            }
            this.getFamilies();
        }
        return patDet;
    }

    // Patching Data For Import Modal
    linkInfo(data, content, datatype?: any) {
        this.linkedData = data;
        if (datatype) {
            this.addOrEditFromOut = true;
        }
        this.linkFromList = datatype ? true : false;
        this.linkFG = this._fb.group(new LinkFamily());
        this.linkModal = this._modalSvc.open(content, { backdrop: false, keyboard: false, centered: true });
    }

    // Import Family Save
    async importFamilyProfile() {
        const MembersSelected = {
            PatientId: this.linkFromList ? this.patientInfo["Patient"]["Id"] : this.existingMembers[0]["PatientId"],
            FamilyId: this.linkFromList ? this.linkedData["Id"] : this.linkedData["PatFamily"]["Id"],
            IsOverrideGeneralDetails: this.linkFG.value["IsOverrideGeneralDetails"],
            IsOverrideInsuranceDetails: this.linkFG.value["IsOverrideInsuranceDetails"],
            FamilyLastName: this.linkFG.value["IsLastNameUpdate"] ?
                this.linkFromList ? this.linkedData["Name"] : this.linkedData["PatFamily"]["Name"] : null
        };
        const linkResult = await this._patService.linkFamilyInfo([MembersSelected]).toPromise();
        const patDet = await this.closeImportPopup();
        if (this.addFamMemFrRx) {
            this.addFamMemFrRx = false;
            this.patientId = this.patientInfo["Patient"]["Id"];
            this.emitPatSelectId.emit(this.patientInfo["Patient"]["Id"]);
        } else if (this.linkFromList || (this.existingMembers && this.existingMembers.length > 0 &&
             this.patientInfo && this.patientInfo["Patient"] &&
            this.existingMembers.find(val => val["PatientId"] === this.patientInfo["Patient"]["Id"]))) {
            this.familyUpdated.emit({
                result: patDet ? patDet : this.patientInfo, overRideName: this.linkFG.value["IsLastNameUpdate"],
                overRideGeneral: this.linkFG.value["IsOverrideGeneralDetails"],
                overRideInsu: this.linkFG.value["IsOverrideInsuranceDetails"]
            });
        }
    }

    async closeImportPopup() {
        this.emitPatSelectId.emit(this.patDetail  ? this.patDetail["PatientId"] : this.patientInfo["Patient"]["Id"]);
        let patInfo = this.patientInfo;
        if (this.linkModal) {
            this.linkModal.close();
        }
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.modelRef2 && this.addOrEditFromOut) {
            this.modelRef2.close();
        }
        patInfo = await this.changeFamilyInfo();
        return patInfo;
    }

    // To Open Patient SearchPopUp
    openPatientSearch(content, type?: any, content2?: any) {
        this.patSelectFrAddFamily = type && type !== "resetFamilyData" ? true : false;
        this.resetAddFamilyData = type && type === "resetFamilyData" ? true : false;
        if (type && type !== "resetFamilyData") {
            this.existingMembers = [];
            this.addOrEditFromOut = false;
        }
        this.patmodelRef = this._modalSvc.open(content, {
            centered: true, backdrop: false, keyboard: false, windowClass: "medium-modal", size: "sm"
        });
    }
    // If Patient Not Found
    addPatientFromFamily(type) {
        if (!this.checkHasPrivsToAccess(this.accessPrivCate.PatientFile, this.patientPrivs.Add) && !this.alreadyPrivCheck) {
            this.alreadyPrivCheck = true;
            this.openOveride('PatientFile', 'Add', 'POST_Patient');
        } else {
            this._patPreDrugModalServ._patPreDrugModal$.next("patient");
            const medalRef = this._patPreDrugModalServ.modalInstanceRef;
            const modalRefPAt = this._patPreDrugModalServ.modalInstanceRef.componentInstance;
            const FG = this._patUtil.generatePatientInfoFG();
            this._patUtil.patchPatAddDefaultVal(FG);
            this._patUtil.patchPatDataFrmFamily(this.patientInfo, FG);
            modalRefPAt.patientID = null;
            modalRefPAt.addPatientModel = FG;
            modalRefPAt.FrmFamily = true;
            modalRefPAt.ClosePatientModal
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async res => {
                medalRef.close();
                if (this.patmodelRef) {
                    this.patmodelRef.close();
                    this.patmodelRef = null;
                }
                if (res["PatientId"]) {
                    this.patDetail = res;
                    if (type === "FamilyMemAddPat") {
                        this.existingMembers = [];
                        if (this.patientInfo && this.patientInfo["PatFamily"] && this.patientInfo["PatFamily"]["Id"]) {
                            this._familyUtil.addMultipleMembers([{ PatientId: res["PatientId"] }], this.patientInfo["PatFamily"]["Id"]);
                        }
                        this.patientInfo = await this._commonServ.getEditPatientInformation(res["PatientId"]).toPromise();
                        // this.emitPatSelectId.emit(res["PatientId"]);
                        this.getFamilyDetails();
                    } else {
                        const ePatDetails = await this._commonServ.getRxPatientDetailsById(res["PatientId"]).toPromise();
                        this.patientSelected(ePatDetails);
                    }
                }
    
            }
    
            );
        }
    }
    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }
    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.addPatientFromFamily("FamilyMemAddPat");
        }
    }

    openOveride(category, type, endpoint?) {
        this.categoryId = category;
        this.privType = type;
        this.openOverride = true;
        this.overrideFor = category;
        this.overrideEndpoint = endpoint;
    }
    // After member selected
    async patientSelected(value) {
        if (value && value["patientid"]) {
            let hasFamily = false;
            if (this.existingMembers && this.existingMembers.length > 0) {
                hasFamily = this.existingMembers.find(val => val.PatientId === value["patientid"]);
            }
            if (value["patientid"] === this.patientInfo["Patient"]["Id"] && this.addOrEditFromOut) {
                hasFamily = true;
            }
            if (hasFamily) {
                this._alertService.warning("Patient is already linked to this family. Please select another", false, "long");
            } else {
                if (this.patmodelRef) {
                    this.patmodelRef.close();
                    this.patmodelRef = null;
                }
                const familyId: any = this.familyInfoFG && this.familyInfoFG.value["PatFamily"];
                const resp = await this._patService.checkPatientHasFamily(value["patientid"],
                familyId && familyId["Id"] ? familyId["Id"] : 0).toPromise();
                this.memberSelected = {
                    PatientId: value["patientid"], LastName: value["lastname"], FirstName: value["firstname"], Phone: value["telephone"]
                };
                if (!resp || resp === 0) {
                    this.existingMembers.push(this.memberSelected);
                    this.generateFamilyMemberWJ(this.existingMembers);
                    if (this.patSelectFrAddFamily) {
                        this.patSelectFrAddFamily = false;
                        const patInfoFrFamilyAdd = await this._commonServ.getEditPatientInformation(value["patientid"]).toPromise();
                        this.patchInfoForFamilyAdd(patInfoFrFamilyAdd);
                        this.openPopUpModalAdd();
                    } else if (this.resetAddFamilyData) {
                        this.resetAddFamilyData = false;
                        const patInfoFrFamilyAdd = await this._commonServ.getEditPatientInformation(value["patientid"]).toPromise();
                        this.patchInfoForFamilyAdd(patInfoFrFamilyAdd);
                    } else if (this.editMode) {
                        this.addMember();
                    }
                } else {
                    this._modalSvc.open(this.FamilyExistPopUp, { centered: true, backdrop: false, keyboard: false,
                         windowClass: "large--content" });
                }
            }
        }
    }

    // Delete Family profile
    async deleteFamilyInfo(data) {
        const familyId = data["FamilyId"];
        const resp = await this._patService.deleteFamily(familyId).toPromise();
        if (resp) {
            this._alertService.success("Patient family info deleted successfully.");
            this.getFamilies();
        }
    }

    createFamilyFG() {
        this.familyInfoFG = this._fb.group({
            FamilyAddress: this._fb.group(new FamilyAddress()),
            FamilyInsuCard: this._fb.group(new FamilyInsuCard()),
            FamilyInsuCarrier: this._fb.group(new FamilyInsuCarrier()),
            FamilyInsuGrp: this._fb.group(new FamilyInsuGroup()),
            FamilyInsurance: this._fb.group(new FamilyInsurance()),
            FamilyPhone: this._fb.group(new FamilyPhone()),
            PatFamily: this._fb.group(new PatFamily()),
            ExistngFamilyMembers: this._fb.array([new ExistngFamily()])
        });
    }

    createFamilyListFG() {
        this.searchFamilyFG = this._fb.group({
            gridParam: this._fb.group({
                SearchText: null,
                SearchBy: null,
                OrderByColumn: null,
                SortDirection: null,
                PageNumber: this.paginationModel.pageNumber,
                PageSize: this.paginationModel.pageSize
            }),
            Name: null,
            ZipCode: null,
            Insurance: null
        });
    }

    async filterGroupsBasedOnInsurerId(insuCarrierId) {
        const resp = await this._additionalInsu.getInsuGroups(insuCarrierId).toPromise();
        if (resp && resp["length"] !== 0) {
            this.filteredGroups = resp;
        }
    }

    setStateCityValues(data) {
        this.stateSelected = new State();
        this.citySelected = new City();
        if (data && data[0]) {
            this.citySelected.Id = data["0"]["CityId"];
            this.citySelected.StateId = data["0"]["StateId"];
            this.stateSelected.Id = data["0"]["StateId"];
            this.citySelected.Name = data["0"]["CityName"];
            this.stateSelected.Name = data["0"]["StateName"];
        }
    }

    generateFamilyMemberWJ(data) {
        if (data && data.length > 0) {
            this.selectedMembId = data[0]["PatientId"];
            this.familyMembersWJ = new CollectionView(
                data.map((family, i) => {
                    const j = {};
                    j["rowid"] = i;
                    j["Last Name"] = family["LastName"];
                    j["First Name"] = family["FirstName"];
                    j["Phone"] = family["Phone"];
                    j["Pat#"] = family["PatientId"];
                    return j;
                })
            );
            this.familyHeader = [
                "Select",
                "Last Name",
                "First Name",
                "Phone"
            ];
        }
    }
    // Delete Members
    async deleteExistingMembers(Id) {
        this.patFamilyInfo = this.patientInfo["PatFamily"];
        if (this.editMode) {
            const resp = await this._patService.deleteFamilyMember(Id, this.patFamilyInfo["Id"]).toPromise();
            if (resp) {
                const index = this.existingMembers.findIndex(val => val.PatientId === Id);
                this.existingMembers.splice(index, 1);
                this.generateFamilyMemberWJ(this.existingMembers);
            }
        } else {
            const index = this.existingMembers.findIndex(val => val.PatientId === Id);
            this.existingMembers.splice(index, 1);
            this.generateFamilyMemberWJ(this.existingMembers);
        }
    }

    // Add Individual members
    async addMember(type?: any) {
        if (this.editMode) {
            const familyId: any = this.familyInfoFG.controls["PatFamily"];
            const MembersSelected = {
                PatientId: this.memberSelected["PatientId"],
                FamilyId: familyId.value["Id"],
                IsOverrideGeneralDetails: false,
                IsOverrideInsuranceDetails: false,
                FamilyLastName: null
            };
            this._patService
                .linkFamilyInfo([MembersSelected])
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        if (type && type === "Update") {
                            this.existingMembers.push(this.memberSelected);
                            this.generateFamilyMemberWJ(this.existingMembers);
                            this.memberSelected = null;
                        }
                    }
                });
        } else {
            this.existingMembers.push(this.memberSelected);
            this.generateFamilyMemberWJ(this.existingMembers);
            if (this.patSelectFrAddFamily) {
                this.patSelectFrAddFamily = false;
                const patInfoFrFamilyAdd = await this._commonServ.getEditPatientInformation(this.memberSelected["PatientId"]).toPromise();
                this.patchInfoForFamilyAdd(patInfoFrFamilyAdd);
                this.openPopUpModalAdd();
            }  else if (this.resetAddFamilyData) {
                this.resetAddFamilyData = false;
                const patInfoFrFamilyAdd = await this._commonServ.getEditPatientInformation(this.memberSelected["PatientId"]).toPromise();
                this.patchInfoForFamilyAdd(patInfoFrFamilyAdd);
            }
            this.memberSelected = null;
        }
    }

    selectFamilyMember(isChecked: boolean, checkedVal: any) {
        if (isChecked && checkedVal && checkedVal["Pat#"]) {
            this.selectedMembId = checkedVal["Pat#"];
        }
    }

    // selectRadioOnKeyEvents(grid) {
    //     this.selectFamilyMember(true, grid.selectedItems[0]);
    // }

    init1(flex: wjcGrid.FlexGrid) {
        flex.focus();
    }

    keyeventOnFamilyWijimo(event) {
        if (event.ctrlKey) {
            if (event.which === 67) {
                event.preventDefault();
                if (this.modelRef) {
                    this.modelRef.close();
                    this.modelRef = null;
                }
            }
        }
    }

    // selected patient for rx
    async selectedPatFrFill(Id) {
        this.emitPatSelectId.emit( this.patDetail ? this.patDetail["PatientId"]:"");
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.patientInfo = await this._commonServ.getEditPatientInformation(Id).toPromise();
        this.emitPatSelectId.emit(Id);
    }

    familyInofWJ(familySearch: any) {
        if (this.totalCount !== 0) {
            this.totalCount = familySearch[0].TotalCount;
            this.searchFamilyInfoWJ = new CollectionView(
                familySearch.map((family, i) => {
                    const j = {};
                    j["data"] = family;
                    j["FamilyId"] = family.Id;
                    j["Last Name"] = family.Name;
                    j["AddressLine 1"] = family.Address1;
                    j["AddressLine 2"] = family.Address2;
                    j["City"] = family.City;
                    j["State"] = family.State;
                    j["ZipCode"] = family.ZipCode;
                    j["Telephone"] = family.xPhone;
                    j["Primary Insurance"] = family.PrimaryInsurance;
                    j["Policy Number"] = family.PolicyNum;
                    return j;
                })
            );
        }
    }

    // Link Data On Key Press
    linkdataOnKeyPress(grid, content, event) {
        if (event && event.keyCode) {
            event.preventDefault();
            this.spaceClick = true;
        }else {
            this.spaceClick = false;
            this.ht = grid.hitTest(event);
        }
        if ((grid.selectedItems[0] && grid.selectedItems[0].data && content && this.spaceClick)||(grid.selectedItems[0] && grid.selectedItems[0].data && content && this.ht.cellType === 1)) {
            this.linkInfo(grid.selectedItems[0].data, content, true);
        }
    }

    getFilteredData() {
        this.createFamilyListFG();
        this.getFamilies();
    }

    setPage(page: any) {
        this.searchFamilyFG.controls["gridParam"].patchValue({ PageNumber: page });
        this.getFamilies();
    }

    setSize(size: number) {
        this.searchFamilyFG.controls["gridParam"].patchValue({PageNumber: this.paginationModel.pageNumber, PageSize: size});
        this.getFamilies();
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.focus();
    }

    searchInsurnace = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => (term.length < 1 ? [] : this.filterByPcnBin(term)))
        )

    filterByPcnBin(term) {
        const searchTerms = term.split(",");
        const insuranceList = this.insuranceData$ && this.insuranceData$["source"]["value"] ?
                     Object.assign([], this.insuranceData$["source"]["value"]) : [];
        if (insuranceList && insuranceList.length > 0) {
            ["e1comm", "..."].map(codes => {
                const index = insuranceList.findIndex(val => (val["InsurerCode"]).toLowerCase() === codes);
                if (index > -1) {
                    insuranceList.splice(index, 1);
                }
            });
        }
        let filteredData = insuranceList.filter(v => {
            let valExists = false;
            ["PlanCode", "InsuranceName", "BinNum"].forEach(function (key) {
                const val = v[key] ? v[key] : "";
                valExists =
                    valExists ||
                    val
                        .toString()
                        .toLowerCase()
                        .startsWith(searchTerms[0].toLocaleLowerCase());
            });
            return valExists;
        });
        if (searchTerms[1]) {
            filteredData = filteredData.filter(v => {
                let valExists = false;
                const val = v["ProcessorNum"] ? v["ProcessorNum"] : "";
                valExists =
                    valExists ||
                    val
                        .toString()
                        .toLowerCase()
                        .startsWith(searchTerms[1].toLocaleLowerCase());
                return valExists;
            });
        }
        return filteredData.splice(0, 20);
    }

    formatter = (x: any) => {
        return x.InsurerCode;
    }

    async selectedInsType(val) {
        this.insuranceCode = val.item.InsurerCode;
        this.familyInfoFG.controls["FamilyInsurance"].patchValue({InsurerId: val.item.InsuCarrierId, InsuGrpId: null});
        this.familyInfoFG.controls["FamilyInsuCard"].patchValue({PolicyNum: null});
        this.familyInfoFG.controls["FamilyInsuGrp"].patchValue({Name: null});
        await this.checkIsPolicyRequired(val);
        await this.filterGroupsBasedOnInsurerId(val.item.InsuCarrierId);
    }

    checkIsPolicyRequired(val) {
        const insuCardsFG: any = this.familyInfoFG.controls["FamilyInsuCard"];
        if (insuCardsFG.value["PolicyNum"] === "" || insuCardsFG.value["PolicyNum"] === " ") {
            insuCardsFG.controls["PolicyNum"].setValue(null);
        }
        if (val.SubmissionType === "9" || !val.SubmissionType) {
            this.policyRequired = true;
            insuCardsFG.controls["PolicyNum"].setValidators([Validators.required]);
            insuCardsFG.controls["PolicyNum"].markAsUntouched();
        } else {
            this.policyRequired = false;
            insuCardsFG.controls["PolicyNum"].setValidators([Validators.nullValidator]);
            insuCardsFG.controls["PolicyNum"].setErrors(null);
            setTimeout(() => {
                insuCardsFG.controls["PolicyNum"].markAsUntouched();
            }, 200);
        }
    }

    onStateOrCityOrZipChange(data, type) {
        if (type === "Zip") {
          this.patchCityAndState(data);
        } else if (type === "State") {
            this.citySelected = this._familyUtil.onStateSelected(data, this.familyInfoFG);
        } else {
            this._familyUtil.onCitySelected(data, this.familyInfoFG);
        }
    }

    patchCityAndState(data) {
        if (data) {
            const result = this._familyUtil.patchCityAndState(data, this.familyInfoFG);
            this.stateSelected = result["stateSelected"];
            this.citySelected = result["citySelected"];
        }
    }

    isTypedValue(Value) {
        const fg: any = this.familyInfoFG.controls["FamilyInsuGrp"];
        if (Value && Value.value && Value.value !== "") {
            fg.controls["Name"].patchValue(Value.value["Name"]);
            fg.controls["InsurerId"].patchValue(this.familyInfoFG.value.FamilyInsurance.InsurerId);
            fg.controls["IsActive"].patchValue(true);
            fg.controls["Id"].patchValue(Value.value["Id"]);
        } else {
            const fgg: any = this.familyInfoFG.controls["FamilyInsurance"];
            fgg.controls["InsuGrpId"].patchValue(null);
            fg.controls["Name"].patchValue(null);
            fg.controls["InsurerId"].patchValue(null);
            fg.controls["IsActive"].patchValue(false);
        }
    }

    focusOutFromGroup() {
        if (document.getElementById("phoneGroup")) {
            document.getElementById("phoneGroup").focus();
        }
    }
    closeModal(type) {
        this.emitPatSelectId.emit(this.patDetail  ? this.patDetail["PatientId"]: "");
        if(type === 'modelRef3' && this.modelRef3){
                this.modelRef3.close();
                this.modelRef3 = null;
        }  else if(type==='modelRef2' && this.modelRef2){
            this.modelRef2.close();
            this.modelRef2 = null;
        } else if(type === 'patmodelRef' && this.patmodelRef){
            this.patmodelRef.close();
            this.patmodelRef = null;
        }


    }
}
