import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { AppComponent } from ".";
import { MainRoutesModule } from "./app.routes.module";
import { AppTranslateRootModule } from "./app.translate.root.module";
import {
    AlertComponent,
    HelpComponent,
    LandingComponent,
    LoaderComponent,
    LoginComponent,
    PageNotFoundComponent
} from "./components";
import { SharedModule } from "./modules/shared/shared.module";
import { BaseHttpInterceptor } from "./services";
// import Wijmo modules
import { WjChartModule } from "@grapecity/wijmo.angular2.chart";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { WjGridSearchModule } from '@grapecity/wijmo.angular2.grid.search';
// apply Wijmo license key
// import { setLicenseKey } from "@grapecity/wijmo";
// import * as wjcCore from "@grapecity/wijmo";
// wjcCore.setLicenseKey("W521700DR439691066C5KKUD");
import { TitleCasePipe } from "@angular/common";
import { ColumnPickerRfComponent } from "./components/column-picker-rf/column-picker-rf.component";
import { ConfirmNavigateComponent } from "./components/confirm-navigate/confirm-navigate.component";
import { QzTrayStepsComponent } from "./components/qz-tray-steps/qz-tray-steps.component";
import { SsoErrorsComponent } from './components/sso-errors/sso-errors.component';
import { SsoRedirectionComponent } from './components/sso-redirection/sso-redirection.component';
import { UserGuidePopupComponent } from "./components/user-guide-popup/user-guide-popup.component";
import { DisplayNotificationsComponent } from "./modules/commonn/header/display-notifications/display-notifications.component";
import { CustFormElemnsModule } from "./modules/cust-form-elemns/cust-form-elemns.module";
import { DrugPickVerificationModule } from "./modules/drug-pick-verification/drug-pick-verification.module";
import { InventoryTransAuditComponent } from "./modules/drug/drug-inventory/inventory-trans-audit/inventory-trans-audit.component";
import { RphVerificationModule } from "./modules/rph-verification/rph-verification.module";
import { AutomaticSubmissionCntrlFileComponent } from "./modules/schedule-task/automatic-submission-cntrl-file/automatic-submission-cntrl-file.component";
import { DefaultReportPrinterComponent } from "./modules/shared/default-report-printer/default-report-printer.component";
import { GlobalErrorHandler } from "./services/global-error-handler.service";
// import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";

@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent,
        AlertComponent,
        PageNotFoundComponent,
        LoginComponent,
        LandingComponent,
        ConfirmNavigateComponent,
        ColumnPickerRfComponent,
        QzTrayStepsComponent,
        HelpComponent,
        DefaultReportPrinterComponent,
        SsoErrorsComponent,
        DisplayNotificationsComponent,
        UserGuidePopupComponent,
        SsoRedirectionComponent

    ],
    imports: [
        BrowserModule,
        WjGridModule,
        WjChartModule,
        MainRoutesModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        AppTranslateRootModule,
        NgxImageZoomModule,
        SharedModule ,
        CustFormElemnsModule,
        WjGridSearchModule,
        DrugPickVerificationModule,
        RphVerificationModule
        // NgIdleKeepaliveModule.forRoot()
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseHttpInterceptor,
            multi: true
        },
        TitleCasePipe, BaseHttpInterceptor
        // SentryErrorHandler
    ],
    schemas:  [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
      ],
    bootstrap: [AppComponent],
    entryComponents: [ConfirmNavigateComponent, QzTrayStepsComponent, DefaultReportPrinterComponent, ColumnPickerRfComponent,AutomaticSubmissionCntrlFileComponent,DisplayNotificationsComponent,InventoryTransAuditComponent]
})
export class AppModule {}
