<ng-template #eRxTRxIn let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePopup()" >
        <h4 header>
            Rx Transfe In
        </h4>
        <div body class="eprx-transfer-req patient-edit--transfer-in">
            <!--Steppers-->
            <div class="row" >
                <div class="col-12 row m-0">
                    <div class="col-5 row m-0" style="padding-top: 0px !important;">
                        <div class="col-12 text-center">
                            <div> <i  [ngClass]="eRxModelFG.value['patientID'] ? 'fas fa-check-circle' : 'fa-kit fa-circle-1-solid'"></i> Select Patient</div>
                            <div class="stepper1"
                            [ngClass]=" eRxModelFG.value['patientID'] ? 'eprx-eRx_transferReq-border-select' : 'eprx-eRx_transferReq-border-unselect'">
                        </div>
                        </div>
                    </div>
                    <div class="col-2 text-center row pl-0" style="padding-top: 0px !important;">
                        <div class="col-12 tex-center">
                            <div>  <i  [ngClass]="(eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn']) ? 'fas fa-check-circle' : 'fa-kit fa-circle-2-solid'"></i> Select Transfer Type</div>
                            <div class="stepper2"
                            [ngClass]=" (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn']) ? 'eprx-eRx_transferReq-border-select' : 'eprx-eRx_transferReq-border-unselect'">
                        </div>
                        </div>
                    </div>
                    <div class="col-5 text-center row" style="padding-top: 0px !important;">
                        <div class="col-12 tex-center">
                            <div> <i  [ngClass]="eRxModelFG.value['sourceNPI'] ? 'fas fa-check-circle' : 'fa-kit fa-circle-3-solid'"></i> Select Pharmacy</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-5">
                    <app-patient-selection  [IsfrmPatHis]="IsfrmPatHis" [SelectedPatientDetailsfrmRx]="selectedPatientDetails" (SelectedPatientID)="selectedPatientID($event)"></app-patient-selection>
                </div>
                <div class="col-2 px-0" *ngIf="!RxTransferType">
                    <app-transfer-type [eRxModelFG]="eRxModelFG" [IsHistory]="true" [IsfrmPatHis]="IsfrmPatHis" (SelectedDrugDetails)="selectedDrugDetails($event)" (SelectedTransType)="selectedTransTypeDetails($event)"></app-transfer-type>
                </div>
                <div class="col-2 px-0" *ngIf="RxTransferType === 'MarkTransferIn'">
                    <app-mark-as-transfer-in-rxdetails></app-mark-as-transfer-in-rxdetails>
                </div>
                <div class="col-5">
                    <app-pharmacy-selection (selectedPharm)="selectedToPharmacy($event)"></app-pharmacy-selection>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col">

                </div>
                <div class="col-12">
                    <app-transfer-request-details [SelectedTransferTypeDetails]="selectedDruginformation ? selectedDruginformation : selectedTransferTypeDetails"></app-transfer-request-details>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-5">
                    <app-current-pharmacy (RequestPharmacyDetails)="requestPharmacyDetails($event)" [LabelName]="'Your Pharmacy'"></app-current-pharmacy>
                </div>
                <div class="col-4 px-0">
                    <app-single-notes [IsNotPopup]="true" (IsPopUpClosed)="IsPopUpClosed($event)"
                        [MainCategory]="'Rx Annotation'"></app-single-notes>
                </div>
                <div class="col-3 text-center" style="vertical-align: middle;
    line-height: 15;">
                      <img src="/assets/Mark Transfer In.svg">
                </div>
            </div>
        </div>
        <button footer class="hotkey_success"
            [ngbTooltip]="eRxModelFG.invalid && (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn'])?'':'Rx Transfer In Request.'" triggers="mouseover:mouseleave"
            [autoClose]="true" placement="top" tooltipClass="search-tooltip title-tooltip"
            [disabled]="(eRxModelFG.valid && (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn'])) ? false : true"
            (click)="eRxModelFG.valid && (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn']) ? sendTransferInReq() : ''"  appShortcutKey [AltKey]="'q'"><span>Q</span> Send Transfer In Req
        </button>
        <button footer class="hotkey_success" (click)="closePopup()" (click)="c('close')"  appShortcutKey
            [AltKey]="'c'"><span>C</span> CANCEL
        </button>
    </app-custom-modal>
</ng-template>